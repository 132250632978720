import { createAsyncThunk } from '@reduxjs/toolkit';

import { UserLocalSettings } from '../lib/getInitialUserSettings';

export const setSettings = createAsyncThunk(
  'user/setSettings',
  async (settings: Partial<UserLocalSettings>) => {
    Object.entries(settings).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });
    return settings;
  },
);
