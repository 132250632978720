import { ToothImage } from '../config/slices.types';

export const orderingMasksAndImages = (masksAndImages: {
  masks: ToothImage[];
  images: ToothImage[];
}) => {
  const result = masksAndImages.images.flatMap((img) => {
    const currentMask = masksAndImages.masks?.find(
      (mask: ToothImage) =>
        img.key === mask.key.slice(0, mask.key.indexOf('mask') - 1),
    );

    return currentMask ? [currentMask, img] : [img];
  });

  return result;
};
