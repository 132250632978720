import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  CreateOrderReq,
  DeepPartial,
  GetAvailableProductsReq,
  PayOrderReq,
  SetAccountAddressReq,
  SetAccountPhoneReq,
} from '@/shared/api/protocol_gen/api/billing/svc_billing';
import api, { ApiError } from '@/shared/api/api';

export const getAvailableProducts = createAsyncThunk(
  'subscriptions/getSubscriptions',
  async (
    request: DeepPartial<GetAvailableProductsReq>,
    { rejectWithValue },
  ) => {
    try {
      const { AvailableProducts } = await api.billing.GetAvailableProducts(
        request,
      );

      return AvailableProducts;
    } catch (error: unknown) {
      const { message, type } = error as ApiError;

      return rejectWithValue({ message, type });
    }
  },
);

export const createOrder = createAsyncThunk(
  'subscriptions/createOrder',
  async (request: DeepPartial<CreateOrderReq>, { rejectWithValue }) => {
    try {
      const response = await api.billing.CreateOrder(request);

      return response;
    } catch (error: unknown) {
      const { message, type } = error as ApiError;

      return rejectWithValue({ message, type });
    }
  },
);

export const payInvoice = createAsyncThunk(
  'subscriptions/payInvoice',
  async (request: DeepPartial<PayOrderReq>, { rejectWithValue }) => {
    try {
      const response = await api.billing.PayOrder(request);

      return response;
    } catch (error: unknown) {
      const { message, type } = error as ApiError;

      return rejectWithValue({ message, type });
    }
  },
);

export const setAccountAddress = createAsyncThunk(
  'subscriptions/setAccountAddress',
  async (request: DeepPartial<SetAccountAddressReq>, { rejectWithValue }) => {
    try {
      const response = await api.billing.SetAccountAddress(request);

      return response;
    } catch (error: unknown) {
      const { message, type } = error as ApiError;

      return rejectWithValue({ message, type });
    }
  },
);

export const setAccountPhone = createAsyncThunk(
  'subscriptions/setAccountPhone',
  async (request: DeepPartial<SetAccountPhoneReq>, { rejectWithValue }) => {
    try {
      const response = await api.billing.SetAccountPhone(request);

      return response;
    } catch (error: unknown) {
      const { message, type } = error as ApiError;

      return rejectWithValue({ message, type });
    }
  },
);
