import { defineMessages } from 'react-intl';

import { Country, Language } from '@/shared/api/protocol_gen/model/dto_base';

export const countryDefaultNames = defineMessages<Exclude<Country, Country.UNRECOGNIZED | Country.COUNTRY_UNSPECIFIED>>({
  [Country.COUNTRY_KG]: {
    id: 'country.kg',
    defaultMessage: 'Kyrgyzstan',
  },
  [Country.COUNTRY_AT]: {
    id: 'country.at',
    defaultMessage: 'Austria',
  },
  [Country.COUNTRY_AM]: {
    id: 'country.am',
    defaultMessage: 'Arminia',
  },
  [Country.COUNTRY_GB]: {
    id: 'country.gb',
    defaultMessage: 'United Kingdom',
  },
  [Country.COUNTRY_SE]: {
    id: 'country.se',
    defaultMessage: 'Sweden',
  },
  [Country.COUNTRY_UZ]: {
    id: 'country.uz',
    defaultMessage: 'Uzbekistan',
  },
  [Country.COUNTRY_MD]: {
    id: 'country.md',
    defaultMessage: 'Moldova',
  },
  [Country.COUNTRY_BE]: {
    id: 'country.be',
    defaultMessage: 'Belgium',
  },
  [Country.COUNTRY_FI]: {
    id: 'country.fi',
    defaultMessage: 'Finland',
  },
  [Country.COUNTRY_FR]: {
    id: 'country.fr',
    defaultMessage: 'France',
  },
  [Country.COUNTRY_MX]: {
    id: 'country.mx',
    defaultMessage: 'Mexico',
  },
  [Country.COUNTRY_TJ]: {
    id: 'country.tj',
    defaultMessage: 'Tajikistan',
  },
  [Country.COUNTRY_BY]: {
    id: 'country.by',
    defaultMessage: 'Belarus',
  },
  [Country.COUNTRY_RU]: {
    id: 'country.ru',
    defaultMessage: 'Russia',
  },
  [Country.COUNTRY_IL]: {
    id: 'country.il',
    defaultMessage: 'Israel',
  },
  [Country.COUNTRY_NO]: {
    id: 'country.no',
    defaultMessage: 'Norway',
  },
  [Country.COUNTRY_DK]: {
    id: 'country.dk',
    defaultMessage: 'Denmark',
  },
  [Country.COUNTRY_US]: {
    id: 'country.us',
    defaultMessage: 'United States',
  },
  [Country.COUNTRY_CA]: {
    id: 'country.ca',
    defaultMessage: 'Canada',
  },
  [Country.COUNTRY_DE]: {
    id: 'country.de',
    defaultMessage: 'Germany',
  },
  [Country.COUNTRY_LU]: {
    id: 'country.lu',
    defaultMessage: 'Luxembourg',
  },
  [Country.COUNTRY_AZ]: {
    id: 'country.az',
    defaultMessage: 'Azerbaijan',
  },
  [Country.COUNTRY_KZ]: {
    id: 'country.kz',
    defaultMessage: 'Kazakhstan',
  },
  [Country.COUNTRY_CH]: {
    id: 'country.ch',
    defaultMessage: 'Switzerland',
  },
  [Country.COUNTRY_IT]: {
    id: 'country.it',
    defaultMessage: 'Italy',
  },
  [Country.COUNTRY_NL]: {
    id: 'country.nl',
    defaultMessage: 'Netherlands',
  },
  [Country.COUNTRY_HR]: {
    id: 'country.hr',
    defaultMessage: 'Croatia',
  },
  [Country.COUNTRY_SI]: {
    id: 'country.si',
    defaultMessage: 'Slovenia',
  },
  [Country.COUNTRY_MT]: {
    id: 'country.mt',
    defaultMessage: 'Malta',
  },
  [Country.COUNTRY_CY]: {
    id: 'country.cy',
    defaultMessage: 'Cyprus',
  },
  [Country.COUNTRY_SK]: {
    id: 'country.sk',
    defaultMessage: 'Slovakia',
  },
  [Country.COUNTRY_PL]: {
    id: 'country.pl',
    defaultMessage: 'Poland',
  },
  [Country.COUNTRY_PS]: {
    id: 'country.ps',
    defaultMessage: 'Palestinian Territories',
  },
  [Country.COUNTRY_CZ]: {
    id: 'country.cz',
    defaultMessage: 'Czechia',
  },
  [Country.COUNTRY_HU]: {
    id: 'country.hu',
    defaultMessage: 'Hungary',
  },
  [Country.COUNTRY_VI]: {
    id: 'country.vi',
    defaultMessage: 'U.S. Virgin Islands',
  },
  [Country.COUNTRY_EE]: {
    id: 'country.ee',
    defaultMessage: 'Estonia',
  },
  [Country.COUNTRY_PR]: {
    id: 'country.pr',
    defaultMessage: 'Puerto Rico',
  },
  [Country.COUNTRY_CO]: {
    id: 'country.co',
    defaultMessage: 'Colombia',
  },
  [Country.COUNTRY_RO]: {
    id: 'country.ro',
    defaultMessage: 'Romania',
  },
  [Country.COUNTRY_GU]: {
    id: 'country.gu',
    defaultMessage: 'Guam',
  },
  [Country.COUNTRY_GE]: {
    id: 'country.ge',
    defaultMessage: 'Georgia',
  },
  [Country.COUNTRY_IE]: {
    id: 'country.ie',
    defaultMessage: 'Ireland',
  },
  [Country.COUNTRY_GR]: {
    id: 'country.gr',
    defaultMessage: 'Greece',
  },
  [Country.COUNTRY_PT]: {
    id: 'country.pt',
    defaultMessage: 'Portugal',
  },
  [Country.COUNTRY_LV]: {
    id: 'country.lv',
    defaultMessage: 'Latvia',
  },
  [Country.COUNTRY_AU]: {
    id: 'country.as',
    defaultMessage: 'Australia',
  },
  [Country.COUNTRY_NZ]: {
    id: 'country.nz',
    defaultMessage: 'New Zealand',
  },
  [Country.COUNTRY_AS]: {
    id: 'country.as',
    defaultMessage: 'American Samoa',
  },
  [Country.COUNTRY_LT]: {
    id: 'country.lt',
    defaultMessage: 'Lithuania',
  },
  [Country.COUNTRY_ES]: {
    id: 'country.es',
    defaultMessage: 'Spain',
  },
  [Country.COUNTRY_UA]: {
    id: 'country.ua',
    defaultMessage: 'Ukraine',
  },
});

export const languageDefaultMessages = defineMessages<Exclude<Language, Language.InvalidValue | Language.UNRECOGNIZED>>({
  [Language.EN_US]: {
    id: 'language.enUS',
    defaultMessage: 'American English',
  },
  [Language.RU]: {
    id: 'language.ru',
    defaultMessage: 'Russian',
  },
  [Language.HE]: {
    id: 'language.he',
    defaultMessage: 'Hebrew',
  },
  [Language.DE]: {
    id: 'language.de',
    defaultMessage: 'German',
  },
  [Language.ES]: {
    id: 'language.es',
    defaultMessage: 'Spanish',
  },
  [Language.ZH_CN]: {
    id: 'language.zhCN',
    defaultMessage: 'Simplified Chinese',
  },
  [Language.IT]: {
    id: 'language.it',
    defaultMessage: 'Italian',
  },
  [Language.RO]: {
    id: 'language.ro',
    defaultMessage: 'Romanian',
  },
  [Language.PT_BR]: {
    id: 'language.ptBR',
    defaultMessage: 'Brazilian Portuguese',
  },
  [Language.FR]: {
    id: 'language.FR',
    defaultMessage: 'French',
  },
  [Language.EN_GB]: {
    id: 'language.enGB',
    defaultMessage: 'British English',
  },
  [Language.JA]: {
    id: 'language.ja',
    defaultMessage: 'Japanese',
  },
  [Language.KA]: {
    id: 'language.ka',
    defaultMessage: 'Georgian',
  },
  [Language.UK]: {
    id: 'language.uk',
    defaultMessage: 'Ukrainian',
  },
  [Language.PL]: {
    id: 'language.PO',
    defaultMessage: 'Polish',
  },
});
