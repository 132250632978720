// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportToothChart-module__container--IhtqDjpb{max-width:720px;margin:0 auto}.ReportToothChart-module__buttons--AipYwliz{display:flex;gap:12px;justify-content:space-between;margin-bottom:12px}.ReportToothChart-module__roi--DwPy8\\+mF{display:flex;align-items:center;justify-content:space-between;margin-bottom:12px}.ReportToothChart-module__toothChartLegends--1mob9EGo{flex-grow:1}@media screen and (max-width: 420px){.ReportToothChart-module__toothChartLegends--1mob9EGo{overflow-x:hidden;max-width:106px}}", "",{"version":3,"sources":["webpack://./src/widgets/ReportToothChart/ui/ReportToothChart/ReportToothChart.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,8CACE,eAAA,CACA,aAAA,CAGF,4CACE,YAAA,CACA,QAAA,CACA,6BAAA,CACA,kBAAA,CAGF,yCACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sDACE,WAAA,CCfA,qCDcF,sDAII,iBAAA,CACA,eAAA,CAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  max-width: 720px;\n  margin: 0 auto;\n}\n\n.buttons {\n  display: flex;\n  gap: 12px;\n  justify-content: space-between;\n  margin-bottom: 12px;\n}\n\n.roi {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 12px;\n}\n\n.toothChartLegends {\n  flex-grow: 1;\n\n  @include mobile {\n    overflow-x: hidden;\n    max-width: 106px;\n  }\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReportToothChart-module__container--IhtqDjpb",
	"buttons": "ReportToothChart-module__buttons--AipYwliz",
	"roi": "ReportToothChart-module__roi--DwPy8+mF",
	"toothChartLegends": "ReportToothChart-module__toothChartLegends--1mob9EGo"
};
export default ___CSS_LOADER_EXPORT___;
