import { filter, isEmpty, keys } from 'ramda';

import { ConditionGroups, ConditionInterface } from '../config';
import { isAnyConditionInterfaceIsCheked } from '../lib';

export const useConditionGroupsList = (
  conditionItemsByGroups: Record<ConditionGroups, ConditionInterface[]>,
) => {
  const conditionGroupsList = keys(conditionItemsByGroups);

  const openConditionGroups = filter((group: ConditionGroups) =>
    isAnyConditionInterfaceIsCheked(conditionItemsByGroups[group]),
  )(conditionGroupsList);

  const isOpenGroupsList = !isEmpty(openConditionGroups);

  return { conditionGroupsList, openConditionGroups, isOpenGroupsList };
};
