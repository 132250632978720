import React, { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { i18n, SCAN_DATA_FIELDS, ScanDataFields } from '@/entities/study';

import styles from './ScanInfo.module.scss';

type ScanInfoProps = {
  className?: string;
};

export const ScanInfo: FC<ScanInfoProps> = (props) => {
  const { className } = props;
  const { formatMessage } = useIntl();

  return (
    <div className={cn(styles.container, className)}>
      <h4 className={cn('h4', styles.title)}>
        <FormattedMessage
          id="sdutyInfo.scanInfo.title"
          defaultMessage="Scan info"
        />
      </h4>
      <div className={styles.scanElems}>
        {SCAN_DATA_FIELDS.map((key: ScanDataFields) => (
          <div key={key} className={cn('p2', styles.scanElem, styles[key])}>
            <span className={styles.scanLabel}>
              {formatMessage(i18n.studyScanInfoMessages[key])}
              {': '}
            </span>
            <span>
              <FormattedMessage
                id="global.notAvailable "
                defaultMessage="not available"
              />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
