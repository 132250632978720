import React, { FC } from 'react';
import cn from 'classnames';

import { Icon } from '../Icon/Icon';

import toothSprite from './toothSprite.svg';
import styles from './ToothIcon.module.scss';

export type ToothIconProps = {
  position: number;
  isMissing?: boolean;
  isThirdMolar?: boolean;
  className?: string;
  testID?: string;
  onClick?: () => void;
};

export const ToothIcon: FC<ToothIconProps> = (props) => {
  const { className, testID, position, isMissing, isThirdMolar, onClick } =
    props;

  const shouldShowPointerCursor = typeof onClick === 'function' && !isMissing;

  const isMolar = [18, 17, 16, 28, 27, 26, 38, 37, 36, 48, 47, 46].includes(
    position,
  );

  if (isMissing) {
    return (
      <Icon
        name="close"
        size={24}
        className={cn(!isThirdMolar && styles.missing)}
      />
    );
  }

  return (
    <svg
      onClick={onClick}
      role="presentation"
      aria-hidden="true"
      focusable="false"
      data-testid={testID}
      viewBox={isMolar ? '0 0 27 47' : '0 0 20 48'}
      width={isMolar ? 27 : 20}
      height={isMolar ? 47 : 48}
      className={cn(
        className,
        styles.container,
        shouldShowPointerCursor && styles.pointer,
      )}
    >
      <use href={`${toothSprite}#tooth${position}`} />
    </svg>
  );
};
