import { ISO_PERMANENT_TOOTH_NUMBERS } from '../config/toothConfig';

const getPrimaryToothByPermanentISONumber = (ISONumber: number): number => {
  const position = Number(String(ISONumber)[1]);

  if (position > 5) {
    return null;
  }

  return ISONumber + 40;
};

export const getISONumbersOrder = () => {
  const ISONumbersOrder = ISO_PERMANENT_TOOTH_NUMBERS.reduce(
    (result, ISONumber) => {
      const primaryToothNumber = getPrimaryToothByPermanentISONumber(ISONumber);

      return primaryToothNumber
        ? [...result, ISONumber, primaryToothNumber]
        : [...result, ISONumber];
    },
    [] as number[],
  );

  return ISONumbersOrder;
};
