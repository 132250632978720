import { setSettings } from './model/userSlice.thunks';
import {
  selectBillingAccountInfo,
  selectCurrentUser,
  selectUserOrganizationLegacy,
  selectSettings,
} from './model/userSlice.selectors';
import {
  addUser,
  setBillingAccount,
  setUserOrganizationLegacy,
} from './model/userSlice';

export const userModel = {
  selectors: {
    selectBillingAccountInfo,
    selectCurrentUser,
    selectUserOrganizationLegacy,
    selectSettings,
  },
  actions: {
    addUser,
    setBillingAccount,
    setUserOrganizationLegacy,
  },
  thunks: {
    setSettings,
  },
};

export * from './lib/getInitialUserSettings';
