import { defineMessages } from 'react-intl';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { ConditionGroups, ChildConditionGroups } from './const';

export const conditionText = defineMessages<ConditionCode>({
  [ConditionCode.UnspecifiedCondition]: {
    id: 'ConditionCode.UnspecifiedCondition',
    defaultMessage: 'ConditionCode.UnspecifiedCondition',
  },
  [ConditionCode.CodeTooth]: {
    id: 'ConditionCode.CodeTooth',
    defaultMessage: 'Tooth',
  },
  [ConditionCode.Missing]: {
    id: 'ConditionCode.Missing',
    defaultMessage: 'Missing',
  },
  [ConditionCode.Implant]: {
    id: 'ConditionCode.Implant',
    defaultMessage: 'Implant',
  },
  [ConditionCode.Pontic]: {
    id: 'ConditionCode.Pontic',
    defaultMessage: 'Pontic',
  },
  [ConditionCode.RootFragment]: {
    id: 'ConditionCode.RootFragment',
    defaultMessage: 'Root fragment',
  },
  [ConditionCode.PartiallyVisible]: {
    id: 'ConditionCode.PartiallyVisible',
    defaultMessage: 'Not fully visible',
  },
  [ConditionCode.Filling]: {
    id: 'ConditionCode.Filling',
    defaultMessage: 'Filling',
  },
  [ConditionCode.Hypercementosis]: {
    id: 'ConditionCode.Hypercementosis',
    defaultMessage: 'Hypercementosis',
  },
  [ConditionCode.Impaction]: {
    id: 'ConditionCode.Impaction',
    defaultMessage: 'Impaction',
  },
  [ConditionCode.Roots1]: {
    id: 'ConditionCode.Roots1',
    defaultMessage: '1 root',
  },
  [ConditionCode.Roots2]: {
    id: 'ConditionCode.Roots2',
    defaultMessage: '2 roots',
  },
  [ConditionCode.Roots3]: {
    id: 'ConditionCode.Roots3',
    defaultMessage: '3 roots',
  },
  [ConditionCode.Roots4]: {
    id: 'ConditionCode.Roots4',
    defaultMessage: '4 roots',
  },
  [ConditionCode.Canals1]: {
    id: 'ConditionCode.Canals1',
    defaultMessage: '1 canals',
  },
  [ConditionCode.Canals2]: {
    id: 'ConditionCode.Canals2',
    defaultMessage: '2 canals',
  },
  [ConditionCode.Canals3]: {
    id: 'ConditionCode.Canals3',
    defaultMessage: '3 canals',
  },
  [ConditionCode.Canals4]: {
    id: 'ConditionCode.Canals4',
    defaultMessage: '4 canals',
  },
  [ConditionCode.Canals5]: {
    id: 'ConditionCode.Canals5',
    defaultMessage: '5 canals',
  },
  [ConditionCode.ArtificialCrown]: {
    id: 'ConditionCode.ArtificialCrown',
    defaultMessage: 'Artificial Crown',
  },
  [ConditionCode.Inlay]: {
    id: 'ConditionCode.Inlay',
    defaultMessage: 'Indirect restoration',
  },
  [ConditionCode.CariesSigns]: {
    id: 'ConditionCode.CariesSigns',
    defaultMessage: 'Signs of caries',
  },
  [ConditionCode.CrownDefectOver50pct]: {
    id: 'ConditionCode.CrownDefectOver50pct',
    defaultMessage: '>50% of crown lost',
  },
  [ConditionCode.EctopicDevelopmentAndEruption]: {
    id: 'ConditionCode.EctopicDevelopmentAndEruption',
    defaultMessage: 'Ectopic development or eruption',
  },
  [ConditionCode.EndoTreated]: {
    id: 'ConditionCode.EndoTreated',
    defaultMessage: 'Endodontically treated tooth',
  },
  [ConditionCode.EndoRootPerforation]: {
    id: 'ConditionCode.EndoRootPerforation',
    defaultMessage: 'Root perforation',
  },
  [ConditionCode.EndoVerticalRootFracture]: {
    id: 'ConditionCode.EndoVerticalRootFracture',
    defaultMessage: 'Vertical root fracture',
  },
  [ConditionCode.EndoCastPostAndCore]: {
    id: 'ConditionCode.EndoCastPostAndCore',
    defaultMessage: 'Cast Post and Core',
  },
  [ConditionCode.EndoFiberPost]: {
    id: 'ConditionCode.EndoFiberPost',
    defaultMessage: 'Fiberglass post',
  },
  [ConditionCode.EndoMetalPost]: {
    id: 'ConditionCode.EndoMetalPost',
    defaultMessage: 'Metal post',
  },
  [ConditionCode.EndoMissedCanal]: {
    id: 'ConditionCode.EndoMissedCanal',
    defaultMessage: 'Missed canal',
  },
  [ConditionCode.EndoOverfilling]: {
    id: 'ConditionCode.EndoOverfilling',
    defaultMessage: 'Overfilling',
  },
  [ConditionCode.EndoShortFilling]: {
    id: 'ConditionCode.EndoShortFilling',
    defaultMessage: 'Short filling',
  },
  [ConditionCode.EndoRootCanalWithRestriction]: {
    id: 'ConditionCode.EndoRootCanalWithRestriction',
    defaultMessage: 'Canal obliteration',
  },
  [ConditionCode.EndoVoidsPresentInTheRootFilling]: {
    id: 'ConditionCode.EndoVoidsPresentInTheRootFilling',
    defaultMessage: 'Voids in the root canal filling',
  },
  [ConditionCode.EndoFracturedInstrumentInRootCanal]: {
    id: 'ConditionCode.EndoFracturedInstrumentInRootCanal',
    defaultMessage: 'Separated instrument in root canal',
  },
  [ConditionCode.EndoAdequateObturation]: {
    id: 'ConditionCode.EndoAdequateObturation',
    defaultMessage: 'Adequate obturation',
  },
  [ConditionCode.EndoAdequateDensity]: {
    id: 'ConditionCode.EndoAdequateDensity',
    defaultMessage: 'Adequate density',
  },
  [ConditionCode.InternalResorption]: {
    id: 'ConditionCode.InternalResorption',
    defaultMessage: 'Internal resorption',
  },
  [ConditionCode.OrthodonticAppliance]: {
    id: 'ConditionCode.OrthodonticAppliance',
    defaultMessage: 'Orthodontic appliance',
  },
  [ConditionCode.PeriodontalLigamentSpaceWideningAlongRoot]: {
    id: 'ConditionCode.PeriodontalLigamentSpaceWideningAlongRoot',
    defaultMessage: 'PDL space widening along root',
  },
  [ConditionCode.FurcationalRadiolucency]: {
    id: 'ConditionCode.FurcationalRadiolucency',
    defaultMessage: 'Furcation lesion',
  },
  [ConditionCode.PulpStones]: {
    id: 'ConditionCode.PulpStones',
    defaultMessage: 'Pulp stone',
  },
  [ConditionCode.SecondaryOrTertriaryDentin]: {
    id: 'ConditionCode.SecondaryOrTertriaryDentin',
    defaultMessage: 'Secondary or tertriary dentin',
  },
  [ConditionCode.SurfaceResorption]: {
    id: 'ConditionCode.SurfaceResorption',
    defaultMessage: 'External resorption',
  },
  [ConditionCode.HorizontalRootFracture]: {
    id: 'ConditionCode.HorizontalRootFracture',
    defaultMessage: 'Horizontal root fracture',
  },
  [ConditionCode.CrownFracture]: {
    id: 'ConditionCode.CrownFracture',
    defaultMessage: 'Crown fracture',
  },
  [ConditionCode.Abfraction]: {
    id: 'ConditionCode.Abfraction',
    defaultMessage: 'Abfraction',
  },
  [ConditionCode.PeriapicalLesion]: {
    id: 'ConditionCode.PeriapicalLesion',
    defaultMessage: 'Periapical radiolucency',
  },
  [ConditionCode.AbnormalityOfSizeAndForm]: {
    id: 'ConditionCode.AbnormalityOfSizeAndForm',
    defaultMessage: 'Abnormality of size and form',
  },
  [ConditionCode.DiscolorationOfTooth]: {
    id: 'ConditionCode.DiscolorationOfTooth',
    defaultMessage: 'Discoloration of tooth',
  },
  [ConditionCode.ExcessiveAttritionOfTooth]: {
    id: 'ConditionCode.ExcessiveAttritionOfTooth',
    defaultMessage: 'Attrition',
  },
  [ConditionCode.Pulpitis]: {
    id: 'ConditionCode.Pulpitis',
    defaultMessage: 'Pulpitis',
  },
  [ConditionCode.FailureOfEndoTreatment]: {
    id: 'ConditionCode.FailureOfEndoTreatment',
    defaultMessage: 'Failure of endodontic treatment',
  },
  [ConditionCode.PostAndCore]: {
    id: 'ConditionCode.PostAndCore',
    defaultMessage: 'Post and core',
  },
  [ConditionCode.Abutment]: {
    id: 'ConditionCode.Abutment',
    defaultMessage: 'Prosthodontic appliance',
  },
  [ConditionCode.PeriImplantitis]: {
    id: 'ConditionCode.PeriImplantitis',
    defaultMessage: 'Peri-implantitis',
  },
  [ConditionCode.PeriImplantMucosis]: {
    id: 'ConditionCode.PeriImplantMucosis',
    defaultMessage: 'Dental peri-implant mucositis',
  },
  [ConditionCode.FailureOfOsseointegrationOfImplant]: {
    id: 'ConditionCode.FailureOfOsseointegrationOfImplant',
    defaultMessage: 'Failure of osseointegration of dental implant',
  },
  [ConditionCode.DentalCalculus]: {
    id: 'ConditionCode.DentalCalculus',
    defaultMessage: 'Dental calculus',
  },
  [ConditionCode.GingivalRecession]: {
    id: 'ConditionCode.GingivalRecession',
    defaultMessage: 'Gingival recession',
  },
  [ConditionCode.BleedingGums]: {
    id: 'ConditionCode.BleedingGums',
    defaultMessage: 'Bleeding gums',
  },
  [ConditionCode.ExcessiveToothMobility]: {
    id: 'ConditionCode.ExcessiveToothMobility',
    defaultMessage: 'Excessive tooth mobility',
  },
  [ConditionCode.Ankylosis]: {
    id: 'ConditionCode.Ankylosis',
    defaultMessage: 'Ankylosis',
  },
  [ConditionCode.Spacing]: {
    id: 'ConditionCode.Spacing',
    defaultMessage: 'Spacing',
  },
  [ConditionCode.HorizontalDisplacement]: {
    id: 'ConditionCode.HorizontalDisplacement',
    defaultMessage: 'Horizontal displacement',
  },
  [ConditionCode.Crowding]: {
    id: 'ConditionCode.Crowding',
    defaultMessage: 'Crowding',
  },
  [ConditionCode.Overeruption]: {
    id: 'ConditionCode.Overeruption',
    defaultMessage: 'Overeruption',
  },
  [ConditionCode.SupernumeraryTooth]: {
    id: 'ConditionCode.SupernumeraryTooth',
    defaultMessage: 'Supernumerary tooth',
  },
  [ConditionCode.Avulsion]: {
    id: 'ConditionCode.Avulsion',
    defaultMessage: 'Avulsion of tooth',
  },
  [ConditionCode.FailureOfRestoration]: {
    id: 'ConditionCode.FailureOfRestoration',
    defaultMessage: 'Failure of restoration',
  },
  [ConditionCode.DentalSealant]: {
    id: 'ConditionCode.DentalSealant',
    defaultMessage: 'Dental sealant',
  },
  [ConditionCode.OpenMargin]: {
    id: 'ConditionCode.OpenMargin',
    defaultMessage: 'Open margin',
  },
  [ConditionCode.Overhang]: {
    id: 'ConditionCode.Overhang',
    defaultMessage: 'Overhang',
  },
  [ConditionCode.DentalRestorationLost]: {
    id: 'ConditionCode.DentalRestorationLost',
    defaultMessage: 'Dental restoration lost',
  },
  [ConditionCode.LackOfInterproximalContact]: {
    id: 'ConditionCode.LackOfInterproximalContact',
    defaultMessage: 'Lack of interproximal tooth contact',
  },
  [ConditionCode.SecondaryCaries]: {
    id: 'ConditionCode.SecondaryCaries',
    defaultMessage: 'Secondary caries',
  },
  [ConditionCode.CrownDefect]: {
    id: 'ConditionCode.CrownDefect',
    defaultMessage: 'Crown defect',
  },
  [ConditionCode.CanalFilling]: {
    id: 'ConditionCode.CanalFilling',
    defaultMessage: 'Canal filling',
  },
  [ConditionCode.Post]: {
    id: 'ConditionCode.Post',
    defaultMessage: 'Post',
  },
  [ConditionCode.PeriodontalBoneLoss]: {
    id: 'ConditionCode.PeriodontalBoneLoss',
    defaultMessage: 'Periodontal bone loss',
  },
  [ConditionCode.EndodonticAccess]: {
    id: 'ConditionCode.EndodonticAccess',
    defaultMessage: 'Endodontic access',
  },
  [ConditionCode.Pulpotomy]: {
    id: 'ConditionCode.Pulpotomy',
    defaultMessage: 'Pulpotomy',
  },
  [ConditionCode.Clamp]: {
    id: 'ConditionCode.Clamp',
    defaultMessage: 'Clamp',
  },
  [ConditionCode.ForeignBody]: {
    id: 'ConditionCode.ForeignBody',
    defaultMessage: 'Foreign body',
  },
  [ConditionCode.EndodonticInstrument]: {
    id: 'ConditionCode.EndodonticInstrument',
    defaultMessage: 'Endodontic instrument',
  },
  [ConditionCode.IndirectRestoration]: {
    id: 'ConditionCode.IndirectRestoration',
    defaultMessage: 'Indirect restoration',
  },
  [ConditionCode.ChippedTooth]: {
    id: 'ConditionCode.ChippedTooth',
    defaultMessage: 'Chipped tooth',
  },
  [ConditionCode.FillingLeakage]: {
    id: 'ConditionCode.FillingLeakage',
    defaultMessage: 'Filling leakage',
  },
  [ConditionCode.LateralRadiolucency]: {
    id: 'ConditionCode.LateralRadiolucency',
    defaultMessage: 'Lateral radiolucency',
  },
  [ConditionCode.Apicoectomy]: {
    id: 'ConditionCode.Apicoectomy',
    defaultMessage: 'Apicoectomy',
  },
  [ConditionCode.ToothGerm]: {
    id: 'ConditionCode.ToothGerm',
    defaultMessage: 'Tooth germ',
  },
  [ConditionCode.SinusPathology]: {
    id: 'ConditionCode.SinusPathology',
    defaultMessage: 'Signs of maxillary sinus pathology',
  },
  [ConditionCode.BoneStructureDisorder]: {
    id: 'ConditionCode.BoneStructureDisorder',
    defaultMessage: 'Signs of bone structure disorder',
  },
  [ConditionCode.PreparedTooth]: {
    id: 'ConditionCode.PreparedTooth',
    defaultMessage: 'Prepared tooth',
  },
  [ConditionCode.VoidsInRestoration]: {
    id: 'ConditionCode.VoidsInRestoration',
    defaultMessage: 'Voids in filling',
  },
  [ConditionCode.Child_DefectDepth_NotSelected]: {
    id: 'ConditionCode.Child_DefectDepth_NotSelected',
    defaultMessage: 'NotSelected',
  },
  [ConditionCode.Child_DefectDepth_Initial]: {
    id: 'ConditionCode.Child_DefectDepth_Initial',
    defaultMessage: 'Initial',
  },
  [ConditionCode.Child_DefectDepth_Enamel]: {
    id: 'ConditionCode.Child_DefectDepth_Enamel',
    defaultMessage: 'Enamel',
  },
  [ConditionCode.Child_DefectDepth_Dentin]: {
    id: 'ConditionCode.Child_DefectDepth_Dentin',
    defaultMessage: 'Dentin',
  },
  [ConditionCode.Child_DefectDepth_Pulp]: {
    id: 'ConditionCode.Child_DefectDepth_Pulp',
    defaultMessage: 'Pulp',
  },
  [ConditionCode.Child_DefectDepth_Root]: {
    id: 'ConditionCode.Child_DefectDepth_Root',
    defaultMessage: 'Root',
  },
  [ConditionCode.Child_DefectSurface_NotSelected]: {
    id: 'ConditionCode.Child_DefectSurface_NotSelected',
    defaultMessage: 'NotSelected',
  },
  [ConditionCode.Child_DefectSurface_Mesial]: {
    id: 'ConditionCode.Child_DefectSurface_Mesial',
    defaultMessage: 'Mesial',
  },
  [ConditionCode.Child_DefectSurface_Distal]: {
    id: 'ConditionCode.Child_DefectSurface_Distal',
    defaultMessage: 'Distal',
  },
  [ConditionCode.Child_DefectSurface_Lingual]: {
    id: 'ConditionCode.Child_DefectSurface_Lingual',
    defaultMessage: 'Lingual',
  },
  [ConditionCode.Child_DefectSurface_Buccal]: {
    id: 'ConditionCode.Child_DefectSurface_Buccal',
    defaultMessage: 'Buccal',
  },
  [ConditionCode.Child_DefectSurface_Occlusial]: {
    id: 'ConditionCode.Child_DefectSurface_Occlusial',
    defaultMessage: 'Occlusial',
  },
  [ConditionCode.Child_DefectSurface_Vestibular]: {
    id: 'ConditionCode.Child_DefectSurface_Vestibular',
    defaultMessage: 'Vestibular',
  },
  [ConditionCode.Child_DefectSurface_Incisal]: {
    id: 'ConditionCode.Child_DefectSurface_Incisal',
    defaultMessage: 'Incisal',
  },
  [ConditionCode.Child_PeriapicalLesion_Communication_Sinus]: {
    id: 'ConditionCode.Child_PeriapicalLesion_Communication_Sinus',
    defaultMessage: 'Communication_Sinus',
  },
  [ConditionCode.Child_PeriapicalLesion_Communication_Canal]: {
    id: 'ConditionCode.Child_PeriapicalLesion_Communication_Canal',
    defaultMessage: 'Communication_Canal',
  },
  [ConditionCode.Child_PeriapicalLesion_Subtype_Radiopacity]: {
    id: 'ConditionCode.Subtype_Radiopacity',
    defaultMessage: 'Radiopacity',
  },
  [ConditionCode.Child_PeriapicalLesion_Subtype_Radiolucency]: {
    id: 'ConditionCode.Child_PeriapicalLesion_Subtype_Radiolucency',
    defaultMessage: 'Sybtype_Radiolucency',
  },
  [ConditionCode.Child_PeriapicalLesion_Subtype_LigamentSpaceWideningInPeriapicalRegion]:
    {
      id: 'ConditionCode.Child_PeriapicalLesion_Subtype_LigamentSpaceWideningInPeriapicalRegion',
      defaultMessage: 'LigamentSpaceWideningInPeriapicalRegion',
    },
  [ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild',
    defaultMessage: 'Mild',
  },
  [ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate',
    defaultMessage: 'Moderate',
  },
  [ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe',
    defaultMessage: 'Severe',
  },
  [ConditionCode.Child_PeriodontalBoneLoss_Shape_Vertical]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Shape_Vertical',
    defaultMessage: 'Vertical',
  },
  [ConditionCode.Child_PeriodontalBoneLoss_Shape_Horizontal]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Shape_Horizontal',
    defaultMessage: 'Horizontal',
  },
  [ConditionCode.Child_PeriodontalBoneLoss_Shape_Mixed]: {
    id: 'ConditionCode.Child_PeriodontalBoneLoss_Shape_Mixed',
    defaultMessage: 'Mixed',
  },
  [ConditionCode.Child_Filling_BuildUp]: {
    id: 'ConditionCode.Child_Filling_BuildUp',
    defaultMessage: 'Child_Filling_BuildUp',
  },
  [ConditionCode.Child_BoneStructureDisorder_Subtype_NotSelected]: {
    id: 'ConditionCode.Child_BoneStructureDisorder_Subtype_NotSelected',
    defaultMessage: 'NotSelected',
  },
  [ConditionCode.Child_BoneStructureDisorder_Subtype_Radiopacity]: {
    id: 'ConditionCode.Child_BoneStructureDisorder_Subtype_Radiopacity',
    defaultMessage: 'Radiopacity',
  },
  [ConditionCode.Child_BoneStructureDisorder_Subtype_Radiolucency]: {
    id: 'ConditionCode.Child_BoneStructureDisorder_Subtype_Radiolucency',
    defaultMessage: 'Radiolucency',
  },
  [ConditionCode.Child_BoneStructureDisorder_Subtype_Mixed]: {
    id: 'ConditionCode.Child_BoneStructureDisorder_Subtype_Mixed',
    defaultMessage: 'Mixed',
  },
  [ConditionCode.Child_Impaction_Subtype_CompleteBony]: {
    id: 'ConditionCode.Child_Impaction_Subtype_CompleteBony',
    defaultMessage: 'Complete bony',
  },
  [ConditionCode.Child_Impaction_Subtype_PartialBony]: {
    id: 'ConditionCode.Child_Impaction_Subtype_PartialBony',
    defaultMessage: 'Partial bony',
  },
  [ConditionCode.UNRECOGNIZED]: {
    id: 'ConditionCode.UNRECOGNIZED',
    defaultMessage: 'UNRECOGNIZED',
  },
});

export const conditionGroupText = defineMessages<ConditionGroups>({
  [ConditionGroups.toothType]: {
    id: 'ConditionGroups.toothType',
    defaultMessage: 'Type',
  },
  [ConditionGroups.rootsNumbers]: {
    id: 'ConditionGroups.rootsNumbers',
    defaultMessage: 'Roots number',
  },
  [ConditionGroups.canalsNumbers]: {
    id: 'ConditionGroups.canalsNumbers',
    defaultMessage: 'Canal number',
  },
  [ConditionGroups.perio]: {
    id: 'ConditionGroups.perio',
    defaultMessage: 'Periodontium',
  },
  [ConditionGroups.position]: {
    id: 'ConditionGroups.position',
    defaultMessage: 'Position',
  },
  [ConditionGroups.crown]: {
    id: 'ConditionGroups.crown',
    defaultMessage: 'Crown state',
  },
  [ConditionGroups.root]: {
    id: 'ConditionGroups.root',
    defaultMessage: 'Roots',
  },
  [ConditionGroups.endo]: {
    id: 'ConditionGroups.endo',
    defaultMessage: 'Endo',
  },
  [ConditionGroups.implant]: {
    id: 'ConditionGroups.implant',
    defaultMessage: 'Implant',
  },
  [ConditionGroups.periradicularPathologies]: {
    id: 'ConditionGroups.periradicularPathologies',
    defaultMessage: 'Periradicular pathologies',
  },
});

export const childConditionGroupText = defineMessages<ChildConditionGroups>({
  [ChildConditionGroups.depth]: {
    id: 'ChildConditionGroups.depth',
    defaultMessage: 'Depth',
  },
  [ChildConditionGroups.surface]: {
    id: 'ChildConditionGroups.surface',
    defaultMessage: 'Surface',
  },
  [ChildConditionGroups.perioSeverity]: {
    id: 'ChildConditionGroups.perioSeverity',
    defaultMessage: 'Severity',
  },
  [ChildConditionGroups.perioType]: {
    id: 'ChildConditionGroups.perioType',
    defaultMessage: 'Type',
  },
  [ChildConditionGroups.impaction]: {
    id: 'ChildConditionGroups.impaction',
    defaultMessage: 'Impaction',
  },
  [ChildConditionGroups.obturation]: {
    id: 'ChildConditionGroups.obturation',
    defaultMessage: 'Obturation',
  },
  [ChildConditionGroups.quality]: {
    id: 'ChildConditionGroups.quality',
    defaultMessage: 'Quality',
  },
  [ChildConditionGroups.complications]: {
    id: 'ChildConditionGroups.complications',
    defaultMessage: 'Complications',
  },
  [ChildConditionGroups.buildup]: {
    id: 'ChildConditionGroups.buildup',
    defaultMessage: 'Build up',
  },
});
