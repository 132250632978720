import React, { FC, ReactNode } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { TooltipContentProps } from '@radix-ui/react-tooltip';
import cn from 'classnames';

import { Icon } from '../Icon/Icon';
import { IconNames } from '../Icon/Icon.types';

import styles from './Tooltip.module.scss';

export interface TooltipProps {
  title?: ReactNode;
  icon?: IconNames;
  className?: string;
  testID?: string;
  content: ReactNode;
  side?: TooltipContentProps['side'];
  align?: TooltipContentProps['align'];
  children?: ReactNode;
}

export const Tooltip: FC<TooltipProps> = (props) => {
  const {
    children,
    className,
    testID,
    title,
    icon,
    content,
    side = 'top',
    align = 'center',
  } = props;
  const isContentWrapper = Boolean(title) || Boolean(icon);

  return (
    <RadixTooltip.Root delayDuration={0}>
      <RadixTooltip.Trigger asChild>
        <div className={styles.tooltipTriggerContainer}>{children}</div>
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          className={cn(styles.tooltipContent, 'p3', className)}
          data-testid={testID}
          side={side}
          align={align}
        >
          {isContentWrapper ? (
            <div className={styles.tooltipContentContainer}>
              {icon && <Icon name={icon} size={24} />}
              <div className={styles.tooltipContentInnerContainer}>
                {title && <h5 className="h5">{title}</h5>}
                {content}
              </div>
            </div>
          ) : (
            content
          )}
          <RadixTooltip.Arrow asChild>
            <div className={cn(styles.tooltipArrow)} />
          </RadixTooltip.Arrow>
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};
