import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector, useMedia } from '@/shared/hooks';
import { Button, Layout } from '@/shared/ui/';
import { Header } from '@/widgets/Header';
import {
  PatientModalForm,
  usePatientModalForm,
} from '@/widgets/PatientModalForm';
import { PATHS } from '@/shared/config';
import { organizationModel } from '@/entities/organization';
import { PatientListFilters } from '@/widgets/PatientListFilters';

import { PatientList } from './ui/PatientList/PatientList';
import {
  PatientsFilter,
  usePatientsListStream,
} from './hooks/usePatientsListStream';
import styles from './Patients.module.scss';

export const Patients: FC = () => {
  const {
    currentPatientID,
    showPatientModalForm,
    openNewPatientModalForm,
    closePatientModalForm,
  } = usePatientModalForm();

  const navigate = useNavigate();

  const organizationID = useAppSelector(organizationModel.selectors.selectID);

  const { isPhone, isMobile } = useMedia();

  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get('search');
  const patietnsFilter = searchParams.get('filters') as PatientsFilter;

  useEffect(() => {
    if (!organizationID) {
      navigate(PATHS.organizations);
    }
  }, []);

  const { openPatientListStream, closePatientListStream } =
    usePatientsListStream({ organizationID });

  const searchPatients = (searchString: string) => {
    closePatientListStream();
    openPatientListStream({
      searchString,
      sharedWithMe: patietnsFilter,
      id: '',
    });
  };

  const filterPatients = (sharedWithMe: PatientsFilter) => {
    closePatientListStream();
    openPatientListStream({ searchString: searchValue, sharedWithMe, id: '' });
  };

  const fetchMorePatients = (id: string) => {
    closePatientListStream(false);
    openPatientListStream({
      searchString: searchValue,
      sharedWithMe: patietnsFilter,
      id,
    });
  };

  useEffect(() => {
    if (organizationID) {
      openPatientListStream();
    }

    return () => {
      closePatientListStream();
    };
  }, [organizationID]);

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.Content>
        <Layout.Main>
          <section className={styles.container}>
            <main className={styles.main}>
              <div className={styles.header}>
                <h1 className="h1">
                  <FormattedMessage
                    id="patientList.pageTitle"
                    defaultMessage="Patients"
                  />
                </h1>
                <Button
                  onClick={openNewPatientModalForm}
                  icon="plus"
                  size={isPhone ? 'medium' : 'large'}
                >
                  {isMobile ? (
                    <FormattedMessage
                      id="patientList.addNew"
                      defaultMessage="Add new"
                    />
                  ) : (
                    <FormattedMessage
                      id="patientList.addPatient"
                      defaultMessage="Add new patient"
                    />
                  )}
                </Button>
              </div>

              <PatientListFilters
                onInputChange={searchPatients}
                onFilterChange={filterPatients}
              />

              <PatientList
                handleAddPatient={openNewPatientModalForm}
                reFetchPatients={fetchMorePatients}
              />
            </main>

            <PatientModalForm
              patientID={currentPatientID}
              isOpen={showPatientModalForm}
              onClose={closePatientModalForm}
            />
          </section>
        </Layout.Main>
      </Layout.Content>
      <Layout.Footer />
    </Layout>
  );
};
