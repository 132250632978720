/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTSeriesGeometryData,
  FloatPoint3D,
} from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

export interface ReportCBCTSegmentation {
  CropGeometryData: CBCTSeriesGeometryData | undefined;
  Landmarks: { [key: string]: FloatPoint3D };
}

export interface ReportCBCTSegmentation_LandmarksEntry {
  key: string;
  value: FloatPoint3D | undefined;
}

function createBaseReportCBCTSegmentation(): ReportCBCTSegmentation {
  return { CropGeometryData: undefined, Landmarks: {} };
}

export const ReportCBCTSegmentation = {
  encode(
    message: ReportCBCTSegmentation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CropGeometryData !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.CropGeometryData,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    Object.entries(message.Landmarks).forEach(([key, value]) => {
      ReportCBCTSegmentation_LandmarksEntry.encode(
        { key: key as any, value },
        writer.uint32(18).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportCBCTSegmentation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTSegmentation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CropGeometryData = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          const entry2 = ReportCBCTSegmentation_LandmarksEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry2.value !== undefined) {
            message.Landmarks[entry2.key] = entry2.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTSegmentation {
    return {
      CropGeometryData: isSet(object.CropGeometryData)
        ? CBCTSeriesGeometryData.fromJSON(object.CropGeometryData)
        : undefined,
      Landmarks: isObject(object.Landmarks)
        ? Object.entries(object.Landmarks).reduce<{
            [key: string]: FloatPoint3D;
          }>((acc, [key, value]) => {
            acc[key] = FloatPoint3D.fromJSON(value);
            return acc;
          }, {})
        : {},
    };
  },

  toJSON(message: ReportCBCTSegmentation): unknown {
    const obj: any = {};
    message.CropGeometryData !== undefined &&
      (obj.CropGeometryData = message.CropGeometryData
        ? CBCTSeriesGeometryData.toJSON(message.CropGeometryData)
        : undefined);
    obj.Landmarks = {};
    if (message.Landmarks) {
      Object.entries(message.Landmarks).forEach(([k, v]) => {
        obj.Landmarks[k] = FloatPoint3D.toJSON(v);
      });
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportCBCTSegmentation>, I>>(
    object: I,
  ): ReportCBCTSegmentation {
    const message = createBaseReportCBCTSegmentation();
    message.CropGeometryData =
      object.CropGeometryData !== undefined && object.CropGeometryData !== null
        ? CBCTSeriesGeometryData.fromPartial(object.CropGeometryData)
        : undefined;
    message.Landmarks = Object.entries(object.Landmarks ?? {}).reduce<{
      [key: string]: FloatPoint3D;
    }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = FloatPoint3D.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseReportCBCTSegmentation_LandmarksEntry(): ReportCBCTSegmentation_LandmarksEntry {
  return { key: '', value: undefined };
}

export const ReportCBCTSegmentation_LandmarksEntry = {
  encode(
    message: ReportCBCTSegmentation_LandmarksEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '') {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      FloatPoint3D.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportCBCTSegmentation_LandmarksEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTSegmentation_LandmarksEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = FloatPoint3D.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTSegmentation_LandmarksEntry {
    return {
      key: isSet(object.key) ? String(object.key) : '',
      value: isSet(object.value)
        ? FloatPoint3D.fromJSON(object.value)
        : undefined,
    };
  },

  toJSON(message: ReportCBCTSegmentation_LandmarksEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value
        ? FloatPoint3D.toJSON(message.value)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportCBCTSegmentation_LandmarksEntry>, I>,
  >(object: I): ReportCBCTSegmentation_LandmarksEntry {
    const message = createBaseReportCBCTSegmentation_LandmarksEntry();
    message.key = object.key ?? '';
    message.value =
      object.value !== undefined && object.value !== null
        ? FloatPoint3D.fromPartial(object.value)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
