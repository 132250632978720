import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useMedia } from '@/shared/hooks';

import { Portal } from '../Portal/Portal';
import { Button, ButtonProps } from '../Button/Button';
import { Icon } from '../Icon/Icon';

import styles from './Modal.module.scss';

export type ModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  onApply?: () => void;
  className?: string;
  testID?: string;
  containerClassName?: string;
  bodyClassName?: string;
  title?: string | JSX.Element;
  applyButtonText?: string | JSX.Element;
  cancelButtonText?: string | JSX.Element;
  shouldRenderCloseIconButton?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  applyButtonProps?: ButtonProps;
  children?: ReactNode;
  footer?: ReactNode;
  hideFooter?: boolean;
  borderless?: boolean;
};

export const Modal: FC<ModalProps> = ({
  isOpen,
  title,
  onClose = () => {},
  onApply = () => {},
  className,
  testID,
  bodyClassName,
  containerClassName,
  shouldRenderCloseIconButton = true,
  shouldCloseOnOverlayClick = true,
  applyButtonText = (
    <FormattedMessage id="global.apply" defaultMessage="Apply" />
  ),
  cancelButtonText = (
    <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
  ),
  applyButtonProps,
  children,
  footer,
  hideFooter = false,
  borderless = false,
}) => {
  const { isMobile } = useMedia();

  if (!isOpen) return null;

  return (
    <Portal>
      <div className={cn(styles.modal, className)} data-testid={testID}>
        <div
          className={styles.overlay}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(shouldCloseOnOverlayClick ? { onClick: onClose } : {})}
        />
        <div className={cn(styles.container, containerClassName)}>
          <header
            className={cn(styles.header, borderless && styles.borderless)}
          >
            <h2 className={cn(styles.title, isMobile ? 'h3' : 'h2')}>
              {title}
            </h2>
            {shouldRenderCloseIconButton && (
              <Icon
                className={styles.crossBtn}
                name="close"
                size={40}
                onClick={onClose}
              />
            )}
          </header>

          <div className={cn(styles.modalBody, bodyClassName)}>{children}</div>

          {hideFooter ? null : (
            <footer
              className={cn(styles.footer, borderless && styles.borderless)}
            >
              {footer || (
                <>
                  <Button variant="tertiary" size="medium" onClick={onClose}>
                    {cancelButtonText}
                  </Button>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <Button onClick={onApply} {...applyButtonProps}>
                    {applyButtonText}
                  </Button>
                </>
              )}
            </footer>
          )}
        </div>
      </div>
    </Portal>
  );
};
