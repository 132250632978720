import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Icon } from '@/shared/ui';

import { toothChartLegendsMessages } from '../../config/i18n';
import { ToothChartLegendsTypes } from '../../config/tooth.type';

import styles from './ToothChartLegends.module.scss';

const DEFAULT_TOOTH_CHART_LEGENDS: ToothChartLegendsTypes[] = [
  'unhealthy',
  'lowProbability',
  'treated',
  'healthy',
  'missing',
];

type ReportToothChartLegendsProps = {
  className?: string;
  toothChartLegends?: ToothChartLegendsTypes[];
};

export const ToothChartLegends: FC<ReportToothChartLegendsProps> = (props) => {
  const { toothChartLegends = DEFAULT_TOOTH_CHART_LEGENDS, className } = props;

  const { formatMessage } = useIntl();

  return (
    <ul className={cn(styles.container, className)}>
      {toothChartLegends.map((legend) => (
        <li className={cn(styles.legend)} key={legend}>
          <span className={cn(styles.legendTitle, 'p3t')}>
            {legend === 'missing' ? (
              <Icon name="close" size={16} className={styles.missingIcon} />
            ) : (
              <span className={cn(styles.icon, styles[legend])} />
            )}
            {formatMessage(toothChartLegendsMessages[legend])}
          </span>
        </li>
      ))}
    </ul>
  );
};
