import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ApproveReportToothReq,
  ApproveReportToothResp,
  CopyReportReq,
  CreateReportToothConditionsReq,
  CreateReportToothConditionsResp,
  DeepPartial,
  DeleteReportReq,
  DisApproveReportToothReq,
  DisApproveReportToothResp,
  ReportServiceClientImpl,
  RequestReportReq,
  ResetReportToothConditionsReq,
  ResetReportToothReq,
  SetReportConclusionReq,
  SetReportPanoramaViewSettingsReq,
  SetReportPrintSettingsReq,
  SetReportSettingsReq,
  SetReportToothCommentReq,
  SetReportToothConditionUserDecisionReq,
  SetReportToothConditionUserDecisionResp,
  SetReportToothControlReq,
  SetReportToothISONumberReq,
  SetReportToothMissingReq,
  SetReportToothSupernumeraryReq,
  SetROIReq,
  SignReportReq,
  VerifyReportToothDetectionsReq,
  SetROIResp,
  ResetReportToothResp,
  SetReportToothCommentResp,
  SignReportResp,
  SetReportConclusionResp,
  ApproveAllTeethAndSignReportReq,
  ApproveAllTeethAndSignReportResp,
} from '@/shared/api/protocol_gen/api/core/svc_report';
import api, { ApiError } from '@/shared/api/api';
import { createThunkGenerator, generateThunk } from '@/shared/lib';
import { SliceName } from '@/shared/config';

export type ReportRequests = keyof ReportServiceClientImpl;

const generateReportThunk = createThunkGenerator<ReportRequests>(
  SliceName.report,
);

export const setReportToothConditionUserDecision = generateReportThunk<
  SetReportToothConditionUserDecisionReq,
  SetReportToothConditionUserDecisionResp
>(
  'SetReportToothConditionUserDecision',
  api.report.SetReportToothConditionUserDecision,
);

export const createReportToothConditions = generateReportThunk<
  CreateReportToothConditionsReq,
  CreateReportToothConditionsResp
>('CreateReportToothConditions', api.report.CreateReportToothConditions);

// TODO: Refactor all thunks with createThunkGenerator
const requestGenerator = <Req>(requestName: ReportRequests) =>
  createAsyncThunk(
    `report/${requestName}`,
    async (request: DeepPartial<Req>, { rejectWithValue }) => {
      try {
        const response = await api.report[requestName](request);

        return response;
      } catch (error: unknown) {
        const { message, type } = error as ApiError;

        return rejectWithValue({ message, type });
      }
    },
  );

export const requestReport =
  requestGenerator<RequestReportReq>('RequestReport');

export const deleteReport = requestGenerator<DeleteReportReq>('DeleteReport');

export const setROI = generateThunk<SetROIReq, SetROIResp, ReportRequests>(
  SliceName.report,
  'SetROI',
  api.report.SetROI,
);

export const setReportToothControl = requestGenerator<SetReportToothControlReq>(
  'SetReportToothControl',
);

export const setReportConclusion = generateThunk<
  SetReportConclusionReq,
  SetReportConclusionResp,
  ReportRequests
>(SliceName.report, 'SetReportConclusion', api.report.SetReportConclusion);

export const setReportSettings =
  requestGenerator<SetReportSettingsReq>('SetReportSettings');

export const setReportPrintSettings =
  requestGenerator<SetReportPrintSettingsReq>('SetReportPrintSettings');

export const signReport = generateThunk<
  SignReportReq,
  SignReportResp,
  ReportRequests
>(SliceName.report, 'SignReport', api.report.SignReport);

export const copyReport = requestGenerator<CopyReportReq>('CopyReport');

export const setReportToothComment = generateThunk<
  SetReportToothCommentReq,
  SetReportToothCommentResp,
  ReportRequests
>(SliceName.report, 'SetReportToothComment', api.report.SetReportToothComment);

export const setReportToothISONumber =
  requestGenerator<SetReportToothISONumberReq>('SetReportToothISONumber');

export const setReportToothMissing = requestGenerator<SetReportToothMissingReq>(
  'SetReportToothMissing',
);

export const setReportToothSupernumerary =
  requestGenerator<SetReportToothSupernumeraryReq>(
    'SetReportToothSupernumerary',
  );

export const resetReportTooth = generateThunk<
  ResetReportToothReq,
  ResetReportToothResp,
  ReportRequests
>(SliceName.report, 'ResetReportTooth', api.report.ResetReportTooth);

export const resetReportToothConditions =
  requestGenerator<ResetReportToothConditionsReq>('ResetReportToothConditions');

export const setReportPanoramaViewSettings =
  requestGenerator<SetReportPanoramaViewSettingsReq>(
    'SetReportPanoramaViewSettings',
  );

export const verifyReportToothDetections =
  requestGenerator<VerifyReportToothDetectionsReq>(
    'VerifyReportToothDetections',
  );

export const approveAllTeethAndSignReport = generateReportThunk<
  ApproveAllTeethAndSignReportReq,
  ApproveAllTeethAndSignReportResp
>('ApproveAllTeethAndSignReport', api.report.ApproveAllTeethAndSignReport);

export const setReportToothApproved = generateReportThunk<
  ApproveReportToothReq,
  ApproveReportToothResp
>('ApproveReportTooth', api.report.ApproveReportTooth);

export const setReportToothDisapproved = generateReportThunk<
  DisApproveReportToothReq,
  DisApproveReportToothResp
>('DisApproveReportTooth', api.report.DisApproveReportTooth);
