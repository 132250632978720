import { DentalNotations, DentalNotation } from '../config/tooth.type';
import { UNIVERSAL_PERMANENT_TOOTH_NUMBERS } from '../config/toothConfig';

export const getDisplayToothNumber = (
  ISONumber: number | null,
  dentalNotation: DentalNotation,
): number => {
  if (dentalNotation === DentalNotations.UNIVERSAL) {
    return UNIVERSAL_PERMANENT_TOOTH_NUMBERS.indexOf(ISONumber) + 1;
  }

  return ISONumber;
};
