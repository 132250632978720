import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { SliceName } from '@/shared/config';
// Legacy for client
import notificationReducer from '@/entities/notification/model/notificationSlice';
import shopReducer from '@/entities/shop/model/shopSlice';
import billingReducer from '@/entities/billing/model/billingSlice';
import messageReducer from '@/entities/messenger/model/messengerSlice';
import userReducer from '@/entities/user/model/userSlice';
import authReducer from '@/entities/auth/model/authSlice';
import themeReducer from '@/entities/theme/model/themeSlice';
import reportsReducer from '@/entities/reports/model/reportSlice';
import patientReducer from '@/entities/patient/model/patientSlice';
import studyReducer from '@/entities/study/model/studySlice';
import organizationReducer from '@/entities/organization/model/organizationSlice';
import usersReducer from '@/entities/users/model/usersSlice';
import studyCountReducer from '@/entities/studyCount/model/studyCountSlice';
import accessReducer from '@/entities/access/model/accessSlice';
import conditionReducer from '@/entities/condition/model/conditionSlice';
import toothReducer from '@/entities/tooth/model/toothSlice';
import modalReducer from '@/entities/modal/model/modalSlice';
import assetsReduce from '@/entities/assets/model/assetsSlice';

import { errorMiddleware } from './errorMiddleware';

const appReducer = combineReducers({
  [SliceName.access]: accessReducer,
  notification: notificationReducer,
  shop: shopReducer,
  billing: billingReducer,
  message: messageReducer,
  user: userReducer,
  [SliceName.auth]: authReducer,
  theme: themeReducer,
  reports: reportsReducer,
  patient: patientReducer,
  study: studyReducer,
  organization: organizationReducer,
  users: usersReducer,
  studyCount: studyCountReducer,
  [SliceName.condition]: conditionReducer,
  tooth: toothReducer,
  [SliceName.modal]: modalReducer,
  [SliceName.assets]: assetsReduce,
});

const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction,
) => {
  if (action.type === 'store/reset') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(errorMiddleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
