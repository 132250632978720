import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import difference from 'lodash/difference';

import { TeethChart } from '@/features/toothChart';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import {
  ToothChartLegendsTypes,
  toothModel,
  useToothProps,
  ToothChartTabs,
  ToothChartLegends,
} from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';
import { EditToothNumber } from '@/features/editToothNumber';
import { ChooseToothRegion } from '@/features/chooseToothRegion';
import { Button } from '@/shared/ui';

import styles from './ReportToothChart.module.scss';

type ReportToothChartProps = {
  className?: string;
};

const toothChartTabs: ToothChartLegendsTypes[] = [
  'unhealthy',
  'lowProbability',
  'treated',
  'healthy',
  'missing',
  'all',
];

const toothChartLegends: ToothChartLegendsTypes[] = toothChartTabs.filter(
  (legend) => legend !== 'all',
);

export const ReportToothChart: FC<ReportToothChartProps> = (props) => {
  const { className } = props;

  const dispatch = useAppDispatch();

  const { reportID } = useParams();

  const [ROIList, setROIList] = useState<number[]>([]);
  const [isROIOpen, setROIOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<ToothChartLegendsTypes[]>(
    [],
  );

  const allLegends: ToothChartLegendsTypes[] = [
    'unhealthy',
    'lowProbability',
    'treated',
    'healthy',
    'missing',
  ];

  const currentReportTeeth = useAppSelector((state) =>
    toothModel.selectors.selectByReportID(state, reportID),
  );

  const ROI = useAppSelector((state) =>
    toothModel.selectors.selectISONumbersByReportIDAndROI(state, reportID),
  );

  const { teethWithStatus, counters } = useToothProps(currentReportTeeth);

  useEffect(() => {
    setROIList(ROI);
  }, [ROI]);

  const setChooseRegion = () => {
    setROIOpen(true);

    const currentFilters = allLegends.filter((legend) =>
      counters[legend].every((counter) => ROIList.includes(counter)),
    );

    setActiveFilters(currentFilters);
  };

  const selectedToothIds = useAppSelector((state) =>
    toothModel.selectors.selectIDsByReportIDAndROIISONumbers(state, {
      reportID,
      ROIISONumbers: ROIList,
    }),
  );

  const onSaveClick = async () => {
    const newROI = await dispatch(
      reportsModel.thunks.setROI({
        ReportID: reportID,
        ROIToothIDs: selectedToothIds,
      }),
    ).unwrap();

    dispatch(toothModel.actions.setMany(newROI.AllReportTeeth));

    // TODO: add error handler everywhere

    setROIOpen(false);
  };

  const handleAllLegend = () => {
    setActiveFilters((prev) => {
      if (allLegends.every((legend) => prev.includes(legend))) {
        setROIList([]);

        return [];
      }

      Object.values(teethWithStatus).forEach((tooth) => {
        setROIList((prevROI) => [...prevROI, tooth.ISONumber]);
      });

      return allLegends;
    });
  };

  const handleLegendAdd = (legend: ToothChartLegendsTypes) => {
    setActiveFilters((prev) => {
      const filteredItems = prev.filter((item) => item !== 'all');

      const newROI = Object.values(teethWithStatus).reduce((acc, tooth) => {
        if (tooth.toothStatus === legend) {
          acc.push(tooth.ISONumber);
        }

        return acc;
      }, [] as number[]);

      setROIList((prevROI) => [...prevROI, ...newROI]);

      return [...filteredItems, legend];
    });
  };

  const handleLegendRemove = (legend: ToothChartLegendsTypes) => {
    setActiveFilters((prev) => {
      const ROIForDelete = Object.values(teethWithStatus).reduce(
        (acc, tooth) => {
          if (tooth.toothStatus === legend) {
            acc.push(tooth.ISONumber);
          }

          return acc;
        },
        [] as number[],
      );

      setROIList((prevROI) => difference(prevROI, ROIForDelete));

      return prev.filter((item) => item !== legend);
    });
  };

  const onLegendClick = (legend: ToothChartLegendsTypes) => {
    if (legend === 'all') {
      handleAllLegend();
    }

    if (legend !== 'all' && !activeFilters.includes(legend)) {
      handleLegendAdd(legend);
    }

    if (activeFilters.includes(legend)) {
      handleLegendRemove(legend);
    }
  };

  if (!currentReportTeeth.length) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      {!isROIOpen && (
        <div className={styles.buttons}>
          <EditToothNumber />
          <ChooseToothRegion onClick={setChooseRegion} />
        </div>
      )}
      {isROIOpen && (
        <div className={styles.roi}>
          <ToothChartTabs
            className={styles.toothChartLegends}
            counters={counters}
            toothChartTabs={toothChartTabs}
            ROIList={ROIList}
            onClick={onLegendClick}
          />
          <Button variant="secondary" size="small" onClick={onSaveClick}>
            <FormattedMessage id="global.save" defaultMessage="Save" />
          </Button>
        </div>
      )}
      <TeethChart
        teeth={currentReportTeeth}
        ROIList={ROIList}
        isROIOpen={isROIOpen}
        setROIList={setROIList}
      />
      <ToothChartLegends toothChartLegends={toothChartLegends} />
    </div>
  );
};
