import {
  AuthenticationClientImpl, LogoutReq,
  RefreshResp,
  SignInReq,
  SignUpReq,
  VerifyResp,
  WhoAmIResp,
} from '@/shared/api/protocol_gen/api/auth/svc_authentication';
import { createThunkGenerator } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import api from '@/shared/api/api';
import { AuthState, Secret } from '@/shared/api/protocol_gen/api/auth/dto_auth';
import { Empty } from '@/shared/api/protocol_gen/google/protobuf/empty';

export type AuthRequests = keyof AuthenticationClientImpl;

const generateAuthThunk = createThunkGenerator<AuthRequests>(SliceName.auth);

export const refresh = generateAuthThunk<Empty, RefreshResp>(
  'Refresh',
  api.auth.Refresh,
);

export const whoAmI = generateAuthThunk<Empty, WhoAmIResp>(
  'WhoAmI',
  api.auth.WhoAmI,
);

export const getAuthStatus = generateAuthThunk<Empty, AuthState>(
  'GetAuthenticationStatus',
  api.auth.GetAuthenticationStatus,
);

export const startSignIn = generateAuthThunk<SignInReq, AuthState>(
  'StartSignIn',
  api.auth.StartSignIn,
);

export const startSignUp = generateAuthThunk<SignUpReq, AuthState>(
  'StartSignUp',
  api.auth.StartSignUp,
);

export const verify = generateAuthThunk<Secret, VerifyResp>(
  'Verify',
  api.auth.Verify,
);

export const logout = generateAuthThunk<LogoutReq, Empty>(
  'Logout',
  api.auth.Logout,
);
