/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

/** A lot of Attributes were added erroneously and will be phased out. */
export enum ConditionCode {
  /** UnspecifiedCondition - unacceptable value */
  UnspecifiedCondition = 0,
  /** CodeTooth - @exclude detected/model-relevant conditions */
  CodeTooth = 1000,
  /** Missing - empty tooth socket */
  Missing = 1001,
  Implant = 1003,
  Pontic = 1004,
  RootFragment = 1057,
  /** PartiallyVisible - tooth of tooth-like object is partially visible */
  PartiallyVisible = 1002,
  Filling = 1007,
  Hypercementosis = 1008,
  Impaction = 1009,
  Roots1 = 1010,
  Roots2 = 1011,
  Roots3 = 1012,
  Roots4 = 1013,
  Canals1 = 1014,
  Canals2 = 1015,
  Canals3 = 1016,
  Canals4 = 1017,
  Canals5 = 1018,
  ArtificialCrown = 1019,
  Inlay = 1020,
  CariesSigns = 1021,
  /** CrownDefectOver50pct - crown_defect_over_50pct does not exclude caries_signs, unlike now */
  CrownDefectOver50pct = 1022,
  EctopicDevelopmentAndEruption = 1023,
  EndoTreated = 1024,
  EndoRootPerforation = 1025,
  EndoVerticalRootFracture = 1026,
  EndoCastPostAndCore = 1027,
  EndoFiberPost = 1028,
  EndoMetalPost = 1029,
  EndoMissedCanal = 1030,
  EndoOverfilling = 1031,
  EndoShortFilling = 1032,
  EndoRootCanalWithRestriction = 1033,
  EndoVoidsPresentInTheRootFilling = 1034,
  EndoFracturedInstrumentInRootCanal = 1035,
  /** EndoAdequateObturation - property of `endo_treaded` ??? */
  EndoAdequateObturation = 1047,
  /** EndoAdequateDensity - property of `endo_treaded` ??? */
  EndoAdequateDensity = 1048,
  InternalResorption = 1036,
  OrthodonticAppliance = 1037,
  PeriodontalLigamentSpaceWideningAlongRoot = 1042,
  FurcationalRadiolucency = 1043,
  PulpStones = 1044,
  SecondaryOrTertriaryDentin = 1045,
  SurfaceResorption = 1046,
  HorizontalRootFracture = 1051,
  CrownFracture = 1052,
  /** Abfraction - also known as `wedge_shaped_defect` */
  Abfraction = 1053,
  PeriapicalLesion = 1072,
  AbnormalityOfSizeAndForm = 1058,
  DiscolorationOfTooth = 1059,
  ExcessiveAttritionOfTooth = 1060,
  Pulpitis = 1061,
  FailureOfEndoTreatment = 1062,
  PostAndCore = 1063,
  Abutment = 1064,
  PeriImplantitis = 1065,
  PeriImplantMucosis = 1066,
  FailureOfOsseointegrationOfImplant = 1067,
  DentalCalculus = 1068,
  GingivalRecession = 1069,
  BleedingGums = 1070,
  ExcessiveToothMobility = 1071,
  Ankylosis = 1073,
  Spacing = 1074,
  HorizontalDisplacement = 1075,
  Crowding = 1076,
  Overeruption = 1077,
  SupernumeraryTooth = 1078,
  Avulsion = 1079,
  FailureOfRestoration = 1081,
  DentalSealant = 1082,
  OpenMargin = 1083,
  Overhang = 1084,
  DentalRestorationLost = 1085,
  LackOfInterproximalContact = 1086,
  SecondaryCaries = 1087,
  /** CrownDefect - group condition; includes `caries_signs`, `chipped_tooth`, etc. */
  CrownDefect = 1089,
  CanalFilling = 1090,
  /** Post - TODO: What is it? */
  Post = 1091,
  PeriodontalBoneLoss = 1092,
  EndodonticAccess = 1148,
  Pulpotomy = 1149,
  Clamp = 1150,
  ForeignBody = 1151,
  EndodonticInstrument = 1152,
  IndirectRestoration = 1181,
  ChippedTooth = 1182,
  FillingLeakage = 1183,
  LateralRadiolucency = 1185,
  Apicoectomy = 1189,
  ToothGerm = 1197,
  /** SinusPathology - Maxillofacial pathologies (but writing `maxfax is redundant`) */
  SinusPathology = 1200,
  BoneStructureDisorder = 1201,
  PreparedTooth = 1204,
  VoidsInRestoration = 1205,
  /** Child_DefectDepth_NotSelected - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectDepth_NotSelected = 5000,
  /** Child_DefectDepth_Initial - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectDepth_Initial = 5001,
  /** Child_DefectDepth_Enamel - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectDepth_Enamel = 5002,
  /** Child_DefectDepth_Dentin - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectDepth_Dentin = 5003,
  /** Child_DefectDepth_Pulp - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectDepth_Pulp = 5004,
  /** Child_DefectDepth_Root - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectDepth_Root = 5005,
  /** Child_DefectSurface_NotSelected - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectSurface_NotSelected = 5006,
  /** Child_DefectSurface_Mesial - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectSurface_Mesial = 5007,
  /** Child_DefectSurface_Distal - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectSurface_Distal = 5008,
  /** Child_DefectSurface_Lingual - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectSurface_Lingual = 5009,
  /** Child_DefectSurface_Buccal - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectSurface_Buccal = 5010,
  /** Child_DefectSurface_Occlusial - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectSurface_Occlusial = 5011,
  /** Child_DefectSurface_Vestibular - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectSurface_Vestibular = 5012,
  /** Child_DefectSurface_Incisal - Parent conditions: caries_signs (1021) || secondary_caries (1087) || filling (1007) || chipped_tooth (1182) */
  Child_DefectSurface_Incisal = 5013,
  /** Child_PeriapicalLesion_Communication_Sinus - Parent conditions: periapical_lesion (1072) */
  Child_PeriapicalLesion_Communication_Sinus = 5014,
  /** Child_PeriapicalLesion_Communication_Canal - Parent conditions: periapical_lesion (1072) */
  Child_PeriapicalLesion_Communication_Canal = 5015,
  /** Child_PeriapicalLesion_Subtype_Radiopacity - Parent conditions: periapical_lesion (1072) */
  Child_PeriapicalLesion_Subtype_Radiopacity = 5016,
  /** Child_PeriapicalLesion_Subtype_Radiolucency - Parent conditions: periapical_lesion (1072) */
  Child_PeriapicalLesion_Subtype_Radiolucency = 5017,
  /** Child_PeriapicalLesion_Subtype_LigamentSpaceWideningInPeriapicalRegion - Parent conditions: periapical_lesion (1072) */
  Child_PeriapicalLesion_Subtype_LigamentSpaceWideningInPeriapicalRegion = 5018,
  /** Child_PeriodontalBoneLoss_Severity_Mild - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Severity_Mild = 5019,
  /** Child_PeriodontalBoneLoss_Severity_Moderate - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Severity_Moderate = 5020,
  /** Child_PeriodontalBoneLoss_Severity_Severe - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Severity_Severe = 5021,
  /** Child_PeriodontalBoneLoss_Shape_Vertical - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Shape_Vertical = 5022,
  /** Child_PeriodontalBoneLoss_Shape_Horizontal - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Shape_Horizontal = 5023,
  /** Child_PeriodontalBoneLoss_Shape_Mixed - Parent conditions: periodontal_bone_loss (1092) */
  Child_PeriodontalBoneLoss_Shape_Mixed = 5024,
  /** Child_Filling_BuildUp - Parent conditions: filling (1007) */
  Child_Filling_BuildUp = 5025,
  /** Child_BoneStructureDisorder_Subtype_NotSelected - Parent conditions: maxfax_lesion (1201) */
  Child_BoneStructureDisorder_Subtype_NotSelected = 5026,
  /** Child_BoneStructureDisorder_Subtype_Radiopacity - Parent conditions: maxfax_lesion (1201) */
  Child_BoneStructureDisorder_Subtype_Radiopacity = 5027,
  /** Child_BoneStructureDisorder_Subtype_Radiolucency - Parent conditions: maxfax_lesion (1201) */
  Child_BoneStructureDisorder_Subtype_Radiolucency = 5028,
  /** Child_BoneStructureDisorder_Subtype_Mixed - Parent conditions: maxfax_lesion (1201) */
  Child_BoneStructureDisorder_Subtype_Mixed = 5029,
  /** Child_Impaction_Subtype_CompleteBony - Parent conditions: impaction (1009) */
  Child_Impaction_Subtype_CompleteBony = 5030,
  /** Child_Impaction_Subtype_PartialBony - Parent conditions: impaction (1009) */
  Child_Impaction_Subtype_PartialBony = 5031,
  UNRECOGNIZED = -1,
}

export function conditionCodeFromJSON(object: any): ConditionCode {
  switch (object) {
    case 0:
    case 'UnspecifiedCondition':
      return ConditionCode.UnspecifiedCondition;
    case 1000:
    case 'CodeTooth':
      return ConditionCode.CodeTooth;
    case 1001:
    case 'Missing':
      return ConditionCode.Missing;
    case 1003:
    case 'Implant':
      return ConditionCode.Implant;
    case 1004:
    case 'Pontic':
      return ConditionCode.Pontic;
    case 1057:
    case 'RootFragment':
      return ConditionCode.RootFragment;
    case 1002:
    case 'PartiallyVisible':
      return ConditionCode.PartiallyVisible;
    case 1007:
    case 'Filling':
      return ConditionCode.Filling;
    case 1008:
    case 'Hypercementosis':
      return ConditionCode.Hypercementosis;
    case 1009:
    case 'Impaction':
      return ConditionCode.Impaction;
    case 1010:
    case 'Roots1':
      return ConditionCode.Roots1;
    case 1011:
    case 'Roots2':
      return ConditionCode.Roots2;
    case 1012:
    case 'Roots3':
      return ConditionCode.Roots3;
    case 1013:
    case 'Roots4':
      return ConditionCode.Roots4;
    case 1014:
    case 'Canals1':
      return ConditionCode.Canals1;
    case 1015:
    case 'Canals2':
      return ConditionCode.Canals2;
    case 1016:
    case 'Canals3':
      return ConditionCode.Canals3;
    case 1017:
    case 'Canals4':
      return ConditionCode.Canals4;
    case 1018:
    case 'Canals5':
      return ConditionCode.Canals5;
    case 1019:
    case 'ArtificialCrown':
      return ConditionCode.ArtificialCrown;
    case 1020:
    case 'Inlay':
      return ConditionCode.Inlay;
    case 1021:
    case 'CariesSigns':
      return ConditionCode.CariesSigns;
    case 1022:
    case 'CrownDefectOver50pct':
      return ConditionCode.CrownDefectOver50pct;
    case 1023:
    case 'EctopicDevelopmentAndEruption':
      return ConditionCode.EctopicDevelopmentAndEruption;
    case 1024:
    case 'EndoTreated':
      return ConditionCode.EndoTreated;
    case 1025:
    case 'EndoRootPerforation':
      return ConditionCode.EndoRootPerforation;
    case 1026:
    case 'EndoVerticalRootFracture':
      return ConditionCode.EndoVerticalRootFracture;
    case 1027:
    case 'EndoCastPostAndCore':
      return ConditionCode.EndoCastPostAndCore;
    case 1028:
    case 'EndoFiberPost':
      return ConditionCode.EndoFiberPost;
    case 1029:
    case 'EndoMetalPost':
      return ConditionCode.EndoMetalPost;
    case 1030:
    case 'EndoMissedCanal':
      return ConditionCode.EndoMissedCanal;
    case 1031:
    case 'EndoOverfilling':
      return ConditionCode.EndoOverfilling;
    case 1032:
    case 'EndoShortFilling':
      return ConditionCode.EndoShortFilling;
    case 1033:
    case 'EndoRootCanalWithRestriction':
      return ConditionCode.EndoRootCanalWithRestriction;
    case 1034:
    case 'EndoVoidsPresentInTheRootFilling':
      return ConditionCode.EndoVoidsPresentInTheRootFilling;
    case 1035:
    case 'EndoFracturedInstrumentInRootCanal':
      return ConditionCode.EndoFracturedInstrumentInRootCanal;
    case 1047:
    case 'EndoAdequateObturation':
      return ConditionCode.EndoAdequateObturation;
    case 1048:
    case 'EndoAdequateDensity':
      return ConditionCode.EndoAdequateDensity;
    case 1036:
    case 'InternalResorption':
      return ConditionCode.InternalResorption;
    case 1037:
    case 'OrthodonticAppliance':
      return ConditionCode.OrthodonticAppliance;
    case 1042:
    case 'PeriodontalLigamentSpaceWideningAlongRoot':
      return ConditionCode.PeriodontalLigamentSpaceWideningAlongRoot;
    case 1043:
    case 'FurcationalRadiolucency':
      return ConditionCode.FurcationalRadiolucency;
    case 1044:
    case 'PulpStones':
      return ConditionCode.PulpStones;
    case 1045:
    case 'SecondaryOrTertriaryDentin':
      return ConditionCode.SecondaryOrTertriaryDentin;
    case 1046:
    case 'SurfaceResorption':
      return ConditionCode.SurfaceResorption;
    case 1051:
    case 'HorizontalRootFracture':
      return ConditionCode.HorizontalRootFracture;
    case 1052:
    case 'CrownFracture':
      return ConditionCode.CrownFracture;
    case 1053:
    case 'Abfraction':
      return ConditionCode.Abfraction;
    case 1072:
    case 'PeriapicalLesion':
      return ConditionCode.PeriapicalLesion;
    case 1058:
    case 'AbnormalityOfSizeAndForm':
      return ConditionCode.AbnormalityOfSizeAndForm;
    case 1059:
    case 'DiscolorationOfTooth':
      return ConditionCode.DiscolorationOfTooth;
    case 1060:
    case 'ExcessiveAttritionOfTooth':
      return ConditionCode.ExcessiveAttritionOfTooth;
    case 1061:
    case 'Pulpitis':
      return ConditionCode.Pulpitis;
    case 1062:
    case 'FailureOfEndoTreatment':
      return ConditionCode.FailureOfEndoTreatment;
    case 1063:
    case 'PostAndCore':
      return ConditionCode.PostAndCore;
    case 1064:
    case 'Abutment':
      return ConditionCode.Abutment;
    case 1065:
    case 'PeriImplantitis':
      return ConditionCode.PeriImplantitis;
    case 1066:
    case 'PeriImplantMucosis':
      return ConditionCode.PeriImplantMucosis;
    case 1067:
    case 'FailureOfOsseointegrationOfImplant':
      return ConditionCode.FailureOfOsseointegrationOfImplant;
    case 1068:
    case 'DentalCalculus':
      return ConditionCode.DentalCalculus;
    case 1069:
    case 'GingivalRecession':
      return ConditionCode.GingivalRecession;
    case 1070:
    case 'BleedingGums':
      return ConditionCode.BleedingGums;
    case 1071:
    case 'ExcessiveToothMobility':
      return ConditionCode.ExcessiveToothMobility;
    case 1073:
    case 'Ankylosis':
      return ConditionCode.Ankylosis;
    case 1074:
    case 'Spacing':
      return ConditionCode.Spacing;
    case 1075:
    case 'HorizontalDisplacement':
      return ConditionCode.HorizontalDisplacement;
    case 1076:
    case 'Crowding':
      return ConditionCode.Crowding;
    case 1077:
    case 'Overeruption':
      return ConditionCode.Overeruption;
    case 1078:
    case 'SupernumeraryTooth':
      return ConditionCode.SupernumeraryTooth;
    case 1079:
    case 'Avulsion':
      return ConditionCode.Avulsion;
    case 1081:
    case 'FailureOfRestoration':
      return ConditionCode.FailureOfRestoration;
    case 1082:
    case 'DentalSealant':
      return ConditionCode.DentalSealant;
    case 1083:
    case 'OpenMargin':
      return ConditionCode.OpenMargin;
    case 1084:
    case 'Overhang':
      return ConditionCode.Overhang;
    case 1085:
    case 'DentalRestorationLost':
      return ConditionCode.DentalRestorationLost;
    case 1086:
    case 'LackOfInterproximalContact':
      return ConditionCode.LackOfInterproximalContact;
    case 1087:
    case 'SecondaryCaries':
      return ConditionCode.SecondaryCaries;
    case 1089:
    case 'CrownDefect':
      return ConditionCode.CrownDefect;
    case 1090:
    case 'CanalFilling':
      return ConditionCode.CanalFilling;
    case 1091:
    case 'Post':
      return ConditionCode.Post;
    case 1092:
    case 'PeriodontalBoneLoss':
      return ConditionCode.PeriodontalBoneLoss;
    case 1148:
    case 'EndodonticAccess':
      return ConditionCode.EndodonticAccess;
    case 1149:
    case 'Pulpotomy':
      return ConditionCode.Pulpotomy;
    case 1150:
    case 'Clamp':
      return ConditionCode.Clamp;
    case 1151:
    case 'ForeignBody':
      return ConditionCode.ForeignBody;
    case 1152:
    case 'EndodonticInstrument':
      return ConditionCode.EndodonticInstrument;
    case 1181:
    case 'IndirectRestoration':
      return ConditionCode.IndirectRestoration;
    case 1182:
    case 'ChippedTooth':
      return ConditionCode.ChippedTooth;
    case 1183:
    case 'FillingLeakage':
      return ConditionCode.FillingLeakage;
    case 1185:
    case 'LateralRadiolucency':
      return ConditionCode.LateralRadiolucency;
    case 1189:
    case 'Apicoectomy':
      return ConditionCode.Apicoectomy;
    case 1197:
    case 'ToothGerm':
      return ConditionCode.ToothGerm;
    case 1200:
    case 'SinusPathology':
      return ConditionCode.SinusPathology;
    case 1201:
    case 'BoneStructureDisorder':
      return ConditionCode.BoneStructureDisorder;
    case 1204:
    case 'PreparedTooth':
      return ConditionCode.PreparedTooth;
    case 1205:
    case 'VoidsInRestoration':
      return ConditionCode.VoidsInRestoration;
    case 5000:
    case 'Child_DefectDepth_NotSelected':
      return ConditionCode.Child_DefectDepth_NotSelected;
    case 5001:
    case 'Child_DefectDepth_Initial':
      return ConditionCode.Child_DefectDepth_Initial;
    case 5002:
    case 'Child_DefectDepth_Enamel':
      return ConditionCode.Child_DefectDepth_Enamel;
    case 5003:
    case 'Child_DefectDepth_Dentin':
      return ConditionCode.Child_DefectDepth_Dentin;
    case 5004:
    case 'Child_DefectDepth_Pulp':
      return ConditionCode.Child_DefectDepth_Pulp;
    case 5005:
    case 'Child_DefectDepth_Root':
      return ConditionCode.Child_DefectDepth_Root;
    case 5006:
    case 'Child_DefectSurface_NotSelected':
      return ConditionCode.Child_DefectSurface_NotSelected;
    case 5007:
    case 'Child_DefectSurface_Mesial':
      return ConditionCode.Child_DefectSurface_Mesial;
    case 5008:
    case 'Child_DefectSurface_Distal':
      return ConditionCode.Child_DefectSurface_Distal;
    case 5009:
    case 'Child_DefectSurface_Lingual':
      return ConditionCode.Child_DefectSurface_Lingual;
    case 5010:
    case 'Child_DefectSurface_Buccal':
      return ConditionCode.Child_DefectSurface_Buccal;
    case 5011:
    case 'Child_DefectSurface_Occlusial':
      return ConditionCode.Child_DefectSurface_Occlusial;
    case 5012:
    case 'Child_DefectSurface_Vestibular':
      return ConditionCode.Child_DefectSurface_Vestibular;
    case 5013:
    case 'Child_DefectSurface_Incisal':
      return ConditionCode.Child_DefectSurface_Incisal;
    case 5014:
    case 'Child_PeriapicalLesion_Communication_Sinus':
      return ConditionCode.Child_PeriapicalLesion_Communication_Sinus;
    case 5015:
    case 'Child_PeriapicalLesion_Communication_Canal':
      return ConditionCode.Child_PeriapicalLesion_Communication_Canal;
    case 5016:
    case 'Child_PeriapicalLesion_Subtype_Radiopacity':
      return ConditionCode.Child_PeriapicalLesion_Subtype_Radiopacity;
    case 5017:
    case 'Child_PeriapicalLesion_Subtype_Radiolucency':
      return ConditionCode.Child_PeriapicalLesion_Subtype_Radiolucency;
    case 5018:
    case 'Child_PeriapicalLesion_Subtype_LigamentSpaceWideningInPeriapicalRegion':
      return ConditionCode.Child_PeriapicalLesion_Subtype_LigamentSpaceWideningInPeriapicalRegion;
    case 5019:
    case 'Child_PeriodontalBoneLoss_Severity_Mild':
      return ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild;
    case 5020:
    case 'Child_PeriodontalBoneLoss_Severity_Moderate':
      return ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate;
    case 5021:
    case 'Child_PeriodontalBoneLoss_Severity_Severe':
      return ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe;
    case 5022:
    case 'Child_PeriodontalBoneLoss_Shape_Vertical':
      return ConditionCode.Child_PeriodontalBoneLoss_Shape_Vertical;
    case 5023:
    case 'Child_PeriodontalBoneLoss_Shape_Horizontal':
      return ConditionCode.Child_PeriodontalBoneLoss_Shape_Horizontal;
    case 5024:
    case 'Child_PeriodontalBoneLoss_Shape_Mixed':
      return ConditionCode.Child_PeriodontalBoneLoss_Shape_Mixed;
    case 5025:
    case 'Child_Filling_BuildUp':
      return ConditionCode.Child_Filling_BuildUp;
    case 5026:
    case 'Child_BoneStructureDisorder_Subtype_NotSelected':
      return ConditionCode.Child_BoneStructureDisorder_Subtype_NotSelected;
    case 5027:
    case 'Child_BoneStructureDisorder_Subtype_Radiopacity':
      return ConditionCode.Child_BoneStructureDisorder_Subtype_Radiopacity;
    case 5028:
    case 'Child_BoneStructureDisorder_Subtype_Radiolucency':
      return ConditionCode.Child_BoneStructureDisorder_Subtype_Radiolucency;
    case 5029:
    case 'Child_BoneStructureDisorder_Subtype_Mixed':
      return ConditionCode.Child_BoneStructureDisorder_Subtype_Mixed;
    case 5030:
    case 'Child_Impaction_Subtype_CompleteBony':
      return ConditionCode.Child_Impaction_Subtype_CompleteBony;
    case 5031:
    case 'Child_Impaction_Subtype_PartialBony':
      return ConditionCode.Child_Impaction_Subtype_PartialBony;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ConditionCode.UNRECOGNIZED;
  }
}

export function conditionCodeToJSON(object: ConditionCode): string {
  switch (object) {
    case ConditionCode.UnspecifiedCondition:
      return 'UnspecifiedCondition';
    case ConditionCode.CodeTooth:
      return 'CodeTooth';
    case ConditionCode.Missing:
      return 'Missing';
    case ConditionCode.Implant:
      return 'Implant';
    case ConditionCode.Pontic:
      return 'Pontic';
    case ConditionCode.RootFragment:
      return 'RootFragment';
    case ConditionCode.PartiallyVisible:
      return 'PartiallyVisible';
    case ConditionCode.Filling:
      return 'Filling';
    case ConditionCode.Hypercementosis:
      return 'Hypercementosis';
    case ConditionCode.Impaction:
      return 'Impaction';
    case ConditionCode.Roots1:
      return 'Roots1';
    case ConditionCode.Roots2:
      return 'Roots2';
    case ConditionCode.Roots3:
      return 'Roots3';
    case ConditionCode.Roots4:
      return 'Roots4';
    case ConditionCode.Canals1:
      return 'Canals1';
    case ConditionCode.Canals2:
      return 'Canals2';
    case ConditionCode.Canals3:
      return 'Canals3';
    case ConditionCode.Canals4:
      return 'Canals4';
    case ConditionCode.Canals5:
      return 'Canals5';
    case ConditionCode.ArtificialCrown:
      return 'ArtificialCrown';
    case ConditionCode.Inlay:
      return 'Inlay';
    case ConditionCode.CariesSigns:
      return 'CariesSigns';
    case ConditionCode.CrownDefectOver50pct:
      return 'CrownDefectOver50pct';
    case ConditionCode.EctopicDevelopmentAndEruption:
      return 'EctopicDevelopmentAndEruption';
    case ConditionCode.EndoTreated:
      return 'EndoTreated';
    case ConditionCode.EndoRootPerforation:
      return 'EndoRootPerforation';
    case ConditionCode.EndoVerticalRootFracture:
      return 'EndoVerticalRootFracture';
    case ConditionCode.EndoCastPostAndCore:
      return 'EndoCastPostAndCore';
    case ConditionCode.EndoFiberPost:
      return 'EndoFiberPost';
    case ConditionCode.EndoMetalPost:
      return 'EndoMetalPost';
    case ConditionCode.EndoMissedCanal:
      return 'EndoMissedCanal';
    case ConditionCode.EndoOverfilling:
      return 'EndoOverfilling';
    case ConditionCode.EndoShortFilling:
      return 'EndoShortFilling';
    case ConditionCode.EndoRootCanalWithRestriction:
      return 'EndoRootCanalWithRestriction';
    case ConditionCode.EndoVoidsPresentInTheRootFilling:
      return 'EndoVoidsPresentInTheRootFilling';
    case ConditionCode.EndoFracturedInstrumentInRootCanal:
      return 'EndoFracturedInstrumentInRootCanal';
    case ConditionCode.EndoAdequateObturation:
      return 'EndoAdequateObturation';
    case ConditionCode.EndoAdequateDensity:
      return 'EndoAdequateDensity';
    case ConditionCode.InternalResorption:
      return 'InternalResorption';
    case ConditionCode.OrthodonticAppliance:
      return 'OrthodonticAppliance';
    case ConditionCode.PeriodontalLigamentSpaceWideningAlongRoot:
      return 'PeriodontalLigamentSpaceWideningAlongRoot';
    case ConditionCode.FurcationalRadiolucency:
      return 'FurcationalRadiolucency';
    case ConditionCode.PulpStones:
      return 'PulpStones';
    case ConditionCode.SecondaryOrTertriaryDentin:
      return 'SecondaryOrTertriaryDentin';
    case ConditionCode.SurfaceResorption:
      return 'SurfaceResorption';
    case ConditionCode.HorizontalRootFracture:
      return 'HorizontalRootFracture';
    case ConditionCode.CrownFracture:
      return 'CrownFracture';
    case ConditionCode.Abfraction:
      return 'Abfraction';
    case ConditionCode.PeriapicalLesion:
      return 'PeriapicalLesion';
    case ConditionCode.AbnormalityOfSizeAndForm:
      return 'AbnormalityOfSizeAndForm';
    case ConditionCode.DiscolorationOfTooth:
      return 'DiscolorationOfTooth';
    case ConditionCode.ExcessiveAttritionOfTooth:
      return 'ExcessiveAttritionOfTooth';
    case ConditionCode.Pulpitis:
      return 'Pulpitis';
    case ConditionCode.FailureOfEndoTreatment:
      return 'FailureOfEndoTreatment';
    case ConditionCode.PostAndCore:
      return 'PostAndCore';
    case ConditionCode.Abutment:
      return 'Abutment';
    case ConditionCode.PeriImplantitis:
      return 'PeriImplantitis';
    case ConditionCode.PeriImplantMucosis:
      return 'PeriImplantMucosis';
    case ConditionCode.FailureOfOsseointegrationOfImplant:
      return 'FailureOfOsseointegrationOfImplant';
    case ConditionCode.DentalCalculus:
      return 'DentalCalculus';
    case ConditionCode.GingivalRecession:
      return 'GingivalRecession';
    case ConditionCode.BleedingGums:
      return 'BleedingGums';
    case ConditionCode.ExcessiveToothMobility:
      return 'ExcessiveToothMobility';
    case ConditionCode.Ankylosis:
      return 'Ankylosis';
    case ConditionCode.Spacing:
      return 'Spacing';
    case ConditionCode.HorizontalDisplacement:
      return 'HorizontalDisplacement';
    case ConditionCode.Crowding:
      return 'Crowding';
    case ConditionCode.Overeruption:
      return 'Overeruption';
    case ConditionCode.SupernumeraryTooth:
      return 'SupernumeraryTooth';
    case ConditionCode.Avulsion:
      return 'Avulsion';
    case ConditionCode.FailureOfRestoration:
      return 'FailureOfRestoration';
    case ConditionCode.DentalSealant:
      return 'DentalSealant';
    case ConditionCode.OpenMargin:
      return 'OpenMargin';
    case ConditionCode.Overhang:
      return 'Overhang';
    case ConditionCode.DentalRestorationLost:
      return 'DentalRestorationLost';
    case ConditionCode.LackOfInterproximalContact:
      return 'LackOfInterproximalContact';
    case ConditionCode.SecondaryCaries:
      return 'SecondaryCaries';
    case ConditionCode.CrownDefect:
      return 'CrownDefect';
    case ConditionCode.CanalFilling:
      return 'CanalFilling';
    case ConditionCode.Post:
      return 'Post';
    case ConditionCode.PeriodontalBoneLoss:
      return 'PeriodontalBoneLoss';
    case ConditionCode.EndodonticAccess:
      return 'EndodonticAccess';
    case ConditionCode.Pulpotomy:
      return 'Pulpotomy';
    case ConditionCode.Clamp:
      return 'Clamp';
    case ConditionCode.ForeignBody:
      return 'ForeignBody';
    case ConditionCode.EndodonticInstrument:
      return 'EndodonticInstrument';
    case ConditionCode.IndirectRestoration:
      return 'IndirectRestoration';
    case ConditionCode.ChippedTooth:
      return 'ChippedTooth';
    case ConditionCode.FillingLeakage:
      return 'FillingLeakage';
    case ConditionCode.LateralRadiolucency:
      return 'LateralRadiolucency';
    case ConditionCode.Apicoectomy:
      return 'Apicoectomy';
    case ConditionCode.ToothGerm:
      return 'ToothGerm';
    case ConditionCode.SinusPathology:
      return 'SinusPathology';
    case ConditionCode.BoneStructureDisorder:
      return 'BoneStructureDisorder';
    case ConditionCode.PreparedTooth:
      return 'PreparedTooth';
    case ConditionCode.VoidsInRestoration:
      return 'VoidsInRestoration';
    case ConditionCode.Child_DefectDepth_NotSelected:
      return 'Child_DefectDepth_NotSelected';
    case ConditionCode.Child_DefectDepth_Initial:
      return 'Child_DefectDepth_Initial';
    case ConditionCode.Child_DefectDepth_Enamel:
      return 'Child_DefectDepth_Enamel';
    case ConditionCode.Child_DefectDepth_Dentin:
      return 'Child_DefectDepth_Dentin';
    case ConditionCode.Child_DefectDepth_Pulp:
      return 'Child_DefectDepth_Pulp';
    case ConditionCode.Child_DefectDepth_Root:
      return 'Child_DefectDepth_Root';
    case ConditionCode.Child_DefectSurface_NotSelected:
      return 'Child_DefectSurface_NotSelected';
    case ConditionCode.Child_DefectSurface_Mesial:
      return 'Child_DefectSurface_Mesial';
    case ConditionCode.Child_DefectSurface_Distal:
      return 'Child_DefectSurface_Distal';
    case ConditionCode.Child_DefectSurface_Lingual:
      return 'Child_DefectSurface_Lingual';
    case ConditionCode.Child_DefectSurface_Buccal:
      return 'Child_DefectSurface_Buccal';
    case ConditionCode.Child_DefectSurface_Occlusial:
      return 'Child_DefectSurface_Occlusial';
    case ConditionCode.Child_DefectSurface_Vestibular:
      return 'Child_DefectSurface_Vestibular';
    case ConditionCode.Child_DefectSurface_Incisal:
      return 'Child_DefectSurface_Incisal';
    case ConditionCode.Child_PeriapicalLesion_Communication_Sinus:
      return 'Child_PeriapicalLesion_Communication_Sinus';
    case ConditionCode.Child_PeriapicalLesion_Communication_Canal:
      return 'Child_PeriapicalLesion_Communication_Canal';
    case ConditionCode.Child_PeriapicalLesion_Subtype_Radiopacity:
      return 'Child_PeriapicalLesion_Subtype_Radiopacity';
    case ConditionCode.Child_PeriapicalLesion_Subtype_Radiolucency:
      return 'Child_PeriapicalLesion_Subtype_Radiolucency';
    case ConditionCode.Child_PeriapicalLesion_Subtype_LigamentSpaceWideningInPeriapicalRegion:
      return 'Child_PeriapicalLesion_Subtype_LigamentSpaceWideningInPeriapicalRegion';
    case ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild:
      return 'Child_PeriodontalBoneLoss_Severity_Mild';
    case ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate:
      return 'Child_PeriodontalBoneLoss_Severity_Moderate';
    case ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe:
      return 'Child_PeriodontalBoneLoss_Severity_Severe';
    case ConditionCode.Child_PeriodontalBoneLoss_Shape_Vertical:
      return 'Child_PeriodontalBoneLoss_Shape_Vertical';
    case ConditionCode.Child_PeriodontalBoneLoss_Shape_Horizontal:
      return 'Child_PeriodontalBoneLoss_Shape_Horizontal';
    case ConditionCode.Child_PeriodontalBoneLoss_Shape_Mixed:
      return 'Child_PeriodontalBoneLoss_Shape_Mixed';
    case ConditionCode.Child_Filling_BuildUp:
      return 'Child_Filling_BuildUp';
    case ConditionCode.Child_BoneStructureDisorder_Subtype_NotSelected:
      return 'Child_BoneStructureDisorder_Subtype_NotSelected';
    case ConditionCode.Child_BoneStructureDisorder_Subtype_Radiopacity:
      return 'Child_BoneStructureDisorder_Subtype_Radiopacity';
    case ConditionCode.Child_BoneStructureDisorder_Subtype_Radiolucency:
      return 'Child_BoneStructureDisorder_Subtype_Radiolucency';
    case ConditionCode.Child_BoneStructureDisorder_Subtype_Mixed:
      return 'Child_BoneStructureDisorder_Subtype_Mixed';
    case ConditionCode.Child_Impaction_Subtype_CompleteBony:
      return 'Child_Impaction_Subtype_CompleteBony';
    case ConditionCode.Child_Impaction_Subtype_PartialBony:
      return 'Child_Impaction_Subtype_PartialBony';
    case ConditionCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
