export enum SliceName {
  access = 'access',
  condition = 'condition',
  report = 'report',
  modal = 'modal',
  auth = 'auth',
  assets = 'assets',
}

export const REFRESH_TOKEN_COOLDOWN_MS = 240000;
