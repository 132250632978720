/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Localization } from '../model/dto_report_localization';

export const protobufPackage = 'com.diagnocat.model';

export enum IOXRaySubimageType {
  IOXRay_SUBIMAGE_TYPE_INTRAORAL_XRAY = 0,
  IOXRay_SUBIMAGE_TYPE_PHOTO = 1,
  IOXRay_SUBIMAGE_TYPE_PANORAMIC_XRAY = 2,
  IOXRay_SUBIMAGE_TYPE_CBCT_SLICE = 3,
  UNRECOGNIZED = -1,
}

export function iOXRaySubimageTypeFromJSON(object: any): IOXRaySubimageType {
  switch (object) {
    case 0:
    case 'IOXRay_SUBIMAGE_TYPE_INTRAORAL_XRAY':
      return IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_INTRAORAL_XRAY;
    case 1:
    case 'IOXRay_SUBIMAGE_TYPE_PHOTO':
      return IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_PHOTO;
    case 2:
    case 'IOXRay_SUBIMAGE_TYPE_PANORAMIC_XRAY':
      return IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_PANORAMIC_XRAY;
    case 3:
    case 'IOXRay_SUBIMAGE_TYPE_CBCT_SLICE':
      return IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_CBCT_SLICE;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return IOXRaySubimageType.UNRECOGNIZED;
  }
}

export function iOXRaySubimageTypeToJSON(object: IOXRaySubimageType): string {
  switch (object) {
    case IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_INTRAORAL_XRAY:
      return 'IOXRay_SUBIMAGE_TYPE_INTRAORAL_XRAY';
    case IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_PHOTO:
      return 'IOXRay_SUBIMAGE_TYPE_PHOTO';
    case IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_PANORAMIC_XRAY:
      return 'IOXRay_SUBIMAGE_TYPE_PANORAMIC_XRAY';
    case IOXRaySubimageType.IOXRay_SUBIMAGE_TYPE_CBCT_SLICE:
      return 'IOXRay_SUBIMAGE_TYPE_CBCT_SLICE';
    case IOXRaySubimageType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface ReportIOXRayGP {
  Subimages: IOXRaySubimage[];
  TeethToSubimages: ReportIOXRayGP_ToothToSubimage[];
  ConditionsToSubimages: ReportIOXRayGP_ConditionToSubimage[];
}

export interface ReportIOXRayGP_ToothToSubimage {
  ToothID: string;
  SubimageID: string;
}

export interface ReportIOXRayGP_ConditionToSubimage {
  ConditionID: string;
  SubimageID: string;
}

export interface IOXRaySubimage {
  ID: string;
  Type: IOXRaySubimageType;
  Localization: Localization | undefined;
}

function createBaseReportIOXRayGP(): ReportIOXRayGP {
  return { Subimages: [], TeethToSubimages: [], ConditionsToSubimages: [] };
}

export const ReportIOXRayGP = {
  encode(
    message: ReportIOXRayGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Subimages) {
      IOXRaySubimage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.TeethToSubimages) {
      ReportIOXRayGP_ToothToSubimage.encode(
        v!,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    for (const v of message.ConditionsToSubimages) {
      ReportIOXRayGP_ConditionToSubimage.encode(
        v!,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportIOXRayGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportIOXRayGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Subimages.push(
            IOXRaySubimage.decode(reader, reader.uint32()),
          );
          break;
        case 2:
          message.TeethToSubimages.push(
            ReportIOXRayGP_ToothToSubimage.decode(reader, reader.uint32()),
          );
          break;
        case 3:
          message.ConditionsToSubimages.push(
            ReportIOXRayGP_ConditionToSubimage.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportIOXRayGP {
    return {
      Subimages: Array.isArray(object?.Subimages)
        ? object.Subimages.map((e: any) => IOXRaySubimage.fromJSON(e))
        : [],
      TeethToSubimages: Array.isArray(object?.TeethToSubimages)
        ? object.TeethToSubimages.map((e: any) =>
            ReportIOXRayGP_ToothToSubimage.fromJSON(e),
          )
        : [],
      ConditionsToSubimages: Array.isArray(object?.ConditionsToSubimages)
        ? object.ConditionsToSubimages.map((e: any) =>
            ReportIOXRayGP_ConditionToSubimage.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ReportIOXRayGP): unknown {
    const obj: any = {};
    if (message.Subimages) {
      obj.Subimages = message.Subimages.map((e) =>
        e ? IOXRaySubimage.toJSON(e) : undefined,
      );
    } else {
      obj.Subimages = [];
    }
    if (message.TeethToSubimages) {
      obj.TeethToSubimages = message.TeethToSubimages.map((e) =>
        e ? ReportIOXRayGP_ToothToSubimage.toJSON(e) : undefined,
      );
    } else {
      obj.TeethToSubimages = [];
    }
    if (message.ConditionsToSubimages) {
      obj.ConditionsToSubimages = message.ConditionsToSubimages.map((e) =>
        e ? ReportIOXRayGP_ConditionToSubimage.toJSON(e) : undefined,
      );
    } else {
      obj.ConditionsToSubimages = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportIOXRayGP>, I>>(
    object: I,
  ): ReportIOXRayGP {
    const message = createBaseReportIOXRayGP();
    message.Subimages =
      object.Subimages?.map((e) => IOXRaySubimage.fromPartial(e)) || [];
    message.TeethToSubimages =
      object.TeethToSubimages?.map((e) =>
        ReportIOXRayGP_ToothToSubimage.fromPartial(e),
      ) || [];
    message.ConditionsToSubimages =
      object.ConditionsToSubimages?.map((e) =>
        ReportIOXRayGP_ConditionToSubimage.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseReportIOXRayGP_ToothToSubimage(): ReportIOXRayGP_ToothToSubimage {
  return { ToothID: '', SubimageID: '' };
}

export const ReportIOXRayGP_ToothToSubimage = {
  encode(
    message: ReportIOXRayGP_ToothToSubimage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.SubimageID !== '') {
      writer.uint32(18).string(message.SubimageID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportIOXRayGP_ToothToSubimage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportIOXRayGP_ToothToSubimage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.SubimageID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportIOXRayGP_ToothToSubimage {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      SubimageID: isSet(object.SubimageID) ? String(object.SubimageID) : '',
    };
  },

  toJSON(message: ReportIOXRayGP_ToothToSubimage): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.SubimageID !== undefined && (obj.SubimageID = message.SubimageID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportIOXRayGP_ToothToSubimage>, I>>(
    object: I,
  ): ReportIOXRayGP_ToothToSubimage {
    const message = createBaseReportIOXRayGP_ToothToSubimage();
    message.ToothID = object.ToothID ?? '';
    message.SubimageID = object.SubimageID ?? '';
    return message;
  },
};

function createBaseReportIOXRayGP_ConditionToSubimage(): ReportIOXRayGP_ConditionToSubimage {
  return { ConditionID: '', SubimageID: '' };
}

export const ReportIOXRayGP_ConditionToSubimage = {
  encode(
    message: ReportIOXRayGP_ConditionToSubimage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ConditionID !== '') {
      writer.uint32(10).string(message.ConditionID);
    }
    if (message.SubimageID !== '') {
      writer.uint32(18).string(message.SubimageID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportIOXRayGP_ConditionToSubimage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportIOXRayGP_ConditionToSubimage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ConditionID = reader.string();
          break;
        case 2:
          message.SubimageID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportIOXRayGP_ConditionToSubimage {
    return {
      ConditionID: isSet(object.ConditionID) ? String(object.ConditionID) : '',
      SubimageID: isSet(object.SubimageID) ? String(object.SubimageID) : '',
    };
  },

  toJSON(message: ReportIOXRayGP_ConditionToSubimage): unknown {
    const obj: any = {};
    message.ConditionID !== undefined &&
      (obj.ConditionID = message.ConditionID);
    message.SubimageID !== undefined && (obj.SubimageID = message.SubimageID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportIOXRayGP_ConditionToSubimage>, I>,
  >(object: I): ReportIOXRayGP_ConditionToSubimage {
    const message = createBaseReportIOXRayGP_ConditionToSubimage();
    message.ConditionID = object.ConditionID ?? '';
    message.SubimageID = object.SubimageID ?? '';
    return message;
  },
};

function createBaseIOXRaySubimage(): IOXRaySubimage {
  return { ID: '', Type: 0, Localization: undefined };
}

export const IOXRaySubimage = {
  encode(
    message: IOXRaySubimage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Type !== 0) {
      writer.uint32(16).int32(message.Type);
    }
    if (message.Localization !== undefined) {
      Localization.encode(
        message.Localization,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IOXRaySubimage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIOXRaySubimage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Type = reader.int32() as any;
          break;
        case 3:
          message.Localization = Localization.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): IOXRaySubimage {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Type: isSet(object.Type) ? iOXRaySubimageTypeFromJSON(object.Type) : 0,
      Localization: isSet(object.Localization)
        ? Localization.fromJSON(object.Localization)
        : undefined,
    };
  },

  toJSON(message: IOXRaySubimage): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Type !== undefined &&
      (obj.Type = iOXRaySubimageTypeToJSON(message.Type));
    message.Localization !== undefined &&
      (obj.Localization = message.Localization
        ? Localization.toJSON(message.Localization)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<IOXRaySubimage>, I>>(
    object: I,
  ): IOXRaySubimage {
    const message = createBaseIOXRaySubimage();
    message.ID = object.ID ?? '';
    message.Type = object.Type ?? 0;
    message.Localization =
      object.Localization !== undefined && object.Localization !== null
        ? Localization.fromPartial(object.Localization)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
