import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import PinInput from 'react-pin-input';

import { toastCaller } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { authModel } from '@/entities/auth';
import { PATHS } from '@/shared/config';
import smileCat from '@/shared/assets/images/cats/smile.png';

import styles from './ConfirmEmail.module.scss';

type ConfirmEmailProps = {
  className?: string;
  testID?: string;
};

export const ConfirmEmail: FC<ConfirmEmailProps> = (props) => {
  const { className, testID } = props;

  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const checkToken = async (token: string) => {
    try {
      const verifyResponse = await dispatch(
        authModel.thunks.verify({ Value: token }),
      ).unwrap();

      if (verifyResponse?.AuthState?.IsSuccessful) {
        navigate(PATHS.main);
      }
    } catch (e) {
      toastCaller({
        type: 'error',
        heading: 'Error',
        message: JSON.parse(e?.message).text,
      });
    }
  };

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      checkToken(token);
    }
  }, []);

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <div className={styles.wrapper}>
        <img src={smileCat} width={130} height={130} alt="Smile cat" />
        <h4 className={cn(styles.header, 'h4')}>
          <FormattedMessage
            id="confirmEmail.congrats"
            defaultMessage="Congrats! Your Diagnocat account is almost ready."
          />
        </h4>
        <div className={cn(styles.text, 'p1')}>
          <FormattedMessage
            id="confirmEmail.we_sent_code"
            defaultMessage="We’ve sent
          verification code to {email}. Check out e-mail to confirm your
          account. Check spam"
            values={{
              email: (
                <strong>
                  {(state as { email: string })?.email || 'your@mail.com'}
                </strong>
              ),
            }}
          />
        </div>
        <div className="p2">
          <FormattedMessage
            id="confirmEmail.enter_code"
            defaultMessage="Enter the code from email"
          />
        </div>
        <PinInput
          length={6}
          type="numeric"
          inputMode="number"
          inputStyle={{
            width: '38px',
            height: '48px',
            borderColor: 'rgba(var(--focused-rgb), .26)',
            borderRadius: '8px',
            borderWidth: '2px',
          }}
          inputFocusStyle={{ borderColor: 'var(--focused)' }}
          onComplete={(value) => {
            checkToken(value);
          }}
          autoSelect
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </div>
    </div>
  );
};
