import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { countryDefaultNames } from '@/pages/SignUp/config/i18n';
import { Country } from '@/shared/api/protocol_gen/model/dto_base';

export const useCountryOptions = () => {
  const { formatMessage } = useIntl();

  const countryOptions = useMemo(
    () => [
      {
        value: Country.COUNTRY_KG,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_KG]),
      },
      {
        value: Country.COUNTRY_AT,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_AT]),
      },
      {
        value: Country.COUNTRY_AM,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_AM]),
      },
      {
        value: Country.COUNTRY_GB,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_GB]),
      },
      {
        value: Country.COUNTRY_SE,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_SE]),
      },
      {
        value: Country.COUNTRY_UZ,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_UZ]),
      },
      {
        value: Country.COUNTRY_MD,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_MD]),
      },
      {
        value: Country.COUNTRY_BE,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_BE]),
      },
      {
        value: Country.COUNTRY_FI,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_FI]),
      },
      {
        value: Country.COUNTRY_FR,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_FR]),
      },
      {
        value: Country.COUNTRY_MX,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_MX]),
      },
      {
        value: Country.COUNTRY_TJ,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_TJ]),
      },
      {
        value: Country.COUNTRY_BY,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_BY]),
      },
      {
        value: Country.COUNTRY_RU,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_RU]),
      },
      {
        value: Country.COUNTRY_IL,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_IL]),
      },
      {
        value: Country.COUNTRY_NO,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_NO]),
      },
      {
        value: Country.COUNTRY_DK,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_DK]),
      },
      {
        value: Country.COUNTRY_US,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_US]),
      },
      {
        value: Country.COUNTRY_CA,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_CA]),
      },
      {
        value: Country.COUNTRY_DE,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_DE]),
      },
      {
        value: Country.COUNTRY_LU,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_LU]),
      },
      {
        value: Country.COUNTRY_AZ,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_AZ]),
      },
      {
        value: Country.COUNTRY_KZ,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_KZ]),
      },
      {
        value: Country.COUNTRY_CH,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_CH]),
      },
      {
        value: Country.COUNTRY_IT,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_IT]),
      },
      {
        value: Country.COUNTRY_NL,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_NL]),
      },
      {
        value: Country.COUNTRY_HR,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_HR]),
      },
      {
        value: Country.COUNTRY_SI,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_SI]),
      },
      {
        value: Country.COUNTRY_MT,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_MT]),
      },
      {
        value: Country.COUNTRY_CY,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_CY]),
      },
      {
        value: Country.COUNTRY_SK,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_SK]),
      },
      {
        value: Country.COUNTRY_PL,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_PL]),
      },
      {
        value: Country.COUNTRY_PS,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_PS]),
      },
      {
        value: Country.COUNTRY_CZ,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_CZ]),
      },
      {
        value: Country.COUNTRY_HU,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_HU]),
      },
      {
        value: Country.COUNTRY_VI,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_VI]),
      },
      {
        value: Country.COUNTRY_EE,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_EE]),
      },
      {
        value: Country.COUNTRY_PR,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_PR]),
      },
      {
        value: Country.COUNTRY_CO,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_CO]),
      },
      {
        value: Country.COUNTRY_RO,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_RO]),
      },
      {
        value: Country.COUNTRY_GU,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_GU]),
      },
      {
        value: Country.COUNTRY_GE,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_GE]),
      },
      {
        value: Country.COUNTRY_IE,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_IE]),
      },
      {
        value: Country.COUNTRY_GR,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_GR]),
      },
      {
        value: Country.COUNTRY_PT,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_PT]),
      },
      {
        value: Country.COUNTRY_LV,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_LV]),
      },
      {
        value: Country.COUNTRY_AU,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_AU]),
      },
      {
        value: Country.COUNTRY_NZ,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_NZ]),
      },
      {
        value: Country.COUNTRY_AS,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_AS]),
      },
      {
        value: Country.COUNTRY_LT,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_LT]),
      },
      {
        value: Country.COUNTRY_ES,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_ES]),
      },
      {
        value: Country.COUNTRY_UA,
        label: formatMessage(countryDefaultNames[Country.COUNTRY_UA]),
      },
    ],
    [formatMessage],
  );

  return countryOptions;
};
