import api from '@/shared/api/api';
import { generateThunk } from '@/shared/lib';
import { SliceName } from '@/shared/config';
import {
  AccessServiceClientImpl,
  AddPatientTeamMemberReq,
  AddPatientTeamMemberResp,
  RevokePatientTeamAccessReq,
  RevokePatientTeamAccessResp,
} from '@/shared/api/protocol_gen/api/core/svc_access';

type AccessRequests = keyof AccessServiceClientImpl;

export const addPatientTeamMember = generateThunk<
  AddPatientTeamMemberReq,
  AddPatientTeamMemberResp,
  AccessRequests
>(SliceName.access, 'AddPatientTeamMember', api.access.AddPatientTeamMember);

export const revokePatientTeamAccess = generateThunk<
  RevokePatientTeamAccessReq,
  RevokePatientTeamAccessResp,
  AccessRequests
>(
  SliceName.access,
  'RevokePatientTeamAccess',
  api.access.RevokePatientTeamAccess,
);
