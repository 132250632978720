import React, { FC } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { TeethChart } from '@/features/toothChart';
import { useAppSelector, useMedia } from '@/shared/hooks';
import {
  ToothChartLegendsTypes,
  toothModel,
  ToothChartLegends,
} from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';
import { PATHS } from '@/shared/config';

import styles from './ProfileToothChart.module.scss';

export type ProfileToothChartProps = {
  className?: string;
};

const toothChartLegends: ToothChartLegendsTypes[] = [
  'unhealthy',
  'lowProbability',
  'treated',
  'healthy',
  'missing',
];

export const ProfileToothChart: FC<ProfileToothChartProps> = (props) => {
  const { className } = props;

  const navigate = useNavigate();

  const { isPhone } = useMedia();

  const { patientID } = useParams();

  const lastReport = useAppSelector(reportsModel.selectors.selectFirst);

  const lastReportTeeth = useAppSelector((state) =>
    toothModel.selectors.selectByReportID(state, lastReport?.ID),
  );

  const onToothClick = (toothID: string) => {
    navigate({
      pathname: generatePath(PATHS.tooth, {
        patientID,
        reportID: lastReport?.ID,
        toothID,
      }),
    });
  };

  if (!lastReportTeeth.length) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      <h3 className={cn(styles.title, isPhone ? 'h4' : 'h3')}>
        <FormattedMessage
          id="studyToothChart.title"
          defaultMessage="Tooth chart"
        />
      </h3>
      <TeethChart teeth={lastReportTeeth} onToothClick={onToothClick} />
      <ToothChartLegends
        toothChartLegends={toothChartLegends}
        className={styles.toothChartLegends}
      />
    </div>
  );
};
