// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer-module__container--79Cg8HiS{grid-area:footer;height:76px}.Footer-module__sticky--TPw95sPo{position:sticky;bottom:0}", "",{"version":3,"sources":["webpack://./src/shared/ui/Layout/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA,oCACE,gBAAA,CACA,WAAA,CAGF,iCACE,eAAA,CACA,QAAA","sourcesContent":[".container {\n  grid-area: footer;\n  height: 76px;\n}\n\n.sticky {\n  position: sticky;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Footer-module__container--79Cg8HiS",
	"sticky": "Footer-module__sticky--TPw95sPo"
};
export default ___CSS_LOADER_EXPORT___;
