import React, { FC, useState, ReactNode } from 'react';
import cn from 'classnames';

import { ContextRequest, ProcessingUnitContext } from 'graphics';
import {
  UltimateMedicalImage,
  UltimateMedicalImageMode,
} from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { AnnotationOrEraser } from '@/shared/graphics/RenderComponents/Presets/DicomImageWithAnnotations';

import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';

import styles from './Slice.module.scss';

const INITIAL_WIDTH = 100;
const INITIAL_HEIGHT = 140;

export type SliceProps = {
  src: string;
  onClick?: () => void;
  sliceLabel?: ReactNode;
  ww?: number;
  wc?: number;
  sharpness?: number;
  inverted?: boolean;
  mode?: UltimateMedicalImageMode;
  annotations?: Annotation[];
  currentAnnotation?: AnnotationOrEraser;
  onAnnotationsChanged?: React.Dispatch<React.SetStateAction<Annotation[]>>;
  onSharpnessChanged?: React.Dispatch<React.SetStateAction<number>>;
  onWWWCChanged?: React.Dispatch<
    React.SetStateAction<{ ww: number; wc: number }>
  >;
  onRemoveSlice?: (src: string) => void;
  focused?: boolean;
  onDragSlice?: (src: string) => void;
  mask?: ContextRequest;
  className?: string;
  checkIsTheSliceSelected?: (src: string) => boolean;
};

export const Slice: FC<SliceProps> = (props) => {
  const {
    src,
    onClick,
    sliceLabel,
    mode,
    annotations,
    currentAnnotation,
    onAnnotationsChanged,
    onWWWCChanged,
    onSharpnessChanged,
    onRemoveSlice,
    ww,
    wc,
    sharpness,
    inverted,
    focused,
    mask,
    className,
    onDragSlice,
    checkIsTheSliceSelected,
  } = props;

  const [size, setSize] = useState({ w: INITIAL_WIDTH, h: INITIAL_HEIGHT });

  const [dragging, setDragging] = useState(false);

  const isImgSelected = checkIsTheSliceSelected && checkIsTheSliceSelected(src);

  const onDicomLoaded = (context: ProcessingUnitContext) => {
    const aspect = Math.min(
      INITIAL_HEIGHT / context.width,
      INITIAL_HEIGHT / context.height,
    );

    setSize({
      w: context.width * aspect,
      h: Math.min(INITIAL_HEIGHT, context.height * aspect),
    });
  };

  const handleDeleteSlice = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onRemoveSlice(src);
  };

  const handleDragStart = () => {
    onDragSlice(src);

    setDragging(true);
  };
  const handleDragEnd = () => {
    onDragSlice('');
    setDragging(false);
  };

  return (
    <div
      className={cn(styles.container, className, focused && styles.focused)}
      style={{ width: size.w }}
      onClick={onClick}
      draggable={!!onDragSlice}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <UltimateMedicalImage
        src={{ url: src, kind: 'dicom' }}
        width={size.w}
        height={size.h}
        mode={mode}
        ww={ww}
        wc={wc}
        sharpness={sharpness}
        annotations={annotations}
        inverted={inverted}
        currentAnnotation={currentAnnotation}
        onAnnotationsChanged={onAnnotationsChanged}
        onSharpnessChanged={onSharpnessChanged}
        onWWWCChanged={onWWWCChanged}
        onLoaded={onDicomLoaded}
        mask={mask}
        className={styles.slice}
      />
      {sliceLabel && <p className={styles.sliceName}>{sliceLabel}</p>}
      {onRemoveSlice && !dragging && (
        <div className={styles.removeButtonContainer}>
          <Button
            icon="delete"
            onClick={handleDeleteSlice}
            size="small"
            variant="tertiary"
          />
        </div>
      )}
      {isImgSelected && (
        <div className={styles.selectedIcon}>
          <Icon name="check" size={24} />
        </div>
      )}
    </div>
  );
};
