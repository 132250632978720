import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { Organization } from '@/shared/api/protocol_gen/model/dto_organization';
import { LocalStorageKey } from '@/shared/config';

import { setCurrentOrganizationID } from './organizationSlice.thunks';

export const organizationAdapter = createEntityAdapter<Organization>({
  selectId: (organization) => organization.ID,
});

const initialStateFlags = {};

// type StateFlags = keyof typeof initialStateFlags;

const initialState = {
  currentOrganizationID: localStorage.getItem(LocalStorageKey.organizationID),
  stateFlags: initialStateFlags,
};

const organizationSlice = createSlice({
  name: 'organizations',
  initialState: organizationAdapter.getInitialState(initialState),
  reducers: {
    setOne: organizationAdapter.setOne,
    removeOne: organizationAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentOrganizationID.fulfilled, (state, action) => {
      state.currentOrganizationID = action.payload;
    });
    // const asyncRequestCreator = <A, B, C>(
    //   thunkAction: AsyncThunk<A, B, C>,
    //   pendingFlagKey: StateFlags,
    //   fullfiledCallback?: (payload: unknown) => void,
    // ) => {
    //   builder.addCase(thunkAction.pending, (state) => {
    //     state[pendingFlagKey] = true;
    //   });
    //   builder.addCase(thunkAction.fulfilled, (state, { payload }) => {
    //     state[pendingFlagKey] = false;
    //     if (typeof fullfiledCallback === 'function') {
    //       fullfiledCallback(payload);
    //     }
    //   });
    //   builder.addCase(thunkAction.rejected, (state) => {
    //     state[pendingFlagKey] = false;
    //   });
    // };
  },
});

export const { setOne, removeOne } = organizationSlice.actions;

export default organizationSlice.reducer;
