import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { DropdownSelect } from '@/shared/ui';

import { useOrganizationUsersOptions } from '../hooks/useOrganizationUsersOptions';

type DoctorsDropdownFilterProps = {
  onChange: () => void;
};

// TODO: add updating search params and connect with patient list stream reopening
export const DoctorsDropdownFilter: FC<DoctorsDropdownFilterProps> = (
  props,
) => {
  const { onChange } = props;
  const { formatMessage } = useIntl();
  const [doctors, setDoctors] = useState<string | string[]>([]);
  const usersOptions = useOrganizationUsersOptions();

  const handleChange = (value: string | string[]) => {
    setDoctors(value);
    onChange();
  };

  return (
    <DropdownSelect
      isMulti
      value={doctors}
      label={formatMessage({
        id: 'patientListFilters.doctorSelectLabel',
        defaultMessage: 'Doctors',
      })}
      options={usersOptions}
      onChange={handleChange}
    />
  );
};
