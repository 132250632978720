// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GroupSlicesList-module__container--jpEQczxw{position:relative}.GroupSlicesList-module__slices--YTC4GS\\+3{position:relative;display:flex;flex-wrap:wrap;grid-gap:2px}\n\n.GroupSlicesList-module__restoreButton--GUMguL2-{position:absolute;top:-55px;right:0}\n\n[dir=\"rtl\"] .GroupSlicesList-module__restoreButton--GUMguL2-{right:auto;left:0}.GroupSlicesList-module__sliceWrapper--SxJkkXNf{position:relative}.GroupSlicesList-module__mask--ttsYBrQ6{background-color:#ff0;top:0;opacity:.4}.GroupSlicesList-module__slicesActions--SaqtDT0b{display:flex;justify-content:flex-end;gap:10px}", "",{"version":3,"sources":["webpack://./src/pages/Tooth/ui/GroupSlicesList/GroupSlicesList.module.scss"],"names":[],"mappings":"AAAA,6CACE,iBAAA,CAGF,2CACE,iBAAA,CACA,YAAA,CACA,cAAA,CACA,YAAA;;AAGF,iDACE,iBAAA,CACA,SAAA,CACA,OAAA;;AAHF,6DAGE,UAAA,CAAA,MAAA,CAGF,gDACE,iBAAA,CAGF,wCACE,qBAAA,CACA,KAAA,CACA,UAAA,CAGF,iDACE,YAAA,CACA,wBAAA,CACA,QAAA","sourcesContent":[".container {\n  position: relative;\n}\n\n.slices {\n  position: relative;\n  display: flex;\n  flex-wrap: wrap;\n  grid-gap: 2px;\n}\n\n.restoreButton {\n  position: absolute;\n  top: -55px;\n  right: 0;\n}\n\n.sliceWrapper {\n  position: relative;\n}\n\n.mask {\n  background-color: yellow;\n  top: 0;\n  opacity: 0.4;\n}\n\n.slicesActions {\n  display: flex;\n  justify-content: flex-end;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GroupSlicesList-module__container--jpEQczxw",
	"slices": "GroupSlicesList-module__slices--YTC4GS+3",
	"restoreButton": "GroupSlicesList-module__restoreButton--GUMguL2-",
	"sliceWrapper": "GroupSlicesList-module__sliceWrapper--SxJkkXNf",
	"mask": "GroupSlicesList-module__mask--ttsYBrQ6",
	"slicesActions": "GroupSlicesList-module__slicesActions--SaqtDT0b"
};
export default ___CSS_LOADER_EXPORT___;
