import React from 'react';
import { useState } from 'react';
import { AnnotationEditContext } from './Annotation';
import { CircleHandleHelper } from './CircleHandleHelper';
import { RulerProps } from './Ruler';

type RulerHandlesProps = RulerProps & {
  onIntentToEdit: (context: AnnotationEditContext) => void;
};

export const RulerHandle = ({
  x1,
  y1,
  x2,
  y2,
  onIntentToEdit,
}: RulerHandlesProps) => {
  return (
    <>
      <CircleHandleHelper
        x={x1}
        y={y1}
        onDown={() => {
          onIntentToEdit({ kind: 'ruler', value: 'dragStartingPoint' });
        }}
      />
      <CircleHandleHelper
        x={x2}
        y={y2}
        onDown={() => {
          onIntentToEdit({ kind: 'ruler', value: 'dragEndingPoint' });
        }}
      />
    </>
  );
};
