import React, { FC, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Dropdown } from '@/shared/ui';
import { PATHS } from '@/shared/config';

import styles from './TestNavigation.module.scss';

type TestNavigationProps = {
  className?: string;
  testID?: string;
};

export const TestNavigation: FC<TestNavigationProps> = (props) => {
  const { className, testID } = props;

  const [open, setIsOpen] = useState(false);

  const routes = Object.entries(PATHS).map(([key, value]) => ({
    name: key,
    path: value,
  }));

  return (
    <div className={cn(styles.container, 'h4', className)} data-testid={testID}>
      <Dropdown
        trigger={<div>Dev Navigation</div>}
        isOpen={open}
        setIsOpen={setIsOpen}
        position="left center"
      >
        <ul className={cn(styles.list)}>
          {routes.map((link) => {
            const { name, path } = link;
            return (
              <li key={name} style={{ listStyleType: 'none' }}>
                <Link key={name} className={cn(styles.link, 'p1')} to={path}>
                  {name}
                </Link>
              </li>
            );
          })}
        </ul>
      </Dropdown>
    </div>
  );
};
