import React, { FC } from 'react';
import cn from 'classnames';

import styles from './InputLabel.module.scss';

type InputLabelProps = {
  className?: string;
  testID?: string;
  label?: string | JSX.Element;
  required?: boolean;
};

export const InputLabel: FC<InputLabelProps> = (props) => {
  const { className, testID, label, required } = props;

  if (!label) {
    return null;
  }

  return (
    <span
      className={cn(
        styles.container,
        required && styles.required,
        'p3',
        className,
      )}
      data-testid={testID}
    >
      {label}
    </span>
  );
};
