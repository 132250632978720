import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './Description.module.scss';

type DescriptionProps = {
  className?: string;
  children?: ReactNode;
  label: JSX.Element | string;
};

export const Description: FC<DescriptionProps> = (props) => {
  const { className, children, label } = props;

  return (
    <div className={cn(styles.container, className, 'p3')}>
      <span className={styles.label}>{label}: </span>
      <span className={styles.item}>{children}</span>
    </div>
  );
};
