import { UserLocale } from '@/entities/user';

export const RTL_LANGUAGES: UserLocale[] = ['he'];

export const isLanguageRTL = (
  language: UserLocale,
  availableLanguages: UserLocale[],
): boolean => {
  const lang = availableLanguages.find((l) => l === language);
  return RTL_LANGUAGES.includes(lang);
};
