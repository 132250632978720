import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import styles from './Textarea.module.scss';

export interface TextareaProps {
  rows?: number;
  value: string;
  outerRef?: React.MutableRefObject<HTMLTextAreaElement>;
  className?: string;
  testID?: string;
  isElastic?: boolean;
  placeholder?: string;
  isResizeDisabled?: boolean;
  autoFocus?: boolean;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
}

export const Textarea: React.FunctionComponent<TextareaProps> = (props) => {
  const {
    rows,
    value,
    outerRef,
    isElastic,
    className,
    testID,
    placeholder,
    isResizeDisabled,
    autoFocus,
    onBlur,
    onFocus,
    onChange,
    onKeyDown,
  } = props;

  const innerRef = useRef<HTMLTextAreaElement>();

  const textareaRef = outerRef || innerRef;

  const DEFAULT_HEIGHT = '5px';

  const autoGrow = (element: HTMLTextAreaElement) => {
    element.style.height = DEFAULT_HEIGHT;
    element.style.height = `${element.scrollHeight}px`;
  };

  useEffect(() => {
    if (textareaRef.current && isElastic) {
      autoGrow(textareaRef.current);
    }
  }, [value, isElastic]);

  return (
    <textarea
      // eslint-disable-next-line
      autoFocus={autoFocus}
      ref={textareaRef}
      rows={rows}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      onKeyDown={onKeyDown}
      className={cn(
        styles.cont,
        'p2',
        isResizeDisabled && styles.isResizeDisabled,
        isElastic && styles.isElastic,
        className,
      )}
      data-testid={testID}
      placeholder={placeholder}
    />
  );
};
