// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SliceList-module__container--9soMU64I{display:flex;flex-wrap:wrap;grid-gap:2px}", "",{"version":3,"sources":["webpack://./src/widgets/ToothCard/ui/SliceList/SliceList.module.scss"],"names":[],"mappings":"AAAA,uCACE,YAAA,CACA,cAAA,CACA,YAAA","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: wrap;\n  grid-gap: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SliceList-module__container--9soMU64I"
};
export default ___CSS_LOADER_EXPORT___;
