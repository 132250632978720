import React, { ReactNode, useRef, useState } from 'react';
import { Annotation, AnnotationEditContext } from './Annotation';
import { AutosizeSvg } from './AutosizeSvg';
import { RenderAnnotationHandle } from './RenderAnnotationHandle';

export const AnnotationsHandlesLayer = ({
  annotations,
  children,
  onIntentToEdit,
}: {
  annotations: Annotation[];
  children?: ReactNode;
  onIntentToEdit: (annotation: Annotation, index: number, context: AnnotationEditContext) => void;
}) => {
  return (
    <AutosizeSvg style={{ display: 'block', pointerEvents: 'none' }}>
      {annotations?.map((annotation, index) => (
        <RenderAnnotationHandle
          key={index}
          index={index}
          annotation={annotation}
          onIntentToEdit={onIntentToEdit}
        />
      ))}
      {children}
    </AutosizeSvg>
  );
};
