/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { FloatPoint2D } from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

export interface Annotations {
  Layers: AnnotationsLayer[];
}

export interface AnnotationsLayer {
  ID: string;
  Readonly: boolean;
  Annotations: Annotation[];
}

export interface Annotation {
  ID: string;
  Ruler: AnnotationTypeRuler | undefined;
  Arrow: AnnotationTypeArrow | undefined;
  PolyLine: AnnotationTypePolyLine | undefined;
  Angle: AnnotationTypeAngle | undefined;
  Guide: AnnotationTypeGuide | undefined;
}

export interface AnnotationTypeRuler {
  Start: FloatPoint2D | undefined;
  End: FloatPoint2D | undefined;
}

export interface AnnotationTypeArrow {
  Start: FloatPoint2D | undefined;
  End: FloatPoint2D | undefined;
  DoubleSided: boolean;
}

export interface AnnotationTypePolyLine {
  Points: FloatPoint2D[];
}

export interface AnnotationTypeAngle {
  Start: FloatPoint2D | undefined;
  Corner: FloatPoint2D | undefined;
  End: FloatPoint2D | undefined;
}

export interface AnnotationTypeGuide {
  Start: FloatPoint2D | undefined;
  End: FloatPoint2D | undefined;
  ReferencedAssetID: string;
}

function createBaseAnnotations(): Annotations {
  return { Layers: [] };
}

export const Annotations = {
  encode(
    message: Annotations,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Layers) {
      AnnotationsLayer.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Annotations {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotations();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Layers.push(AnnotationsLayer.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Annotations {
    return {
      Layers: Array.isArray(object?.Layers)
        ? object.Layers.map((e: any) => AnnotationsLayer.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Annotations): unknown {
    const obj: any = {};
    if (message.Layers) {
      obj.Layers = message.Layers.map((e) =>
        e ? AnnotationsLayer.toJSON(e) : undefined,
      );
    } else {
      obj.Layers = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Annotations>, I>>(
    object: I,
  ): Annotations {
    const message = createBaseAnnotations();
    message.Layers =
      object.Layers?.map((e) => AnnotationsLayer.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAnnotationsLayer(): AnnotationsLayer {
  return { ID: '', Readonly: false, Annotations: [] };
}

export const AnnotationsLayer = {
  encode(
    message: AnnotationsLayer,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Readonly === true) {
      writer.uint32(16).bool(message.Readonly);
    }
    for (const v of message.Annotations) {
      Annotation.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnnotationsLayer {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotationsLayer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Readonly = reader.bool();
          break;
        case 3:
          message.Annotations.push(Annotation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AnnotationsLayer {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Readonly: isSet(object.Readonly) ? Boolean(object.Readonly) : false,
      Annotations: Array.isArray(object?.Annotations)
        ? object.Annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AnnotationsLayer): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Readonly !== undefined && (obj.Readonly = message.Readonly);
    if (message.Annotations) {
      obj.Annotations = message.Annotations.map((e) =>
        e ? Annotation.toJSON(e) : undefined,
      );
    } else {
      obj.Annotations = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AnnotationsLayer>, I>>(
    object: I,
  ): AnnotationsLayer {
    const message = createBaseAnnotationsLayer();
    message.ID = object.ID ?? '';
    message.Readonly = object.Readonly ?? false;
    message.Annotations =
      object.Annotations?.map((e) => Annotation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAnnotation(): Annotation {
  return {
    ID: '',
    Ruler: undefined,
    Arrow: undefined,
    PolyLine: undefined,
    Angle: undefined,
    Guide: undefined,
  };
}

export const Annotation = {
  encode(
    message: Annotation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Ruler !== undefined) {
      AnnotationTypeRuler.encode(
        message.Ruler,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.Arrow !== undefined) {
      AnnotationTypeArrow.encode(
        message.Arrow,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.PolyLine !== undefined) {
      AnnotationTypePolyLine.encode(
        message.PolyLine,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.Angle !== undefined) {
      AnnotationTypeAngle.encode(
        message.Angle,
        writer.uint32(114).fork(),
      ).ldelim();
    }
    if (message.Guide !== undefined) {
      AnnotationTypeGuide.encode(
        message.Guide,
        writer.uint32(122).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Annotation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 11:
          message.Ruler = AnnotationTypeRuler.decode(reader, reader.uint32());
          break;
        case 12:
          message.Arrow = AnnotationTypeArrow.decode(reader, reader.uint32());
          break;
        case 13:
          message.PolyLine = AnnotationTypePolyLine.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 14:
          message.Angle = AnnotationTypeAngle.decode(reader, reader.uint32());
          break;
        case 15:
          message.Guide = AnnotationTypeGuide.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Annotation {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Ruler: isSet(object.Ruler)
        ? AnnotationTypeRuler.fromJSON(object.Ruler)
        : undefined,
      Arrow: isSet(object.Arrow)
        ? AnnotationTypeArrow.fromJSON(object.Arrow)
        : undefined,
      PolyLine: isSet(object.PolyLine)
        ? AnnotationTypePolyLine.fromJSON(object.PolyLine)
        : undefined,
      Angle: isSet(object.Angle)
        ? AnnotationTypeAngle.fromJSON(object.Angle)
        : undefined,
      Guide: isSet(object.Guide)
        ? AnnotationTypeGuide.fromJSON(object.Guide)
        : undefined,
    };
  },

  toJSON(message: Annotation): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Ruler !== undefined &&
      (obj.Ruler = message.Ruler
        ? AnnotationTypeRuler.toJSON(message.Ruler)
        : undefined);
    message.Arrow !== undefined &&
      (obj.Arrow = message.Arrow
        ? AnnotationTypeArrow.toJSON(message.Arrow)
        : undefined);
    message.PolyLine !== undefined &&
      (obj.PolyLine = message.PolyLine
        ? AnnotationTypePolyLine.toJSON(message.PolyLine)
        : undefined);
    message.Angle !== undefined &&
      (obj.Angle = message.Angle
        ? AnnotationTypeAngle.toJSON(message.Angle)
        : undefined);
    message.Guide !== undefined &&
      (obj.Guide = message.Guide
        ? AnnotationTypeGuide.toJSON(message.Guide)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Annotation>, I>>(
    object: I,
  ): Annotation {
    const message = createBaseAnnotation();
    message.ID = object.ID ?? '';
    message.Ruler =
      object.Ruler !== undefined && object.Ruler !== null
        ? AnnotationTypeRuler.fromPartial(object.Ruler)
        : undefined;
    message.Arrow =
      object.Arrow !== undefined && object.Arrow !== null
        ? AnnotationTypeArrow.fromPartial(object.Arrow)
        : undefined;
    message.PolyLine =
      object.PolyLine !== undefined && object.PolyLine !== null
        ? AnnotationTypePolyLine.fromPartial(object.PolyLine)
        : undefined;
    message.Angle =
      object.Angle !== undefined && object.Angle !== null
        ? AnnotationTypeAngle.fromPartial(object.Angle)
        : undefined;
    message.Guide =
      object.Guide !== undefined && object.Guide !== null
        ? AnnotationTypeGuide.fromPartial(object.Guide)
        : undefined;
    return message;
  },
};

function createBaseAnnotationTypeRuler(): AnnotationTypeRuler {
  return { Start: undefined, End: undefined };
}

export const AnnotationTypeRuler = {
  encode(
    message: AnnotationTypeRuler,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Start !== undefined) {
      FloatPoint2D.encode(message.Start, writer.uint32(10).fork()).ldelim();
    }
    if (message.End !== undefined) {
      FloatPoint2D.encode(message.End, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnnotationTypeRuler {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotationTypeRuler();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Start = FloatPoint2D.decode(reader, reader.uint32());
          break;
        case 2:
          message.End = FloatPoint2D.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AnnotationTypeRuler {
    return {
      Start: isSet(object.Start)
        ? FloatPoint2D.fromJSON(object.Start)
        : undefined,
      End: isSet(object.End) ? FloatPoint2D.fromJSON(object.End) : undefined,
    };
  },

  toJSON(message: AnnotationTypeRuler): unknown {
    const obj: any = {};
    message.Start !== undefined &&
      (obj.Start = message.Start
        ? FloatPoint2D.toJSON(message.Start)
        : undefined);
    message.End !== undefined &&
      (obj.End = message.End ? FloatPoint2D.toJSON(message.End) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AnnotationTypeRuler>, I>>(
    object: I,
  ): AnnotationTypeRuler {
    const message = createBaseAnnotationTypeRuler();
    message.Start =
      object.Start !== undefined && object.Start !== null
        ? FloatPoint2D.fromPartial(object.Start)
        : undefined;
    message.End =
      object.End !== undefined && object.End !== null
        ? FloatPoint2D.fromPartial(object.End)
        : undefined;
    return message;
  },
};

function createBaseAnnotationTypeArrow(): AnnotationTypeArrow {
  return { Start: undefined, End: undefined, DoubleSided: false };
}

export const AnnotationTypeArrow = {
  encode(
    message: AnnotationTypeArrow,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Start !== undefined) {
      FloatPoint2D.encode(message.Start, writer.uint32(10).fork()).ldelim();
    }
    if (message.End !== undefined) {
      FloatPoint2D.encode(message.End, writer.uint32(18).fork()).ldelim();
    }
    if (message.DoubleSided === true) {
      writer.uint32(24).bool(message.DoubleSided);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnnotationTypeArrow {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotationTypeArrow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Start = FloatPoint2D.decode(reader, reader.uint32());
          break;
        case 2:
          message.End = FloatPoint2D.decode(reader, reader.uint32());
          break;
        case 3:
          message.DoubleSided = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AnnotationTypeArrow {
    return {
      Start: isSet(object.Start)
        ? FloatPoint2D.fromJSON(object.Start)
        : undefined,
      End: isSet(object.End) ? FloatPoint2D.fromJSON(object.End) : undefined,
      DoubleSided: isSet(object.DoubleSided)
        ? Boolean(object.DoubleSided)
        : false,
    };
  },

  toJSON(message: AnnotationTypeArrow): unknown {
    const obj: any = {};
    message.Start !== undefined &&
      (obj.Start = message.Start
        ? FloatPoint2D.toJSON(message.Start)
        : undefined);
    message.End !== undefined &&
      (obj.End = message.End ? FloatPoint2D.toJSON(message.End) : undefined);
    message.DoubleSided !== undefined &&
      (obj.DoubleSided = message.DoubleSided);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AnnotationTypeArrow>, I>>(
    object: I,
  ): AnnotationTypeArrow {
    const message = createBaseAnnotationTypeArrow();
    message.Start =
      object.Start !== undefined && object.Start !== null
        ? FloatPoint2D.fromPartial(object.Start)
        : undefined;
    message.End =
      object.End !== undefined && object.End !== null
        ? FloatPoint2D.fromPartial(object.End)
        : undefined;
    message.DoubleSided = object.DoubleSided ?? false;
    return message;
  },
};

function createBaseAnnotationTypePolyLine(): AnnotationTypePolyLine {
  return { Points: [] };
}

export const AnnotationTypePolyLine = {
  encode(
    message: AnnotationTypePolyLine,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Points) {
      FloatPoint2D.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AnnotationTypePolyLine {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotationTypePolyLine();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Points.push(FloatPoint2D.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AnnotationTypePolyLine {
    return {
      Points: Array.isArray(object?.Points)
        ? object.Points.map((e: any) => FloatPoint2D.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AnnotationTypePolyLine): unknown {
    const obj: any = {};
    if (message.Points) {
      obj.Points = message.Points.map((e) =>
        e ? FloatPoint2D.toJSON(e) : undefined,
      );
    } else {
      obj.Points = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AnnotationTypePolyLine>, I>>(
    object: I,
  ): AnnotationTypePolyLine {
    const message = createBaseAnnotationTypePolyLine();
    message.Points =
      object.Points?.map((e) => FloatPoint2D.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAnnotationTypeAngle(): AnnotationTypeAngle {
  return { Start: undefined, Corner: undefined, End: undefined };
}

export const AnnotationTypeAngle = {
  encode(
    message: AnnotationTypeAngle,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Start !== undefined) {
      FloatPoint2D.encode(message.Start, writer.uint32(10).fork()).ldelim();
    }
    if (message.Corner !== undefined) {
      FloatPoint2D.encode(message.Corner, writer.uint32(18).fork()).ldelim();
    }
    if (message.End !== undefined) {
      FloatPoint2D.encode(message.End, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnnotationTypeAngle {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotationTypeAngle();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Start = FloatPoint2D.decode(reader, reader.uint32());
          break;
        case 2:
          message.Corner = FloatPoint2D.decode(reader, reader.uint32());
          break;
        case 3:
          message.End = FloatPoint2D.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AnnotationTypeAngle {
    return {
      Start: isSet(object.Start)
        ? FloatPoint2D.fromJSON(object.Start)
        : undefined,
      Corner: isSet(object.Corner)
        ? FloatPoint2D.fromJSON(object.Corner)
        : undefined,
      End: isSet(object.End) ? FloatPoint2D.fromJSON(object.End) : undefined,
    };
  },

  toJSON(message: AnnotationTypeAngle): unknown {
    const obj: any = {};
    message.Start !== undefined &&
      (obj.Start = message.Start
        ? FloatPoint2D.toJSON(message.Start)
        : undefined);
    message.Corner !== undefined &&
      (obj.Corner = message.Corner
        ? FloatPoint2D.toJSON(message.Corner)
        : undefined);
    message.End !== undefined &&
      (obj.End = message.End ? FloatPoint2D.toJSON(message.End) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AnnotationTypeAngle>, I>>(
    object: I,
  ): AnnotationTypeAngle {
    const message = createBaseAnnotationTypeAngle();
    message.Start =
      object.Start !== undefined && object.Start !== null
        ? FloatPoint2D.fromPartial(object.Start)
        : undefined;
    message.Corner =
      object.Corner !== undefined && object.Corner !== null
        ? FloatPoint2D.fromPartial(object.Corner)
        : undefined;
    message.End =
      object.End !== undefined && object.End !== null
        ? FloatPoint2D.fromPartial(object.End)
        : undefined;
    return message;
  },
};

function createBaseAnnotationTypeGuide(): AnnotationTypeGuide {
  return { Start: undefined, End: undefined, ReferencedAssetID: '' };
}

export const AnnotationTypeGuide = {
  encode(
    message: AnnotationTypeGuide,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Start !== undefined) {
      FloatPoint2D.encode(message.Start, writer.uint32(10).fork()).ldelim();
    }
    if (message.End !== undefined) {
      FloatPoint2D.encode(message.End, writer.uint32(18).fork()).ldelim();
    }
    if (message.ReferencedAssetID !== '') {
      writer.uint32(26).string(message.ReferencedAssetID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnnotationTypeGuide {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotationTypeGuide();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Start = FloatPoint2D.decode(reader, reader.uint32());
          break;
        case 2:
          message.End = FloatPoint2D.decode(reader, reader.uint32());
          break;
        case 3:
          message.ReferencedAssetID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AnnotationTypeGuide {
    return {
      Start: isSet(object.Start)
        ? FloatPoint2D.fromJSON(object.Start)
        : undefined,
      End: isSet(object.End) ? FloatPoint2D.fromJSON(object.End) : undefined,
      ReferencedAssetID: isSet(object.ReferencedAssetID)
        ? String(object.ReferencedAssetID)
        : '',
    };
  },

  toJSON(message: AnnotationTypeGuide): unknown {
    const obj: any = {};
    message.Start !== undefined &&
      (obj.Start = message.Start
        ? FloatPoint2D.toJSON(message.Start)
        : undefined);
    message.End !== undefined &&
      (obj.End = message.End ? FloatPoint2D.toJSON(message.End) : undefined);
    message.ReferencedAssetID !== undefined &&
      (obj.ReferencedAssetID = message.ReferencedAssetID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AnnotationTypeGuide>, I>>(
    object: I,
  ): AnnotationTypeGuide {
    const message = createBaseAnnotationTypeGuide();
    message.Start =
      object.Start !== undefined && object.Start !== null
        ? FloatPoint2D.fromPartial(object.Start)
        : undefined;
    message.End =
      object.End !== undefined && object.End !== null
        ? FloatPoint2D.fromPartial(object.End)
        : undefined;
    message.ReferencedAssetID = object.ReferencedAssetID ?? '';
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
