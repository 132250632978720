import {
  createSlice,
  createEntityAdapter,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';
import { DefaultInitialState, SliceLoading } from '@/shared/config';

import { createPatient, deletePatient } from './patientSlice.thunks';

export const patientAdapter = createEntityAdapter<Patient>({
  selectId: (patient) => patient.ID,
  // The system should display patients sorted by Last modified date in descending order
  sortComparer: (a, b) => b.Revision.UpdatedAt - a.Revision.UpdatedAt,
});

type PatientSliceInitialState = DefaultInitialState & {
  flags: {
    isCreatePatientPending: boolean;
    isDeletePatientPending: boolean;
  };
  sharedCounter: number;
  totalCounter: number;
};

const initialStateFlags: PatientSliceInitialState = {
  flags: {
    isCreatePatientPending: false,
    isDeletePatientPending: false,
  },
  sharedCounter: 0,
  totalCounter: 0,
  loading: 'idle',
};

type StateFlags = keyof typeof initialStateFlags.flags;

const patientSlice = createSlice({
  name: 'patient',
  initialState: patientAdapter.getInitialState(initialStateFlags),
  reducers: {
    addOne: patientAdapter.addOne,
    addMany: patientAdapter.addMany,
    removeAll: patientAdapter.removeAll,
    setOne: patientAdapter.setOne,
    setNewestOne: (state, action: PayloadAction<Patient>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision.Number;

      if (payloadRevisionNumber > currentRevisionNumber) {
        patientAdapter.setOne(state, action.payload);
      }
    },
    removeOne: patientAdapter.removeOne,
    setSharedCounter: (state, action: PayloadAction<number>) => {
      state.sharedCounter = action.payload;
    },
    setTotalCounter: (state, action: PayloadAction<number>) => {
      state.totalCounter = action.payload;
    },
    setLoading: (state, action: PayloadAction<SliceLoading>) => {
      state.loading = action.payload;
    },
    patientListLoadingPending: (state) => {
      state.loading = 'pending';
    },
    patientListLoadingSucceeded: (state) => {
      state.loading = 'succeeded';
    },
    patientListLoadingFailed: (state) => {
      state.loading = 'failed';
    },
  },
  extraReducers: (builder) => {
    const asyncRequestCreator = <A, B, C>(
      thunkAction: AsyncThunk<A, B, C>,
      pendingFlagKey: StateFlags,
      fulfilledCallback?: (payload: unknown) => void,
    ) => {
      builder.addCase(thunkAction.pending, (state) => {
        state.flags[pendingFlagKey] = true;
      });
      builder.addCase(thunkAction.fulfilled, (state, { payload }) => {
        state.flags[pendingFlagKey] = false;
        if (typeof fulfilledCallback === 'function') {
          fulfilledCallback(payload);
        }
      });
      builder.addCase(thunkAction.rejected, (state) => {
        state.flags[pendingFlagKey] = false;
      });
    };

    asyncRequestCreator(createPatient, 'isCreatePatientPending');
    asyncRequestCreator(deletePatient, 'isDeletePatientPending');
  },
});

export const { actions } = patientSlice;

export default patientSlice.reducer;
