// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TestNavigation-module__container--8qYizwRa{padding:4px;color:var(--purpletext1);text-decoration:none}.TestNavigation-module__container--8qYizwRa:hover{color:var(--purpleflat)}.TestNavigation-module__list--QV5nJXwl{padding:0 10px;background-color:var(--bg4);border-radius:5px}.TestNavigation-module__link--R6gMyCOn{color:var(--purpletext1)}", "",{"version":3,"sources":["webpack://./src/pages/TestPage/components/TestNavigation/TestNavigation.module.scss"],"names":[],"mappings":"AAAA,4CACE,WAAA,CACA,wBAAA,CACA,oBAAA,CAEA,kDACE,uBAAA,CAIJ,uCACE,cAAA,CACA,2BAAA,CACA,iBAAA,CAGF,uCACE,wBAAA","sourcesContent":[".container {\n  padding: 4px;\n  color: var(--purpletext1);\n  text-decoration: none;\n\n  &:hover {\n    color: var(--purpleflat);\n  }\n}\n\n.list {\n  padding: 0 10px;\n  background-color: var(--bg4);\n  border-radius: 5px;\n}\n\n.link {\n  color: var(--purpletext1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TestNavigation-module__container--8qYizwRa",
	"list": "TestNavigation-module__list--QV5nJXwl",
	"link": "TestNavigation-module__link--R6gMyCOn"
};
export default ___CSS_LOADER_EXPORT___;
