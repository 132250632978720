import { SlicesGroups, ToothImage } from '../config/slices.types';

import { sortSlicesGroupsByDefinitionMask } from './sortSlicesGroupsByDefinitionMask';

export const getSlicesGroups = (
  images: ToothImage[],
  sliceGroupsDefinition: string[],
) => {
  const result = images.reduce((acc, image) => {
    const groupPrefix = image.key.split('-')[0];

    if (
      !sliceGroupsDefinition.length ||
      sliceGroupsDefinition.includes(groupPrefix)
    ) {
      return { ...acc, [groupPrefix]: [...(acc[groupPrefix] || []), image] };
    }

    return acc;
  }, {} as SlicesGroups);

  return sortSlicesGroupsByDefinitionMask(result, sliceGroupsDefinition);
};
