import React, { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { Modal, ModalProps, toastCaller } from '@/shared/ui';
import { reportsModel } from '@/entities/reports';
import { useAppDispatch } from '@/shared/hooks';
import { toothModel } from '@/entities/tooth';

import styles from './ConfirmSignReportModal.module.scss';

type ConfirmSignReportProps = {
  className?: string;
  yellowConditionsIDs: string[];
} & Pick<ModalProps, 'isOpen' | 'onClose' | 'onApply'>;

export const ConfirmSignReportModal: FC<ConfirmSignReportProps> = (props) => {
  const { isOpen, onClose, className, yellowConditionsIDs } = props;

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { reportID } = useParams();

  const handleApproveAndSign = async () => {
    setLoading(true);
    try {
      const currentReport = await dispatch(
        reportsModel.thunks.approveAllTeethAndSignReport({
          ReportID: reportID,
          TeethConditionIDs: yellowConditionsIDs,
        }),
      ).unwrap();
      dispatch(reportsModel.actions.setNewestOne(currentReport.Report));
      dispatch(toothModel.actions.setMany(currentReport.Teeth));
    } catch (error) {
      toastCaller({
        message: error?.message,
        type: 'error',
        heading: 'Error',
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Approve all teeth"
      onApply={handleApproveAndSign}
      onClose={onClose}
      className={cn(styles.container, className)}
      applyButtonText={
        <FormattedMessage
          id="report.confirm.approveAndSign"
          defaultMessage="Yes, approve all and sign"
        />
      }
      applyButtonProps={{ loading }}
    >
      <FormattedMessage
        id="report.warning.approveAndSign"
        defaultMessage="Are you sure you want to approve all the teeth and include in the report?"
      />
    </Modal>
  );
};
