import { SlicesGroups } from '../config/slices.types';

export const sortSlicesGroupsByDefinitionMask = (
  slicesGroups: SlicesGroups,
  mask: string[],
) => {
  const result = mask.reduce((acc, key) => {
    if (slicesGroups[key]) {
      return { ...acc, [key]: slicesGroups[key] };
    }
    return acc;
  }, {} as SlicesGroups);

  return result;
};
