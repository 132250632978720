import { FC } from 'react';
import cn from 'classnames';

import { reportsModel } from '@/entities/reports';

import { Method } from '../../../Method/Method';

import styles from './ReportMethods.module.scss';

type Props = {
  className?: string;
};

type AnalysisThunks = keyof typeof reportsModel.thunks;

export const ReportMethods: FC<Props> = (props) => {
  const { className } = props;

  return (
    <div className={cn(styles.cont, className)}>
      {Object.keys(reportsModel.thunks).map((thunkName) => (
        <Method
          thunk={reportsModel.thunks[thunkName as AnalysisThunks]}
          label={thunkName}
        />
      ))}
    </div>
  );
};
