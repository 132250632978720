import React, { FC } from 'react';
import cn from 'classnames';
import { generatePath, useNavigate, useParams } from 'react-router';

import { useAppSelector } from '@/shared/hooks';
import {
  JawQuartile,
  makeJawObject,
  Quartiles,
  toothModel,
} from '@/entities/tooth';
import { PATHS } from '@/shared/config';

import styles from './SimplifiedToothChart.module.scss';

type SimplifiedToothChartProps = {
  className?: string;
  toothOfInterestNumber?: number;
};

export const SimplifiedToothChart: FC<SimplifiedToothChartProps> = (props) => {
  const { className, toothOfInterestNumber } = props;

  const { patientID, reportID } = useParams();
  const navigate = useNavigate();

  const teeth = useAppSelector((state) =>
    toothModel.selectors.selectByReportID(state, reportID),
  );

  const jawObject = makeJawObject(teeth);

  const onToothClick = (toothID: string) => {
    navigate({
      pathname: generatePath(PATHS.tooth, {
        patientID,
        reportID,
        toothID,
      }),
    });
  };

  return (
    <div className={cn(styles.container, className)}>
      {Object.entries(jawObject).map(([quartile, quartileTeeth]) => (
        <JawQuartile
          key={quartile}
          className={cn(styles[quartile])}
          quartile={quartile as Quartiles}
          teeth={quartileTeeth}
          onToothClick={onToothClick}
          toothOfInterestNumber={toothOfInterestNumber}
          isSimplify
        />
      ))}
    </div>
  );
};
