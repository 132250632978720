import React, { FC, memo, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Slice, WidgetCard } from '@/shared/ui';
import { SliceInterface } from '@/entities/assets';

import styles from './SelectedSlices.module.scss';

type SelectedSlicesProps = {
  onDrop?: () => void;
  selectedSlices: SliceInterface[];
  onDeleteSlice?: (src: string) => void;
  className?: string;
};

export const SelectedSlices: FC<SelectedSlicesProps> = memo((props) => {
  const { className, selectedSlices, onDrop, onDeleteSlice } = props;

  const [dragOvered, setDragOvered] = useState(false);

  const handleDragOver = (event: React.MouseEvent) => {
    event.preventDefault();
    setDragOvered(true);
  };

  const handleDragLeave = (event: React.MouseEvent) => {
    event.preventDefault();
    setDragOvered(false);
  };

  const handleDrop = (event: React.MouseEvent) => {
    event.preventDefault();
    onDrop();
    setDragOvered(false);
  };

  const handleDeleteSlice = (id: string) => {
    onDeleteSlice(id);
  };

  return (
    <WidgetCard className={cn(className, styles.selectedSlices)}>
      <div
        className={cn(dragOvered && styles.dragOvered)}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <h1>
          <FormattedMessage
            id="global.selectedSlices"
            defaultMessage="Selected slices"
          />
        </h1>
        <div className={cn(styles.container)}>
          {selectedSlices?.length
            ? selectedSlices.map((slice) => (
                <div>
                  <Slice
                    key={slice.id}
                    onRemoveSlice={handleDeleteSlice}
                    src={slice.src}
                  />
                </div>
              ))
            : null}
        </div>
        <div>
          <p className={styles.dragDescription}>
            <FormattedMessage
              id="global.dragDropPhotos"
              defaultMessage="Drag or add slices or photos to report"
            />
          </p>
        </div>
      </div>
    </WidgetCard>
  );
});
