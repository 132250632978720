/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { BrowserHeaders } from 'browser-headers';
import {
  InvitationTarget,
  Invitation,
  InvitationTarget_OrganizationMembership,
} from '../../model/dto_access';
import { Organization } from '../../model/dto_organization';
import { Patient } from '../../model/dto_patient';

export const protobufPackage = 'com.diagnocat.core';

export interface InviteReq {
  Contacts: InviteReq_RecipientContacts | undefined;
  UserID: string | undefined;
  Target: InvitationTarget | undefined;
  Comment: string;
}

export interface InviteReq_RecipientContacts {
  Phone: string;
  Email: string;
}

export interface InviteResp {
  Invitation: Invitation | undefined;
  /** if add not the first organization role */
  Organization: Organization | undefined;
}

export interface CancelInvitationReq {
  InvitationID: string;
}

export interface CancelInvitationResp {
  Invitation: Invitation | undefined;
}

export interface AcceptInvitationReq {
  InvitationID: string;
}

export interface AcceptInvitationResp {
  Invitations: Invitation[];
  Organization: Organization | undefined;
  Patient: Patient | undefined;
}

export interface RejectInvitationReq {
  InvitationID: string;
}

export interface RejectInvitationResp {
  Invitation: Invitation | undefined;
}

export interface RevokeRoleReq {
  UserID: string;
  OrganizationRole: InvitationTarget_OrganizationMembership | undefined;
  UnsharePatientID: string | undefined;
  UnshareStudyID: string | undefined;
  UnshareReportID: string | undefined;
}

export interface RevokeRoleResp {
  Organization: Organization | undefined;
  Patient: Patient | undefined;
}

export interface AddPatientTeamMemberReq {
  PatientID: string;
  UserIDs: string[];
}

export interface AddPatientTeamMemberResp {
  Patient: Patient | undefined;
}

export interface RevokePatientTeamAccessReq {
  PatientID: string;
  UserID: string;
}

export interface RevokePatientTeamAccessResp {
  Patient: Patient | undefined;
}

function createBaseInviteReq(): InviteReq {
  return {
    Contacts: undefined,
    UserID: undefined,
    Target: undefined,
    Comment: '',
  };
}

export const InviteReq = {
  encode(
    message: InviteReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Contacts !== undefined) {
      InviteReq_RecipientContacts.encode(
        message.Contacts,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.UserID !== undefined) {
      writer.uint32(18).string(message.UserID);
    }
    if (message.Target !== undefined) {
      InvitationTarget.encode(
        message.Target,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Comment !== '') {
      writer.uint32(34).string(message.Comment);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Contacts = InviteReq_RecipientContacts.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.UserID = reader.string();
          break;
        case 3:
          message.Target = InvitationTarget.decode(reader, reader.uint32());
          break;
        case 4:
          message.Comment = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InviteReq {
    return {
      Contacts: isSet(object.Contacts)
        ? InviteReq_RecipientContacts.fromJSON(object.Contacts)
        : undefined,
      UserID: isSet(object.UserID) ? String(object.UserID) : undefined,
      Target: isSet(object.Target)
        ? InvitationTarget.fromJSON(object.Target)
        : undefined,
      Comment: isSet(object.Comment) ? String(object.Comment) : '',
    };
  },

  toJSON(message: InviteReq): unknown {
    const obj: any = {};
    message.Contacts !== undefined &&
      (obj.Contacts = message.Contacts
        ? InviteReq_RecipientContacts.toJSON(message.Contacts)
        : undefined);
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.Target !== undefined &&
      (obj.Target = message.Target
        ? InvitationTarget.toJSON(message.Target)
        : undefined);
    message.Comment !== undefined && (obj.Comment = message.Comment);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InviteReq>, I>>(
    object: I,
  ): InviteReq {
    const message = createBaseInviteReq();
    message.Contacts =
      object.Contacts !== undefined && object.Contacts !== null
        ? InviteReq_RecipientContacts.fromPartial(object.Contacts)
        : undefined;
    message.UserID = object.UserID ?? undefined;
    message.Target =
      object.Target !== undefined && object.Target !== null
        ? InvitationTarget.fromPartial(object.Target)
        : undefined;
    message.Comment = object.Comment ?? '';
    return message;
  },
};

function createBaseInviteReq_RecipientContacts(): InviteReq_RecipientContacts {
  return { Phone: '', Email: '' };
}

export const InviteReq_RecipientContacts = {
  encode(
    message: InviteReq_RecipientContacts,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Phone !== '') {
      writer.uint32(10).string(message.Phone);
    }
    if (message.Email !== '') {
      writer.uint32(18).string(message.Email);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): InviteReq_RecipientContacts {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteReq_RecipientContacts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Phone = reader.string();
          break;
        case 2:
          message.Email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InviteReq_RecipientContacts {
    return {
      Phone: isSet(object.Phone) ? String(object.Phone) : '',
      Email: isSet(object.Email) ? String(object.Email) : '',
    };
  },

  toJSON(message: InviteReq_RecipientContacts): unknown {
    const obj: any = {};
    message.Phone !== undefined && (obj.Phone = message.Phone);
    message.Email !== undefined && (obj.Email = message.Email);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InviteReq_RecipientContacts>, I>>(
    object: I,
  ): InviteReq_RecipientContacts {
    const message = createBaseInviteReq_RecipientContacts();
    message.Phone = object.Phone ?? '';
    message.Email = object.Email ?? '';
    return message;
  },
};

function createBaseInviteResp(): InviteResp {
  return { Invitation: undefined, Organization: undefined };
}

export const InviteResp = {
  encode(
    message: InviteResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Invitation !== undefined) {
      Invitation.encode(message.Invitation, writer.uint32(10).fork()).ldelim();
    }
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Invitation = Invitation.decode(reader, reader.uint32());
          break;
        case 2:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InviteResp {
    return {
      Invitation: isSet(object.Invitation)
        ? Invitation.fromJSON(object.Invitation)
        : undefined,
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
    };
  },

  toJSON(message: InviteResp): unknown {
    const obj: any = {};
    message.Invitation !== undefined &&
      (obj.Invitation = message.Invitation
        ? Invitation.toJSON(message.Invitation)
        : undefined);
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<InviteResp>, I>>(
    object: I,
  ): InviteResp {
    const message = createBaseInviteResp();
    message.Invitation =
      object.Invitation !== undefined && object.Invitation !== null
        ? Invitation.fromPartial(object.Invitation)
        : undefined;
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    return message;
  },
};

function createBaseCancelInvitationReq(): CancelInvitationReq {
  return { InvitationID: '' };
}

export const CancelInvitationReq = {
  encode(
    message: CancelInvitationReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.InvitationID !== '') {
      writer.uint32(10).string(message.InvitationID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelInvitationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelInvitationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.InvitationID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CancelInvitationReq {
    return {
      InvitationID: isSet(object.InvitationID)
        ? String(object.InvitationID)
        : '',
    };
  },

  toJSON(message: CancelInvitationReq): unknown {
    const obj: any = {};
    message.InvitationID !== undefined &&
      (obj.InvitationID = message.InvitationID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CancelInvitationReq>, I>>(
    object: I,
  ): CancelInvitationReq {
    const message = createBaseCancelInvitationReq();
    message.InvitationID = object.InvitationID ?? '';
    return message;
  },
};

function createBaseCancelInvitationResp(): CancelInvitationResp {
  return { Invitation: undefined };
}

export const CancelInvitationResp = {
  encode(
    message: CancelInvitationResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Invitation !== undefined) {
      Invitation.encode(message.Invitation, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CancelInvitationResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelInvitationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Invitation = Invitation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CancelInvitationResp {
    return {
      Invitation: isSet(object.Invitation)
        ? Invitation.fromJSON(object.Invitation)
        : undefined,
    };
  },

  toJSON(message: CancelInvitationResp): unknown {
    const obj: any = {};
    message.Invitation !== undefined &&
      (obj.Invitation = message.Invitation
        ? Invitation.toJSON(message.Invitation)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CancelInvitationResp>, I>>(
    object: I,
  ): CancelInvitationResp {
    const message = createBaseCancelInvitationResp();
    message.Invitation =
      object.Invitation !== undefined && object.Invitation !== null
        ? Invitation.fromPartial(object.Invitation)
        : undefined;
    return message;
  },
};

function createBaseAcceptInvitationReq(): AcceptInvitationReq {
  return { InvitationID: '' };
}

export const AcceptInvitationReq = {
  encode(
    message: AcceptInvitationReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.InvitationID !== '') {
      writer.uint32(10).string(message.InvitationID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcceptInvitationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptInvitationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.InvitationID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AcceptInvitationReq {
    return {
      InvitationID: isSet(object.InvitationID)
        ? String(object.InvitationID)
        : '',
    };
  },

  toJSON(message: AcceptInvitationReq): unknown {
    const obj: any = {};
    message.InvitationID !== undefined &&
      (obj.InvitationID = message.InvitationID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AcceptInvitationReq>, I>>(
    object: I,
  ): AcceptInvitationReq {
    const message = createBaseAcceptInvitationReq();
    message.InvitationID = object.InvitationID ?? '';
    return message;
  },
};

function createBaseAcceptInvitationResp(): AcceptInvitationResp {
  return { Invitations: [], Organization: undefined, Patient: undefined };
}

export const AcceptInvitationResp = {
  encode(
    message: AcceptInvitationResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Invitations) {
      Invitation.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AcceptInvitationResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptInvitationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Invitations.push(Invitation.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        case 3:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AcceptInvitationResp {
    return {
      Invitations: Array.isArray(object?.Invitations)
        ? object.Invitations.map((e: any) => Invitation.fromJSON(e))
        : [],
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: AcceptInvitationResp): unknown {
    const obj: any = {};
    if (message.Invitations) {
      obj.Invitations = message.Invitations.map((e) =>
        e ? Invitation.toJSON(e) : undefined,
      );
    } else {
      obj.Invitations = [];
    }
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AcceptInvitationResp>, I>>(
    object: I,
  ): AcceptInvitationResp {
    const message = createBaseAcceptInvitationResp();
    message.Invitations =
      object.Invitations?.map((e) => Invitation.fromPartial(e)) || [];
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseRejectInvitationReq(): RejectInvitationReq {
  return { InvitationID: '' };
}

export const RejectInvitationReq = {
  encode(
    message: RejectInvitationReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.InvitationID !== '') {
      writer.uint32(10).string(message.InvitationID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RejectInvitationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRejectInvitationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.InvitationID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RejectInvitationReq {
    return {
      InvitationID: isSet(object.InvitationID)
        ? String(object.InvitationID)
        : '',
    };
  },

  toJSON(message: RejectInvitationReq): unknown {
    const obj: any = {};
    message.InvitationID !== undefined &&
      (obj.InvitationID = message.InvitationID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RejectInvitationReq>, I>>(
    object: I,
  ): RejectInvitationReq {
    const message = createBaseRejectInvitationReq();
    message.InvitationID = object.InvitationID ?? '';
    return message;
  },
};

function createBaseRejectInvitationResp(): RejectInvitationResp {
  return { Invitation: undefined };
}

export const RejectInvitationResp = {
  encode(
    message: RejectInvitationResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Invitation !== undefined) {
      Invitation.encode(message.Invitation, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RejectInvitationResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRejectInvitationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Invitation = Invitation.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RejectInvitationResp {
    return {
      Invitation: isSet(object.Invitation)
        ? Invitation.fromJSON(object.Invitation)
        : undefined,
    };
  },

  toJSON(message: RejectInvitationResp): unknown {
    const obj: any = {};
    message.Invitation !== undefined &&
      (obj.Invitation = message.Invitation
        ? Invitation.toJSON(message.Invitation)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RejectInvitationResp>, I>>(
    object: I,
  ): RejectInvitationResp {
    const message = createBaseRejectInvitationResp();
    message.Invitation =
      object.Invitation !== undefined && object.Invitation !== null
        ? Invitation.fromPartial(object.Invitation)
        : undefined;
    return message;
  },
};

function createBaseRevokeRoleReq(): RevokeRoleReq {
  return {
    UserID: '',
    OrganizationRole: undefined,
    UnsharePatientID: undefined,
    UnshareStudyID: undefined,
    UnshareReportID: undefined,
  };
}

export const RevokeRoleReq = {
  encode(
    message: RevokeRoleReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.OrganizationRole !== undefined) {
      InvitationTarget_OrganizationMembership.encode(
        message.OrganizationRole,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.UnsharePatientID !== undefined) {
      writer.uint32(26).string(message.UnsharePatientID);
    }
    if (message.UnshareStudyID !== undefined) {
      writer.uint32(34).string(message.UnshareStudyID);
    }
    if (message.UnshareReportID !== undefined) {
      writer.uint32(42).string(message.UnshareReportID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeRoleReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevokeRoleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.OrganizationRole =
            InvitationTarget_OrganizationMembership.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 3:
          message.UnsharePatientID = reader.string();
          break;
        case 4:
          message.UnshareStudyID = reader.string();
          break;
        case 5:
          message.UnshareReportID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RevokeRoleReq {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      OrganizationRole: isSet(object.OrganizationRole)
        ? InvitationTarget_OrganizationMembership.fromJSON(
            object.OrganizationRole,
          )
        : undefined,
      UnsharePatientID: isSet(object.UnsharePatientID)
        ? String(object.UnsharePatientID)
        : undefined,
      UnshareStudyID: isSet(object.UnshareStudyID)
        ? String(object.UnshareStudyID)
        : undefined,
      UnshareReportID: isSet(object.UnshareReportID)
        ? String(object.UnshareReportID)
        : undefined,
    };
  },

  toJSON(message: RevokeRoleReq): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.OrganizationRole !== undefined &&
      (obj.OrganizationRole = message.OrganizationRole
        ? InvitationTarget_OrganizationMembership.toJSON(
            message.OrganizationRole,
          )
        : undefined);
    message.UnsharePatientID !== undefined &&
      (obj.UnsharePatientID = message.UnsharePatientID);
    message.UnshareStudyID !== undefined &&
      (obj.UnshareStudyID = message.UnshareStudyID);
    message.UnshareReportID !== undefined &&
      (obj.UnshareReportID = message.UnshareReportID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RevokeRoleReq>, I>>(
    object: I,
  ): RevokeRoleReq {
    const message = createBaseRevokeRoleReq();
    message.UserID = object.UserID ?? '';
    message.OrganizationRole =
      object.OrganizationRole !== undefined && object.OrganizationRole !== null
        ? InvitationTarget_OrganizationMembership.fromPartial(
            object.OrganizationRole,
          )
        : undefined;
    message.UnsharePatientID = object.UnsharePatientID ?? undefined;
    message.UnshareStudyID = object.UnshareStudyID ?? undefined;
    message.UnshareReportID = object.UnshareReportID ?? undefined;
    return message;
  },
};

function createBaseRevokeRoleResp(): RevokeRoleResp {
  return { Organization: undefined, Patient: undefined };
}

export const RevokeRoleResp = {
  encode(
    message: RevokeRoleResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevokeRoleResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevokeRoleResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        case 2:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RevokeRoleResp {
    return {
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: RevokeRoleResp): unknown {
    const obj: any = {};
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RevokeRoleResp>, I>>(
    object: I,
  ): RevokeRoleResp {
    const message = createBaseRevokeRoleResp();
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseAddPatientTeamMemberReq(): AddPatientTeamMemberReq {
  return { PatientID: '', UserIDs: [] };
}

export const AddPatientTeamMemberReq = {
  encode(
    message: AddPatientTeamMemberReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    for (const v of message.UserIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddPatientTeamMemberReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddPatientTeamMemberReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.UserIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddPatientTeamMemberReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      UserIDs: Array.isArray(object?.UserIDs)
        ? object.UserIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: AddPatientTeamMemberReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    if (message.UserIDs) {
      obj.UserIDs = message.UserIDs.map((e) => e);
    } else {
      obj.UserIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddPatientTeamMemberReq>, I>>(
    object: I,
  ): AddPatientTeamMemberReq {
    const message = createBaseAddPatientTeamMemberReq();
    message.PatientID = object.PatientID ?? '';
    message.UserIDs = object.UserIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseAddPatientTeamMemberResp(): AddPatientTeamMemberResp {
  return { Patient: undefined };
}

export const AddPatientTeamMemberResp = {
  encode(
    message: AddPatientTeamMemberResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddPatientTeamMemberResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddPatientTeamMemberResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddPatientTeamMemberResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: AddPatientTeamMemberResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddPatientTeamMemberResp>, I>>(
    object: I,
  ): AddPatientTeamMemberResp {
    const message = createBaseAddPatientTeamMemberResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseRevokePatientTeamAccessReq(): RevokePatientTeamAccessReq {
  return { PatientID: '', UserID: '' };
}

export const RevokePatientTeamAccessReq = {
  encode(
    message: RevokePatientTeamAccessReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.UserID !== '') {
      writer.uint32(18).string(message.UserID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RevokePatientTeamAccessReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevokePatientTeamAccessReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.UserID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RevokePatientTeamAccessReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
    };
  },

  toJSON(message: RevokePatientTeamAccessReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.UserID !== undefined && (obj.UserID = message.UserID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RevokePatientTeamAccessReq>, I>>(
    object: I,
  ): RevokePatientTeamAccessReq {
    const message = createBaseRevokePatientTeamAccessReq();
    message.PatientID = object.PatientID ?? '';
    message.UserID = object.UserID ?? '';
    return message;
  },
};

function createBaseRevokePatientTeamAccessResp(): RevokePatientTeamAccessResp {
  return { Patient: undefined };
}

export const RevokePatientTeamAccessResp = {
  encode(
    message: RevokePatientTeamAccessResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RevokePatientTeamAccessResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevokePatientTeamAccessResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RevokePatientTeamAccessResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: RevokePatientTeamAccessResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RevokePatientTeamAccessResp>, I>>(
    object: I,
  ): RevokePatientTeamAccessResp {
    const message = createBaseRevokePatientTeamAccessResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

export interface AccessService {
  /**
   * Invite:
   * - invite user to organization (first or subsequent roles)
   * - sharing patient/study/report for user which is not an employee of this patient's organization
   *
   * Errors:
   * 1. Common errors:
   * - type: NotFoundError, Entity: recipient (if user not found by `InviteReq.Recipient.UserID`)
   * 2. If Target == Organization:
   * - type: RequiredArgumentMissingError, Argument: target organizationID (if `InviteReq.Target.Organization.OrganisationID` is empty)
   * - type: NotFoundError, Entity: target organization (if organization not found by `InviteReq.Target.Organization.OrganisationID`)
   * - type: BadRequestError, Reason: target organization deleted (if remote organization found by `InviteReq.Target.Organization.OrganisationID`)
   * - type: InvalidArgumentValueError, Argument: target organization user role - (if `InviteReq.Target.Organization.Role` == `TypeInvalidValue`)
   * - type: PermissionDeniedError, Reason: you do not have organization permission `CanEditStaff` (if user does not have the appropriate permission)
   * 3. If Target == Patient:
   * - type: RequiredArgumentMissingError, Argument: target patientID (if `InviteReq.Target.Patient.PatientID` is empty)
   * - type: RequiredArgumentMissingError, Argument: target patient recipientPermissions (if `InviteReq.Target.Patient.RecipientPermissions` == null)
   * - type: NotFoundError, Entity: target patient (if organization not found by `InviteReq.Target.Patient.PatientID`)
   * - type: BadRequestError, Reason: target patient deleted (if remote patient found by `InviteReq.Target.Patient.PatientID`)
   * - type: PermissionDeniedError, Reason: you do not have patient permission `CanShare`
   * - type: BadRequestError, Reason: recipient is already a team member (if recipient already has patient role)
   * 4. If Target == Study:
   * - type: RequiredArgumentMissingError, Argument: target studyID (if `InviteReq.Target.Study.StudyID` is empty)
   * - type: RequiredArgumentMissingError, Argument: target study recipientPermissions (if `InviteReq.Target.Study.RecipientPermissions` == null)
   * - type: NotFoundError, Entity: target study (if study not found by `InviteReq.Target.Study.StudyID`)
   * - type: BadRequestError, Reason: target study deleted (if remote study found by `InviteReq.Target.Study.StudyID`)
   * - type: PermissionDeniedError, Reason: you do not have patient permission `CanShare`
   * - type: BadRequestError, Reason: recipient is already a team member (if recipient already has patient role)
   * 5. If Target == Report:
   * - type: RequiredArgumentMissingError, Argument: target reportID (if `InviteReq.Target.Report.ReportID` is empty)
   * - type: RequiredArgumentMissingError, Argument: target report recipientPermissions (if `InviteReq.Target.Report.RecipientPermissions` == null)
   * - type: NotFoundError, Entity: target report (if report not found by `InviteReq.Target.Report.ReportID`)
   * - type: BadRequestError, Reason: target report deleted (if remote report found by `InviteReq.Target.Report.ReportID`)
   * - type: PermissionDeniedError, Reason: you do not have patient permission `CanShare`
   * - type: BadRequestError, Reason: recipient is already a team member (if recipient already has patient role)
   */
  Invite(
    request: DeepPartial<InviteReq>,
    metadata?: grpc.Metadata,
  ): Promise<InviteResp>;
  /**
   * Cancel invitation: if invitation not accepted
   *
   *
   * Available for:
   * - invitation creator (current userID == `invitation.Created.By`)
   * - current user if `OrganizationPermissions.CanEditStaff == true` and `InvitationTarget == Organization`
   * - current user if `PatientPermissions.CanCancelOthersSharings == true` and `InvitationTarget != Organization`
   *
   * Permission:
   * - `OrganizationPermissions.CanEditStaff`
   * - `PatientPermissions.CanCancelOthersSharings`
   */
  CancelInvitation(
    request: DeepPartial<CancelInvitationReq>,
    metadata?: grpc.Metadata,
  ): Promise<CancelInvitationResp>;
  /**
   * Accept invitation
   *
   * Errors:
   * - type: BadRequestError, Reason: invitation closed
   * - type: PermissionDeniedError, Reason: you are not the recipient
   */
  AcceptInvitation(
    request: DeepPartial<AcceptInvitationReq>,
    metadata?: grpc.Metadata,
  ): Promise<AcceptInvitationResp>;
  /**
   * <br>
   * Reject invitation
   *
   * Errors:
   * - type: BadRequestError, Reason: invitation closed
   * - type: PermissionDeniedError, Reason: you are not the recipient
   */
  RejectInvitation(
    request: DeepPartial<RejectInvitationReq>,
    metadata?: grpc.Metadata,
  ): Promise<RejectInvitationResp>;
  /**
   * Revoke role:
   * - if `RevokeRoleReq.Target is OrganizationRole` and the last organization role is revoked -> all patient roles in that organization are revoked for the user
   *
   * Permission:
   * - `OrganizationPermissions.CanEditStaff` (if `RevokeRoleReq.Target is OrganizationRole`)
   * - `PatientPermissions.CanCancelOthersSharings` (if `RevokeRoleReq.Target is not OrganizationRole`)
   *
   * Errors:
   * - type: InvalidArgumentValueError, Argument: user does not have this role - (if `RevokeRoleReq.Target is OrganizationRole`)
   */
  RevokeRole(
    request: DeepPartial<RevokeRoleReq>,
    metadata?: grpc.Metadata,
  ): Promise<RevokeRoleResp>;
  /**
   * Added user to patient team
   * - all invitations to this patient, his studies and reports where user is `Recipient` are closed
   *
   * Permission: `PatientPermissions.CanEditTeam`
   *
   * Errors:
   * - type: BadRequestError, Reason: user is already in patient team
   * - type: BadRequestError, Reason: user does not have roles in this organization
   */
  AddPatientTeamMember(
    request: DeepPartial<AddPatientTeamMemberReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddPatientTeamMemberResp>;
  /**
   * Revoked patient team member access
   *
   * Permission: `PatientPermissions.CanEditTeam`
   *
   * Errors:
   * - type: BadRequestError, Argument: user does not have this role
   */
  RevokePatientTeamAccess(
    request: DeepPartial<RevokePatientTeamAccessReq>,
    metadata?: grpc.Metadata,
  ): Promise<RevokePatientTeamAccessResp>;
}

export class AccessServiceClientImpl implements AccessService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Invite = this.Invite.bind(this);
    this.CancelInvitation = this.CancelInvitation.bind(this);
    this.AcceptInvitation = this.AcceptInvitation.bind(this);
    this.RejectInvitation = this.RejectInvitation.bind(this);
    this.RevokeRole = this.RevokeRole.bind(this);
    this.AddPatientTeamMember = this.AddPatientTeamMember.bind(this);
    this.RevokePatientTeamAccess = this.RevokePatientTeamAccess.bind(this);
  }

  Invite(
    request: DeepPartial<InviteReq>,
    metadata?: grpc.Metadata,
  ): Promise<InviteResp> {
    return this.rpc.unary(
      AccessServiceInviteDesc,
      InviteReq.fromPartial(request),
      metadata,
    );
  }

  CancelInvitation(
    request: DeepPartial<CancelInvitationReq>,
    metadata?: grpc.Metadata,
  ): Promise<CancelInvitationResp> {
    return this.rpc.unary(
      AccessServiceCancelInvitationDesc,
      CancelInvitationReq.fromPartial(request),
      metadata,
    );
  }

  AcceptInvitation(
    request: DeepPartial<AcceptInvitationReq>,
    metadata?: grpc.Metadata,
  ): Promise<AcceptInvitationResp> {
    return this.rpc.unary(
      AccessServiceAcceptInvitationDesc,
      AcceptInvitationReq.fromPartial(request),
      metadata,
    );
  }

  RejectInvitation(
    request: DeepPartial<RejectInvitationReq>,
    metadata?: grpc.Metadata,
  ): Promise<RejectInvitationResp> {
    return this.rpc.unary(
      AccessServiceRejectInvitationDesc,
      RejectInvitationReq.fromPartial(request),
      metadata,
    );
  }

  RevokeRole(
    request: DeepPartial<RevokeRoleReq>,
    metadata?: grpc.Metadata,
  ): Promise<RevokeRoleResp> {
    return this.rpc.unary(
      AccessServiceRevokeRoleDesc,
      RevokeRoleReq.fromPartial(request),
      metadata,
    );
  }

  AddPatientTeamMember(
    request: DeepPartial<AddPatientTeamMemberReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddPatientTeamMemberResp> {
    return this.rpc.unary(
      AccessServiceAddPatientTeamMemberDesc,
      AddPatientTeamMemberReq.fromPartial(request),
      metadata,
    );
  }

  RevokePatientTeamAccess(
    request: DeepPartial<RevokePatientTeamAccessReq>,
    metadata?: grpc.Metadata,
  ): Promise<RevokePatientTeamAccessResp> {
    return this.rpc.unary(
      AccessServiceRevokePatientTeamAccessDesc,
      RevokePatientTeamAccessReq.fromPartial(request),
      metadata,
    );
  }
}

export const AccessServiceDesc = {
  serviceName: 'com.diagnocat.core.AccessService',
};

export const AccessServiceInviteDesc: UnaryMethodDefinitionish = {
  methodName: 'Invite',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return InviteReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...InviteResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceCancelInvitationDesc: UnaryMethodDefinitionish = {
  methodName: 'CancelInvitation',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CancelInvitationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CancelInvitationResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceAcceptInvitationDesc: UnaryMethodDefinitionish = {
  methodName: 'AcceptInvitation',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AcceptInvitationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AcceptInvitationResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceRejectInvitationDesc: UnaryMethodDefinitionish = {
  methodName: 'RejectInvitation',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RejectInvitationReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RejectInvitationResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceRevokeRoleDesc: UnaryMethodDefinitionish = {
  methodName: 'RevokeRole',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RevokeRoleReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RevokeRoleResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceAddPatientTeamMemberDesc: UnaryMethodDefinitionish = {
  methodName: 'AddPatientTeamMember',
  service: AccessServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddPatientTeamMemberReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AddPatientTeamMemberResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AccessServiceRevokePatientTeamAccessDesc: UnaryMethodDefinitionish =
  {
    methodName: 'RevokePatientTeamAccess',
    service: AccessServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return RevokePatientTeamAccessReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...RevokePatientTeamAccessResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
