import { compose, filter, includes, isEmpty, last, map, uniq } from 'ramda';

import {
  conditionModel,
  shouldConditionItemBeShown,
} from '@/entities/condition';
import { reportsModel } from '@/entities/reports';
import { mapCondtitionsToTeethIDs } from '@/entities/tooth/lib/mapCondtitionsToTeethIDs';
import { useAppSelector } from '@/shared/hooks';
import { toothModel } from '@/entities/tooth';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { ReferralsGroupsType } from '../config/referralsGroups.type';
import { filterConditionByReferral } from '../lib/filterConditionByReferral';
import { isThisReferralGroupForThisRepport } from '../lib/isThisReferralGroupForThisRepport';

export const useReferralGroup = (
  referralGroup: ReferralsGroupsType,
  reportID: string,
): {
  referralGroupTeeth: Tooth[];
  lastToothISOnumber: number;
  dontShowThisReferral: boolean;
} => {
  const report = useAppSelector((state) =>
    reportsModel.selectors.selectById(state, reportID),
  );

  const conditions = useAppSelector((state) =>
    conditionModel.selectors
      .selectByReportID(state, reportID)
      .filter(shouldConditionItemBeShown()),
  );

  const ROITeeth = useAppSelector((state) =>
    toothModel.selectors.selectByReportIDAndROI(state, reportID),
  );

  const isDataNotCome =
    isEmpty(report) || isEmpty(conditions) || isEmpty(ROITeeth);

  if (isDataNotCome) {
    return {
      referralGroupTeeth: [],
      lastToothISOnumber: 0,
      dontShowThisReferral: true,
    };
  }

  const teethIDsFromConditions = compose(
    uniq,
    mapCondtitionsToTeethIDs,
    filterConditionByReferral,
  )(conditions, referralGroup);

  const referralGroupTeeth = filter(
    (tooth) => includes(tooth.ID, teethIDsFromConditions),
    ROITeeth,
  );

  const lastToothISOnumber = last(
    map((tooth) => tooth.ISONumber, referralGroupTeeth),
  );

  const dontShowThisReferral = !(
    isThisReferralGroupForThisRepport(referralGroup, report.Type) &&
    !isEmpty(referralGroupTeeth)
  );

  return { referralGroupTeeth, lastToothISOnumber, dontShowThisReferral };
};
