// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScanUidCopy-module__container--fBxo5rl3{display:flex;align-items:center;gap:12px}.ScanUidCopy-module__button--u96H20CE{display:flex;align-items:center}.ScanUidCopy-module__title--rObXDo34{color:var(--greytext2)}.ScanUidCopy-module__scanUid--eyfq5wbI{color:var(--bw)}", "",{"version":3,"sources":["webpack://./src/features/scanUidCopy/ui/ScanUidCopy/ScanUidCopy.module.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,sCACE,YAAA,CACA,kBAAA,CAGF,qCACE,sBAAA,CAGF,uCACE,eAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n}\n\n.button {\n  display: flex;\n  align-items: center;\n}\n\n.title {\n  color: var(--greytext2);\n}\n\n.scanUid {\n  color: var(--bw);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ScanUidCopy-module__container--fBxo5rl3",
	"button": "ScanUidCopy-module__button--u96H20CE",
	"title": "ScanUidCopy-module__title--rObXDo34",
	"scanUid": "ScanUidCopy-module__scanUid--eyfq5wbI"
};
export default ___CSS_LOADER_EXPORT___;
