import { defineMessages } from 'react-intl';

import { OrganizationSpecialization } from '@/shared/api/protocol_gen/model/dto_organization';

export const specialityType = defineMessages<OrganizationSpecialization>({
  [OrganizationSpecialization.OrganizationSpecializationGeneral]: {
    id: 'OrganizationSpecialization.OrganizationSpecializationGeneral',
    defaultMessage: 'General',
  },
  [OrganizationSpecialization.OrganizationSpecializationPathologist]: {
    id: 'OrganizationSpecialization.OrganizationSpecializationPathologist',
    defaultMessage: 'Pathologist',
  },
  [OrganizationSpecialization.OrganizationSpecializationRadiologist]: {
    id: 'OrganizationSpecialization.OrganizationSpecializationRadiologist',
    defaultMessage: 'Pathologist',
  },
  [OrganizationSpecialization.OrganizationSpecializationChief]: {
    id: 'OrganizationSpecialization.OrganizationSpecializationChief',
    defaultMessage: 'Chief',
  },
  [OrganizationSpecialization.OrganizationSpecializationEndodontist]: {
    id: 'OrganizationSpecialization.OrganizationSpecializationEndodontist',
    defaultMessage: 'Endodontist',
  },
  [OrganizationSpecialization.OrganizationSpecializationOrthodontist]: {
    id: 'OrganizationSpecialization.OrganizationSpecializationOrthodontist',
    defaultMessage: 'Orthodontist',
  },
  [OrganizationSpecialization.OrganizationSpecializationPeriodontologist]: {
    id: 'OrganizationSpecialization.OrganizationSpecializationPeriodontologist',
    defaultMessage: 'Periodontologist',
  },
  [OrganizationSpecialization.OrganizationSpecializationProsthodontist]: {
    id: 'OrganizationSpecialization.OrganizationSpecializationProsthodontist',
    defaultMessage: 'Prosthodontist',
  },
  [OrganizationSpecialization.OrganizationSpecializationPediatric]: {
    id: 'OrganizationSpecialization.OrganizationSpecializationPediatric',
    defaultMessage: 'Pediatric',
  },
  [OrganizationSpecialization.OrganizationSpecializationSurgeon]: {
    id: 'OrganizationSpecialization.OrganizationSpecializationSurgeon',
    defaultMessage: 'Surgeon',
  },
  [OrganizationSpecialization.UNRECOGNIZED]: {
    id: 'OrganizationSpecialization.UNRECOGNIZED',
    defaultMessage: 'UNRECOGNIZED',
  },
});
