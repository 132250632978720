import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';

import { PatientPayload } from '../config';

export const getEditPatientDefaultValues = (
  patient?: Patient,
): Partial<PatientPayload> =>
  patient
    ? {
        patientExternalID: patient.ExternalID,
        firstName: patient.PersonalData.FirstName,
        lastName: patient.PersonalData.LastName,
        dateOfBirth: new Date(patient.PersonalData.DateOfBirth),
        email: patient.PersonalData.Emails[0],
        treatingDoctor: patient.Doctors.map(({ UserID }) => UserID),
        gender: patient.Gender,
      }
    : {};
