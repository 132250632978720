/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import {
  ServiceType,
  serviceTypeFromJSON,
  serviceTypeToJSON,
} from '../../api/billing/dto_services';
import { Observable } from 'rxjs';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';
import { Account, AvailableProducts } from '../../api/billing/dto_account';
import { Order, Consumption } from '../../api/billing/dto_order';

export const protobufPackage = 'com.diagnocat.billing';

export interface AccountInfoStreamReq {
  AccountID: string | undefined;
  MyAccount: boolean | undefined;
  OwnerID: string | undefined;
  OrganizationID: string | undefined;
}

export interface AccountInfoStreamResp {
  AccountInfo: Account | undefined;
  Order: Order | undefined;
  HistoricalOrders: AccountInfoStreamResp_Orders | undefined;
  Consumption: Consumption | undefined;
  ServiceGroups: ServiceGroupsList | undefined;
  Ping: Ping | undefined;
}

export interface AccountInfoStreamResp_Orders {
  Orders: Order[];
}

export interface Ping {}

export interface ServiceGroupsList {
  ServiceGroups: ServiceGroupsList_GroupInfo[];
}

export interface ServiceGroupsList_GroupInfo {
  Group: ServiceType;
  Children: ServiceType[];
}

export interface CreateOrderReq {
  AccountID: string;
  SubscriptionAdd: CreateOrderReq_SubscriptionAddEffect | undefined;
  AutoCharge: boolean;
}

export interface CreateOrderReq_SubscriptionAddEffect {
  SubscriptionModelID: string;
  LifeTimeMonths: number;
}

export interface CreateOrderResp {
  Order: Order | undefined;
  InvoicePaymentURL: string;
}

export interface ProcessOrderReq {
  OrderID: string;
}

export interface ProcessOrderResp {}

export interface PayOrderReq {
  Recurring: boolean;
  OrderID: string;
  AutoCharge: boolean;
}

export interface PayOrderResp {
  Paid: boolean | undefined;
  URL: string | undefined;
  ManualPaymentOnly: boolean | undefined;
}

export interface CreateAccountPaymentMethodReq {
  AccountID: string;
  SuccessURL: string;
  CancelURL: string;
}

export interface CreateAccountPaymentMethodResp {
  URL: string;
}

export interface DeleteAccountPaymentMethodReq {
  AccountID: string;
}

export interface DeleteAccountPaymentMethodResp {}

export interface SetAccountBillingZoneReq {
  AccountID: string;
  BillingZone: string;
}

export interface SetAccountBillingZoneResp {
  account: Account | undefined;
}

export interface RemoveSubscriptionStateReq {
  AccountID: string;
  SubscriptionStateID: string;
}

export interface RemoveSubscriptionStateResp {}

export interface SetAvailableProductsReq {
  AvailableProducts: AvailableProducts | undefined;
}

export interface SetAvailableProductsResp {
  AvailableProducts: AvailableProducts | undefined;
}

export interface GetAllBillingZonesReq {}

export interface GetAllBillingZonesResp {
  BillingZones: string[];
}

export interface GetAvailableProductsReq {
  ByID: string | undefined;
  ByAccountID: string | undefined;
  ByGeoZone: string | undefined;
}

export interface GetAvailableProductsResp {
  AvailableProducts: AvailableProducts | undefined;
}

export interface GetAllAvailableProductsReq {}

export interface GetAllAvailableProductsResp {
  AvailableProductsList: AvailableProducts[];
}

export interface SetIndividualConditionsReq {
  AccountID: string;
  AvailableProducts: AvailableProducts | undefined;
}

export interface SetIndividualConditionsResp {
  Account: Account | undefined;
}

export interface SetSubscriptionAutoRenewalReq {
  AccountID: string;
  SubscriptionStateID: string;
  AutoRenewal: boolean;
}

export interface SetSubscriptionAutoRenewalResp {}

export interface SetSubscriptionAutoChargeReq {
  AccountID: string;
  SubscriptionStateID: string;
  AutoCharge: boolean;
}

export interface SetSubscriptionAutoChargeResp {}

export interface SetAccountAddressReq {
  AccountID: string;
  Address: string;
}

export interface SetAccountAddressResp {}

export interface SetAccountPhoneReq {
  AccountID: string;
  Phone: string;
}

export interface SetAccountPhoneResp {}

export interface GetPaymentDocumentByOrderReq {
  OrderID: string;
}

export interface GetPaymentDocumentByOrderResp {
  document: Uint8Array;
}

export interface SetTransactionNumberToOrderReq {
  OrderID: string;
  TransactionNumber: string;
}

export interface SetTransactionNumberToOrderResp {}

export interface TestExpireSubscriptionAfterReq {
  AccountID: string;
  SubscriptionStateID: string;
  Seconds: number;
}

export interface TestExpireSubscriptionAfterResp {}

export interface TestSetAccountWithoutStripeInvoicesReq {
  AccountID: string;
  WithoutStripeInvoices: boolean;
}

export interface TestSetAccountWithoutStripeInvoicesResp {}

function createBaseAccountInfoStreamReq(): AccountInfoStreamReq {
  return {
    AccountID: undefined,
    MyAccount: undefined,
    OwnerID: undefined,
    OrganizationID: undefined,
  };
}

export const AccountInfoStreamReq = {
  encode(
    message: AccountInfoStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== undefined) {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.MyAccount !== undefined) {
      writer.uint32(16).bool(message.MyAccount);
    }
    if (message.OwnerID !== undefined) {
      writer.uint32(26).string(message.OwnerID);
    }
    if (message.OrganizationID !== undefined) {
      writer.uint32(34).string(message.OrganizationID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AccountInfoStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountInfoStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.MyAccount = reader.bool();
          break;
        case 3:
          message.OwnerID = reader.string();
          break;
        case 4:
          message.OrganizationID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountInfoStreamReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : undefined,
      MyAccount: isSet(object.MyAccount)
        ? Boolean(object.MyAccount)
        : undefined,
      OwnerID: isSet(object.OwnerID) ? String(object.OwnerID) : undefined,
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : undefined,
    };
  },

  toJSON(message: AccountInfoStreamReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.MyAccount !== undefined && (obj.MyAccount = message.MyAccount);
    message.OwnerID !== undefined && (obj.OwnerID = message.OwnerID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AccountInfoStreamReq>, I>>(
    object: I,
  ): AccountInfoStreamReq {
    const message = createBaseAccountInfoStreamReq();
    message.AccountID = object.AccountID ?? undefined;
    message.MyAccount = object.MyAccount ?? undefined;
    message.OwnerID = object.OwnerID ?? undefined;
    message.OrganizationID = object.OrganizationID ?? undefined;
    return message;
  },
};

function createBaseAccountInfoStreamResp(): AccountInfoStreamResp {
  return {
    AccountInfo: undefined,
    Order: undefined,
    HistoricalOrders: undefined,
    Consumption: undefined,
    ServiceGroups: undefined,
    Ping: undefined,
  };
}

export const AccountInfoStreamResp = {
  encode(
    message: AccountInfoStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountInfo !== undefined) {
      Account.encode(message.AccountInfo, writer.uint32(10).fork()).ldelim();
    }
    if (message.Order !== undefined) {
      Order.encode(message.Order, writer.uint32(18).fork()).ldelim();
    }
    if (message.HistoricalOrders !== undefined) {
      AccountInfoStreamResp_Orders.encode(
        message.HistoricalOrders,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Consumption !== undefined) {
      Consumption.encode(
        message.Consumption,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.ServiceGroups !== undefined) {
      ServiceGroupsList.encode(
        message.ServiceGroups,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.Ping !== undefined) {
      Ping.encode(message.Ping, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AccountInfoStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountInfoStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountInfo = Account.decode(reader, reader.uint32());
          break;
        case 2:
          message.Order = Order.decode(reader, reader.uint32());
          break;
        case 3:
          message.HistoricalOrders = AccountInfoStreamResp_Orders.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.Consumption = Consumption.decode(reader, reader.uint32());
          break;
        case 5:
          message.ServiceGroups = ServiceGroupsList.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 6:
          message.Ping = Ping.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountInfoStreamResp {
    return {
      AccountInfo: isSet(object.AccountInfo)
        ? Account.fromJSON(object.AccountInfo)
        : undefined,
      Order: isSet(object.Order) ? Order.fromJSON(object.Order) : undefined,
      HistoricalOrders: isSet(object.HistoricalOrders)
        ? AccountInfoStreamResp_Orders.fromJSON(object.HistoricalOrders)
        : undefined,
      Consumption: isSet(object.Consumption)
        ? Consumption.fromJSON(object.Consumption)
        : undefined,
      ServiceGroups: isSet(object.ServiceGroups)
        ? ServiceGroupsList.fromJSON(object.ServiceGroups)
        : undefined,
      Ping: isSet(object.Ping) ? Ping.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: AccountInfoStreamResp): unknown {
    const obj: any = {};
    message.AccountInfo !== undefined &&
      (obj.AccountInfo = message.AccountInfo
        ? Account.toJSON(message.AccountInfo)
        : undefined);
    message.Order !== undefined &&
      (obj.Order = message.Order ? Order.toJSON(message.Order) : undefined);
    message.HistoricalOrders !== undefined &&
      (obj.HistoricalOrders = message.HistoricalOrders
        ? AccountInfoStreamResp_Orders.toJSON(message.HistoricalOrders)
        : undefined);
    message.Consumption !== undefined &&
      (obj.Consumption = message.Consumption
        ? Consumption.toJSON(message.Consumption)
        : undefined);
    message.ServiceGroups !== undefined &&
      (obj.ServiceGroups = message.ServiceGroups
        ? ServiceGroupsList.toJSON(message.ServiceGroups)
        : undefined);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Ping.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AccountInfoStreamResp>, I>>(
    object: I,
  ): AccountInfoStreamResp {
    const message = createBaseAccountInfoStreamResp();
    message.AccountInfo =
      object.AccountInfo !== undefined && object.AccountInfo !== null
        ? Account.fromPartial(object.AccountInfo)
        : undefined;
    message.Order =
      object.Order !== undefined && object.Order !== null
        ? Order.fromPartial(object.Order)
        : undefined;
    message.HistoricalOrders =
      object.HistoricalOrders !== undefined && object.HistoricalOrders !== null
        ? AccountInfoStreamResp_Orders.fromPartial(object.HistoricalOrders)
        : undefined;
    message.Consumption =
      object.Consumption !== undefined && object.Consumption !== null
        ? Consumption.fromPartial(object.Consumption)
        : undefined;
    message.ServiceGroups =
      object.ServiceGroups !== undefined && object.ServiceGroups !== null
        ? ServiceGroupsList.fromPartial(object.ServiceGroups)
        : undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Ping.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBaseAccountInfoStreamResp_Orders(): AccountInfoStreamResp_Orders {
  return { Orders: [] };
}

export const AccountInfoStreamResp_Orders = {
  encode(
    message: AccountInfoStreamResp_Orders,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Orders) {
      Order.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AccountInfoStreamResp_Orders {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountInfoStreamResp_Orders();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Orders.push(Order.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountInfoStreamResp_Orders {
    return {
      Orders: Array.isArray(object?.Orders)
        ? object.Orders.map((e: any) => Order.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountInfoStreamResp_Orders): unknown {
    const obj: any = {};
    if (message.Orders) {
      obj.Orders = message.Orders.map((e) => (e ? Order.toJSON(e) : undefined));
    } else {
      obj.Orders = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AccountInfoStreamResp_Orders>, I>>(
    object: I,
  ): AccountInfoStreamResp_Orders {
    const message = createBaseAccountInfoStreamResp_Orders();
    message.Orders = object.Orders?.map((e) => Order.fromPartial(e)) || [];
    return message;
  },
};

function createBasePing(): Ping {
  return {};
}

export const Ping = {
  encode(_: Ping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ping {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePing();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): Ping {
    return {};
  },

  toJSON(_: Ping): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Ping>, I>>(_: I): Ping {
    const message = createBasePing();
    return message;
  },
};

function createBaseServiceGroupsList(): ServiceGroupsList {
  return { ServiceGroups: [] };
}

export const ServiceGroupsList = {
  encode(
    message: ServiceGroupsList,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.ServiceGroups) {
      ServiceGroupsList_GroupInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServiceGroupsList {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceGroupsList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ServiceGroups.push(
            ServiceGroupsList_GroupInfo.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ServiceGroupsList {
    return {
      ServiceGroups: Array.isArray(object?.ServiceGroups)
        ? object.ServiceGroups.map((e: any) =>
            ServiceGroupsList_GroupInfo.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ServiceGroupsList): unknown {
    const obj: any = {};
    if (message.ServiceGroups) {
      obj.ServiceGroups = message.ServiceGroups.map((e) =>
        e ? ServiceGroupsList_GroupInfo.toJSON(e) : undefined,
      );
    } else {
      obj.ServiceGroups = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ServiceGroupsList>, I>>(
    object: I,
  ): ServiceGroupsList {
    const message = createBaseServiceGroupsList();
    message.ServiceGroups =
      object.ServiceGroups?.map((e) =>
        ServiceGroupsList_GroupInfo.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseServiceGroupsList_GroupInfo(): ServiceGroupsList_GroupInfo {
  return { Group: 0, Children: [] };
}

export const ServiceGroupsList_GroupInfo = {
  encode(
    message: ServiceGroupsList_GroupInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Group !== 0) {
      writer.uint32(8).int32(message.Group);
    }
    writer.uint32(18).fork();
    for (const v of message.Children) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ServiceGroupsList_GroupInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceGroupsList_GroupInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Group = reader.int32() as any;
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.Children.push(reader.int32() as any);
            }
          } else {
            message.Children.push(reader.int32() as any);
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ServiceGroupsList_GroupInfo {
    return {
      Group: isSet(object.Group) ? serviceTypeFromJSON(object.Group) : 0,
      Children: Array.isArray(object?.Children)
        ? object.Children.map((e: any) => serviceTypeFromJSON(e))
        : [],
    };
  },

  toJSON(message: ServiceGroupsList_GroupInfo): unknown {
    const obj: any = {};
    message.Group !== undefined &&
      (obj.Group = serviceTypeToJSON(message.Group));
    if (message.Children) {
      obj.Children = message.Children.map((e) => serviceTypeToJSON(e));
    } else {
      obj.Children = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ServiceGroupsList_GroupInfo>, I>>(
    object: I,
  ): ServiceGroupsList_GroupInfo {
    const message = createBaseServiceGroupsList_GroupInfo();
    message.Group = object.Group ?? 0;
    message.Children = object.Children?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateOrderReq(): CreateOrderReq {
  return { AccountID: '', SubscriptionAdd: undefined, AutoCharge: false };
}

export const CreateOrderReq = {
  encode(
    message: CreateOrderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.SubscriptionAdd !== undefined) {
      CreateOrderReq_SubscriptionAddEffect.encode(
        message.SubscriptionAdd,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.AutoCharge === true) {
      writer.uint32(24).bool(message.AutoCharge);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.SubscriptionAdd = CreateOrderReq_SubscriptionAddEffect.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.AutoCharge = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateOrderReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      SubscriptionAdd: isSet(object.SubscriptionAdd)
        ? CreateOrderReq_SubscriptionAddEffect.fromJSON(object.SubscriptionAdd)
        : undefined,
      AutoCharge: isSet(object.AutoCharge) ? Boolean(object.AutoCharge) : false,
    };
  },

  toJSON(message: CreateOrderReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.SubscriptionAdd !== undefined &&
      (obj.SubscriptionAdd = message.SubscriptionAdd
        ? CreateOrderReq_SubscriptionAddEffect.toJSON(message.SubscriptionAdd)
        : undefined);
    message.AutoCharge !== undefined && (obj.AutoCharge = message.AutoCharge);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateOrderReq>, I>>(
    object: I,
  ): CreateOrderReq {
    const message = createBaseCreateOrderReq();
    message.AccountID = object.AccountID ?? '';
    message.SubscriptionAdd =
      object.SubscriptionAdd !== undefined && object.SubscriptionAdd !== null
        ? CreateOrderReq_SubscriptionAddEffect.fromPartial(
            object.SubscriptionAdd,
          )
        : undefined;
    message.AutoCharge = object.AutoCharge ?? false;
    return message;
  },
};

function createBaseCreateOrderReq_SubscriptionAddEffect(): CreateOrderReq_SubscriptionAddEffect {
  return { SubscriptionModelID: '', LifeTimeMonths: 0 };
}

export const CreateOrderReq_SubscriptionAddEffect = {
  encode(
    message: CreateOrderReq_SubscriptionAddEffect,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.SubscriptionModelID !== '') {
      writer.uint32(10).string(message.SubscriptionModelID);
    }
    if (message.LifeTimeMonths !== 0) {
      writer.uint32(16).int32(message.LifeTimeMonths);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateOrderReq_SubscriptionAddEffect {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrderReq_SubscriptionAddEffect();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SubscriptionModelID = reader.string();
          break;
        case 2:
          message.LifeTimeMonths = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateOrderReq_SubscriptionAddEffect {
    return {
      SubscriptionModelID: isSet(object.SubscriptionModelID)
        ? String(object.SubscriptionModelID)
        : '',
      LifeTimeMonths: isSet(object.LifeTimeMonths)
        ? Number(object.LifeTimeMonths)
        : 0,
    };
  },

  toJSON(message: CreateOrderReq_SubscriptionAddEffect): unknown {
    const obj: any = {};
    message.SubscriptionModelID !== undefined &&
      (obj.SubscriptionModelID = message.SubscriptionModelID);
    message.LifeTimeMonths !== undefined &&
      (obj.LifeTimeMonths = Math.round(message.LifeTimeMonths));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<CreateOrderReq_SubscriptionAddEffect>, I>,
  >(object: I): CreateOrderReq_SubscriptionAddEffect {
    const message = createBaseCreateOrderReq_SubscriptionAddEffect();
    message.SubscriptionModelID = object.SubscriptionModelID ?? '';
    message.LifeTimeMonths = object.LifeTimeMonths ?? 0;
    return message;
  },
};

function createBaseCreateOrderResp(): CreateOrderResp {
  return { Order: undefined, InvoicePaymentURL: '' };
}

export const CreateOrderResp = {
  encode(
    message: CreateOrderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Order !== undefined) {
      Order.encode(message.Order, writer.uint32(10).fork()).ldelim();
    }
    if (message.InvoicePaymentURL !== '') {
      writer.uint32(18).string(message.InvoicePaymentURL);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Order = Order.decode(reader, reader.uint32());
          break;
        case 2:
          message.InvoicePaymentURL = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateOrderResp {
    return {
      Order: isSet(object.Order) ? Order.fromJSON(object.Order) : undefined,
      InvoicePaymentURL: isSet(object.InvoicePaymentURL)
        ? String(object.InvoicePaymentURL)
        : '',
    };
  },

  toJSON(message: CreateOrderResp): unknown {
    const obj: any = {};
    message.Order !== undefined &&
      (obj.Order = message.Order ? Order.toJSON(message.Order) : undefined);
    message.InvoicePaymentURL !== undefined &&
      (obj.InvoicePaymentURL = message.InvoicePaymentURL);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateOrderResp>, I>>(
    object: I,
  ): CreateOrderResp {
    const message = createBaseCreateOrderResp();
    message.Order =
      object.Order !== undefined && object.Order !== null
        ? Order.fromPartial(object.Order)
        : undefined;
    message.InvoicePaymentURL = object.InvoicePaymentURL ?? '';
    return message;
  },
};

function createBaseProcessOrderReq(): ProcessOrderReq {
  return { OrderID: '' };
}

export const ProcessOrderReq = {
  encode(
    message: ProcessOrderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrderID !== '') {
      writer.uint32(10).string(message.OrderID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessOrderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessOrderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrderID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProcessOrderReq {
    return {
      OrderID: isSet(object.OrderID) ? String(object.OrderID) : '',
    };
  },

  toJSON(message: ProcessOrderReq): unknown {
    const obj: any = {};
    message.OrderID !== undefined && (obj.OrderID = message.OrderID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProcessOrderReq>, I>>(
    object: I,
  ): ProcessOrderReq {
    const message = createBaseProcessOrderReq();
    message.OrderID = object.OrderID ?? '';
    return message;
  },
};

function createBaseProcessOrderResp(): ProcessOrderResp {
  return {};
}

export const ProcessOrderResp = {
  encode(
    _: ProcessOrderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcessOrderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcessOrderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ProcessOrderResp {
    return {};
  },

  toJSON(_: ProcessOrderResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProcessOrderResp>, I>>(
    _: I,
  ): ProcessOrderResp {
    const message = createBaseProcessOrderResp();
    return message;
  },
};

function createBasePayOrderReq(): PayOrderReq {
  return { Recurring: false, OrderID: '', AutoCharge: false };
}

export const PayOrderReq = {
  encode(
    message: PayOrderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Recurring === true) {
      writer.uint32(8).bool(message.Recurring);
    }
    if (message.OrderID !== '') {
      writer.uint32(18).string(message.OrderID);
    }
    if (message.AutoCharge === true) {
      writer.uint32(24).bool(message.AutoCharge);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayOrderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayOrderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Recurring = reader.bool();
          break;
        case 2:
          message.OrderID = reader.string();
          break;
        case 3:
          message.AutoCharge = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PayOrderReq {
    return {
      Recurring: isSet(object.Recurring) ? Boolean(object.Recurring) : false,
      OrderID: isSet(object.OrderID) ? String(object.OrderID) : '',
      AutoCharge: isSet(object.AutoCharge) ? Boolean(object.AutoCharge) : false,
    };
  },

  toJSON(message: PayOrderReq): unknown {
    const obj: any = {};
    message.Recurring !== undefined && (obj.Recurring = message.Recurring);
    message.OrderID !== undefined && (obj.OrderID = message.OrderID);
    message.AutoCharge !== undefined && (obj.AutoCharge = message.AutoCharge);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PayOrderReq>, I>>(
    object: I,
  ): PayOrderReq {
    const message = createBasePayOrderReq();
    message.Recurring = object.Recurring ?? false;
    message.OrderID = object.OrderID ?? '';
    message.AutoCharge = object.AutoCharge ?? false;
    return message;
  },
};

function createBasePayOrderResp(): PayOrderResp {
  return { Paid: undefined, URL: undefined, ManualPaymentOnly: undefined };
}

export const PayOrderResp = {
  encode(
    message: PayOrderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Paid !== undefined) {
      writer.uint32(8).bool(message.Paid);
    }
    if (message.URL !== undefined) {
      writer.uint32(18).string(message.URL);
    }
    if (message.ManualPaymentOnly !== undefined) {
      writer.uint32(24).bool(message.ManualPaymentOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PayOrderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayOrderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Paid = reader.bool();
          break;
        case 2:
          message.URL = reader.string();
          break;
        case 3:
          message.ManualPaymentOnly = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PayOrderResp {
    return {
      Paid: isSet(object.Paid) ? Boolean(object.Paid) : undefined,
      URL: isSet(object.URL) ? String(object.URL) : undefined,
      ManualPaymentOnly: isSet(object.ManualPaymentOnly)
        ? Boolean(object.ManualPaymentOnly)
        : undefined,
    };
  },

  toJSON(message: PayOrderResp): unknown {
    const obj: any = {};
    message.Paid !== undefined && (obj.Paid = message.Paid);
    message.URL !== undefined && (obj.URL = message.URL);
    message.ManualPaymentOnly !== undefined &&
      (obj.ManualPaymentOnly = message.ManualPaymentOnly);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PayOrderResp>, I>>(
    object: I,
  ): PayOrderResp {
    const message = createBasePayOrderResp();
    message.Paid = object.Paid ?? undefined;
    message.URL = object.URL ?? undefined;
    message.ManualPaymentOnly = object.ManualPaymentOnly ?? undefined;
    return message;
  },
};

function createBaseCreateAccountPaymentMethodReq(): CreateAccountPaymentMethodReq {
  return { AccountID: '', SuccessURL: '', CancelURL: '' };
}

export const CreateAccountPaymentMethodReq = {
  encode(
    message: CreateAccountPaymentMethodReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.SuccessURL !== '') {
      writer.uint32(18).string(message.SuccessURL);
    }
    if (message.CancelURL !== '') {
      writer.uint32(26).string(message.CancelURL);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateAccountPaymentMethodReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccountPaymentMethodReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.SuccessURL = reader.string();
          break;
        case 3:
          message.CancelURL = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateAccountPaymentMethodReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      SuccessURL: isSet(object.SuccessURL) ? String(object.SuccessURL) : '',
      CancelURL: isSet(object.CancelURL) ? String(object.CancelURL) : '',
    };
  },

  toJSON(message: CreateAccountPaymentMethodReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.SuccessURL !== undefined && (obj.SuccessURL = message.SuccessURL);
    message.CancelURL !== undefined && (obj.CancelURL = message.CancelURL);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateAccountPaymentMethodReq>, I>>(
    object: I,
  ): CreateAccountPaymentMethodReq {
    const message = createBaseCreateAccountPaymentMethodReq();
    message.AccountID = object.AccountID ?? '';
    message.SuccessURL = object.SuccessURL ?? '';
    message.CancelURL = object.CancelURL ?? '';
    return message;
  },
};

function createBaseCreateAccountPaymentMethodResp(): CreateAccountPaymentMethodResp {
  return { URL: '' };
}

export const CreateAccountPaymentMethodResp = {
  encode(
    message: CreateAccountPaymentMethodResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.URL !== '') {
      writer.uint32(10).string(message.URL);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateAccountPaymentMethodResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateAccountPaymentMethodResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.URL = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateAccountPaymentMethodResp {
    return {
      URL: isSet(object.URL) ? String(object.URL) : '',
    };
  },

  toJSON(message: CreateAccountPaymentMethodResp): unknown {
    const obj: any = {};
    message.URL !== undefined && (obj.URL = message.URL);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateAccountPaymentMethodResp>, I>>(
    object: I,
  ): CreateAccountPaymentMethodResp {
    const message = createBaseCreateAccountPaymentMethodResp();
    message.URL = object.URL ?? '';
    return message;
  },
};

function createBaseDeleteAccountPaymentMethodReq(): DeleteAccountPaymentMethodReq {
  return { AccountID: '' };
}

export const DeleteAccountPaymentMethodReq = {
  encode(
    message: DeleteAccountPaymentMethodReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteAccountPaymentMethodReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAccountPaymentMethodReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteAccountPaymentMethodReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
    };
  },

  toJSON(message: DeleteAccountPaymentMethodReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteAccountPaymentMethodReq>, I>>(
    object: I,
  ): DeleteAccountPaymentMethodReq {
    const message = createBaseDeleteAccountPaymentMethodReq();
    message.AccountID = object.AccountID ?? '';
    return message;
  },
};

function createBaseDeleteAccountPaymentMethodResp(): DeleteAccountPaymentMethodResp {
  return {};
}

export const DeleteAccountPaymentMethodResp = {
  encode(
    _: DeleteAccountPaymentMethodResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DeleteAccountPaymentMethodResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAccountPaymentMethodResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): DeleteAccountPaymentMethodResp {
    return {};
  },

  toJSON(_: DeleteAccountPaymentMethodResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteAccountPaymentMethodResp>, I>>(
    _: I,
  ): DeleteAccountPaymentMethodResp {
    const message = createBaseDeleteAccountPaymentMethodResp();
    return message;
  },
};

function createBaseSetAccountBillingZoneReq(): SetAccountBillingZoneReq {
  return { AccountID: '', BillingZone: '' };
}

export const SetAccountBillingZoneReq = {
  encode(
    message: SetAccountBillingZoneReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.BillingZone !== '') {
      writer.uint32(18).string(message.BillingZone);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetAccountBillingZoneReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAccountBillingZoneReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.BillingZone = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetAccountBillingZoneReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      BillingZone: isSet(object.BillingZone) ? String(object.BillingZone) : '',
    };
  },

  toJSON(message: SetAccountBillingZoneReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.BillingZone !== undefined &&
      (obj.BillingZone = message.BillingZone);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetAccountBillingZoneReq>, I>>(
    object: I,
  ): SetAccountBillingZoneReq {
    const message = createBaseSetAccountBillingZoneReq();
    message.AccountID = object.AccountID ?? '';
    message.BillingZone = object.BillingZone ?? '';
    return message;
  },
};

function createBaseSetAccountBillingZoneResp(): SetAccountBillingZoneResp {
  return { account: undefined };
}

export const SetAccountBillingZoneResp = {
  encode(
    message: SetAccountBillingZoneResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.account !== undefined) {
      Account.encode(message.account, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetAccountBillingZoneResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAccountBillingZoneResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.account = Account.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetAccountBillingZoneResp {
    return {
      account: isSet(object.account)
        ? Account.fromJSON(object.account)
        : undefined,
    };
  },

  toJSON(message: SetAccountBillingZoneResp): unknown {
    const obj: any = {};
    message.account !== undefined &&
      (obj.account = message.account
        ? Account.toJSON(message.account)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetAccountBillingZoneResp>, I>>(
    object: I,
  ): SetAccountBillingZoneResp {
    const message = createBaseSetAccountBillingZoneResp();
    message.account =
      object.account !== undefined && object.account !== null
        ? Account.fromPartial(object.account)
        : undefined;
    return message;
  },
};

function createBaseRemoveSubscriptionStateReq(): RemoveSubscriptionStateReq {
  return { AccountID: '', SubscriptionStateID: '' };
}

export const RemoveSubscriptionStateReq = {
  encode(
    message: RemoveSubscriptionStateReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.SubscriptionStateID !== '') {
      writer.uint32(18).string(message.SubscriptionStateID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemoveSubscriptionStateReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveSubscriptionStateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.SubscriptionStateID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveSubscriptionStateReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      SubscriptionStateID: isSet(object.SubscriptionStateID)
        ? String(object.SubscriptionStateID)
        : '',
    };
  },

  toJSON(message: RemoveSubscriptionStateReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.SubscriptionStateID !== undefined &&
      (obj.SubscriptionStateID = message.SubscriptionStateID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemoveSubscriptionStateReq>, I>>(
    object: I,
  ): RemoveSubscriptionStateReq {
    const message = createBaseRemoveSubscriptionStateReq();
    message.AccountID = object.AccountID ?? '';
    message.SubscriptionStateID = object.SubscriptionStateID ?? '';
    return message;
  },
};

function createBaseRemoveSubscriptionStateResp(): RemoveSubscriptionStateResp {
  return {};
}

export const RemoveSubscriptionStateResp = {
  encode(
    _: RemoveSubscriptionStateResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemoveSubscriptionStateResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveSubscriptionStateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): RemoveSubscriptionStateResp {
    return {};
  },

  toJSON(_: RemoveSubscriptionStateResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemoveSubscriptionStateResp>, I>>(
    _: I,
  ): RemoveSubscriptionStateResp {
    const message = createBaseRemoveSubscriptionStateResp();
    return message;
  },
};

function createBaseSetAvailableProductsReq(): SetAvailableProductsReq {
  return { AvailableProducts: undefined };
}

export const SetAvailableProductsReq = {
  encode(
    message: SetAvailableProductsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AvailableProducts !== undefined) {
      AvailableProducts.encode(
        message.AvailableProducts,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetAvailableProductsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAvailableProductsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AvailableProducts = AvailableProducts.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetAvailableProductsReq {
    return {
      AvailableProducts: isSet(object.AvailableProducts)
        ? AvailableProducts.fromJSON(object.AvailableProducts)
        : undefined,
    };
  },

  toJSON(message: SetAvailableProductsReq): unknown {
    const obj: any = {};
    message.AvailableProducts !== undefined &&
      (obj.AvailableProducts = message.AvailableProducts
        ? AvailableProducts.toJSON(message.AvailableProducts)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetAvailableProductsReq>, I>>(
    object: I,
  ): SetAvailableProductsReq {
    const message = createBaseSetAvailableProductsReq();
    message.AvailableProducts =
      object.AvailableProducts !== undefined &&
      object.AvailableProducts !== null
        ? AvailableProducts.fromPartial(object.AvailableProducts)
        : undefined;
    return message;
  },
};

function createBaseSetAvailableProductsResp(): SetAvailableProductsResp {
  return { AvailableProducts: undefined };
}

export const SetAvailableProductsResp = {
  encode(
    message: SetAvailableProductsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AvailableProducts !== undefined) {
      AvailableProducts.encode(
        message.AvailableProducts,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetAvailableProductsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAvailableProductsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AvailableProducts = AvailableProducts.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetAvailableProductsResp {
    return {
      AvailableProducts: isSet(object.AvailableProducts)
        ? AvailableProducts.fromJSON(object.AvailableProducts)
        : undefined,
    };
  },

  toJSON(message: SetAvailableProductsResp): unknown {
    const obj: any = {};
    message.AvailableProducts !== undefined &&
      (obj.AvailableProducts = message.AvailableProducts
        ? AvailableProducts.toJSON(message.AvailableProducts)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetAvailableProductsResp>, I>>(
    object: I,
  ): SetAvailableProductsResp {
    const message = createBaseSetAvailableProductsResp();
    message.AvailableProducts =
      object.AvailableProducts !== undefined &&
      object.AvailableProducts !== null
        ? AvailableProducts.fromPartial(object.AvailableProducts)
        : undefined;
    return message;
  },
};

function createBaseGetAllBillingZonesReq(): GetAllBillingZonesReq {
  return {};
}

export const GetAllBillingZonesReq = {
  encode(
    _: GetAllBillingZonesReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAllBillingZonesReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllBillingZonesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetAllBillingZonesReq {
    return {};
  },

  toJSON(_: GetAllBillingZonesReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetAllBillingZonesReq>, I>>(
    _: I,
  ): GetAllBillingZonesReq {
    const message = createBaseGetAllBillingZonesReq();
    return message;
  },
};

function createBaseGetAllBillingZonesResp(): GetAllBillingZonesResp {
  return { BillingZones: [] };
}

export const GetAllBillingZonesResp = {
  encode(
    message: GetAllBillingZonesResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.BillingZones) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAllBillingZonesResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllBillingZonesResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.BillingZones.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllBillingZonesResp {
    return {
      BillingZones: Array.isArray(object?.BillingZones)
        ? object.BillingZones.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: GetAllBillingZonesResp): unknown {
    const obj: any = {};
    if (message.BillingZones) {
      obj.BillingZones = message.BillingZones.map((e) => e);
    } else {
      obj.BillingZones = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetAllBillingZonesResp>, I>>(
    object: I,
  ): GetAllBillingZonesResp {
    const message = createBaseGetAllBillingZonesResp();
    message.BillingZones = object.BillingZones?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetAvailableProductsReq(): GetAvailableProductsReq {
  return { ByID: undefined, ByAccountID: undefined, ByGeoZone: undefined };
}

export const GetAvailableProductsReq = {
  encode(
    message: GetAvailableProductsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ByID !== undefined) {
      writer.uint32(10).string(message.ByID);
    }
    if (message.ByAccountID !== undefined) {
      writer.uint32(18).string(message.ByAccountID);
    }
    if (message.ByGeoZone !== undefined) {
      writer.uint32(26).string(message.ByGeoZone);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAvailableProductsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAvailableProductsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ByID = reader.string();
          break;
        case 2:
          message.ByAccountID = reader.string();
          break;
        case 3:
          message.ByGeoZone = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAvailableProductsReq {
    return {
      ByID: isSet(object.ByID) ? String(object.ByID) : undefined,
      ByAccountID: isSet(object.ByAccountID)
        ? String(object.ByAccountID)
        : undefined,
      ByGeoZone: isSet(object.ByGeoZone) ? String(object.ByGeoZone) : undefined,
    };
  },

  toJSON(message: GetAvailableProductsReq): unknown {
    const obj: any = {};
    message.ByID !== undefined && (obj.ByID = message.ByID);
    message.ByAccountID !== undefined &&
      (obj.ByAccountID = message.ByAccountID);
    message.ByGeoZone !== undefined && (obj.ByGeoZone = message.ByGeoZone);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetAvailableProductsReq>, I>>(
    object: I,
  ): GetAvailableProductsReq {
    const message = createBaseGetAvailableProductsReq();
    message.ByID = object.ByID ?? undefined;
    message.ByAccountID = object.ByAccountID ?? undefined;
    message.ByGeoZone = object.ByGeoZone ?? undefined;
    return message;
  },
};

function createBaseGetAvailableProductsResp(): GetAvailableProductsResp {
  return { AvailableProducts: undefined };
}

export const GetAvailableProductsResp = {
  encode(
    message: GetAvailableProductsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AvailableProducts !== undefined) {
      AvailableProducts.encode(
        message.AvailableProducts,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAvailableProductsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAvailableProductsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AvailableProducts = AvailableProducts.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAvailableProductsResp {
    return {
      AvailableProducts: isSet(object.AvailableProducts)
        ? AvailableProducts.fromJSON(object.AvailableProducts)
        : undefined,
    };
  },

  toJSON(message: GetAvailableProductsResp): unknown {
    const obj: any = {};
    message.AvailableProducts !== undefined &&
      (obj.AvailableProducts = message.AvailableProducts
        ? AvailableProducts.toJSON(message.AvailableProducts)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetAvailableProductsResp>, I>>(
    object: I,
  ): GetAvailableProductsResp {
    const message = createBaseGetAvailableProductsResp();
    message.AvailableProducts =
      object.AvailableProducts !== undefined &&
      object.AvailableProducts !== null
        ? AvailableProducts.fromPartial(object.AvailableProducts)
        : undefined;
    return message;
  },
};

function createBaseGetAllAvailableProductsReq(): GetAllAvailableProductsReq {
  return {};
}

export const GetAllAvailableProductsReq = {
  encode(
    _: GetAllAvailableProductsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAllAvailableProductsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllAvailableProductsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetAllAvailableProductsReq {
    return {};
  },

  toJSON(_: GetAllAvailableProductsReq): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetAllAvailableProductsReq>, I>>(
    _: I,
  ): GetAllAvailableProductsReq {
    const message = createBaseGetAllAvailableProductsReq();
    return message;
  },
};

function createBaseGetAllAvailableProductsResp(): GetAllAvailableProductsResp {
  return { AvailableProductsList: [] };
}

export const GetAllAvailableProductsResp = {
  encode(
    message: GetAllAvailableProductsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.AvailableProductsList) {
      AvailableProducts.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAllAvailableProductsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllAvailableProductsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AvailableProductsList.push(
            AvailableProducts.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllAvailableProductsResp {
    return {
      AvailableProductsList: Array.isArray(object?.AvailableProductsList)
        ? object.AvailableProductsList.map((e: any) =>
            AvailableProducts.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: GetAllAvailableProductsResp): unknown {
    const obj: any = {};
    if (message.AvailableProductsList) {
      obj.AvailableProductsList = message.AvailableProductsList.map((e) =>
        e ? AvailableProducts.toJSON(e) : undefined,
      );
    } else {
      obj.AvailableProductsList = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetAllAvailableProductsResp>, I>>(
    object: I,
  ): GetAllAvailableProductsResp {
    const message = createBaseGetAllAvailableProductsResp();
    message.AvailableProductsList =
      object.AvailableProductsList?.map((e) =>
        AvailableProducts.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseSetIndividualConditionsReq(): SetIndividualConditionsReq {
  return { AccountID: '', AvailableProducts: undefined };
}

export const SetIndividualConditionsReq = {
  encode(
    message: SetIndividualConditionsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.AvailableProducts !== undefined) {
      AvailableProducts.encode(
        message.AvailableProducts,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetIndividualConditionsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetIndividualConditionsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.AvailableProducts = AvailableProducts.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetIndividualConditionsReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      AvailableProducts: isSet(object.AvailableProducts)
        ? AvailableProducts.fromJSON(object.AvailableProducts)
        : undefined,
    };
  },

  toJSON(message: SetIndividualConditionsReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.AvailableProducts !== undefined &&
      (obj.AvailableProducts = message.AvailableProducts
        ? AvailableProducts.toJSON(message.AvailableProducts)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetIndividualConditionsReq>, I>>(
    object: I,
  ): SetIndividualConditionsReq {
    const message = createBaseSetIndividualConditionsReq();
    message.AccountID = object.AccountID ?? '';
    message.AvailableProducts =
      object.AvailableProducts !== undefined &&
      object.AvailableProducts !== null
        ? AvailableProducts.fromPartial(object.AvailableProducts)
        : undefined;
    return message;
  },
};

function createBaseSetIndividualConditionsResp(): SetIndividualConditionsResp {
  return { Account: undefined };
}

export const SetIndividualConditionsResp = {
  encode(
    message: SetIndividualConditionsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Account !== undefined) {
      Account.encode(message.Account, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetIndividualConditionsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetIndividualConditionsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Account = Account.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetIndividualConditionsResp {
    return {
      Account: isSet(object.Account)
        ? Account.fromJSON(object.Account)
        : undefined,
    };
  },

  toJSON(message: SetIndividualConditionsResp): unknown {
    const obj: any = {};
    message.Account !== undefined &&
      (obj.Account = message.Account
        ? Account.toJSON(message.Account)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetIndividualConditionsResp>, I>>(
    object: I,
  ): SetIndividualConditionsResp {
    const message = createBaseSetIndividualConditionsResp();
    message.Account =
      object.Account !== undefined && object.Account !== null
        ? Account.fromPartial(object.Account)
        : undefined;
    return message;
  },
};

function createBaseSetSubscriptionAutoRenewalReq(): SetSubscriptionAutoRenewalReq {
  return { AccountID: '', SubscriptionStateID: '', AutoRenewal: false };
}

export const SetSubscriptionAutoRenewalReq = {
  encode(
    message: SetSubscriptionAutoRenewalReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.SubscriptionStateID !== '') {
      writer.uint32(18).string(message.SubscriptionStateID);
    }
    if (message.AutoRenewal === true) {
      writer.uint32(24).bool(message.AutoRenewal);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetSubscriptionAutoRenewalReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriptionAutoRenewalReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.SubscriptionStateID = reader.string();
          break;
        case 3:
          message.AutoRenewal = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetSubscriptionAutoRenewalReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      SubscriptionStateID: isSet(object.SubscriptionStateID)
        ? String(object.SubscriptionStateID)
        : '',
      AutoRenewal: isSet(object.AutoRenewal)
        ? Boolean(object.AutoRenewal)
        : false,
    };
  },

  toJSON(message: SetSubscriptionAutoRenewalReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.SubscriptionStateID !== undefined &&
      (obj.SubscriptionStateID = message.SubscriptionStateID);
    message.AutoRenewal !== undefined &&
      (obj.AutoRenewal = message.AutoRenewal);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetSubscriptionAutoRenewalReq>, I>>(
    object: I,
  ): SetSubscriptionAutoRenewalReq {
    const message = createBaseSetSubscriptionAutoRenewalReq();
    message.AccountID = object.AccountID ?? '';
    message.SubscriptionStateID = object.SubscriptionStateID ?? '';
    message.AutoRenewal = object.AutoRenewal ?? false;
    return message;
  },
};

function createBaseSetSubscriptionAutoRenewalResp(): SetSubscriptionAutoRenewalResp {
  return {};
}

export const SetSubscriptionAutoRenewalResp = {
  encode(
    _: SetSubscriptionAutoRenewalResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetSubscriptionAutoRenewalResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriptionAutoRenewalResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SetSubscriptionAutoRenewalResp {
    return {};
  },

  toJSON(_: SetSubscriptionAutoRenewalResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetSubscriptionAutoRenewalResp>, I>>(
    _: I,
  ): SetSubscriptionAutoRenewalResp {
    const message = createBaseSetSubscriptionAutoRenewalResp();
    return message;
  },
};

function createBaseSetSubscriptionAutoChargeReq(): SetSubscriptionAutoChargeReq {
  return { AccountID: '', SubscriptionStateID: '', AutoCharge: false };
}

export const SetSubscriptionAutoChargeReq = {
  encode(
    message: SetSubscriptionAutoChargeReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.SubscriptionStateID !== '') {
      writer.uint32(18).string(message.SubscriptionStateID);
    }
    if (message.AutoCharge === true) {
      writer.uint32(24).bool(message.AutoCharge);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetSubscriptionAutoChargeReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriptionAutoChargeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.SubscriptionStateID = reader.string();
          break;
        case 3:
          message.AutoCharge = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetSubscriptionAutoChargeReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      SubscriptionStateID: isSet(object.SubscriptionStateID)
        ? String(object.SubscriptionStateID)
        : '',
      AutoCharge: isSet(object.AutoCharge) ? Boolean(object.AutoCharge) : false,
    };
  },

  toJSON(message: SetSubscriptionAutoChargeReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.SubscriptionStateID !== undefined &&
      (obj.SubscriptionStateID = message.SubscriptionStateID);
    message.AutoCharge !== undefined && (obj.AutoCharge = message.AutoCharge);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetSubscriptionAutoChargeReq>, I>>(
    object: I,
  ): SetSubscriptionAutoChargeReq {
    const message = createBaseSetSubscriptionAutoChargeReq();
    message.AccountID = object.AccountID ?? '';
    message.SubscriptionStateID = object.SubscriptionStateID ?? '';
    message.AutoCharge = object.AutoCharge ?? false;
    return message;
  },
};

function createBaseSetSubscriptionAutoChargeResp(): SetSubscriptionAutoChargeResp {
  return {};
}

export const SetSubscriptionAutoChargeResp = {
  encode(
    _: SetSubscriptionAutoChargeResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetSubscriptionAutoChargeResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriptionAutoChargeResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SetSubscriptionAutoChargeResp {
    return {};
  },

  toJSON(_: SetSubscriptionAutoChargeResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetSubscriptionAutoChargeResp>, I>>(
    _: I,
  ): SetSubscriptionAutoChargeResp {
    const message = createBaseSetSubscriptionAutoChargeResp();
    return message;
  },
};

function createBaseSetAccountAddressReq(): SetAccountAddressReq {
  return { AccountID: '', Address: '' };
}

export const SetAccountAddressReq = {
  encode(
    message: SetAccountAddressReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.Address !== '') {
      writer.uint32(18).string(message.Address);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetAccountAddressReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAccountAddressReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.Address = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetAccountAddressReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      Address: isSet(object.Address) ? String(object.Address) : '',
    };
  },

  toJSON(message: SetAccountAddressReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.Address !== undefined && (obj.Address = message.Address);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetAccountAddressReq>, I>>(
    object: I,
  ): SetAccountAddressReq {
    const message = createBaseSetAccountAddressReq();
    message.AccountID = object.AccountID ?? '';
    message.Address = object.Address ?? '';
    return message;
  },
};

function createBaseSetAccountAddressResp(): SetAccountAddressResp {
  return {};
}

export const SetAccountAddressResp = {
  encode(
    _: SetAccountAddressResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetAccountAddressResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAccountAddressResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SetAccountAddressResp {
    return {};
  },

  toJSON(_: SetAccountAddressResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetAccountAddressResp>, I>>(
    _: I,
  ): SetAccountAddressResp {
    const message = createBaseSetAccountAddressResp();
    return message;
  },
};

function createBaseSetAccountPhoneReq(): SetAccountPhoneReq {
  return { AccountID: '', Phone: '' };
}

export const SetAccountPhoneReq = {
  encode(
    message: SetAccountPhoneReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.Phone !== '') {
      writer.uint32(18).string(message.Phone);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetAccountPhoneReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAccountPhoneReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.Phone = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetAccountPhoneReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      Phone: isSet(object.Phone) ? String(object.Phone) : '',
    };
  },

  toJSON(message: SetAccountPhoneReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.Phone !== undefined && (obj.Phone = message.Phone);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetAccountPhoneReq>, I>>(
    object: I,
  ): SetAccountPhoneReq {
    const message = createBaseSetAccountPhoneReq();
    message.AccountID = object.AccountID ?? '';
    message.Phone = object.Phone ?? '';
    return message;
  },
};

function createBaseSetAccountPhoneResp(): SetAccountPhoneResp {
  return {};
}

export const SetAccountPhoneResp = {
  encode(
    _: SetAccountPhoneResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetAccountPhoneResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetAccountPhoneResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SetAccountPhoneResp {
    return {};
  },

  toJSON(_: SetAccountPhoneResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetAccountPhoneResp>, I>>(
    _: I,
  ): SetAccountPhoneResp {
    const message = createBaseSetAccountPhoneResp();
    return message;
  },
};

function createBaseGetPaymentDocumentByOrderReq(): GetPaymentDocumentByOrderReq {
  return { OrderID: '' };
}

export const GetPaymentDocumentByOrderReq = {
  encode(
    message: GetPaymentDocumentByOrderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrderID !== '') {
      writer.uint32(10).string(message.OrderID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetPaymentDocumentByOrderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPaymentDocumentByOrderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrderID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetPaymentDocumentByOrderReq {
    return {
      OrderID: isSet(object.OrderID) ? String(object.OrderID) : '',
    };
  },

  toJSON(message: GetPaymentDocumentByOrderReq): unknown {
    const obj: any = {};
    message.OrderID !== undefined && (obj.OrderID = message.OrderID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetPaymentDocumentByOrderReq>, I>>(
    object: I,
  ): GetPaymentDocumentByOrderReq {
    const message = createBaseGetPaymentDocumentByOrderReq();
    message.OrderID = object.OrderID ?? '';
    return message;
  },
};

function createBaseGetPaymentDocumentByOrderResp(): GetPaymentDocumentByOrderResp {
  return { document: new Uint8Array() };
}

export const GetPaymentDocumentByOrderResp = {
  encode(
    message: GetPaymentDocumentByOrderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.document.length !== 0) {
      writer.uint32(10).bytes(message.document);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetPaymentDocumentByOrderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPaymentDocumentByOrderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.document = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetPaymentDocumentByOrderResp {
    return {
      document: isSet(object.document)
        ? bytesFromBase64(object.document)
        : new Uint8Array(),
    };
  },

  toJSON(message: GetPaymentDocumentByOrderResp): unknown {
    const obj: any = {};
    message.document !== undefined &&
      (obj.document = base64FromBytes(
        message.document !== undefined ? message.document : new Uint8Array(),
      ));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetPaymentDocumentByOrderResp>, I>>(
    object: I,
  ): GetPaymentDocumentByOrderResp {
    const message = createBaseGetPaymentDocumentByOrderResp();
    message.document = object.document ?? new Uint8Array();
    return message;
  },
};

function createBaseSetTransactionNumberToOrderReq(): SetTransactionNumberToOrderReq {
  return { OrderID: '', TransactionNumber: '' };
}

export const SetTransactionNumberToOrderReq = {
  encode(
    message: SetTransactionNumberToOrderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrderID !== '') {
      writer.uint32(10).string(message.OrderID);
    }
    if (message.TransactionNumber !== '') {
      writer.uint32(18).string(message.TransactionNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetTransactionNumberToOrderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTransactionNumberToOrderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrderID = reader.string();
          break;
        case 2:
          message.TransactionNumber = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetTransactionNumberToOrderReq {
    return {
      OrderID: isSet(object.OrderID) ? String(object.OrderID) : '',
      TransactionNumber: isSet(object.TransactionNumber)
        ? String(object.TransactionNumber)
        : '',
    };
  },

  toJSON(message: SetTransactionNumberToOrderReq): unknown {
    const obj: any = {};
    message.OrderID !== undefined && (obj.OrderID = message.OrderID);
    message.TransactionNumber !== undefined &&
      (obj.TransactionNumber = message.TransactionNumber);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetTransactionNumberToOrderReq>, I>>(
    object: I,
  ): SetTransactionNumberToOrderReq {
    const message = createBaseSetTransactionNumberToOrderReq();
    message.OrderID = object.OrderID ?? '';
    message.TransactionNumber = object.TransactionNumber ?? '';
    return message;
  },
};

function createBaseSetTransactionNumberToOrderResp(): SetTransactionNumberToOrderResp {
  return {};
}

export const SetTransactionNumberToOrderResp = {
  encode(
    _: SetTransactionNumberToOrderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetTransactionNumberToOrderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetTransactionNumberToOrderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): SetTransactionNumberToOrderResp {
    return {};
  },

  toJSON(_: SetTransactionNumberToOrderResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetTransactionNumberToOrderResp>, I>>(
    _: I,
  ): SetTransactionNumberToOrderResp {
    const message = createBaseSetTransactionNumberToOrderResp();
    return message;
  },
};

function createBaseTestExpireSubscriptionAfterReq(): TestExpireSubscriptionAfterReq {
  return { AccountID: '', SubscriptionStateID: '', Seconds: 0 };
}

export const TestExpireSubscriptionAfterReq = {
  encode(
    message: TestExpireSubscriptionAfterReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.SubscriptionStateID !== '') {
      writer.uint32(18).string(message.SubscriptionStateID);
    }
    if (message.Seconds !== 0) {
      writer.uint32(24).int64(message.Seconds);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TestExpireSubscriptionAfterReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestExpireSubscriptionAfterReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.SubscriptionStateID = reader.string();
          break;
        case 3:
          message.Seconds = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TestExpireSubscriptionAfterReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      SubscriptionStateID: isSet(object.SubscriptionStateID)
        ? String(object.SubscriptionStateID)
        : '',
      Seconds: isSet(object.Seconds) ? Number(object.Seconds) : 0,
    };
  },

  toJSON(message: TestExpireSubscriptionAfterReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.SubscriptionStateID !== undefined &&
      (obj.SubscriptionStateID = message.SubscriptionStateID);
    message.Seconds !== undefined &&
      (obj.Seconds = Math.round(message.Seconds));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<TestExpireSubscriptionAfterReq>, I>>(
    object: I,
  ): TestExpireSubscriptionAfterReq {
    const message = createBaseTestExpireSubscriptionAfterReq();
    message.AccountID = object.AccountID ?? '';
    message.SubscriptionStateID = object.SubscriptionStateID ?? '';
    message.Seconds = object.Seconds ?? 0;
    return message;
  },
};

function createBaseTestExpireSubscriptionAfterResp(): TestExpireSubscriptionAfterResp {
  return {};
}

export const TestExpireSubscriptionAfterResp = {
  encode(
    _: TestExpireSubscriptionAfterResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TestExpireSubscriptionAfterResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestExpireSubscriptionAfterResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): TestExpireSubscriptionAfterResp {
    return {};
  },

  toJSON(_: TestExpireSubscriptionAfterResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<TestExpireSubscriptionAfterResp>, I>>(
    _: I,
  ): TestExpireSubscriptionAfterResp {
    const message = createBaseTestExpireSubscriptionAfterResp();
    return message;
  },
};

function createBaseTestSetAccountWithoutStripeInvoicesReq(): TestSetAccountWithoutStripeInvoicesReq {
  return { AccountID: '', WithoutStripeInvoices: false };
}

export const TestSetAccountWithoutStripeInvoicesReq = {
  encode(
    message: TestSetAccountWithoutStripeInvoicesReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AccountID !== '') {
      writer.uint32(10).string(message.AccountID);
    }
    if (message.WithoutStripeInvoices === true) {
      writer.uint32(16).bool(message.WithoutStripeInvoices);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TestSetAccountWithoutStripeInvoicesReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestSetAccountWithoutStripeInvoicesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AccountID = reader.string();
          break;
        case 2:
          message.WithoutStripeInvoices = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): TestSetAccountWithoutStripeInvoicesReq {
    return {
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      WithoutStripeInvoices: isSet(object.WithoutStripeInvoices)
        ? Boolean(object.WithoutStripeInvoices)
        : false,
    };
  },

  toJSON(message: TestSetAccountWithoutStripeInvoicesReq): unknown {
    const obj: any = {};
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.WithoutStripeInvoices !== undefined &&
      (obj.WithoutStripeInvoices = message.WithoutStripeInvoices);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<TestSetAccountWithoutStripeInvoicesReq>, I>,
  >(object: I): TestSetAccountWithoutStripeInvoicesReq {
    const message = createBaseTestSetAccountWithoutStripeInvoicesReq();
    message.AccountID = object.AccountID ?? '';
    message.WithoutStripeInvoices = object.WithoutStripeInvoices ?? false;
    return message;
  },
};

function createBaseTestSetAccountWithoutStripeInvoicesResp(): TestSetAccountWithoutStripeInvoicesResp {
  return {};
}

export const TestSetAccountWithoutStripeInvoicesResp = {
  encode(
    _: TestSetAccountWithoutStripeInvoicesResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): TestSetAccountWithoutStripeInvoicesResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestSetAccountWithoutStripeInvoicesResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): TestSetAccountWithoutStripeInvoicesResp {
    return {};
  },

  toJSON(_: TestSetAccountWithoutStripeInvoicesResp): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<TestSetAccountWithoutStripeInvoicesResp>, I>,
  >(_: I): TestSetAccountWithoutStripeInvoicesResp {
    const message = createBaseTestSetAccountWithoutStripeInvoicesResp();
    return message;
  },
};

export interface Billing {
  /** Get stream client with account info */
  AccountInfoStream(
    request: DeepPartial<AccountInfoStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<AccountInfoStreamResp>;
  /** Get available product objects by available product id or geo zone or account id */
  GetAvailableProducts(
    request: DeepPartial<GetAvailableProductsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAvailableProductsResp>;
  /** Get all available products (salesman only) */
  GetAllAvailableProducts(
    request: DeepPartial<GetAllAvailableProductsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAllAvailableProductsResp>;
  /** Set available product object (salesman only) */
  SetAvailableProducts(
    request: DeepPartial<SetAvailableProductsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetAvailableProductsResp>;
  GetAllBillingZones(
    request: DeepPartial<GetAllBillingZonesReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAllBillingZonesResp>;
  /** Create order */
  CreateOrder(
    request: DeepPartial<CreateOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateOrderResp>;
  /** Process order by id (salesman only) */
  ProcessOrder(
    request: DeepPartial<ProcessOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<ProcessOrderResp>;
  /** Pay order (owner only) */
  PayOrder(
    request: DeepPartial<PayOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<PayOrderResp>;
  /** Get stripe's page with payment method creation */
  CreateAccountPaymentMethod(
    request: DeepPartial<CreateAccountPaymentMethodReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateAccountPaymentMethodResp>;
  /** Delete account payment method by stripe's id */
  DeleteAccountPaymentMethod(
    request: DeepPartial<DeleteAccountPaymentMethodReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteAccountPaymentMethodResp>;
  /** (salesman only) */
  SetAccountBillingZone(
    request: DeepPartial<SetAccountBillingZoneReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetAccountBillingZoneResp>;
  /** (salesman only) */
  RemoveSubscriptionState(
    request: DeepPartial<RemoveSubscriptionStateReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveSubscriptionStateResp>;
  /** Set individual available product for account */
  SetIndividualConditions(
    request: DeepPartial<SetIndividualConditionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetIndividualConditionsResp>;
  /** Set/unset autorenewal for a specific subscription */
  SetSubscriptionAutoRenewal(
    request: DeepPartial<SetSubscriptionAutoRenewalReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetSubscriptionAutoRenewalResp>;
  /** Allow/disallow autocharge for a specific subscription */
  SetSubscriptionAutoCharge(
    request: DeepPartial<SetSubscriptionAutoChargeReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetSubscriptionAutoChargeResp>;
  SetAccountAddress(
    request: DeepPartial<SetAccountAddressReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetAccountAddressResp>;
  SetAccountPhone(
    request: DeepPartial<SetAccountPhoneReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetAccountPhoneResp>;
  GetPaymentDocumentByOrder(
    request: DeepPartial<GetPaymentDocumentByOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetPaymentDocumentByOrderResp>;
  SetTransactionNumberToOrder(
    request: DeepPartial<SetTransactionNumberToOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetTransactionNumberToOrderResp>;
  /**
   * Only for QA
   *  Через сколько секунд должна закончиться подписка
   */
  Test_ExpireSubscriptionAfter(
    request: DeepPartial<TestExpireSubscriptionAfterReq>,
    metadata?: grpc.Metadata,
  ): Promise<TestExpireSubscriptionAfterResp>;
  Test_SetAccountWithoutStripeInvoices(
    request: DeepPartial<TestSetAccountWithoutStripeInvoicesReq>,
    metadata?: grpc.Metadata,
  ): Promise<TestSetAccountWithoutStripeInvoicesResp>;
}

export class BillingClientImpl implements Billing {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.AccountInfoStream = this.AccountInfoStream.bind(this);
    this.GetAvailableProducts = this.GetAvailableProducts.bind(this);
    this.GetAllAvailableProducts = this.GetAllAvailableProducts.bind(this);
    this.SetAvailableProducts = this.SetAvailableProducts.bind(this);
    this.GetAllBillingZones = this.GetAllBillingZones.bind(this);
    this.CreateOrder = this.CreateOrder.bind(this);
    this.ProcessOrder = this.ProcessOrder.bind(this);
    this.PayOrder = this.PayOrder.bind(this);
    this.CreateAccountPaymentMethod =
      this.CreateAccountPaymentMethod.bind(this);
    this.DeleteAccountPaymentMethod =
      this.DeleteAccountPaymentMethod.bind(this);
    this.SetAccountBillingZone = this.SetAccountBillingZone.bind(this);
    this.RemoveSubscriptionState = this.RemoveSubscriptionState.bind(this);
    this.SetIndividualConditions = this.SetIndividualConditions.bind(this);
    this.SetSubscriptionAutoRenewal =
      this.SetSubscriptionAutoRenewal.bind(this);
    this.SetSubscriptionAutoCharge = this.SetSubscriptionAutoCharge.bind(this);
    this.SetAccountAddress = this.SetAccountAddress.bind(this);
    this.SetAccountPhone = this.SetAccountPhone.bind(this);
    this.GetPaymentDocumentByOrder = this.GetPaymentDocumentByOrder.bind(this);
    this.SetTransactionNumberToOrder =
      this.SetTransactionNumberToOrder.bind(this);
    this.Test_ExpireSubscriptionAfter =
      this.Test_ExpireSubscriptionAfter.bind(this);
    this.Test_SetAccountWithoutStripeInvoices =
      this.Test_SetAccountWithoutStripeInvoices.bind(this);
  }

  AccountInfoStream(
    request: DeepPartial<AccountInfoStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<AccountInfoStreamResp> {
    return this.rpc.invoke(
      BillingAccountInfoStreamDesc,
      AccountInfoStreamReq.fromPartial(request),
      metadata,
    );
  }

  GetAvailableProducts(
    request: DeepPartial<GetAvailableProductsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAvailableProductsResp> {
    return this.rpc.unary(
      BillingGetAvailableProductsDesc,
      GetAvailableProductsReq.fromPartial(request),
      metadata,
    );
  }

  GetAllAvailableProducts(
    request: DeepPartial<GetAllAvailableProductsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAllAvailableProductsResp> {
    return this.rpc.unary(
      BillingGetAllAvailableProductsDesc,
      GetAllAvailableProductsReq.fromPartial(request),
      metadata,
    );
  }

  SetAvailableProducts(
    request: DeepPartial<SetAvailableProductsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetAvailableProductsResp> {
    return this.rpc.unary(
      BillingSetAvailableProductsDesc,
      SetAvailableProductsReq.fromPartial(request),
      metadata,
    );
  }

  GetAllBillingZones(
    request: DeepPartial<GetAllBillingZonesReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAllBillingZonesResp> {
    return this.rpc.unary(
      BillingGetAllBillingZonesDesc,
      GetAllBillingZonesReq.fromPartial(request),
      metadata,
    );
  }

  CreateOrder(
    request: DeepPartial<CreateOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateOrderResp> {
    return this.rpc.unary(
      BillingCreateOrderDesc,
      CreateOrderReq.fromPartial(request),
      metadata,
    );
  }

  ProcessOrder(
    request: DeepPartial<ProcessOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<ProcessOrderResp> {
    return this.rpc.unary(
      BillingProcessOrderDesc,
      ProcessOrderReq.fromPartial(request),
      metadata,
    );
  }

  PayOrder(
    request: DeepPartial<PayOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<PayOrderResp> {
    return this.rpc.unary(
      BillingPayOrderDesc,
      PayOrderReq.fromPartial(request),
      metadata,
    );
  }

  CreateAccountPaymentMethod(
    request: DeepPartial<CreateAccountPaymentMethodReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateAccountPaymentMethodResp> {
    return this.rpc.unary(
      BillingCreateAccountPaymentMethodDesc,
      CreateAccountPaymentMethodReq.fromPartial(request),
      metadata,
    );
  }

  DeleteAccountPaymentMethod(
    request: DeepPartial<DeleteAccountPaymentMethodReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteAccountPaymentMethodResp> {
    return this.rpc.unary(
      BillingDeleteAccountPaymentMethodDesc,
      DeleteAccountPaymentMethodReq.fromPartial(request),
      metadata,
    );
  }

  SetAccountBillingZone(
    request: DeepPartial<SetAccountBillingZoneReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetAccountBillingZoneResp> {
    return this.rpc.unary(
      BillingSetAccountBillingZoneDesc,
      SetAccountBillingZoneReq.fromPartial(request),
      metadata,
    );
  }

  RemoveSubscriptionState(
    request: DeepPartial<RemoveSubscriptionStateReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveSubscriptionStateResp> {
    return this.rpc.unary(
      BillingRemoveSubscriptionStateDesc,
      RemoveSubscriptionStateReq.fromPartial(request),
      metadata,
    );
  }

  SetIndividualConditions(
    request: DeepPartial<SetIndividualConditionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetIndividualConditionsResp> {
    return this.rpc.unary(
      BillingSetIndividualConditionsDesc,
      SetIndividualConditionsReq.fromPartial(request),
      metadata,
    );
  }

  SetSubscriptionAutoRenewal(
    request: DeepPartial<SetSubscriptionAutoRenewalReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetSubscriptionAutoRenewalResp> {
    return this.rpc.unary(
      BillingSetSubscriptionAutoRenewalDesc,
      SetSubscriptionAutoRenewalReq.fromPartial(request),
      metadata,
    );
  }

  SetSubscriptionAutoCharge(
    request: DeepPartial<SetSubscriptionAutoChargeReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetSubscriptionAutoChargeResp> {
    return this.rpc.unary(
      BillingSetSubscriptionAutoChargeDesc,
      SetSubscriptionAutoChargeReq.fromPartial(request),
      metadata,
    );
  }

  SetAccountAddress(
    request: DeepPartial<SetAccountAddressReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetAccountAddressResp> {
    return this.rpc.unary(
      BillingSetAccountAddressDesc,
      SetAccountAddressReq.fromPartial(request),
      metadata,
    );
  }

  SetAccountPhone(
    request: DeepPartial<SetAccountPhoneReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetAccountPhoneResp> {
    return this.rpc.unary(
      BillingSetAccountPhoneDesc,
      SetAccountPhoneReq.fromPartial(request),
      metadata,
    );
  }

  GetPaymentDocumentByOrder(
    request: DeepPartial<GetPaymentDocumentByOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetPaymentDocumentByOrderResp> {
    return this.rpc.unary(
      BillingGetPaymentDocumentByOrderDesc,
      GetPaymentDocumentByOrderReq.fromPartial(request),
      metadata,
    );
  }

  SetTransactionNumberToOrder(
    request: DeepPartial<SetTransactionNumberToOrderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetTransactionNumberToOrderResp> {
    return this.rpc.unary(
      BillingSetTransactionNumberToOrderDesc,
      SetTransactionNumberToOrderReq.fromPartial(request),
      metadata,
    );
  }

  Test_ExpireSubscriptionAfter(
    request: DeepPartial<TestExpireSubscriptionAfterReq>,
    metadata?: grpc.Metadata,
  ): Promise<TestExpireSubscriptionAfterResp> {
    return this.rpc.unary(
      BillingTest_ExpireSubscriptionAfterDesc,
      TestExpireSubscriptionAfterReq.fromPartial(request),
      metadata,
    );
  }

  Test_SetAccountWithoutStripeInvoices(
    request: DeepPartial<TestSetAccountWithoutStripeInvoicesReq>,
    metadata?: grpc.Metadata,
  ): Promise<TestSetAccountWithoutStripeInvoicesResp> {
    return this.rpc.unary(
      BillingTest_SetAccountWithoutStripeInvoicesDesc,
      TestSetAccountWithoutStripeInvoicesReq.fromPartial(request),
      metadata,
    );
  }
}

export const BillingDesc = {
  serviceName: 'com.diagnocat.billing.Billing',
};

export const BillingAccountInfoStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'AccountInfoStream',
  service: BillingDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return AccountInfoStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AccountInfoStreamResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingGetAvailableProductsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetAvailableProducts',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAvailableProductsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetAvailableProductsResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingGetAllAvailableProductsDesc: UnaryMethodDefinitionish = {
  methodName: 'GetAllAvailableProducts',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAllAvailableProductsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetAllAvailableProductsResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetAvailableProductsDesc: UnaryMethodDefinitionish = {
  methodName: 'SetAvailableProducts',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetAvailableProductsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetAvailableProductsResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingGetAllBillingZonesDesc: UnaryMethodDefinitionish = {
  methodName: 'GetAllBillingZones',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAllBillingZonesReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetAllBillingZonesResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingCreateOrderDesc: UnaryMethodDefinitionish = {
  methodName: 'CreateOrder',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateOrderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CreateOrderResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingProcessOrderDesc: UnaryMethodDefinitionish = {
  methodName: 'ProcessOrder',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ProcessOrderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ProcessOrderResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingPayOrderDesc: UnaryMethodDefinitionish = {
  methodName: 'PayOrder',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PayOrderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...PayOrderResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingCreateAccountPaymentMethodDesc: UnaryMethodDefinitionish = {
  methodName: 'CreateAccountPaymentMethod',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateAccountPaymentMethodReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CreateAccountPaymentMethodResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingDeleteAccountPaymentMethodDesc: UnaryMethodDefinitionish = {
  methodName: 'DeleteAccountPaymentMethod',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteAccountPaymentMethodReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeleteAccountPaymentMethodResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetAccountBillingZoneDesc: UnaryMethodDefinitionish = {
  methodName: 'SetAccountBillingZone',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetAccountBillingZoneReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetAccountBillingZoneResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingRemoveSubscriptionStateDesc: UnaryMethodDefinitionish = {
  methodName: 'RemoveSubscriptionState',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveSubscriptionStateReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RemoveSubscriptionStateResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetIndividualConditionsDesc: UnaryMethodDefinitionish = {
  methodName: 'SetIndividualConditions',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetIndividualConditionsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetIndividualConditionsResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetSubscriptionAutoRenewalDesc: UnaryMethodDefinitionish = {
  methodName: 'SetSubscriptionAutoRenewal',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetSubscriptionAutoRenewalReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetSubscriptionAutoRenewalResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetSubscriptionAutoChargeDesc: UnaryMethodDefinitionish = {
  methodName: 'SetSubscriptionAutoCharge',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetSubscriptionAutoChargeReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetSubscriptionAutoChargeResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetAccountAddressDesc: UnaryMethodDefinitionish = {
  methodName: 'SetAccountAddress',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetAccountAddressReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetAccountAddressResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetAccountPhoneDesc: UnaryMethodDefinitionish = {
  methodName: 'SetAccountPhone',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetAccountPhoneReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetAccountPhoneResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingGetPaymentDocumentByOrderDesc: UnaryMethodDefinitionish = {
  methodName: 'GetPaymentDocumentByOrder',
  service: BillingDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetPaymentDocumentByOrderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetPaymentDocumentByOrderResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const BillingSetTransactionNumberToOrderDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetTransactionNumberToOrder',
    service: BillingDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetTransactionNumberToOrderReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetTransactionNumberToOrderResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const BillingTest_ExpireSubscriptionAfterDesc: UnaryMethodDefinitionish =
  {
    methodName: 'Test_ExpireSubscriptionAfter',
    service: BillingDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return TestExpireSubscriptionAfterReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...TestExpireSubscriptionAfterResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const BillingTest_SetAccountWithoutStripeInvoicesDesc: UnaryMethodDefinitionish =
  {
    methodName: 'Test_SetAccountWithoutStripeInvoices',
    service: BillingDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return TestSetAccountWithoutStripeInvoicesReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...TestSetAccountWithoutStripeInvoicesResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

const atob: (b64: string) => string =
  globalThis.atob ||
  ((b64) => globalThis.Buffer.from(b64, 'base64').toString('binary'));
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

const btoa: (bin: string) => string =
  globalThis.btoa ||
  ((bin) => globalThis.Buffer.from(bin, 'binary').toString('base64'));
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(String.fromCharCode(byte));
  });
  return btoa(bin.join(''));
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
