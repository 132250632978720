import React, { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { DentalNotations } from '@/entities/tooth';
import { useMedia } from '@/shared/hooks';

import { referralRecomendationCodesType } from '../../config/i18n';
import { ToothNumber } from '../ToothNumber/ToothNumber';
import { ReferralsGroupsType } from '../../config/referralsGroups.type';
import { useReferralGroup } from '../../hooks/useReferralGroup';

import styles from './ReferralGroup.module.scss';

type ReferralGroupProps = {
  referralGroup: ReferralsGroupsType;
  className?: string;
  testID?: string;
};

export const ReferralGroup: FC<ReferralGroupProps> = (props) => {
  const { referralGroup, className, testID } = props;

  const { reportID } = useParams();

  const { formatMessage } = useIntl();

  const { isPhone } = useMedia();

  const { referralGroupTeeth, lastToothISOnumber, dontShowThisReferral } =
    useReferralGroup(referralGroup, reportID);

  if (dontShowThisReferral) {
    return null;
  }

  return (
    <div
      className={cn(styles.container, className, isPhone ? 'p3' : 'p2')}
      data-testid={testID}
    >
      {`${formatMessage(referralRecomendationCodesType[referralGroup])}: `}

      {referralGroupTeeth.map((tooth) => (
        <ToothNumber
          tooth={tooth}
          lastISOnumber={lastToothISOnumber}
          dentalNotation={DentalNotations.ISO}
          key={tooth.ID}
        />
      ))}
    </div>
  );
};
