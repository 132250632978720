import { addOne, setOne, setMany } from './model/usersSlice';
import { selectUserById, usersSelectors } from './model/usersSlice.selectors';

export const usersModel = {
  selectors: {
    ...usersSelectors,
    selectById: selectUserById,
  },
  actions: {
    addOne,
    setOne,
    setMany,
  },
};

export { getUserFullName } from './lib/getUserFullName';
export { getUserInitials } from './lib/getUserInitials';

export { DoctorLabel } from './ui/DoctorLabel/DoctorLabel';
export { DoctorRow } from './ui/DoctorRow/DoctorRow';
export { DoctorsSelect } from './ui/DoctorsSelect/DoctorsSelect';

export { DoctorOptionType } from './config/types';
