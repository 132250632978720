/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export interface OrganizationPermissions {
  /** if the user has any role in the organization */
  CanView: boolean;
  /** if the user has Owner or Administrative role in the organization (info: name, type, contacts, logo, stamp) */
  CanEditInfo: boolean;
  /** if the user has Owner or Administrative role in the organization */
  CanViewSettings: boolean;
  /** if the user has Owner or Administrative role in the organization */
  CanEditSettings: boolean;
  /** if the user has Owner or Administrative role in the organization */
  CanEditBranches: boolean;
  /** if the user has any role in the organization */
  CanViewStaff: boolean;
  /** if the user has Owner or Administrative role in the organization */
  CanEditStaff: boolean;
  /** if the user has any role in the organization */
  CanCreatePatients: boolean;
}

function createBaseOrganizationPermissions(): OrganizationPermissions {
  return {
    CanView: false,
    CanEditInfo: false,
    CanViewSettings: false,
    CanEditSettings: false,
    CanEditBranches: false,
    CanViewStaff: false,
    CanEditStaff: false,
    CanCreatePatients: false,
  };
}

export const OrganizationPermissions = {
  encode(
    message: OrganizationPermissions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CanView === true) {
      writer.uint32(8).bool(message.CanView);
    }
    if (message.CanEditInfo === true) {
      writer.uint32(16).bool(message.CanEditInfo);
    }
    if (message.CanViewSettings === true) {
      writer.uint32(24).bool(message.CanViewSettings);
    }
    if (message.CanEditSettings === true) {
      writer.uint32(32).bool(message.CanEditSettings);
    }
    if (message.CanEditBranches === true) {
      writer.uint32(40).bool(message.CanEditBranches);
    }
    if (message.CanViewStaff === true) {
      writer.uint32(48).bool(message.CanViewStaff);
    }
    if (message.CanEditStaff === true) {
      writer.uint32(56).bool(message.CanEditStaff);
    }
    if (message.CanCreatePatients === true) {
      writer.uint32(64).bool(message.CanCreatePatients);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationPermissions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CanView = reader.bool();
          break;
        case 2:
          message.CanEditInfo = reader.bool();
          break;
        case 3:
          message.CanViewSettings = reader.bool();
          break;
        case 4:
          message.CanEditSettings = reader.bool();
          break;
        case 5:
          message.CanEditBranches = reader.bool();
          break;
        case 6:
          message.CanViewStaff = reader.bool();
          break;
        case 7:
          message.CanEditStaff = reader.bool();
          break;
        case 8:
          message.CanCreatePatients = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationPermissions {
    return {
      CanView: isSet(object.CanView) ? Boolean(object.CanView) : false,
      CanEditInfo: isSet(object.CanEditInfo)
        ? Boolean(object.CanEditInfo)
        : false,
      CanViewSettings: isSet(object.CanViewSettings)
        ? Boolean(object.CanViewSettings)
        : false,
      CanEditSettings: isSet(object.CanEditSettings)
        ? Boolean(object.CanEditSettings)
        : false,
      CanEditBranches: isSet(object.CanEditBranches)
        ? Boolean(object.CanEditBranches)
        : false,
      CanViewStaff: isSet(object.CanViewStaff)
        ? Boolean(object.CanViewStaff)
        : false,
      CanEditStaff: isSet(object.CanEditStaff)
        ? Boolean(object.CanEditStaff)
        : false,
      CanCreatePatients: isSet(object.CanCreatePatients)
        ? Boolean(object.CanCreatePatients)
        : false,
    };
  },

  toJSON(message: OrganizationPermissions): unknown {
    const obj: any = {};
    message.CanView !== undefined && (obj.CanView = message.CanView);
    message.CanEditInfo !== undefined &&
      (obj.CanEditInfo = message.CanEditInfo);
    message.CanViewSettings !== undefined &&
      (obj.CanViewSettings = message.CanViewSettings);
    message.CanEditSettings !== undefined &&
      (obj.CanEditSettings = message.CanEditSettings);
    message.CanEditBranches !== undefined &&
      (obj.CanEditBranches = message.CanEditBranches);
    message.CanViewStaff !== undefined &&
      (obj.CanViewStaff = message.CanViewStaff);
    message.CanEditStaff !== undefined &&
      (obj.CanEditStaff = message.CanEditStaff);
    message.CanCreatePatients !== undefined &&
      (obj.CanCreatePatients = message.CanCreatePatients);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationPermissions>, I>>(
    object: I,
  ): OrganizationPermissions {
    const message = createBaseOrganizationPermissions();
    message.CanView = object.CanView ?? false;
    message.CanEditInfo = object.CanEditInfo ?? false;
    message.CanViewSettings = object.CanViewSettings ?? false;
    message.CanEditSettings = object.CanEditSettings ?? false;
    message.CanEditBranches = object.CanEditBranches ?? false;
    message.CanViewStaff = object.CanViewStaff ?? false;
    message.CanEditStaff = object.CanEditStaff ?? false;
    message.CanCreatePatients = object.CanCreatePatients ?? false;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
