// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConditionListByCategory-module__list--sK9uPkpq{display:flex;flex-direction:column;gap:12px}\n\n.ConditionListByCategory-module__content--Ifd12nBt{padding-top:12px;padding-left:26px;margin-bottom:-12px}\n\n[dir=\"rtl\"] .ConditionListByCategory-module__content--Ifd12nBt{padding-left:0;padding-right:26px}.ConditionListByCategory-module__container--Jr60Wbl0{margin-top:12px}", "",{"version":3,"sources":["webpack://./src/entities/condition/ui/ConditionListByCategory/ConditionListByCategory.module.scss"],"names":[],"mappings":"AAAA,gDACE,YAAA,CACA,qBAAA,CACA,QAAA;;AAGF,mDACE,gBAAA,CACA,iBAAA,CACA,mBAAA;;AAHF,+DAEE,cAAA,CAAA,kBACA,CAGF,qDACE,eAAA","sourcesContent":[".list {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.content {\n  padding-top: 12px;\n  padding-left: 26px;\n  margin-bottom: -12px;\n}\n\n.container {\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "ConditionListByCategory-module__list--sK9uPkpq",
	"content": "ConditionListByCategory-module__content--Ifd12nBt",
	"container": "ConditionListByCategory-module__container--Jr60Wbl0"
};
export default ___CSS_LOADER_EXPORT___;
