import { compose, filter, flip, includes, prop } from 'ramda';
import { useParams } from 'react-router';
import { useMemo } from 'react';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { useAppSelector } from '@/shared/hooks';
import {
  LOWER_JAW_TEETH_ISO_NUMBERS,
  toothModel,
  UPPER_JAW_TEETH_ISO_NUMBERS,
} from '@/entities/tooth';
import { useReport } from '@/features/reportStream';
import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';
import { assetsModel, getImageSrc } from '@/entities/assets';
import { GeneratedReportAsset } from '@/shared/api/protocol_gen/model/dto_asset';

import { PreviewSettings } from './usePreviewSettings';

const filterTeethByISONumbers = (ISONumbers: number[]) => (teeth: Tooth[]) =>
  filter(compose(flip(includes)(ISONumbers), prop<keyof Tooth>('ISONumber')))(
    teeth,
  );

const getISONumbers = ({
  showUpperJaw,
  showLowerJaw,
}: {
  showUpperJaw: boolean;
  showLowerJaw: boolean;
}) => {
  if (!showLowerJaw && !showUpperJaw) {
    return [];
  }

  if (!showUpperJaw) {
    return LOWER_JAW_TEETH_ISO_NUMBERS;
  }

  if (!showLowerJaw) {
    return UPPER_JAW_TEETH_ISO_NUMBERS;
  }

  return [];
};

type UsePreviewDocument = {
  isLoading: boolean;
  isReportSigned: boolean;
  teeth: Tooth[];
  report: Report;
  patient: Patient;
  panoImage: GeneratedReportAsset;
  panoImageUrl: string;
  ROI: number[];
  patientFullName: string;
};

export const usePreviewDocument = (
  settings: PreviewSettings,
): UsePreviewDocument => {
  const { reportID } = useParams();

  const {
    report,
    patient,
    isReportLoading,
    isReportSigned,
    ROITeeth,
    isPatientLoading,
    patientFullName,
  } = useReport();

  const ROI = useAppSelector((state) =>
    toothModel.selectors.selectISONumbersByReportIDAndROI(state, reportID),
  );

  const panoImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(reportID),
  );

  const panoImageUrl = useMemo(
    () =>
      getImageSrc(panoImage?.CBCTGPPanoramaReformatGeneral.PanoramaSingle.ID),
    [panoImage],
  );

  const showAllTeeth = settings.showUpperJaw && settings.showLowerJaw;

  const ISONumbers = getISONumbers({
    showUpperJaw: settings.showUpperJaw,
    showLowerJaw: settings.showLowerJaw,
  });

  const teeth = showAllTeeth
    ? ROITeeth
    : filterTeethByISONumbers(ISONumbers)(ROITeeth);

  const isLoading = isReportLoading || isPatientLoading;

  return {
    teeth,
    report,
    patient,
    panoImage,
    panoImageUrl,
    ROI,
    patientFullName,
    isReportSigned: !!isReportSigned,
    isLoading,
  };
};
