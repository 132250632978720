import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/model/store';
import { OrganizationUserRole_Type } from '@/shared/api/protocol_gen/model/dto_organization';

import { organizationAdapter } from './organizationSlice';

export const organizationSelectors = organizationAdapter.getSelectors(
  (state: RootState) => state.organization,
);

export const selectID = (state: RootState) =>
  state.organization.currentOrganizationID;

export const selectCurrent = createSelector(
  [organizationSelectors.selectEntities, selectID],
  (organizationEntities, organizationID) =>
    organizationEntities[organizationID],
);

export const selectUsers = createSelector(
  selectCurrent,
  (organization) => organization?.UserRoles,
);

export const selectUserSpecializationsByID = createSelector(
  selectUsers,
  (state: RootState, userID: string) => userID,
  (userRoles, userID) => {
    const userData = userRoles?.find((user) => user.UserID === userID);
    return userData?.Specializations;
  },
);

export const selectUsersByRoles = createSelector(
  [selectUsers, (state, userRoles: OrganizationUserRole_Type[]) => userRoles],
  (organizationUsers, userRoles) =>
    organizationUsers?.filter((user) =>
      user?.Roles?.some((role) => userRoles.includes(role)),
    ),
);
