import { PathologyGroup, SliceInterface } from '@/entities/assets';
import { getImageSrc } from '@/entities/assets/lib/getImageSrc';
import {
  AssetContentGeneratedCBCTGPToothPathologySlice,
  AssetContentGeneratedCBCTGPToothPathologySlice_PathologyType,
} from '@/shared/api/protocol_gen/model/dto_asset_cbct_gp';

export const makePathologyGroup = (
  [groupName, groupCode]: Array<
    | AssetContentGeneratedCBCTGPToothPathologySlice_PathologyType
    | number
    | string
  >,
  sliceList: AssetContentGeneratedCBCTGPToothPathologySlice[],
): PathologyGroup => {
  const slices: SliceInterface[] = sliceList.reduce((acc, item) => {
    if (item.Pathology === groupCode) {
      return [
        ...acc,
        {
          id: item.Slice.ID,
          src: getImageSrc(item.Slice.ID),
          path: item.Slice.Path,
        },
      ];
    }

    return acc;
  }, []);

  return {
    type: groupName,
    slices,
  };
};
