import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { sortByISONumberOrder } from '../lib/sortByISONumbersOrder';

export const toothAdapter = createEntityAdapter<Tooth>({
  selectId: (tooth: Tooth) => tooth.ID,
  sortComparer: (a, b) => sortByISONumberOrder(a.ISONumber, b.ISONumber),
});

const initialStateFlags = {};

const toothSlice = createSlice({
  name: 'tooth',
  initialState: toothAdapter.getInitialState(initialStateFlags),
  reducers: {
    addOne: toothAdapter.addOne,
    addMany: toothAdapter.addMany,
    setNewestOne: (state, action: PayloadAction<Tooth>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision.Number;

      if (payloadRevisionNumber > currentRevisionNumber) {
        toothAdapter.setOne(state, action.payload);
      }
    },
    setMany: toothAdapter.setMany,
    removeOne: toothAdapter.removeOne,
    removeAll: toothAdapter.removeAll,
  },
});

export const { actions } = toothSlice;

export default toothSlice.reducer;
