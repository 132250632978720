import React, { FC } from 'react';
import cn from 'classnames';

import styles from './BasicLayout.module.scss';

export type BasicLayoutProps = {
  className?: string;
  testID?: string;
  children?: React.ReactNode;
};

export const BasicLayout: FC<BasicLayoutProps> = (props) => {
  const { className, testID, children } = props;

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      {children}
    </div>
  );
};
