import cbct from './cbct3d.svg';
import doc from './doc3d.svg';
import ios from './ios3d.svg';
import pano from './pano3d.svg';
import photo from './photo3d.svg';
import addButton from './add.svg';
import model from './3dmodel3d.svg';

export const studyUploadImages = {
  model,
  cbct,
  doc,
  ios,
  pano,
  photo,
  addButton,
};
