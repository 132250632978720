import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  DeepPartial,
  SetSubscriptionAutoRenewalReq,
} from '@/shared/api/protocol_gen/api/billing/svc_billing';
import api, { ApiError } from '@/shared/api/api';

export const cancelSubscription = createAsyncThunk(
  'billing/cancelSubscription',
  async (
    request: DeepPartial<SetSubscriptionAutoRenewalReq>,
    { rejectWithValue },
  ) => {
    try {
      const response = await api.billing.SetSubscriptionAutoRenewal(request);

      return response;
    } catch (error: unknown) {
      const { type, message } = error as ApiError;

      return rejectWithValue({ type, message });
    }
  },
);
