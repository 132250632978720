import { useMemo } from 'react';
import { find, propEq } from 'ramda';
import { useIntl } from 'react-intl';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import {
  getConditionGroupByCode,
  isConditionChecked,
  isConditionUncertain,
  getConditionCodeColor,
  getConditionModelScorePercentage,
} from '../lib';
import {
  ChildConditionGroups,
  FLAT_CONDITION_GROUPS,
  CHILD_CONDITION_GROUPS,
  ONE_OF_CONDITION_GROUPS,
} from '../config/const';
import { ConditionInterface, ChildConditionInterface } from '../config/types';
import { conditionText } from '../config/i18n';

// Return all possible condition items or only transform received conditions
export const useTransformCondition = (
  conditions: Condition[],
  allCodes: boolean = true,
): ConditionInterface[] => {
  const { formatMessage } = useIntl();

  const conditionItems = useMemo((): ConditionInterface[] => {
    if (allCodes) {
      return FLAT_CONDITION_GROUPS.map((code) => {
        const condition = find(propEq<keyof Condition>('Code')(code))(
          conditions,
        );

        const conditionColor = getConditionCodeColor(
          code,
          isConditionUncertain(condition),
        );

        const conditionGroup = getConditionGroupByCode(code);

        const childConditionInterfaces: ChildConditionInterface[] =
          code in CHILD_CONDITION_GROUPS
            ? Object.entries(
                CHILD_CONDITION_GROUPS[code] as Record<
                  ChildConditionGroups,
                  ConditionCode[]
                >,
              ).flatMap(
                ([group, codes]: [ChildConditionGroups, ConditionCode[]]) =>
                  codes.map((childCode): ChildConditionInterface => {
                    const childCondition = find(
                      propEq<keyof Condition>('Code')(childCode),
                    )(conditions);

                    return {
                      id: childCondition?.ID,
                      code: childCode,
                      parentConditionCode: code,
                      parentConditionId: condition?.ID,
                      group,
                      text: formatMessage(conditionText[childCode]),
                      color: 'white',
                      probability: childCondition?.Certainty?.ModelScore,
                      probabilityText:
                        getConditionModelScorePercentage(childCondition),
                      isChecked: isConditionChecked(childCondition),
                      isOneOf: false,
                    };
                  }),
              )
            : [];

        return {
          id: condition?.ID,
          code,
          group: conditionGroup,
          text: formatMessage(conditionText[code]),
          color: conditionColor,
          probability: condition?.Certainty?.ModelScore,
          probabilityText: getConditionModelScorePercentage(condition),
          isChecked: isConditionChecked(condition),
          isOneOf: ONE_OF_CONDITION_GROUPS.includes(conditionGroup),
          childConditionInterfaces,
        };
      });
    }

    return conditions.map((condition) => {
      const code = condition.Code;
      const conditionColor = getConditionCodeColor(
        code,
        isConditionUncertain(condition),
      );
      const conditionGroup = getConditionGroupByCode(code);

      return {
        id: condition?.ID,
        code: condition.Code,
        group: conditionGroup,
        text: formatMessage(conditionText[code]),
        color: conditionColor,
        probability: condition?.Certainty?.ModelScore,
        probabilityText: getConditionModelScorePercentage(condition),
        isChecked: isConditionChecked(condition),
        isOneOf: ONE_OF_CONDITION_GROUPS.includes(conditionGroup),
      };
    });
  }, [conditions, allCodes, formatMessage]);

  return conditionItems;
};
