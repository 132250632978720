import React, { FC } from 'react';

import { Button, Layout } from '@/shared/ui/';
import { Header } from '@/widgets/Header';

import styles from './ErrorPage.module.scss';

export const ErrorPage: FC = () => (
  <Layout>
    <Layout.Header>
      <Header />
    </Layout.Header>
    <Layout.Content>
      <Layout.Main>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.logo} />
            {/* TODO: change to intl node after integration */}
            <p className={styles.text}>Opps. Something went wrong</p>
            <Button
              variant="secondary"
              onClick={() => window.location.replace('/')}
              className={styles.button}
            >
              Go to home page
            </Button>
          </div>
        </div>
      </Layout.Main>
    </Layout.Content>
    <Layout.Footer />
  </Layout>
);
