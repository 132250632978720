import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ContextRequest } from 'graphics';
import { Checkbox, Slice } from '@/shared/ui';
import { SliceInterface } from '@/entities/assets';
import { useAppDispatch } from '@/shared/hooks';
import { modalModel } from '@/entities/modal';

import styles from './GroupSlicesList.module.scss';

type GroupSlicesListProps = {
  focusedIndex?: number;
  zoomedIndex?: number;
  className?: string;
  slices?: SliceInterface[];
  selectedSlices?: SliceInterface[];
  onHoveredGuideChanged?: (index: number) => void;
  toggleSelected?: (src: string) => void;
  onDragSlice?: (src: string) => void;
};

export const GroupSlicesList: FC<GroupSlicesListProps> = memo((props) => {
  const {
    className,
    focusedIndex,
    zoomedIndex,
    slices,
    selectedSlices,
    onHoveredGuideChanged,
    onDragSlice,
  } = props;

  const [showMasks, setShowMasks] = useState(true);

  const dispatch = useAppDispatch();

  const masks: ContextRequest[] = slices.reduce((acc, item) => {
    if (item?.path?.includes('mask')) {
      return [
        ...acc,
        {
          url: item.src,
          kind: 'dicom-mask',
        },
      ];
    }

    return acc;
  }, []);

  const silcesWithMasks = slices.reduce((acc, item, index) => {
    if (!item?.path?.includes('mask')) {
      return [
        ...acc,
        {
          ...item,
          mask: masks[index],
        },
      ];
    }

    return acc;
  }, []);

  const checkIsTheSliceSelected = useCallback(
    (src: string) => selectedSlices?.some((slice) => slice.src === src),
    [selectedSlices],
  );

  const handleOpenModal = useCallback(
    (src: string) => {
      dispatch(
        modalModel.actions.openZoomedSliceModal({
          src,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (zoomedIndex !== null) {
      handleOpenModal(slices[zoomedIndex]?.src);
    }
  }, [zoomedIndex]);

  return (
    <div
      className={cn(styles.container, className)}
      onMouseLeave={() => onHoveredGuideChanged(null)}
    >
      <div className={styles.slicesActions}>
        {!!masks.length && (
          <Checkbox
            checked={showMasks}
            onChange={() => setShowMasks(!showMasks)}
          >
            <FormattedMessage
              id="global.showMasks"
              defaultMessage="Show Masks"
            />
          </Checkbox>
        )}
      </div>
      <div className={styles.slices}>
        {silcesWithMasks.map((slice, sliceIndex) => (
          <div
            key={slice.src}
            onMouseEnter={() => onHoveredGuideChanged(sliceIndex)}
            className={styles.sliceWrapper}
          >
            <Slice
              key={slice.src}
              src={slice.src}
              onClick={() => handleOpenModal(slice.src)}
              annotations={slice.annotations}
              onDragSlice={onDragSlice}
              checkIsTheSliceSelected={checkIsTheSliceSelected}
              mask={showMasks ? slice.mask : null}
              focused={focusedIndex === sliceIndex}
            />
          </div>
        ))}
      </div>
    </div>
  );
});
