import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';

import { DefaultInitialState, SliceLoading, SliceName } from '@/shared/config';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { sortConditionsByGroupOrder } from '../lib/sortConditionsByGroupOrder';

export const conditionAdapter = createEntityAdapter<Condition>({
  selectId: (condition: Condition) => condition.ID,
  sortComparer: sortConditionsByGroupOrder,
});

type ConditionInitialState = DefaultInitialState;

const initialState: ConditionInitialState = {
  loading: 'idle',
};

const conditionSlice = createSlice({
  name: SliceName.condition,
  initialState: conditionAdapter.getInitialState(initialState),
  reducers: {
    addOne: conditionAdapter.addOne,
    addMany: conditionAdapter.addMany,
    setNewestOne: (state, action: PayloadAction<Condition>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision.Number;

      if (payloadRevisionNumber > currentRevisionNumber) {
        conditionAdapter.setOne(state, action.payload);
      }
    },
    setMany: conditionAdapter.setMany,
    removeOne: conditionAdapter.removeOne,
    removeAll: conditionAdapter.removeAll,
    setLoading: (state, action: PayloadAction<SliceLoading>) => {
      state.loading = action.payload;
    },
  },
});

export const { actions } = conditionSlice;

export default conditionSlice.reducer;
