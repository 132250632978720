import { useMemo } from 'react';
import { filter } from 'ramda';
import { isEqual } from 'lodash';

import { useAppSelector } from '@/shared/hooks';

import {
  filterConditionsByGroups,
  groupConditionInterfaceByGroup,
  shouldConditionItemBeShown,
} from '../lib';
import {
  TOOTH_ANATOMY_GROUPS,
  TOOTH_GENERAL_GROUPS,
  TOOTH_TYPE_GROUP,
} from '../config';
import * as conditionModel from '../model';

import { useTransformCondition } from './useTransformCondition';

export const useToothConditions = (toothID: string) => {
  const showUncertainConditions = false; // TODO: Select from report settings

  const conditions = useAppSelector(
    conditionModel.selectors.selectByToothID(toothID),
    isEqual,
  );

  const conditionItems = useTransformCondition(conditions);

  const groupedConditionItems = useMemo(
    () => groupConditionInterfaceByGroup(conditionItems),
    [conditionItems],
  );

  const conditionsToBeShown = useMemo(
    () =>
      filter(shouldConditionItemBeShown(showUncertainConditions), conditions),
    [conditions, showUncertainConditions],
  );

  const anatomyConditions = useMemo(
    () =>
      filterConditionsByGroups({
        ...TOOTH_TYPE_GROUP,
        ...TOOTH_ANATOMY_GROUPS,
      })(conditionsToBeShown),
    [conditionsToBeShown],
  );

  const generalConditions = useMemo(
    () =>
      filterConditionsByGroups({
        ...TOOTH_GENERAL_GROUPS,
      })(conditionsToBeShown),
    [conditionsToBeShown],
  );

  const anatomyConditionItems = useTransformCondition(anatomyConditions, false);

  const generalConditionItems = useTransformCondition(generalConditions, false);

  return {
    anatomyConditionItems,
    generalConditionItems,
    groupedConditionItems,
  };
};
