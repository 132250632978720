import React, { useState, FC } from 'react';

import { AbsoluteBlock } from '@/shared/graphics/RenderComponents/AbsoluteBlock';
import { GuidesLayer } from '@/shared/graphics/RenderComponents/GuidesLayer/GuidesLayer';
import { MedicalImage } from '@/shared/graphics/RenderComponents/MedicalImage/MedicalImage';

import styles from '../SliceGroupSection/SliceGroupSection.module.scss';
import { Group, Guide } from '../../config/types';
import { getGuideLines } from '../../helpers/getGuideLines';

type GuidesListProps = {
  group: Group;
  hoveredGuide: number;
  handleZoomImg: (index: number) => void;
  setHoveredGuide: (index: number) => void;
};

const GuidesList: FC<GuidesListProps> = (props) => {
  const { group, hoveredGuide, handleZoomImg, setHoveredGuide } = props;
  const [dicom, setDicom] = useState(null);

  return (
    <div className={styles.container}>
      {group.guides?.length &&
        group.guides.map((guide: Guide) => (
          <div
            className={styles.guidesLayerWrapper}
            style={{ width: dicom?.width, height: dicom?.height }}
            key={guide.id}
          >
            <AbsoluteBlock>
              <MedicalImage
                src={{ url: guide.src, kind: 'dicom' }}
                onLoaded={(newDicom) => setDicom(newDicom)}
              />
            </AbsoluteBlock>
            {'Lines' in guide.guide && guide.guide?.Lines && (
              <AbsoluteBlock>
                <GuidesLayer
                  width="100"
                  height="100"
                  hoveredGuide={hoveredGuide}
                  onHoveredGuideChanged={setHoveredGuide}
                  guides={getGuideLines(guide.guide?.Lines, group.slices)}
                  onGuideClick={handleZoomImg}
                />
              </AbsoluteBlock>
            )}
          </div>
        ))}
    </div>
  );
};

export default GuidesList;
