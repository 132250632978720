import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { ConditionGroups, CONDITION_CODES_BY_GROUPS } from '../config/const';

export const getConditionGroupByCode = (conditionCode: ConditionCode) =>
  Object.keys(CONDITION_CODES_BY_GROUPS).find((conditionGroup) =>
    CONDITION_CODES_BY_GROUPS[conditionGroup as ConditionGroups].includes(
      conditionCode,
    ),
  ) as ConditionGroups;
