import { ToothImage } from '../config/slices.types';

export const sortSpecialGroupImages = (
  imagesList: ToothImage[],
): ToothImage[] => {
  const priorities = {
    axial: 1,
    frontal: 2,
    sagittal: 3,
  } as Record<string, number>;

  const sortedByPriorities = imagesList.sort((a, b) => {
    const currentImageProjection: string = a?.meta
      ? JSON.parse(a.meta).projection_name
      : null;

    const nextImageProjection: string = b?.meta
      ? JSON.parse(b.meta).projection_name
      : null;

    return priorities[currentImageProjection] - priorities[nextImageProjection]; // priorities would sort toothImages into [ ...axialProjections, ...frontalProjection, ...sagittalProfections ]
  });

  return sortedByPriorities;
};
