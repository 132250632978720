import React, { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button, Textarea } from '@/shared/ui';

import styles from './CommentTooth.module.scss';

type CommentToothProps = {
  comment?: string;
  newComment?: string;
  onSaveComment?: () => void;
  onOpenChangeComment?: () => void;
  onCancel?: () => void;
  onChangeCommentInEditor?: (text: string) => void;
  isNewCommentLoading?: boolean;
  isCommentEditorVisible?: boolean;
  className?: string;
  testID?: string;
  autoFocus?: boolean;
};

export const CommentTooth: FC<CommentToothProps> = (props) => {
  const {
    className,
    testID,
    comment,
    newComment,
    isNewCommentLoading,
    isCommentEditorVisible,
    autoFocus,
    onSaveComment,
    onOpenChangeComment,
    onCancel,
    onChangeCommentInEditor,
  } = props;

  const isCommentExists = Boolean(comment);

  return (
    (isCommentExists || isCommentEditorVisible) && (
      <div className={cn(styles.container, className)} data-testid={testID}>
        {isCommentEditorVisible ? (
          <Textarea
            autoFocus={autoFocus}
            className={styles.textarea}
            value={newComment}
            onChange={(event) => onChangeCommentInEditor(event.target.value)}
          />
        ) : (
          <div className={styles.comment}>{comment}</div>
        )}
        <footer className={styles.footer}>
          {isCommentEditorVisible ? (
            <>
              <Button variant="tertiary" size="medium" onClick={onCancel}>
                <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                variant="secondary"
                size="medium"
                loading={isNewCommentLoading}
                onClick={onSaveComment}
              >
                <FormattedMessage
                  id="commentTooth.save"
                  defaultMessage="Save"
                />
              </Button>
            </>
          ) : (
            <Button
              variant="secondary"
              size="medium"
              onClick={onOpenChangeComment}
            >
              <FormattedMessage
                id="commentTooth.change"
                defaultMessage="Change"
              />
            </Button>
          )}
        </footer>
      </div>
    )
  );
};
