import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';

import { Study } from '@/shared/api/protocol_gen/model/dto_study';
import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import api from '@/shared/api/api';
import { StreamDataAccumulatorKey } from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';
import { studyModel } from '@/entities/study';
import { reportsModel } from '@/entities/reports';
import { patientModel } from '@/entities/patient';
import { conditionModel } from '@/entities/condition';
import { toothModel } from '@/entities/tooth';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import { assetsModel } from '@/entities/assets';

type PatientProfileStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: Study[];
  [StreamDataAccumulatorKey.reports]: Report[];
  [StreamDataAccumulatorKey.conditions]: Condition[];
  [StreamDataAccumulatorKey.teeth]: Tooth[];
  [StreamDataAccumulatorKey.assets]: Asset[];
};

const dataAccumulators: PatientProfileStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: [],
  [StreamDataAccumulatorKey.reports]: [],
  [StreamDataAccumulatorKey.conditions]: [],
  [StreamDataAccumulatorKey.teeth]: [],
  [StreamDataAccumulatorKey.assets]: [],
};

export const usePatientProfileStream = (
  id: string,
  onError?: (error: Error) => void,
) => {
  const dispatch = useAppDispatch();

  const patientProfileStream = useRef<Subscription>(undefined);

  const openPatientProfileStream = () => {
    dispatch(patientModel.actions.setLoading('pending'));
    dispatch(studyModel.actions.setLoading('pending'));
    dispatch(reportsModel.actions.setLoading('pending'));
    dispatch(assetsModel.actions.setLoading('pending'));

    patientProfileStream.current = api.patient
      .PatientProfileStream({ PatientID: id })
      .subscribe({
        next: (data) => {
          // Patient handling: start
          if (data.HistoricalPatient) {
            dispatch(patientModel.actions.setOne(data.HistoricalPatient));
            dispatch(patientModel.actions.setLoading('succeeded'));
          }

          if (data.UpdatedPatient) {
            dispatch(patientModel.actions.setNewestOne(data.UpdatedPatient));
          }
          // Patient handling: end

          // Study handling: start
          if (data.HistoricalStudy) {
            dataAccumulators[StreamDataAccumulatorKey.study].push(
              data.HistoricalStudy,
            );
          }

          if (data.EndOfHistoricalHistoricalStudies) {
            dispatch(
              studyModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.study],
              ),
            );
            dispatch(studyModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.study] = [];
          }

          if (data.UpdatedStudy) {
            dispatch(studyModel.actions.setNewestOne(data.UpdatedStudy));
          }
          // Study handling: end

          // Report handling: start
          if (data.HistoricalReport) {
            dataAccumulators[StreamDataAccumulatorKey.reports].push(
              data.HistoricalReport,
            );
          }

          if (data.EndOfHistoricalHistoricalReports) {
            dispatch(
              reportsModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.reports],
              ),
            );
            dispatch(reportsModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.reports] = [];
          }

          if (data.UpdatedReport) {
            dispatch(reportsModel.actions.setNewestOne(data.UpdatedReport));
            dispatch(reportsModel.actions.setLoading('succeeded'));
          }
          // Report handling: end

          // Tooth handling: start
          if (data.HistoricalTooth) {
            dataAccumulators[StreamDataAccumulatorKey.teeth].push(
              data.HistoricalTooth,
            );
          }

          if (data.EndOfHistoricalHistoricalTeeth) {
            dispatch(
              toothModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.teeth],
              ),
            );
            dataAccumulators[StreamDataAccumulatorKey.teeth] = [];
          }

          if (data.UpdatedTooth) {
            dispatch(toothModel.actions.setNewestOne(data.UpdatedTooth));
          }
          // Tooth handling: end

          if (data.HistoricalAsset) {
            dataAccumulators[StreamDataAccumulatorKey.assets].push(
              data.HistoricalAsset,
            );
          }

          if (data.EndOfHistoricalHistoricalAssets) {
            dispatch(
              assetsModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.assets],
              ),
            );
            dispatch(assetsModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.assets] = [];
          }

          // Condition handling: start
          if (data.HistoricalCondition) {
            dataAccumulators[StreamDataAccumulatorKey.conditions].push(
              data.HistoricalCondition,
            );
          }

          if (data.EndOfHistoricalHistoricalConditions) {
            dispatch(
              conditionModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.conditions],
              ),
            );
            dataAccumulators[StreamDataAccumulatorKey.conditions] = [];
          }

          if (data.UpdatedCondition) {
            dispatch(
              conditionModel.actions.setNewestOne(data.UpdatedCondition),
            );
          }
          // Condition handling: end
        },
        error: (error) => {
          if (typeof onError === 'function') onError(error);
          // dispatch(patientModel.actions.setLoading('failed));
        },
        complete: () => {
          // Do nothin
        },
      });
  };

  const closePatientProfileStream = () => {
    if (patientProfileStream.current) {
      patientProfileStream.current.unsubscribe();
    }
  };

  useEffect(() => {
    openPatientProfileStream();

    return () => {
      closePatientProfileStream();
    };
  }, []);
};
