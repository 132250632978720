// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../shared/assets/images/error_404.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorPage-module__container--LUjRdphy{display:flex;align-items:center;justify-content:center;height:calc(100vh - 152px);background-color:var(--mainbg)}.ErrorPage-module__content--S8EkjcLf{width:600px;display:flex;flex-direction:column;justify-content:center;align-items:center}.ErrorPage-module__logo--gfcR5CbP{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;width:130px;height:130px;margin-bottom:24px}.ErrorPage-module__text--8ebk5OZ9{margin-bottom:24px;color:var(--text5);opacity:.5}.ErrorPage-module__button--OZcoih\\+X{text-decoration:none;font-weight:700;font-size:22px}", "",{"version":3,"sources":["webpack://./src/pages/ErrorPage/ErrorPage.module.scss"],"names":[],"mappings":"AAEA,uCACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,0BAAA,CACA,8BAAA,CAGF,qCACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAGF,kCACE,kDAAA,CACA,uBAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CAGF,kCACE,kBAAA,CACA,kBAAA,CACA,UAAA,CAGF,qCACE,oBAAA,CACA,eAAA,CACA,cAAA","sourcesContent":["$headerAndFooterHieght: 152px;\n\n.container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - $headerAndFooterHieght);\n  background-color: var(--mainbg);\n}\n\n.content {\n  width: 600px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.logo {\n  background: url('@/shared/assets/images/error_404.png');\n  background-size: contain;\n  width: 130px;\n  height: 130px;\n  margin-bottom: 24px;\n}\n\n.text {\n  margin-bottom: 24px;\n  color: var(--text5);\n  opacity: 0.5;\n}\n\n.button {\n  text-decoration: none;\n  font-weight: 700;\n  font-size: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ErrorPage-module__container--LUjRdphy",
	"content": "ErrorPage-module__content--S8EkjcLf",
	"logo": "ErrorPage-module__logo--gfcR5CbP",
	"text": "ErrorPage-module__text--8ebk5OZ9",
	"button": "ErrorPage-module__button--OZcoih+X"
};
export default ___CSS_LOADER_EXPORT___;
