import React, { FC } from 'react';
import cn from 'classnames';

import { StudyCounter, StudyCounterNames } from '../StudyCounter/StudyCounter';

import styles from './StudiesCounters.module.scss';

type StudiesCountersVariants = 'row' | 'card';
export type StudyCounterItem = {
  name: StudyCounterNames;
  count: number;
};

type StudiesCountersProps = {
  studies: StudyCounterItem[];
  variant?: StudiesCountersVariants;
  className?: string;
};

export const StudiesCounters: FC<StudiesCountersProps> = (props) => {
  const { studies, className, variant = 'row' } = props;
  return (
    <div
      className={cn(
        styles.container,
        variant === 'card' && styles.cardContainer,
        className,
      )}
    >
      {studies.map(({ name, count }) => (
        <StudyCounter
          name={name as StudyCounterNames}
          count={count}
          key={name}
        />
      ))}
    </div>
  );
};
