// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Result-module__container--IuUEVrxV{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:24px;width:100%;height:100%;flex-grow:1}.Result-module__icon--MgTVQzyP{flex-shrink:0}.Result-module__text--6sAahvpn{white-space:pre;text-align:center;max-width:288px;color:var(--text5);opacity:.5}.Result-module__extra--T6x3hS3Y{display:flex;gap:16px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Result/Result.module.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CAGF,+BACE,aAAA,CAGF,+BACE,eAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,UAAA,CAGF,gCACE,YAAA,CACA,QAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 24px;\n  width: 100%;\n  height: 100%;\n  flex-grow: 1;\n}\n\n.icon {\n  flex-shrink: 0;\n}\n\n.text {\n  white-space: pre;\n  text-align: center;\n  max-width: 288px;\n  color: var(--text5);\n  opacity: 0.5;\n}\n\n.extra {\n  display: flex;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Result-module__container--IuUEVrxV",
	"icon": "Result-module__icon--MgTVQzyP",
	"text": "Result-module__text--6sAahvpn",
	"extra": "Result-module__extra--T6x3hS3Y"
};
export default ___CSS_LOADER_EXPORT___;
