import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Description } from '@/shared/ui';

import styles from './PreviewHeader.module.scss';

type PreviewHeaderProps = {
  title: string;
  date: string;
  patientName: string;
  patientGender: string;
  patientDateOfBirth: string;
};

export const PreviewHeader: FC<PreviewHeaderProps> = (props) => {
  const { title, date, patientName, patientGender, patientDateOfBirth } = props;

  const { formatMessage } = useIntl();

  return (
    <div className={styles.containter}>
      <div className={styles.reportInfo}>
        <h1 className="h2">{title}</h1>
        <h2 className="h3">{date}</h2>
        <h3 className="p1">{patientName}</h3>

        <div className={styles.reportInfoDetails}>
          <Description
            label={formatMessage({
              id: 'patientInfo.DoB',
              defaultMessage: 'DoB',
            })}
          >
            {patientDateOfBirth}
          </Description>

          <Description
            label={formatMessage({
              id: 'patientInfo.gender',
              defaultMessage: 'Gender',
            })}
          >
            {patientGender}
          </Description>

          <Description label="Study Date">{date}</Description>
        </div>
      </div>

      {/* <div>TODO: Clinic info section</div> */}
    </div>
  );
};
