import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { ConditionColor, useToothConditions } from '@/entities/condition';
import { useGetSelectedSlices } from '@/entities/assets';
import { Slice } from '@/shared/ui';
// TODO: Move it to lower slice. We have to many widgets - this is not reusable and we lack in flexibility
import { getSliceLabel } from '@/widgets/ToothCard/lib/getSliceLabel';
import { sliceLabels } from '@/widgets/ToothCard/config/i18n';
import { SliceLabelsTypes } from '@/widgets/ToothCard/config/types';

import styles from './PreviewToothCard.module.scss';

type PreviewToothCardProps = {
  tooth: Tooth;
  showProbability: boolean;
  showConditions: boolean;
  showSlices: boolean;
  inverted: boolean;
};

const colorStyle: Record<ConditionColor, string> = {
  yellow: styles.colorYellow,
  red: styles.colorRed,
  purple: styles.colorPurple,
  white: styles.colorWhite,
};

export const PreviewToothCard: FC<PreviewToothCardProps> = (props) => {
  const { tooth, showProbability, showConditions, showSlices, inverted } =
    props;
  const { formatMessage } = useIntl();
  const { anatomyConditionItems, generalConditionItems } = useToothConditions(
    tooth.ID,
  );
  const selectedSlices = useGetSelectedSlices(tooth.ID);

  const conditionItems = [...anatomyConditionItems, ...generalConditionItems];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={cn('h2')}>
          <FormattedMessage
            id="toothCard.title"
            defaultMessage="Tooth {number}"
            values={{
              number: tooth?.ISONumber,
            }}
          />
        </h2>

        {showConditions && (
          <div className={styles.toothConditionContainer}>
            {conditionItems.map((item) => (
              <span
                key={item.id}
                className={cn(
                  styles.toothCondition,
                  colorStyle[item.color],
                  'p2',
                )}
              >
                <span>{item.text}</span>

                {showProbability && <span>{item.probabilityText}</span>}
              </span>
            ))}
          </div>
        )}
      </div>

      {showSlices && (
        <div className={styles.slices}>
          {selectedSlices?.map((toothSlice) => {
            const sliceLabel = getSliceLabel(
              toothSlice?.path,
            ) as SliceLabelsTypes;
            const label = formatMessage(sliceLabels[sliceLabel]);

            return (
              <Slice
                key={toothSlice.src}
                src={toothSlice.src}
                sliceLabel={label}
                annotations={toothSlice.annotations}
                inverted={inverted}
              />
            );
          })}
        </div>
      )}

      {tooth?.Comment && <p className="p2">{tooth.Comment}</p>}
    </div>
  );
};
