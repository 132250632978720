import { format, differenceInYears } from 'date-fns';

import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';

// TODO: Make it with intl
export const formatPatientDateOfBirth = (patient: Patient) => {
  const dateOfBirth = format(
    new Date(patient?.PersonalData?.DateOfBirth),
    'MMMM, d yyyy',
  );
  const age = differenceInYears(
    new Date(),
    new Date(patient?.PersonalData?.DateOfBirth),
  );

  return `${dateOfBirth}, ${age} y.o.`;
};
