import React, { FC } from 'react';
import cn from 'classnames';

import styles from './BasicSkeleton.module.scss';

export type BasicSkeletonProps = {
  className?: string;
  testID?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
};

export const BasicSkeleton: FC<BasicSkeletonProps> = (props) => {
  const {
    className,
    testID,
    width = '280px',
    height = '60px',
    borderRadius = '90px',
  } = props;

  return (
    <div
      className={cn(styles.container, className)}
      data-testid={testID}
      style={{
        width: `${width}`,
        height: `${height}`,
        borderRadius: `${borderRadius}`,
      }}
    />
  );
};
