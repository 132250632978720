import React, { FC, useMemo } from 'react';
import cn from 'classnames';

import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import { assetsModel, getImageSrc } from '@/entities/assets';
import { useAppSelector } from '@/shared/hooks';

import styles from './RecentReportsIcons.module.scss';

type RecentReportsIconsProps = {
  className?: string;
  testID?: string;
  report: Report;
  reportIndex: number;
  currentReportIndex: number;
  setCurrentReport: (index: number) => void;
};

export const RecentReportsIcons: FC<RecentReportsIconsProps> = (props) => {
  const {
    className,
    testID,
    report,
    reportIndex,
    currentReportIndex,
    setCurrentReport,
  } = props;

  const panoImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(report.ID),
  );

  const panoSrc = useMemo(
    () =>
      getImageSrc(
        panoImage?.CBCTGPPanoramaReformatGeneral.Derived.Thumbnail.ID,
      ),
    [panoImage],
  );

  return (
    <div
      className={cn(
        className,
        styles.container,
        reportIndex === currentReportIndex && styles.active,
      )}
      data-testid={testID}
      key={report.ID}
      onClick={() => setCurrentReport(reportIndex)}
    >
      {panoImage && (
        <img src={panoSrc} alt={report.ID} className={styles.reportIconImage} />
      )}
    </div>
  );
};
