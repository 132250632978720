import { FC } from 'react';
import cn from 'classnames';

import logo from '@/shared/assets/logo.svg';

import styles from './PreviewFooter.module.scss';

type PreviewFooterProps = {
  text: string;
  date: string;
};

export const PreviewFooter: FC<PreviewFooterProps> = (props) => {
  const { text, date } = props;

  return (
    <div className={styles.container}>
      <p className={cn(styles.text, 'p3')}>{text}</p>

      <div className={styles.rightSide}>
        <img src={logo} width={100} alt="Diagnocat" />
        <p className={styles.date}>{date}</p>
      </div>
    </div>
  );
};
