import React, { FC, useState } from 'react';

import { Popup } from '@/shared/ui';
import { UltimateMedicalImage } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { useWindowSize } from '@/shared/hooks';

export type ExpandedImageProps = {
  src: string;
  isOpen: boolean;
  onClose: () => void;
};

const PANO_WIDTH_INCREASE = 0.6;
const PANO_HEIGHT_INCREASE = 0.8;

export const ExpandedPanoModal: FC<ExpandedImageProps> = (props) => {
  const { src, isOpen, onClose } = props;

  const [dicomSize, setDicomSize] = useState({ w: 0, h: 0 });

  const { width: wWidth, height: wHeight } = useWindowSize();

  const onDicomLoaded = () => {
    setDicomSize({
      w: wWidth * PANO_WIDTH_INCREASE,
      h: wHeight * PANO_HEIGHT_INCREASE,
    });
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <UltimateMedicalImage
        src={src}
        onDicomLoaded={onDicomLoaded}
        width={dicomSize.w}
        height={dicomSize.h}
      />
    </Popup>
  );
};
