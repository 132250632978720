import { useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks';
import {
  AssetContentGeneratedCBCTGPToothPathologySlice,
  AssetContentGeneratedCBCTGPToothSlice,
} from '@/shared/api/protocol_gen/model/dto_asset_cbct_gp';

import { makePathologyGroup } from '../lib/makePathologyGroup';
import {
  TOOTH_PAGE_GROUPNAMES,
  TOOTH_PAGE_PATHOLOGYS,
} from '../config/constants';
import { Group, PathologyGroup } from '../config/slices.types';
import { makeGroupByOrientation } from '../lib/makeGroupByOrientation';
import { assetsModel } from '../model';

export const useGetSliceGroups = (
  toothID: string,
): Array<Group | PathologyGroup> => {
  const CBCTToothSlices: AssetContentGeneratedCBCTGPToothSlice[] =
    useAppSelector(assetsModel.selectors.selectCBCTSlicesByToothID(toothID));

  const CBCTToothPathologySlices: AssetContentGeneratedCBCTGPToothPathologySlice[] =
    useAppSelector(
      assetsModel.selectors.selectCBCTToothPathologySlice(toothID),
    );

  const pathologyGroups = useMemo(
    () =>
      Object.entries(TOOTH_PAGE_PATHOLOGYS).map(([groupName, groupCode]) =>
        makePathologyGroup([groupName, groupCode], CBCTToothPathologySlices),
      ),
    [CBCTToothPathologySlices],
  );

  const groups = useMemo(
    () =>
      Object.entries(TOOTH_PAGE_GROUPNAMES).map(([groupName, groupCode]) =>
        makeGroupByOrientation([groupName, groupCode], CBCTToothSlices),
      ),
    [CBCTToothSlices],
  );

  return [...groups, ...pathologyGroups];
};
