// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WidgetCard-module__container--Xeu-XEYq{padding:24px;border-radius:16px;background-color:var(--bg2)}.WidgetCard-module__container--Xeu-XEYq img{width:100%}", "",{"version":3,"sources":["webpack://./src/shared/ui/WidgetCard/WidgetCard.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,kBAAA,CACA,2BAAA,CAEA,4CACE,UAAA","sourcesContent":[".container {\n  padding: 24px;\n  border-radius: 16px;\n  background-color: var(--bg2);\n\n  img {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WidgetCard-module__container--Xeu-XEYq"
};
export default ___CSS_LOADER_EXPORT___;
