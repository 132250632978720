import React from 'react';

export type Guide = { x1: number; y1: number; x2: number; y2: number };

export const GuidesLayer = ({
  width,
  height,
  guides,
  hoveredGuide,
  onHoveredGuideChanged,
  onGuideClick,
}: {
  width: string;
  height: string;
  guides: Guide[];
  hoveredGuide: number;
  onHoveredGuideChanged: (index: number) => void;
  onGuideClick:(index: number) => void;
}) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={{ display: 'block' }}
      >
        {guides.map((guide, index) =>
          index === hoveredGuide ? (
            <HoveredLine
              key={index}
              guide={guide}
              onMouseLeave={() => {
                onHoveredGuideChanged(-1);
              }}
              onClick={() => { onGuideClick(hoveredGuide) }}
            />
          ) : (
            <UsualLine
              key={index}
              guide={guide}
              onMouseEnter={() => {
                onHoveredGuideChanged(index);
              }}
            />
          ),
        )}
      </svg>
    </>
  );
};

const UsualLine = ({
  guide,
  onMouseEnter,
}: {
  guide: Guide;
  onMouseEnter: () => void;
}) => (
  <>
    {/* 
	A little wider transparent line to make user mouse pointing easier.
	Don't make stroke-wdith higher than HoveredLine in case of preventing exit that instantly entering the same line and stuck
	*/}
    <line
      {...guide}
      stroke="red"
      strokeWidth="3"
      strokeOpacity="0"
      onMouseEnter={onMouseEnter}
    />
    <line {...guide} stroke="lime" strokeWidth="1" strokeOpacity="0.4" />
  </>
);

const HoveredLine = ({
  guide,
  onMouseLeave,
  onClick,
}: {
  guide: Guide;
  onMouseLeave: () => void;
  onClick: () => void
}) => (
  <line
    {...guide}
    stroke="white"
    strokeWidth="3"
    strokeOpacity="1"
    onMouseLeave={onMouseLeave}
    onClick={onClick}
  />
);
