import { date, InferType, number, object, string } from '@/shared/config/yup';
import { Gender } from '@/shared/api/protocol_gen/model/dto_base';
import { addDoctorsSchema } from '@/features/addDoctors';

export const patientSchema = object({
  firstName: string().required().max(50),
  lastName: string().required().max(50),
  email: string().email().max(50),
  patientExternalID: string().required().max(50),
  dateOfBirth: date().required(),
  gender: number()
    .required()
    .oneOf([Gender.GenderMale, Gender.GenderFemale, Gender.GenderOther]),
}).concat(addDoctorsSchema);

export type PatientPayload = InferType<typeof patientSchema>;

export const DATE_OF_BIRTH_FORMAT = 'yyyy-MM-dd';

export const PATIENT_FORM_ID = 'patient-form';
