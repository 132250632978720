import { FC } from 'react';
import cn from 'classnames';

import { organizationModel } from '@/entities/organization';

import { Method } from '../Method/Method';

import styles from './OrganizationTest.module.scss';

type Props = {
  className?: string;
};

export const OrganizationTest: FC<Props> = (props) => {
  const { className } = props;

  return (
    <div className={cn(className)}>
      <h1 className={cn('h1')}>Organization Entity</h1>
      <div className={styles.methods}>
        {Object.keys(organizationModel.thunks).map((thunkName) => (
          <Method
            key={thunkName}
            thunk={organizationModel.thunks}
            label={thunkName}
          />
        ))}
      </div>
    </div>
  );
};
