import {
  createSlice,
  createEntityAdapter,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import { DefaultInitialState, SliceLoading, SliceName } from '@/shared/config';

import { deleteReport } from './reportSlice.thunks';

export const reportsAdapter = createEntityAdapter<Report>({
  selectId: (report) => report.ID,
});

type ReportInitialState = DefaultInitialState & {
  flags: {
    isReportRequestPending: boolean;
    isDeleteReportPending: boolean;
    isReportROIPending: boolean;
  };
};

const initialState: ReportInitialState = {
  flags: {
    isReportRequestPending: false,
    isDeleteReportPending: false,
    isReportROIPending: false,
  },
  loading: 'idle',
};

type StateFlags = keyof typeof initialState.flags;

const reportsSlice = createSlice({
  name: SliceName.report,
  initialState: reportsAdapter.getInitialState(initialState),
  reducers: {
    addOne: reportsAdapter.addOne,
    addMany: reportsAdapter.addMany,
    setNewestOne: (state, action: PayloadAction<Report>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision.Number;

      if (payloadRevisionNumber > currentRevisionNumber) {
        reportsAdapter.setOne(state, action.payload);
      }
    },
    setMany: reportsAdapter.setMany,
    removeOne: reportsAdapter.removeOne,
    setLoading: (state, action: PayloadAction<SliceLoading>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    const asyncRequestCreator = <A, B, C>(
      thunkAction: AsyncThunk<A, B, C>,
      pendingFlagKey: StateFlags,
      fulfilledCallback?: (payload: unknown) => void,
    ) => {
      builder.addCase(thunkAction.pending, (state) => {
        state.flags[pendingFlagKey] = true;
      });
      builder.addCase(thunkAction.fulfilled, (state, { payload }) => {
        state.flags[pendingFlagKey] = false;
        if (typeof fulfilledCallback === 'function') {
          fulfilledCallback(payload);
        }
      });
      builder.addCase(thunkAction.rejected, (state) => {
        state.flags[pendingFlagKey] = false;
      });
    };

    asyncRequestCreator(deleteReport, 'isDeleteReportPending');
  },
});

export const { actions } = reportsSlice;

export default reportsSlice.reducer;
