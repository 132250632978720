import { OrganizationSpecialization } from '@/shared/api/protocol_gen/model/dto_organization';

export enum ReferralsGroupsByStudyType {
  FMXAndPano = 'FMXAndPano',
  CBCT = 'CBCT',
}

export type ReferralsGroupsByReportAndOrganizationSpecialization =
  typeof ReferralsGroupsByStudyType & typeof OrganizationSpecialization;

export type ReferralsGroupsType =
  | ReferralsGroupsByReportAndOrganizationSpecialization['FMXAndPano']
  | ReferralsGroupsByReportAndOrganizationSpecialization['CBCT']
  | ReferralsGroupsByReportAndOrganizationSpecialization['OrganizationSpecializationGeneral']
  | ReferralsGroupsByReportAndOrganizationSpecialization['OrganizationSpecializationEndodontist']
  | ReferralsGroupsByReportAndOrganizationSpecialization['OrganizationSpecializationOrthodontist']
  | ReferralsGroupsByReportAndOrganizationSpecialization['OrganizationSpecializationPeriodontologist']
  | ReferralsGroupsByReportAndOrganizationSpecialization['OrganizationSpecializationProsthodontist'];
