import { FC } from 'react';
import { useParams } from 'react-router';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import { Button, Column, Layout, TabGroup, WidgetLayout } from '@/shared/ui';
import { useAppSelector, useMedia } from '@/shared/hooks';
import { getPatientFullName, patientModel } from '@/entities/patient';
import { ENABLED_RECENT_REPORTS_TYPES, reportsModel } from '@/entities/reports';
import { studyModel } from '@/entities/study';
import { Header } from '@/widgets/Header';
import { PatientInfo } from '@/widgets/PatientInfo';
import { RecentReports } from '@/widgets/RecentReports';
import {
  PatientModalForm,
  usePatientModalForm,
} from '@/widgets/PatientModalForm';
import {
  StudyUpload,
  StudyInfoTypes,
  studyUploadImages,
} from '@/widgets/StudyUpload';
import { ProfileToothChart } from '@/widgets/ProfileToothChart';
import { StudyCard } from '@/widgets/StudyCard';
import {
  StudyInformation,
  useStudyInfoModal,
} from '@/widgets/StudyInformation';

import { usePatientProfileStream } from './hooks/usePatientProfileStream';
import styles from './PatientProfile.module.scss';

const mockStudiesInfo: StudyInfoTypes[] = [
  {
    studyID: '1',
    title: <FormattedMessage id="studyUpload.Cbct" defaultMessage="CBCT" />,
    image: studyUploadImages.model,
  },
  {
    studyID: '2',
    title: (
      <FormattedMessage id="studyUpload.3DModel" defaultMessage="3D Model" />
    ),
    image: studyUploadImages.cbct,
  },
  {
    studyID: '3',
    title: <FormattedMessage id="studyUpload.Ios" defaultMessage="IOS" />,
    image: studyUploadImages.doc,
  },
  {
    studyID: '4',
    title: <FormattedMessage id="studyUpload.Docs" defaultMessage="Docs" />,
    image: studyUploadImages.pano,
  },
  {
    studyID: '5',
    title: (
      <FormattedMessage id="studyUpload.PanoFmx" defaultMessage="PANO, FMX" />
    ),
    image: studyUploadImages.ios,
  },
  {
    studyID: '6',
    title: (
      <FormattedMessage
        id="studyUpload.DentalPhotos"
        defaultMessage="Dental photos"
      />
    ),
    image: studyUploadImages.photo,
  },
];

export const PatientProfile: FC = () => {
  const { patientID } = useParams();

  const {
    currentPatientID,
    showPatientModalForm,
    openEditPatientModalForm,
    closePatientModalForm,
  } = usePatientModalForm();

  const {
    currentStudyID,
    openStudyInfoModal,
    closeStudyModal,
    showStudyInfoModal,
  } = useStudyInfoModal();

  const { isMobile } = useMedia();

  const patient = useAppSelector((state) =>
    patientModel.selectors.selectById(state, patientID),
  );

  const studies = useAppSelector(studyModel.selectors.selectAll);

  const patientRecentReports = useAppSelector((state) =>
    reportsModel.selectors.selectPatientRecentReports(
      state,
      patientID,
      ENABLED_RECENT_REPORTS_TYPES,
    ),
  );

  const CBCTStudies = useAppSelector((state) =>
    studyModel.selectors.selectByType(state, StudyType.StudyType_CBCT),
  );
  const panoramicStudies = useAppSelector((state) =>
    studyModel.selectors.selectByType(state, StudyType.StudyType_PanoramicXRay),
  );
  const fmxStudies = useAppSelector((state) =>
    studyModel.selectors.selectByType(state, StudyType.StudyType_IntraoralXRay),
  );

  const isUploadStudyWidgetVisible = !studies?.length;
  const isRecentReportsWidgetVisible = !!patientRecentReports?.length;

  usePatientProfileStream(patientID);

  const tabItems = [
    {
      label: (
        <FormattedMessage id="patient.overview" defaultMessage="Overview" />
      ),
      value: 'Overview',
      content: (
        <WidgetLayout>
          <Column>
            <PatientInfo
              patientID={patientID}
              onEditPatient={openEditPatientModalForm}
            />
          </Column>
          <Column>
            <ProfileToothChart />

            {isRecentReportsWidgetVisible && (
              <RecentReports
                reports={patientRecentReports}
                patientID={patientID}
              />
            )}

            {isUploadStudyWidgetVisible && (
              <StudyUpload studiesInfo={mockStudiesInfo} />
            )}
          </Column>
        </WidgetLayout>
      ),
    },
    {
      label: (
        <FormattedMessage
          id="patient.studiesAndReports"
          defaultMessage="Studies & Reports"
        />
      ),
      value: 'Studies&Reports',
      content: (
        <WidgetLayout>
          <Column>
            <StudyCard
              studies={CBCTStudies}
              onViewDetails={openStudyInfoModal}
            />
          </Column>

          <Column>
            <StudyUpload studiesInfo={mockStudiesInfo} />
            <StudyCard
              studies={panoramicStudies}
              onViewDetails={openStudyInfoModal}
            />
            <StudyCard
              studies={fmxStudies}
              onViewDetails={openStudyInfoModal}
            />
          </Column>
        </WidgetLayout>
      ),
    },
    {
      label: (
        <FormattedMessage id="patient.3dModels" defaultMessage="3D models" />
      ),
      value: '3Dmodels',
      content: (
        <WidgetLayout>
          <Column />
          <Column />
        </WidgetLayout>
      ),
    },
  ];

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main>
          <section className={styles.main}>
            <h1 className={cn(!isMobile ? 'h1' : 'h2', styles.patientName)}>
              {getPatientFullName(patient)}
            </h1>

            <TabGroup
              tabName="patientProfile"
              tabItems={tabItems}
              size={!isMobile ? 'normal' : 'small'}
              extraContentRightClassName={styles.uploadStudyButtonWrapper}
              extraContent={
                <Button
                  size={!isMobile ? 'large' : 'small'}
                  icon="plus"
                  className={styles.uploadStudyButton}
                >
                  <FormattedMessage
                    id="patientProfile.uploadNewStudy"
                    defaultMessage="Upload new study"
                  />
                </Button>
              }
            />
          </section>

          <StudyInformation
            studyID={currentStudyID}
            isOpen={showStudyInfoModal}
            onClose={closeStudyModal}
          />

          <PatientModalForm
            patientID={currentPatientID}
            isOpen={showPatientModalForm}
            onClose={closePatientModalForm}
          />
        </Layout.Main>
      </Layout.Content>
      <Layout.Footer />
    </Layout>
  );
};
