import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format, subYears } from 'date-fns';
import { isEqual } from 'lodash';

import { Gender } from '@/shared/api/protocol_gen/model/dto_base';
import {
  DatePicker,
  Input,
  Modal,
  ModalProps,
  RadioGroup,
  ErrorText,
  Button,
} from '@/shared/ui';
import { gender } from '@/shared/i18n';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { patientModel } from '@/entities/patient';
import { organizationModel } from '@/entities/organization';
import { DoctorsSelect } from '@/entities/users';
import { useOrganizationDoctorsOptions } from '@/features/addDoctors';

import {
  patientSchema,
  PatientPayload,
  DATE_OF_BIRTH_FORMAT,
  PATIENT_FORM_ID,
} from '../config';
import { getEditPatientDefaultValues } from '../lib/getEditPatientDefaultValues';

import styles from './PatientModalForm.module.scss';

type PatientModalFormProps = {
  patientID?: string;
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const PatientModalForm: FC<PatientModalFormProps> = (props) => {
  const { isOpen, onClose, patientID } = props;

  const [globalFormError, setGlobalFormError] = useState<string>();

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const isEditPatient = Boolean(patientID);

  const patient = useAppSelector((state) =>
    patientModel.selectors.selectById(state, patientID),
  );

  const currentOrganizationID = useAppSelector(
    organizationModel.selectors.selectID,
  );

  const doctorsOptions = useOrganizationDoctorsOptions();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<PatientPayload>({
    resolver: yupResolver(patientSchema),
  });

  // Set default
  useEffect(() => {
    if (patientID) {
      reset(getEditPatientDefaultValues(patient));
    } else {
      reset({
        dateOfBirth: subYears(new Date(), 10),
      });
    }
  }, [patientID]);

  const { isPhone, isMobile } = useMedia();

  const buttonSize = isPhone ? 'medium' : 'large';

  const onSubmit: SubmitHandler<PatientPayload> = async (data) => {
    try {
      if (isEditPatient) {
        const shouldUpdatePersonalData =
          data.firstName !== patient.PersonalData.FirstName ||
          data.lastName !== patient.PersonalData.LastName ||
          !isEqual([data.email], patient.PersonalData.Emails) ||
          format(data.dateOfBirth, DATE_OF_BIRTH_FORMAT) !==
            patient.PersonalData.DateOfBirth;

        const shouldUpdateGender = data.gender !== patient.Gender;

        if (shouldUpdatePersonalData) {
          const updatedPatient = await dispatch(
            patientModel.thunks.setPatientPersonalData({
              PatientID: patientID,
              PersonalData: {
                FirstName: data.firstName,
                LastName: data.lastName,
                DateOfBirth: format(data.dateOfBirth, DATE_OF_BIRTH_FORMAT),
                Emails: data.email ? [data.email] : [],
              },
            }),
          ).unwrap();

          dispatch(patientModel.actions.setNewestOne(updatedPatient.Patient));
        }

        if (shouldUpdateGender) {
          const updatedPatient = await dispatch(
            patientModel.thunks.setPatientGender({
              PatientID: patientID,
              Gender: data.gender,
            }),
          ).unwrap();

          dispatch(patientModel.actions.setNewestOne(updatedPatient.Patient));
        }
        // TODO: To add or remove doctors using AddPatientTeamMember and RevokePatientTeamAccess from svc_access
        // Need to make access entity
      } else {
        const newPatient = await dispatch(
          patientModel.thunks.createPatient({
            OrganizationID: currentOrganizationID,
            Gender: data.gender,
            PersonalData: {
              FirstName: data.firstName,
              LastName: data.lastName,
              DateOfBirth: format(data.dateOfBirth, DATE_OF_BIRTH_FORMAT),
              Emails: data.email ? [data.email] : [],
            },
            ExternalID: data.patientExternalID,
            TreatingDoctorIDs: data.treatingDoctor,
          }),
        ).unwrap();

        dispatch(patientModel.actions.setNewestOne(newPatient.Patient));
      }

      setGlobalFormError(undefined);
      onClose();
      reset({});
    } catch (error) {
      setGlobalFormError(error.message);
    }
  };

  return (
    <Modal
      containerClassName={styles.container}
      bodyClassName={styles.modalBody}
      title={
        isEditPatient ? (
          <FormattedMessage
            id="patientModalForm.editTitle"
            defaultMessage="Edit patient details"
          />
        ) : (
          <FormattedMessage
            id="patientModalForm.title"
            defaultMessage="New patient"
          />
        )
      }
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <>
          {isEditPatient && (
            <Button
              className={styles.removeButton}
              size={buttonSize}
              variant="tertiary"
              icon="delete"
              danger
            >
              {!isMobile && (
                <FormattedMessage id="global.remove" defaultMessage="Remove" />
              )}
            </Button>
          )}

          <Button size={buttonSize} variant="tertiary" onClick={onClose}>
            <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
          </Button>

          <Button
            size={buttonSize}
            type="submit"
            form={PATIENT_FORM_ID}
            loading={isSubmitting}
          >
            {isEditPatient ? (
              <FormattedMessage
                id="patientModalForm.saveButton"
                defaultMessage="Save changes"
              />
            ) : (
              <FormattedMessage
                id="patientModalForm.addPatientButton"
                defaultMessage="Add patient"
              />
            )}
          </Button>
        </>
      }
    >
      <form
        id={PATIENT_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
      >
        <div className={styles.formRow}>
          <Controller
            control={control}
            name="firstName"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                label={
                  <FormattedMessage
                    id="patientModalForm.label.firstName"
                    defaultMessage="First Name"
                  />
                }
                error={error?.message}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                label={
                  <FormattedMessage
                    id="patientModalForm.label.lastName"
                    defaultMessage="Last Name"
                  />
                }
                error={error?.message}
                required
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="email"
          render={({
            field: { ref, value, name, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Input
              ref={ref}
              value={value}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              label={
                <FormattedMessage
                  id="patientModalForm.label.email"
                  defaultMessage="Email"
                />
              }
              error={error?.message}
            />
          )}
        />

        <div className={styles.formRow}>
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DatePicker
                value={value}
                onChange={onChange}
                label={
                  <FormattedMessage
                    id="patientModalForm.label.dateOfBirth"
                    defaultMessage="Date of birth"
                  />
                }
                error={error?.message}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="patientExternalID"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                label={
                  <FormattedMessage
                    id="patientModalForm.label.patientID"
                    defaultMessage="Patient ID"
                  />
                }
                error={error?.message}
                required
              />
            )}
          />
        </div>

        {!isEditPatient && (
          <Controller
            control={control}
            name="treatingDoctor"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DoctorsSelect
                options={doctorsOptions}
                value={value}
                onChange={onChange}
                error={error?.message}
              />
            )}
          />
        )}

        <Controller
          control={control}
          name="gender"
          render={({
            field: { ref, value, name, onChange, onBlur },
            fieldState: { error },
          }) => (
            <RadioGroup
              ref={ref}
              value={value}
              name={name}
              variant="tab"
              onBlur={onBlur}
              onChange={onChange}
              error={error?.message}
              items={[
                {
                  value: Gender.GenderMale,
                  label: formatMessage(gender[Gender.GenderMale]),
                },
                {
                  value: Gender.GenderFemale,
                  label: formatMessage(gender[Gender.GenderFemale]),
                },
                {
                  value: Gender.GenderOther,
                  label: formatMessage(gender[Gender.GenderOther]),
                },
              ]}
            />
          )}
        />

        <ErrorText error={globalFormError} />
      </form>
    </Modal>
  );
};
