import { defineMessages } from 'react-intl';

import { ScanDataFields } from './const';

export const studyScanInfoMessages = defineMessages<ScanDataFields>({
  date: {
    id: 'studyInfo.scanInfo.date',
    defaultMessage: 'Date of study',
  },
  manufacturer: {
    id: 'studyInfo.scanInfo.manufacturer',
    defaultMessage: 'Manufacturer',
  },
  model: {
    id: 'studyInfo.scanInfo.model',
    defaultMessage: 'Model',
  },
  dap: {
    id: 'studyInfo.scanInfo.dap',
    defaultMessage: 'Exposure',
  },
  fov: {
    id: 'studyInfo.scanInfo.fov',
    defaultMessage: 'Field of view',
  },
  voxel: {
    id: 'studyInfo.scanInfo.voxel',
    defaultMessage: 'Voxel',
  },
});
