import { createAsyncThunk, DeepPartial } from '@reduxjs/toolkit';

import api, { ApiError } from '@/shared/api/api';
import {
  PatientServiceClientImpl,
  CreatePatientReq,
  CreatePatientResp,
  DeletePatientReq,
  DeletePatientResp,
  SetPatientExternalIDReq,
  SetPatientExternalIDResp,
  SetPatientGenderReq,
  SetPatientGenderResp,
  SetPatientPersonalDataReq,
  SetPatientPersonalDataResp,
  SetPatientStatusReq,
  SetPatientStatusResp,
} from '@/shared/api/protocol_gen/api/core/svc_patient';

type PatientRequests = keyof PatientServiceClientImpl;

const requestGenerator = <Req, Res>(requestName: PatientRequests) =>
  createAsyncThunk(
    `patient/${requestName}`,
    async (request: DeepPartial<Req>, { rejectWithValue }) => {
      try {
        const response = (await api.patient[requestName](
          request,
        )) as unknown as Res;

        return response;
      } catch (error: unknown) {
        const { message, type } = error as ApiError;

        return rejectWithValue({ message, type });
      }
    },
  );

export const createPatient = requestGenerator<
  CreatePatientReq,
  CreatePatientResp
>('CreatePatient');

export const deletePatient = requestGenerator<
  DeletePatientReq,
  DeletePatientResp
>('DeletePatient');

export const setPatientPersonalData = requestGenerator<
  SetPatientPersonalDataReq,
  SetPatientPersonalDataResp
>('SetPatientPersonalData');

export const setPatientExternalID = requestGenerator<
  SetPatientExternalIDReq,
  SetPatientExternalIDResp
>('SetPatientExternalID');

export const setPatientGender = requestGenerator<
  SetPatientGenderReq,
  SetPatientGenderResp
>('SetPatientGender');

export const setPatientStatus = requestGenerator<
  SetPatientStatusReq,
  SetPatientStatusResp
>('SetPatientStatus');
