import { filter } from 'ramda';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { isConditionInGroups } from './isConditionInGroups';

export const filterConditionsByGroups =
  (groups: Record<string, ConditionCode[]>) =>
  (conditions: Condition[]): Condition[] =>
    filter(isConditionInGroups(groups))(conditions);
