// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmSignReportModal-module__container--U71znYYF{width:75%;margin:0 auto}.ConfirmSignReportModal-module__buttons--eBa8mu8\\+{display:flex;justify-content:space-around;margin-top:16px}", "",{"version":3,"sources":["webpack://./src/pages/Report/ui/ConfirmSignReportModal/ConfirmSignReportModal.module.scss"],"names":[],"mappings":"AAAA,oDACE,SAAA,CACA,aAAA,CAGF,mDACE,YAAA,CACA,4BAAA,CACA,eAAA","sourcesContent":[".container {\n  width: 75%;\n  margin: 0 auto;\n}\n\n.buttons {\n  display: flex;\n  justify-content: space-around;\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ConfirmSignReportModal-module__container--U71znYYF",
	"buttons": "ConfirmSignReportModal-module__buttons--eBa8mu8+"
};
export default ___CSS_LOADER_EXPORT___;
