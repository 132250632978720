import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/model/store';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import {
  filterConditionsByToothID,
  filterConditionsByPatientID,
  filterConditionsByReportID,
  filterConditionsByToothIDAndGroupCode,
  findConditionByToothIDAndCode,
} from '../lib';
import { ConditionGroups } from '../config/const';

import { conditionAdapter } from './conditionSlice';

export const conditionSelectors = conditionAdapter.getSelectors(
  (state: RootState) => state.condition,
);

export const { selectAll, selectIds, selectEntities, selectTotal, selectById } =
  conditionSelectors;

export const selectByPatientID = createSelector(
  conditionSelectors.selectAll,
  (state: RootState, patientID: string) => patientID,
  filterConditionsByPatientID,
);

export const selectByReportID = createSelector(
  conditionSelectors.selectAll,
  (state: RootState, reportID: string) => reportID,
  filterConditionsByReportID,
);

// Use this as a refference how to write selectors to avoid rerender
export const selectByToothID = (toothID: string) =>
  createSelector(conditionSelectors.selectAll, (conditions) =>
    filterConditionsByToothID(conditions, toothID),
  );

export const selectByToothIDAndCode = createSelector(
  conditionSelectors.selectAll,
  (state: RootState, toothID: string) => toothID,
  (state: RootState, toothID: string, conditionCode: ConditionCode) =>
    conditionCode,
  findConditionByToothIDAndCode,
);

export const selectByToothIDAndGroupCode = createSelector(
  conditionSelectors.selectAll,
  (state: RootState, toothID: string) => toothID,
  (state: RootState, toothID: string, conditionGroupCode: ConditionGroups) =>
    conditionGroupCode,
  filterConditionsByToothIDAndGroupCode,
);

export const selectByROIIDs = (ROI: Tooth[]) =>
  createSelector(conditionSelectors.selectAll, (conditions) =>
    ROI.reduce(
      (acc, tooth) => [
        ...acc,
        ...filterConditionsByToothID(conditions, tooth.ID),
      ],
      [] as Condition[],
    ),
  );
