import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Portal } from '../Portal/Portal';
import { Icon } from '../Icon/Icon';

import styles from './Popup.module.scss';

export type PopupProps = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  testID?: string;
  shouldRenderCloseIconButton?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  children?: ReactNode;
};

export const Popup: FC<PopupProps> = ({
  isOpen,
  onClose,
  className,
  testID,
  shouldRenderCloseIconButton = true,
  shouldCloseOnOverlayClick = true,
  children,
}) => {
  if (!isOpen) return null;

  return (
    <Portal>
      <div className={styles.popup} data-testid={testID}>
        <div
          className={styles.overlay}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(shouldCloseOnOverlayClick ? { onClick: onClose } : {})}
        />
        <div className={styles.container}>
          <header
            className={cn(
              styles.header,
              shouldRenderCloseIconButton && styles.rowReverse,
            )}
          >
            {shouldRenderCloseIconButton && (
              <Icon
                className={styles.crossBtn}
                name="close"
                size={40}
                onClick={onClose}
              />
            )}
          </header>
          <div className={cn(styles.popupBody, className)}>{children}</div>
        </div>
      </div>
    </Portal>
  );
};
