import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import { FormattedMessage } from 'react-intl';

import { ProcessingUnitContext } from 'graphics';
import { ImageToolbar } from '@/features/imageToolbar';
import { Checkbox, Popup } from '@/shared/ui';
import { useSliceControls } from '@/entities/reports';
import { UltimateMedicalImage } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { ModalID, modalModel } from '@/entities/modal';
import { SliceInterface } from '@/entities/assets';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import {
  POPUP_DEFAULT_SIZE,
  RESIZABLE_BOX_DEFAULT_SIZE,
  RESIZABLE_BOX_MIN_CONSTRAINT,
  RESIZABLE_BOX_MAX_CONSTRAINT,
  RESIZEING_COEFFICIENT,
  CONTROLS,
} from '../config/constants';

import styles from './ZoomedSliceModal.module.scss';

import 'react-resizable/css/styles.css';

export type ZoomedSliceModalProps = {
  toggleSelected?: (src: string) => void;
  selectedSlices?: SliceInterface[];
};

export const ZoomedSliceModal: FC<ZoomedSliceModalProps> = (props) => {
  const { toggleSelected, selectedSlices } = props;

  const dispatch = useAppDispatch();

  const {
    visible,
    data: { src },
  } = useAppSelector((state) => state.modal.ZoomedSlice);

  const [dicom, setDicom] = useState<ProcessingUnitContext>();
  const isTheSliceSelected = useMemo(
    () => selectedSlices?.some((slice) => slice.src === src),
    [selectedSlices, src],
  );

  const {
    activeControl,
    setActiveControl,
    mode,
    setMode,
    size,
    setSize,
    annotations,
    setAnnotations,
    viewAnnotations,
    sharpness,
    setSharpness,
    currentAnnotation,
    wwwc,
    setWWWC,
  } = useSliceControls(dicom, src);

  const onDicomLoaded = (newDicom: ProcessingUnitContext) => {
    setDicom(newDicom);
  };

  const onResize = (_: SyntheticEvent, data: ResizeCallbackData) => {
    setSize({
      w: data.size.width * RESIZEING_COEFFICIENT,
      h: data.size.height * RESIZEING_COEFFICIENT,
    });
  };

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.ZoomedSlice));
  }, [dispatch]);

  const handleClosePopup = () => {
    setActiveControl('');
    setMode('view');
    setSize({ w: POPUP_DEFAULT_SIZE, h: POPUP_DEFAULT_SIZE });
    handleClose();
  };

  return (
    <Popup isOpen={visible} onClose={handleClosePopup} className={styles.popup}>
      {toggleSelected ? (
        <Checkbox
          checked={isTheSliceSelected}
          onChange={() => toggleSelected(src)}
        >
          <FormattedMessage id="slices.Selected" defaultMessage="Selected" />
        </Checkbox>
      ) : null}

      <ResizableBox
        width={RESIZABLE_BOX_DEFAULT_SIZE}
        height={RESIZABLE_BOX_DEFAULT_SIZE}
        minConstraints={[
          RESIZABLE_BOX_MIN_CONSTRAINT,
          RESIZABLE_BOX_MIN_CONSTRAINT,
        ]}
        maxConstraints={[
          RESIZABLE_BOX_MAX_CONSTRAINT,
          RESIZABLE_BOX_MAX_CONSTRAINT,
        ]}
        onResize={onResize}
      >
        <div className={styles.controls}>
          <UltimateMedicalImage
            src={{ url: src, kind: 'dicom' }}
            mode={mode}
            currentAnnotation={currentAnnotation}
            annotations={viewAnnotations ? annotations : []}
            onAnnotationsChanged={setAnnotations}
            width={size.w}
            height={size.h}
            onLoaded={onDicomLoaded}
            onWWWCChanged={setWWWC}
            ww={wwwc.ww}
            wc={wwwc.wc}
            sharpness={sharpness}
            onSharpnessChanged={setSharpness}
            inverted={false}
          />
          <ImageToolbar
            controls={CONTROLS}
            activeControl={activeControl}
            setActiveControl={setActiveControl}
            className={styles.toolbar}
          />
        </div>
      </ResizableBox>
    </Popup>
  );
};
