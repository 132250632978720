import React from 'react';
import { add, angle, cross, len, norm, scl, sub, vec2 } from '../vec2';
import { Annotation } from './Annotation';

export type AngleProps = Omit<Extract<Annotation, { kind: 'angle' }>, 'kind'>;

const strokeColor = 'lime';

export const Angle = ({ x1, x2, x3, y1, y2, y3 }: AngleProps) => {
  // Render the only line while both hands are the same (cretion of the first hand)
  if (x3 === x1 && y3 === y1)
    return <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={strokeColor} strokeWidth="3" />;

  const arcRadius = 20;
  const firstHand = vec2(x1, y1);
  const angleVertex = vec2(x2, y2);
  const secondHand = vec2(x3, y3);
  // Normalized vector from angleVertex to the firstHand in local space
  const a = norm(sub(firstHand, angleVertex));
  // Normalized vector from angleVertex to the secondHand in local space
  const b = norm(sub(secondHand, angleVertex));

  // Scaled `a` vector in world space. Point where arc starts
  const A = add(angleVertex, scl(a, arcRadius));
  // Scaled `b` vector in world space. Point where arc ends
  const B = add(angleVertex, scl(b, arcRadius));

  // Scaled with arcRadius angle bisector. Is a point to position text
  const C = add(angleVertex, scl(norm(add(a, b)), arcRadius));
  // 57.2958 makes degrees from radians
  const angleText = (angle(a, b) * 57.2958).toFixed(0);
  // flag for arc drawing.
  // angle(a,b) cannot be larger that 180. It calculated the shortest angle
  // To figure out is this angle clockwise or counter-clockwise (to render arc correctly)
  // we calculate cross-product:
  // cross(a,b) > 0 means angle is clockwise and less than 180 deg
  // cross(a,b) < 0 means angle is counther-clockwise and less than 180 deg
  const sweepFlag = cross(a, b) > 0 ? 1 : 0;

  return (
    <>
      <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={strokeColor} strokeWidth="3" />
      <line x1={x2} y1={y2} x2={x3} y2={y3} stroke={strokeColor} strokeWidth="3" />
      <path
        d={`M ${A.x} ${A.y} A ${arcRadius} ${arcRadius} 0 0 ${sweepFlag} ${B.x} ${B.y}`}
        stroke={strokeColor}
        fill="transparent"
      />
      <text transform={`translate(${C.x},${C.y} )`} fill={strokeColor} textAnchor="middle">
        {angleText}
      </text>
    </>
  );
};
