// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DoctorsSelect-module__container--Rii6a\\+JP{display:flex}\n\n.DoctorsSelect-module__selectedIcon--TmdxL6zM{margin-left:auto;margin-right:12px;color:var(--purpleflat)}\n\n[dir=\"rtl\"] .DoctorsSelect-module__selectedIcon--TmdxL6zM{margin-right:auto;margin-left:12px}", "",{"version":3,"sources":["webpack://./src/entities/users/ui/DoctorsSelect/DoctorsSelect.module.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA;;AAGF,8CACE,gBAAA,CACA,iBAAA,CACA,uBAAA;;AAHF,0DACE,iBAAA,CACA,gBACA","sourcesContent":[".container {\n  display: flex;\n}\n\n.selectedIcon {\n  margin-left: auto;\n  margin-right: 12px;\n  color: var(--purpleflat);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DoctorsSelect-module__container--Rii6a+JP",
	"selectedIcon": "DoctorsSelect-module__selectedIcon--TmdxL6zM"
};
export default ___CSS_LOADER_EXPORT___;
