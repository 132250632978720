import React, { FC } from 'react';
import cn from 'classnames';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';

import { Input, Layout, Modal, Select, toastCaller } from '@/shared/ui/';
import { Header } from '@/widgets/Header';
import { authModel } from '@/entities/auth';
import { useAppDispatch } from '@/shared/hooks';
import { PATHS } from '@/shared/config';

import { useCountryOptions } from './hooks/useCountryOptions';
import { useLanguageOptions } from './hooks/useLanguageOptions';
import styles from './SignUp.module.scss';
import { SignUpFormPayload, signUpFormSchema } from './config/formConfig';

type SignUpProps = {
  className?: string;
  testID?: string;
};

export const SignUp: FC<SignUpProps> = (props) => {
  const { className, testID } = props;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const {formatMessage} = useIntl();
  
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<SignUpFormPayload>({
    resolver: yupResolver(signUpFormSchema),
  });

  const countryOptions = useCountryOptions();
  const languageOptions = useLanguageOptions();

  const onSubmit: SubmitHandler<SignUpFormPayload> = async (data) => {
    const { email, country, language, lastName, firstName, password } = data;

    try {
      const signUpResponse = await dispatch(
        authModel.thunks.startSignUp({
          Email: email,
          Info: {
            Country: country,
            Language: language,
            FirstName: firstName,
            LastName: lastName,
          },
        }),
      ).unwrap();

      if (signUpResponse.InProgress) {
        const verifyResponse = await dispatch(
          authModel.thunks.verify({
            Value: password,
          }),
        ).unwrap();

        if (verifyResponse?.AuthState?.InProgress) {
          navigate(PATHS.confirmEmail, {
            state: {
              email,
            },
          });
        }
      }
    } catch (e) {
      toastCaller({
        message: JSON.parse(e.message).text,
        type: 'error',
        heading: formatMessage({id: 'global.error', defaultMessage: 'Error'}),
      });
    }
  };

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.Content>
        <Layout.Main>
          <div className={cn(styles.container, className)} data-testid={testID}>
            <Modal
              containerClassName={styles.container}
              bodyClassName={styles.modalBody}
              title="Sign Up"
              isOpen
              onClose={() => {}}
              applyButtonText="Sign up"
              applyButtonProps={{
                type: 'submit',
                form: 'signup',
                disabled: isSubmitting,
              }}
            >
              <form id="signup" onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.row}>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({
                      field: { ref, value, name, onBlur, onChange },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        ref={ref}
                        value={value}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        type="text"
                        label="First name"
                        error={error?.message}
                        autoComplete="given-name"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="lastName"
                    render={({
                      field: { ref, value, name, onBlur, onChange },
                      fieldState: { error },
                    }) => (
                      <Input
                        required
                        ref={ref}
                        value={value}
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        type="text"
                        label="Last name"
                        error={error?.message}
                        autoComplete="family-name"
                      />
                    )}
                  />
                </div>
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: { ref, value, name, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      ref={ref}
                      value={value}
                      name={name}
                      inputMode="email"
                      type="email"
                      onBlur={onBlur}
                      onChange={onChange}
                      label="Email"
                      error={error?.message}
                      autoComplete="email"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="password"
                  render={({
                    field: { ref, value, name, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      ref={ref}
                      value={value}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      type="password"
                      label="Password"
                      error={error?.message}
                      autoComplete="new-password"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="language"
                  render={({
                    field: { ref, value, name, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <Select
                      label="Language"
                      options={languageOptions}
                      isRequired
                      ref={ref}
                      value={`${value}`}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="country"
                  render={({
                    field: { ref, value, name, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <Select
                      label="Country"
                      options={countryOptions}
                      isRequired
                      ref={ref}
                      value={`${value}`}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </form>
            </Modal>
          </div>
        </Layout.Main>
      </Layout.Content>
      <Layout.Footer />
    </Layout>
  );
};
