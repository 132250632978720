import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { PatientListStreamResp_PatientItem_StudyCount } from '@/shared/api/protocol_gen/api/core/svc_patient';

type StudyCount = {
  patientID: string;
  counts: PatientListStreamResp_PatientItem_StudyCount[];
};

export const studyCountAdapter = createEntityAdapter<StudyCount>({
  selectId: (count) => count.patientID,
});

const studyCountSlice = createSlice({
  name: 'studyCount',
  initialState: studyCountAdapter.getInitialState(),
  reducers: {
    setOne: studyCountAdapter.setOne,
    addMany: studyCountAdapter.addMany,
    setMany: studyCountAdapter.setMany,
  },
});

export const { setOne, addMany, setMany } = studyCountSlice.actions;

export default studyCountSlice.reducer;
