import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { collectConditionCodesFromGroups } from '../lib/collectConditionCodesFromGroups';

export const RED_CODES = [
  ConditionCode.Crowding,
  ConditionCode.VoidsInRestoration,
  ConditionCode.Avulsion,
  ConditionCode.InternalResorption,
  ConditionCode.AbnormalityOfSizeAndForm,
  ConditionCode.EndoRootPerforation,
  ConditionCode.EndoVoidsPresentInTheRootFilling,
  ConditionCode.HorizontalDisplacement,
  ConditionCode.SecondaryOrTertriaryDentin,
  ConditionCode.LateralRadiolucency,
  ConditionCode.DentalRestorationLost,
  ConditionCode.CrownDefectOver50pct,
  ConditionCode.Missing,
  ConditionCode.EndoOverfilling,
  ConditionCode.BleedingGums,
  ConditionCode.EndoFracturedInstrumentInRootCanal,
  ConditionCode.Spacing,
  ConditionCode.FailureOfEndoTreatment,
  ConditionCode.CrownDefect,
  ConditionCode.EndoVerticalRootFracture,
  ConditionCode.Overhang,
  ConditionCode.PeriodontalLigamentSpaceWideningAlongRoot,
  ConditionCode.ChippedTooth,
  ConditionCode.Pulpitis,
  ConditionCode.PreparedTooth,
  ConditionCode.DiscolorationOfTooth,
  ConditionCode.CrownFracture,
  ConditionCode.Ankylosis,
  ConditionCode.ExcessiveAttritionOfTooth,
  ConditionCode.ForeignBody,
  ConditionCode.DentalCalculus,
  ConditionCode.LackOfInterproximalContact,
  ConditionCode.Impaction,
  ConditionCode.HorizontalRootFracture,
  ConditionCode.RootFragment,
  ConditionCode.GingivalRecession,
  ConditionCode.EndoMissedCanal,
  ConditionCode.Overeruption,
  ConditionCode.OpenMargin,
  ConditionCode.FurcationalRadiolucency,
  ConditionCode.EctopicDevelopmentAndEruption,
  ConditionCode.PeriapicalLesion,
  ConditionCode.SecondaryCaries,
  ConditionCode.FailureOfOsseointegrationOfImplant,
  ConditionCode.EndoRootCanalWithRestriction,
  ConditionCode.ExcessiveToothMobility,
  ConditionCode.CariesSigns,
  ConditionCode.FailureOfRestoration,
  ConditionCode.Abfraction,
  ConditionCode.EndoShortFilling,
  ConditionCode.SupernumeraryTooth,
  ConditionCode.SurfaceResorption,
  ConditionCode.Hypercementosis,
];

export const PURPLE_CODES = [
  ConditionCode.EndoAdequateObturation,
  ConditionCode.VoidsInRestoration,
  ConditionCode.ArtificialCrown,
  ConditionCode.EndoRootPerforation,
  ConditionCode.IndirectRestoration,
  ConditionCode.EndoVoidsPresentInTheRootFilling,
  ConditionCode.CanalFilling,
  ConditionCode.EndoOverfilling,
  ConditionCode.PostAndCore,
  ConditionCode.EndoFracturedInstrumentInRootCanal,
  ConditionCode.Pontic,
  ConditionCode.Post,
  ConditionCode.Inlay,
  ConditionCode.EndoCastPostAndCore,
  ConditionCode.Pulpotomy,
  ConditionCode.PreparedTooth,
  ConditionCode.DentalSealant,
  ConditionCode.Implant,
  ConditionCode.EndoFiberPost,
  ConditionCode.EndoMissedCanal,
  ConditionCode.EndoTreated,
  ConditionCode.Abutment,
  ConditionCode.OrthodonticAppliance,
  ConditionCode.Filling,
  ConditionCode.Apicoectomy,
  ConditionCode.EndoMetalPost,
  ConditionCode.EndoShortFilling,
  ConditionCode.EndoAdequateDensity,
];

export const YELLOW_CODES = [
  ConditionCode.CariesSigns,
  ConditionCode.SecondaryCaries,
  ConditionCode.PeriodontalLigamentSpaceWideningAlongRoot,
  ConditionCode.PeriodontalBoneLoss,
];

export enum ConditionGroups {
  toothType = 'type',
  rootsNumbers = 'rootsNumbers',
  canalsNumbers = 'canalsNumbers',
  perio = 'perio',
  position = 'position',
  crown = 'crown',
  root = 'root',
  endo = 'endo',
  implant = 'implant',
  periradicularPathologies = 'periradicularPathologies',
}

export enum ChildConditionGroups {
  depth = 'depth',
  surface = 'surface',
  perioSeverity = 'perioSeverity',
  perioType = 'perioType',
  impaction = 'impaction',
  obturation = 'obturation',
  quality = 'quality',
  complications = 'complications',
  buildup = 'buildup',
}

export const ONE_OF_CONDITION_GROUPS = [
  ConditionGroups.toothType,
  ConditionGroups.rootsNumbers,
  ConditionGroups.canalsNumbers,
];

export const TOOTH_TYPE_GROUP = {
  [ConditionGroups.toothType]: [
    ConditionCode.CodeTooth,
    ConditionCode.Implant,
    ConditionCode.Pontic,
    ConditionCode.RootFragment,
    ConditionCode.Missing,
    ConditionCode.ToothGerm,
  ],
};

export const TOOTH_ANATOMY_GROUPS = {
  [ConditionGroups.rootsNumbers]: [
    ConditionCode.Roots1,
    ConditionCode.Roots2,
    ConditionCode.Roots3,
    ConditionCode.Roots4,
  ],
  [ConditionGroups.canalsNumbers]: [
    ConditionCode.Canals1,
    ConditionCode.Canals2,
    ConditionCode.Canals3,
    ConditionCode.Canals4,
    ConditionCode.Canals5,
  ],
};

export const TOOTH_GENERAL_GROUPS = {
  [ConditionGroups.perio]: [
    ConditionCode.PeriodontalBoneLoss,
    ConditionCode.FurcationalRadiolucency,
    ConditionCode.DentalCalculus,
  ],
  [ConditionGroups.position]: [
    ConditionCode.Impaction,
    ConditionCode.HorizontalDisplacement,
    ConditionCode.Overeruption,
    ConditionCode.EctopicDevelopmentAndEruption,
  ],
  [ConditionGroups.crown]: [
    ConditionCode.Filling,
    ConditionCode.ArtificialCrown,
    ConditionCode.IndirectRestoration,
    ConditionCode.OrthodonticAppliance,
    ConditionCode.PulpStones,
    ConditionCode.Abfraction,
    ConditionCode.CrownFracture,
    ConditionCode.CrownDefectOver50pct,
    ConditionCode.VoidsInRestoration,
    ConditionCode.Overhang,
    ConditionCode.OpenMargin,
    ConditionCode.LackOfInterproximalContact,
    ConditionCode.EndodonticAccess,
    ConditionCode.PreparedTooth,
    ConditionCode.CariesSigns,
    ConditionCode.SecondaryCaries,
  ],
  [ConditionGroups.root]: [
    ConditionCode.InternalResorption,
    ConditionCode.HorizontalRootFracture,
    ConditionCode.EndoVerticalRootFracture,
    ConditionCode.Hypercementosis,
    ConditionCode.Apicoectomy,
  ],
  [ConditionGroups.endo]: [ConditionCode.Pulpotomy, ConditionCode.EndoTreated],
  [ConditionGroups.implant]: [ConditionCode.PeriImplantitis],
  [ConditionGroups.periradicularPathologies]: [
    ConditionCode.LateralRadiolucency,
    ConditionCode.PeriodontalLigamentSpaceWideningAlongRoot,
  ],
};

export const CONDITION_CODES_BY_GROUPS = {
  ...TOOTH_TYPE_GROUP,
  ...TOOTH_ANATOMY_GROUPS,
  ...TOOTH_GENERAL_GROUPS,
};

export const FLAT_CONDITION_GROUPS = collectConditionCodesFromGroups(
  CONDITION_CODES_BY_GROUPS,
);

const PERIODONTAL_BONE_LOSS_CHILD_GROUP = {
  [ConditionCode.PeriodontalBoneLoss]: {
    [ChildConditionGroups.perioSeverity]: [
      ConditionCode.Child_PeriodontalBoneLoss_Severity_Mild,
      ConditionCode.Child_PeriodontalBoneLoss_Severity_Moderate,
      ConditionCode.Child_PeriodontalBoneLoss_Severity_Severe,
    ],
    [ChildConditionGroups.perioType]: [
      ConditionCode.Child_PeriodontalBoneLoss_Shape_Horizontal,
      ConditionCode.Child_PeriodontalBoneLoss_Shape_Vertical,
      ConditionCode.Child_PeriodontalBoneLoss_Shape_Mixed,
    ],
  },
};

const IMPACTION_GROUP = {
  [ConditionCode.Impaction]: {
    [ChildConditionGroups.impaction]: [
      ConditionCode.Child_Impaction_Subtype_CompleteBony,
      ConditionCode.Child_Impaction_Subtype_PartialBony,
    ],
  },
};

const FILLING_CHILD_GROUP = {
  [ConditionCode.Filling]: {
    [ChildConditionGroups.depth]: [
      ConditionCode.Child_DefectDepth_Enamel,
      ConditionCode.Child_DefectDepth_Dentin,
      ConditionCode.Child_DefectDepth_Pulp,
      ConditionCode.Child_DefectDepth_Root,
      ConditionCode.Child_DefectDepth_NotSelected,
    ],
    [ChildConditionGroups.surface]: [
      ConditionCode.Child_DefectSurface_Mesial,
      ConditionCode.Child_DefectSurface_Distal,
      ConditionCode.Child_DefectSurface_Occlusial,
      ConditionCode.Child_DefectSurface_Incisal,
      ConditionCode.Child_DefectSurface_Buccal,
      ConditionCode.Child_DefectSurface_Vestibular,
      ConditionCode.Child_DefectSurface_Lingual,
      ConditionCode.Child_DefectSurface_NotSelected,
    ],
    [ChildConditionGroups.buildup]: [ConditionCode.Child_Filling_BuildUp],
  },
};
const CROWN_FRACTURE_CHILD_GROUP = {
  [ConditionCode.CrownFracture]: {
    [ChildConditionGroups.depth]: [
      ConditionCode.Child_DefectDepth_Enamel,
      ConditionCode.Child_DefectDepth_Dentin,
      ConditionCode.Child_DefectDepth_Pulp,
      ConditionCode.Child_DefectDepth_Root, // TODO: In exel schema its crown-root, not just root
    ],
  },
};

const CARIES_CHILD_GROUP = {
  [ConditionCode.CariesSigns]: {
    [ChildConditionGroups.surface]: [
      ConditionCode.Child_DefectSurface_Mesial,
      ConditionCode.Child_DefectSurface_Distal,
      ConditionCode.Child_DefectSurface_Occlusial,
      ConditionCode.Child_DefectSurface_Incisal,
      ConditionCode.Child_DefectSurface_Buccal,
      ConditionCode.Child_DefectSurface_Vestibular,
      ConditionCode.Child_DefectSurface_Lingual,
      ConditionCode.Child_DefectSurface_NotSelected,
    ],
    [ChildConditionGroups.depth]: [
      ConditionCode.Child_DefectDepth_Initial,
      ConditionCode.Child_DefectDepth_Enamel,
      ConditionCode.Child_DefectDepth_Dentin,
      ConditionCode.Child_DefectDepth_Pulp,
      ConditionCode.Child_DefectDepth_Root,
      ConditionCode.Child_DefectDepth_NotSelected,
    ],
  },
};

const SECONDARY_CARIES_CHILD_GROUP = {
  [ConditionCode.SecondaryCaries]: {
    [ChildConditionGroups.depth]: [
      ConditionCode.Child_DefectDepth_Initial,
      ConditionCode.Child_DefectDepth_Enamel,
      ConditionCode.Child_DefectDepth_Dentin,
      ConditionCode.Child_DefectDepth_Pulp,
      ConditionCode.Child_DefectDepth_Root,
      ConditionCode.Child_DefectDepth_NotSelected,
    ],
    [ChildConditionGroups.surface]: [
      ConditionCode.Child_DefectSurface_Mesial,
      ConditionCode.Child_DefectSurface_Distal,
      ConditionCode.Child_DefectSurface_Occlusial,
      ConditionCode.Child_DefectSurface_Incisal,
      ConditionCode.Child_DefectSurface_Buccal,
      ConditionCode.Child_DefectSurface_Vestibular,
      ConditionCode.Child_DefectSurface_Lingual,
      ConditionCode.Child_DefectSurface_NotSelected,
    ],
  },
};

export const CHILD_CONDITION_GROUPS = {
  ...PERIODONTAL_BONE_LOSS_CHILD_GROUP,
  ...IMPACTION_GROUP,
  ...FILLING_CHILD_GROUP,
  ...CROWN_FRACTURE_CHILD_GROUP,
  ...CARIES_CHILD_GROUP,
  ...SECONDARY_CARIES_CHILD_GROUP,
};
