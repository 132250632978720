import { compose, filter, propEq, sortBy } from 'ramda';

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';

const filterReportsByPatientID = (patientID: string) =>
  filter<Report>(propEq<keyof Report>('PatientID')(patientID));

const filterReportsByEnabledReportTypes = (
  enabledAsRecentTypes: ReportType[],
) => filter<Report>((report) => enabledAsRecentTypes.includes(report.Type));

const sortByRevisionUpdatedAt = sortBy<Report>(
  (a) => a.Revision.UpdatedAt * -1, // desc sorting
);

export const getPatientRecentReports = (
  reports: Report[],
  patientID: string,
  enabledAsRecentTypes: ReportType[],
) =>
  compose(
    sortByRevisionUpdatedAt,
    filterReportsByEnabledReportTypes(enabledAsRecentTypes),
    filterReportsByPatientID(patientID),
  )(reports);
