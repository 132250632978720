import { FC, memo, useCallback, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router';

import { Button, Icon, toastCaller, WidgetCard } from '@/shared/ui';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { PATHS } from '@/shared/config';
import { CommentTooth, useCommentTooth } from '@/features/commentTooth';
import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { ConditionControl } from '@/features/conditionControl';
import { ModalID, modalModel } from '@/entities/modal';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { useGetSelectedSlices } from '@/entities/assets';
import { useApproveTooth } from '@/features/approveTooth';
import { useToothConditions } from '@/entities/condition';

import styles from './ToothCard.module.scss';
import { SliceList } from './SliceList/SliceList';

type ToothCardProps = {
  className?: string;
  tooth: Tooth;
  onRemove: (toothID: string) => void;
};

export const InternalToothCard: FC<ToothCardProps> = (props) => {
  const { className, tooth, onRemove = () => {} } = props;

  const [isResetToothLoading, setResetToothLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { patientID, reportID } = useParams();
  const { isPhone, isMobile } = useMedia();

  const {
    anatomyConditionItems,
    generalConditionItems,
    groupedConditionItems,
  } = useToothConditions(tooth.ID);

  const { isApproveToothLoading, toggleApproveTooth } = useApproveTooth(tooth);

  const {
    newComment,
    isCommentButtonDisabled,
    isCommentEditorVisible,
    isNewCommentLoading,
    openCommentEditor,
    closeAndResetTextInEditor,
    openChangeComment,
    changeCommentInEditor,
    saveComment,
  } = useCommentTooth(tooth.Comment);

  const selectedSlices = useGetSelectedSlices(tooth.ID);

  const report = useAppSelector((state) =>
    reportsModel.selectors.selectById(state, reportID),
  );

  // TODO: incapsulate it in hook
  const handleResetTooth = async () => {
    setResetToothLoading(true);

    try {
      const response = await dispatch(
        reportsModel.thunks.resetReportTooth({
          ToothID: tooth.ID,
          GeneratedVersion: true,
        }),
      ).unwrap();
      dispatch(toothModel.actions.setNewestOne(response.Tooth));
    } catch (error) {
      toastCaller({
        message: error?.message,
        type: 'error',
        heading: 'Error',
      });
    } finally {
      setResetToothLoading(false);
    }
  };

  const handleAddCondition = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.AddCondition,
        data: { toothID: tooth.ID },
      }),
    );
  }, [tooth.ID, dispatch]);

  return (
    <WidgetCard className={cn(styles.container, className)} id={tooth.ID}>
      <header className={styles.header}>
        <h3 className={cn(styles.title, isMobile ? 'h4' : 'h3')}>
          <FormattedMessage
            id="toothCard.title"
            defaultMessage="Tooth {number}"
            values={{
              number: tooth?.ISONumber,
            }}
          />
        </h3>

        {/* TODO: Add skeleton when conditions are loading */}
        <div className={styles.conditions}>
          {anatomyConditionItems.map((conditionItem) => (
            <ConditionControl
              key={conditionItem.id}
              toothID={tooth.ID}
              conditionInterface={conditionItem}
              conditionInterfaceGroup={
                groupedConditionItems[conditionItem.group]
              }
            />
          ))}
        </div>

        <button
          type="button"
          className={styles.removeButton}
          onClick={() => onRemove(tooth.ID)}
        >
          <Icon name="close" size={40} />
        </button>
      </header>

      {/* TODO: Add skeleton when conditions are loading */}
      <div className={styles.conditions}>
        {generalConditionItems.map((conditionItem) => (
          <ConditionControl
            key={conditionItem.id}
            toothID={tooth.ID}
            conditionInterface={conditionItem}
            conditionInterfaceGroup={groupedConditionItems[conditionItem.group]}
          />
        ))}

        <Button
          size="small"
          variant="tertiary"
          icon="plus"
          onClick={handleAddCondition}
        >
          <FormattedMessage
            id="toothCard.openAddConditionModalButton"
            defaultMessage="Add contition"
          />
        </Button>
      </div>

      <SliceList slices={selectedSlices} />

      <footer className={styles.footer}>
        <Button
          variant="secondary"
          size="medium"
          icon="back"
          onClick={handleResetTooth}
          loading={isResetToothLoading}
        />
        <Button
          variant="secondary"
          size="medium"
          icon="comment"
          onClick={openCommentEditor}
          disabled={isCommentButtonDisabled}
        />
        {!isPhone && (
          <Button
            variant="secondary"
            size={isMobile ? 'small' : 'medium'}
            className={styles.footerButtonMargin}
            onClick={() => {
              navigate(
                generatePath(PATHS.tooth, {
                  patientID,
                  reportID,
                  toothID: tooth?.ID,
                }),
              );
            }}
          >
            <FormattedMessage
              id="toothCard.3dviewer"
              defaultMessage="3D viewer and slices"
            />
          </Button>
        )}

        <Button
          size="medium"
          icon={tooth.IsApproved ? 'check' : 'plus'}
          loading={isApproveToothLoading}
          onClick={toggleApproveTooth}
          disabled={!report.YourPermissions.CanChangeToothApproved}
          danger={tooth.IsApproved}
          className={styles.approveButton}
        >
          {tooth.IsApproved ? (
            <FormattedMessage
              id="toothCard.approved"
              defaultMessage="Approved"
            />
          ) : (
            <FormattedMessage id="toothCard.approve" defaultMessage="Approve" />
          )}
        </Button>
      </footer>

      <CommentTooth
        autoFocus
        comment={tooth.Comment}
        newComment={newComment}
        isNewCommentLoading={isNewCommentLoading}
        isCommentEditorVisible={isCommentEditorVisible}
        onSaveComment={() => saveComment(tooth.ID)}
        onCancel={closeAndResetTextInEditor}
        onOpenChangeComment={openChangeComment}
        onChangeCommentInEditor={changeCommentInEditor}
      />
    </WidgetCard>
  );
};

export const ToothCard = memo(InternalToothCard);
