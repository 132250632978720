import { ToothImage } from '../config/slices.types';

export const separateMasksAndImages = (imagesList: ToothImage[]) => {
  const masksAndImages = imagesList.reduce(
    (acc, image) => {
      const meta = image?.meta ? JSON.parse(image.meta) : {};
      const isMask = meta
        ? meta?.is_mask || image?.key.includes('mask') // TODO: remove this condition check after get meta type
        : false;

      const key = isMask ? 'masks' : 'images';

      return {
        ...acc,
        [key]: [...(acc[key] || []), image],
      };
    },
    { masks: [], images: [] } as { masks: ToothImage[]; images: ToothImage[] },
  );

  return masksAndImages;
};
