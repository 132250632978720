// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SettingsBar-module__container--wyzCfMFD{width:332px;display:flex;flex-direction:column;grid-gap:16px}.SettingsBar-module__notSigned--YmSDleuB{color:var(--red)}.SettingsBar-module__checkboxContainer--sEy8Cqf7{display:flex;flex-direction:column;grid-gap:12px}\n\n.SettingsBar-module__nestedCheckbox--Glz\\+K2Rc{margin-left:24px}\n\n[dir=\"rtl\"] .SettingsBar-module__nestedCheckbox--Glz\\+K2Rc{margin-left:0;margin-right:24px}.SettingsBar-module__status--m\\+gtScz5{display:flex;flex-direction:column;gap:4px}", "",{"version":3,"sources":["webpack://./src/pages/PreviewReport/ui/SettingsBar/SettingsBar.module.scss"],"names":[],"mappings":"AAAA,yCACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CAGF,yCACE,gBAAA,CAGF,iDACE,YAAA,CACA,qBAAA,CACA,aAAA;;AAGF,+CACE,gBAAA;;AADF,2DACE,aAAA,CAAA,iBAAA,CAGF,uCACE,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".container {\n  width: 332px;\n  display: flex;\n  flex-direction: column;\n  grid-gap: 16px;\n}\n\n.notSigned {\n  color: var(--red);\n}\n\n.checkboxContainer {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 12px;\n}\n\n.nestedCheckbox {\n  margin-left: 24px;\n}\n\n.status {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SettingsBar-module__container--wyzCfMFD",
	"notSigned": "SettingsBar-module__notSigned--YmSDleuB",
	"checkboxContainer": "SettingsBar-module__checkboxContainer--sEy8Cqf7",
	"nestedCheckbox": "SettingsBar-module__nestedCheckbox--Glz+K2Rc",
	"status": "SettingsBar-module__status--m+gtScz5"
};
export default ___CSS_LOADER_EXPORT___;
