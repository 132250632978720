import React, { FC, memo } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';

import { SliceInterface, useGetSliceGroups } from '@/entities/assets';

import { SliceGroupSection } from '../SliceGroupSection/SliceGroupSection';
import { Group } from '../../config/types';

import styles from './SliceGroup.module.scss';

type SliceGroupProps = {
  selectedSlices: SliceInterface[];
  onDragSlice?: (src: string) => void;
  toggleSelected?: (src: string) => void;
  className?: string;
};

export const SliceGroup: FC<SliceGroupProps> = memo((props) => {
  const { className, selectedSlices, toggleSelected, onDragSlice } = props;

  const { toothID } = useParams();

  const groups = useGetSliceGroups(toothID);

  return (
    <div className={cn(styles.container, className)}>
      {groups.length &&
        groups.map((group: Group) => (
          <SliceGroupSection
            key={group.type}
            group={group}
            selectedSlices={selectedSlices}
            onDragSlice={onDragSlice}
            toggleSelected={toggleSelected}
          />
        ))}
    </div>
  );
});
