/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Attribute, Created, Revision, Deleted } from '../model/dto_base';
import { StudyPermissions } from '../model/dto_study_permissions';

export const protobufPackage = 'com.diagnocat.model';

export enum StudyType {
  StudyType_InvalidValue = 0,
  StudyType_CBCT = 1,
  StudyType_IntraoralXRay = 2,
  StudyType_PanoramicXRay = 3,
  StudyType_IOS_STL = 4,
  StudyType_DentalPhoto = 5,
  UNRECOGNIZED = -1,
}

export function studyTypeFromJSON(object: any): StudyType {
  switch (object) {
    case 0:
    case 'StudyType_InvalidValue':
      return StudyType.StudyType_InvalidValue;
    case 1:
    case 'StudyType_CBCT':
      return StudyType.StudyType_CBCT;
    case 2:
    case 'StudyType_IntraoralXRay':
      return StudyType.StudyType_IntraoralXRay;
    case 3:
    case 'StudyType_PanoramicXRay':
      return StudyType.StudyType_PanoramicXRay;
    case 4:
    case 'StudyType_IOS_STL':
      return StudyType.StudyType_IOS_STL;
    case 5:
    case 'StudyType_DentalPhoto':
      return StudyType.StudyType_DentalPhoto;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return StudyType.UNRECOGNIZED;
  }
}

export function studyTypeToJSON(object: StudyType): string {
  switch (object) {
    case StudyType.StudyType_InvalidValue:
      return 'StudyType_InvalidValue';
    case StudyType.StudyType_CBCT:
      return 'StudyType_CBCT';
    case StudyType.StudyType_IntraoralXRay:
      return 'StudyType_IntraoralXRay';
    case StudyType.StudyType_PanoramicXRay:
      return 'StudyType_PanoramicXRay';
    case StudyType.StudyType_IOS_STL:
      return 'StudyType_IOS_STL';
    case StudyType.StudyType_DentalPhoto:
      return 'StudyType_DentalPhoto';
    case StudyType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Study {
  ID: string;
  Name: string;
  Time: number;
  OrganizationID: string;
  PatientID: string;
  Type: StudyType;
  AssetID: string;
  Attributes: Attribute[];
  YourPermissions: StudyPermissions | undefined;
  Created: Created | undefined;
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
}

function createBaseStudy(): Study {
  return {
    ID: '',
    Name: '',
    Time: 0,
    OrganizationID: '',
    PatientID: '',
    Type: 0,
    AssetID: '',
    Attributes: [],
    YourPermissions: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const Study = {
  encode(message: Study, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== '') {
      writer.uint32(18).string(message.Name);
    }
    if (message.Time !== 0) {
      writer.uint32(24).int64(message.Time);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(34).string(message.OrganizationID);
    }
    if (message.PatientID !== '') {
      writer.uint32(42).string(message.PatientID);
    }
    if (message.Type !== 0) {
      writer.uint32(48).int32(message.Type);
    }
    if (message.AssetID !== '') {
      writer.uint32(58).string(message.AssetID);
    }
    for (const v of message.Attributes) {
      Attribute.encode(v!, writer.uint32(8002).fork()).ldelim();
    }
    if (message.YourPermissions !== undefined) {
      StudyPermissions.encode(
        message.YourPermissions,
        writer.uint32(80162).fork(),
      ).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Study {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStudy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Time = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.OrganizationID = reader.string();
          break;
        case 5:
          message.PatientID = reader.string();
          break;
        case 6:
          message.Type = reader.int32() as any;
          break;
        case 7:
          message.AssetID = reader.string();
          break;
        case 1000:
          message.Attributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        case 10020:
          message.YourPermissions = StudyPermissions.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Study {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Name: isSet(object.Name) ? String(object.Name) : '',
      Time: isSet(object.Time) ? Number(object.Time) : 0,
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      Type: isSet(object.Type) ? studyTypeFromJSON(object.Type) : 0,
      AssetID: isSet(object.AssetID) ? String(object.AssetID) : '',
      Attributes: Array.isArray(object?.Attributes)
        ? object.Attributes.map((e: any) => Attribute.fromJSON(e))
        : [],
      YourPermissions: isSet(object.YourPermissions)
        ? StudyPermissions.fromJSON(object.YourPermissions)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: Study): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Time !== undefined && (obj.Time = Math.round(message.Time));
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.Type !== undefined && (obj.Type = studyTypeToJSON(message.Type));
    message.AssetID !== undefined && (obj.AssetID = message.AssetID);
    if (message.Attributes) {
      obj.Attributes = message.Attributes.map((e) =>
        e ? Attribute.toJSON(e) : undefined,
      );
    } else {
      obj.Attributes = [];
    }
    message.YourPermissions !== undefined &&
      (obj.YourPermissions = message.YourPermissions
        ? StudyPermissions.toJSON(message.YourPermissions)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Study>, I>>(object: I): Study {
    const message = createBaseStudy();
    message.ID = object.ID ?? '';
    message.Name = object.Name ?? '';
    message.Time = object.Time ?? 0;
    message.OrganizationID = object.OrganizationID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.Type = object.Type ?? 0;
    message.AssetID = object.AssetID ?? '';
    message.Attributes =
      object.Attributes?.map((e) => Attribute.fromPartial(e)) || [];
    message.YourPermissions =
      object.YourPermissions !== undefined && object.YourPermissions !== null
        ? StudyPermissions.fromPartial(object.YourPermissions)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
