import { useCallback, useState, ChangeEvent } from 'react';

export type PreviewPrintType = 'black-and-white' | 'colored';

export type PreviewSettings = {
  printType: PreviewPrintType;
  showToothChart: boolean;
  showUpperJaw: boolean;
  showLowerJaw: boolean;
  showProbability: boolean;
  showConditions: boolean;
  showSlices: boolean;
  inverted: boolean;
};

export type PreviewSettingsHandlers = {
  toggleToothChart: () => void;
  toggleUpperJaw: () => void;
  toggleLowerJaw: () => void;
  toggleProbability: () => void;
  toggleConditions: () => void;
  toggleSlices: () => void;
  toggleInvert: () => void;
  changePrintType: (e: ChangeEvent<HTMLInputElement>) => void;
};

type UsePreviewSettings = {
  settings: PreviewSettings;
  handlers: PreviewSettingsHandlers;
};

export const usePreviewSettings = (): UsePreviewSettings => {
  const [showToothChart, setShowToothChart] = useState(true);
  const [showUpperJaw, setShowUpperJaw] = useState(true);
  const [showLowerJaw, setShowLowerJaw] = useState(true);
  const [showProbability, setShowProbability] = useState(true);
  const [showConditions, setShowConditions] = useState(true);
  const [showSlices, setShowSlices] = useState(true);
  const [inverted, setInverted] = useState(false);
  const [printType, setPrintType] = useState<PreviewPrintType>('colored');

  const toggleToothChart = useCallback(() => {
    const toggledShowToothChart = !showToothChart;
    setShowToothChart(toggledShowToothChart);

    if (toggledShowToothChart && !showUpperJaw) {
      setShowUpperJaw(true);
    } else {
      setShowUpperJaw(false);
    }

    if (toggledShowToothChart && !showLowerJaw) {
      setShowLowerJaw(true);
    } else {
      setShowLowerJaw(false);
    }
  }, [showToothChart, showUpperJaw, showLowerJaw]);

  const toggleUpperJaw = useCallback(() => {
    const toggledShowUpperJaw = !showUpperJaw;
    setShowUpperJaw(toggledShowUpperJaw);

    if (toggledShowUpperJaw && !showToothChart) {
      setShowToothChart(true);
    }

    if (!toggledShowUpperJaw && !showLowerJaw && showToothChart) {
      setShowToothChart(false);
    }
  }, [showToothChart, showUpperJaw, showLowerJaw]);

  const toggleLowerJaw = useCallback(() => {
    const toggledShowLowerJaw = !showLowerJaw;
    setShowLowerJaw(toggledShowLowerJaw);

    if (toggledShowLowerJaw && !showToothChart) {
      setShowToothChart(true);
    }

    if (!toggledShowLowerJaw && !showUpperJaw && showToothChart) {
      setShowToothChart(false);
    }
  }, [showToothChart, showUpperJaw, showLowerJaw]);

  const toggleProbability = useCallback(() => {
    setShowProbability(!showProbability);
  }, [showProbability]);

  const toggleConditions = useCallback(() => {
    setShowConditions(!showConditions);
  }, [showConditions]);

  const toggleSlices = useCallback(() => {
    setShowSlices(!showSlices);
  }, [showSlices]);

  const toggleInvert = useCallback(() => {
    setInverted(!inverted);
  }, [inverted]);

  const changePrintType = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const type = e.currentTarget.value as PreviewPrintType;

    setPrintType(type);
  }, []);

  return {
    settings: {
      printType,
      inverted,
      showToothChart,
      showUpperJaw,
      showLowerJaw,
      showProbability,
      showConditions,
      showSlices,
    },
    handlers: {
      changePrintType,
      toggleToothChart,
      toggleUpperJaw,
      toggleLowerJaw,
      toggleProbability,
      toggleConditions,
      toggleSlices,
      toggleInvert,
    },
  };
};
