import { ToothImage } from '../config/slices.types';

import { orderingMasksAndImages } from './orderingMasksAndImages';
import { separateMasksAndImages } from './separateMasksAndImages';
import { sortSpecialGroupImages } from './sortSpecialGroupImages';

export const updateOneSliceGroupByMasks = (groupImages: ToothImage[]) => {
  const separatedMasksAndImages = separateMasksAndImages(groupImages);

  const sortedByPriorities = {
    masks: separatedMasksAndImages.masks,
    images: sortSpecialGroupImages(separatedMasksAndImages.images),
  };

  const orderedMasksAndImages = orderingMasksAndImages(sortedByPriorities);

  return orderedMasksAndImages;
};
