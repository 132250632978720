import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { sort } from 'ramda';

import { Modal, Input, Tabs } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import {
  ConditionToggle,
  ConditionToggleList,
  ConditionChildGroup,
  ConditionToggleContainer,
  useTransformCondition,
  ConditionInterface,
  filterConditionInterfacesBySearch,
  sortConditionInterfaceByProbability,
  sortConditionInterfaceAlphabetically,
  groupConditionInterfaceByGroup,
  conditionModel,
  ConditionListByCategory,
  useConditionGroupsList,
} from '@/entities/condition';
import { modalModel, ModalID } from '@/entities/modal';
import { useUpdateConditionDecision } from '@/features/conditionControl';

import styles from './AddConditionModal.module.scss';

enum TabValue {
  Probability = 'Probability',
  Category = 'Category',
  Alphabetically = 'Alphabetically',
}

const useAddCondition = (conditionItems: ConditionInterface[]) => {
  const [search, setSearch] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const filteredConditionItems =
    search !== ''
      ? filterConditionInterfacesBySearch(search, conditionItems)
      : conditionItems;

  const conditionItemsSortedByAlphabet = sort(
    sortConditionInterfaceAlphabetically,
    filteredConditionItems,
  );

  const conditionItemsSortedByProbability = sort(
    sortConditionInterfaceByProbability,
    filteredConditionItems,
  );

  const conditionItemsByGroups = groupConditionInterfaceByGroup(
    filteredConditionItems,
  );

  return {
    search,
    handleSearch,
    conditionItemsSortedByProbability,
    conditionItemsByGroups,
    conditionItemsSortedByAlphabet,
  };
};

export const AddConditionModal: FC = () => {
  const dispatch = useAppDispatch();

  const {
    visible,
    data: { toothID },
  } = useAppSelector((state) => state.modal.AddCondition);
  const conditions = useAppSelector(
    conditionModel.selectors.selectByToothID(toothID),
  );

  const conditionItems = useTransformCondition(conditions);

  const {
    search,
    handleSearch,
    conditionItemsSortedByProbability,
    conditionItemsByGroups,
    conditionItemsSortedByAlphabet,
  } = useAddCondition(conditionItems);

  const { isOpenGroupsList } = useConditionGroupsList(conditionItemsByGroups);

  const { updateConditionDecision, isLoading } =
    useUpdateConditionDecision(toothID);

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.AddCondition));
  }, [dispatch]);

  return (
    <Modal
      title={
        <FormattedMessage
          id="addConditionModal.title"
          defaultMessage="Add conditions"
        />
      }
      isOpen={visible}
      onClose={handleClose}
      className={styles.modal}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      hideFooter
      borderless
    >
      <Tabs.Root defaultValue={TabValue.Probability}>
        <Tabs.List size="small">
          <Tabs.Trigger value={TabValue.Probability} size="small">
            <FormattedMessage
              id="addConditionModal.tab.probability"
              defaultMessage="Probability"
            />
          </Tabs.Trigger>

          <Tabs.Trigger value={TabValue.Category} size="small">
            <FormattedMessage
              id="addConditionModal.tab.category"
              defaultMessage="Category"
            />
          </Tabs.Trigger>

          <Tabs.Trigger value={TabValue.Alphabetically} size="small">
            <FormattedMessage
              id="addConditionModal.tab.alphabetically"
              defaultMessage="Alphabetically"
            />
          </Tabs.Trigger>
        </Tabs.List>

        <Input
          type="search"
          name="search-condition"
          value={search}
          onChange={handleSearch}
        />

        <Tabs.Content
          value={TabValue.Probability}
          className={styles.tabContent}
        >
          <ConditionToggleList>
            {conditionItemsSortedByProbability.map((data) =>
              data.childConditionInterfaces ? (
                <ConditionToggleContainer key={data.code}>
                  <ConditionToggle
                    data={data}
                    onChange={updateConditionDecision}
                    disabled={isLoading}
                  />

                  <ConditionChildGroup
                    className={styles.childGroup}
                    data={data.childConditionInterfaces}
                    onChange={updateConditionDecision}
                    disabled={isLoading || !data?.isChecked}
                  />
                </ConditionToggleContainer>
              ) : (
                <ConditionToggle
                  key={data.code}
                  data={data}
                  onChange={updateConditionDecision}
                  disabled={isLoading}
                />
              ),
            )}
          </ConditionToggleList>
        </Tabs.Content>

        <Tabs.Content value={TabValue.Category} className={styles.tabContent}>
          {isOpenGroupsList && (
            <ConditionListByCategory
              conditionItemsByGroups={conditionItemsByGroups}
              updateConditionDecision={updateConditionDecision}
              disabled={isLoading}
            />
          )}
        </Tabs.Content>

        <Tabs.Content
          value={TabValue.Alphabetically}
          className={styles.tabContent}
        >
          <ConditionToggleList>
            {conditionItemsSortedByAlphabet.map((data) =>
              data.childConditionInterfaces ? (
                <ConditionToggleContainer key={data.code}>
                  <ConditionToggle
                    data={data}
                    onChange={updateConditionDecision}
                    disabled={isLoading}
                  />

                  <ConditionChildGroup
                    className={styles.childGroup}
                    data={data.childConditionInterfaces}
                    onChange={updateConditionDecision}
                    disabled={isLoading || !data?.isChecked}
                  />
                </ConditionToggleContainer>
              ) : (
                <ConditionToggle
                  key={data.code}
                  data={data}
                  onChange={updateConditionDecision}
                  disabled={isLoading}
                />
              ),
            )}
          </ConditionToggleList>
        </Tabs.Content>
      </Tabs.Root>
    </Modal>
  );
};
