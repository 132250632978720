import React, { FC } from 'react';
import cn from 'classnames';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Button, Input, toastCaller } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { authModel } from '@/entities/auth';
import { PATHS } from '@/shared/config';

import styles from './SignIn.module.scss';
import { SignInFormPayload, signInSchema } from './config/formConfig';

export const SignIn: FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const location = useLocation();
  const from = (location?.state as { from: Location })?.from;

  const { formatMessage } = useIntl();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<SignInFormPayload>({
    resolver: yupResolver(signInSchema),
  });

  const onSubmit: SubmitHandler<SignInFormPayload> = async (data) => {
    const { email, password } = data;

    try {
      const startLogInResponse = await dispatch(
        authModel.thunks.startSignIn({ Email: email }),
      ).unwrap();

      if (startLogInResponse.InProgress) {
        const verifyResponse = await dispatch(
          authModel.thunks.verify({ Value: password }),
        ).unwrap();

        if (verifyResponse?.AuthState?.IsSuccessful) {
          dispatch(authModel.actions.setIsAuthenticated(true));

          if (from) {
            navigate(from);
          } else {
            navigate(PATHS.patients);
          }
        }
      }
    } catch (e) {
      toastCaller({
        message: JSON.parse(e.message).text,
        type: 'error',
        heading: formatMessage({ id: 'global.error', defaultMessage: 'Error' }),
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2 className={cn(styles.header, 'h2')}>
          <FormattedMessage id="signIn.header" defaultMessage="Sign in" />
        </h2>
        <form id="signinform" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="email"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                className={styles.email}
                required
                ref={ref}
                value={value}
                name={name}
                inputMode="email"
                type="email"
                onBlur={onBlur}
                onChange={onChange}
                label="E-mail"
                error={error?.message}
                autoComplete="email"
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({
              field: { ref, value, name, onBlur, onChange },
              fieldState: { error },
            }) => (
              <Input
                className={styles.password}
                required
                ref={ref}
                value={value}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                type="password"
                label="Password"
                error={error?.message}
                autoComplete="current-password"
              />
            )}
          />
          <div className={styles.formFooter}>
            <Button
              variant="tertiary"
              disabled={isSubmitting}
              onClick={() => navigate(PATHS.resetPassword)}
            >
              <FormattedMessage
                id="signIn.resetPassword"
                defaultMessage="Reset password"
              />
            </Button>
            <Button type="submit" loading={isSubmitting}>
              <FormattedMessage id="signIn.submit" defaultMessage="Sign in" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
