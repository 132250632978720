// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreviewReport-module__content--jsCSMJWI{padding:0 24px}@media print{.PreviewReport-module__content--jsCSMJWI{padding:0}}.PreviewReport-module__nav--joJ\\+mYe\\+{display:flex}.PreviewReport-module__backLink--WC-qP2Z6{display:inline-flex;align-items:center;gap:4px;color:var(--purpletext2)}.PreviewReport-module__backLinkIcon--XSiDlEBx{color:var(--purpledec);rotate:90deg}@media print{.PreviewReport-module__nav--joJ\\+mYe\\+,.PreviewReport-module__header--MJm2Kl0n,.PreviewReport-module__sider--vaXTwKoU{display:none}}", "",{"version":3,"sources":["webpack://./src/pages/PreviewReport/PreviewReport.module.scss"],"names":[],"mappings":"AAAA,yCACE,cAAA,CAEA,aAHF,yCAII,SAAA,CAAA,CAIJ,uCACE,YAAA,CAGF,0CACE,mBAAA,CACA,kBAAA,CACA,OAAA,CACA,wBAAA,CAGF,8CACE,sBAAA,CACA,YAAA,CAGF,aACE,sHAGE,YAAA,CAAA","sourcesContent":[".content {\n  padding: 0 24px;\n\n  @media print {\n    padding: 0;\n  }\n}\n\n.nav {\n  display: flex;\n}\n\n.backLink {\n  display: inline-flex;\n  align-items: center;\n  gap: 4px;\n  color: var(--purpletext2);\n}\n\n.backLinkIcon {\n  color: var(--purpledec);\n  rotate: 90deg;\n}\n\n@media print {\n  .nav,\n  .header,\n  .sider {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "PreviewReport-module__content--jsCSMJWI",
	"nav": "PreviewReport-module__nav--joJ+mYe+",
	"backLink": "PreviewReport-module__backLink--WC-qP2Z6",
	"backLinkIcon": "PreviewReport-module__backLinkIcon--XSiDlEBx",
	"header": "PreviewReport-module__header--MJm2Kl0n",
	"sider": "PreviewReport-module__sider--vaXTwKoU"
};
export default ___CSS_LOADER_EXPORT___;
