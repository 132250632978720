// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreviewHeader-module__containter--YdE\\+MhBM{display:flex;justify-content:space-between;margin-bottom:16px}.PreviewHeader-module__reportInfo--EfQ5THzb{display:flex;flex-direction:column;gap:8px}.PreviewHeader-module__reportInfoDetails--gDAI1h4m{display:flex;flex-direction:column;gap:4px}.PreviewHeader-module__clinicInfo--R5vq-TJm{display:flex}", "",{"version":3,"sources":["webpack://./src/pages/PreviewReport/ui/PreviewHeader/PreviewHeader.module.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,4CACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,mDACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,4CACE,YAAA","sourcesContent":[".containter {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 16px;\n}\n\n.reportInfo {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.reportInfoDetails {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.clinicInfo {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containter": "PreviewHeader-module__containter--YdE+MhBM",
	"reportInfo": "PreviewHeader-module__reportInfo--EfQ5THzb",
	"reportInfoDetails": "PreviewHeader-module__reportInfoDetails--gDAI1h4m",
	"clinicInfo": "PreviewHeader-module__clinicInfo--R5vq-TJm"
};
export default ___CSS_LOADER_EXPORT___;
