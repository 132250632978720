/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export interface CBCTSeriesGeometryData {
  /** subvolume in original DICOM coordinates which was processed (cropped out parts are uninformative) */
  WorkingCrop: BBox | undefined;
  /** axis sizes in voxels (shape of original DICOM file without cropped parts) */
  Shape: IntTuple3D | undefined;
  /** voxel size in mm */
  Scale: FloatTuple3D | undefined;
}

export interface FloatPoint2D {
  X: number;
  Y: number;
}

export interface IntPoint3D {
  Z: number;
  Y: number;
  X: number;
}

export interface FloatPoint3D {
  Z: number;
  Y: number;
  X: number;
}

export interface OrderedLine2D {
  Order: number;
  Start: FloatPoint2D | undefined;
  End: FloatPoint2D | undefined;
}

export interface IntTuple3D {
  Z: number;
  Y: number;
  X: number;
}

export interface FloatTuple3D {
  Z: number;
  Y: number;
  X: number;
}

/** 0 <= Min <= Max <= Size */
export interface AxisSlice {
  Min: number;
  Max: number;
  Size: number;
}

/**
 * BBox describes a rectangular subportion of 2D or 3D image.
 * 3D BBox will have X, Y, Z defined. 2D BBox will have X, Y defined.
 */
export interface BBox {
  Z: AxisSlice | undefined;
  Y: AxisSlice | undefined;
  X: AxisSlice | undefined;
}

function createBaseCBCTSeriesGeometryData(): CBCTSeriesGeometryData {
  return { WorkingCrop: undefined, Shape: undefined, Scale: undefined };
}

export const CBCTSeriesGeometryData = {
  encode(
    message: CBCTSeriesGeometryData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.WorkingCrop !== undefined) {
      BBox.encode(message.WorkingCrop, writer.uint32(10).fork()).ldelim();
    }
    if (message.Shape !== undefined) {
      IntTuple3D.encode(message.Shape, writer.uint32(18).fork()).ldelim();
    }
    if (message.Scale !== undefined) {
      FloatTuple3D.encode(message.Scale, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CBCTSeriesGeometryData {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCBCTSeriesGeometryData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.WorkingCrop = BBox.decode(reader, reader.uint32());
          break;
        case 2:
          message.Shape = IntTuple3D.decode(reader, reader.uint32());
          break;
        case 3:
          message.Scale = FloatTuple3D.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CBCTSeriesGeometryData {
    return {
      WorkingCrop: isSet(object.WorkingCrop)
        ? BBox.fromJSON(object.WorkingCrop)
        : undefined,
      Shape: isSet(object.Shape)
        ? IntTuple3D.fromJSON(object.Shape)
        : undefined,
      Scale: isSet(object.Scale)
        ? FloatTuple3D.fromJSON(object.Scale)
        : undefined,
    };
  },

  toJSON(message: CBCTSeriesGeometryData): unknown {
    const obj: any = {};
    message.WorkingCrop !== undefined &&
      (obj.WorkingCrop = message.WorkingCrop
        ? BBox.toJSON(message.WorkingCrop)
        : undefined);
    message.Shape !== undefined &&
      (obj.Shape = message.Shape
        ? IntTuple3D.toJSON(message.Shape)
        : undefined);
    message.Scale !== undefined &&
      (obj.Scale = message.Scale
        ? FloatTuple3D.toJSON(message.Scale)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CBCTSeriesGeometryData>, I>>(
    object: I,
  ): CBCTSeriesGeometryData {
    const message = createBaseCBCTSeriesGeometryData();
    message.WorkingCrop =
      object.WorkingCrop !== undefined && object.WorkingCrop !== null
        ? BBox.fromPartial(object.WorkingCrop)
        : undefined;
    message.Shape =
      object.Shape !== undefined && object.Shape !== null
        ? IntTuple3D.fromPartial(object.Shape)
        : undefined;
    message.Scale =
      object.Scale !== undefined && object.Scale !== null
        ? FloatTuple3D.fromPartial(object.Scale)
        : undefined;
    return message;
  },
};

function createBaseFloatPoint2D(): FloatPoint2D {
  return { X: 0, Y: 0 };
}

export const FloatPoint2D = {
  encode(
    message: FloatPoint2D,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.X !== 0) {
      writer.uint32(9).double(message.X);
    }
    if (message.Y !== 0) {
      writer.uint32(17).double(message.Y);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FloatPoint2D {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFloatPoint2D();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.X = reader.double();
          break;
        case 2:
          message.Y = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FloatPoint2D {
    return {
      X: isSet(object.X) ? Number(object.X) : 0,
      Y: isSet(object.Y) ? Number(object.Y) : 0,
    };
  },

  toJSON(message: FloatPoint2D): unknown {
    const obj: any = {};
    message.X !== undefined && (obj.X = message.X);
    message.Y !== undefined && (obj.Y = message.Y);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<FloatPoint2D>, I>>(
    object: I,
  ): FloatPoint2D {
    const message = createBaseFloatPoint2D();
    message.X = object.X ?? 0;
    message.Y = object.Y ?? 0;
    return message;
  },
};

function createBaseIntPoint3D(): IntPoint3D {
  return { Z: 0, Y: 0, X: 0 };
}

export const IntPoint3D = {
  encode(
    message: IntPoint3D,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Z !== 0) {
      writer.uint32(8).int64(message.Z);
    }
    if (message.Y !== 0) {
      writer.uint32(16).int64(message.Y);
    }
    if (message.X !== 0) {
      writer.uint32(24).int64(message.X);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IntPoint3D {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIntPoint3D();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Z = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.Y = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.X = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): IntPoint3D {
    return {
      Z: isSet(object.Z) ? Number(object.Z) : 0,
      Y: isSet(object.Y) ? Number(object.Y) : 0,
      X: isSet(object.X) ? Number(object.X) : 0,
    };
  },

  toJSON(message: IntPoint3D): unknown {
    const obj: any = {};
    message.Z !== undefined && (obj.Z = Math.round(message.Z));
    message.Y !== undefined && (obj.Y = Math.round(message.Y));
    message.X !== undefined && (obj.X = Math.round(message.X));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<IntPoint3D>, I>>(
    object: I,
  ): IntPoint3D {
    const message = createBaseIntPoint3D();
    message.Z = object.Z ?? 0;
    message.Y = object.Y ?? 0;
    message.X = object.X ?? 0;
    return message;
  },
};

function createBaseFloatPoint3D(): FloatPoint3D {
  return { Z: 0, Y: 0, X: 0 };
}

export const FloatPoint3D = {
  encode(
    message: FloatPoint3D,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Z !== 0) {
      writer.uint32(9).double(message.Z);
    }
    if (message.Y !== 0) {
      writer.uint32(17).double(message.Y);
    }
    if (message.X !== 0) {
      writer.uint32(25).double(message.X);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FloatPoint3D {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFloatPoint3D();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Z = reader.double();
          break;
        case 2:
          message.Y = reader.double();
          break;
        case 3:
          message.X = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FloatPoint3D {
    return {
      Z: isSet(object.Z) ? Number(object.Z) : 0,
      Y: isSet(object.Y) ? Number(object.Y) : 0,
      X: isSet(object.X) ? Number(object.X) : 0,
    };
  },

  toJSON(message: FloatPoint3D): unknown {
    const obj: any = {};
    message.Z !== undefined && (obj.Z = message.Z);
    message.Y !== undefined && (obj.Y = message.Y);
    message.X !== undefined && (obj.X = message.X);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<FloatPoint3D>, I>>(
    object: I,
  ): FloatPoint3D {
    const message = createBaseFloatPoint3D();
    message.Z = object.Z ?? 0;
    message.Y = object.Y ?? 0;
    message.X = object.X ?? 0;
    return message;
  },
};

function createBaseOrderedLine2D(): OrderedLine2D {
  return { Order: 0, Start: undefined, End: undefined };
}

export const OrderedLine2D = {
  encode(
    message: OrderedLine2D,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Order !== 0) {
      writer.uint32(8).uint32(message.Order);
    }
    if (message.Start !== undefined) {
      FloatPoint2D.encode(message.Start, writer.uint32(18).fork()).ldelim();
    }
    if (message.End !== undefined) {
      FloatPoint2D.encode(message.End, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderedLine2D {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderedLine2D();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Order = reader.uint32();
          break;
        case 2:
          message.Start = FloatPoint2D.decode(reader, reader.uint32());
          break;
        case 3:
          message.End = FloatPoint2D.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrderedLine2D {
    return {
      Order: isSet(object.Order) ? Number(object.Order) : 0,
      Start: isSet(object.Start)
        ? FloatPoint2D.fromJSON(object.Start)
        : undefined,
      End: isSet(object.End) ? FloatPoint2D.fromJSON(object.End) : undefined,
    };
  },

  toJSON(message: OrderedLine2D): unknown {
    const obj: any = {};
    message.Order !== undefined && (obj.Order = Math.round(message.Order));
    message.Start !== undefined &&
      (obj.Start = message.Start
        ? FloatPoint2D.toJSON(message.Start)
        : undefined);
    message.End !== undefined &&
      (obj.End = message.End ? FloatPoint2D.toJSON(message.End) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrderedLine2D>, I>>(
    object: I,
  ): OrderedLine2D {
    const message = createBaseOrderedLine2D();
    message.Order = object.Order ?? 0;
    message.Start =
      object.Start !== undefined && object.Start !== null
        ? FloatPoint2D.fromPartial(object.Start)
        : undefined;
    message.End =
      object.End !== undefined && object.End !== null
        ? FloatPoint2D.fromPartial(object.End)
        : undefined;
    return message;
  },
};

function createBaseIntTuple3D(): IntTuple3D {
  return { Z: 0, Y: 0, X: 0 };
}

export const IntTuple3D = {
  encode(
    message: IntTuple3D,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Z !== 0) {
      writer.uint32(8).int64(message.Z);
    }
    if (message.Y !== 0) {
      writer.uint32(16).int64(message.Y);
    }
    if (message.X !== 0) {
      writer.uint32(24).int64(message.X);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IntTuple3D {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIntTuple3D();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Z = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.Y = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.X = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): IntTuple3D {
    return {
      Z: isSet(object.Z) ? Number(object.Z) : 0,
      Y: isSet(object.Y) ? Number(object.Y) : 0,
      X: isSet(object.X) ? Number(object.X) : 0,
    };
  },

  toJSON(message: IntTuple3D): unknown {
    const obj: any = {};
    message.Z !== undefined && (obj.Z = Math.round(message.Z));
    message.Y !== undefined && (obj.Y = Math.round(message.Y));
    message.X !== undefined && (obj.X = Math.round(message.X));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<IntTuple3D>, I>>(
    object: I,
  ): IntTuple3D {
    const message = createBaseIntTuple3D();
    message.Z = object.Z ?? 0;
    message.Y = object.Y ?? 0;
    message.X = object.X ?? 0;
    return message;
  },
};

function createBaseFloatTuple3D(): FloatTuple3D {
  return { Z: 0, Y: 0, X: 0 };
}

export const FloatTuple3D = {
  encode(
    message: FloatTuple3D,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Z !== 0) {
      writer.uint32(9).double(message.Z);
    }
    if (message.Y !== 0) {
      writer.uint32(17).double(message.Y);
    }
    if (message.X !== 0) {
      writer.uint32(25).double(message.X);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FloatTuple3D {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFloatTuple3D();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Z = reader.double();
          break;
        case 2:
          message.Y = reader.double();
          break;
        case 3:
          message.X = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): FloatTuple3D {
    return {
      Z: isSet(object.Z) ? Number(object.Z) : 0,
      Y: isSet(object.Y) ? Number(object.Y) : 0,
      X: isSet(object.X) ? Number(object.X) : 0,
    };
  },

  toJSON(message: FloatTuple3D): unknown {
    const obj: any = {};
    message.Z !== undefined && (obj.Z = message.Z);
    message.Y !== undefined && (obj.Y = message.Y);
    message.X !== undefined && (obj.X = message.X);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<FloatTuple3D>, I>>(
    object: I,
  ): FloatTuple3D {
    const message = createBaseFloatTuple3D();
    message.Z = object.Z ?? 0;
    message.Y = object.Y ?? 0;
    message.X = object.X ?? 0;
    return message;
  },
};

function createBaseAxisSlice(): AxisSlice {
  return { Min: 0, Max: 0, Size: 0 };
}

export const AxisSlice = {
  encode(
    message: AxisSlice,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Min !== 0) {
      writer.uint32(9).double(message.Min);
    }
    if (message.Max !== 0) {
      writer.uint32(17).double(message.Max);
    }
    if (message.Size !== 0) {
      writer.uint32(25).double(message.Size);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AxisSlice {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAxisSlice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Min = reader.double();
          break;
        case 2:
          message.Max = reader.double();
          break;
        case 3:
          message.Size = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AxisSlice {
    return {
      Min: isSet(object.Min) ? Number(object.Min) : 0,
      Max: isSet(object.Max) ? Number(object.Max) : 0,
      Size: isSet(object.Size) ? Number(object.Size) : 0,
    };
  },

  toJSON(message: AxisSlice): unknown {
    const obj: any = {};
    message.Min !== undefined && (obj.Min = message.Min);
    message.Max !== undefined && (obj.Max = message.Max);
    message.Size !== undefined && (obj.Size = message.Size);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AxisSlice>, I>>(
    object: I,
  ): AxisSlice {
    const message = createBaseAxisSlice();
    message.Min = object.Min ?? 0;
    message.Max = object.Max ?? 0;
    message.Size = object.Size ?? 0;
    return message;
  },
};

function createBaseBBox(): BBox {
  return { Z: undefined, Y: undefined, X: undefined };
}

export const BBox = {
  encode(message: BBox, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Z !== undefined) {
      AxisSlice.encode(message.Z, writer.uint32(10).fork()).ldelim();
    }
    if (message.Y !== undefined) {
      AxisSlice.encode(message.Y, writer.uint32(18).fork()).ldelim();
    }
    if (message.X !== undefined) {
      AxisSlice.encode(message.X, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BBox {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBBox();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Z = AxisSlice.decode(reader, reader.uint32());
          break;
        case 2:
          message.Y = AxisSlice.decode(reader, reader.uint32());
          break;
        case 3:
          message.X = AxisSlice.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BBox {
    return {
      Z: isSet(object.Z) ? AxisSlice.fromJSON(object.Z) : undefined,
      Y: isSet(object.Y) ? AxisSlice.fromJSON(object.Y) : undefined,
      X: isSet(object.X) ? AxisSlice.fromJSON(object.X) : undefined,
    };
  },

  toJSON(message: BBox): unknown {
    const obj: any = {};
    message.Z !== undefined &&
      (obj.Z = message.Z ? AxisSlice.toJSON(message.Z) : undefined);
    message.Y !== undefined &&
      (obj.Y = message.Y ? AxisSlice.toJSON(message.Y) : undefined);
    message.X !== undefined &&
      (obj.X = message.X ? AxisSlice.toJSON(message.X) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<BBox>, I>>(object: I): BBox {
    const message = createBaseBBox();
    message.Z =
      object.Z !== undefined && object.Z !== null
        ? AxisSlice.fromPartial(object.Z)
        : undefined;
    message.Y =
      object.Y !== undefined && object.Y !== null
        ? AxisSlice.fromPartial(object.Y)
        : undefined;
    message.X =
      object.X !== undefined && object.X !== null
        ? AxisSlice.fromPartial(object.X)
        : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
