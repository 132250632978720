import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

import { Button, Icon } from '@/shared/ui';
import { REFRESH_INTERVAL_IN_MILLISECONDS } from '@/widgets/RefreshPage/config/refreshPage.const';

import styles from './RefreshPage.module.scss';

type RefreshPageProps = {
  className?: string;
};

export const RefreshPage: FC<RefreshPageProps> = (props) => {
  const { className } = props;

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const isClientVersionEqualServerVersion = async () => {
    const url = window.location.origin;

    const response = await axios.get(url, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });

    const html = new DOMParser().parseFromString(response.data, 'text/html');

    const serverVersion = (
      html.getElementsByName('version')[0] as HTMLMetaElement
    )?.content;
    const clientVersion = (
      document.getElementsByName('version')[0] as HTMLMetaElement
    )?.content;

    const needToRefreshPage =
      serverVersion && clientVersion && serverVersion !== clientVersion;

    setIsVisible(needToRefreshPage);
  };

  useEffect(() => {
    const interval = setInterval(
      isClientVersionEqualServerVersion,
      REFRESH_INTERVAL_IN_MILLISECONDS,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!isVisible) return null;

  return (
    <div className={cn(styles.container, className)}>
      <Icon name="rotate" size={32} className={styles.refreshIcon} />
      <h4 className={cn(styles.title, 'h4')}>
        <FormattedMessage
          id="refreshPage.title"
          defaultMessage="Refresh the page"
        />
      </h4>
      <p className={cn(styles.body, 'p2')}>
        <FormattedMessage
          id="refreshPage.body"
          defaultMessage="Please refresh page to reload the view and clear browser cache. Reloading would allow us to correctly display all Diagnocat features"
        />
      </p>
      <Button
        variant="secondary"
        size="small"
        onClick={() => window.location.reload()}
      >
        <FormattedMessage
          id="refreshPage.refreshButton"
          defaultMessage="Refresh now"
        />
      </Button>
    </div>
  );
};
