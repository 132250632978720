import { FC } from 'react';
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import { ToothChartLegends } from '@/entities/tooth';
import { gender } from '@/shared/i18n';
import {
  getReportSignStatus,
  i18n as i18nReport,
  REPORT_CREATION_DATE_FORMAT,
} from '@/entities/reports';
import { TeethChart } from '@/features/toothChart';
import { PanoImagePreview } from '@/widgets/PanoImage/ui/PanoImage/PanoImage';
import { formatPatientDateOfBirth } from '@/entities/patient';
import { PreviewConclusion } from '@/widgets/Conclusion/ui/Conclusion/Conclusion';

import { PreviewSettings } from '../../hooks/usePreviewSettings';
import { PreviewToothCard } from '../PreviewToothCard/PreviewToothCard';
import { PreviewFooter } from '../PreviewFooter/PreviewFooter';
import { PreviewHeader } from '../PreviewHeader/PreviewHeader';
import { usePreviewDocument } from '../../hooks/usePreviewDocument';
import { previewFooterDisclaimer } from '../../config/i18n';

import styles from './PreviewDocument.module.scss';

type PreviewDocumentType = {
  settings: PreviewSettings;
};

export const PreviewDocument: FC<PreviewDocumentType> = (props) => {
  const {
    settings,
    settings: {
      // printType,
      inverted,
      showToothChart,
      showUpperJaw,
      showLowerJaw,
      showProbability,
      showConditions,
      showSlices,
    },
  } = props;

  const { formatMessage } = useIntl();

  const {
    teeth,
    patient,
    report,
    panoImage,
    panoImageUrl,
    ROI,
    patientFullName,
    isLoading,
  } = usePreviewDocument(settings);

  if (isLoading) {
    return null;
  }

  // Get view data after patient and report data is fetched
  const reportDate = format(report?.Created?.At, REPORT_CREATION_DATE_FORMAT);
  const reportTitle = formatMessage(i18nReport.reportType[report.Type]);
  const reportSignStatus = getReportSignStatus(report);

  const patientDateOfBirth = formatPatientDateOfBirth(patient);
  const patientGender = formatMessage(gender[patient?.Gender]);

  return (
    <div className={styles.document}>
      <PreviewHeader
        title={reportTitle}
        date={reportDate}
        patientName={patientFullName}
        patientGender={patientGender}
        patientDateOfBirth={patientDateOfBirth}
      />

      <div className={styles.panoContainer}>
        {panoImage && (
          <PanoImagePreview src={panoImageUrl} inverted={inverted} />
        )}
      </div>

      <p className={cn(styles.panoDisclaimer, 'p3')}>
        <FormattedMessage
          id="previewDocument.panoDisclaimer"
          defaultMessage="This report was generated with Diagnocat using Artificial Intelligence. The conditions and pathologies in this report can not be considered a medical diagnosis and must be interpreted by the attending dentist."
        />
      </p>

      {showToothChart && (
        <>
          <TeethChart
            teeth={teeth}
            ROIList={ROI}
            showUpperJaw={showUpperJaw}
            showLowerJaw={showLowerJaw}
          />

          <ToothChartLegends />
        </>
      )}

      <div className={styles.teethContainer}>
        {teeth.map((tooth) => (
          <PreviewToothCard
            key={tooth.ID}
            tooth={tooth}
            showProbability={showProbability}
            showConditions={showConditions}
            showSlices={showSlices}
            inverted={inverted}
          />
        ))}
      </div>

      <PreviewConclusion conclusion={report?.LegacyConclusion} />

      <PreviewFooter
        text={formatMessage(previewFooterDisclaimer[reportSignStatus])}
        date={reportDate}
      />
    </div>
  );
};
