import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';

import api from '@/shared/api/api';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { userModel } from '@/entities/user';
import { organizationModel } from '@/entities/organization';
import { usersModel } from '@/entities/users';
import { authModel } from '@/entities/auth';

export const useUserDataStream = () => {
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(authModel.selectors.selectIsAuth);

  const userDataStream = useRef<Subscription>(undefined);

  const openUserDataStream = () => {
    userDataStream.current = api.user.MyDataStream({}).subscribe({
      next: (data) => {
        if (data.HistoricalMe) {
          dispatch(userModel.actions.addUser(data.HistoricalMe));
          dispatch(usersModel.actions.addOne(data.HistoricalMe));
        }

        if (data.HistoricalOrganization) {
          dispatch(
            organizationModel.actions.setOne(data.HistoricalOrganization),
          );
        }

        if (data.HistoricalUser) {
          dispatch(usersModel.actions.addOne(data.HistoricalUser));
        }
      },
      error: (error) => {
        // eslint-disable-next-line no-console
        console.error(
          'MyDataStream:: error: ',
          error.message,
          error.code,
          error.type,
          error,
        );
      },
      complete: () => {
        // Do nothing
      },
    });
  };

  const closeUserDataStream = () => {
    if (userDataStream.current) {
      userDataStream.current.unsubscribe();
    }
  };

  useEffect(() => {
    if (isAuth) {
      openUserDataStream();
    }

    return () => {
      closeUserDataStream();
    };
  }, [isAuth]);
};
