// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportAccordion-module__container--QKYfCoTP{flex-direction:column;gap:12px}.ReportAccordion-module__reportRowContainer--zjRJJK55{display:flex;flex-direction:column;padding:4px;gap:4px}", "",{"version":3,"sources":["webpack://./src/widgets/StudyCard/ui/ReportAccordion/ReportAccordion.module.scss"],"names":[],"mappings":"AAAA,6CACE,qBAAA,CACA,QAAA,CAGF,sDACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,OAAA","sourcesContent":[".container {\n  flex-direction: column;\n  gap: 12px;\n}\n\n.reportRowContainer {\n  display: flex;\n  flex-direction: column;\n  padding: 4px;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReportAccordion-module__container--QKYfCoTP",
	"reportRowContainer": "ReportAccordion-module__reportRowContainer--zjRJJK55"
};
export default ___CSS_LOADER_EXPORT___;
