import { setOne, removeOne } from './model/organizationSlice';
import { specialityType } from './config/i18n';
import {
  createOrganization,
  setOrganizationName,
  setOrganizationType,
  setOrganizationContacts,
  setOrganizationSettings,
  deleteOrganization,
  setOrganizationUserJobTitle,
  addOrganizationUserSpecialization,
  removeOrganizationUserSpecialization,
  setCurrentOrganizationID,
} from './model/organizationSlice.thunks';
import {
  organizationSelectors,
  selectID,
  selectUsers,
  selectUsersByRoles,
  selectUserSpecializationsByID,
} from './model/organizationSlice.selectors';

export const i18n = {
  specialityType,
};

export const organizationModel = {
  selectors: {
    ...organizationSelectors,
    selectID,
    selectUsers,
    selectUsersByRoles,
    selectUserSpecializationsByID,
  },
  actions: {
    setOne,
    removeOne,
  },
  thunks: {
    createOrganization,
    setOrganizationName,
    setOrganizationType,
    setOrganizationContacts,
    setOrganizationSettings,
    deleteOrganization,
    setOrganizationUserJobTitle,
    addOrganizationUserSpecialization,
    removeOrganizationUserSpecialization,
    setCurrentOrganizationID,
  },
};
