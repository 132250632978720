import { studyModel } from '@/entities/study';
import { useAppDispatch } from '@/shared/hooks';

export const useRemoveStudy = () => {
  const dispatch = useAppDispatch();

  return async (studyID: string) => {
    const removeStudy = await dispatch(
      studyModel.thunks.deleteStudy({ StudyID: studyID }),
    );

    if (removeStudy.meta.requestStatus === 'fulfilled') {
      dispatch(studyModel.actions.removeOne(studyID));
    }
  };
};
