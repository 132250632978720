import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

// TODO: need add to correct rules and test
export const isConditionChecked = (condition: Condition) => {
  if (!condition) {
    return false;
  }

  const { Certainty } = condition;

  const isChecked =
    Certainty?.UserDecision === Decision.PositiveDecision ||
    (Certainty?.ModelDecision === Decision.PositiveDecision &&
      Certainty?.UserDecision !== Decision.NegativeDecision) ||
    (Certainty?.EngineDecision === Decision.PositiveDecision &&
      Certainty?.UserDecision !== Decision.NegativeDecision);

  return isChecked;
};
