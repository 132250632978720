import React, { FC } from 'react';
import cn from 'classnames';
import { generatePath, Link } from 'react-router-dom';

import { Avatar } from '@/shared/ui';
import { StudiesCounters, StudyCounterItem } from '@/entities/patient';
import { PATHS } from '@/shared/config';

import styles from './PatientListCard.module.scss';

type PatientListCardProps = {
  id: string;
  patientName: string;
  patientBirthDate: string;
  studies: StudyCounterItem[];
  previewImage?: string;
  className?: string;
  testID?: string;
};

export const PatientListCard: FC<PatientListCardProps> = (props) => {
  const {
    id,
    patientName,
    patientBirthDate,
    studies,
    previewImage,
    className,
    testID,
  } = props;

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <header className={styles.patientCardHeader}>
        <Link to={generatePath(PATHS.patientProfile, { patientID: id })}>
          <div className={styles.previewContainer}>
            {previewImage ? (
              <img
                className={styles.preview}
                src={previewImage}
                // TODO: Add translate for alt text
                alt="Patient preview"
              />
            ) : (
              <div className={styles.previewDefaultImage} />
            )}
          </div>
          {/* TODO: add logic and translate */}
          <div className={styles.conditionsLabel}>2 conditions</div>
          <div className={cn(styles.studyLabel, 'p3b')}>3D Model</div>
        </Link>
      </header>
      <div className={styles.patientInfo}>
        <Link to={generatePath(PATHS.patientProfile, { patientID: id })}>
          <h3 className={cn(styles.patientName, 'h3')}>{patientName}</h3>
          {/* TODO: add translate for ID */}
          <p className={cn(styles.patientID, 'p2')}>ID {id}</p>
          <p className={cn(styles.date, 'p2')}>{patientBirthDate}</p>
        </Link>
        <Avatar.Group className={styles.avatars}>
          <Avatar initials="JD" />
        </Avatar.Group>
        <StudiesCounters studies={studies} />
      </div>
    </div>
  );
};
