import React, { SyntheticEvent } from 'react';
import cn from 'classnames';

import { useMedia } from '@/shared/hooks';
import { Icon } from '@/shared/ui';

import { ConditionColor, ConditionInterface } from '../../config/types';

import styles from './ConditionButton.module.scss';

type ConditionButtonProps = Pick<
  ConditionInterface,
  'text' | 'color' | 'probability' | 'probabilityText'
> & {
  className?: string;
  rounded?: boolean;
  onRemove?: () => void;
};

const colorStyle: Record<ConditionColor, string> = {
  yellow: styles.colorYellow,
  red: styles.colorRed,
  purple: styles.colorPurple,
  white: styles.colorWhite,
};

const InternalConditionButton: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ConditionButtonProps
> = (props, ref) => {
  const {
    className,
    text,
    color,
    probability,
    probabilityText,
    rounded = false,
    onRemove,
    ...restConditionItemProps
  } = props;

  const { isMobile } = useMedia();

  const isRemoveVisible = typeof onRemove === 'function';

  const handleRemove = (event: SyntheticEvent) => {
    event.stopPropagation();
    onRemove();
  };

  return (
    <button
      ref={ref}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restConditionItemProps}
      type="button"
      className={cn(
        styles.container,
        isMobile ? 'p3' : 'p2',
        colorStyle[color],
        rounded && styles.rounded,
        isRemoveVisible && styles.removeVisible,
        className,
      )}
    >
      <span>{text}</span>

      {probability && <span>{probabilityText}</span>}

      {isRemoveVisible ? (
        <>
          <div className={styles.separator} />

          <Icon name="close" size={24} onClick={handleRemove} />
        </>
      ) : null}
    </button>
  );
};

export const ConditionButton = React.forwardRef<
  HTMLButtonElement,
  ConditionButtonProps
>(InternalConditionButton);
