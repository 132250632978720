import { useParams } from 'react-router';

import { useAppSelector } from '@/shared/hooks/store';
import {
  conditionModel,
  PURPLE_CODES,
  RED_CODES,
  shouldConditionItemBeShown,
  YELLOW_CODES,
} from '@/entities/condition';
import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { ToothChartLegendsTypes } from '../config/tooth.type';

const getToothConditions = (toothID: string, conditions: Condition[]) =>
  conditions.filter((condition) => condition?.Tooth?.ToothID === toothID);

export type ToothWithStatus = Tooth & {
  toothStatus: ToothChartLegendsTypes;
};

// TODO: to be refactored and covered by tests
export const useToothProps = (teeth: Tooth[]) => {
  const { patientID } = useParams();

  // TODO: get from API
  const showLowProbability = true;

  const allConditions = useAppSelector((state) =>
    conditionModel.selectors.selectByPatientID(state, patientID),
  );

  const filteredConditions = allConditions.filter((condition) =>
    shouldConditionItemBeShown(false)(condition),
  );

  const counters: Record<ToothChartLegendsTypes, number[]> = {
    lowProbability: [],
    unhealthy: [],
    treated: [],
    healthy: [],
    missing: [],
    all: [],
  };

  const teethConditions = teeth.reduce(
    (acc, tooth) => ({
      ...acc,
      [tooth.ID]: getToothConditions(tooth.ID, filteredConditions),
    }),
    {} as Record<string, Condition[]>,
  );

  const teethWithStatus = teeth.reduce((acc, item) => {
    const toothConditions = teethConditions[item.ID];

    counters.all.push(item.ISONumber);

    const toothWithStatus: ToothWithStatus = {
      ...item,
      toothStatus: 'healthy',
    };

    toothConditions.map((condition) => {
      if (toothWithStatus.IsMissing) {
        counters.missing.push(toothWithStatus.ISONumber);
        toothWithStatus.toothStatus = 'missing';

        return false;
      }

      const lowProbability = YELLOW_CODES.some(
        (code) => code === condition.Code,
      );

      if (
        lowProbability &&
        condition.Certainty.UserDecision === Decision.NoDecision &&
        condition.Certainty.ModelScore > 0.3 &&
        condition.Certainty.ModelScore < 0.5
      ) {
        toothWithStatus.toothStatus = 'lowProbability';

        return false;
      }

      const pathologic = RED_CODES.some(
        (pathologicCode) => pathologicCode === condition.Code,
      );

      if (pathologic && toothWithStatus.toothStatus !== 'lowProbability') {
        toothWithStatus.toothStatus = 'unhealthy';

        return false;
      }

      const treated = PURPLE_CODES.some(
        (treatedCode) => treatedCode === condition.Code,
      );

      if (
        treated &&
        toothWithStatus.toothStatus !== 'lowProbability' &&
        toothWithStatus.toothStatus !== 'unhealthy'
      ) {
        toothWithStatus.toothStatus = 'treated';

        return false;
      }

      return false;
    });

    if (toothWithStatus.toothStatus === 'lowProbability') {
      counters.lowProbability.push(toothWithStatus.ISONumber);
    } else if (toothWithStatus.toothStatus === 'unhealthy') {
      counters.unhealthy.push(toothWithStatus.ISONumber);
    } else if (toothWithStatus.toothStatus === 'treated') {
      counters.treated.push(toothWithStatus.ISONumber);
    } else if (toothWithStatus.toothStatus === 'healthy') {
      counters.healthy.push(toothWithStatus.ISONumber);
    }

    return { ...acc, [item.ID]: toothWithStatus };
  }, {} as Record<string, ToothWithStatus>);

  return { teethWithStatus, counters, showLowProbability };
};
