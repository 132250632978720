import { SlicesGroups, ToothImage } from '../config/slices.types';

export const getSmartSlicesGroups = (images: ToothImage[]) => {
  const smartSlicesImages = images.filter(
    (image) => image.type === 'condition-image',
  );

  const smartSlicesGroups = smartSlicesImages.reduce((acc, image) => {
    const meta = image?.meta
      ? (JSON.parse(image.meta) as {
          attribute_slug: string;
          pathology_component: string;
        })
      : null;

    if (!meta) {
      return acc;
    }

    const groupPrefix = `${meta.attribute_slug}-${meta.pathology_component}`; // should return "illness_slug-N" (N - count number located in pathology_component) (don't forget about '-' in this string)

    return { ...acc, [groupPrefix]: [...(acc[groupPrefix] || []), image] };
  }, {} as SlicesGroups);

  return smartSlicesGroups;
};
