/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export enum Decision {
  NoDecision = 0,
  NegativeDecision = 1,
  PositiveDecision = 2,
  UNRECOGNIZED = -1,
}

export function decisionFromJSON(object: any): Decision {
  switch (object) {
    case 0:
    case 'NoDecision':
      return Decision.NoDecision;
    case 1:
    case 'NegativeDecision':
      return Decision.NegativeDecision;
    case 2:
    case 'PositiveDecision':
      return Decision.PositiveDecision;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Decision.UNRECOGNIZED;
  }
}

export function decisionToJSON(object: Decision): string {
  switch (object) {
    case Decision.NoDecision:
      return 'NoDecision';
    case Decision.NegativeDecision:
      return 'NegativeDecision';
    case Decision.PositiveDecision:
      return 'PositiveDecision';
    case Decision.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum CBCTAnatomy {
  CBCT_Anatomy_InvalidValue = 0,
  CBCT_Anatomy_Airways = 1,
  CBCT_Anatomy_Cranium = 2,
  CBCT_Anatomy_SoftTissue = 3,
  CBCT_Anatomy_IncisiveCanal = 4,
  CBCT_Anatomy_Mandible = 5,
  CBCT_Anatomy_Maxilla = 6,
  CBCT_Anatomy_MandibularCanal = 7,
  CBCT_Anatomy_Sinuses = 8,
  CBCT_Anatomy_GingivaUpper = 9,
  CBCT_Anatomy_GingivaLower = 10,
  CBCT_Anatomy_OriginalIOSUpper = 11,
  CBCT_Anatomy_OriginalIOSLower = 12,
  /** CBCT_Anatomy_PeriapicalLesions - deprecated? */
  CBCT_Anatomy_PeriapicalLesions = 13,
  /** CBCT_Anatomy_ToothPulps - deprecated? */
  CBCT_Anatomy_ToothPulps = 14,
  UNRECOGNIZED = -1,
}

export function cBCTAnatomyFromJSON(object: any): CBCTAnatomy {
  switch (object) {
    case 0:
    case 'CBCT_Anatomy_InvalidValue':
      return CBCTAnatomy.CBCT_Anatomy_InvalidValue;
    case 1:
    case 'CBCT_Anatomy_Airways':
      return CBCTAnatomy.CBCT_Anatomy_Airways;
    case 2:
    case 'CBCT_Anatomy_Cranium':
      return CBCTAnatomy.CBCT_Anatomy_Cranium;
    case 3:
    case 'CBCT_Anatomy_SoftTissue':
      return CBCTAnatomy.CBCT_Anatomy_SoftTissue;
    case 4:
    case 'CBCT_Anatomy_IncisiveCanal':
      return CBCTAnatomy.CBCT_Anatomy_IncisiveCanal;
    case 5:
    case 'CBCT_Anatomy_Mandible':
      return CBCTAnatomy.CBCT_Anatomy_Mandible;
    case 6:
    case 'CBCT_Anatomy_Maxilla':
      return CBCTAnatomy.CBCT_Anatomy_Maxilla;
    case 7:
    case 'CBCT_Anatomy_MandibularCanal':
      return CBCTAnatomy.CBCT_Anatomy_MandibularCanal;
    case 8:
    case 'CBCT_Anatomy_Sinuses':
      return CBCTAnatomy.CBCT_Anatomy_Sinuses;
    case 9:
    case 'CBCT_Anatomy_GingivaUpper':
      return CBCTAnatomy.CBCT_Anatomy_GingivaUpper;
    case 10:
    case 'CBCT_Anatomy_GingivaLower':
      return CBCTAnatomy.CBCT_Anatomy_GingivaLower;
    case 11:
    case 'CBCT_Anatomy_OriginalIOSUpper':
      return CBCTAnatomy.CBCT_Anatomy_OriginalIOSUpper;
    case 12:
    case 'CBCT_Anatomy_OriginalIOSLower':
      return CBCTAnatomy.CBCT_Anatomy_OriginalIOSLower;
    case 13:
    case 'CBCT_Anatomy_PeriapicalLesions':
      return CBCTAnatomy.CBCT_Anatomy_PeriapicalLesions;
    case 14:
    case 'CBCT_Anatomy_ToothPulps':
      return CBCTAnatomy.CBCT_Anatomy_ToothPulps;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CBCTAnatomy.UNRECOGNIZED;
  }
}

export function cBCTAnatomyToJSON(object: CBCTAnatomy): string {
  switch (object) {
    case CBCTAnatomy.CBCT_Anatomy_InvalidValue:
      return 'CBCT_Anatomy_InvalidValue';
    case CBCTAnatomy.CBCT_Anatomy_Airways:
      return 'CBCT_Anatomy_Airways';
    case CBCTAnatomy.CBCT_Anatomy_Cranium:
      return 'CBCT_Anatomy_Cranium';
    case CBCTAnatomy.CBCT_Anatomy_SoftTissue:
      return 'CBCT_Anatomy_SoftTissue';
    case CBCTAnatomy.CBCT_Anatomy_IncisiveCanal:
      return 'CBCT_Anatomy_IncisiveCanal';
    case CBCTAnatomy.CBCT_Anatomy_Mandible:
      return 'CBCT_Anatomy_Mandible';
    case CBCTAnatomy.CBCT_Anatomy_Maxilla:
      return 'CBCT_Anatomy_Maxilla';
    case CBCTAnatomy.CBCT_Anatomy_MandibularCanal:
      return 'CBCT_Anatomy_MandibularCanal';
    case CBCTAnatomy.CBCT_Anatomy_Sinuses:
      return 'CBCT_Anatomy_Sinuses';
    case CBCTAnatomy.CBCT_Anatomy_GingivaUpper:
      return 'CBCT_Anatomy_GingivaUpper';
    case CBCTAnatomy.CBCT_Anatomy_GingivaLower:
      return 'CBCT_Anatomy_GingivaLower';
    case CBCTAnatomy.CBCT_Anatomy_OriginalIOSUpper:
      return 'CBCT_Anatomy_OriginalIOSUpper';
    case CBCTAnatomy.CBCT_Anatomy_OriginalIOSLower:
      return 'CBCT_Anatomy_OriginalIOSLower';
    case CBCTAnatomy.CBCT_Anatomy_PeriapicalLesions:
      return 'CBCT_Anatomy_PeriapicalLesions';
    case CBCTAnatomy.CBCT_Anatomy_ToothPulps:
      return 'CBCT_Anatomy_ToothPulps';
    case CBCTAnatomy.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
