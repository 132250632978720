import { useAppDispatch } from '@/shared/hooks';
import { accessModel } from '@/entities/access';
import { patientModel } from '@/entities/patient';

export const useRemoveDoctor = () => {
  const dispatch = useAppDispatch();

  return async ({
    patientID,
    doctorID,
  }: {
    patientID: string;
    doctorID: string;
  }) => {
    const { Patient } = await dispatch(
      accessModel.thunks.revokePatientTeamAccess({
        PatientID: patientID,
        UserID: doctorID,
      }),
    ).unwrap();

    dispatch(patientModel.actions.setNewestOne(Patient));
  };
};
