import React, { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from '@/shared/ui';

import styles from './RoiColumn.module.scss';

type RoiColumnProps = {
  roi: number[];
  isAllTeethInROI?: boolean;
  className?: string;
  testID?: string;
};

const ROI_LENGTH_WITHOUT_TOOLTIP = 6;

export const RoiColumn: FC<RoiColumnProps> = (props) => {
  const { className, testID, roi, isAllTeethInROI = false } = props;

  const shouldDisplayTooltip = roi.length > ROI_LENGTH_WITHOUT_TOOLTIP;

  const roiString = roi?.join(', ');

  return shouldDisplayTooltip ? (
    <Tooltip
      title={
        <FormattedMessage
          id="reportRow.roi"
          defaultMessage="Region of interest"
        />
      }
      content={roiString}
      side="top"
    >
      <p className={cn(styles.container, 'p2', className)} data-testid={testID}>
        {isAllTeethInROI ? (
          <FormattedMessage id="reportRow.all" defaultMessage="All" />
        ) : (
          roiString
        )}
      </p>
    </Tooltip>
  ) : (
    <p className={cn(styles.container, 'p2', className)} data-testid={testID}>
      {isAllTeethInROI ? (
        <FormattedMessage id="reportRow.all" defaultMessage="All" />
      ) : (
        roiString
      )}
    </p>
  );
};
