import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/model/store';

// TODO: Fix export select into new entity
export const selectBillingAccountInfo = createSelector(
  (state: RootState) => state.user.billingAccount,
  (accountInfo) => accountInfo,
);

export const selectCurrentUser = createSelector(
  (state: RootState) => state.user.user,
  (user) => user,
);

export const selectUserOrganizationLegacy = createSelector(
  (state: RootState) => state.user.userOrganizationLegacy,
  (organization) => organization,
);

export const selectSettings = createSelector(
  (state: RootState) => state.user.settings,
  (settings) => settings,
);
