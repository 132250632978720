import { FC, useState } from 'react';
import cn from 'classnames';

import { Button } from '@/shared/ui';

import styles from './CopyPaste.module.scss';

type Props = {
  className?: string;
  text: string;
};

export const CopyPaste: FC<Props> = (props) => {
  const { className, text } = props;

  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className={cn(styles.cont, className)}>
      <Button
        size="small"
        icon={isCopied ? 'check' : 'doc'}
        variant="tertiary"
        onClick={() => {
          navigator.clipboard.writeText(text);
          setIsCopied(true);
        }}
      />
    </div>
  );
};
