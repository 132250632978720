import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { languageDefaultMessages } from '@/pages/SignUp/config/i18n';
import { Language } from '@/shared/api/protocol_gen/model/dto_base';

export const useLanguageOptions = () => {
  const { formatMessage } = useIntl();

  const languageOptions = useMemo(
    () => [
      {
        value: Language.EN_US,
        label: formatMessage(languageDefaultMessages[Language.EN_US]),
      },
      {
        value: Language.RU,
        label: formatMessage(languageDefaultMessages[Language.RU]),
      },
      {
        value: Language.HE,
        label: formatMessage(languageDefaultMessages[Language.HE]),
      },
      {
        value: Language.DE,
        label: formatMessage(languageDefaultMessages[Language.DE]),
      },
      {
        value: Language.ES,
        label: formatMessage(languageDefaultMessages[Language.ES]),
      },
      {
        value: Language.ZH_CN,
        label: formatMessage(languageDefaultMessages[Language.ZH_CN]),
      },
      {
        value: Language.IT,
        label: formatMessage(languageDefaultMessages[Language.IT]),
      },
      {
        value: Language.RO,
        label: formatMessage(languageDefaultMessages[Language.RO]),
      },
      {
        value: Language.PT_BR,
        label: formatMessage(languageDefaultMessages[Language.PT_BR]),
      },
      {
        value: Language.FR,
        label: formatMessage(languageDefaultMessages[Language.FR]),
      },
      {
        value: Language.EN_GB,
        label: formatMessage(languageDefaultMessages[Language.EN_GB]),
      },
      {
        value: Language.JA,
        label: formatMessage(languageDefaultMessages[Language.JA]),
      },
      {
        value: Language.KA,
        label: formatMessage(languageDefaultMessages[Language.KA]),
      },
      {
        value: Language.UK,
        label: formatMessage(languageDefaultMessages[Language.UK]),
      },
      {
        value: Language.PL,
        label: formatMessage(languageDefaultMessages[Language.PL]),
      },
    ],
    [formatMessage],
  );

  return languageOptions;
};
