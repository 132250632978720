// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectedSlices-module__container--z3HSxaA\\+{display:flex;flex-wrap:wrap;gap:5px;margin-top:12px}.SelectedSlices-module__dragOvered--fLllXux5{outline:2px dashed var(--purpleflat);transition:transform var(--transition-time) ease-in-out;border-radius:8px}.SelectedSlices-module__dragDescription--Ey4AcK-a{margin-top:12px;padding:20px;border:1px dashed var(--purpleflat);border-radius:8px;text-align:center;font-size:18px;color:var(--purpleflat)}", "",{"version":3,"sources":["webpack://./src/pages/Tooth/ui/SelectedSlices/SelectedSlices.module.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CACA,cAAA,CACA,OAAA,CACA,eAAA,CAGF,6CACE,oCAAA,CACA,uDAAA,CACA,iBAAA,CAGF,kDACE,eAAA,CACA,YAAA,CACA,mCAAA,CACA,iBAAA,CACA,iBAAA,CACA,cAAA,CACA,uBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 5px;\n  margin-top: 12px;\n}\n\n.dragOvered {\n  outline: 2px dashed var(--purpleflat);\n  transition: transform var(--transition-time) ease-in-out;\n  border-radius: 8px;\n}\n\n.dragDescription {\n  margin-top: 12px;\n  padding: 20px;\n  border: 1px dashed var(--purpleflat);\n  border-radius: 8px;\n  text-align: center;\n  font-size: 18px;\n  color: var(--purpleflat);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SelectedSlices-module__container--z3HSxaA+",
	"dragOvered": "SelectedSlices-module__dragOvered--fLllXux5",
	"dragDescription": "SelectedSlices-module__dragDescription--Ey4AcK-a"
};
export default ___CSS_LOADER_EXPORT___;
