import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button, WidgetCard } from '@/shared/ui';
import { ReferralGroup } from '@/features/referralGroup';
import { REFERRALS_GROUPS_LIST } from '@/features/referralGroup/config/constants';
import { useMedia } from '@/shared/hooks';

import styles from './ReferralRecommendations.module.scss';

type ReferralRecommendationsProps = {
  className?: string;
  testID?: string;
};

export const ReferralRecommendations: FC<ReferralRecommendationsProps> = (
  props,
) => {
  const { className, testID } = props;

  const { isPhone } = useMedia();

  return (
    <WidgetCard
      className={cn(styles.container, className)}
      data-testid={testID}
    >
      <header>
        <h3 className={isPhone ? 'h4' : 'h3'}>
          <FormattedMessage
            id="referralRecommendations.header"
            defaultMessage="Teeth referral recommendations"
          />
        </h3>
      </header>
      <main className={styles.main}>
        {REFERRALS_GROUPS_LIST.map((referralGroup) => (
          <ReferralGroup referralGroup={referralGroup} key={referralGroup} />
        ))}
      </main>
      <footer className={styles.footer}>
        <Button
          variant="secondary"
          size={isPhone ? 'small' : 'medium'}
          icon="plus"
        >
          <FormattedMessage
            id="referralRecommendations.addRecommendation"
            defaultMessage="Add recommendation"
          />
        </Button>
        <Button
          variant="primary"
          size={isPhone ? 'small' : 'medium'}
          icon="plus"
        >
          <FormattedMessage
            id="referralRecommendations.createTasks"
            defaultMessage="Create tasks"
          />
        </Button>
      </footer>
    </WidgetCard>
  );
};
