import { prop, groupBy, any, propEq } from 'ramda';

import { ConditionGroups, ChildConditionGroups } from '../config/const';
import { ConditionInterface, ChildConditionInterface } from '../config/types';

// Filter both condition interfaces and child condition interfaces
export const filterConditionInterfacesBySearch = (
  search: string,
  conditionInterface: ConditionInterface[],
) =>
  conditionInterface.filter((data) => {
    const lowerCaseSearch = search.toLowerCase();
    const searchIsMatch = data.text.toLowerCase().includes(lowerCaseSearch);

    if (!searchIsMatch) {
      data.childConditionInterfaces = data.childConditionInterfaces.filter(
        (child) => child.text.toLowerCase().includes(lowerCaseSearch),
      );
    }

    return searchIsMatch || data.childConditionInterfaces.length > 0;
  });

export const sortConditionInterfaceAlphabetically = (
  a: ConditionInterface,
  b: ConditionInterface,
) => a.text.localeCompare(b.text);

export const sortConditionInterfaceByProbability = (
  a: ConditionInterface,
  b: ConditionInterface,
) => (b.probability ?? 0) * 100 - (a.probability ?? 0) * 100;

export const groupConditionInterfaceByGroup = (
  conditionItems: ConditionInterface[],
) =>
  groupBy(prop('group'), conditionItems) as Record<
    ConditionGroups,
    ConditionInterface[]
  >;

export const groupChildConditionInterfaces = (
  childConditionInterfaces: ChildConditionInterface[],
) =>
  groupBy(
    prop<keyof ChildConditionInterface>('group'),
    childConditionInterfaces,
  ) as Record<ChildConditionGroups, ChildConditionInterface[]>;

export const isAnyConditionInterfaceIsCheked = any<ConditionInterface>(
  propEq<keyof ConditionInterface>('isChecked')(true),
);
