import { FC } from 'react';
import cn from 'classnames';

import { patientModel } from '@/entities/patient';

import { Method } from '../../../Method/Method';

import styles from './PatientMethods.module.scss';

type Props = {
  className?: string;
};

type PatientThunks = keyof typeof patientModel.thunks;

export const PatientMethods: FC<Props> = (props) => {
  const { className } = props;

  return (
    <div className={cn(styles.cont, className)}>
      {Object.keys(patientModel.thunks).map((thunkName) => (
        <Method
          thunk={patientModel.thunks[thunkName as PatientThunks]}
          label={thunkName}
        />
      ))}
    </div>
  );
};
