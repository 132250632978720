// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SliceGroup-module__container--\\+hUW9oms{display:flex;flex-direction:column;gap:20px}", "",{"version":3,"sources":["webpack://./src/pages/Tooth/ui/SliceGroup/SliceGroup.module.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SliceGroup-module__container--+hUW9oms"
};
export default ___CSS_LOADER_EXPORT___;
