import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { Message } from '@/shared/api/protocol_gen/api/messenger/dto_message';

export const messageAdapter = createEntityAdapter({
  selectId: (message: Message) => message.ID,
});

const initialState = {
  messageStatus: {
    isOpen: false,
    entityType: null as string,
    entityID: null as string,
    total: 0,
  },
  message: messageAdapter.getInitialState(),
};

const messengerSlice = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    changeMessageIsOpenTo: (state, action) => {
      state.messageStatus.isOpen = action.payload;
    },
    clearMessages: (state) => {
      messageAdapter.removeAll(state.message);
    },
    addMessage: (state, action) => {
      messageAdapter.setOne(state.message, action.payload);
    },
    setTotal: (state, action) => {
      state.messageStatus.total = action.payload;
    },
    addAllMessages: (state, action) => {
      messageAdapter.setMany(state.message, action.payload);
    },
    removeMessage: (state, action) => {
      messageAdapter.removeOne(state.message, action.payload);
    },
  },
});

export const {
  addMessage,
  addAllMessages,
  changeMessageIsOpenTo,
  clearMessages,
  setTotal,
  removeMessage,
} = messengerSlice.actions;

export default messengerSlice.reducer;
