import React from 'react';

import { Annotation } from './Annotation';

const angle = (x1: number, y1: number, x2: number, y2: number): number => {
  const A = y2 - y1;
  const C = length(x1, y1, x2, y2);
  const sinAlpha = A / C;
  const alpha = Math.asin(sinAlpha) * 57.2958;

  return x2 > x1 ? alpha : 360 - alpha;
};

const length = (x1: number, y1: number, x2: number, y2: number): number =>
  Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));

export type RulerProps = Omit<
  Extract<Annotation, { kind: 'ruler' }>,
  'kind'
> & {
  pixelSpacing: [number, number];
};

const strokeColor = 'lime';
// Number of digits after the decimal point
const fractionDigits = 2;

export const Ruler = ({ x1, y1, x2, y2, pixelSpacing }: RulerProps) => {
  return (
    <>
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={strokeColor}
        strokeWidth="2"
        strokeOpacity="0.4"
      />

      <text
        transform={`
          translate(${(x2 + x1) / 2},${(y2 + y1) / 2} ) rotate(${angle(
          x1,
          y1,
          x2,
          y2,
        )})`}
        fill={strokeColor}
        textAnchor="middle"
        dy={-5}
        style={{
          userSelect: 'none',
          MozUserSelect: 'none',
          WebkitUserSelect: 'none',
        }}
      >
        {length(
          x1 * pixelSpacing[0],
          y1 * pixelSpacing[1],
          x2 * pixelSpacing[0],
          y2 * pixelSpacing[1],
        ).toFixed(fractionDigits)}
        mm
      </text>
    </>
  );
};
