import { FC } from 'react';
import cn from 'classnames';

import { scrollToElementByID } from '@/shared/lib';
import { DentalNotation, getDisplayToothNumber } from '@/entities/tooth';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import styles from './ToothNumber.module.scss';

type ToothNumberProps = {
  tooth: Tooth;
  dentalNotation: DentalNotation;
  lastISOnumber: number;
  className?: string;
  testID?: string;
};

export const ToothNumber: FC<ToothNumberProps> = (props) => {
  const { tooth, dentalNotation, lastISOnumber, className, testID } = props;

  const isLastToothInList = lastISOnumber === tooth.ISONumber;

  const displayToothNumber = getDisplayToothNumber(
    tooth.ISONumber,
    dentalNotation,
  );

  return (
    <span
      onClick={() => scrollToElementByID(tooth?.ID)}
      className={cn(styles.container, className)}
      data-testid={testID}
    >
      {displayToothNumber}
      {!isLastToothInList && `, `}
    </span>
  );
};
