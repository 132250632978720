import React, { FC } from 'react';
import cn from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';

import { ScanUidCopy } from '@/features/scanUidCopy';
import { RemoveStudyModal, useRemoveStudyModal } from '@/features/removeStudy';
import { Modal, Button } from '@/shared/ui';
import { useMedia } from '@/shared/hooks';

import { ScanInfo } from '../ScanInfo/ScanInfo';

import styles from './StudyInformation.module.scss';

type StudyInformationProps = {
  className?: string;
  testID?: string;
  studyID: string;
  isOpen?: boolean;
  onClose?: () => void;
};

export const StudyInformation: FC<StudyInformationProps> = (props) => {
  const {
    className,
    testID,
    studyID,
    isOpen = false,
    onClose = () => {},
  } = props;

  const {
    isRemoveStudyModalOpen,
    openRemoveStudyModal,
    closeRemoveStudyModal,
  } = useRemoveStudyModal();

  const { isPhone, isMobile } = useMedia();

  const buttonSize = isPhone ? 'medium' : 'large';

  const { formatMessage } = useIntl();

  // TODO: add study info from backend
  // const study = useAppSelector(studyModel.selectors.selectById(studyID));

  return (
    <>
      <div className={cn(styles.container, className)} data-testid={testID}>
        <Modal
          isOpen={isOpen}
          onClose={() => onClose()}
          title={formatMessage({
            id: 'viewDetails.title',
            defaultMessage: 'Study information',
          })}
          footer={
            <footer className={cn(styles.footer)}>
              <Button
                size={buttonSize}
                variant="tertiary"
                icon="delete"
                danger
                className={cn(styles.removeButton)}
                onClick={() => openRemoveStudyModal(studyID)}
              >
                {!isMobile && (
                  <p className="p2">
                    <FormattedMessage
                      id="global.remove"
                      defaultMessage="Remove"
                    />
                  </p>
                )}
              </Button>
              <div className={cn(styles.buttons)}>
                <Button
                  size={buttonSize}
                  variant="tertiary"
                  onClick={() => onClose()}
                >
                  <p className="p2">
                    <FormattedMessage
                      id="global.close"
                      defaultMessage="Close"
                    />
                  </p>
                </Button>
                <Button
                  size={buttonSize}
                  variant="secondary"
                  className={cn(styles.downloadButton)}
                  icon="download"
                >
                  <p className="h5">
                    <FormattedMessage
                      id="global.download"
                      defaultMessage="Download"
                    />
                  </p>
                </Button>
              </div>
            </footer>
          }
        >
          <div className={cn(styles.container, className)}>
            <main className={styles.content}>
              <ScanUidCopy scanUid="" className={styles.scanUidCopy} />
              <ScanInfo />
            </main>
          </div>
        </Modal>
      </div>

      <RemoveStudyModal
        studyID={studyID}
        isOpen={isRemoveStudyModalOpen}
        onClose={closeRemoveStudyModal}
        onRemove={onClose}
      />
    </>
  );
};
