import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DeepPartial,
  ServiceGroupsList_GroupInfo,
} from '@/shared/api/protocol_gen/api/billing/svc_billing';
import {
  SubscriptionModel,
  SubscriptionModelGroupName,
} from '@/shared/api/protocol_gen/api/billing/dto_subscription';
import { browserHistory, openInNewBrowserTab } from '@/shared/lib';

import {
  createOrder,
  getAvailableProducts,
  payInvoice,
  setAccountAddress,
  setAccountPhone,
} from './shopSlice.thunks';

export type SubscriptionAndPackagesType = DeepPartial<SubscriptionModel[]>;

export type SubscriptionToAdd = {
  SubscriptionModelID: string;
  LifeTimeMonths: number;
};

export type LifetimeOptionsByGroupName = Record<
  SubscriptionModelGroupName,
  string
>;

export type LifetimeOptionsPayload = {
  groupName: SubscriptionModelGroupName;
  lifetimeValue: string;
};

const initialState = {
  subscriptions: [] as SubscriptionAndPackagesType,
  packages: [] as SubscriptionAndPackagesType,
  serviceGroups: [] as ServiceGroupsList_GroupInfo[],
  isPending: false,
  isManualPayment: false,
  isManualPaymentModalOpen: false,
  selectedLifetimeOptionByGroup: {} as LifetimeOptionsByGroupName,
  isAdditionalFormOpen: false,
  subscriptionToAdd: {
    SubscriptionModelID: '',
    LifeTimeMonths: 0,
  },
};

const shopSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setSelectedLifetimeOptionByGroupName: (
      state,
      { payload }: PayloadAction<LifetimeOptionsPayload>,
    ) => {
      const { groupName, lifetimeValue } = payload;

      state.selectedLifetimeOptionByGroup[groupName] = lifetimeValue;
    },
    setServiceGroups: (
      state,
      action: PayloadAction<ServiceGroupsList_GroupInfo[]>,
    ) => {
      state.serviceGroups = action.payload;
    },
    setIsPending: (state, action: PayloadAction<boolean>) => {
      state.isPending = action.payload;
    },
    setIsManualPayment: (state, action: PayloadAction<boolean>) => {
      state.isManualPayment = action.payload;
    },
    setIsManualPaymentModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isManualPaymentModalOpen = action.payload;
    },
    setIsAdditionalFormOpen: (state, action: PayloadAction<boolean>) => {
      state.isAdditionalFormOpen = action.payload;
    },
    setSubscriptionToAdd: (state, action: PayloadAction<SubscriptionToAdd>) => {
      state.subscriptionToAdd = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvailableProducts.fulfilled, (state, { payload }) => {
      state.subscriptions = payload.Subscriptions;
      state.packages = payload.Packages;
    });
    // createOrder reducers
    builder.addCase(createOrder.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(createOrder.fulfilled, (state, { payload }) => {
      state.isPending = false;

      if (payload.InvoicePaymentURL) {
        openInNewBrowserTab(payload.InvoicePaymentURL);
        browserHistory.push('/settings?tab=billing');
      }
    });
    builder.addCase(createOrder.rejected, (state) => {
      state.isPending = false;
    });
    // payInvoice reducers
    builder.addCase(payInvoice.fulfilled, (state, { payload }) => {
      const { URL, Paid, ManualPaymentOnly } = payload;

      if (ManualPaymentOnly) {
        state.isManualPayment = true;
        state.isManualPaymentModalOpen = true;
      }
      if (!Paid && URL) {
        openInNewBrowserTab(URL);
      }
    });
    // setAccountPhone reducers
    builder.addCase(setAccountPhone.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(setAccountPhone.fulfilled, (state) => {
      state.isPending = false;
    });
    builder.addCase(setAccountPhone.rejected, (state) => {
      state.isPending = false;
    });
    // setAccountAddress reducers
    builder.addCase(setAccountAddress.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(setAccountAddress.fulfilled, (state) => {
      state.isPending = false;
    });
    builder.addCase(setAccountAddress.rejected, (state) => {
      state.isPending = false;
    });
  },
});

export const { actions } = shopSlice;

export default shopSlice.reducer;
