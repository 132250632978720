/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import {
  ProjectTaskStatus,
  PatientProject,
  projectTaskStatusFromJSON,
  projectTaskStatusToJSON,
} from '../../model/dto_patient_project';
import { BrowserHeaders } from 'browser-headers';

export const protobufPackage = 'com.diagnocat.core';

export interface CreatePatientProjectReq {
  PatientID: string;
  CaseDescription: string;
  DueDate: number;
}

export interface CreatePatientProjectResp {
  Project: PatientProject | undefined;
}

export interface SetPatientProjectCaseDescriptionReq {
  ID: string;
  CaseDescription: string;
}

export interface SetPatientProjectCaseDescriptionResp {
  Project: PatientProject | undefined;
}

export interface SetPatientProjectDueDateReq {
  ID: string;
  DueDate: number;
}

export interface SetPatientProjectDueDateResp {
  Project: PatientProject | undefined;
}

export interface ClosePatientProjectReq {
  ID: string;
}

export interface ClosePatientProjectResp {
  Project: PatientProject | undefined;
}

export interface ReopenPatientProjectReq {
  ID: string;
}

export interface ReopenPatientProjectResp {
  Project: PatientProject | undefined;
}

/**
 * //////////////////////////////////////////////////////////////
 * // @exclude
 * // ProjectTask
 */
export interface AddPatientProjectTaskReq {
  ProjectID: string;
  Title: string;
  Description: string;
  DueDate: number;
  Assignees: string[];
}

export interface AddPatientProjectTaskResp {
  Project: PatientProject | undefined;
}

export interface SetPatientProjectTaskTitleReq {
  ProjectID: string;
  TaskID: string;
  Title: string;
}

export interface SetPatientProjectTaskTitleResp {
  Project: PatientProject | undefined;
}

export interface SetPatientProjectTaskDescriptionReq {
  ProjectID: string;
  TaskID: string;
  Description: string;
}

export interface SetPatientProjectTaskDescriptionResp {
  Project: PatientProject | undefined;
}

export interface SetPatientProjectTaskDueDateReq {
  ProjectID: string;
  TaskID: string;
  DueDate: number;
}

export interface SetPatientProjectTaskDueDateResp {
  Project: PatientProject | undefined;
}

export interface SetPatientProjectTaskStatusReq {
  ProjectID: string;
  TaskID: string;
  Status: ProjectTaskStatus;
}

export interface SetPatientProjectTaskStatusResp {
  Project: PatientProject | undefined;
}

export interface AddPatientProjectTaskAssigneeReq {
  ProjectID: string;
  TaskID: string;
  AssigneeID: string;
}

export interface AddPatientProjectTaskAssigneeResp {
  Project: PatientProject | undefined;
}

export interface RemovePatientProjectTaskAssigneeReq {
  ProjectID: string;
  TaskID: string;
  AssigneeID: string;
}

export interface RemovePatientProjectTaskAssigneeResp {
  Project: PatientProject | undefined;
}

export interface RemovePatientProjectTaskReq {
  ProjectID: string;
  TaskID: string;
}

export interface RemovePatientProjectTaskResp {
  Project: PatientProject | undefined;
}

function createBaseCreatePatientProjectReq(): CreatePatientProjectReq {
  return { PatientID: '', CaseDescription: '', DueDate: 0 };
}

export const CreatePatientProjectReq = {
  encode(
    message: CreatePatientProjectReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.CaseDescription !== '') {
      writer.uint32(18).string(message.CaseDescription);
    }
    if (message.DueDate !== 0) {
      writer.uint32(24).int64(message.DueDate);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreatePatientProjectReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePatientProjectReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.CaseDescription = reader.string();
          break;
        case 3:
          message.DueDate = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreatePatientProjectReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      CaseDescription: isSet(object.CaseDescription)
        ? String(object.CaseDescription)
        : '',
      DueDate: isSet(object.DueDate) ? Number(object.DueDate) : 0,
    };
  },

  toJSON(message: CreatePatientProjectReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.CaseDescription !== undefined &&
      (obj.CaseDescription = message.CaseDescription);
    message.DueDate !== undefined &&
      (obj.DueDate = Math.round(message.DueDate));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreatePatientProjectReq>, I>>(
    object: I,
  ): CreatePatientProjectReq {
    const message = createBaseCreatePatientProjectReq();
    message.PatientID = object.PatientID ?? '';
    message.CaseDescription = object.CaseDescription ?? '';
    message.DueDate = object.DueDate ?? 0;
    return message;
  },
};

function createBaseCreatePatientProjectResp(): CreatePatientProjectResp {
  return { Project: undefined };
}

export const CreatePatientProjectResp = {
  encode(
    message: CreatePatientProjectResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreatePatientProjectResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePatientProjectResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreatePatientProjectResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: CreatePatientProjectResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreatePatientProjectResp>, I>>(
    object: I,
  ): CreatePatientProjectResp {
    const message = createBaseCreatePatientProjectResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseSetPatientProjectCaseDescriptionReq(): SetPatientProjectCaseDescriptionReq {
  return { ID: '', CaseDescription: '' };
}

export const SetPatientProjectCaseDescriptionReq = {
  encode(
    message: SetPatientProjectCaseDescriptionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.CaseDescription !== '') {
      writer.uint32(18).string(message.CaseDescription);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectCaseDescriptionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectCaseDescriptionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.CaseDescription = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectCaseDescriptionReq {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      CaseDescription: isSet(object.CaseDescription)
        ? String(object.CaseDescription)
        : '',
    };
  },

  toJSON(message: SetPatientProjectCaseDescriptionReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.CaseDescription !== undefined &&
      (obj.CaseDescription = message.CaseDescription);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetPatientProjectCaseDescriptionReq>, I>,
  >(object: I): SetPatientProjectCaseDescriptionReq {
    const message = createBaseSetPatientProjectCaseDescriptionReq();
    message.ID = object.ID ?? '';
    message.CaseDescription = object.CaseDescription ?? '';
    return message;
  },
};

function createBaseSetPatientProjectCaseDescriptionResp(): SetPatientProjectCaseDescriptionResp {
  return { Project: undefined };
}

export const SetPatientProjectCaseDescriptionResp = {
  encode(
    message: SetPatientProjectCaseDescriptionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectCaseDescriptionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectCaseDescriptionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectCaseDescriptionResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: SetPatientProjectCaseDescriptionResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetPatientProjectCaseDescriptionResp>, I>,
  >(object: I): SetPatientProjectCaseDescriptionResp {
    const message = createBaseSetPatientProjectCaseDescriptionResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseSetPatientProjectDueDateReq(): SetPatientProjectDueDateReq {
  return { ID: '', DueDate: 0 };
}

export const SetPatientProjectDueDateReq = {
  encode(
    message: SetPatientProjectDueDateReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.DueDate !== 0) {
      writer.uint32(16).int64(message.DueDate);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectDueDateReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectDueDateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.DueDate = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectDueDateReq {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      DueDate: isSet(object.DueDate) ? Number(object.DueDate) : 0,
    };
  },

  toJSON(message: SetPatientProjectDueDateReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.DueDate !== undefined &&
      (obj.DueDate = Math.round(message.DueDate));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientProjectDueDateReq>, I>>(
    object: I,
  ): SetPatientProjectDueDateReq {
    const message = createBaseSetPatientProjectDueDateReq();
    message.ID = object.ID ?? '';
    message.DueDate = object.DueDate ?? 0;
    return message;
  },
};

function createBaseSetPatientProjectDueDateResp(): SetPatientProjectDueDateResp {
  return { Project: undefined };
}

export const SetPatientProjectDueDateResp = {
  encode(
    message: SetPatientProjectDueDateResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectDueDateResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectDueDateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectDueDateResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: SetPatientProjectDueDateResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientProjectDueDateResp>, I>>(
    object: I,
  ): SetPatientProjectDueDateResp {
    const message = createBaseSetPatientProjectDueDateResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseClosePatientProjectReq(): ClosePatientProjectReq {
  return { ID: '' };
}

export const ClosePatientProjectReq = {
  encode(
    message: ClosePatientProjectReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ClosePatientProjectReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClosePatientProjectReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ClosePatientProjectReq {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
    };
  },

  toJSON(message: ClosePatientProjectReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ClosePatientProjectReq>, I>>(
    object: I,
  ): ClosePatientProjectReq {
    const message = createBaseClosePatientProjectReq();
    message.ID = object.ID ?? '';
    return message;
  },
};

function createBaseClosePatientProjectResp(): ClosePatientProjectResp {
  return { Project: undefined };
}

export const ClosePatientProjectResp = {
  encode(
    message: ClosePatientProjectResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ClosePatientProjectResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClosePatientProjectResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ClosePatientProjectResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: ClosePatientProjectResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ClosePatientProjectResp>, I>>(
    object: I,
  ): ClosePatientProjectResp {
    const message = createBaseClosePatientProjectResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseReopenPatientProjectReq(): ReopenPatientProjectReq {
  return { ID: '' };
}

export const ReopenPatientProjectReq = {
  encode(
    message: ReopenPatientProjectReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReopenPatientProjectReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReopenPatientProjectReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReopenPatientProjectReq {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
    };
  },

  toJSON(message: ReopenPatientProjectReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReopenPatientProjectReq>, I>>(
    object: I,
  ): ReopenPatientProjectReq {
    const message = createBaseReopenPatientProjectReq();
    message.ID = object.ID ?? '';
    return message;
  },
};

function createBaseReopenPatientProjectResp(): ReopenPatientProjectResp {
  return { Project: undefined };
}

export const ReopenPatientProjectResp = {
  encode(
    message: ReopenPatientProjectResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReopenPatientProjectResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReopenPatientProjectResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReopenPatientProjectResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: ReopenPatientProjectResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReopenPatientProjectResp>, I>>(
    object: I,
  ): ReopenPatientProjectResp {
    const message = createBaseReopenPatientProjectResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseAddPatientProjectTaskReq(): AddPatientProjectTaskReq {
  return {
    ProjectID: '',
    Title: '',
    Description: '',
    DueDate: 0,
    Assignees: [],
  };
}

export const AddPatientProjectTaskReq = {
  encode(
    message: AddPatientProjectTaskReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ProjectID !== '') {
      writer.uint32(10).string(message.ProjectID);
    }
    if (message.Title !== '') {
      writer.uint32(18).string(message.Title);
    }
    if (message.Description !== '') {
      writer.uint32(26).string(message.Description);
    }
    if (message.DueDate !== 0) {
      writer.uint32(32).int64(message.DueDate);
    }
    for (const v of message.Assignees) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddPatientProjectTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddPatientProjectTaskReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ProjectID = reader.string();
          break;
        case 2:
          message.Title = reader.string();
          break;
        case 3:
          message.Description = reader.string();
          break;
        case 4:
          message.DueDate = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.Assignees.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddPatientProjectTaskReq {
    return {
      ProjectID: isSet(object.ProjectID) ? String(object.ProjectID) : '',
      Title: isSet(object.Title) ? String(object.Title) : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
      DueDate: isSet(object.DueDate) ? Number(object.DueDate) : 0,
      Assignees: Array.isArray(object?.Assignees)
        ? object.Assignees.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: AddPatientProjectTaskReq): unknown {
    const obj: any = {};
    message.ProjectID !== undefined && (obj.ProjectID = message.ProjectID);
    message.Title !== undefined && (obj.Title = message.Title);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.DueDate !== undefined &&
      (obj.DueDate = Math.round(message.DueDate));
    if (message.Assignees) {
      obj.Assignees = message.Assignees.map((e) => e);
    } else {
      obj.Assignees = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddPatientProjectTaskReq>, I>>(
    object: I,
  ): AddPatientProjectTaskReq {
    const message = createBaseAddPatientProjectTaskReq();
    message.ProjectID = object.ProjectID ?? '';
    message.Title = object.Title ?? '';
    message.Description = object.Description ?? '';
    message.DueDate = object.DueDate ?? 0;
    message.Assignees = object.Assignees?.map((e) => e) || [];
    return message;
  },
};

function createBaseAddPatientProjectTaskResp(): AddPatientProjectTaskResp {
  return { Project: undefined };
}

export const AddPatientProjectTaskResp = {
  encode(
    message: AddPatientProjectTaskResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddPatientProjectTaskResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddPatientProjectTaskResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddPatientProjectTaskResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: AddPatientProjectTaskResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddPatientProjectTaskResp>, I>>(
    object: I,
  ): AddPatientProjectTaskResp {
    const message = createBaseAddPatientProjectTaskResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseSetPatientProjectTaskTitleReq(): SetPatientProjectTaskTitleReq {
  return { ProjectID: '', TaskID: '', Title: '' };
}

export const SetPatientProjectTaskTitleReq = {
  encode(
    message: SetPatientProjectTaskTitleReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ProjectID !== '') {
      writer.uint32(10).string(message.ProjectID);
    }
    if (message.TaskID !== '') {
      writer.uint32(18).string(message.TaskID);
    }
    if (message.Title !== '') {
      writer.uint32(26).string(message.Title);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectTaskTitleReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectTaskTitleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ProjectID = reader.string();
          break;
        case 2:
          message.TaskID = reader.string();
          break;
        case 3:
          message.Title = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectTaskTitleReq {
    return {
      ProjectID: isSet(object.ProjectID) ? String(object.ProjectID) : '',
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
      Title: isSet(object.Title) ? String(object.Title) : '',
    };
  },

  toJSON(message: SetPatientProjectTaskTitleReq): unknown {
    const obj: any = {};
    message.ProjectID !== undefined && (obj.ProjectID = message.ProjectID);
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.Title !== undefined && (obj.Title = message.Title);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientProjectTaskTitleReq>, I>>(
    object: I,
  ): SetPatientProjectTaskTitleReq {
    const message = createBaseSetPatientProjectTaskTitleReq();
    message.ProjectID = object.ProjectID ?? '';
    message.TaskID = object.TaskID ?? '';
    message.Title = object.Title ?? '';
    return message;
  },
};

function createBaseSetPatientProjectTaskTitleResp(): SetPatientProjectTaskTitleResp {
  return { Project: undefined };
}

export const SetPatientProjectTaskTitleResp = {
  encode(
    message: SetPatientProjectTaskTitleResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectTaskTitleResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectTaskTitleResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectTaskTitleResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: SetPatientProjectTaskTitleResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientProjectTaskTitleResp>, I>>(
    object: I,
  ): SetPatientProjectTaskTitleResp {
    const message = createBaseSetPatientProjectTaskTitleResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseSetPatientProjectTaskDescriptionReq(): SetPatientProjectTaskDescriptionReq {
  return { ProjectID: '', TaskID: '', Description: '' };
}

export const SetPatientProjectTaskDescriptionReq = {
  encode(
    message: SetPatientProjectTaskDescriptionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ProjectID !== '') {
      writer.uint32(10).string(message.ProjectID);
    }
    if (message.TaskID !== '') {
      writer.uint32(18).string(message.TaskID);
    }
    if (message.Description !== '') {
      writer.uint32(26).string(message.Description);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectTaskDescriptionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectTaskDescriptionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ProjectID = reader.string();
          break;
        case 2:
          message.TaskID = reader.string();
          break;
        case 3:
          message.Description = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectTaskDescriptionReq {
    return {
      ProjectID: isSet(object.ProjectID) ? String(object.ProjectID) : '',
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
    };
  },

  toJSON(message: SetPatientProjectTaskDescriptionReq): unknown {
    const obj: any = {};
    message.ProjectID !== undefined && (obj.ProjectID = message.ProjectID);
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetPatientProjectTaskDescriptionReq>, I>,
  >(object: I): SetPatientProjectTaskDescriptionReq {
    const message = createBaseSetPatientProjectTaskDescriptionReq();
    message.ProjectID = object.ProjectID ?? '';
    message.TaskID = object.TaskID ?? '';
    message.Description = object.Description ?? '';
    return message;
  },
};

function createBaseSetPatientProjectTaskDescriptionResp(): SetPatientProjectTaskDescriptionResp {
  return { Project: undefined };
}

export const SetPatientProjectTaskDescriptionResp = {
  encode(
    message: SetPatientProjectTaskDescriptionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectTaskDescriptionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectTaskDescriptionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectTaskDescriptionResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: SetPatientProjectTaskDescriptionResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetPatientProjectTaskDescriptionResp>, I>,
  >(object: I): SetPatientProjectTaskDescriptionResp {
    const message = createBaseSetPatientProjectTaskDescriptionResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseSetPatientProjectTaskDueDateReq(): SetPatientProjectTaskDueDateReq {
  return { ProjectID: '', TaskID: '', DueDate: 0 };
}

export const SetPatientProjectTaskDueDateReq = {
  encode(
    message: SetPatientProjectTaskDueDateReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ProjectID !== '') {
      writer.uint32(10).string(message.ProjectID);
    }
    if (message.TaskID !== '') {
      writer.uint32(18).string(message.TaskID);
    }
    if (message.DueDate !== 0) {
      writer.uint32(24).int64(message.DueDate);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectTaskDueDateReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectTaskDueDateReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ProjectID = reader.string();
          break;
        case 2:
          message.TaskID = reader.string();
          break;
        case 3:
          message.DueDate = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectTaskDueDateReq {
    return {
      ProjectID: isSet(object.ProjectID) ? String(object.ProjectID) : '',
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
      DueDate: isSet(object.DueDate) ? Number(object.DueDate) : 0,
    };
  },

  toJSON(message: SetPatientProjectTaskDueDateReq): unknown {
    const obj: any = {};
    message.ProjectID !== undefined && (obj.ProjectID = message.ProjectID);
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.DueDate !== undefined &&
      (obj.DueDate = Math.round(message.DueDate));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientProjectTaskDueDateReq>, I>>(
    object: I,
  ): SetPatientProjectTaskDueDateReq {
    const message = createBaseSetPatientProjectTaskDueDateReq();
    message.ProjectID = object.ProjectID ?? '';
    message.TaskID = object.TaskID ?? '';
    message.DueDate = object.DueDate ?? 0;
    return message;
  },
};

function createBaseSetPatientProjectTaskDueDateResp(): SetPatientProjectTaskDueDateResp {
  return { Project: undefined };
}

export const SetPatientProjectTaskDueDateResp = {
  encode(
    message: SetPatientProjectTaskDueDateResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectTaskDueDateResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectTaskDueDateResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectTaskDueDateResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: SetPatientProjectTaskDueDateResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetPatientProjectTaskDueDateResp>, I>,
  >(object: I): SetPatientProjectTaskDueDateResp {
    const message = createBaseSetPatientProjectTaskDueDateResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseSetPatientProjectTaskStatusReq(): SetPatientProjectTaskStatusReq {
  return { ProjectID: '', TaskID: '', Status: 0 };
}

export const SetPatientProjectTaskStatusReq = {
  encode(
    message: SetPatientProjectTaskStatusReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ProjectID !== '') {
      writer.uint32(10).string(message.ProjectID);
    }
    if (message.TaskID !== '') {
      writer.uint32(18).string(message.TaskID);
    }
    if (message.Status !== 0) {
      writer.uint32(24).int32(message.Status);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectTaskStatusReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectTaskStatusReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ProjectID = reader.string();
          break;
        case 2:
          message.TaskID = reader.string();
          break;
        case 3:
          message.Status = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectTaskStatusReq {
    return {
      ProjectID: isSet(object.ProjectID) ? String(object.ProjectID) : '',
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
      Status: isSet(object.Status)
        ? projectTaskStatusFromJSON(object.Status)
        : 0,
    };
  },

  toJSON(message: SetPatientProjectTaskStatusReq): unknown {
    const obj: any = {};
    message.ProjectID !== undefined && (obj.ProjectID = message.ProjectID);
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.Status !== undefined &&
      (obj.Status = projectTaskStatusToJSON(message.Status));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientProjectTaskStatusReq>, I>>(
    object: I,
  ): SetPatientProjectTaskStatusReq {
    const message = createBaseSetPatientProjectTaskStatusReq();
    message.ProjectID = object.ProjectID ?? '';
    message.TaskID = object.TaskID ?? '';
    message.Status = object.Status ?? 0;
    return message;
  },
};

function createBaseSetPatientProjectTaskStatusResp(): SetPatientProjectTaskStatusResp {
  return { Project: undefined };
}

export const SetPatientProjectTaskStatusResp = {
  encode(
    message: SetPatientProjectTaskStatusResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientProjectTaskStatusResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientProjectTaskStatusResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientProjectTaskStatusResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: SetPatientProjectTaskStatusResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientProjectTaskStatusResp>, I>>(
    object: I,
  ): SetPatientProjectTaskStatusResp {
    const message = createBaseSetPatientProjectTaskStatusResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseAddPatientProjectTaskAssigneeReq(): AddPatientProjectTaskAssigneeReq {
  return { ProjectID: '', TaskID: '', AssigneeID: '' };
}

export const AddPatientProjectTaskAssigneeReq = {
  encode(
    message: AddPatientProjectTaskAssigneeReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ProjectID !== '') {
      writer.uint32(10).string(message.ProjectID);
    }
    if (message.TaskID !== '') {
      writer.uint32(18).string(message.TaskID);
    }
    if (message.AssigneeID !== '') {
      writer.uint32(26).string(message.AssigneeID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddPatientProjectTaskAssigneeReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddPatientProjectTaskAssigneeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ProjectID = reader.string();
          break;
        case 2:
          message.TaskID = reader.string();
          break;
        case 3:
          message.AssigneeID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddPatientProjectTaskAssigneeReq {
    return {
      ProjectID: isSet(object.ProjectID) ? String(object.ProjectID) : '',
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
      AssigneeID: isSet(object.AssigneeID) ? String(object.AssigneeID) : '',
    };
  },

  toJSON(message: AddPatientProjectTaskAssigneeReq): unknown {
    const obj: any = {};
    message.ProjectID !== undefined && (obj.ProjectID = message.ProjectID);
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.AssigneeID !== undefined && (obj.AssigneeID = message.AssigneeID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AddPatientProjectTaskAssigneeReq>, I>,
  >(object: I): AddPatientProjectTaskAssigneeReq {
    const message = createBaseAddPatientProjectTaskAssigneeReq();
    message.ProjectID = object.ProjectID ?? '';
    message.TaskID = object.TaskID ?? '';
    message.AssigneeID = object.AssigneeID ?? '';
    return message;
  },
};

function createBaseAddPatientProjectTaskAssigneeResp(): AddPatientProjectTaskAssigneeResp {
  return { Project: undefined };
}

export const AddPatientProjectTaskAssigneeResp = {
  encode(
    message: AddPatientProjectTaskAssigneeResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddPatientProjectTaskAssigneeResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddPatientProjectTaskAssigneeResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddPatientProjectTaskAssigneeResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: AddPatientProjectTaskAssigneeResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AddPatientProjectTaskAssigneeResp>, I>,
  >(object: I): AddPatientProjectTaskAssigneeResp {
    const message = createBaseAddPatientProjectTaskAssigneeResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseRemovePatientProjectTaskAssigneeReq(): RemovePatientProjectTaskAssigneeReq {
  return { ProjectID: '', TaskID: '', AssigneeID: '' };
}

export const RemovePatientProjectTaskAssigneeReq = {
  encode(
    message: RemovePatientProjectTaskAssigneeReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ProjectID !== '') {
      writer.uint32(10).string(message.ProjectID);
    }
    if (message.TaskID !== '') {
      writer.uint32(18).string(message.TaskID);
    }
    if (message.AssigneeID !== '') {
      writer.uint32(26).string(message.AssigneeID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemovePatientProjectTaskAssigneeReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemovePatientProjectTaskAssigneeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ProjectID = reader.string();
          break;
        case 2:
          message.TaskID = reader.string();
          break;
        case 3:
          message.AssigneeID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemovePatientProjectTaskAssigneeReq {
    return {
      ProjectID: isSet(object.ProjectID) ? String(object.ProjectID) : '',
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
      AssigneeID: isSet(object.AssigneeID) ? String(object.AssigneeID) : '',
    };
  },

  toJSON(message: RemovePatientProjectTaskAssigneeReq): unknown {
    const obj: any = {};
    message.ProjectID !== undefined && (obj.ProjectID = message.ProjectID);
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    message.AssigneeID !== undefined && (obj.AssigneeID = message.AssigneeID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<RemovePatientProjectTaskAssigneeReq>, I>,
  >(object: I): RemovePatientProjectTaskAssigneeReq {
    const message = createBaseRemovePatientProjectTaskAssigneeReq();
    message.ProjectID = object.ProjectID ?? '';
    message.TaskID = object.TaskID ?? '';
    message.AssigneeID = object.AssigneeID ?? '';
    return message;
  },
};

function createBaseRemovePatientProjectTaskAssigneeResp(): RemovePatientProjectTaskAssigneeResp {
  return { Project: undefined };
}

export const RemovePatientProjectTaskAssigneeResp = {
  encode(
    message: RemovePatientProjectTaskAssigneeResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemovePatientProjectTaskAssigneeResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemovePatientProjectTaskAssigneeResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemovePatientProjectTaskAssigneeResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: RemovePatientProjectTaskAssigneeResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<RemovePatientProjectTaskAssigneeResp>, I>,
  >(object: I): RemovePatientProjectTaskAssigneeResp {
    const message = createBaseRemovePatientProjectTaskAssigneeResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

function createBaseRemovePatientProjectTaskReq(): RemovePatientProjectTaskReq {
  return { ProjectID: '', TaskID: '' };
}

export const RemovePatientProjectTaskReq = {
  encode(
    message: RemovePatientProjectTaskReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ProjectID !== '') {
      writer.uint32(10).string(message.ProjectID);
    }
    if (message.TaskID !== '') {
      writer.uint32(18).string(message.TaskID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemovePatientProjectTaskReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemovePatientProjectTaskReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ProjectID = reader.string();
          break;
        case 2:
          message.TaskID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemovePatientProjectTaskReq {
    return {
      ProjectID: isSet(object.ProjectID) ? String(object.ProjectID) : '',
      TaskID: isSet(object.TaskID) ? String(object.TaskID) : '',
    };
  },

  toJSON(message: RemovePatientProjectTaskReq): unknown {
    const obj: any = {};
    message.ProjectID !== undefined && (obj.ProjectID = message.ProjectID);
    message.TaskID !== undefined && (obj.TaskID = message.TaskID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemovePatientProjectTaskReq>, I>>(
    object: I,
  ): RemovePatientProjectTaskReq {
    const message = createBaseRemovePatientProjectTaskReq();
    message.ProjectID = object.ProjectID ?? '';
    message.TaskID = object.TaskID ?? '';
    return message;
  },
};

function createBaseRemovePatientProjectTaskResp(): RemovePatientProjectTaskResp {
  return { Project: undefined };
}

export const RemovePatientProjectTaskResp = {
  encode(
    message: RemovePatientProjectTaskResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Project !== undefined) {
      PatientProject.encode(message.Project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemovePatientProjectTaskResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemovePatientProjectTaskResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Project = PatientProject.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemovePatientProjectTaskResp {
    return {
      Project: isSet(object.Project)
        ? PatientProject.fromJSON(object.Project)
        : undefined,
    };
  },

  toJSON(message: RemovePatientProjectTaskResp): unknown {
    const obj: any = {};
    message.Project !== undefined &&
      (obj.Project = message.Project
        ? PatientProject.toJSON(message.Project)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemovePatientProjectTaskResp>, I>>(
    object: I,
  ): RemovePatientProjectTaskResp {
    const message = createBaseRemovePatientProjectTaskResp();
    message.Project =
      object.Project !== undefined && object.Project !== null
        ? PatientProject.fromPartial(object.Project)
        : undefined;
    return message;
  },
};

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: ProjectID/TaskID/PatientID
 * - type: NotFoundError, Entity: patient project (if project or project task not fount)
 * - type: BadRequestError, Reason: patient deleted (if remote patient found by PatientID)
 * - type: PermissionDeniedError, Reason: you do not have patient permission `CanEditProject`
 */
export interface PatientProjectService {
  /**
   * Create new patient project
   * - Only one open project per patient allowed
   *
   * Errors:
   * - type: BadRequestError, Reason: patient already has open project
   */
  CreatePatientProject(
    request: DeepPartial<CreatePatientProjectReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreatePatientProjectResp>;
  SetPatientProjectCaseDescription(
    request: DeepPartial<SetPatientProjectCaseDescriptionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectCaseDescriptionResp>;
  /**
   * Set project due date
   * - in milliseconds
   *
   * Errors:
   * - type: InvalidArgumentValueError, Argument: DueDate (if the `DueDate` argument is less than current time or greater than current time + 1 year)
   */
  SetPatientProjectDueDate(
    request: DeepPartial<SetPatientProjectDueDateReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectDueDateResp>;
  ClosePatientProject(
    request: DeepPartial<ClosePatientProjectReq>,
    metadata?: grpc.Metadata,
  ): Promise<ClosePatientProjectResp>;
  ReopenPatientProject(
    request: DeepPartial<ReopenPatientProjectReq>,
    metadata?: grpc.Metadata,
  ): Promise<ReopenPatientProjectResp>;
  /**
   * Add assignee to project
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: Title
   * - type: NotFoundError, Entity: assignee
   * - type: BadRequestError, Reason: assignee does not have organization or patient roles
   */
  AddPatientProjectTask(
    request: DeepPartial<AddPatientProjectTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddPatientProjectTaskResp>;
  /**
   * Set project task title
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: Title
   */
  SetPatientProjectTaskTitle(
    request: DeepPartial<SetPatientProjectTaskTitleReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectTaskTitleResp>;
  SetPatientProjectTaskDescription(
    request: DeepPartial<SetPatientProjectTaskDescriptionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectTaskDescriptionResp>;
  /**
   * Set project task due date
   * - in milliseconds
   *
   * Errors:
   * - type: InvalidArgumentValueError, Argument: DueDate (if the `DueDate` argument is less than current time or greater than current time + 1 year)
   */
  SetPatientProjectTaskDueDate(
    request: DeepPartial<SetPatientProjectTaskDueDateReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectTaskDueDateResp>;
  /**
   * Set project task status
   *
   * Errors:
   * - type: InvalidArgumentValueError, Argument: Status (if `SetPatientProjectTaskStatusReq.Status` == `ProjectTaskStatusInvalidValue`)
   */
  SetPatientProjectTaskStatus(
    request: DeepPartial<SetPatientProjectTaskStatusReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectTaskStatusResp>;
  /**
   * Add assignee to project task
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: AssigneeID
   * - type: NotFoundError, Entity: assignee
   * - type: BadRequestError, Reason: assignee does not have organization or patient roles
   */
  AddPatientProjectTaskAssignee(
    request: DeepPartial<AddPatientProjectTaskAssigneeReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddPatientProjectTaskAssigneeResp>;
  /**
   * Remove assignee from project task
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: AssigneeID
   */
  RemovePatientProjectTaskAssignee(
    request: DeepPartial<RemovePatientProjectTaskAssigneeReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemovePatientProjectTaskAssigneeResp>;
  RemovePatientProjectTask(
    request: DeepPartial<RemovePatientProjectTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemovePatientProjectTaskResp>;
}

export class PatientProjectServiceClientImpl implements PatientProjectService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreatePatientProject = this.CreatePatientProject.bind(this);
    this.SetPatientProjectCaseDescription =
      this.SetPatientProjectCaseDescription.bind(this);
    this.SetPatientProjectDueDate = this.SetPatientProjectDueDate.bind(this);
    this.ClosePatientProject = this.ClosePatientProject.bind(this);
    this.ReopenPatientProject = this.ReopenPatientProject.bind(this);
    this.AddPatientProjectTask = this.AddPatientProjectTask.bind(this);
    this.SetPatientProjectTaskTitle =
      this.SetPatientProjectTaskTitle.bind(this);
    this.SetPatientProjectTaskDescription =
      this.SetPatientProjectTaskDescription.bind(this);
    this.SetPatientProjectTaskDueDate =
      this.SetPatientProjectTaskDueDate.bind(this);
    this.SetPatientProjectTaskStatus =
      this.SetPatientProjectTaskStatus.bind(this);
    this.AddPatientProjectTaskAssignee =
      this.AddPatientProjectTaskAssignee.bind(this);
    this.RemovePatientProjectTaskAssignee =
      this.RemovePatientProjectTaskAssignee.bind(this);
    this.RemovePatientProjectTask = this.RemovePatientProjectTask.bind(this);
  }

  CreatePatientProject(
    request: DeepPartial<CreatePatientProjectReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreatePatientProjectResp> {
    return this.rpc.unary(
      PatientProjectServiceCreatePatientProjectDesc,
      CreatePatientProjectReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientProjectCaseDescription(
    request: DeepPartial<SetPatientProjectCaseDescriptionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectCaseDescriptionResp> {
    return this.rpc.unary(
      PatientProjectServiceSetPatientProjectCaseDescriptionDesc,
      SetPatientProjectCaseDescriptionReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientProjectDueDate(
    request: DeepPartial<SetPatientProjectDueDateReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectDueDateResp> {
    return this.rpc.unary(
      PatientProjectServiceSetPatientProjectDueDateDesc,
      SetPatientProjectDueDateReq.fromPartial(request),
      metadata,
    );
  }

  ClosePatientProject(
    request: DeepPartial<ClosePatientProjectReq>,
    metadata?: grpc.Metadata,
  ): Promise<ClosePatientProjectResp> {
    return this.rpc.unary(
      PatientProjectServiceClosePatientProjectDesc,
      ClosePatientProjectReq.fromPartial(request),
      metadata,
    );
  }

  ReopenPatientProject(
    request: DeepPartial<ReopenPatientProjectReq>,
    metadata?: grpc.Metadata,
  ): Promise<ReopenPatientProjectResp> {
    return this.rpc.unary(
      PatientProjectServiceReopenPatientProjectDesc,
      ReopenPatientProjectReq.fromPartial(request),
      metadata,
    );
  }

  AddPatientProjectTask(
    request: DeepPartial<AddPatientProjectTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddPatientProjectTaskResp> {
    return this.rpc.unary(
      PatientProjectServiceAddPatientProjectTaskDesc,
      AddPatientProjectTaskReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientProjectTaskTitle(
    request: DeepPartial<SetPatientProjectTaskTitleReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectTaskTitleResp> {
    return this.rpc.unary(
      PatientProjectServiceSetPatientProjectTaskTitleDesc,
      SetPatientProjectTaskTitleReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientProjectTaskDescription(
    request: DeepPartial<SetPatientProjectTaskDescriptionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectTaskDescriptionResp> {
    return this.rpc.unary(
      PatientProjectServiceSetPatientProjectTaskDescriptionDesc,
      SetPatientProjectTaskDescriptionReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientProjectTaskDueDate(
    request: DeepPartial<SetPatientProjectTaskDueDateReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectTaskDueDateResp> {
    return this.rpc.unary(
      PatientProjectServiceSetPatientProjectTaskDueDateDesc,
      SetPatientProjectTaskDueDateReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientProjectTaskStatus(
    request: DeepPartial<SetPatientProjectTaskStatusReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientProjectTaskStatusResp> {
    return this.rpc.unary(
      PatientProjectServiceSetPatientProjectTaskStatusDesc,
      SetPatientProjectTaskStatusReq.fromPartial(request),
      metadata,
    );
  }

  AddPatientProjectTaskAssignee(
    request: DeepPartial<AddPatientProjectTaskAssigneeReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddPatientProjectTaskAssigneeResp> {
    return this.rpc.unary(
      PatientProjectServiceAddPatientProjectTaskAssigneeDesc,
      AddPatientProjectTaskAssigneeReq.fromPartial(request),
      metadata,
    );
  }

  RemovePatientProjectTaskAssignee(
    request: DeepPartial<RemovePatientProjectTaskAssigneeReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemovePatientProjectTaskAssigneeResp> {
    return this.rpc.unary(
      PatientProjectServiceRemovePatientProjectTaskAssigneeDesc,
      RemovePatientProjectTaskAssigneeReq.fromPartial(request),
      metadata,
    );
  }

  RemovePatientProjectTask(
    request: DeepPartial<RemovePatientProjectTaskReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemovePatientProjectTaskResp> {
    return this.rpc.unary(
      PatientProjectServiceRemovePatientProjectTaskDesc,
      RemovePatientProjectTaskReq.fromPartial(request),
      metadata,
    );
  }
}

export const PatientProjectServiceDesc = {
  serviceName: 'com.diagnocat.core.PatientProjectService',
};

export const PatientProjectServiceCreatePatientProjectDesc: UnaryMethodDefinitionish =
  {
    methodName: 'CreatePatientProject',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return CreatePatientProjectReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...CreatePatientProjectResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceSetPatientProjectCaseDescriptionDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientProjectCaseDescription',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientProjectCaseDescriptionReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientProjectCaseDescriptionResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceSetPatientProjectDueDateDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientProjectDueDate',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientProjectDueDateReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientProjectDueDateResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceClosePatientProjectDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ClosePatientProject',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ClosePatientProjectReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...ClosePatientProjectResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceReopenPatientProjectDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ReopenPatientProject',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ReopenPatientProjectReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...ReopenPatientProjectResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceAddPatientProjectTaskDesc: UnaryMethodDefinitionish =
  {
    methodName: 'AddPatientProjectTask',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return AddPatientProjectTaskReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AddPatientProjectTaskResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceSetPatientProjectTaskTitleDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientProjectTaskTitle',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientProjectTaskTitleReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientProjectTaskTitleResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceSetPatientProjectTaskDescriptionDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientProjectTaskDescription',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientProjectTaskDescriptionReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientProjectTaskDescriptionResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceSetPatientProjectTaskDueDateDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientProjectTaskDueDate',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientProjectTaskDueDateReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientProjectTaskDueDateResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceSetPatientProjectTaskStatusDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientProjectTaskStatus',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientProjectTaskStatusReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientProjectTaskStatusResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceAddPatientProjectTaskAssigneeDesc: UnaryMethodDefinitionish =
  {
    methodName: 'AddPatientProjectTaskAssignee',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return AddPatientProjectTaskAssigneeReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AddPatientProjectTaskAssigneeResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceRemovePatientProjectTaskAssigneeDesc: UnaryMethodDefinitionish =
  {
    methodName: 'RemovePatientProjectTaskAssignee',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return RemovePatientProjectTaskAssigneeReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...RemovePatientProjectTaskAssigneeResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientProjectServiceRemovePatientProjectTaskDesc: UnaryMethodDefinitionish =
  {
    methodName: 'RemovePatientProjectTask',
    service: PatientProjectServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return RemovePatientProjectTaskReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...RemovePatientProjectTaskResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
