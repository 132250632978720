import React, { FC } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { Virtuoso, VirtuosoGrid } from 'react-virtuoso';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import sadCatImg from '@/shared/assets/images/sad-cat.png';
import { Button, Result, Skeleton } from '@/shared/ui';
import { useAppSelector, useMedia } from '@/shared/hooks';
import { patientModel, StudyCounterItem } from '@/entities/patient';
import { PatientListCard, PatientListRow } from '@/features/patients';
import { userModel } from '@/entities/user';
import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';

import styles from './PatientList.module.scss';

// TODO: Remove mock data
const studies: StudyCounterItem[] = [
  { name: 'cbct', count: 1 },
  { name: 'ios', count: 0 },
  { name: 'pano', count: 0 },
  { name: 'photo', count: 0 },
  { name: '3d', count: 0 },
  { name: 'doc', count: 1 },
];

type PatientListProps = {
  className?: string;
  handleAddPatient: () => void;
  reFetchPatients: (id: string) => void;
};

export const PatientList: FC<PatientListProps> = (props) => {
  const { className, handleAddPatient, reFetchPatients } = props;

  const { formatMessage } = useIntl();

  const emptyMessage = formatMessage({
    id: 'patientList.empty',
    defaultMessage: 'No patients in the list yet.\n You can add them now',
  });

  const nothingFoundMessage = formatMessage({
    id: 'patientList.nothingFound',
    defaultMessage: 'Nothing found.\n Please, try other search options',
  });

  const { isPhone } = useMedia();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');

  const { view } = useAppSelector(userModel.selectors.selectSettings);
  const patientList = useAppSelector(patientModel.selectors.selectAll);
  const patientListLoading = useAppSelector((state) => state.patient.loading);

  const showSkeleton =
    (patientListLoading === 'pending' || patientListLoading === 'idle') &&
    patientList.length === 0;

  const isRowSkeleton = view === 'row' && !isPhone;

  const showNothingFound =
    patientListLoading === 'succeeded' &&
    patientList.length === 0 &&
    Boolean(search);

  const showEmpty =
    patientListLoading === 'succeeded' &&
    patientList.length === 0 &&
    isEmpty(search);

  const showList = !!patientList.length;

  const patientListRow = (index: number, patient: Patient) => (
    <PatientListRow
      key={patient.ID}
      id={patient.ID}
      patientName={`${patient.PersonalData.FirstName} ${patient.PersonalData.LastName}`}
      patientBirthDate={format(
        new Date(patient.PersonalData.DateOfBirth),
        'd MMMM, y',
      )}
      studies={studies}
    />
  );

  const patientListGrid = (index: number) => {
    const patient = patientList[index];
    return (
      <PatientListCard
        key={patient.ID}
        id={patient.ID}
        patientName={`${patient.PersonalData.FirstName}\n${patient.PersonalData.LastName}`}
        patientBirthDate={format(
          new Date(patient.PersonalData.DateOfBirth),
          'd MMMM, y',
        )}
        studies={studies}
      />
    );
  };

  const loadMore = () => {
    const patient = patientList[patientList.length - 1];
    reFetchPatients(patient.ID);
  };

  return (
    <div
      className={cn(
        styles.container,
        view === 'grid' && styles.transparent,
        className,
      )}
    >
      {showSkeleton &&
        (isRowSkeleton ? (
          <Skeleton.Row className={styles.skeletonContainer} />
        ) : (
          <Skeleton.Grid />
        ))}

      {showEmpty && (
        <Result
          className={styles.empty}
          icon={
            <img src={sadCatImg} width={130} height={130} alt={emptyMessage} />
          }
          text={emptyMessage}
          extra={
            <Button variant="secondary" icon="plus" onClick={handleAddPatient}>
              <FormattedMessage
                id="patientList.addPatient"
                defaultMessage="Add new patient"
              />
            </Button>
          }
        />
      )}

      {showNothingFound && (
        <Result
          className={styles.empty}
          icon={
            <img
              src={sadCatImg}
              width={130}
              height={130}
              alt={nothingFoundMessage}
            />
          }
          text={nothingFoundMessage}
        />
      )}

      {showList &&
        (view === 'row' && !isPhone ? (
          <Virtuoso
            style={{ flex: 1 }}
            data={patientList}
            endReached={loadMore}
            overscan={200}
            itemContent={patientListRow}
            className={styles.row}
          />
        ) : (
          <VirtuosoGrid
            listClassName={styles.grid}
            itemClassName={styles.gridItem}
            className={styles.virtuosoGrid}
            totalCount={patientList.length}
            style={{ flex: 1 }}
            endReached={loadMore}
            overscan={1200}
            itemContent={patientListGrid}
          />
        ))}
    </div>
  );
};
