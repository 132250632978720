import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeepPartial } from 'react-hook-form';

import api, { ApiError } from '@/shared/api/api';
import {
  AddOrganizationUserSpecializationReq,
  CreateOrganizationReq,
  DeleteOrganizationReq,
  //   HackSetOrganizationUserContactsReq,
  OrganizationServiceClientImpl,
  RemoveOrganizationUserSpecializationReq,
  SetOrganizationContactsReq,
  SetOrganizationNameReq,
  SetOrganizationSettingsReq,
  SetOrganizationTypeReq,
  SetOrganizationUserJobTitleReq,
} from '@/shared/api/protocol_gen/api/core/svc_organization';
import { LocalStorageKey } from '@/shared/config';

type OrganizationRequests = keyof OrganizationServiceClientImpl;

const requestGenerator = <Req>(requestName: OrganizationRequests) =>
  createAsyncThunk(
    `organization/${requestName}`,
    async (request: DeepPartial<Req>, { rejectWithValue }) => {
      try {
        const response = await api.organization[requestName](request);

        return response;
      } catch (error: unknown) {
        const { message, type } = error as ApiError;

        return rejectWithValue({ message, type });
      }
    },
  );

export const createOrganization =
  requestGenerator<CreateOrganizationReq>('CreateOrganization');
export const setOrganizationName = requestGenerator<SetOrganizationNameReq>(
  'SetOrganizationName',
);
export const setOrganizationType = requestGenerator<SetOrganizationTypeReq>(
  'SetOrganizationType',
);
export const setOrganizationContacts =
  requestGenerator<SetOrganizationContactsReq>('SetOrganizationContacts');
export const setOrganizationSettings =
  requestGenerator<SetOrganizationSettingsReq>('SetOrganizationSettings');
export const deleteOrganization =
  requestGenerator<DeleteOrganizationReq>('DeleteOrganization');
// export const hack_SetUserContacts =
//   requestGenerator<HackSetOrganizationUserContactsReq>('Hack_SetUserContacts');
export const setOrganizationUserJobTitle =
  requestGenerator<SetOrganizationUserJobTitleReq>(
    'SetOrganizationUserJobTitle',
  );
export const addOrganizationUserSpecialization =
  requestGenerator<AddOrganizationUserSpecializationReq>(
    'AddOrganizationUserSpecialization',
  );
export const removeOrganizationUserSpecialization =
  requestGenerator<RemoveOrganizationUserSpecializationReq>(
    'RemoveOrganizationUserSpecialization',
  );

export const setCurrentOrganizationID = createAsyncThunk(
  'organization/setCurrentOrganizationID',
  async (organizationID: string) => {
    localStorage.setItem(LocalStorageKey.organizationID, organizationID);
    return organizationID;
  },
);
