/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import {
  StudyType,
  Study,
  studyTypeFromJSON,
  studyTypeToJSON,
} from '../../model/dto_study';
import {
  Gender,
  PersonalData,
  genderFromJSON,
  genderToJSON,
} from '../../model/dto_base';
import {
  PatientStatus,
  Patient,
  patientStatusFromJSON,
  patientStatusToJSON,
} from '../../model/dto_patient';
import { Observable } from 'rxjs';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';
import { PatientProject } from '../../model/dto_patient_project';
import { Empty } from '../../google/protobuf/empty';
import { Asset } from '../../model/dto_asset';
import { Report } from '../../model/dto_report';
import { Tooth } from '../../model/dto_report_tooth';
import { Condition } from '../../model/dto_report_condition';
import { Invitation } from '../../model/dto_access';
import { User } from '../../model/dto_user';

export const protobufPackage = 'com.diagnocat.core';

export interface PatientProfileStreamReq {
  PatientID: string;
}

export interface PatientProfileStreamResp {
  HistoricalPatient: Patient | undefined;
  /** if `Patient.YourPermissions.CanViewProject` == true */
  HistoricalPatientProject: PatientProject | undefined;
  /** `EndOfHistoricalPatientProjects` comes right after all historical patient projects are received */
  EndOfHistoricalPatientProjects: Empty | undefined;
  /** if `Patient.YourPermissions.CanViewNewStudies` == true || `Patient.YourPermissions.CanViewHistoricalStudies` == true */
  HistoricalStudy: Study | undefined;
  /** `EndOfHistoricalHistoricalStudies` comes right after all historical studies are received */
  EndOfHistoricalHistoricalStudies: Empty | undefined;
  /** study assets, documents assets */
  HistoricalAsset: Asset | undefined;
  /** `EndOfHistoricalHistoricalAssets` comes right after all historical assets are received */
  EndOfHistoricalHistoricalAssets: Empty | undefined;
  /** patient reports - if `Patient.YourPermissions.CanViewNewReports` == true || `Patient.YourPermissions.CanViewHistoricalReports` == true */
  HistoricalReport: Report | undefined;
  /** `EndOfHistoricalHistoricalReports` comes right after all historical reports are received */
  EndOfHistoricalHistoricalReports: Empty | undefined;
  HistoricalTooth: Tooth | undefined;
  /** `EndOfHistoricalHistoricalTeeth` comes right after all historical teeth are received */
  EndOfHistoricalHistoricalTeeth: Empty | undefined;
  /** only if `Certainty.ModelDecision == true` || `Certainty.UserDecision == true` */
  HistoricalCondition: Condition | undefined;
  /** `EndOfHistoricalHistoricalConditions` comes right after all historical conditions are received */
  EndOfHistoricalHistoricalConditions: Empty | undefined;
  /**
   * if `Patient.YourPermissions.CanViewSharings` == true.
   * All invitations to this patient, its studies or its reports.
   * There will be no invitations that were canceled/accepted/rejected/deleted before the stream opening
   */
  HistoricalInvitation: Invitation | undefined;
  /** `EndOfHistoricalHistoricalInvitations` comes right after all historical invitations are received */
  EndOfHistoricalHistoricalInvitations: Empty | undefined;
  /**
   * if `Patient.YourPermissions.CanViewSharings` == true.
   * Users to whom this patient/patient_studies/patient_reports was shared
   */
  HistoricalSharingMember: User | undefined;
  /** `EndOfHistoricalHistoricalSharingMembers` comes right after all historical users are received */
  EndOfHistoricalHistoricalSharingMembers: Empty | undefined;
  UpdatedPatient: Patient | undefined;
  UpdatedPatientProject: PatientProject | undefined;
  UpdatedStudy: Study | undefined;
  UpdatedAsset: Asset | undefined;
  UpdatedReport: Report | undefined;
  UpdatedTooth: Tooth | undefined;
  UpdatedCondition: Condition | undefined;
  UpdatedInvitation: Invitation | undefined;
  UpdatedSharingMember: User | undefined;
  /** `Ping` - just a stub ping message to ensure that the stream won't break by timeout */
  Ping: Empty | undefined;
}

/** sorted by Last modified date in descending order */
export interface PatientListStreamReq {
  /**
   * The last `ResumeToken` obtained from `PatientListStreamResp`.
   * If filters are changed or a page is just opened, pass empty string until new token is received
   */
  ResumeToken: string;
  /** Organization you gonna work with */
  OrganizationID: string;
  /** externalID/first name/last name or a part of it */
  SearchString: string;
  /** if `SharedWithMe` == true, stream returns only shared patients */
  SharedWithMe: boolean;
  /**
   * Returns only patients who include at least one of the `DoctorIDs`.
   * This field won't be ignored only if `SharedWithMe` == false.
   * These two fields (`SharedWithMe` && `DoctorIDs`) are mutually exclusive.
   * There was `oneof` here to describe it, but @frontend-team asked to remove it, so you can read it in the comment
   */
  DoctorIDs: string[];
  /** Patient's identifier to start listing from */
  StartFromPatientID: string;
  /** min == 10, max == 1000 */
  Limit: number;
}

/** We do not send doctors participating in this patient 'cause you already have it from MyDataStream */
export interface PatientListStreamResp {
  /** If the stream was closed, we need `ResumeToken` to know which event you received the last. */
  ResumeToken: string;
  /** Patients list according to request filters and limit */
  InitialPatientsListItems:
    | PatientListStreamResp_PatientsListItemsUpdate
    | undefined;
  PatientUpdated: PatientListStreamResp_PatientItem | undefined;
  /** Total count of patients available to `Me` in the selected organization */
  TotalCount: number | undefined;
  /** Total count of shared patients */
  SharedCount: number | undefined;
  /** `Ping` - just a stub ping message to ensure that the stream won't break by timeout */
  Ping: Empty | undefined;
}

export interface PatientListStreamResp_PatientItem {
  Patient: Patient | undefined;
  Counts: PatientListStreamResp_PatientItem_StudyCount[];
  /**
   * RelatedUsers is filled only if `PatientListStreamReq.SharedWithMe` == true
   * RelatedUsers includes users who has shared patient/patient_studies/patient_reports with current user
   */
  RelatedUsers: User[];
}

export interface PatientListStreamResp_PatientItem_StudyCount {
  Modality: StudyType;
  Count: number;
}

export interface PatientListStreamResp_PatientsListItemsUpdate {
  Patients: PatientListStreamResp_PatientItem[];
}

export interface CreatePatientReq {
  OrganizationID: string;
  PersonalData: PersonalData | undefined;
  Gender: Gender;
  ExternalID: string;
  /** @exclude repeated com.diagnocat.model.Attribute Attributes = 10; // TODO - what is it? */
  TreatingDoctorIDs: string[];
}

export interface CreatePatientResp {
  Patient: Patient | undefined;
}

export interface SetPatientPersonalDataReq {
  PatientID: string;
  PersonalData: PersonalData | undefined;
}

export interface SetPatientPersonalDataResp {
  Patient: Patient | undefined;
}

export interface SetPatientExternalIDReq {
  PatientID: string;
  ExternalID: string;
}

export interface SetPatientExternalIDResp {
  Patient: Patient | undefined;
}

export interface SetPatientGenderReq {
  PatientID: string;
  Gender: Gender;
}

export interface SetPatientGenderResp {
  Patient: Patient | undefined;
}

export interface SetPatientStatusReq {
  PatientID: string;
  Status: PatientStatus;
}

export interface SetPatientStatusResp {
  Patient: Patient | undefined;
}

export interface DeletePatientReq {
  PatientID: string;
}

export interface DeletePatientResp {
  DeletedPatientID: string;
  DeletedStudyIDs: string[];
  DeletedReportIDs: string[];
  DeletedToothIDs: string[];
  DeletedConditionIDs: string[];
  DeletedAssetIDs: string[];
  DeletedInvitationIDs: string[];
}

function createBasePatientProfileStreamReq(): PatientProfileStreamReq {
  return { PatientID: '' };
}

export const PatientProfileStreamReq = {
  encode(
    message: PatientProfileStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientProfileStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientProfileStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientProfileStreamReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
    };
  },

  toJSON(message: PatientProfileStreamReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientProfileStreamReq>, I>>(
    object: I,
  ): PatientProfileStreamReq {
    const message = createBasePatientProfileStreamReq();
    message.PatientID = object.PatientID ?? '';
    return message;
  },
};

function createBasePatientProfileStreamResp(): PatientProfileStreamResp {
  return {
    HistoricalPatient: undefined,
    HistoricalPatientProject: undefined,
    EndOfHistoricalPatientProjects: undefined,
    HistoricalStudy: undefined,
    EndOfHistoricalHistoricalStudies: undefined,
    HistoricalAsset: undefined,
    EndOfHistoricalHistoricalAssets: undefined,
    HistoricalReport: undefined,
    EndOfHistoricalHistoricalReports: undefined,
    HistoricalTooth: undefined,
    EndOfHistoricalHistoricalTeeth: undefined,
    HistoricalCondition: undefined,
    EndOfHistoricalHistoricalConditions: undefined,
    HistoricalInvitation: undefined,
    EndOfHistoricalHistoricalInvitations: undefined,
    HistoricalSharingMember: undefined,
    EndOfHistoricalHistoricalSharingMembers: undefined,
    UpdatedPatient: undefined,
    UpdatedPatientProject: undefined,
    UpdatedStudy: undefined,
    UpdatedAsset: undefined,
    UpdatedReport: undefined,
    UpdatedTooth: undefined,
    UpdatedCondition: undefined,
    UpdatedInvitation: undefined,
    UpdatedSharingMember: undefined,
    Ping: undefined,
  };
}

export const PatientProfileStreamResp = {
  encode(
    message: PatientProfileStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.HistoricalPatient !== undefined) {
      Patient.encode(
        message.HistoricalPatient,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.HistoricalPatientProject !== undefined) {
      PatientProject.encode(
        message.HistoricalPatientProject,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalPatientProjects !== undefined) {
      Empty.encode(
        message.EndOfHistoricalPatientProjects,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.HistoricalStudy !== undefined) {
      Study.encode(message.HistoricalStudy, writer.uint32(34).fork()).ldelim();
    }
    if (message.EndOfHistoricalHistoricalStudies !== undefined) {
      Empty.encode(
        message.EndOfHistoricalHistoricalStudies,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.HistoricalAsset !== undefined) {
      Asset.encode(message.HistoricalAsset, writer.uint32(50).fork()).ldelim();
    }
    if (message.EndOfHistoricalHistoricalAssets !== undefined) {
      Empty.encode(
        message.EndOfHistoricalHistoricalAssets,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.HistoricalReport !== undefined) {
      Report.encode(
        message.HistoricalReport,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalHistoricalReports !== undefined) {
      Empty.encode(
        message.EndOfHistoricalHistoricalReports,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    if (message.HistoricalTooth !== undefined) {
      Tooth.encode(message.HistoricalTooth, writer.uint32(82).fork()).ldelim();
    }
    if (message.EndOfHistoricalHistoricalTeeth !== undefined) {
      Empty.encode(
        message.EndOfHistoricalHistoricalTeeth,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.HistoricalCondition !== undefined) {
      Condition.encode(
        message.HistoricalCondition,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalHistoricalConditions !== undefined) {
      Empty.encode(
        message.EndOfHistoricalHistoricalConditions,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.HistoricalInvitation !== undefined) {
      Invitation.encode(
        message.HistoricalInvitation,
        writer.uint32(114).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalHistoricalInvitations !== undefined) {
      Empty.encode(
        message.EndOfHistoricalHistoricalInvitations,
        writer.uint32(122).fork(),
      ).ldelim();
    }
    if (message.HistoricalSharingMember !== undefined) {
      User.encode(
        message.HistoricalSharingMember,
        writer.uint32(130).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalHistoricalSharingMembers !== undefined) {
      Empty.encode(
        message.EndOfHistoricalHistoricalSharingMembers,
        writer.uint32(138).fork(),
      ).ldelim();
    }
    if (message.UpdatedPatient !== undefined) {
      Patient.encode(
        message.UpdatedPatient,
        writer.uint32(250).fork(),
      ).ldelim();
    }
    if (message.UpdatedPatientProject !== undefined) {
      PatientProject.encode(
        message.UpdatedPatientProject,
        writer.uint32(258).fork(),
      ).ldelim();
    }
    if (message.UpdatedStudy !== undefined) {
      Study.encode(message.UpdatedStudy, writer.uint32(266).fork()).ldelim();
    }
    if (message.UpdatedAsset !== undefined) {
      Asset.encode(message.UpdatedAsset, writer.uint32(274).fork()).ldelim();
    }
    if (message.UpdatedReport !== undefined) {
      Report.encode(message.UpdatedReport, writer.uint32(282).fork()).ldelim();
    }
    if (message.UpdatedTooth !== undefined) {
      Tooth.encode(message.UpdatedTooth, writer.uint32(290).fork()).ldelim();
    }
    if (message.UpdatedCondition !== undefined) {
      Condition.encode(
        message.UpdatedCondition,
        writer.uint32(298).fork(),
      ).ldelim();
    }
    if (message.UpdatedInvitation !== undefined) {
      Invitation.encode(
        message.UpdatedInvitation,
        writer.uint32(306).fork(),
      ).ldelim();
    }
    if (message.UpdatedSharingMember !== undefined) {
      User.encode(
        message.UpdatedSharingMember,
        writer.uint32(314).fork(),
      ).ldelim();
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientProfileStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientProfileStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.HistoricalPatient = Patient.decode(reader, reader.uint32());
          break;
        case 2:
          message.HistoricalPatientProject = PatientProject.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.EndOfHistoricalPatientProjects = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.HistoricalStudy = Study.decode(reader, reader.uint32());
          break;
        case 5:
          message.EndOfHistoricalHistoricalStudies = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 6:
          message.HistoricalAsset = Asset.decode(reader, reader.uint32());
          break;
        case 7:
          message.EndOfHistoricalHistoricalAssets = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 8:
          message.HistoricalReport = Report.decode(reader, reader.uint32());
          break;
        case 9:
          message.EndOfHistoricalHistoricalReports = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10:
          message.HistoricalTooth = Tooth.decode(reader, reader.uint32());
          break;
        case 11:
          message.EndOfHistoricalHistoricalTeeth = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 12:
          message.HistoricalCondition = Condition.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 13:
          message.EndOfHistoricalHistoricalConditions = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 14:
          message.HistoricalInvitation = Invitation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 15:
          message.EndOfHistoricalHistoricalInvitations = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 16:
          message.HistoricalSharingMember = User.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 17:
          message.EndOfHistoricalHistoricalSharingMembers = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 31:
          message.UpdatedPatient = Patient.decode(reader, reader.uint32());
          break;
        case 32:
          message.UpdatedPatientProject = PatientProject.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 33:
          message.UpdatedStudy = Study.decode(reader, reader.uint32());
          break;
        case 34:
          message.UpdatedAsset = Asset.decode(reader, reader.uint32());
          break;
        case 35:
          message.UpdatedReport = Report.decode(reader, reader.uint32());
          break;
        case 36:
          message.UpdatedTooth = Tooth.decode(reader, reader.uint32());
          break;
        case 37:
          message.UpdatedCondition = Condition.decode(reader, reader.uint32());
          break;
        case 38:
          message.UpdatedInvitation = Invitation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 39:
          message.UpdatedSharingMember = User.decode(reader, reader.uint32());
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientProfileStreamResp {
    return {
      HistoricalPatient: isSet(object.HistoricalPatient)
        ? Patient.fromJSON(object.HistoricalPatient)
        : undefined,
      HistoricalPatientProject: isSet(object.HistoricalPatientProject)
        ? PatientProject.fromJSON(object.HistoricalPatientProject)
        : undefined,
      EndOfHistoricalPatientProjects: isSet(
        object.EndOfHistoricalPatientProjects,
      )
        ? Empty.fromJSON(object.EndOfHistoricalPatientProjects)
        : undefined,
      HistoricalStudy: isSet(object.HistoricalStudy)
        ? Study.fromJSON(object.HistoricalStudy)
        : undefined,
      EndOfHistoricalHistoricalStudies: isSet(
        object.EndOfHistoricalHistoricalStudies,
      )
        ? Empty.fromJSON(object.EndOfHistoricalHistoricalStudies)
        : undefined,
      HistoricalAsset: isSet(object.HistoricalAsset)
        ? Asset.fromJSON(object.HistoricalAsset)
        : undefined,
      EndOfHistoricalHistoricalAssets: isSet(
        object.EndOfHistoricalHistoricalAssets,
      )
        ? Empty.fromJSON(object.EndOfHistoricalHistoricalAssets)
        : undefined,
      HistoricalReport: isSet(object.HistoricalReport)
        ? Report.fromJSON(object.HistoricalReport)
        : undefined,
      EndOfHistoricalHistoricalReports: isSet(
        object.EndOfHistoricalHistoricalReports,
      )
        ? Empty.fromJSON(object.EndOfHistoricalHistoricalReports)
        : undefined,
      HistoricalTooth: isSet(object.HistoricalTooth)
        ? Tooth.fromJSON(object.HistoricalTooth)
        : undefined,
      EndOfHistoricalHistoricalTeeth: isSet(
        object.EndOfHistoricalHistoricalTeeth,
      )
        ? Empty.fromJSON(object.EndOfHistoricalHistoricalTeeth)
        : undefined,
      HistoricalCondition: isSet(object.HistoricalCondition)
        ? Condition.fromJSON(object.HistoricalCondition)
        : undefined,
      EndOfHistoricalHistoricalConditions: isSet(
        object.EndOfHistoricalHistoricalConditions,
      )
        ? Empty.fromJSON(object.EndOfHistoricalHistoricalConditions)
        : undefined,
      HistoricalInvitation: isSet(object.HistoricalInvitation)
        ? Invitation.fromJSON(object.HistoricalInvitation)
        : undefined,
      EndOfHistoricalHistoricalInvitations: isSet(
        object.EndOfHistoricalHistoricalInvitations,
      )
        ? Empty.fromJSON(object.EndOfHistoricalHistoricalInvitations)
        : undefined,
      HistoricalSharingMember: isSet(object.HistoricalSharingMember)
        ? User.fromJSON(object.HistoricalSharingMember)
        : undefined,
      EndOfHistoricalHistoricalSharingMembers: isSet(
        object.EndOfHistoricalHistoricalSharingMembers,
      )
        ? Empty.fromJSON(object.EndOfHistoricalHistoricalSharingMembers)
        : undefined,
      UpdatedPatient: isSet(object.UpdatedPatient)
        ? Patient.fromJSON(object.UpdatedPatient)
        : undefined,
      UpdatedPatientProject: isSet(object.UpdatedPatientProject)
        ? PatientProject.fromJSON(object.UpdatedPatientProject)
        : undefined,
      UpdatedStudy: isSet(object.UpdatedStudy)
        ? Study.fromJSON(object.UpdatedStudy)
        : undefined,
      UpdatedAsset: isSet(object.UpdatedAsset)
        ? Asset.fromJSON(object.UpdatedAsset)
        : undefined,
      UpdatedReport: isSet(object.UpdatedReport)
        ? Report.fromJSON(object.UpdatedReport)
        : undefined,
      UpdatedTooth: isSet(object.UpdatedTooth)
        ? Tooth.fromJSON(object.UpdatedTooth)
        : undefined,
      UpdatedCondition: isSet(object.UpdatedCondition)
        ? Condition.fromJSON(object.UpdatedCondition)
        : undefined,
      UpdatedInvitation: isSet(object.UpdatedInvitation)
        ? Invitation.fromJSON(object.UpdatedInvitation)
        : undefined,
      UpdatedSharingMember: isSet(object.UpdatedSharingMember)
        ? User.fromJSON(object.UpdatedSharingMember)
        : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: PatientProfileStreamResp): unknown {
    const obj: any = {};
    message.HistoricalPatient !== undefined &&
      (obj.HistoricalPatient = message.HistoricalPatient
        ? Patient.toJSON(message.HistoricalPatient)
        : undefined);
    message.HistoricalPatientProject !== undefined &&
      (obj.HistoricalPatientProject = message.HistoricalPatientProject
        ? PatientProject.toJSON(message.HistoricalPatientProject)
        : undefined);
    message.EndOfHistoricalPatientProjects !== undefined &&
      (obj.EndOfHistoricalPatientProjects =
        message.EndOfHistoricalPatientProjects
          ? Empty.toJSON(message.EndOfHistoricalPatientProjects)
          : undefined);
    message.HistoricalStudy !== undefined &&
      (obj.HistoricalStudy = message.HistoricalStudy
        ? Study.toJSON(message.HistoricalStudy)
        : undefined);
    message.EndOfHistoricalHistoricalStudies !== undefined &&
      (obj.EndOfHistoricalHistoricalStudies =
        message.EndOfHistoricalHistoricalStudies
          ? Empty.toJSON(message.EndOfHistoricalHistoricalStudies)
          : undefined);
    message.HistoricalAsset !== undefined &&
      (obj.HistoricalAsset = message.HistoricalAsset
        ? Asset.toJSON(message.HistoricalAsset)
        : undefined);
    message.EndOfHistoricalHistoricalAssets !== undefined &&
      (obj.EndOfHistoricalHistoricalAssets =
        message.EndOfHistoricalHistoricalAssets
          ? Empty.toJSON(message.EndOfHistoricalHistoricalAssets)
          : undefined);
    message.HistoricalReport !== undefined &&
      (obj.HistoricalReport = message.HistoricalReport
        ? Report.toJSON(message.HistoricalReport)
        : undefined);
    message.EndOfHistoricalHistoricalReports !== undefined &&
      (obj.EndOfHistoricalHistoricalReports =
        message.EndOfHistoricalHistoricalReports
          ? Empty.toJSON(message.EndOfHistoricalHistoricalReports)
          : undefined);
    message.HistoricalTooth !== undefined &&
      (obj.HistoricalTooth = message.HistoricalTooth
        ? Tooth.toJSON(message.HistoricalTooth)
        : undefined);
    message.EndOfHistoricalHistoricalTeeth !== undefined &&
      (obj.EndOfHistoricalHistoricalTeeth =
        message.EndOfHistoricalHistoricalTeeth
          ? Empty.toJSON(message.EndOfHistoricalHistoricalTeeth)
          : undefined);
    message.HistoricalCondition !== undefined &&
      (obj.HistoricalCondition = message.HistoricalCondition
        ? Condition.toJSON(message.HistoricalCondition)
        : undefined);
    message.EndOfHistoricalHistoricalConditions !== undefined &&
      (obj.EndOfHistoricalHistoricalConditions =
        message.EndOfHistoricalHistoricalConditions
          ? Empty.toJSON(message.EndOfHistoricalHistoricalConditions)
          : undefined);
    message.HistoricalInvitation !== undefined &&
      (obj.HistoricalInvitation = message.HistoricalInvitation
        ? Invitation.toJSON(message.HistoricalInvitation)
        : undefined);
    message.EndOfHistoricalHistoricalInvitations !== undefined &&
      (obj.EndOfHistoricalHistoricalInvitations =
        message.EndOfHistoricalHistoricalInvitations
          ? Empty.toJSON(message.EndOfHistoricalHistoricalInvitations)
          : undefined);
    message.HistoricalSharingMember !== undefined &&
      (obj.HistoricalSharingMember = message.HistoricalSharingMember
        ? User.toJSON(message.HistoricalSharingMember)
        : undefined);
    message.EndOfHistoricalHistoricalSharingMembers !== undefined &&
      (obj.EndOfHistoricalHistoricalSharingMembers =
        message.EndOfHistoricalHistoricalSharingMembers
          ? Empty.toJSON(message.EndOfHistoricalHistoricalSharingMembers)
          : undefined);
    message.UpdatedPatient !== undefined &&
      (obj.UpdatedPatient = message.UpdatedPatient
        ? Patient.toJSON(message.UpdatedPatient)
        : undefined);
    message.UpdatedPatientProject !== undefined &&
      (obj.UpdatedPatientProject = message.UpdatedPatientProject
        ? PatientProject.toJSON(message.UpdatedPatientProject)
        : undefined);
    message.UpdatedStudy !== undefined &&
      (obj.UpdatedStudy = message.UpdatedStudy
        ? Study.toJSON(message.UpdatedStudy)
        : undefined);
    message.UpdatedAsset !== undefined &&
      (obj.UpdatedAsset = message.UpdatedAsset
        ? Asset.toJSON(message.UpdatedAsset)
        : undefined);
    message.UpdatedReport !== undefined &&
      (obj.UpdatedReport = message.UpdatedReport
        ? Report.toJSON(message.UpdatedReport)
        : undefined);
    message.UpdatedTooth !== undefined &&
      (obj.UpdatedTooth = message.UpdatedTooth
        ? Tooth.toJSON(message.UpdatedTooth)
        : undefined);
    message.UpdatedCondition !== undefined &&
      (obj.UpdatedCondition = message.UpdatedCondition
        ? Condition.toJSON(message.UpdatedCondition)
        : undefined);
    message.UpdatedInvitation !== undefined &&
      (obj.UpdatedInvitation = message.UpdatedInvitation
        ? Invitation.toJSON(message.UpdatedInvitation)
        : undefined);
    message.UpdatedSharingMember !== undefined &&
      (obj.UpdatedSharingMember = message.UpdatedSharingMember
        ? User.toJSON(message.UpdatedSharingMember)
        : undefined);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientProfileStreamResp>, I>>(
    object: I,
  ): PatientProfileStreamResp {
    const message = createBasePatientProfileStreamResp();
    message.HistoricalPatient =
      object.HistoricalPatient !== undefined &&
      object.HistoricalPatient !== null
        ? Patient.fromPartial(object.HistoricalPatient)
        : undefined;
    message.HistoricalPatientProject =
      object.HistoricalPatientProject !== undefined &&
      object.HistoricalPatientProject !== null
        ? PatientProject.fromPartial(object.HistoricalPatientProject)
        : undefined;
    message.EndOfHistoricalPatientProjects =
      object.EndOfHistoricalPatientProjects !== undefined &&
      object.EndOfHistoricalPatientProjects !== null
        ? Empty.fromPartial(object.EndOfHistoricalPatientProjects)
        : undefined;
    message.HistoricalStudy =
      object.HistoricalStudy !== undefined && object.HistoricalStudy !== null
        ? Study.fromPartial(object.HistoricalStudy)
        : undefined;
    message.EndOfHistoricalHistoricalStudies =
      object.EndOfHistoricalHistoricalStudies !== undefined &&
      object.EndOfHistoricalHistoricalStudies !== null
        ? Empty.fromPartial(object.EndOfHistoricalHistoricalStudies)
        : undefined;
    message.HistoricalAsset =
      object.HistoricalAsset !== undefined && object.HistoricalAsset !== null
        ? Asset.fromPartial(object.HistoricalAsset)
        : undefined;
    message.EndOfHistoricalHistoricalAssets =
      object.EndOfHistoricalHistoricalAssets !== undefined &&
      object.EndOfHistoricalHistoricalAssets !== null
        ? Empty.fromPartial(object.EndOfHistoricalHistoricalAssets)
        : undefined;
    message.HistoricalReport =
      object.HistoricalReport !== undefined && object.HistoricalReport !== null
        ? Report.fromPartial(object.HistoricalReport)
        : undefined;
    message.EndOfHistoricalHistoricalReports =
      object.EndOfHistoricalHistoricalReports !== undefined &&
      object.EndOfHistoricalHistoricalReports !== null
        ? Empty.fromPartial(object.EndOfHistoricalHistoricalReports)
        : undefined;
    message.HistoricalTooth =
      object.HistoricalTooth !== undefined && object.HistoricalTooth !== null
        ? Tooth.fromPartial(object.HistoricalTooth)
        : undefined;
    message.EndOfHistoricalHistoricalTeeth =
      object.EndOfHistoricalHistoricalTeeth !== undefined &&
      object.EndOfHistoricalHistoricalTeeth !== null
        ? Empty.fromPartial(object.EndOfHistoricalHistoricalTeeth)
        : undefined;
    message.HistoricalCondition =
      object.HistoricalCondition !== undefined &&
      object.HistoricalCondition !== null
        ? Condition.fromPartial(object.HistoricalCondition)
        : undefined;
    message.EndOfHistoricalHistoricalConditions =
      object.EndOfHistoricalHistoricalConditions !== undefined &&
      object.EndOfHistoricalHistoricalConditions !== null
        ? Empty.fromPartial(object.EndOfHistoricalHistoricalConditions)
        : undefined;
    message.HistoricalInvitation =
      object.HistoricalInvitation !== undefined &&
      object.HistoricalInvitation !== null
        ? Invitation.fromPartial(object.HistoricalInvitation)
        : undefined;
    message.EndOfHistoricalHistoricalInvitations =
      object.EndOfHistoricalHistoricalInvitations !== undefined &&
      object.EndOfHistoricalHistoricalInvitations !== null
        ? Empty.fromPartial(object.EndOfHistoricalHistoricalInvitations)
        : undefined;
    message.HistoricalSharingMember =
      object.HistoricalSharingMember !== undefined &&
      object.HistoricalSharingMember !== null
        ? User.fromPartial(object.HistoricalSharingMember)
        : undefined;
    message.EndOfHistoricalHistoricalSharingMembers =
      object.EndOfHistoricalHistoricalSharingMembers !== undefined &&
      object.EndOfHistoricalHistoricalSharingMembers !== null
        ? Empty.fromPartial(object.EndOfHistoricalHistoricalSharingMembers)
        : undefined;
    message.UpdatedPatient =
      object.UpdatedPatient !== undefined && object.UpdatedPatient !== null
        ? Patient.fromPartial(object.UpdatedPatient)
        : undefined;
    message.UpdatedPatientProject =
      object.UpdatedPatientProject !== undefined &&
      object.UpdatedPatientProject !== null
        ? PatientProject.fromPartial(object.UpdatedPatientProject)
        : undefined;
    message.UpdatedStudy =
      object.UpdatedStudy !== undefined && object.UpdatedStudy !== null
        ? Study.fromPartial(object.UpdatedStudy)
        : undefined;
    message.UpdatedAsset =
      object.UpdatedAsset !== undefined && object.UpdatedAsset !== null
        ? Asset.fromPartial(object.UpdatedAsset)
        : undefined;
    message.UpdatedReport =
      object.UpdatedReport !== undefined && object.UpdatedReport !== null
        ? Report.fromPartial(object.UpdatedReport)
        : undefined;
    message.UpdatedTooth =
      object.UpdatedTooth !== undefined && object.UpdatedTooth !== null
        ? Tooth.fromPartial(object.UpdatedTooth)
        : undefined;
    message.UpdatedCondition =
      object.UpdatedCondition !== undefined && object.UpdatedCondition !== null
        ? Condition.fromPartial(object.UpdatedCondition)
        : undefined;
    message.UpdatedInvitation =
      object.UpdatedInvitation !== undefined &&
      object.UpdatedInvitation !== null
        ? Invitation.fromPartial(object.UpdatedInvitation)
        : undefined;
    message.UpdatedSharingMember =
      object.UpdatedSharingMember !== undefined &&
      object.UpdatedSharingMember !== null
        ? User.fromPartial(object.UpdatedSharingMember)
        : undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBasePatientListStreamReq(): PatientListStreamReq {
  return {
    ResumeToken: '',
    OrganizationID: '',
    SearchString: '',
    SharedWithMe: false,
    DoctorIDs: [],
    StartFromPatientID: '',
    Limit: 0,
  };
}

export const PatientListStreamReq = {
  encode(
    message: PatientListStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ResumeToken !== '') {
      writer.uint32(10).string(message.ResumeToken);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(18).string(message.OrganizationID);
    }
    if (message.SearchString !== '') {
      writer.uint32(26).string(message.SearchString);
    }
    if (message.SharedWithMe === true) {
      writer.uint32(88).bool(message.SharedWithMe);
    }
    for (const v of message.DoctorIDs) {
      writer.uint32(98).string(v!);
    }
    if (message.StartFromPatientID !== '') {
      writer.uint32(802).string(message.StartFromPatientID);
    }
    if (message.Limit !== 0) {
      writer.uint32(808).int64(message.Limit);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ResumeToken = reader.string();
          break;
        case 2:
          message.OrganizationID = reader.string();
          break;
        case 3:
          message.SearchString = reader.string();
          break;
        case 11:
          message.SharedWithMe = reader.bool();
          break;
        case 12:
          message.DoctorIDs.push(reader.string());
          break;
        case 100:
          message.StartFromPatientID = reader.string();
          break;
        case 101:
          message.Limit = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamReq {
    return {
      ResumeToken: isSet(object.ResumeToken) ? String(object.ResumeToken) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      SearchString: isSet(object.SearchString)
        ? String(object.SearchString)
        : '',
      SharedWithMe: isSet(object.SharedWithMe)
        ? Boolean(object.SharedWithMe)
        : false,
      DoctorIDs: Array.isArray(object?.DoctorIDs)
        ? object.DoctorIDs.map((e: any) => String(e))
        : [],
      StartFromPatientID: isSet(object.StartFromPatientID)
        ? String(object.StartFromPatientID)
        : '',
      Limit: isSet(object.Limit) ? Number(object.Limit) : 0,
    };
  },

  toJSON(message: PatientListStreamReq): unknown {
    const obj: any = {};
    message.ResumeToken !== undefined &&
      (obj.ResumeToken = message.ResumeToken);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.SearchString !== undefined &&
      (obj.SearchString = message.SearchString);
    message.SharedWithMe !== undefined &&
      (obj.SharedWithMe = message.SharedWithMe);
    if (message.DoctorIDs) {
      obj.DoctorIDs = message.DoctorIDs.map((e) => e);
    } else {
      obj.DoctorIDs = [];
    }
    message.StartFromPatientID !== undefined &&
      (obj.StartFromPatientID = message.StartFromPatientID);
    message.Limit !== undefined && (obj.Limit = Math.round(message.Limit));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientListStreamReq>, I>>(
    object: I,
  ): PatientListStreamReq {
    const message = createBasePatientListStreamReq();
    message.ResumeToken = object.ResumeToken ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.SearchString = object.SearchString ?? '';
    message.SharedWithMe = object.SharedWithMe ?? false;
    message.DoctorIDs = object.DoctorIDs?.map((e) => e) || [];
    message.StartFromPatientID = object.StartFromPatientID ?? '';
    message.Limit = object.Limit ?? 0;
    return message;
  },
};

function createBasePatientListStreamResp(): PatientListStreamResp {
  return {
    ResumeToken: '',
    InitialPatientsListItems: undefined,
    PatientUpdated: undefined,
    TotalCount: undefined,
    SharedCount: undefined,
    Ping: undefined,
  };
}

export const PatientListStreamResp = {
  encode(
    message: PatientListStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ResumeToken !== '') {
      writer.uint32(10).string(message.ResumeToken);
    }
    if (message.InitialPatientsListItems !== undefined) {
      PatientListStreamResp_PatientsListItemsUpdate.encode(
        message.InitialPatientsListItems,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    if (message.PatientUpdated !== undefined) {
      PatientListStreamResp_PatientItem.encode(
        message.PatientUpdated,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.TotalCount !== undefined) {
      writer.uint32(160).int64(message.TotalCount);
    }
    if (message.SharedCount !== undefined) {
      writer.uint32(176).int64(message.SharedCount);
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ResumeToken = reader.string();
          break;
        case 10:
          message.InitialPatientsListItems =
            PatientListStreamResp_PatientsListItemsUpdate.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 11:
          message.PatientUpdated = PatientListStreamResp_PatientItem.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 20:
          message.TotalCount = longToNumber(reader.int64() as Long);
          break;
        case 22:
          message.SharedCount = longToNumber(reader.int64() as Long);
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamResp {
    return {
      ResumeToken: isSet(object.ResumeToken) ? String(object.ResumeToken) : '',
      InitialPatientsListItems: isSet(object.InitialPatientsListItems)
        ? PatientListStreamResp_PatientsListItemsUpdate.fromJSON(
            object.InitialPatientsListItems,
          )
        : undefined,
      PatientUpdated: isSet(object.PatientUpdated)
        ? PatientListStreamResp_PatientItem.fromJSON(object.PatientUpdated)
        : undefined,
      TotalCount: isSet(object.TotalCount)
        ? Number(object.TotalCount)
        : undefined,
      SharedCount: isSet(object.SharedCount)
        ? Number(object.SharedCount)
        : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: PatientListStreamResp): unknown {
    const obj: any = {};
    message.ResumeToken !== undefined &&
      (obj.ResumeToken = message.ResumeToken);
    message.InitialPatientsListItems !== undefined &&
      (obj.InitialPatientsListItems = message.InitialPatientsListItems
        ? PatientListStreamResp_PatientsListItemsUpdate.toJSON(
            message.InitialPatientsListItems,
          )
        : undefined);
    message.PatientUpdated !== undefined &&
      (obj.PatientUpdated = message.PatientUpdated
        ? PatientListStreamResp_PatientItem.toJSON(message.PatientUpdated)
        : undefined);
    message.TotalCount !== undefined &&
      (obj.TotalCount = Math.round(message.TotalCount));
    message.SharedCount !== undefined &&
      (obj.SharedCount = Math.round(message.SharedCount));
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientListStreamResp>, I>>(
    object: I,
  ): PatientListStreamResp {
    const message = createBasePatientListStreamResp();
    message.ResumeToken = object.ResumeToken ?? '';
    message.InitialPatientsListItems =
      object.InitialPatientsListItems !== undefined &&
      object.InitialPatientsListItems !== null
        ? PatientListStreamResp_PatientsListItemsUpdate.fromPartial(
            object.InitialPatientsListItems,
          )
        : undefined;
    message.PatientUpdated =
      object.PatientUpdated !== undefined && object.PatientUpdated !== null
        ? PatientListStreamResp_PatientItem.fromPartial(object.PatientUpdated)
        : undefined;
    message.TotalCount = object.TotalCount ?? undefined;
    message.SharedCount = object.SharedCount ?? undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBasePatientListStreamResp_PatientItem(): PatientListStreamResp_PatientItem {
  return { Patient: undefined, Counts: [], RelatedUsers: [] };
}

export const PatientListStreamResp_PatientItem = {
  encode(
    message: PatientListStreamResp_PatientItem,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Counts) {
      PatientListStreamResp_PatientItem_StudyCount.encode(
        v!,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    for (const v of message.RelatedUsers) {
      User.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamResp_PatientItem {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamResp_PatientItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        case 2:
          message.Counts.push(
            PatientListStreamResp_PatientItem_StudyCount.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        case 3:
          message.RelatedUsers.push(User.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamResp_PatientItem {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
      Counts: Array.isArray(object?.Counts)
        ? object.Counts.map((e: any) =>
            PatientListStreamResp_PatientItem_StudyCount.fromJSON(e),
          )
        : [],
      RelatedUsers: Array.isArray(object?.RelatedUsers)
        ? object.RelatedUsers.map((e: any) => User.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PatientListStreamResp_PatientItem): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    if (message.Counts) {
      obj.Counts = message.Counts.map((e) =>
        e ? PatientListStreamResp_PatientItem_StudyCount.toJSON(e) : undefined,
      );
    } else {
      obj.Counts = [];
    }
    if (message.RelatedUsers) {
      obj.RelatedUsers = message.RelatedUsers.map((e) =>
        e ? User.toJSON(e) : undefined,
      );
    } else {
      obj.RelatedUsers = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<PatientListStreamResp_PatientItem>, I>,
  >(object: I): PatientListStreamResp_PatientItem {
    const message = createBasePatientListStreamResp_PatientItem();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    message.Counts =
      object.Counts?.map((e) =>
        PatientListStreamResp_PatientItem_StudyCount.fromPartial(e),
      ) || [];
    message.RelatedUsers =
      object.RelatedUsers?.map((e) => User.fromPartial(e)) || [];
    return message;
  },
};

function createBasePatientListStreamResp_PatientItem_StudyCount(): PatientListStreamResp_PatientItem_StudyCount {
  return { Modality: 0, Count: 0 };
}

export const PatientListStreamResp_PatientItem_StudyCount = {
  encode(
    message: PatientListStreamResp_PatientItem_StudyCount,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Modality !== 0) {
      writer.uint32(8).int32(message.Modality);
    }
    if (message.Count !== 0) {
      writer.uint32(16).int64(message.Count);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamResp_PatientItem_StudyCount {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamResp_PatientItem_StudyCount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Modality = reader.int32() as any;
          break;
        case 2:
          message.Count = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamResp_PatientItem_StudyCount {
    return {
      Modality: isSet(object.Modality) ? studyTypeFromJSON(object.Modality) : 0,
      Count: isSet(object.Count) ? Number(object.Count) : 0,
    };
  },

  toJSON(message: PatientListStreamResp_PatientItem_StudyCount): unknown {
    const obj: any = {};
    message.Modality !== undefined &&
      (obj.Modality = studyTypeToJSON(message.Modality));
    message.Count !== undefined && (obj.Count = Math.round(message.Count));
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<PatientListStreamResp_PatientItem_StudyCount>,
      I
    >,
  >(object: I): PatientListStreamResp_PatientItem_StudyCount {
    const message = createBasePatientListStreamResp_PatientItem_StudyCount();
    message.Modality = object.Modality ?? 0;
    message.Count = object.Count ?? 0;
    return message;
  },
};

function createBasePatientListStreamResp_PatientsListItemsUpdate(): PatientListStreamResp_PatientsListItemsUpdate {
  return { Patients: [] };
}

export const PatientListStreamResp_PatientsListItemsUpdate = {
  encode(
    message: PatientListStreamResp_PatientsListItemsUpdate,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Patients) {
      PatientListStreamResp_PatientItem.encode(
        v!,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientListStreamResp_PatientsListItemsUpdate {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientListStreamResp_PatientsListItemsUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patients.push(
            PatientListStreamResp_PatientItem.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientListStreamResp_PatientsListItemsUpdate {
    return {
      Patients: Array.isArray(object?.Patients)
        ? object.Patients.map((e: any) =>
            PatientListStreamResp_PatientItem.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: PatientListStreamResp_PatientsListItemsUpdate): unknown {
    const obj: any = {};
    if (message.Patients) {
      obj.Patients = message.Patients.map((e) =>
        e ? PatientListStreamResp_PatientItem.toJSON(e) : undefined,
      );
    } else {
      obj.Patients = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<PatientListStreamResp_PatientsListItemsUpdate>,
      I
    >,
  >(object: I): PatientListStreamResp_PatientsListItemsUpdate {
    const message = createBasePatientListStreamResp_PatientsListItemsUpdate();
    message.Patients =
      object.Patients?.map((e) =>
        PatientListStreamResp_PatientItem.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseCreatePatientReq(): CreatePatientReq {
  return {
    OrganizationID: '',
    PersonalData: undefined,
    Gender: 0,
    ExternalID: '',
    TreatingDoctorIDs: [],
  };
}

export const CreatePatientReq = {
  encode(
    message: CreatePatientReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.PersonalData !== undefined) {
      PersonalData.encode(
        message.PersonalData,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Gender !== 0) {
      writer.uint32(24).int32(message.Gender);
    }
    if (message.ExternalID !== '') {
      writer.uint32(34).string(message.ExternalID);
    }
    for (const v of message.TreatingDoctorIDs) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreatePatientReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePatientReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.PersonalData = PersonalData.decode(reader, reader.uint32());
          break;
        case 3:
          message.Gender = reader.int32() as any;
          break;
        case 4:
          message.ExternalID = reader.string();
          break;
        case 5:
          message.TreatingDoctorIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreatePatientReq {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      PersonalData: isSet(object.PersonalData)
        ? PersonalData.fromJSON(object.PersonalData)
        : undefined,
      Gender: isSet(object.Gender) ? genderFromJSON(object.Gender) : 0,
      ExternalID: isSet(object.ExternalID) ? String(object.ExternalID) : '',
      TreatingDoctorIDs: Array.isArray(object?.TreatingDoctorIDs)
        ? object.TreatingDoctorIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: CreatePatientReq): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.PersonalData !== undefined &&
      (obj.PersonalData = message.PersonalData
        ? PersonalData.toJSON(message.PersonalData)
        : undefined);
    message.Gender !== undefined && (obj.Gender = genderToJSON(message.Gender));
    message.ExternalID !== undefined && (obj.ExternalID = message.ExternalID);
    if (message.TreatingDoctorIDs) {
      obj.TreatingDoctorIDs = message.TreatingDoctorIDs.map((e) => e);
    } else {
      obj.TreatingDoctorIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreatePatientReq>, I>>(
    object: I,
  ): CreatePatientReq {
    const message = createBaseCreatePatientReq();
    message.OrganizationID = object.OrganizationID ?? '';
    message.PersonalData =
      object.PersonalData !== undefined && object.PersonalData !== null
        ? PersonalData.fromPartial(object.PersonalData)
        : undefined;
    message.Gender = object.Gender ?? 0;
    message.ExternalID = object.ExternalID ?? '';
    message.TreatingDoctorIDs = object.TreatingDoctorIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreatePatientResp(): CreatePatientResp {
  return { Patient: undefined };
}

export const CreatePatientResp = {
  encode(
    message: CreatePatientResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreatePatientResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePatientResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreatePatientResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: CreatePatientResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreatePatientResp>, I>>(
    object: I,
  ): CreatePatientResp {
    const message = createBaseCreatePatientResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetPatientPersonalDataReq(): SetPatientPersonalDataReq {
  return { PatientID: '', PersonalData: undefined };
}

export const SetPatientPersonalDataReq = {
  encode(
    message: SetPatientPersonalDataReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.PersonalData !== undefined) {
      PersonalData.encode(
        message.PersonalData,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientPersonalDataReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientPersonalDataReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.PersonalData = PersonalData.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientPersonalDataReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      PersonalData: isSet(object.PersonalData)
        ? PersonalData.fromJSON(object.PersonalData)
        : undefined,
    };
  },

  toJSON(message: SetPatientPersonalDataReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.PersonalData !== undefined &&
      (obj.PersonalData = message.PersonalData
        ? PersonalData.toJSON(message.PersonalData)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientPersonalDataReq>, I>>(
    object: I,
  ): SetPatientPersonalDataReq {
    const message = createBaseSetPatientPersonalDataReq();
    message.PatientID = object.PatientID ?? '';
    message.PersonalData =
      object.PersonalData !== undefined && object.PersonalData !== null
        ? PersonalData.fromPartial(object.PersonalData)
        : undefined;
    return message;
  },
};

function createBaseSetPatientPersonalDataResp(): SetPatientPersonalDataResp {
  return { Patient: undefined };
}

export const SetPatientPersonalDataResp = {
  encode(
    message: SetPatientPersonalDataResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientPersonalDataResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientPersonalDataResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientPersonalDataResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: SetPatientPersonalDataResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientPersonalDataResp>, I>>(
    object: I,
  ): SetPatientPersonalDataResp {
    const message = createBaseSetPatientPersonalDataResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetPatientExternalIDReq(): SetPatientExternalIDReq {
  return { PatientID: '', ExternalID: '' };
}

export const SetPatientExternalIDReq = {
  encode(
    message: SetPatientExternalIDReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.ExternalID !== '') {
      writer.uint32(18).string(message.ExternalID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientExternalIDReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientExternalIDReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.ExternalID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientExternalIDReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      ExternalID: isSet(object.ExternalID) ? String(object.ExternalID) : '',
    };
  },

  toJSON(message: SetPatientExternalIDReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.ExternalID !== undefined && (obj.ExternalID = message.ExternalID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientExternalIDReq>, I>>(
    object: I,
  ): SetPatientExternalIDReq {
    const message = createBaseSetPatientExternalIDReq();
    message.PatientID = object.PatientID ?? '';
    message.ExternalID = object.ExternalID ?? '';
    return message;
  },
};

function createBaseSetPatientExternalIDResp(): SetPatientExternalIDResp {
  return { Patient: undefined };
}

export const SetPatientExternalIDResp = {
  encode(
    message: SetPatientExternalIDResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientExternalIDResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientExternalIDResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientExternalIDResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: SetPatientExternalIDResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientExternalIDResp>, I>>(
    object: I,
  ): SetPatientExternalIDResp {
    const message = createBaseSetPatientExternalIDResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetPatientGenderReq(): SetPatientGenderReq {
  return { PatientID: '', Gender: 0 };
}

export const SetPatientGenderReq = {
  encode(
    message: SetPatientGenderReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.Gender !== 0) {
      writer.uint32(16).int32(message.Gender);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPatientGenderReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientGenderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.Gender = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientGenderReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      Gender: isSet(object.Gender) ? genderFromJSON(object.Gender) : 0,
    };
  },

  toJSON(message: SetPatientGenderReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.Gender !== undefined && (obj.Gender = genderToJSON(message.Gender));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientGenderReq>, I>>(
    object: I,
  ): SetPatientGenderReq {
    const message = createBaseSetPatientGenderReq();
    message.PatientID = object.PatientID ?? '';
    message.Gender = object.Gender ?? 0;
    return message;
  },
};

function createBaseSetPatientGenderResp(): SetPatientGenderResp {
  return { Patient: undefined };
}

export const SetPatientGenderResp = {
  encode(
    message: SetPatientGenderResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientGenderResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientGenderResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientGenderResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: SetPatientGenderResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientGenderResp>, I>>(
    object: I,
  ): SetPatientGenderResp {
    const message = createBaseSetPatientGenderResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseSetPatientStatusReq(): SetPatientStatusReq {
  return { PatientID: '', Status: 0 };
}

export const SetPatientStatusReq = {
  encode(
    message: SetPatientStatusReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    if (message.Status !== 0) {
      writer.uint32(16).int32(message.Status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPatientStatusReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientStatusReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        case 2:
          message.Status = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientStatusReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      Status: isSet(object.Status) ? patientStatusFromJSON(object.Status) : 0,
    };
  },

  toJSON(message: SetPatientStatusReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.Status !== undefined &&
      (obj.Status = patientStatusToJSON(message.Status));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientStatusReq>, I>>(
    object: I,
  ): SetPatientStatusReq {
    const message = createBaseSetPatientStatusReq();
    message.PatientID = object.PatientID ?? '';
    message.Status = object.Status ?? 0;
    return message;
  },
};

function createBaseSetPatientStatusResp(): SetPatientStatusResp {
  return { Patient: undefined };
}

export const SetPatientStatusResp = {
  encode(
    message: SetPatientStatusResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetPatientStatusResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPatientStatusResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetPatientStatusResp {
    return {
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
    };
  },

  toJSON(message: SetPatientStatusResp): unknown {
    const obj: any = {};
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetPatientStatusResp>, I>>(
    object: I,
  ): SetPatientStatusResp {
    const message = createBaseSetPatientStatusResp();
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    return message;
  },
};

function createBaseDeletePatientReq(): DeletePatientReq {
  return { PatientID: '' };
}

export const DeletePatientReq = {
  encode(
    message: DeletePatientReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PatientID !== '') {
      writer.uint32(10).string(message.PatientID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeletePatientReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePatientReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PatientID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeletePatientReq {
    return {
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
    };
  },

  toJSON(message: DeletePatientReq): unknown {
    const obj: any = {};
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeletePatientReq>, I>>(
    object: I,
  ): DeletePatientReq {
    const message = createBaseDeletePatientReq();
    message.PatientID = object.PatientID ?? '';
    return message;
  },
};

function createBaseDeletePatientResp(): DeletePatientResp {
  return {
    DeletedPatientID: '',
    DeletedStudyIDs: [],
    DeletedReportIDs: [],
    DeletedToothIDs: [],
    DeletedConditionIDs: [],
    DeletedAssetIDs: [],
    DeletedInvitationIDs: [],
  };
}

export const DeletePatientResp = {
  encode(
    message: DeletePatientResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DeletedPatientID !== '') {
      writer.uint32(10).string(message.DeletedPatientID);
    }
    for (const v of message.DeletedStudyIDs) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.DeletedReportIDs) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.DeletedToothIDs) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.DeletedConditionIDs) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.DeletedAssetIDs) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.DeletedInvitationIDs) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeletePatientResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletePatientResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DeletedPatientID = reader.string();
          break;
        case 2:
          message.DeletedStudyIDs.push(reader.string());
          break;
        case 3:
          message.DeletedReportIDs.push(reader.string());
          break;
        case 4:
          message.DeletedToothIDs.push(reader.string());
          break;
        case 5:
          message.DeletedConditionIDs.push(reader.string());
          break;
        case 6:
          message.DeletedAssetIDs.push(reader.string());
          break;
        case 7:
          message.DeletedInvitationIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeletePatientResp {
    return {
      DeletedPatientID: isSet(object.DeletedPatientID)
        ? String(object.DeletedPatientID)
        : '',
      DeletedStudyIDs: Array.isArray(object?.DeletedStudyIDs)
        ? object.DeletedStudyIDs.map((e: any) => String(e))
        : [],
      DeletedReportIDs: Array.isArray(object?.DeletedReportIDs)
        ? object.DeletedReportIDs.map((e: any) => String(e))
        : [],
      DeletedToothIDs: Array.isArray(object?.DeletedToothIDs)
        ? object.DeletedToothIDs.map((e: any) => String(e))
        : [],
      DeletedConditionIDs: Array.isArray(object?.DeletedConditionIDs)
        ? object.DeletedConditionIDs.map((e: any) => String(e))
        : [],
      DeletedAssetIDs: Array.isArray(object?.DeletedAssetIDs)
        ? object.DeletedAssetIDs.map((e: any) => String(e))
        : [],
      DeletedInvitationIDs: Array.isArray(object?.DeletedInvitationIDs)
        ? object.DeletedInvitationIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: DeletePatientResp): unknown {
    const obj: any = {};
    message.DeletedPatientID !== undefined &&
      (obj.DeletedPatientID = message.DeletedPatientID);
    if (message.DeletedStudyIDs) {
      obj.DeletedStudyIDs = message.DeletedStudyIDs.map((e) => e);
    } else {
      obj.DeletedStudyIDs = [];
    }
    if (message.DeletedReportIDs) {
      obj.DeletedReportIDs = message.DeletedReportIDs.map((e) => e);
    } else {
      obj.DeletedReportIDs = [];
    }
    if (message.DeletedToothIDs) {
      obj.DeletedToothIDs = message.DeletedToothIDs.map((e) => e);
    } else {
      obj.DeletedToothIDs = [];
    }
    if (message.DeletedConditionIDs) {
      obj.DeletedConditionIDs = message.DeletedConditionIDs.map((e) => e);
    } else {
      obj.DeletedConditionIDs = [];
    }
    if (message.DeletedAssetIDs) {
      obj.DeletedAssetIDs = message.DeletedAssetIDs.map((e) => e);
    } else {
      obj.DeletedAssetIDs = [];
    }
    if (message.DeletedInvitationIDs) {
      obj.DeletedInvitationIDs = message.DeletedInvitationIDs.map((e) => e);
    } else {
      obj.DeletedInvitationIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeletePatientResp>, I>>(
    object: I,
  ): DeletePatientResp {
    const message = createBaseDeletePatientResp();
    message.DeletedPatientID = object.DeletedPatientID ?? '';
    message.DeletedStudyIDs = object.DeletedStudyIDs?.map((e) => e) || [];
    message.DeletedReportIDs = object.DeletedReportIDs?.map((e) => e) || [];
    message.DeletedToothIDs = object.DeletedToothIDs?.map((e) => e) || [];
    message.DeletedConditionIDs =
      object.DeletedConditionIDs?.map((e) => e) || [];
    message.DeletedAssetIDs = object.DeletedAssetIDs?.map((e) => e) || [];
    message.DeletedInvitationIDs =
      object.DeletedInvitationIDs?.map((e) => e) || [];
    return message;
  },
};

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: PatientID
 * - type: NotFoundError, Entity: patient (if patient not found by PatientID)
 * - type: BadRequestError, Reason: patient deleted (if remote patient found by PatientID)
 */
export interface PatientService {
  /**
   * Get stream with information about patient, his projects, sharing members, invitations, studies and reports
   *
   * Permission: `PatientPermissions.CanView`
   */
  PatientProfileStream(
    request: DeepPartial<PatientProfileStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<PatientProfileStreamResp>;
  /**
   * Get list of patients from available organizations
   *
   * Errors:
   * - type: InvalidArgumentValueError, Argument: PatientStatus - (if `ListPatientsReq.Filters.PatientStatuses` contains `PatientStatusInvalidValue`)
   * - type: InvalidArgumentValueError, Argument: StudyType - (if `ListPatientsReq.Filters.StudyTypes` contains `StudyTypeInvalidValue`)
   */
  PatientListStream(
    request: DeepPartial<PatientListStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<PatientListStreamResp>;
  /**
   * Create new patient
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: OrganizationID/PersonalData/FirstName/LastName/ExternalID/TreatingDoctorID - (if the corresponding argument is empty)
   * - type: BadRequestError, Reason: organization deleted (if remote organization found by `CreatePatientReq.OrganizationID`)
   * - type: InvalidArgumentValueError, Argument: DateOfBirth - (if `CreatePatientReq.PersonalData.DateOfBirth` is not in the correct format ("2006-01-25"))
   * - type: PermissionDeniedError, Reason: you do not have any organization role
   * - type: BadRequestError, Reason: treating doctor does not have organization roles
   */
  CreatePatient(
    request: DeepPartial<CreatePatientReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreatePatientResp>;
  /**
   * Set personal data
   *
   * Permission: `PatientPermissions.CanEditPersonalData`
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: PersonalData/FirstName/LastName - (if the corresponding argument is empty)
   */
  SetPatientPersonalData(
    request: DeepPartial<SetPatientPersonalDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientPersonalDataResp>;
  /**
   * Set externalID
   *
   * Permission: `PatientPermissions.CanEditPatient`
   *
   * Errors:
   * - type: RequiredArgumentMissingError, Argument: ExternalID
   */
  SetPatientExternalID(
    request: DeepPartial<SetPatientExternalIDReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientExternalIDResp>;
  /**
   * Set gender
   *
   * Permission: `PatientPermissions.CanEditPatient`
   */
  SetPatientGender(
    request: DeepPartial<SetPatientGenderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientGenderResp>;
  /**
   * Set patient status
   *
   * Permission: `PatientPermissions.CanEditPatient`
   *
   * Errors:
   * - type: InvalidArgumentValueError, Argument: Status - (if `SetPatientStatusReq.Status` == `PatientStatusInvalidValue`)
   */
  SetPatientStatus(
    request: DeepPartial<SetPatientStatusReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientStatusResp>;
  /**
   * Mark patient as deleted using patient id
   * - this patient mark as deleted
   * - all patient studies and reports mark as deleted
   * - all invitations to this patient are closed
   * - all invitations to the studies/reports of this patient are closed
   *
   * Permission: `PatientPermissions.CanEditPatient`
   */
  DeletePatient(
    request: DeepPartial<DeletePatientReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeletePatientResp>;
}

export class PatientServiceClientImpl implements PatientService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.PatientProfileStream = this.PatientProfileStream.bind(this);
    this.PatientListStream = this.PatientListStream.bind(this);
    this.CreatePatient = this.CreatePatient.bind(this);
    this.SetPatientPersonalData = this.SetPatientPersonalData.bind(this);
    this.SetPatientExternalID = this.SetPatientExternalID.bind(this);
    this.SetPatientGender = this.SetPatientGender.bind(this);
    this.SetPatientStatus = this.SetPatientStatus.bind(this);
    this.DeletePatient = this.DeletePatient.bind(this);
  }

  PatientProfileStream(
    request: DeepPartial<PatientProfileStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<PatientProfileStreamResp> {
    return this.rpc.invoke(
      PatientServicePatientProfileStreamDesc,
      PatientProfileStreamReq.fromPartial(request),
      metadata,
    );
  }

  PatientListStream(
    request: DeepPartial<PatientListStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<PatientListStreamResp> {
    return this.rpc.invoke(
      PatientServicePatientListStreamDesc,
      PatientListStreamReq.fromPartial(request),
      metadata,
    );
  }

  CreatePatient(
    request: DeepPartial<CreatePatientReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreatePatientResp> {
    return this.rpc.unary(
      PatientServiceCreatePatientDesc,
      CreatePatientReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientPersonalData(
    request: DeepPartial<SetPatientPersonalDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientPersonalDataResp> {
    return this.rpc.unary(
      PatientServiceSetPatientPersonalDataDesc,
      SetPatientPersonalDataReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientExternalID(
    request: DeepPartial<SetPatientExternalIDReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientExternalIDResp> {
    return this.rpc.unary(
      PatientServiceSetPatientExternalIDDesc,
      SetPatientExternalIDReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientGender(
    request: DeepPartial<SetPatientGenderReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientGenderResp> {
    return this.rpc.unary(
      PatientServiceSetPatientGenderDesc,
      SetPatientGenderReq.fromPartial(request),
      metadata,
    );
  }

  SetPatientStatus(
    request: DeepPartial<SetPatientStatusReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetPatientStatusResp> {
    return this.rpc.unary(
      PatientServiceSetPatientStatusDesc,
      SetPatientStatusReq.fromPartial(request),
      metadata,
    );
  }

  DeletePatient(
    request: DeepPartial<DeletePatientReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeletePatientResp> {
    return this.rpc.unary(
      PatientServiceDeletePatientDesc,
      DeletePatientReq.fromPartial(request),
      metadata,
    );
  }
}

export const PatientServiceDesc = {
  serviceName: 'com.diagnocat.core.PatientService',
};

export const PatientServicePatientProfileStreamDesc: UnaryMethodDefinitionish =
  {
    methodName: 'PatientProfileStream',
    service: PatientServiceDesc,
    requestStream: false,
    responseStream: true,
    requestType: {
      serializeBinary() {
        return PatientProfileStreamReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...PatientProfileStreamResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientServicePatientListStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'PatientListStream',
  service: PatientServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return PatientListStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...PatientListStreamResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const PatientServiceCreatePatientDesc: UnaryMethodDefinitionish = {
  methodName: 'CreatePatient',
  service: PatientServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreatePatientReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CreatePatientResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const PatientServiceSetPatientPersonalDataDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientPersonalData',
    service: PatientServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientPersonalDataReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientPersonalDataResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientServiceSetPatientExternalIDDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetPatientExternalID',
    service: PatientServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetPatientExternalIDReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetPatientExternalIDResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const PatientServiceSetPatientGenderDesc: UnaryMethodDefinitionish = {
  methodName: 'SetPatientGender',
  service: PatientServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetPatientGenderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetPatientGenderResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const PatientServiceSetPatientStatusDesc: UnaryMethodDefinitionish = {
  methodName: 'SetPatientStatus',
  service: PatientServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetPatientStatusReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetPatientStatusResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const PatientServiceDeletePatientDesc: UnaryMethodDefinitionish = {
  methodName: 'DeletePatient',
  service: PatientServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeletePatientReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeletePatientResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
