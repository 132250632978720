import { reportsModel } from '@/entities/reports';
import { useAppDispatch } from '@/shared/hooks';

export const useRemoveReport = () => {
  const dispatch = useAppDispatch();

  return async (reportID: string) => {
    const removeReport = await dispatch(
      reportsModel.thunks.deleteReport({ ReportID: reportID }),
    );

    if (removeReport.meta.requestStatus === 'fulfilled') {
      dispatch(reportsModel.actions.removeOne(reportID));
    }
  };
};
