import { FC, useEffect } from 'react';
import cn from 'classnames';

import { useAppSelector } from '@/shared/hooks';
import { patientModel } from '@/entities/patient';
import { organizationModel } from '@/entities/organization';

import { usePatientsListStream } from '../../../Patients/hooks/usePatientsListStream';

import styles from './PatientTest.module.scss';
import { PatientMethods } from './components/PatientMethods/PatientMethods';

type Props = {
  className?: string;
};

export const PatientTest: FC<Props> = (props) => {
  const { className } = props;

  const patientItemsList = useAppSelector(patientModel.selectors.selectAll);

  const [sharedCounter, totalCounter] = useAppSelector(
    patientModel.selectors.selectCounters,
  );

  const organizations = useAppSelector(organizationModel.selectors.selectAll);

  const organizationID =
    organizations.find((elem) => elem.Name === 'Max patients count')?.ID || '';

  const { openPatientListStream, closePatientListStream } =
    usePatientsListStream({ organizationID });

  useEffect(() => {
    if (organizationID) {
      openPatientListStream();
    }

    return () => {
      closePatientListStream();
    };
  }, [organizationID]);

  return (
    <div className={cn(styles.cont, className)}>
      <h1 className={cn(styles.test, 'h1')}>Patient entity</h1>
      <h3>Patients count: {patientItemsList?.length}</h3>
      <div className={styles.a}>Total counter: {totalCounter}</div>
      <div className={styles.a}>Shared counter: {sharedCounter}</div>
      {patientItemsList?.slice(0, 10).map((patient) => (
        <div>{`PatientID: ${patient.ID}, Name: ${patient.PersonalData.FirstName} ${patient.PersonalData.LastName}`}</div>
      ))}
      <PatientMethods />
    </div>
  );
};
