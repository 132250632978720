import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  DeleteStudyReq,
  StorageServiceClientImpl,
  DeepPartial,
} from '@/shared/api/protocol_gen/api/core/svc_upload';
import api, { ApiError } from '@/shared/api/api';

export type StudyRequests = keyof StorageServiceClientImpl;

const requestGenerator = <Req>(requestName: StudyRequests) =>
  createAsyncThunk(
    `report/${requestName}`,
    async (request: DeepPartial<Req>, { rejectWithValue }) => {
      try {
        const response = await api.study[requestName](request);

        return response;
      } catch (error: unknown) {
        const { message, type } = error as ApiError;

        return rejectWithValue({ message, type });
      }
    },
  );

export const deleteStudy = requestGenerator<DeleteStudyReq>('DeleteStudy');
