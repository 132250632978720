import React, { FC } from 'react';
import cn from 'classnames';

import { Spinner } from '../Spinner/Spinner';
import { Icon } from '../Icon/Icon';
import { IconNames } from '../Icon/Icon.types';

import styles from './Button.module.scss';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

type ButtonType = 'button' | 'submit' | 'reset';

type ButtonSize = 'large' | 'medium' | 'small';

interface BaseButtonProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
  circle?: boolean;
  danger?: boolean;
  loading?: boolean;
  disabled?: boolean;
  block?: boolean;
  type?: ButtonType;
  icon?: IconNames;
  className?: string;
  testID?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export type ButtonProps = BaseButtonProps & React.ButtonHTMLAttributes<unknown>;

const buttonSize: Record<ButtonSize, string> = {
  large: styles.sizeLarge,
  medium: styles.sizeMedium,
  small: styles.sizeSmall,
};

const buttonVariant: Record<ButtonVariant, string> = {
  primary: styles.variantPrimary,
  secondary: styles.variantSecondary,
  tertiary: styles.variantTertiary,
};

const buttonTypography: Record<ButtonVariant, Record<ButtonSize, string>> = {
  primary: {
    large: 'h4',
    medium: 'h5',
    small: 'p3b',
  },
  secondary: {
    large: 'h4',
    medium: 'h5',
    small: 'p3b',
  },
  tertiary: {
    large: 'p1',
    medium: 'p2',
    small: 'p3',
  },
};

export const Button: FC<ButtonProps> = ({
  loading = false,
  type = 'button',
  variant = 'primary',
  size = 'large',
  danger = false,
  circle = false,
  block = false,
  icon,
  className,
  testID,
  children,
  onClick,
  disabled,
  ...restBaseButtonProps
}) => {
  const isOnlyIcon = Boolean(icon) && !children;
  const showIcon = !loading && Boolean(icon);

  const mergedDisabled = loading || disabled;

  const classes = cn(
    styles.button,
    buttonSize[size],
    buttonVariant[variant],
    buttonTypography[variant][size],
    isOnlyIcon && styles.onlyIcon,
    circle && styles.circle,
    danger && styles.danger,
    block && styles.block,
    className,
  );

  return (
    <button
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...(restBaseButtonProps as BaseButtonProps)}
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      className={classes}
      data-testid={testID}
      onClick={onClick}
      disabled={mergedDisabled}
    >
      {loading && (
        <div className={styles.buttonSpinner}>
          <Spinner size={size === 'small' ? 24 : 32} />
        </div>
      )}
      {/* TODO: Need to decide Icon size for small button */}
      {showIcon && <Icon name={icon} size={size === 'small' ? 24 : 32} />}
      {children && (
        <span className={cn(loading && styles.hiddenButtonChildren)}>
          {children}
        </span>
      )}
    </button>
  );
};
