import { createSelector } from '@reduxjs/toolkit';
import { compose, filter, head, propEq } from 'ramda';

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { RootState } from '@/app/model/store';

import { filterReportByStudyID, filterReportByStudyIDAndType } from '../lib';
import { getPatientRecentReports } from '../lib/getPatientRecentReports';

import { reportsAdapter } from './reportSlice';

// TODO: How to reexport this with spread like { ... reportsSelectors }
const reportsSelectors = reportsAdapter.getSelectors(
  (state: RootState) => state.reports,
);

export const { selectAll, selectById, selectIds, selectEntities, selectTotal } =
  reportsSelectors;

export const selectFirst = (state: RootState) =>
  <Report>compose(head, reportsSelectors.selectAll)(state);

export const selectByStudyID = createSelector(
  reportsSelectors.selectAll,
  (state: RootState, studyID: string) => studyID,
  filterReportByStudyID,
);

export const selectByStudyIDAndType = createSelector(
  reportsSelectors.selectAll,
  (state: RootState, studyID: string) => studyID,
  (state: RootState, studyID: string, reportType: ReportType) => reportType,
  filterReportByStudyIDAndType,
);

export const selectByPatientID = createSelector(
  reportsSelectors.selectAll,
  (state: RootState, patientID: string) => patientID,
  (reports, patientID) =>
    filter<Report>(propEq<keyof Report>('PatientID')(patientID))(reports),
);

export const selectPatientRecentReports = createSelector(
  reportsSelectors.selectAll,
  (state: RootState, patientID: string) => patientID,
  (state: RootState, patientID: string, enabledAsRecentTypes: ReportType[]) =>
    enabledAsRecentTypes,
  getPatientRecentReports,
);
