import { InferType, number, object, string } from 'yup';

export const signUpFormSchema = object({
  email: string().email().required(),
  password: string().required(),
  firstName: string().required(),
  lastName: string().required(),
  language: number().required(),
  country: number().required(),
});

export type SignUpFormPayload = InferType<typeof signUpFormSchema>;
