import { OrganizationSpecialization } from '@/shared/api/protocol_gen/model/dto_organization';
import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import {
  ReferralsGroupsByStudyType,
  ReferralsGroupsType,
} from './referralsGroups.type';

export const REFERRALS_GROUPS_LIST: ReferralsGroupsType[] = [
  OrganizationSpecialization.OrganizationSpecializationGeneral,
  OrganizationSpecialization.OrganizationSpecializationPeriodontologist,
  OrganizationSpecialization.OrganizationSpecializationProsthodontist,
  OrganizationSpecialization.OrganizationSpecializationOrthodontist,
  OrganizationSpecialization.OrganizationSpecializationEndodontist,
  ReferralsGroupsByStudyType.FMXAndPano,
  ReferralsGroupsByStudyType.CBCT,
];

export const REFFERALS_GROUP_DISABLED_FOR_IOXRAY_AND_PANO: ReferralsGroupsType[] =
  [ReferralsGroupsByStudyType.FMXAndPano];

export const REFFERALS_GROUP_DISABLED_FOR_CBCT: ReferralsGroupsType[] = [
  ReferralsGroupsByStudyType.CBCT,
];

export const CBCT_REPORTS_TYPE = [
  ReportType.ReportType_CBCT_GP,
  ReportType.ReportType_CBCT_Segmentation,
  ReportType.ReportType_CBCT_OrthoSlides,
  ReportType.ReportType_CBCT_Implant,
  ReportType.ReportType_CBCT_Endo,
  ReportType.ReportType_CBCT_Molar,
  ReportType.ReportType_CBCT_IOS_Superimposition,
];

export const CONDITIONS_CODE_BY_REFERRAL: Record<
  ReferralsGroupsType,
  ConditionCode[]
> = {
  [OrganizationSpecialization.OrganizationSpecializationGeneral]: [
    ConditionCode.CariesSigns,
    ConditionCode.OpenMargin,
    ConditionCode.Overhang,
    ConditionCode.LackOfInterproximalContact,
  ],
  [OrganizationSpecialization.OrganizationSpecializationPeriodontologist]: [
    ConditionCode.DentalCalculus,
    ConditionCode.PeriodontalBoneLoss,
  ],
  [OrganizationSpecialization.OrganizationSpecializationProsthodontist]: [
    ConditionCode.Missing,
    ConditionCode.CrownDefectOver50pct,
    ConditionCode.RootFragment,
    ConditionCode.OpenMargin,
    ConditionCode.Overhang,
    ConditionCode.LackOfInterproximalContact,
    ConditionCode.EndoTreated,
  ],
  [OrganizationSpecialization.OrganizationSpecializationOrthodontist]: [
    ConditionCode.Impaction,
    ConditionCode.EctopicDevelopmentAndEruption,
    ConditionCode.Spacing,
    ConditionCode.HorizontalDisplacement,
    ConditionCode.Crowding,
    ConditionCode.Overeruption,
  ],
  [OrganizationSpecialization.OrganizationSpecializationEndodontist]: [
    ConditionCode.EndoRootPerforation,
    ConditionCode.EndoVerticalRootFracture,
    ConditionCode.EndoMissedCanal,
    ConditionCode.EndoShortFilling,
    ConditionCode.EndoRootCanalWithRestriction,
    ConditionCode.EndoVoidsPresentInTheRootFilling,
    ConditionCode.EndoFracturedInstrumentInRootCanal,
    ConditionCode.InternalResorption,
    ConditionCode.PeriodontalLigamentSpaceWideningAlongRoot,
    ConditionCode.FurcationalRadiolucency,
    ConditionCode.SurfaceResorption,
    ConditionCode.HorizontalRootFracture,
    ConditionCode.Pulpitis,
  ],
  [ReferralsGroupsByStudyType.FMXAndPano]: [ConditionCode.CariesSigns],
  [ReferralsGroupsByStudyType.CBCT]: [
    ConditionCode.Missing,
    ConditionCode.Impaction,
    ConditionCode.EndoRootPerforation,
    ConditionCode.EndoFracturedInstrumentInRootCanal,
    ConditionCode.InternalResorption,
    ConditionCode.PeriodontalLigamentSpaceWideningAlongRoot,
    ConditionCode.FurcationalRadiolucency,
    ConditionCode.SurfaceResorption,
    ConditionCode.HorizontalRootFracture,
    ConditionCode.RootFragment,
    ConditionCode.PeriapicalLesion,
    ConditionCode.PeriodontalBoneLoss,
  ],
};
