import { createSelector } from '@reduxjs/toolkit';

import { usersModel } from '@/entities/users';
import { organizationModel } from '@/entities/organization';

export const selectOrganizationUsers = createSelector(
  usersModel.selectors.selectAll,
  organizationModel.selectors.selectUsers,
  (users, organizationUsers) =>
    organizationUsers?.map((organizationUser) =>
      users?.find(({ ID }) => ID === organizationUser.UserID),
    ),
);
