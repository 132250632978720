import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { WidgetCard, SmartGrid } from '@/shared/ui';
import { studyUploadImages } from '@/widgets/StudyUpload/assets/images';
import { StudyInfoTypes } from '@/widgets/StudyUpload/config/StudyUpload.types';

import styles from './StudyUpload.module.scss';

type StudyUploadProps = {
  className?: string;
  studiesInfo: StudyInfoTypes[];
};

export const StudyUpload: FC<StudyUploadProps> = (props) => {
  const { className, studiesInfo } = props;

  const { formatMessage } = useIntl();

  const addMessage = formatMessage({
    id: 'studyUpload.add',
    defaultMessage: 'add',
  });

  return (
    <SmartGrid className={className}>
      {studiesInfo.map((studyInfo) => (
        <WidgetCard className={styles.card} key={studyInfo.studyID}>
          <header className={styles.header}>
            <h4 className={styles.title}>{studyInfo.title}</h4>
            <img
              className={styles.addIcon}
              src={studyUploadImages.addButton}
              alt={addMessage}
            />
          </header>
          <section className={styles.section}>
            <img src={studyInfo.image} alt={studyInfo.title as string} />
          </section>
        </WidgetCard>
      ))}
    </SmartGrid>
  );
};
