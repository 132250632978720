import { IconNames } from '@/shared/ui';

export const POPUP_DEFAULT_SIZE = 320;
export const RESIZABLE_BOX_DEFAULT_SIZE = 400;
export const RESIZABLE_BOX_MIN_CONSTRAINT = 250;
export const RESIZABLE_BOX_MAX_CONSTRAINT = 1000;
export const RESIZEING_COEFFICIENT = 0.8;

export const CONTROLS: IconNames[] = [
  'ruler',
  'arrow',
  'erase',
  'eyeFilled',
  'brightness',
  'shaprness',
  '3d',
];
