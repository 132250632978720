/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  ServiceType,
  serviceTypeFromJSON,
  serviceTypeToJSON,
} from '../../api/billing/dto_services';
import { SubscriptionModel } from '../../api/billing/dto_subscription';

export const protobufPackage = 'com.diagnocat.billing';

export interface Order {
  ID: string;
  Description: string;
  SeqID: number;
  AccountID: string;
  Amount: string;
  Currency: string;
  Invoiced: boolean;
  InvoiceID: string;
  InvoicePDFURL: string;
  DueDate: number;
  Paid: boolean;
  Processed: boolean;
  Cancelled: boolean;
  Overdue: boolean;
  Effect: Effect | undefined;
  PaymentInfo: Order_OrderPaymentInfo | undefined;
  CreatedAt: number;
  MarkedAsPaid: boolean;
}

export interface Order_OrderPaymentInfo {
  DocumentID: string;
  TransactionNumber: string;
}

export interface Effect {
  SubscriptionAdd: EffectSubscriptionAdd | undefined;
  SubscriptionRenew: EffectSubscriptionRenew | undefined;
  SubscriptionUpgrade: EffectSubscriptionUpgrade | undefined;
  PayForOveruse: EffectPayForOveruse | undefined;
}

export interface EffectSubscriptionAdd {
  Subscription: SubscriptionModel | undefined;
}

export interface EffectSubscriptionRenew {
  SubscriptionStateID: string;
  SubscriptionName: string;
}

export interface EffectSubscriptionUpgrade {
  CoverConsumptionIDs: string[];
  Subscription: SubscriptionModel | undefined;
}

export interface EffectPayForOveruse {
  Consumptions: Consumption[];
}

export interface Consumption {
  ID: string;
  AccountID: string;
  ServiceType: ServiceType;
  ConsumedAmount: number;
  CreatedAt: number;
  Reason: ConsumptionReason | undefined;
}

/** TODO: HACK - Rename */
export interface ConsumptionReason {
  Type: string;
  ID: string;
}

function createBaseOrder(): Order {
  return {
    ID: '',
    Description: '',
    SeqID: 0,
    AccountID: '',
    Amount: '',
    Currency: '',
    Invoiced: false,
    InvoiceID: '',
    InvoicePDFURL: '',
    DueDate: 0,
    Paid: false,
    Processed: false,
    Cancelled: false,
    Overdue: false,
    Effect: undefined,
    PaymentInfo: undefined,
    CreatedAt: 0,
    MarkedAsPaid: false,
  };
}

export const Order = {
  encode(message: Order, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Description !== '') {
      writer.uint32(138).string(message.Description);
    }
    if (message.SeqID !== 0) {
      writer.uint32(120).int64(message.SeqID);
    }
    if (message.AccountID !== '') {
      writer.uint32(18).string(message.AccountID);
    }
    if (message.Amount !== '') {
      writer.uint32(26).string(message.Amount);
    }
    if (message.Currency !== '') {
      writer.uint32(34).string(message.Currency);
    }
    if (message.Invoiced === true) {
      writer.uint32(48).bool(message.Invoiced);
    }
    if (message.InvoiceID !== '') {
      writer.uint32(58).string(message.InvoiceID);
    }
    if (message.InvoicePDFURL !== '') {
      writer.uint32(66).string(message.InvoicePDFURL);
    }
    if (message.DueDate !== 0) {
      writer.uint32(72).int64(message.DueDate);
    }
    if (message.Paid === true) {
      writer.uint32(80).bool(message.Paid);
    }
    if (message.Processed === true) {
      writer.uint32(88).bool(message.Processed);
    }
    if (message.Cancelled === true) {
      writer.uint32(96).bool(message.Cancelled);
    }
    if (message.Overdue === true) {
      writer.uint32(104).bool(message.Overdue);
    }
    if (message.Effect !== undefined) {
      Effect.encode(message.Effect, writer.uint32(114).fork()).ldelim();
    }
    if (message.PaymentInfo !== undefined) {
      Order_OrderPaymentInfo.encode(
        message.PaymentInfo,
        writer.uint32(130).fork(),
      ).ldelim();
    }
    if (message.CreatedAt !== 0) {
      writer.uint32(40).int64(message.CreatedAt);
    }
    if (message.MarkedAsPaid === true) {
      writer.uint32(144).bool(message.MarkedAsPaid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Order {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 17:
          message.Description = reader.string();
          break;
        case 15:
          message.SeqID = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.AccountID = reader.string();
          break;
        case 3:
          message.Amount = reader.string();
          break;
        case 4:
          message.Currency = reader.string();
          break;
        case 6:
          message.Invoiced = reader.bool();
          break;
        case 7:
          message.InvoiceID = reader.string();
          break;
        case 8:
          message.InvoicePDFURL = reader.string();
          break;
        case 9:
          message.DueDate = longToNumber(reader.int64() as Long);
          break;
        case 10:
          message.Paid = reader.bool();
          break;
        case 11:
          message.Processed = reader.bool();
          break;
        case 12:
          message.Cancelled = reader.bool();
          break;
        case 13:
          message.Overdue = reader.bool();
          break;
        case 14:
          message.Effect = Effect.decode(reader, reader.uint32());
          break;
        case 16:
          message.PaymentInfo = Order_OrderPaymentInfo.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.CreatedAt = longToNumber(reader.int64() as Long);
          break;
        case 18:
          message.MarkedAsPaid = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Order {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
      SeqID: isSet(object.SeqID) ? Number(object.SeqID) : 0,
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      Amount: isSet(object.Amount) ? String(object.Amount) : '',
      Currency: isSet(object.Currency) ? String(object.Currency) : '',
      Invoiced: isSet(object.Invoiced) ? Boolean(object.Invoiced) : false,
      InvoiceID: isSet(object.InvoiceID) ? String(object.InvoiceID) : '',
      InvoicePDFURL: isSet(object.InvoicePDFURL)
        ? String(object.InvoicePDFURL)
        : '',
      DueDate: isSet(object.DueDate) ? Number(object.DueDate) : 0,
      Paid: isSet(object.Paid) ? Boolean(object.Paid) : false,
      Processed: isSet(object.Processed) ? Boolean(object.Processed) : false,
      Cancelled: isSet(object.Cancelled) ? Boolean(object.Cancelled) : false,
      Overdue: isSet(object.Overdue) ? Boolean(object.Overdue) : false,
      Effect: isSet(object.Effect) ? Effect.fromJSON(object.Effect) : undefined,
      PaymentInfo: isSet(object.PaymentInfo)
        ? Order_OrderPaymentInfo.fromJSON(object.PaymentInfo)
        : undefined,
      CreatedAt: isSet(object.CreatedAt) ? Number(object.CreatedAt) : 0,
      MarkedAsPaid: isSet(object.MarkedAsPaid)
        ? Boolean(object.MarkedAsPaid)
        : false,
    };
  },

  toJSON(message: Order): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.SeqID !== undefined && (obj.SeqID = Math.round(message.SeqID));
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.Amount !== undefined && (obj.Amount = message.Amount);
    message.Currency !== undefined && (obj.Currency = message.Currency);
    message.Invoiced !== undefined && (obj.Invoiced = message.Invoiced);
    message.InvoiceID !== undefined && (obj.InvoiceID = message.InvoiceID);
    message.InvoicePDFURL !== undefined &&
      (obj.InvoicePDFURL = message.InvoicePDFURL);
    message.DueDate !== undefined &&
      (obj.DueDate = Math.round(message.DueDate));
    message.Paid !== undefined && (obj.Paid = message.Paid);
    message.Processed !== undefined && (obj.Processed = message.Processed);
    message.Cancelled !== undefined && (obj.Cancelled = message.Cancelled);
    message.Overdue !== undefined && (obj.Overdue = message.Overdue);
    message.Effect !== undefined &&
      (obj.Effect = message.Effect ? Effect.toJSON(message.Effect) : undefined);
    message.PaymentInfo !== undefined &&
      (obj.PaymentInfo = message.PaymentInfo
        ? Order_OrderPaymentInfo.toJSON(message.PaymentInfo)
        : undefined);
    message.CreatedAt !== undefined &&
      (obj.CreatedAt = Math.round(message.CreatedAt));
    message.MarkedAsPaid !== undefined &&
      (obj.MarkedAsPaid = message.MarkedAsPaid);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Order>, I>>(object: I): Order {
    const message = createBaseOrder();
    message.ID = object.ID ?? '';
    message.Description = object.Description ?? '';
    message.SeqID = object.SeqID ?? 0;
    message.AccountID = object.AccountID ?? '';
    message.Amount = object.Amount ?? '';
    message.Currency = object.Currency ?? '';
    message.Invoiced = object.Invoiced ?? false;
    message.InvoiceID = object.InvoiceID ?? '';
    message.InvoicePDFURL = object.InvoicePDFURL ?? '';
    message.DueDate = object.DueDate ?? 0;
    message.Paid = object.Paid ?? false;
    message.Processed = object.Processed ?? false;
    message.Cancelled = object.Cancelled ?? false;
    message.Overdue = object.Overdue ?? false;
    message.Effect =
      object.Effect !== undefined && object.Effect !== null
        ? Effect.fromPartial(object.Effect)
        : undefined;
    message.PaymentInfo =
      object.PaymentInfo !== undefined && object.PaymentInfo !== null
        ? Order_OrderPaymentInfo.fromPartial(object.PaymentInfo)
        : undefined;
    message.CreatedAt = object.CreatedAt ?? 0;
    message.MarkedAsPaid = object.MarkedAsPaid ?? false;
    return message;
  },
};

function createBaseOrder_OrderPaymentInfo(): Order_OrderPaymentInfo {
  return { DocumentID: '', TransactionNumber: '' };
}

export const Order_OrderPaymentInfo = {
  encode(
    message: Order_OrderPaymentInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DocumentID !== '') {
      writer.uint32(10).string(message.DocumentID);
    }
    if (message.TransactionNumber !== '') {
      writer.uint32(18).string(message.TransactionNumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Order_OrderPaymentInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrder_OrderPaymentInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DocumentID = reader.string();
          break;
        case 2:
          message.TransactionNumber = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Order_OrderPaymentInfo {
    return {
      DocumentID: isSet(object.DocumentID) ? String(object.DocumentID) : '',
      TransactionNumber: isSet(object.TransactionNumber)
        ? String(object.TransactionNumber)
        : '',
    };
  },

  toJSON(message: Order_OrderPaymentInfo): unknown {
    const obj: any = {};
    message.DocumentID !== undefined && (obj.DocumentID = message.DocumentID);
    message.TransactionNumber !== undefined &&
      (obj.TransactionNumber = message.TransactionNumber);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Order_OrderPaymentInfo>, I>>(
    object: I,
  ): Order_OrderPaymentInfo {
    const message = createBaseOrder_OrderPaymentInfo();
    message.DocumentID = object.DocumentID ?? '';
    message.TransactionNumber = object.TransactionNumber ?? '';
    return message;
  },
};

function createBaseEffect(): Effect {
  return {
    SubscriptionAdd: undefined,
    SubscriptionRenew: undefined,
    SubscriptionUpgrade: undefined,
    PayForOveruse: undefined,
  };
}

export const Effect = {
  encode(
    message: Effect,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.SubscriptionAdd !== undefined) {
      EffectSubscriptionAdd.encode(
        message.SubscriptionAdd,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.SubscriptionRenew !== undefined) {
      EffectSubscriptionRenew.encode(
        message.SubscriptionRenew,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.SubscriptionUpgrade !== undefined) {
      EffectSubscriptionUpgrade.encode(
        message.SubscriptionUpgrade,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.PayForOveruse !== undefined) {
      EffectPayForOveruse.encode(
        message.PayForOveruse,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Effect {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffect();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SubscriptionAdd = EffectSubscriptionAdd.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.SubscriptionRenew = EffectSubscriptionRenew.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.SubscriptionUpgrade = EffectSubscriptionUpgrade.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.PayForOveruse = EffectPayForOveruse.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Effect {
    return {
      SubscriptionAdd: isSet(object.SubscriptionAdd)
        ? EffectSubscriptionAdd.fromJSON(object.SubscriptionAdd)
        : undefined,
      SubscriptionRenew: isSet(object.SubscriptionRenew)
        ? EffectSubscriptionRenew.fromJSON(object.SubscriptionRenew)
        : undefined,
      SubscriptionUpgrade: isSet(object.SubscriptionUpgrade)
        ? EffectSubscriptionUpgrade.fromJSON(object.SubscriptionUpgrade)
        : undefined,
      PayForOveruse: isSet(object.PayForOveruse)
        ? EffectPayForOveruse.fromJSON(object.PayForOveruse)
        : undefined,
    };
  },

  toJSON(message: Effect): unknown {
    const obj: any = {};
    message.SubscriptionAdd !== undefined &&
      (obj.SubscriptionAdd = message.SubscriptionAdd
        ? EffectSubscriptionAdd.toJSON(message.SubscriptionAdd)
        : undefined);
    message.SubscriptionRenew !== undefined &&
      (obj.SubscriptionRenew = message.SubscriptionRenew
        ? EffectSubscriptionRenew.toJSON(message.SubscriptionRenew)
        : undefined);
    message.SubscriptionUpgrade !== undefined &&
      (obj.SubscriptionUpgrade = message.SubscriptionUpgrade
        ? EffectSubscriptionUpgrade.toJSON(message.SubscriptionUpgrade)
        : undefined);
    message.PayForOveruse !== undefined &&
      (obj.PayForOveruse = message.PayForOveruse
        ? EffectPayForOveruse.toJSON(message.PayForOveruse)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Effect>, I>>(object: I): Effect {
    const message = createBaseEffect();
    message.SubscriptionAdd =
      object.SubscriptionAdd !== undefined && object.SubscriptionAdd !== null
        ? EffectSubscriptionAdd.fromPartial(object.SubscriptionAdd)
        : undefined;
    message.SubscriptionRenew =
      object.SubscriptionRenew !== undefined &&
      object.SubscriptionRenew !== null
        ? EffectSubscriptionRenew.fromPartial(object.SubscriptionRenew)
        : undefined;
    message.SubscriptionUpgrade =
      object.SubscriptionUpgrade !== undefined &&
      object.SubscriptionUpgrade !== null
        ? EffectSubscriptionUpgrade.fromPartial(object.SubscriptionUpgrade)
        : undefined;
    message.PayForOveruse =
      object.PayForOveruse !== undefined && object.PayForOveruse !== null
        ? EffectPayForOveruse.fromPartial(object.PayForOveruse)
        : undefined;
    return message;
  },
};

function createBaseEffectSubscriptionAdd(): EffectSubscriptionAdd {
  return { Subscription: undefined };
}

export const EffectSubscriptionAdd = {
  encode(
    message: EffectSubscriptionAdd,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Subscription !== undefined) {
      SubscriptionModel.encode(
        message.Subscription,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): EffectSubscriptionAdd {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectSubscriptionAdd();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Subscription = SubscriptionModel.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectSubscriptionAdd {
    return {
      Subscription: isSet(object.Subscription)
        ? SubscriptionModel.fromJSON(object.Subscription)
        : undefined,
    };
  },

  toJSON(message: EffectSubscriptionAdd): unknown {
    const obj: any = {};
    message.Subscription !== undefined &&
      (obj.Subscription = message.Subscription
        ? SubscriptionModel.toJSON(message.Subscription)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectSubscriptionAdd>, I>>(
    object: I,
  ): EffectSubscriptionAdd {
    const message = createBaseEffectSubscriptionAdd();
    message.Subscription =
      object.Subscription !== undefined && object.Subscription !== null
        ? SubscriptionModel.fromPartial(object.Subscription)
        : undefined;
    return message;
  },
};

function createBaseEffectSubscriptionRenew(): EffectSubscriptionRenew {
  return { SubscriptionStateID: '', SubscriptionName: '' };
}

export const EffectSubscriptionRenew = {
  encode(
    message: EffectSubscriptionRenew,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.SubscriptionStateID !== '') {
      writer.uint32(10).string(message.SubscriptionStateID);
    }
    if (message.SubscriptionName !== '') {
      writer.uint32(18).string(message.SubscriptionName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): EffectSubscriptionRenew {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectSubscriptionRenew();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SubscriptionStateID = reader.string();
          break;
        case 2:
          message.SubscriptionName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectSubscriptionRenew {
    return {
      SubscriptionStateID: isSet(object.SubscriptionStateID)
        ? String(object.SubscriptionStateID)
        : '',
      SubscriptionName: isSet(object.SubscriptionName)
        ? String(object.SubscriptionName)
        : '',
    };
  },

  toJSON(message: EffectSubscriptionRenew): unknown {
    const obj: any = {};
    message.SubscriptionStateID !== undefined &&
      (obj.SubscriptionStateID = message.SubscriptionStateID);
    message.SubscriptionName !== undefined &&
      (obj.SubscriptionName = message.SubscriptionName);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectSubscriptionRenew>, I>>(
    object: I,
  ): EffectSubscriptionRenew {
    const message = createBaseEffectSubscriptionRenew();
    message.SubscriptionStateID = object.SubscriptionStateID ?? '';
    message.SubscriptionName = object.SubscriptionName ?? '';
    return message;
  },
};

function createBaseEffectSubscriptionUpgrade(): EffectSubscriptionUpgrade {
  return { CoverConsumptionIDs: [], Subscription: undefined };
}

export const EffectSubscriptionUpgrade = {
  encode(
    message: EffectSubscriptionUpgrade,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.CoverConsumptionIDs) {
      writer.uint32(10).string(v!);
    }
    if (message.Subscription !== undefined) {
      SubscriptionModel.encode(
        message.Subscription,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): EffectSubscriptionUpgrade {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectSubscriptionUpgrade();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CoverConsumptionIDs.push(reader.string());
          break;
        case 2:
          message.Subscription = SubscriptionModel.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectSubscriptionUpgrade {
    return {
      CoverConsumptionIDs: Array.isArray(object?.CoverConsumptionIDs)
        ? object.CoverConsumptionIDs.map((e: any) => String(e))
        : [],
      Subscription: isSet(object.Subscription)
        ? SubscriptionModel.fromJSON(object.Subscription)
        : undefined,
    };
  },

  toJSON(message: EffectSubscriptionUpgrade): unknown {
    const obj: any = {};
    if (message.CoverConsumptionIDs) {
      obj.CoverConsumptionIDs = message.CoverConsumptionIDs.map((e) => e);
    } else {
      obj.CoverConsumptionIDs = [];
    }
    message.Subscription !== undefined &&
      (obj.Subscription = message.Subscription
        ? SubscriptionModel.toJSON(message.Subscription)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectSubscriptionUpgrade>, I>>(
    object: I,
  ): EffectSubscriptionUpgrade {
    const message = createBaseEffectSubscriptionUpgrade();
    message.CoverConsumptionIDs =
      object.CoverConsumptionIDs?.map((e) => e) || [];
    message.Subscription =
      object.Subscription !== undefined && object.Subscription !== null
        ? SubscriptionModel.fromPartial(object.Subscription)
        : undefined;
    return message;
  },
};

function createBaseEffectPayForOveruse(): EffectPayForOveruse {
  return { Consumptions: [] };
}

export const EffectPayForOveruse = {
  encode(
    message: EffectPayForOveruse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Consumptions) {
      Consumption.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EffectPayForOveruse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEffectPayForOveruse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Consumptions.push(
            Consumption.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EffectPayForOveruse {
    return {
      Consumptions: Array.isArray(object?.Consumptions)
        ? object.Consumptions.map((e: any) => Consumption.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EffectPayForOveruse): unknown {
    const obj: any = {};
    if (message.Consumptions) {
      obj.Consumptions = message.Consumptions.map((e) =>
        e ? Consumption.toJSON(e) : undefined,
      );
    } else {
      obj.Consumptions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EffectPayForOveruse>, I>>(
    object: I,
  ): EffectPayForOveruse {
    const message = createBaseEffectPayForOveruse();
    message.Consumptions =
      object.Consumptions?.map((e) => Consumption.fromPartial(e)) || [];
    return message;
  },
};

function createBaseConsumption(): Consumption {
  return {
    ID: '',
    AccountID: '',
    ServiceType: 0,
    ConsumedAmount: 0,
    CreatedAt: 0,
    Reason: undefined,
  };
}

export const Consumption = {
  encode(
    message: Consumption,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.AccountID !== '') {
      writer.uint32(18).string(message.AccountID);
    }
    if (message.ServiceType !== 0) {
      writer.uint32(24).int32(message.ServiceType);
    }
    if (message.ConsumedAmount !== 0) {
      writer.uint32(32).int64(message.ConsumedAmount);
    }
    if (message.CreatedAt !== 0) {
      writer.uint32(40).int64(message.CreatedAt);
    }
    if (message.Reason !== undefined) {
      ConsumptionReason.encode(
        message.Reason,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Consumption {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.AccountID = reader.string();
          break;
        case 3:
          message.ServiceType = reader.int32() as any;
          break;
        case 4:
          message.ConsumedAmount = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.CreatedAt = longToNumber(reader.int64() as Long);
          break;
        case 6:
          message.Reason = ConsumptionReason.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Consumption {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      ServiceType: isSet(object.ServiceType)
        ? serviceTypeFromJSON(object.ServiceType)
        : 0,
      ConsumedAmount: isSet(object.ConsumedAmount)
        ? Number(object.ConsumedAmount)
        : 0,
      CreatedAt: isSet(object.CreatedAt) ? Number(object.CreatedAt) : 0,
      Reason: isSet(object.Reason)
        ? ConsumptionReason.fromJSON(object.Reason)
        : undefined,
    };
  },

  toJSON(message: Consumption): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.ServiceType !== undefined &&
      (obj.ServiceType = serviceTypeToJSON(message.ServiceType));
    message.ConsumedAmount !== undefined &&
      (obj.ConsumedAmount = Math.round(message.ConsumedAmount));
    message.CreatedAt !== undefined &&
      (obj.CreatedAt = Math.round(message.CreatedAt));
    message.Reason !== undefined &&
      (obj.Reason = message.Reason
        ? ConsumptionReason.toJSON(message.Reason)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Consumption>, I>>(
    object: I,
  ): Consumption {
    const message = createBaseConsumption();
    message.ID = object.ID ?? '';
    message.AccountID = object.AccountID ?? '';
    message.ServiceType = object.ServiceType ?? 0;
    message.ConsumedAmount = object.ConsumedAmount ?? 0;
    message.CreatedAt = object.CreatedAt ?? 0;
    message.Reason =
      object.Reason !== undefined && object.Reason !== null
        ? ConsumptionReason.fromPartial(object.Reason)
        : undefined;
    return message;
  },
};

function createBaseConsumptionReason(): ConsumptionReason {
  return { Type: '', ID: '' };
}

export const ConsumptionReason = {
  encode(
    message: ConsumptionReason,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Type !== '') {
      writer.uint32(10).string(message.Type);
    }
    if (message.ID !== '') {
      writer.uint32(18).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConsumptionReason {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumptionReason();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Type = reader.string();
          break;
        case 2:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ConsumptionReason {
    return {
      Type: isSet(object.Type) ? String(object.Type) : '',
      ID: isSet(object.ID) ? String(object.ID) : '',
    };
  },

  toJSON(message: ConsumptionReason): unknown {
    const obj: any = {};
    message.Type !== undefined && (obj.Type = message.Type);
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ConsumptionReason>, I>>(
    object: I,
  ): ConsumptionReason {
    const message = createBaseConsumptionReason();
    message.Type = object.Type ?? '';
    message.ID = object.ID ?? '';
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
