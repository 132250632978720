import { AssetContentGeneratedCBCTGPToothSlice } from '@/shared/api/protocol_gen/model/dto_asset_cbct_gp';

import { getImageSrc } from './getImageSrc';

export const groupSlicesByRole = (
  groupAllSlices: AssetContentGeneratedCBCTGPToothSlice[],
  sliceRole: 'slice' | 'guide',
) =>
  groupAllSlices.reduce((acc, slice) => {
    const thicknessMm = slice?.Regular?.Thickness;
    const strideMm = slice?.Regular?.Stride;
    if (sliceRole === 'guide' && (slice?.GuideFrontal || slice?.Guide)) {
      return [
        ...acc,
        {
          id: slice?.Slice.ID,
          src: getImageSrc(slice?.Slice.ID),
          image: slice?.Slice.Image,
          guide: slice?.GuideFrontal || slice?.Guide,
        },
      ];
    }
    if (sliceRole === 'slice' && slice?.Regular) {
      return [
        ...acc,
        {
          id: slice?.Slice.ID,
          src: getImageSrc(slice?.Slice.ID),
          image: slice?.Slice.Image,
          ...(sliceRole === 'slice' ? { thicknessMm, strideMm } : {}),
        },
      ];
    }

    return acc;
  }, []);
