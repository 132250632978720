import React, { FC } from 'react';
import cn from 'classnames';

import { ReportMethods } from './components/ReportMethods/ReportMethods';

type Props = {
  className?: string;
};

export const ReportTest: FC<Props> = (props) => {
  const { className } = props;

  return (
    <div className={cn(className)}>
      <h1 className={cn('h1')}>Report Entity</h1>
      <ReportMethods />
    </div>
  );
};
