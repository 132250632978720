// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemoveStudyModal-module__container--PBwHy3EA{margin-top:90px}.RemoveStudyModal-module__modalBody--RTklQXyx{display:flex;flex-direction:column;gap:16px}.RemoveStudyModal-module__errorText--Qn3VvXoy{text-align:center}", "",{"version":3,"sources":["webpack://./src/features/removeStudy/ui/RemoveStudyModal/RemoveStudyModal.module.scss"],"names":[],"mappings":"AAAA,8CACE,eAAA,CAGF,8CACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,8CACE,iBAAA","sourcesContent":[".container {\n  margin-top: 90px;\n}\n\n.modalBody {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.errorText {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RemoveStudyModal-module__container--PBwHy3EA",
	"modalBody": "RemoveStudyModal-module__modalBody--RTklQXyx",
	"errorText": "RemoveStudyModal-module__errorText--Qn3VvXoy"
};
export default ___CSS_LOADER_EXPORT___;
