import React, { FC } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import { getUserFullName, usersModel } from '@/entities/users';
import { useAppSelector } from '@/shared/hooks';
import { ReportUserSignature } from '@/shared/api/protocol_gen/model/dto_report';
import { organizationModel, i18n } from '@/entities/organization';

import { REPORT_SIGNED_DATE_FORMAT } from '../../config';

import styles from './ReportSign.module.scss';

type ReportSignProps = {
  user: ReportUserSignature;
  className?: string;
};

export const ReportSign: FC<ReportSignProps> = (props) => {
  const { user, className } = props;

  const { formatMessage } = useIntl();

  const signedUser = useAppSelector(
    usersModel.selectors.selectById(user.UserID),
  );

  const userName = getUserFullName(signedUser);

  const speciality = useAppSelector((state) =>
    organizationModel.selectors.selectUserSpecializationsByID(
      state,
      user.UserID,
    ),
  );

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.signedUser}>
        <p className={cn(styles.signedUserName, 'p2')}>{userName}</p>
        {speciality?.length ? (
          <p className={cn(styles.signedUserSpeciality, 'p3')}>
            {formatMessage(i18n.specialityType[speciality[0]])}
          </p>
        ) : null}
      </div>
      <p className={cn(styles.signDate, 'p2')}>
        {format(user.SignedAt, REPORT_SIGNED_DATE_FORMAT)}
      </p>
    </div>
  );
};
