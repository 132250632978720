import { defineMessages } from 'react-intl';

import { ToothChartLegendsTypes } from './tooth.type';

export const toothChartLegendsMessages = defineMessages<ToothChartLegendsTypes>(
  {
    unhealthy: {
      id: 'studyToothChart.unhealthy',
      defaultMessage: 'Unhealthy',
    },
    lowProbability: {
      id: 'studyToothChart.lowProbability',
      defaultMessage: 'Low probability',
    },
    treated: {
      id: 'studyToothChart.treated',
      defaultMessage: 'Treated',
    },
    healthy: {
      id: 'studyToothChart.healthy',
      defaultMessage: 'Healthy',
    },
    missing: {
      id: 'studyToothChart.missing',
      defaultMessage: 'Missing',
    },
    all: {
      id: 'studyToothChart.all',
      defaultMessage: 'All',
    },
  },
);
