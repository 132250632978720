import React from 'react';
import { Route as _Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { ErrorPage } from '@/pages/ErrorPage/ErrorPage';
import { TestPage } from '@/pages/TestPage/TestPage';
import { Patients } from '@/pages/Patients/Patients';
import { TooltipTestPage } from '@/pages/TooltipTestPage/TooltipTestPage';
import { TestIntlPage } from '@/pages/TestIntlPage/TestIntlPage';
import { Organizations } from '@/pages/Organizations/Organizations';
import { PATHS } from '@/shared/config';
import { PatientProfile } from '@/pages/PatientProfile/PatientProfile';
import { Settings } from '@/pages/Settings/Settings';
import { Report } from '@/pages/Report/Report';
import { EndodonticReport } from '@/pages/EndodonticReport/EndodonticReport';
import { PreviewReport } from '@/pages/PreviewReport/PreviewReport';
import { STLViewer } from '@/pages/STLViewer/STLViewer';
import { ImplantStudio } from '@/pages/ImplantStudio/ImplantStudio';
import { Tooth } from '@/pages/Tooth/Tooth';
import { ResetPassword } from '@/pages/ResetPassword/ResetPassword';
import { Disclaimer } from '@/pages/Disclaimer/Disclaimer';
import { SignUp } from '@/pages/SignUp/SignUp';
import { ConfirmEmail } from '@/pages/ConfirmEmail/ConfirmEmail';
import { ConfirmSharing } from '@/pages/ConfirmSharing/ConfirmSharing';
import { ImplantReport } from '@/pages/ImplantReport/ImplantReport';
import { MolarReport } from '@/pages/MolarReport/MolarReport';
import { SegmentationReport } from '@/pages/SegmentationReport/SegmentationReport';
import { SignIn } from '@/pages/SignIn/SignIn';

import { ProtectedRoutes } from '../providers/ProtectedRoutes';

const Route = Sentry.withSentryReactRouterV6Routing(_Route);

export const ClientCoreRoutes = (): React.ReactElement => (
  <Routes>
    <Route path={PATHS.signUp} element={<SignUp />} />
    <Route path={PATHS.signIn} element={<SignIn />} />
    <Route path={PATHS.confirmEmail} element={<ConfirmEmail />} />
    <Route path={PATHS.resetPassword} element={<ResetPassword />} />

    <Route element={<ProtectedRoutes />}>
      <Route path="test-intl" element={<TestIntlPage />} />
      <Route path={PATHS.test} element={<TestPage />} />
      <Route path={PATHS.main} element={<Patients />} />
      <Route path={PATHS.patients} element={<Patients />} />
      <Route path={PATHS.organizations} element={<Organizations />} />
      <Route path={PATHS.patientProfile} element={<PatientProfile />} />
      <Route path={PATHS.settings} element={<Settings />} />
      <Route path={PATHS.report} element={<Report />} />
      <Route path={PATHS.gpReport} element={<Report />} />
      <Route path={PATHS.endodonticReport} element={<EndodonticReport />} />
      <Route path={PATHS.implantReport} element={<ImplantReport />} />
      <Route path={PATHS.molarReport} element={<MolarReport />} />
      <Route path={PATHS.segmentationReport} element={<SegmentationReport />} />
      <Route path={PATHS.previewReport} element={<PreviewReport />} />
      <Route path={PATHS.STLViewer} element={<STLViewer />} />
      <Route path={PATHS.implantStudio} element={<ImplantStudio />} />
      <Route path={PATHS.tooth} element={<Tooth />} />
      <Route path={PATHS.disclaimer} element={<Disclaimer />} />
      <Route path={PATHS.confirmSharing} element={<ConfirmSharing />} />
    </Route>

    <Route path={PATHS.error} element={<ErrorPage />} />
    {/* Pages for dev testing */}
    <Route path="tooltip" element={<TooltipTestPage />} />
  </Routes>
);
