import React, { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ReportUserSignature } from '@/shared/api/protocol_gen/model/dto_report';

import { ReportSign } from '../ReportSign/ReportSign';

import styles from './ReportSigns.module.scss';

type ReportSignsProps = {
  signedUsers: ReportUserSignature[];
  className?: string;
};

export const ReportSigns: FC<ReportSignsProps> = (props) => {
  const { signedUsers, className } = props;

  const sortedSignedUsers = [...signedUsers].sort(
    (a, b) => b.SignedAt - a.SignedAt,
  );

  return (
    <div className={cn(styles.container, className)}>
      <h4 className={cn(styles.signedTitle, 'h4')}>
        <FormattedMessage
          id="report.signedByReport"
          defaultMessage="Report signed by"
        />
      </h4>

      {sortedSignedUsers.map((user) => (
        <ReportSign key={user.UserID} user={user} />
      ))}
    </div>
  );
};
