export type ScanDataFields =
  | 'date'
  | 'manufacturer'
  | 'model'
  | 'dap'
  | 'fov'
  | 'voxel';

export const SCAN_DATA_FIELDS: ScanDataFields[] = [
  'date',
  'manufacturer',
  'model',
  'dap',
  'fov',
  'voxel',
];

export const STUDY_TAB_DATE_FORMAT = 'MMM, Y';

export const STUDY_IMAGE_DATE_FORMAT = 'MMMM d, Y';
