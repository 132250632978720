// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileToothChart-module__container--8KnRYq8v{padding:24px;background-color:var(--bg2);border-radius:16px}.ProfileToothChart-module__title--A4QD25It{margin-bottom:12px}@media screen and (max-width: 420px){.ProfileToothChart-module__title--A4QD25It{margin-bottom:2px}}.ProfileToothChart-module__toothChartLegends--pSMYLHd0{margin-top:12px;margin-bottom:0}@media screen and (max-width: 420px){.ProfileToothChart-module__toothChartLegends--pSMYLHd0{margin-top:2px}}", "",{"version":3,"sources":["webpack://./src/widgets/ProfileToothChart/ui/ProfileToothChart/ProfileToothChart.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,+CACE,YAAA,CACA,2BAAA,CACA,kBAAA,CAGF,2CACE,kBAAA,CCFA,qCDCF,2CAII,iBAAA,CAAA,CAIJ,uDACE,eAAA,CACA,eAAA,CCXA,qCDSF,uDAKI,cAAA,CAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  padding: 24px;\n  background-color: var(--bg2);\n  border-radius: 16px;\n}\n\n.title {\n  margin-bottom: 12px;\n\n  @include mobile {\n    margin-bottom: 2px;\n  }\n}\n\n.toothChartLegends {\n  margin-top: 12px;\n  margin-bottom: 0;\n\n  @include mobile {\n    margin-top: 2px;\n  }\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ProfileToothChart-module__container--8KnRYq8v",
	"title": "ProfileToothChart-module__title--A4QD25It",
	"toothChartLegends": "ProfileToothChart-module__toothChartLegends--pSMYLHd0"
};
export default ___CSS_LOADER_EXPORT___;
