import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { User } from '@/shared/api/protocol_gen/model/dto_user';

export const usersAdapter = createEntityAdapter<User>({
  selectId: (user) => user.ID,
});

const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState(),
  reducers: {
    addOne: usersAdapter.addOne,
    setOne: usersAdapter.setOne,
    setMany: usersAdapter.setMany,
  },
});

export const { addOne, setOne, setMany } = usersSlice.actions;

export default usersSlice.reducer;
