import { useState } from 'react';

export const useStudyInfoModal = () => {
  const [currentStudyID, setStudyID] = useState<string>(undefined);
  const [show, setShow] = useState(false);

  const openStudyInfoModal = (selectedStudyID: string) => {
    setStudyID(selectedStudyID);
    setShow(true);
  };

  const closeStudyModal = () => {
    if (currentStudyID) {
      setStudyID(undefined);
    }
    setShow(false);
  };

  return {
    currentStudyID,
    showStudyInfoModal: show,
    openStudyInfoModal,
    closeStudyModal,
  };
};
