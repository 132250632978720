import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { AnyAction } from '@reduxjs/toolkit';
import queryString from 'query-string';

import { Button, Textarea } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';

import styles from './Method.module.scss';

type Props = {
  className?: string;
  thunk: (args: unknown) => unknown;
  label: string;
};

export const Method: FC<Props> = (props) => {
  const { className, thunk, label } = props;

  const dispatch = useAppDispatch();

  const [thunkResult, setThunkResult] =
    useState<{ type: string; payload: { message: string } }>();
  const [isVisible, setIsVisible] = useState(false);

  const [payload, setPayload] = useState('');

  const payloadConvert = () => {
    const result = queryString.parse(payload);
    return result;
  };

  useEffect(() => {
    if (thunkResult) {
      // eslint-disable-next-line no-console
      console.log({ thunkResult });
    }
  }, [thunkResult]);

  return (
    <div
      className={cn(styles.cont, className, isVisible ? styles.visible : '')}
    >
      {isVisible && (
        <Textarea
          placeholder="Type payload here in search query format: ?key1=value&#38;key2=value"
          value={payload}
          onChange={(e) => {
            setPayload(e.target.value);
          }}
        />
      )}
      {thunkResult && (
        <div
          style={{
            color: thunkResult?.type?.includes('rejected') ? 'red' : 'green',
          }}
        >
          result: {thunkResult?.type}
        </div>
      )}
      <div className={cn('h2')}>
        {thunkResult?.payload?.message && (
          <div>{thunkResult.payload.message}</div>
        )}
      </div>
      <Button
        size="small"
        disabled={!isVisible}
        className={styles.button}
        onClick={async () => {
          const result = await dispatch(thunk(payloadConvert()) as AnyAction);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setThunkResult(result as any);
        }}
      >
        {label}
      </Button>
      <Button
        size="small"
        onClick={() => {
          setIsVisible((prev) => !prev);
        }}
        variant="secondary"
      >
        Payload
      </Button>
    </div>
  );
};
