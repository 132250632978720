import { RootState } from '@/app/model/store';

import { usersAdapter } from './usersSlice';

export const usersSelectors = usersAdapter.getSelectors(
  (state: RootState) => state.users,
);

export const selectUserById = (userID: string) => (state: RootState) =>
  usersSelectors.selectById(state, userID);
