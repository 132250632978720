import React, { FC, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { isEmpty } from 'ramda';

import { PATHS } from '@/shared/config';
import { Header } from '@/widgets/Header';
import {
  Button,
  Column,
  Description,
  Icon,
  Layout,
  Skeleton,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui/';
import { useAppSelector, useMedia } from '@/shared/hooks';
import { getPatientFullName, patientModel } from '@/entities/patient';
import {
  DentalNotations,
  getDisplayToothNumber,
  toothModel,
} from '@/entities/tooth';
import {
  conditionModel,
  useTransformCondition,
  groupConditionInterfaceByGroup,
  ConditionButton,
  ConditionListByCategory,
  useToothConditions,
  useConditionGroupsList,
} from '@/entities/condition';
import {
  assetsModel,
  useGetSelectedSlices,
  getImageSrc,
  SliceInterface,
} from '@/entities/assets';
import { SimplifiedToothChart } from '@/features/simplifiedToothChart';
import { useUpdateConditionDecision } from '@/features/conditionControl';
import { useReportDataStream } from '@/features/reportStream';
import { PanoImage } from '@/widgets/PanoImage';
import { reportsModel } from '@/entities/reports';
import { useApproveTooth } from '@/features/approveTooth';
import { ZoomedSliceModal } from '@/widgets/ZoomedSliceModal';
import { CommentTooth, useCommentTooth } from '@/features/commentTooth';

import { SelectedSlices } from './ui/SelectedSlices/SelectedSlices';
import styles from './Tooth.module.scss';
import { PANO_TOOLS_ICONS } from './config';
import { SliceGroup } from './ui/SliceGroup/SliceGroup';

type ToothProps = {
  className?: string;
};

export const Tooth: FC<ToothProps> = (props) => {
  const { className } = props;

  const [panoImageToggled, setPanoImageToggled] = useState(true);
  const [draggedSlice, setDraggedSlice] = useState('');
  const [selected, setSelected] = useState<SliceInterface[]>([]);

  const { reportID, patientID, toothID } = useParams();
  const { isPhone, isSmallDesktop, isMobile } = useMedia();

  const selectedSlices = useGetSelectedSlices(toothID);

  const patient = useAppSelector((state) =>
    patientModel.selectors.selectById(state, patientID),
  );

  const tooth = useAppSelector((state) =>
    toothModel.selectors.selectById(state, toothID),
  );

  const conditions = useAppSelector(
    conditionModel.selectors.selectByToothID(toothID),
  );

  const report = useAppSelector((state) =>
    reportsModel.selectors.selectById(state, reportID),
  );

  const conditionItems = useTransformCondition(conditions);

  const { generalConditionItems } = useToothConditions(tooth?.ID);

  const { isApproveToothLoading, toggleApproveTooth } = useApproveTooth(tooth);
  const panoImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(reportID),
  );

  const panoImageUrl = useMemo(
    () =>
      getImageSrc(panoImage?.CBCTGPPanoramaReformatGeneral.PanoramaSingle.ID),
    [panoImage],
  );

  const conditionItemsByGroups = useMemo(
    () => groupConditionInterfaceByGroup(conditionItems),
    [conditionItems],
  );

  const { isOpenGroupsList } = useConditionGroupsList(conditionItemsByGroups);

  const {
    updateConditionDecision,
    isLoading: isUpdateConditionDecisionLoading,
  } = useUpdateConditionDecision(toothID);

  const patientFullName = getPatientFullName(patient);

  const isToothLoaded = !!tooth;
  const isPatientLoaded = !!patient;
  const displayedToothNumber = getDisplayToothNumber(
    tooth?.ISONumber,
    DentalNotations.ISO,
  );

  const {
    newComment,
    isCommentEditorVisible,
    isNewCommentLoading,
    closeAndResetTextInEditor,
    openChangeComment,
    openCommentEditor,
    changeCommentInEditor,
    saveComment,
  } = useCommentTooth(tooth?.Comment);

  const isToothCommentExists = !isEmpty(tooth?.Comment);

  // TODO: fsd import issue
  useReportDataStream(reportID);

  const handleTogglePanoImage = () => {
    setPanoImageToggled(!panoImageToggled);
  };

  const handleAddSelected = (src: string) => {
    if (selected.find((item) => item.src === src)) {
      return;
    }
    setSelected((prev) => [...prev, { src, id: src }]);
  };

  const toggleSelected = (src: string) => {
    if (selected.find((item) => item.src === src)) {
      setSelected((prev) => prev.filter((slice) => slice.src !== src));
    } else {
      setSelected((prev) => [...prev, { src, id: src }]);
    }
  };

  const handleDeleteSlice = (src: string) => {
    setSelected((prev) => prev.filter((slice) => slice.src !== src));
  };

  useEffect(() => {
    setSelected(selectedSlices);
  }, [selectedSlices?.length]);

  const handleDrop = () => {
    handleAddSelected(draggedSlice);
    setDraggedSlice('');
  };

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main>
          <Layout.Content className={cn(styles.container, className)}>
            <nav className={styles.nav}>
              <Link
                to={generatePath(PATHS.report, { reportID, patientID })}
                className={styles.backLink}
              >
                <Icon
                  className={styles.backLinkIcon}
                  name="arrowDown"
                  size={32}
                />
                <span className="p1">
                  <FormattedMessage
                    id="previewReport.backToReport"
                    defaultMessage="Back to report"
                  />
                </span>
              </Link>
            </nav>
            <header className={styles.header}>
              {isToothLoaded ? (
                <h1 className={cn(isMobile ? 'h2' : 'h1')}>
                  {`Tooth ${displayedToothNumber}`}
                </h1>
              ) : (
                <Skeleton width="75%" borderRadius="42px" height="87px" />
              )}

              {patientFullName ? (
                <p className="p1">{getPatientFullName(patient)}</p>
              ) : (
                <Skeleton width="35%" borderRadius="12px" height="24px" />
              )}

              {isPatientLoaded ? (
                <div className={styles.descriptionContainer}>
                  <Description
                    label={
                      <FormattedMessage id="patient.dob" defaultMessage="DoB" />
                    }
                  >
                    {`${patient?.PersonalData?.DateOfBirth} ${patient?.Age}`}
                  </Description>

                  <Description
                    label={
                      <FormattedMessage
                        id="patient.gender"
                        defaultMessage="Gender"
                      />
                    }
                  >
                    {patient?.Gender}
                  </Description>
                </div>
              ) : (
                <Skeleton width="55%" borderRadius="8px" height="16px" />
              )}
            </header>

            <WidgetLayout>
              <Column
                className={cn(
                  styles.column,
                  panoImageToggled && styles.columnFirstToggled,
                )}
              >
                <WidgetCard
                  className={cn(
                    styles.panoImageWidget,
                    panoImageToggled && styles.toggled,
                  )}
                >
                  {panoImage && (
                    <PanoImage
                      src={panoImageUrl}
                      controls={PANO_TOOLS_ICONS}
                      toggled={panoImageToggled}
                    />
                  )}
                  <Icon
                    name="playFilled"
                    onClick={handleTogglePanoImage}
                    size={24}
                    className={cn(
                      styles.panoToggleIcon,
                      panoImageToggled && styles.iconToggled,
                    )}
                  />
                </WidgetCard>

                <WidgetCard>
                  <h1>
                    <FormattedMessage
                      id="global.addConditions"
                      defaultMessage="Add Conditions"
                    />
                  </h1>
                  {isOpenGroupsList ? (
                    <ConditionListByCategory
                      conditionItemsByGroups={conditionItemsByGroups}
                      updateConditionDecision={updateConditionDecision}
                      disabled={isUpdateConditionDecisionLoading}
                    />
                  ) : (
                    <Skeleton width="100%" borderRadius="8px" height="46px" />
                  )}
                </WidgetCard>
                {!draggedSlice && (
                  <SelectedSlices
                    onDrop={handleDrop}
                    onDeleteSlice={handleDeleteSlice}
                    selectedSlices={selected}
                  />
                )}
                <SelectedSlices
                  className={cn(
                    styles.hiddenSelected,
                    !!draggedSlice && styles.fixedSelected,
                  )}
                  onDrop={handleDrop}
                  selectedSlices={selected}
                />
                <WidgetCard className={styles.commentToothWidget}>
                  <h3 className={isPhone ? 'h4' : 'h3'}>
                    <FormattedMessage
                      id="tooth.comment"
                      defaultMessage="Comment"
                    />
                  </h3>
                  {isToothCommentExists || isCommentEditorVisible ? (
                    <CommentTooth
                      autoFocus
                      comment={tooth?.Comment}
                      newComment={newComment}
                      isNewCommentLoading={isNewCommentLoading}
                      isCommentEditorVisible={isCommentEditorVisible}
                      onSaveComment={() => saveComment(tooth.ID)}
                      onCancel={closeAndResetTextInEditor}
                      onOpenChangeComment={openChangeComment}
                      onChangeCommentInEditor={changeCommentInEditor}
                    />
                  ) : (
                    <Button
                      variant="secondary"
                      size="medium"
                      onClick={openCommentEditor}
                      className={styles.addComment}
                    >
                      <FormattedMessage
                        id="tooth.addComment"
                        defaultMessage="Add comment"
                      />
                    </Button>
                  )}
                </WidgetCard>
              </Column>

              <Column className={styles.column}>
                <WidgetCard>
                  <div className={styles.conditions}>
                    {generalConditionItems.map((conditionItem) => (
                      <ConditionButton
                        key={conditionItem.id}
                        color={conditionItem.color}
                        probabilityText={conditionItem.probabilityText}
                        text={conditionItem.text}
                        probability={conditionItem.probability}
                      />
                    ))}
                  </div>
                </WidgetCard>
                <WidgetCard>
                  <h1>MPR widget</h1>
                </WidgetCard>
                <WidgetCard>
                  <SliceGroup
                    onDragSlice={setDraggedSlice}
                    selectedSlices={selected}
                    toggleSelected={toggleSelected}
                  />
                </WidgetCard>
              </Column>
            </WidgetLayout>
          </Layout.Content>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer className={styles.footer} sticky>
        <SimplifiedToothChart toothOfInterestNumber={displayedToothNumber} />

        <div className={styles.footerButtons}>
          <Button
            size={isSmallDesktop || isMobile ? 'medium' : 'large'}
            icon={tooth?.IsApproved ? 'check' : 'plus'}
            loading={isApproveToothLoading}
            onClick={toggleApproveTooth}
            disabled={!report?.YourPermissions.CanChangeToothApproved}
            danger={tooth?.IsApproved}
            className={styles.approveButton}
          >
            {tooth?.IsApproved ? (
              <FormattedMessage
                id="toothCard.approved"
                defaultMessage="Approved"
              />
            ) : (
              <FormattedMessage
                id="toothCard.approve"
                defaultMessage="Approve"
              />
            )}
          </Button>
        </div>
      </Layout.Footer>
      <ZoomedSliceModal
        toggleSelected={toggleSelected}
        selectedSlices={selected}
      />
    </Layout>
  );
};
