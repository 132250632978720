import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Service } from '@/shared/api/protocol_gen/api/billing/dto_services';
import { Order } from '@/shared/api/protocol_gen/api/billing/dto_order';
import {
  SubscriptionState,
  SubscriptionStateStatus,
} from '@/shared/api/protocol_gen/api/billing/dto_subscription';
import { AccountInventory } from '@/shared/api/protocol_gen/api/billing/dto_account';

import { CBCTConsumptionType } from '../lib/makeCBCTConsumption';
import {
  getServicesFlags,
  INITIAL_SERVICES_FLAGS,
} from '../lib/subsriptionsFlags';

import { cancelSubscription } from './billingSlice.thunks';

export type UserSubscriptionType = {
  id: string;
  name: string;
  services: Service[];
  currency: string;
  from: number;
  to: number;
  price: string;
  status: SubscriptionStateStatus;
  isAutoRenewal: boolean;
};

const initialState = {
  subscriptions: [] as SubscriptionState[],
  packages: [] as SubscriptionState[],
  trials: [] as SubscriptionState[],
  servicesFlags: INITIAL_SERVICES_FLAGS,
  orders: [] as Order[],
  hasOverdueSubscription: false,
  CBCTConsumption: { limit: 0, leftover: 0 },
  isCancelWarningOpen: false,
  isSubscriptionCancellationPending: false,
  orderIDForPayment: '',
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setInventory: (state, action: PayloadAction<AccountInventory>) => {
      state.subscriptions = action.payload.Subscriptions;
      state.packages = action.payload.Packages;
      state.trials = action.payload.Trials;
    },
    setServicesFlags: (state, action) => {
      state.servicesFlags = getServicesFlags([
        ...(action?.payload?.Subscriptions ?? []),
        ...(action?.payload?.Packages ?? []),
        ...(action?.payload?.Trials ?? []),
      ]);
    },
    setBillingOrders: (state, action: PayloadAction<Order[]>) => {
      state.orders = action.payload;
    },
    updateOrders: (state, action: PayloadAction<Order>) => {
      const orderForUpdateIndex = state.orders.findIndex(
        (order) => order.ID === action.payload.ID,
      );

      if (orderForUpdateIndex < 0) {
        state.orders = [action.payload, ...state.orders];
      } else {
        state.orders[orderForUpdateIndex] = action.payload;
      }
    },
    setHasOverdueSubscription: (state, action: PayloadAction<boolean>) => {
      state.hasOverdueSubscription = action.payload;
    },
    setCBCTConsumption: (state, action: PayloadAction<CBCTConsumptionType>) => {
      state.CBCTConsumption = action.payload;
    },
    setIsCancelWarningOpen: (state, action: PayloadAction<boolean>) => {
      state.isCancelWarningOpen = action.payload;
    },
    setIsSubscriptionCancellationPending: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isSubscriptionCancellationPending = action.payload;
    },
    setOrderIDForPayment: (state, { payload }: PayloadAction<string>) => {
      state.orderIDForPayment = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cancelSubscription.pending, (state) => {
      state.isSubscriptionCancellationPending = true;
    });
    builder.addCase(cancelSubscription.fulfilled, (state) => {
      state.isSubscriptionCancellationPending = false;
      state.isCancelWarningOpen = false;
    });
    builder.addCase(cancelSubscription.rejected, (state) => {
      state.isSubscriptionCancellationPending = false;
    });
  },
});

export const {
  setInventory,
  setServicesFlags,
  setBillingOrders,
  updateOrders,
  setHasOverdueSubscription,
  setCBCTConsumption,
  setIsCancelWarningOpen,
  setIsSubscriptionCancellationPending,
  setOrderIDForPayment,
} = billingSlice.actions;

export default billingSlice.reducer;
