/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export enum ReportCBCTIOSSuperimpositionOrientation {
  /** CBCT_IOS_Superimposition_Orientation_CBCT - all models will be in CBCT coordinates. In this case, IOS Mandible will change its position relative to IOS Maxilla, so any occlusion information in IOS will be lost */
  CBCT_IOS_Superimposition_Orientation_CBCT = 0,
  /** CBCT_IOS_Superimposition_Orientation_IOS - all models will be in IOS coordinates. Occlusion information in IOS will be preserved. Models will not be aligned to CBCT when opened in the viewer */
  CBCT_IOS_Superimposition_Orientation_IOS = 1,
  /** CBCT_IOS_Superimposition_Orientation_Zero_index - will align to CBCT but ignore the original DICOM origin coordinate offset (can be helpful for opening in some software, such as Blue Sky Plan) */
  CBCT_IOS_Superimposition_Orientation_Zero_index = 2,
  UNRECOGNIZED = -1,
}

export function reportCBCTIOSSuperimpositionOrientationFromJSON(
  object: any,
): ReportCBCTIOSSuperimpositionOrientation {
  switch (object) {
    case 0:
    case 'CBCT_IOS_Superimposition_Orientation_CBCT':
      return ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_CBCT;
    case 1:
    case 'CBCT_IOS_Superimposition_Orientation_IOS':
      return ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_IOS;
    case 2:
    case 'CBCT_IOS_Superimposition_Orientation_Zero_index':
      return ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_Zero_index;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ReportCBCTIOSSuperimpositionOrientation.UNRECOGNIZED;
  }
}

export function reportCBCTIOSSuperimpositionOrientationToJSON(
  object: ReportCBCTIOSSuperimpositionOrientation,
): string {
  switch (object) {
    case ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_CBCT:
      return 'CBCT_IOS_Superimposition_Orientation_CBCT';
    case ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_IOS:
      return 'CBCT_IOS_Superimposition_Orientation_IOS';
    case ReportCBCTIOSSuperimpositionOrientation.CBCT_IOS_Superimposition_Orientation_Zero_index:
      return 'CBCT_IOS_Superimposition_Orientation_Zero_index';
    case ReportCBCTIOSSuperimpositionOrientation.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface ReportCBCTIOSSuperimposition {
  /** set from request */
  Orientation: ReportCBCTIOSSuperimpositionOrientation;
  AlignmentSuccessful: boolean;
}

function createBaseReportCBCTIOSSuperimposition(): ReportCBCTIOSSuperimposition {
  return { Orientation: 0, AlignmentSuccessful: false };
}

export const ReportCBCTIOSSuperimposition = {
  encode(
    message: ReportCBCTIOSSuperimposition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Orientation !== 0) {
      writer.uint32(8).int32(message.Orientation);
    }
    if (message.AlignmentSuccessful === true) {
      writer.uint32(16).bool(message.AlignmentSuccessful);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportCBCTIOSSuperimposition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTIOSSuperimposition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Orientation = reader.int32() as any;
          break;
        case 2:
          message.AlignmentSuccessful = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTIOSSuperimposition {
    return {
      Orientation: isSet(object.Orientation)
        ? reportCBCTIOSSuperimpositionOrientationFromJSON(object.Orientation)
        : 0,
      AlignmentSuccessful: isSet(object.AlignmentSuccessful)
        ? Boolean(object.AlignmentSuccessful)
        : false,
    };
  },

  toJSON(message: ReportCBCTIOSSuperimposition): unknown {
    const obj: any = {};
    message.Orientation !== undefined &&
      (obj.Orientation = reportCBCTIOSSuperimpositionOrientationToJSON(
        message.Orientation,
      ));
    message.AlignmentSuccessful !== undefined &&
      (obj.AlignmentSuccessful = message.AlignmentSuccessful);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportCBCTIOSSuperimposition>, I>>(
    object: I,
  ): ReportCBCTIOSSuperimposition {
    const message = createBaseReportCBCTIOSSuperimposition();
    message.Orientation = object.Orientation ?? 0;
    message.AlignmentSuccessful = object.AlignmentSuccessful ?? false;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
