import React from 'react';

import { Avatar, Button, Tooltip } from '@/shared/ui';

// TODO: Remove after UI ui will be done
export const TooltipTestPage = () => (
  <div
    style={{
      display: 'flex',
      padding: 60,
      gap: 40,
      flexDirection: 'column',
      alignItems: 'flex-start',
    }}
  >
    <Tooltip
      side="right"
      align="start"
      content={<span>This is a tooltip text</span>}
    >
      <Button>Test</Button>
    </Tooltip>

    <Tooltip
      side="right"
      align="start"
      content={<span>This is a tooltip text</span>}
      title="Title"
      icon="plus"
    >
      <Button>Test 2</Button>
    </Tooltip>

    <Avatar.Tooltip content="doc. Marvin Elendor">
      <Avatar
        src="https://images.unsplash.com/photo-1511485977113-f34c92461ad9?ixlib=rb-1.2.1&w=128&h=128&dpr=2&q=80"
        initials="GG"
      />
    </Avatar.Tooltip>

    <hr />

    <Avatar
      size={36}
      src="https://images.unsplash.com/photo-1511485977113-f34c92461ad9?ixlib=rb-1.2.1&w=128&h=128&dpr=2&q=80"
      initials="GG"
    />
  </div>
);
