import { reportType } from './config/i18n';

export * as reportsModel from './model';

export const i18n = {
  reportType,
};

export { getReportSignStatus } from './lib/getReportSignStatus';
export { getIsAllTeethInROI } from './lib/getIsAllTeethInROI';
export { useSliceControls } from './hooks';

export * from './config/constants';
