import { includes } from 'ramda';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { CONDITIONS_CODE_BY_REFERRAL } from '../config/constants';
import { ReferralsGroupsType } from '../config/referralsGroups.type';

export const isConditionCodeIncludeInThisReferralGroup = (
  conditionCode: ConditionCode,
  referralGroup: ReferralsGroupsType,
) => includes(conditionCode, CONDITIONS_CODE_BY_REFERRAL[referralGroup]);
