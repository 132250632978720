import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '@/shared/api/protocol_gen/model/dto_user';
import { Account } from '@/shared/api/protocol_gen/api/billing/dto_account';

import { getInitialUserSettings } from '../lib/getInitialUserSettings';

import { setSettings } from './userSlice.thunks';

export type UserOrganizationLegacy = {
  id: string;
  name: string;
  isCurrentUserAdmin: boolean;
};

const initialState = {
  user: {} as User,
  userOrganizationLegacy: {
    id: '',
    name: '',
    isCurrentUserAdmin: true,
  },
  billingAccount: {} as Account,
  settings: getInitialUserSettings(),
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setUserOrganizationLegacy: (
      state,
      action: PayloadAction<UserOrganizationLegacy>,
    ) => {
      state.userOrganizationLegacy = action.payload;
    },
    setBillingAccount: (state, action: PayloadAction<Account>) => {
      state.billingAccount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSettings.fulfilled, (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
    });
  },
});

export const { addUser, setBillingAccount, setUserOrganizationLegacy } =
  userSlice.actions;

export default userSlice.reducer;
