import React, { FC } from 'react';
import cn from 'classnames';

import styles from './StudySelectOption.module.scss';

type Order = {
  id: string;
  date: string;
  img: string;
};

type StudySelectOptionProps = {
  order: Order;
  className?: string;
};

export const StudySelectOption: FC<StudySelectOptionProps> = (props) => {
  const { order, className } = props;

  return (
    <div className={cn(styles.container, className)}>
      <img src={order.img} alt={order.id} />
      <p className="p2">{order.date}</p>
      <p className={cn('p2', styles.patientID)}>#{order.id}</p>
    </div>
  );
};
