import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { format } from 'date-fns';
import { isEmpty } from 'ramda';

import { Study } from '@/shared/api/protocol_gen/model/dto_study';
import {
  Button,
  Icon,
  Skeleton,
  TabGroup,
  TabItem,
  WidgetCard,
} from '@/shared/ui';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import {
  STUDY_IMAGE_DATE_FORMAT,
  STUDY_TAB_DATE_FORMAT,
} from '@/entities/study';
import { reportsModel } from '@/entities/reports';

import { ReportAccordion } from '../ReportAccordion/ReportAccordion';
import CBCTPlaceholderImage from '../../assets/StudyCardPlaceholder.png';
import { studyCardTitle } from '../../config';

import styles from './StudyCard.module.scss';

type StudyCardProps = {
  className?: string;
  studies: Study[];
  // Get study type from internal data 'studies'
  onViewDetails: (studyID: string) => void;
};

export const StudyCard: FC<StudyCardProps> = (props) => {
  const { className, studies, onViewDetails } = props;

  const dispatch = useAppDispatch();

  const [studyID, setStudyID] = useState(studies[0]?.ID);

  const { formatMessage } = useIntl();

  const { isPhone } = useMedia();

  const studyLoading = useAppSelector((state) => state.study.loading);

  const requestReportHandle = async () => {
    await dispatch(
      reportsModel.thunks.requestReport({
        InputCBCTGP: {
          CBCTStudyID: studyID,
        },
      }),
    );
  };

  const showSkeleton = studyLoading === 'idle' || studyLoading === 'pending';

  if (showSkeleton) {
    return <Skeleton width="100%" borderRadius="16px" height="390px" />;
  }

  if (isEmpty(studies)) {
    return null;
  }

  const studyType = studies[0]?.Type;

  // TODO: Refactor with new Tabs components
  const tabItems: TabItem[] = studies.map((study) => ({
    label: format(study?.Created.At, STUDY_TAB_DATE_FORMAT),
    value: `${study?.ID}`,
    content: (
      <div className={styles.content}>
        <div className={styles.imgContainer}>
          <img className={styles.img} src={CBCTPlaceholderImage} alt="" />

          <div className={styles.imgText}>
            <h4 className={cn('h4', styles.imgTextItem)}>
              {formatMessage(studyCardTitle[studyType])}
            </h4>

            <span className={cn('p3', styles.imgTextItem)}>
              {format(study?.Created.At, STUDY_IMAGE_DATE_FORMAT)}
            </span>
          </div>
        </div>

        <ReportAccordion studyID={study.ID} />
      </div>
    ),
  }));

  return (
    <WidgetCard className={cn(styles.container, className)}>
      <TabGroup
        size="small"
        tabName={`studyCard-${studyType}`}
        defaultTabID={studyID}
        onTabChange={setStudyID}
        tabItems={tabItems}
        extraContent={{
          left: (
            <h3 className="h3">{formatMessage(studyCardTitle[studyType])}</h3>
          ),
          right: (
            <button
              type="button"
              onClick={() => onViewDetails(studyID)}
              className={cn(styles.buttonLink, 'p2')}
            >
              {isPhone ? (
                <Icon name="info" size={22} />
              ) : (
                <FormattedMessage
                  id="studyCard.viewDetailsLink"
                  defaultMessage="View details"
                />
              )}
            </button>
          ),
        }}
      />

      <footer>
        <Button
          variant="secondary"
          size="medium"
          block
          icon="plus"
          onClick={requestReportHandle}
        >
          <FormattedMessage
            id="studyCard.orderButton"
            defaultMessage="Order new report"
          />
        </Button>
      </footer>
    </WidgetCard>
  );
};
