import { defineMessages } from 'react-intl';

import { GPSliceGroupsTypes } from './types';

export const sliceTitles = defineMessages<GPSliceGroupsTypes>({
  CBCTGPToothSliceGuideFrontal: {
    id: 'sliceTitle.space',
    defaultMessage: 'Frontal view',
  },
  OrientationAxial: {
    id: 'sliceTitle.axial',
    defaultMessage: 'Axial view',
  },
  OrientationMesioDistal: {
    id: 'sliceTitle.mesioDistal',
    defaultMessage: 'Mesio-distal',
  },
  CBCTEndoFurcationGuide: {
    id: 'sliceTitle.caries',
    defaultMessage: 'Caries',
  },
  OrientationVestibuloOral: {
    id: 'sliceTitle.vestibuloOral',
    defaultMessage: 'Vestibulo Oral',
  },
  Caries: {
    id: 'sliceTitle.caries',
    defaultMessage: 'Caries',
  },
  PeriapicalLesion: {
    id: 'sliceTitle.PeriapicalLesion',
    defaultMessage: 'Periapical Lesion',
  },
});
