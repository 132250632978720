import { equals, isEmpty, trim } from 'ramda';
import { useEffect, useState } from 'react';

import { toastCaller } from '@/shared/ui';

import { useAddNewComment } from './useAddNewComment';

export const useCommentTooth = (comment: string) => {
  const [isCommentEditorVisible, setIsCommentEditorVisible] = useState(false);
  const [isCommentButtonDisabled, setIsCommentButtonDisabled] = useState(
    Boolean(comment),
  );
  const [isNewCommentLoading, setIsNewCommentLoading] = useState(false);
  const [newComment, setNewComment] = useState('');

  const addNewComment = useAddNewComment();

  const newCommentWithoutWhitespace = trim(newComment);

  const isNewCommentEmpty = isEmpty(newCommentWithoutWhitespace);

  const isCommentExisting =
    Boolean(comment) || Boolean(newCommentWithoutWhitespace);

  const isNewCommentNotEqualsCurrentComment = isCommentExisting
    ? !equals(newCommentWithoutWhitespace, trim(comment))
    : undefined;

  const resetNewComment = () => setNewComment('');

  useEffect(() => {
    setIsCommentButtonDisabled(Boolean(comment));

    if (!comment) {
      resetNewComment();
    }
  }, [comment]);

  const openCommentEditor = () => {
    setIsCommentEditorVisible(true);

    setIsCommentButtonDisabled(true);
  };

  const closeAndResetTextInEditor = () => {
    if (!comment) {
      setIsCommentButtonDisabled(false);
    }

    setIsCommentEditorVisible(false);

    resetNewComment();
  };

  const openChangeComment = () => {
    setIsCommentEditorVisible(true);

    setNewComment(comment);
  };

  const changeCommentInEditor = (text: string) => setNewComment(text);

  const saveComment = async (toothID: string) => {
    if (isNewCommentNotEqualsCurrentComment) {
      setIsNewCommentLoading(true);

      try {
        await addNewComment(toothID, newComment);
      } catch (error) {
        toastCaller({
          message: error?.message,
          type: 'error',
          heading: 'Error',
        });
      } finally {
        setIsNewCommentLoading(false);
      }
    }

    if (isNewCommentEmpty) {
      setIsCommentButtonDisabled(false);

      setIsCommentEditorVisible(false);
    } else {
      setIsCommentEditorVisible(false);
    }
  };

  return {
    newComment,
    isCommentEditorVisible,
    isCommentButtonDisabled,
    isNewCommentLoading,
    openCommentEditor,
    closeAndResetTextInEditor,
    openChangeComment,
    changeCommentInEditor,
    saveComment,
  };
};
