// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportMethods-module__cont--rUqz7zCf{padding-top:20px;padding-bottom:20px;display:flex;flex-direction:column}.ReportMethods-module__cont--rUqz7zCf button{margin:4px}", "",{"version":3,"sources":["webpack://./src/pages/TestPage/components/Report/components/ReportMethods/ReportMethods.module.scss"],"names":[],"mappings":"AAAA,sCACE,gBAAA,CACA,mBAAA,CACA,YAAA,CACA,qBAAA,CAEA,6CACE,UAAA","sourcesContent":[".cont {\n  padding-top: 20px;\n  padding-bottom: 20px;\n  display: flex;\n  flex-direction: column;\n\n  button {\n    margin: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cont": "ReportMethods-module__cont--rUqz7zCf"
};
export default ___CSS_LOADER_EXPORT___;
