// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ControlPanel-module__container--DLwCsfgt{display:flex;align-items:center;grid-gap:8px}\n\n.ControlPanel-module__icon--8fO57CRO{margin-right:4px;cursor:pointer;color:var(--black)}\n\n[dir=\"rtl\"] .ControlPanel-module__icon--8fO57CRO{margin-right:0;margin-left:4px}.ControlPanel-module__burger--jUxMmc9p{cursor:pointer;color:var(--purpleflat)}", "",{"version":3,"sources":["webpack://./src/widgets/Header/ui/ControlPanel/ControlPanel.module.scss"],"names":[],"mappings":"AAAA,0CACE,YAAA,CACA,kBAAA,CACA,YAAA;;AAGF,qCACE,gBAAA,CACA,cAAA,CACA,kBAAA;;AAHF,iDACE,cAAA,CAAA,eAEA,CAGF,uCACE,cAAA,CACA,uBAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  grid-gap: 8px;\n}\n\n.icon {\n  margin-right: 4px;\n  cursor: pointer;\n  color: var(--black);\n}\n\n.burger {\n  cursor: pointer;\n  color: var(--purpleflat);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ControlPanel-module__container--DLwCsfgt",
	"icon": "ControlPanel-module__icon--8fO57CRO",
	"burger": "ControlPanel-module__burger--jUxMmc9p"
};
export default ___CSS_LOADER_EXPORT___;
