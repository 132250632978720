// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputLabel-module__container--U8K42j3j{margin-left:16px;color:var(--greytext)}\n\n[dir=\"rtl\"] .InputLabel-module__container--U8K42j3j{margin-left:0;margin-right:16px}.InputLabel-module__container--U8K42j3j.InputLabel-module__required--WjE7BHan::after{content:\" *\";color:var(--redrose)}", "",{"version":3,"sources":["webpack://./src/shared/ui/InputLabel/InputLabel.module.scss"],"names":[],"mappings":"AAAA,wCACE,gBAAA,CACA,qBAAA;;AAFF,oDACE,aAAA,CAAA,iBACA,CAGE,qFACE,YAAA,CACA,oBAAA","sourcesContent":[".container {\n  margin-left: 16px;\n  color: var(--greytext);\n\n  &.required {\n    &::after {\n      content: ' *';\n      color: var(--redrose);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "InputLabel-module__container--U8K42j3j",
	"required": "InputLabel-module__required--WjE7BHan"
};
export default ___CSS_LOADER_EXPORT___;
