import { createSlice } from '@reduxjs/toolkit';

import { SliceName } from '@/shared/config';

const accessSlice = createSlice({
  name: SliceName.access,
  initialState: {},
  reducers: {},
});

export default accessSlice.reducer;
