import { FC } from 'react';
import cn from 'classnames';

import { TestNavigation } from '@/pages/TestPage/components/TestNavigation/TestNavigation';
import { useMedia } from '@/shared/hooks';

import { Logo } from './Logo/Logo';
import { Navigation } from './Navigation/Navigation';
import { ControlPanel } from './ControlPanel/ControlPanel';
import styles from './Header.module.scss';

type HeaderProps = {
  className?: string;
};
const mockLinks = [
  { name: 'Patients', path: '/patients' },
  { name: 'Reports', path: '#' },
  { name: 'Tasks', path: '#' },
  { name: 'Comments', path: '#' },
];

export const Header: FC<HeaderProps> = (props) => {
  const { className } = props;

  const { isMobile } = useMedia();

  return (
    <header className={cn(styles.container, className)}>
      {!isMobile && <TestNavigation />}
      <Logo className={styles.logo} />
      <Navigation links={mockLinks} />
      <ControlPanel />
    </header>
  );
};
