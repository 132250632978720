import { useEffect, useState } from 'react';

import { ProcessingUnitContext } from 'graphics';
import { UltimateMedicalImageMode } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { AnnotationOrEraser } from '@/shared/graphics/RenderComponents/Presets/DicomImageWithAnnotations';

export const useSliceControls = (
  context: ProcessingUnitContext,
  src: string,
) => {
  const [size, setSize] = useState({ w: 320, h: 320 });
  const [activeControl, setActiveControl] = useState<string>('');
  const [mode, setMode] = useState<UltimateMedicalImageMode>('view');
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [inverted, setInverted] = useState(false);
  const [sharpness, setSharpness] = useState(0);
  const [viewAnnotations, setViewAnnotations] = useState(true);
  const [currentAnnotation, setCurrentAnnotation] =
    useState<AnnotationOrEraser | null>(null);
  const [wwwc, setWWWC] = useState<{ ww: number; wc: number }>({
    ww: 0,
    wc: 0,
  });
  const [expandedImage, setExpandedImage] = useState(null);

  const handleReset = () => {
    setActiveControl('');
    setInverted(false);
    setMode('view');
    setSharpness(0);
    setAnnotations([]);
    setCurrentAnnotation(null);
    setWWWC({ ww: context.sourceWindowWidth, wc: context.sourceWindowCenter });
  };

  useEffect(() => {
    switch (activeControl) {
      case 'ruler':
      case 'arrow':
      case 'angle':
      case 'eraser':
        setMode('annotate');
        setCurrentAnnotation(activeControl);
        setViewAnnotations(true);
        break;
      case 'view':
        setMode('view');
        setViewAnnotations(false);
        break;
      case 'brightness':
        setMode('windowing');
        setViewAnnotations(true);
        break;
      case 'sharpening':
        setMode('sharpening');
        setViewAnnotations(true);
        break;
      case 'expand':
        setExpandedImage(src);
        break;
      case 'reset':
        handleReset();
        break;
      default:
        setMode(null);
        setCurrentAnnotation(null);
        setViewAnnotations(true);
    }
  }, [activeControl]);

  return {
    activeControl,
    setActiveControl,
    size,
    setSize,
    mode,
    setMode,
    annotations,
    setAnnotations,
    inverted,
    setInverted,
    sharpness,
    setSharpness,
    viewAnnotations,
    setViewAnnotations,
    currentAnnotation,
    setCurrentAnnotation,
    wwwc,
    setWWWC,
    expandedImage,
    setExpandedImage,
  };
};
