import { FC, useState } from 'react';
import cn from 'classnames';

import { ProcessingUnitContext } from 'graphics';
import { IconNames } from '@/shared/ui';
import { ImageToolbar } from '@/features/imageToolbar';
import { UltimateMedicalImage } from '@/shared/graphics/RenderComponents/Presets/UltimateMedicalImage';
import { useSliceControls } from '@/entities/reports';
import { useMedia, useMedicalImageContainerSize } from '@/shared/hooks';

import { ExpandedPanoModal } from '../ExpandedPanoModal/ExpandedPanoModal';

import styles from './PanoImage.module.scss';

type PanoImageProps = {
  src: string;
  controls?: IconNames[];
  toggled?: boolean;
  className?: string;
};

type PanoImagePreviewProps = {
  src: string;
  inverted: boolean;
};

export const PanoImagePreview: FC<PanoImagePreviewProps> = (props) => {
  const { src, inverted } = props;

  const [imageContext, setImageContext] = useState<ProcessingUnitContext>();

  const {
    mode,
    annotations,
    setAnnotations,
    sharpness,
    setSharpness,
    currentAnnotation,
    wwwc,
    setWWWC,
  } = useSliceControls(imageContext, src);

  const onLoaded = (context: ProcessingUnitContext) => {
    setImageContext(context);
  };

  // TODO: Try MedicalImage components instead of Ultimate
  return (
    <UltimateMedicalImage
      src={{ url: src, kind: 'dicom' }}
      onLoaded={onLoaded}
      width={718}
      height={336}
      mode={mode}
      currentAnnotation={currentAnnotation}
      annotations={annotations}
      onAnnotationsChanged={setAnnotations}
      onWWWCChanged={setWWWC}
      ww={wwwc.ww}
      wc={wwwc.wc}
      sharpness={sharpness}
      onSharpnessChanged={setSharpness}
      inverted={inverted}
      className={styles.ultimateDicom}
    />
  );
};

export const PanoImage: FC<PanoImageProps> = (props) => {
  const { src, controls, toggled, className } = props;

  const [imageContext, setImageContext] = useState<ProcessingUnitContext>();

  const { imageContainerWidth, imageContainerHeight } =
    useMedicalImageContainerSize(imageContext?.width, imageContext?.height);

  const { isMobile } = useMedia();

  const {
    activeControl,
    setActiveControl,
    mode,
    annotations,
    setAnnotations,
    sharpness,
    setSharpness,
    currentAnnotation,
    wwwc,
    setWWWC,
    expandedImage,
    setExpandedImage,
    inverted,
    setInverted,
  } = useSliceControls(imageContext, src);

  const onLoaded = (context: ProcessingUnitContext) => {
    setImageContext(context);
  };

  const handleCloseModal = () => {
    setExpandedImage(null);
    setActiveControl('');
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.image}>
        {!isMobile && !toggled && controls && (
          <ImageToolbar
            controls={controls}
            activeControl={activeControl}
            setActiveControl={setActiveControl}
            inverted={inverted}
            setInverted={setInverted}
            className={styles.toolbar}
          />
        )}
        <UltimateMedicalImage
          src={{ url: src, kind: 'dicom' }}
          onLoaded={onLoaded}
          width={toggled ? imageContainerWidth / 2 : imageContainerWidth}
          height={toggled ? imageContainerHeight / 2 : imageContainerHeight}
          mode={mode}
          currentAnnotation={currentAnnotation}
          annotations={annotations}
          onAnnotationsChanged={setAnnotations}
          onWWWCChanged={setWWWC}
          ww={wwwc.ww}
          wc={wwwc.wc}
          sharpness={sharpness}
          onSharpnessChanged={setSharpness}
          inverted={inverted}
          className={styles.ultimateDicom}
        />
        <ExpandedPanoModal
          src={expandedImage}
          isOpen={!!expandedImage}
          onClose={handleCloseModal}
        />
      </div>
    </div>
  );
};
