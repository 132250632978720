/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { CBCTSeriesGeometryData } from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

export interface ReportCBCTEndo {
  CropGeometryData: CBCTSeriesGeometryData | undefined;
  PeriapicalLesionLocalization:
    | ReportCBCTEndoPeriapicalLesionLocalization
    | undefined;
}

export interface ReportCBCTEndoPeriapicalLesionLocalization {
  /** {"type": "Update", "update": {"task_id": "da8561ec-a376-9d8a-c0a9-6da76722dcc4", "key": "perio_localization", "value": {"tooth_number": 43, "pathology": {"periapical_lesions": false}, "hypodensity_volume": null}, "type": "Update"}, "event": "execution-event"} */
  ToothNumberISO: number;
  PeriapicalLesionDetected: boolean;
  HypodensityVolume: number;
}

export interface ReportCBCTImplant {
  CropGeometryData: CBCTSeriesGeometryData | undefined;
  SliceStep: number;
  SliceThickness: number;
  MeasurementScale: number;
}

export interface ReportCBCTMolar {
  CropGeometryData: CBCTSeriesGeometryData | undefined;
}

export interface ReportCBCTOrtho {}

export interface ReportPanoGP {}

export interface ReportDentalPhotoGP {}

function createBaseReportCBCTEndo(): ReportCBCTEndo {
  return {
    CropGeometryData: undefined,
    PeriapicalLesionLocalization: undefined,
  };
}

export const ReportCBCTEndo = {
  encode(
    message: ReportCBCTEndo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CropGeometryData !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.CropGeometryData,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.PeriapicalLesionLocalization !== undefined) {
      ReportCBCTEndoPeriapicalLesionLocalization.encode(
        message.PeriapicalLesionLocalization,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportCBCTEndo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTEndo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CropGeometryData = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.PeriapicalLesionLocalization =
            ReportCBCTEndoPeriapicalLesionLocalization.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTEndo {
    return {
      CropGeometryData: isSet(object.CropGeometryData)
        ? CBCTSeriesGeometryData.fromJSON(object.CropGeometryData)
        : undefined,
      PeriapicalLesionLocalization: isSet(object.PeriapicalLesionLocalization)
        ? ReportCBCTEndoPeriapicalLesionLocalization.fromJSON(
            object.PeriapicalLesionLocalization,
          )
        : undefined,
    };
  },

  toJSON(message: ReportCBCTEndo): unknown {
    const obj: any = {};
    message.CropGeometryData !== undefined &&
      (obj.CropGeometryData = message.CropGeometryData
        ? CBCTSeriesGeometryData.toJSON(message.CropGeometryData)
        : undefined);
    message.PeriapicalLesionLocalization !== undefined &&
      (obj.PeriapicalLesionLocalization = message.PeriapicalLesionLocalization
        ? ReportCBCTEndoPeriapicalLesionLocalization.toJSON(
            message.PeriapicalLesionLocalization,
          )
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportCBCTEndo>, I>>(
    object: I,
  ): ReportCBCTEndo {
    const message = createBaseReportCBCTEndo();
    message.CropGeometryData =
      object.CropGeometryData !== undefined && object.CropGeometryData !== null
        ? CBCTSeriesGeometryData.fromPartial(object.CropGeometryData)
        : undefined;
    message.PeriapicalLesionLocalization =
      object.PeriapicalLesionLocalization !== undefined &&
      object.PeriapicalLesionLocalization !== null
        ? ReportCBCTEndoPeriapicalLesionLocalization.fromPartial(
            object.PeriapicalLesionLocalization,
          )
        : undefined;
    return message;
  },
};

function createBaseReportCBCTEndoPeriapicalLesionLocalization(): ReportCBCTEndoPeriapicalLesionLocalization {
  return {
    ToothNumberISO: 0,
    PeriapicalLesionDetected: false,
    HypodensityVolume: 0,
  };
}

export const ReportCBCTEndoPeriapicalLesionLocalization = {
  encode(
    message: ReportCBCTEndoPeriapicalLesionLocalization,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothNumberISO !== 0) {
      writer.uint32(8).uint32(message.ToothNumberISO);
    }
    if (message.PeriapicalLesionDetected === true) {
      writer.uint32(16).bool(message.PeriapicalLesionDetected);
    }
    if (message.HypodensityVolume !== 0) {
      writer.uint32(29).float(message.HypodensityVolume);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportCBCTEndoPeriapicalLesionLocalization {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTEndoPeriapicalLesionLocalization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothNumberISO = reader.uint32();
          break;
        case 2:
          message.PeriapicalLesionDetected = reader.bool();
          break;
        case 3:
          message.HypodensityVolume = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTEndoPeriapicalLesionLocalization {
    return {
      ToothNumberISO: isSet(object.ToothNumberISO)
        ? Number(object.ToothNumberISO)
        : 0,
      PeriapicalLesionDetected: isSet(object.PeriapicalLesionDetected)
        ? Boolean(object.PeriapicalLesionDetected)
        : false,
      HypodensityVolume: isSet(object.HypodensityVolume)
        ? Number(object.HypodensityVolume)
        : 0,
    };
  },

  toJSON(message: ReportCBCTEndoPeriapicalLesionLocalization): unknown {
    const obj: any = {};
    message.ToothNumberISO !== undefined &&
      (obj.ToothNumberISO = Math.round(message.ToothNumberISO));
    message.PeriapicalLesionDetected !== undefined &&
      (obj.PeriapicalLesionDetected = message.PeriapicalLesionDetected);
    message.HypodensityVolume !== undefined &&
      (obj.HypodensityVolume = message.HypodensityVolume);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportCBCTEndoPeriapicalLesionLocalization>, I>,
  >(object: I): ReportCBCTEndoPeriapicalLesionLocalization {
    const message = createBaseReportCBCTEndoPeriapicalLesionLocalization();
    message.ToothNumberISO = object.ToothNumberISO ?? 0;
    message.PeriapicalLesionDetected = object.PeriapicalLesionDetected ?? false;
    message.HypodensityVolume = object.HypodensityVolume ?? 0;
    return message;
  },
};

function createBaseReportCBCTImplant(): ReportCBCTImplant {
  return {
    CropGeometryData: undefined,
    SliceStep: 0,
    SliceThickness: 0,
    MeasurementScale: 0,
  };
}

export const ReportCBCTImplant = {
  encode(
    message: ReportCBCTImplant,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CropGeometryData !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.CropGeometryData,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.SliceStep !== 0) {
      writer.uint32(21).float(message.SliceStep);
    }
    if (message.SliceThickness !== 0) {
      writer.uint32(29).float(message.SliceThickness);
    }
    if (message.MeasurementScale !== 0) {
      writer.uint32(37).float(message.MeasurementScale);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportCBCTImplant {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTImplant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CropGeometryData = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.SliceStep = reader.float();
          break;
        case 3:
          message.SliceThickness = reader.float();
          break;
        case 4:
          message.MeasurementScale = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTImplant {
    return {
      CropGeometryData: isSet(object.CropGeometryData)
        ? CBCTSeriesGeometryData.fromJSON(object.CropGeometryData)
        : undefined,
      SliceStep: isSet(object.SliceStep) ? Number(object.SliceStep) : 0,
      SliceThickness: isSet(object.SliceThickness)
        ? Number(object.SliceThickness)
        : 0,
      MeasurementScale: isSet(object.MeasurementScale)
        ? Number(object.MeasurementScale)
        : 0,
    };
  },

  toJSON(message: ReportCBCTImplant): unknown {
    const obj: any = {};
    message.CropGeometryData !== undefined &&
      (obj.CropGeometryData = message.CropGeometryData
        ? CBCTSeriesGeometryData.toJSON(message.CropGeometryData)
        : undefined);
    message.SliceStep !== undefined && (obj.SliceStep = message.SliceStep);
    message.SliceThickness !== undefined &&
      (obj.SliceThickness = message.SliceThickness);
    message.MeasurementScale !== undefined &&
      (obj.MeasurementScale = message.MeasurementScale);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportCBCTImplant>, I>>(
    object: I,
  ): ReportCBCTImplant {
    const message = createBaseReportCBCTImplant();
    message.CropGeometryData =
      object.CropGeometryData !== undefined && object.CropGeometryData !== null
        ? CBCTSeriesGeometryData.fromPartial(object.CropGeometryData)
        : undefined;
    message.SliceStep = object.SliceStep ?? 0;
    message.SliceThickness = object.SliceThickness ?? 0;
    message.MeasurementScale = object.MeasurementScale ?? 0;
    return message;
  },
};

function createBaseReportCBCTMolar(): ReportCBCTMolar {
  return { CropGeometryData: undefined };
}

export const ReportCBCTMolar = {
  encode(
    message: ReportCBCTMolar,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CropGeometryData !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.CropGeometryData,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportCBCTMolar {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTMolar();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CropGeometryData = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTMolar {
    return {
      CropGeometryData: isSet(object.CropGeometryData)
        ? CBCTSeriesGeometryData.fromJSON(object.CropGeometryData)
        : undefined,
    };
  },

  toJSON(message: ReportCBCTMolar): unknown {
    const obj: any = {};
    message.CropGeometryData !== undefined &&
      (obj.CropGeometryData = message.CropGeometryData
        ? CBCTSeriesGeometryData.toJSON(message.CropGeometryData)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportCBCTMolar>, I>>(
    object: I,
  ): ReportCBCTMolar {
    const message = createBaseReportCBCTMolar();
    message.CropGeometryData =
      object.CropGeometryData !== undefined && object.CropGeometryData !== null
        ? CBCTSeriesGeometryData.fromPartial(object.CropGeometryData)
        : undefined;
    return message;
  },
};

function createBaseReportCBCTOrtho(): ReportCBCTOrtho {
  return {};
}

export const ReportCBCTOrtho = {
  encode(
    _: ReportCBCTOrtho,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportCBCTOrtho {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTOrtho();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ReportCBCTOrtho {
    return {};
  },

  toJSON(_: ReportCBCTOrtho): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportCBCTOrtho>, I>>(
    _: I,
  ): ReportCBCTOrtho {
    const message = createBaseReportCBCTOrtho();
    return message;
  },
};

function createBaseReportPanoGP(): ReportPanoGP {
  return {};
}

export const ReportPanoGP = {
  encode(
    _: ReportPanoGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportPanoGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportPanoGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ReportPanoGP {
    return {};
  },

  toJSON(_: ReportPanoGP): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportPanoGP>, I>>(
    _: I,
  ): ReportPanoGP {
    const message = createBaseReportPanoGP();
    return message;
  },
};

function createBaseReportDentalPhotoGP(): ReportDentalPhotoGP {
  return {};
}

export const ReportDentalPhotoGP = {
  encode(
    _: ReportDentalPhotoGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportDentalPhotoGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportDentalPhotoGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): ReportDentalPhotoGP {
    return {};
  },

  toJSON(_: ReportDentalPhotoGP): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportDentalPhotoGP>, I>>(
    _: I,
  ): ReportDentalPhotoGP {
    const message = createBaseReportDentalPhotoGP();
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
