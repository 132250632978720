import { getSlicesGroups } from './lib/getSlicesGroups';
import { getSmartSlicesGroups } from './lib/getSmartSlicesGroups';
import { updateSlicesGroupsByMasks } from './lib/updateSlicesGroupsByMasks';
import { sortSlicesByOrder } from './lib/sortSlicesByOrder';

export { useGetSelectedSlices, useGetSliceGroups } from './hooks';
export { assetsModel } from './model';
export * from './config/constants';
export * from './config/slices.types';

export { getImageSrc } from './lib/getImageSrc';

export const slicesLib = {
  getSlicesGroups,
  sortSlicesByOrder,
  getSmartSlicesGroups,
  updateSlicesGroupsByMasks,
};
