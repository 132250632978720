import { AssetContentGeneratedCBCTGPToothSlice } from '@/shared/api/protocol_gen/model/dto_asset_cbct_gp';
import { ToothCrossSectionOrientation } from '@/shared/api/protocol_gen/model/dto_asset_common';

import { SliceItem, Group, Guide } from '../config/slices.types';

import { groupSlicesByRole } from './groupSlicesByRole';

export const makeGroupByOrientation = (
  [groupName, groupCode]: Array<ToothCrossSectionOrientation | number | string>,
  sliceList: AssetContentGeneratedCBCTGPToothSlice[],
): Group => {
  const groupItems = sliceList?.filter(
    (item) => item?.Orientation === groupCode,
  );
  const slices: SliceItem[] = groupSlicesByRole(groupItems, 'slice');

  const guides: Guide[] = groupSlicesByRole(groupItems, 'guide');

  return {
    type: groupName,
    slices,
    guides,
  };
};
