// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BasicLayout-module__container--93eFQ5tL{min-height:100%;display:grid;grid-template-columns:auto 1fr auto;grid-template-rows:auto 1fr auto;grid-template-areas:\"header    header     header\" \"  .       content      .   \" \"footer    footer    footer\";background-color:var(--mainbg)}@media only screen and (min-width: 1400px){.BasicLayout-module__container--93eFQ5tL{grid-template-columns:auto 1400px auto}}", "",{"version":3,"sources":["webpack://./src/shared/ui/Layout/components/BasicLayout/BasicLayout.module.scss"],"names":[],"mappings":"AAEA,yCACE,eAAA,CACA,YAAA,CACA,mCAAA,CACA,gCAAA,CACA,4GACE,CAIF,8BAAA,CAGF,2CACE,yCACE,sCAAA,CAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  min-height: 100%;\n  display: grid;\n  grid-template-columns: auto 1fr auto;\n  grid-template-rows: auto 1fr auto;\n  grid-template-areas:\n    'header    header     header'\n    '  .       content      .   '\n    'footer    footer    footer';\n\n  background-color: var(--mainbg);\n}\n\n@media only screen and (min-width: $largeDesktop) {\n  .container {\n    grid-template-columns: auto $largeDesktop auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BasicLayout-module__container--93eFQ5tL"
};
export default ___CSS_LOADER_EXPORT___;
