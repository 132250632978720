/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import {
  ConditionCode,
  conditionCodeFromJSON,
  conditionCodeToJSON,
} from '../../model/dto_report_condition_codes';
import {
  Decision,
  decisionFromJSON,
  decisionToJSON,
} from '../../model/dto_report_common';
import { Observable } from 'rxjs';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';
import {
  Report,
  ReportSettings,
  ReportPrintSettings,
} from '../../model/dto_report';
import { Tooth } from '../../model/dto_report_tooth';
import { Empty } from '../../google/protobuf/empty';
import { Condition } from '../../model/dto_report_condition';
import { Patient } from '../../model/dto_patient';
import { Study } from '../../model/dto_study';
import { Asset } from '../../model/dto_asset';
import { User } from '../../model/dto_user';
import { ReportRequest } from '../../model/dto_report_request';

export const protobufPackage = 'com.diagnocat.core';

export interface ReportDataStreamReq {
  ReportID: string;
}

export interface ReportDataStreamResp {
  HistoricalReport: Report | undefined;
  HistoricalTooth: Tooth | undefined;
  /** `EndOfHistoricalTeeth` comes right after all historical teeth are received */
  EndOfHistoricalTeeth: Empty | undefined;
  HistoricalCondition: Condition | undefined;
  /** `EndOfHistoricalConditions` comes right after all historical conditions are received */
  EndOfHistoricalConditions: Empty | undefined;
  HistoricalPatient: Patient | undefined;
  HistoricalStudy: Study | undefined;
  /** `EndOfHistoricalStudies` comes right after all historical studies are received */
  EndOfHistoricalStudies: Empty | undefined;
  HistoricalAsset: Asset | undefined;
  /** `EndOfHistoricalAssets` comes right after all historical report assets are received */
  EndOfHistoricalAssets: Empty | undefined;
  HistoricalUser: User | undefined;
  /** `EndOfHistoricalUsers` comes right after all historical users are received */
  EndOfHistoricalUsers: Empty | undefined;
  UpdatedReport: Report | undefined;
  UpdatedTooth: Tooth | undefined;
  UpdatedCondition: Condition | undefined;
  UpdatedPatient: Patient | undefined;
  UpdatedStudy: Study | undefined;
  UpdatedAsset: Asset | undefined;
  UpdatedUser: User | undefined;
  /** `Ping` - just a stub ping message to ensure that the stream won't break by timeout */
  Ping: Empty | undefined;
}

export interface RequestReportReq {
  Request: ReportRequest | undefined;
}

export interface RequestReportResp {
  Report: Report | undefined;
}

export interface DeleteReportReq {
  ReportID: string;
}

export interface DeleteReportResp {
  DeletedReportID: string;
  DeletedToothIDs: string[];
  DeletedConditionIDs: string[];
  DeletedAssetIDs: string[];
  DeletedInvitationIDs: string[];
}

export interface SetROIReq {
  ReportID: string;
  /** array of tooth identifiers */
  ROIToothIDs: string[];
}

export interface SetROIResp {
  AllReportTeeth: Tooth[];
}

export interface SetReportConclusionReq {
  ReportID: string;
  /** https://draftjs.org/ */
  LegacyConclusion: string;
}

export interface SetReportConclusionResp {
  Report: Report | undefined;
}

export interface SetReportSettingsReq {
  ReportID: string;
  Settings: ReportSettings | undefined;
}

export interface SetReportSettingsResp {
  Report: Report | undefined;
}

export interface SetReportPrintSettingsReq {
  ReportID: string;
  PrintSettings: ReportPrintSettings | undefined;
}

export interface SetReportPrintSettingsResp {
  Report: Report | undefined;
}

export interface SignReportReq {
  ReportID: string;
}

export interface SignReportResp {
  Report: Report | undefined;
}

export interface CopyReportReq {
  ReportID: string;
  GeneratedVersion: boolean;
}

export interface CopyReportResp {
  Report: Report | undefined;
  Tooth: Tooth | undefined;
  Condition: Condition | undefined;
}

/**
 * //////////////////////////////////////////////////////////////
 * //////// @exclude TOOTH
 */
export interface SetReportToothCommentReq {
  ToothID: string;
  Comment: string;
}

export interface SetReportToothCommentResp {
  Tooth: Tooth | undefined;
}

export interface SetReportToothISONumberReq {
  ToothID: string;
  /** ISO number */
  ISONumber: number;
}

export interface SetReportToothISONumberResp {
  Tooth: Tooth | undefined;
}

export interface ApproveReportToothReq {
  ToothID: string;
  ToothConditionIDs: string[];
}

export interface ApproveReportToothResp {
  Tooth: Tooth | undefined;
  Conditions: Condition[];
}

export interface DisApproveReportToothReq {
  ToothID: string;
}

export interface DisApproveReportToothResp {
  Tooth: Tooth | undefined;
}

export interface ApproveAllTeethAndSignReportReq {
  ReportID: string;
  TeethConditionIDs: string[];
}

export interface ApproveAllTeethAndSignReportResp {
  Report: Report | undefined;
  Teeth: Tooth[];
  Conditions: Condition[];
}

export interface SetReportToothMissingReq {
  ToothID: string;
  IsMissing: boolean;
}

export interface SetReportToothMissingResp {
  Tooth: Tooth | undefined;
}

export interface SetReportToothSupernumeraryReq {
  ToothID: string;
  IsSupernumerary: boolean;
}

export interface SetReportToothSupernumeraryResp {
  Tooth: Tooth | undefined;
}

export interface SetReportToothControlReq {
  ToothID: string;
  IsControl: boolean;
}

export interface SetReportToothControlResp {
  Tooth: Tooth | undefined;
}

export interface ResetReportToothReq {
  ToothID: string;
  GeneratedVersion: boolean;
}

export interface ResetReportToothResp {
  Tooth: Tooth | undefined;
  Conditions: Condition[];
}

export interface AddToothDisplaySliceReq {
  ToothID: string;
  AssetID: string;
}

export interface AddToothDisplaySliceResp {
  Tooth: Tooth | undefined;
}

export interface RemoveToothDisplaySliceReq {
  ToothID: string;
  AssetID: string;
}

export interface RemoveToothDisplaySliceResp {
  Tooth: Tooth | undefined;
}

export interface CreateReportToothConditionsReq {
  ToothID: string;
  Conditions: CreateReportToothConditionsReq_Condition[];
}

export interface CreateReportToothConditionsReq_Condition {
  Code: ConditionCode;
  ParentConditionID: string;
}

/** Condition with UserDecision == PositiveDecision */
export interface CreateReportToothConditionsResp {
  Conditions: Condition[];
}

export interface SetReportToothConditionUserDecisionReq {
  ConditionID: string;
  UserDecision: Decision;
}

export interface SetReportToothConditionUserDecisionResp {
  Condition: Condition | undefined;
  Tooth: Tooth | undefined;
}

export interface ResetReportToothConditionsReq {
  ToothID: string;
  GeneratedVersion: boolean;
}

export interface ResetReportToothConditionsResp {
  Conditions: Condition[];
}

function createBaseReportDataStreamReq(): ReportDataStreamReq {
  return { ReportID: '' };
}

export const ReportDataStreamReq = {
  encode(
    message: ReportDataStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportDataStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportDataStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportDataStreamReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
    };
  },

  toJSON(message: ReportDataStreamReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportDataStreamReq>, I>>(
    object: I,
  ): ReportDataStreamReq {
    const message = createBaseReportDataStreamReq();
    message.ReportID = object.ReportID ?? '';
    return message;
  },
};

function createBaseReportDataStreamResp(): ReportDataStreamResp {
  return {
    HistoricalReport: undefined,
    HistoricalTooth: undefined,
    EndOfHistoricalTeeth: undefined,
    HistoricalCondition: undefined,
    EndOfHistoricalConditions: undefined,
    HistoricalPatient: undefined,
    HistoricalStudy: undefined,
    EndOfHistoricalStudies: undefined,
    HistoricalAsset: undefined,
    EndOfHistoricalAssets: undefined,
    HistoricalUser: undefined,
    EndOfHistoricalUsers: undefined,
    UpdatedReport: undefined,
    UpdatedTooth: undefined,
    UpdatedCondition: undefined,
    UpdatedPatient: undefined,
    UpdatedStudy: undefined,
    UpdatedAsset: undefined,
    UpdatedUser: undefined,
    Ping: undefined,
  };
}

export const ReportDataStreamResp = {
  encode(
    message: ReportDataStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.HistoricalReport !== undefined) {
      Report.encode(
        message.HistoricalReport,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.HistoricalTooth !== undefined) {
      Tooth.encode(message.HistoricalTooth, writer.uint32(18).fork()).ldelim();
    }
    if (message.EndOfHistoricalTeeth !== undefined) {
      Empty.encode(
        message.EndOfHistoricalTeeth,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.HistoricalCondition !== undefined) {
      Condition.encode(
        message.HistoricalCondition,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalConditions !== undefined) {
      Empty.encode(
        message.EndOfHistoricalConditions,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.HistoricalPatient !== undefined) {
      Patient.encode(
        message.HistoricalPatient,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    if (message.HistoricalStudy !== undefined) {
      Study.encode(message.HistoricalStudy, writer.uint32(58).fork()).ldelim();
    }
    if (message.EndOfHistoricalStudies !== undefined) {
      Empty.encode(
        message.EndOfHistoricalStudies,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    if (message.HistoricalAsset !== undefined) {
      Asset.encode(message.HistoricalAsset, writer.uint32(74).fork()).ldelim();
    }
    if (message.EndOfHistoricalAssets !== undefined) {
      Empty.encode(
        message.EndOfHistoricalAssets,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    if (message.HistoricalUser !== undefined) {
      User.encode(message.HistoricalUser, writer.uint32(90).fork()).ldelim();
    }
    if (message.EndOfHistoricalUsers !== undefined) {
      Empty.encode(
        message.EndOfHistoricalUsers,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.UpdatedReport !== undefined) {
      Report.encode(message.UpdatedReport, writer.uint32(170).fork()).ldelim();
    }
    if (message.UpdatedTooth !== undefined) {
      Tooth.encode(message.UpdatedTooth, writer.uint32(178).fork()).ldelim();
    }
    if (message.UpdatedCondition !== undefined) {
      Condition.encode(
        message.UpdatedCondition,
        writer.uint32(186).fork(),
      ).ldelim();
    }
    if (message.UpdatedPatient !== undefined) {
      Patient.encode(
        message.UpdatedPatient,
        writer.uint32(194).fork(),
      ).ldelim();
    }
    if (message.UpdatedStudy !== undefined) {
      Study.encode(message.UpdatedStudy, writer.uint32(202).fork()).ldelim();
    }
    if (message.UpdatedAsset !== undefined) {
      Asset.encode(message.UpdatedAsset, writer.uint32(210).fork()).ldelim();
    }
    if (message.UpdatedUser !== undefined) {
      User.encode(message.UpdatedUser, writer.uint32(218).fork()).ldelim();
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportDataStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportDataStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.HistoricalReport = Report.decode(reader, reader.uint32());
          break;
        case 2:
          message.HistoricalTooth = Tooth.decode(reader, reader.uint32());
          break;
        case 3:
          message.EndOfHistoricalTeeth = Empty.decode(reader, reader.uint32());
          break;
        case 4:
          message.HistoricalCondition = Condition.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.EndOfHistoricalConditions = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 6:
          message.HistoricalPatient = Patient.decode(reader, reader.uint32());
          break;
        case 7:
          message.HistoricalStudy = Study.decode(reader, reader.uint32());
          break;
        case 8:
          message.EndOfHistoricalStudies = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 9:
          message.HistoricalAsset = Asset.decode(reader, reader.uint32());
          break;
        case 10:
          message.EndOfHistoricalAssets = Empty.decode(reader, reader.uint32());
          break;
        case 11:
          message.HistoricalUser = User.decode(reader, reader.uint32());
          break;
        case 12:
          message.EndOfHistoricalUsers = Empty.decode(reader, reader.uint32());
          break;
        case 21:
          message.UpdatedReport = Report.decode(reader, reader.uint32());
          break;
        case 22:
          message.UpdatedTooth = Tooth.decode(reader, reader.uint32());
          break;
        case 23:
          message.UpdatedCondition = Condition.decode(reader, reader.uint32());
          break;
        case 24:
          message.UpdatedPatient = Patient.decode(reader, reader.uint32());
          break;
        case 25:
          message.UpdatedStudy = Study.decode(reader, reader.uint32());
          break;
        case 26:
          message.UpdatedAsset = Asset.decode(reader, reader.uint32());
          break;
        case 27:
          message.UpdatedUser = User.decode(reader, reader.uint32());
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportDataStreamResp {
    return {
      HistoricalReport: isSet(object.HistoricalReport)
        ? Report.fromJSON(object.HistoricalReport)
        : undefined,
      HistoricalTooth: isSet(object.HistoricalTooth)
        ? Tooth.fromJSON(object.HistoricalTooth)
        : undefined,
      EndOfHistoricalTeeth: isSet(object.EndOfHistoricalTeeth)
        ? Empty.fromJSON(object.EndOfHistoricalTeeth)
        : undefined,
      HistoricalCondition: isSet(object.HistoricalCondition)
        ? Condition.fromJSON(object.HistoricalCondition)
        : undefined,
      EndOfHistoricalConditions: isSet(object.EndOfHistoricalConditions)
        ? Empty.fromJSON(object.EndOfHistoricalConditions)
        : undefined,
      HistoricalPatient: isSet(object.HistoricalPatient)
        ? Patient.fromJSON(object.HistoricalPatient)
        : undefined,
      HistoricalStudy: isSet(object.HistoricalStudy)
        ? Study.fromJSON(object.HistoricalStudy)
        : undefined,
      EndOfHistoricalStudies: isSet(object.EndOfHistoricalStudies)
        ? Empty.fromJSON(object.EndOfHistoricalStudies)
        : undefined,
      HistoricalAsset: isSet(object.HistoricalAsset)
        ? Asset.fromJSON(object.HistoricalAsset)
        : undefined,
      EndOfHistoricalAssets: isSet(object.EndOfHistoricalAssets)
        ? Empty.fromJSON(object.EndOfHistoricalAssets)
        : undefined,
      HistoricalUser: isSet(object.HistoricalUser)
        ? User.fromJSON(object.HistoricalUser)
        : undefined,
      EndOfHistoricalUsers: isSet(object.EndOfHistoricalUsers)
        ? Empty.fromJSON(object.EndOfHistoricalUsers)
        : undefined,
      UpdatedReport: isSet(object.UpdatedReport)
        ? Report.fromJSON(object.UpdatedReport)
        : undefined,
      UpdatedTooth: isSet(object.UpdatedTooth)
        ? Tooth.fromJSON(object.UpdatedTooth)
        : undefined,
      UpdatedCondition: isSet(object.UpdatedCondition)
        ? Condition.fromJSON(object.UpdatedCondition)
        : undefined,
      UpdatedPatient: isSet(object.UpdatedPatient)
        ? Patient.fromJSON(object.UpdatedPatient)
        : undefined,
      UpdatedStudy: isSet(object.UpdatedStudy)
        ? Study.fromJSON(object.UpdatedStudy)
        : undefined,
      UpdatedAsset: isSet(object.UpdatedAsset)
        ? Asset.fromJSON(object.UpdatedAsset)
        : undefined,
      UpdatedUser: isSet(object.UpdatedUser)
        ? User.fromJSON(object.UpdatedUser)
        : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: ReportDataStreamResp): unknown {
    const obj: any = {};
    message.HistoricalReport !== undefined &&
      (obj.HistoricalReport = message.HistoricalReport
        ? Report.toJSON(message.HistoricalReport)
        : undefined);
    message.HistoricalTooth !== undefined &&
      (obj.HistoricalTooth = message.HistoricalTooth
        ? Tooth.toJSON(message.HistoricalTooth)
        : undefined);
    message.EndOfHistoricalTeeth !== undefined &&
      (obj.EndOfHistoricalTeeth = message.EndOfHistoricalTeeth
        ? Empty.toJSON(message.EndOfHistoricalTeeth)
        : undefined);
    message.HistoricalCondition !== undefined &&
      (obj.HistoricalCondition = message.HistoricalCondition
        ? Condition.toJSON(message.HistoricalCondition)
        : undefined);
    message.EndOfHistoricalConditions !== undefined &&
      (obj.EndOfHistoricalConditions = message.EndOfHistoricalConditions
        ? Empty.toJSON(message.EndOfHistoricalConditions)
        : undefined);
    message.HistoricalPatient !== undefined &&
      (obj.HistoricalPatient = message.HistoricalPatient
        ? Patient.toJSON(message.HistoricalPatient)
        : undefined);
    message.HistoricalStudy !== undefined &&
      (obj.HistoricalStudy = message.HistoricalStudy
        ? Study.toJSON(message.HistoricalStudy)
        : undefined);
    message.EndOfHistoricalStudies !== undefined &&
      (obj.EndOfHistoricalStudies = message.EndOfHistoricalStudies
        ? Empty.toJSON(message.EndOfHistoricalStudies)
        : undefined);
    message.HistoricalAsset !== undefined &&
      (obj.HistoricalAsset = message.HistoricalAsset
        ? Asset.toJSON(message.HistoricalAsset)
        : undefined);
    message.EndOfHistoricalAssets !== undefined &&
      (obj.EndOfHistoricalAssets = message.EndOfHistoricalAssets
        ? Empty.toJSON(message.EndOfHistoricalAssets)
        : undefined);
    message.HistoricalUser !== undefined &&
      (obj.HistoricalUser = message.HistoricalUser
        ? User.toJSON(message.HistoricalUser)
        : undefined);
    message.EndOfHistoricalUsers !== undefined &&
      (obj.EndOfHistoricalUsers = message.EndOfHistoricalUsers
        ? Empty.toJSON(message.EndOfHistoricalUsers)
        : undefined);
    message.UpdatedReport !== undefined &&
      (obj.UpdatedReport = message.UpdatedReport
        ? Report.toJSON(message.UpdatedReport)
        : undefined);
    message.UpdatedTooth !== undefined &&
      (obj.UpdatedTooth = message.UpdatedTooth
        ? Tooth.toJSON(message.UpdatedTooth)
        : undefined);
    message.UpdatedCondition !== undefined &&
      (obj.UpdatedCondition = message.UpdatedCondition
        ? Condition.toJSON(message.UpdatedCondition)
        : undefined);
    message.UpdatedPatient !== undefined &&
      (obj.UpdatedPatient = message.UpdatedPatient
        ? Patient.toJSON(message.UpdatedPatient)
        : undefined);
    message.UpdatedStudy !== undefined &&
      (obj.UpdatedStudy = message.UpdatedStudy
        ? Study.toJSON(message.UpdatedStudy)
        : undefined);
    message.UpdatedAsset !== undefined &&
      (obj.UpdatedAsset = message.UpdatedAsset
        ? Asset.toJSON(message.UpdatedAsset)
        : undefined);
    message.UpdatedUser !== undefined &&
      (obj.UpdatedUser = message.UpdatedUser
        ? User.toJSON(message.UpdatedUser)
        : undefined);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportDataStreamResp>, I>>(
    object: I,
  ): ReportDataStreamResp {
    const message = createBaseReportDataStreamResp();
    message.HistoricalReport =
      object.HistoricalReport !== undefined && object.HistoricalReport !== null
        ? Report.fromPartial(object.HistoricalReport)
        : undefined;
    message.HistoricalTooth =
      object.HistoricalTooth !== undefined && object.HistoricalTooth !== null
        ? Tooth.fromPartial(object.HistoricalTooth)
        : undefined;
    message.EndOfHistoricalTeeth =
      object.EndOfHistoricalTeeth !== undefined &&
      object.EndOfHistoricalTeeth !== null
        ? Empty.fromPartial(object.EndOfHistoricalTeeth)
        : undefined;
    message.HistoricalCondition =
      object.HistoricalCondition !== undefined &&
      object.HistoricalCondition !== null
        ? Condition.fromPartial(object.HistoricalCondition)
        : undefined;
    message.EndOfHistoricalConditions =
      object.EndOfHistoricalConditions !== undefined &&
      object.EndOfHistoricalConditions !== null
        ? Empty.fromPartial(object.EndOfHistoricalConditions)
        : undefined;
    message.HistoricalPatient =
      object.HistoricalPatient !== undefined &&
      object.HistoricalPatient !== null
        ? Patient.fromPartial(object.HistoricalPatient)
        : undefined;
    message.HistoricalStudy =
      object.HistoricalStudy !== undefined && object.HistoricalStudy !== null
        ? Study.fromPartial(object.HistoricalStudy)
        : undefined;
    message.EndOfHistoricalStudies =
      object.EndOfHistoricalStudies !== undefined &&
      object.EndOfHistoricalStudies !== null
        ? Empty.fromPartial(object.EndOfHistoricalStudies)
        : undefined;
    message.HistoricalAsset =
      object.HistoricalAsset !== undefined && object.HistoricalAsset !== null
        ? Asset.fromPartial(object.HistoricalAsset)
        : undefined;
    message.EndOfHistoricalAssets =
      object.EndOfHistoricalAssets !== undefined &&
      object.EndOfHistoricalAssets !== null
        ? Empty.fromPartial(object.EndOfHistoricalAssets)
        : undefined;
    message.HistoricalUser =
      object.HistoricalUser !== undefined && object.HistoricalUser !== null
        ? User.fromPartial(object.HistoricalUser)
        : undefined;
    message.EndOfHistoricalUsers =
      object.EndOfHistoricalUsers !== undefined &&
      object.EndOfHistoricalUsers !== null
        ? Empty.fromPartial(object.EndOfHistoricalUsers)
        : undefined;
    message.UpdatedReport =
      object.UpdatedReport !== undefined && object.UpdatedReport !== null
        ? Report.fromPartial(object.UpdatedReport)
        : undefined;
    message.UpdatedTooth =
      object.UpdatedTooth !== undefined && object.UpdatedTooth !== null
        ? Tooth.fromPartial(object.UpdatedTooth)
        : undefined;
    message.UpdatedCondition =
      object.UpdatedCondition !== undefined && object.UpdatedCondition !== null
        ? Condition.fromPartial(object.UpdatedCondition)
        : undefined;
    message.UpdatedPatient =
      object.UpdatedPatient !== undefined && object.UpdatedPatient !== null
        ? Patient.fromPartial(object.UpdatedPatient)
        : undefined;
    message.UpdatedStudy =
      object.UpdatedStudy !== undefined && object.UpdatedStudy !== null
        ? Study.fromPartial(object.UpdatedStudy)
        : undefined;
    message.UpdatedAsset =
      object.UpdatedAsset !== undefined && object.UpdatedAsset !== null
        ? Asset.fromPartial(object.UpdatedAsset)
        : undefined;
    message.UpdatedUser =
      object.UpdatedUser !== undefined && object.UpdatedUser !== null
        ? User.fromPartial(object.UpdatedUser)
        : undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBaseRequestReportReq(): RequestReportReq {
  return { Request: undefined };
}

export const RequestReportReq = {
  encode(
    message: RequestReportReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Request !== undefined) {
      ReportRequest.encode(message.Request, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RequestReportReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Request = ReportRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RequestReportReq {
    return {
      Request: isSet(object.Request)
        ? ReportRequest.fromJSON(object.Request)
        : undefined,
    };
  },

  toJSON(message: RequestReportReq): unknown {
    const obj: any = {};
    message.Request !== undefined &&
      (obj.Request = message.Request
        ? ReportRequest.toJSON(message.Request)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RequestReportReq>, I>>(
    object: I,
  ): RequestReportReq {
    const message = createBaseRequestReportReq();
    message.Request =
      object.Request !== undefined && object.Request !== null
        ? ReportRequest.fromPartial(object.Request)
        : undefined;
    return message;
  },
};

function createBaseRequestReportResp(): RequestReportResp {
  return { Report: undefined };
}

export const RequestReportResp = {
  encode(
    message: RequestReportResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RequestReportResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestReportResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RequestReportResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: RequestReportResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RequestReportResp>, I>>(
    object: I,
  ): RequestReportResp {
    const message = createBaseRequestReportResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseDeleteReportReq(): DeleteReportReq {
  return { ReportID: '' };
}

export const DeleteReportReq = {
  encode(
    message: DeleteReportReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteReportReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteReportReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
    };
  },

  toJSON(message: DeleteReportReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteReportReq>, I>>(
    object: I,
  ): DeleteReportReq {
    const message = createBaseDeleteReportReq();
    message.ReportID = object.ReportID ?? '';
    return message;
  },
};

function createBaseDeleteReportResp(): DeleteReportResp {
  return {
    DeletedReportID: '',
    DeletedToothIDs: [],
    DeletedConditionIDs: [],
    DeletedAssetIDs: [],
    DeletedInvitationIDs: [],
  };
}

export const DeleteReportResp = {
  encode(
    message: DeleteReportResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DeletedReportID !== '') {
      writer.uint32(10).string(message.DeletedReportID);
    }
    for (const v of message.DeletedToothIDs) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.DeletedConditionIDs) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.DeletedAssetIDs) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.DeletedInvitationIDs) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteReportResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteReportResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DeletedReportID = reader.string();
          break;
        case 2:
          message.DeletedToothIDs.push(reader.string());
          break;
        case 3:
          message.DeletedConditionIDs.push(reader.string());
          break;
        case 4:
          message.DeletedAssetIDs.push(reader.string());
          break;
        case 5:
          message.DeletedInvitationIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteReportResp {
    return {
      DeletedReportID: isSet(object.DeletedReportID)
        ? String(object.DeletedReportID)
        : '',
      DeletedToothIDs: Array.isArray(object?.DeletedToothIDs)
        ? object.DeletedToothIDs.map((e: any) => String(e))
        : [],
      DeletedConditionIDs: Array.isArray(object?.DeletedConditionIDs)
        ? object.DeletedConditionIDs.map((e: any) => String(e))
        : [],
      DeletedAssetIDs: Array.isArray(object?.DeletedAssetIDs)
        ? object.DeletedAssetIDs.map((e: any) => String(e))
        : [],
      DeletedInvitationIDs: Array.isArray(object?.DeletedInvitationIDs)
        ? object.DeletedInvitationIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: DeleteReportResp): unknown {
    const obj: any = {};
    message.DeletedReportID !== undefined &&
      (obj.DeletedReportID = message.DeletedReportID);
    if (message.DeletedToothIDs) {
      obj.DeletedToothIDs = message.DeletedToothIDs.map((e) => e);
    } else {
      obj.DeletedToothIDs = [];
    }
    if (message.DeletedConditionIDs) {
      obj.DeletedConditionIDs = message.DeletedConditionIDs.map((e) => e);
    } else {
      obj.DeletedConditionIDs = [];
    }
    if (message.DeletedAssetIDs) {
      obj.DeletedAssetIDs = message.DeletedAssetIDs.map((e) => e);
    } else {
      obj.DeletedAssetIDs = [];
    }
    if (message.DeletedInvitationIDs) {
      obj.DeletedInvitationIDs = message.DeletedInvitationIDs.map((e) => e);
    } else {
      obj.DeletedInvitationIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteReportResp>, I>>(
    object: I,
  ): DeleteReportResp {
    const message = createBaseDeleteReportResp();
    message.DeletedReportID = object.DeletedReportID ?? '';
    message.DeletedToothIDs = object.DeletedToothIDs?.map((e) => e) || [];
    message.DeletedConditionIDs =
      object.DeletedConditionIDs?.map((e) => e) || [];
    message.DeletedAssetIDs = object.DeletedAssetIDs?.map((e) => e) || [];
    message.DeletedInvitationIDs =
      object.DeletedInvitationIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetROIReq(): SetROIReq {
  return { ReportID: '', ROIToothIDs: [] };
}

export const SetROIReq = {
  encode(
    message: SetROIReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    for (const v of message.ROIToothIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetROIReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetROIReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.ROIToothIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetROIReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      ROIToothIDs: Array.isArray(object?.ROIToothIDs)
        ? object.ROIToothIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: SetROIReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    if (message.ROIToothIDs) {
      obj.ROIToothIDs = message.ROIToothIDs.map((e) => e);
    } else {
      obj.ROIToothIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetROIReq>, I>>(
    object: I,
  ): SetROIReq {
    const message = createBaseSetROIReq();
    message.ReportID = object.ReportID ?? '';
    message.ROIToothIDs = object.ROIToothIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetROIResp(): SetROIResp {
  return { AllReportTeeth: [] };
}

export const SetROIResp = {
  encode(
    message: SetROIResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.AllReportTeeth) {
      Tooth.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetROIResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetROIResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AllReportTeeth.push(Tooth.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetROIResp {
    return {
      AllReportTeeth: Array.isArray(object?.AllReportTeeth)
        ? object.AllReportTeeth.map((e: any) => Tooth.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetROIResp): unknown {
    const obj: any = {};
    if (message.AllReportTeeth) {
      obj.AllReportTeeth = message.AllReportTeeth.map((e) =>
        e ? Tooth.toJSON(e) : undefined,
      );
    } else {
      obj.AllReportTeeth = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetROIResp>, I>>(
    object: I,
  ): SetROIResp {
    const message = createBaseSetROIResp();
    message.AllReportTeeth =
      object.AllReportTeeth?.map((e) => Tooth.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetReportConclusionReq(): SetReportConclusionReq {
  return { ReportID: '', LegacyConclusion: '' };
}

export const SetReportConclusionReq = {
  encode(
    message: SetReportConclusionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    if (message.LegacyConclusion !== '') {
      writer.uint32(18).string(message.LegacyConclusion);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportConclusionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportConclusionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.LegacyConclusion = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportConclusionReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      LegacyConclusion: isSet(object.LegacyConclusion)
        ? String(object.LegacyConclusion)
        : '',
    };
  },

  toJSON(message: SetReportConclusionReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.LegacyConclusion !== undefined &&
      (obj.LegacyConclusion = message.LegacyConclusion);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportConclusionReq>, I>>(
    object: I,
  ): SetReportConclusionReq {
    const message = createBaseSetReportConclusionReq();
    message.ReportID = object.ReportID ?? '';
    message.LegacyConclusion = object.LegacyConclusion ?? '';
    return message;
  },
};

function createBaseSetReportConclusionResp(): SetReportConclusionResp {
  return { Report: undefined };
}

export const SetReportConclusionResp = {
  encode(
    message: SetReportConclusionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportConclusionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportConclusionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportConclusionResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SetReportConclusionResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportConclusionResp>, I>>(
    object: I,
  ): SetReportConclusionResp {
    const message = createBaseSetReportConclusionResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSetReportSettingsReq(): SetReportSettingsReq {
  return { ReportID: '', Settings: undefined };
}

export const SetReportSettingsReq = {
  encode(
    message: SetReportSettingsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    if (message.Settings !== undefined) {
      ReportSettings.encode(
        message.Settings,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportSettingsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportSettingsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.Settings = ReportSettings.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportSettingsReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      Settings: isSet(object.Settings)
        ? ReportSettings.fromJSON(object.Settings)
        : undefined,
    };
  },

  toJSON(message: SetReportSettingsReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.Settings !== undefined &&
      (obj.Settings = message.Settings
        ? ReportSettings.toJSON(message.Settings)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportSettingsReq>, I>>(
    object: I,
  ): SetReportSettingsReq {
    const message = createBaseSetReportSettingsReq();
    message.ReportID = object.ReportID ?? '';
    message.Settings =
      object.Settings !== undefined && object.Settings !== null
        ? ReportSettings.fromPartial(object.Settings)
        : undefined;
    return message;
  },
};

function createBaseSetReportSettingsResp(): SetReportSettingsResp {
  return { Report: undefined };
}

export const SetReportSettingsResp = {
  encode(
    message: SetReportSettingsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportSettingsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportSettingsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportSettingsResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SetReportSettingsResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportSettingsResp>, I>>(
    object: I,
  ): SetReportSettingsResp {
    const message = createBaseSetReportSettingsResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSetReportPrintSettingsReq(): SetReportPrintSettingsReq {
  return { ReportID: '', PrintSettings: undefined };
}

export const SetReportPrintSettingsReq = {
  encode(
    message: SetReportPrintSettingsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    if (message.PrintSettings !== undefined) {
      ReportPrintSettings.encode(
        message.PrintSettings,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportPrintSettingsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportPrintSettingsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.PrintSettings = ReportPrintSettings.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportPrintSettingsReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      PrintSettings: isSet(object.PrintSettings)
        ? ReportPrintSettings.fromJSON(object.PrintSettings)
        : undefined,
    };
  },

  toJSON(message: SetReportPrintSettingsReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.PrintSettings !== undefined &&
      (obj.PrintSettings = message.PrintSettings
        ? ReportPrintSettings.toJSON(message.PrintSettings)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportPrintSettingsReq>, I>>(
    object: I,
  ): SetReportPrintSettingsReq {
    const message = createBaseSetReportPrintSettingsReq();
    message.ReportID = object.ReportID ?? '';
    message.PrintSettings =
      object.PrintSettings !== undefined && object.PrintSettings !== null
        ? ReportPrintSettings.fromPartial(object.PrintSettings)
        : undefined;
    return message;
  },
};

function createBaseSetReportPrintSettingsResp(): SetReportPrintSettingsResp {
  return { Report: undefined };
}

export const SetReportPrintSettingsResp = {
  encode(
    message: SetReportPrintSettingsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportPrintSettingsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportPrintSettingsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportPrintSettingsResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SetReportPrintSettingsResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportPrintSettingsResp>, I>>(
    object: I,
  ): SetReportPrintSettingsResp {
    const message = createBaseSetReportPrintSettingsResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSignReportReq(): SignReportReq {
  return { ReportID: '' };
}

export const SignReportReq = {
  encode(
    message: SignReportReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignReportReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignReportReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
    };
  },

  toJSON(message: SignReportReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignReportReq>, I>>(
    object: I,
  ): SignReportReq {
    const message = createBaseSignReportReq();
    message.ReportID = object.ReportID ?? '';
    return message;
  },
};

function createBaseSignReportResp(): SignReportResp {
  return { Report: undefined };
}

export const SignReportResp = {
  encode(
    message: SignReportResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignReportResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignReportResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignReportResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SignReportResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignReportResp>, I>>(
    object: I,
  ): SignReportResp {
    const message = createBaseSignReportResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseCopyReportReq(): CopyReportReq {
  return { ReportID: '', GeneratedVersion: false };
}

export const CopyReportReq = {
  encode(
    message: CopyReportReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    if (message.GeneratedVersion === true) {
      writer.uint32(16).bool(message.GeneratedVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CopyReportReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCopyReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.GeneratedVersion = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CopyReportReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      GeneratedVersion: isSet(object.GeneratedVersion)
        ? Boolean(object.GeneratedVersion)
        : false,
    };
  },

  toJSON(message: CopyReportReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.GeneratedVersion !== undefined &&
      (obj.GeneratedVersion = message.GeneratedVersion);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CopyReportReq>, I>>(
    object: I,
  ): CopyReportReq {
    const message = createBaseCopyReportReq();
    message.ReportID = object.ReportID ?? '';
    message.GeneratedVersion = object.GeneratedVersion ?? false;
    return message;
  },
};

function createBaseCopyReportResp(): CopyReportResp {
  return { Report: undefined, Tooth: undefined, Condition: undefined };
}

export const CopyReportResp = {
  encode(
    message: CopyReportResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(18).fork()).ldelim();
    }
    if (message.Condition !== undefined) {
      Condition.encode(message.Condition, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CopyReportResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCopyReportResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        case 2:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        case 3:
          message.Condition = Condition.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CopyReportResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
      Condition: isSet(object.Condition)
        ? Condition.fromJSON(object.Condition)
        : undefined,
    };
  },

  toJSON(message: CopyReportResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    message.Condition !== undefined &&
      (obj.Condition = message.Condition
        ? Condition.toJSON(message.Condition)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CopyReportResp>, I>>(
    object: I,
  ): CopyReportResp {
    const message = createBaseCopyReportResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    message.Condition =
      object.Condition !== undefined && object.Condition !== null
        ? Condition.fromPartial(object.Condition)
        : undefined;
    return message;
  },
};

function createBaseSetReportToothCommentReq(): SetReportToothCommentReq {
  return { ToothID: '', Comment: '' };
}

export const SetReportToothCommentReq = {
  encode(
    message: SetReportToothCommentReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.Comment !== '') {
      writer.uint32(18).string(message.Comment);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothCommentReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothCommentReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.Comment = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothCommentReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      Comment: isSet(object.Comment) ? String(object.Comment) : '',
    };
  },

  toJSON(message: SetReportToothCommentReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.Comment !== undefined && (obj.Comment = message.Comment);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothCommentReq>, I>>(
    object: I,
  ): SetReportToothCommentReq {
    const message = createBaseSetReportToothCommentReq();
    message.ToothID = object.ToothID ?? '';
    message.Comment = object.Comment ?? '';
    return message;
  },
};

function createBaseSetReportToothCommentResp(): SetReportToothCommentResp {
  return { Tooth: undefined };
}

export const SetReportToothCommentResp = {
  encode(
    message: SetReportToothCommentResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothCommentResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothCommentResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothCommentResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: SetReportToothCommentResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothCommentResp>, I>>(
    object: I,
  ): SetReportToothCommentResp {
    const message = createBaseSetReportToothCommentResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

function createBaseSetReportToothISONumberReq(): SetReportToothISONumberReq {
  return { ToothID: '', ISONumber: 0 };
}

export const SetReportToothISONumberReq = {
  encode(
    message: SetReportToothISONumberReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.ISONumber !== 0) {
      writer.uint32(16).uint32(message.ISONumber);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothISONumberReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothISONumberReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.ISONumber = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothISONumberReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      ISONumber: isSet(object.ISONumber) ? Number(object.ISONumber) : 0,
    };
  },

  toJSON(message: SetReportToothISONumberReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.ISONumber !== undefined &&
      (obj.ISONumber = Math.round(message.ISONumber));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothISONumberReq>, I>>(
    object: I,
  ): SetReportToothISONumberReq {
    const message = createBaseSetReportToothISONumberReq();
    message.ToothID = object.ToothID ?? '';
    message.ISONumber = object.ISONumber ?? 0;
    return message;
  },
};

function createBaseSetReportToothISONumberResp(): SetReportToothISONumberResp {
  return { Tooth: undefined };
}

export const SetReportToothISONumberResp = {
  encode(
    message: SetReportToothISONumberResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothISONumberResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothISONumberResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothISONumberResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: SetReportToothISONumberResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothISONumberResp>, I>>(
    object: I,
  ): SetReportToothISONumberResp {
    const message = createBaseSetReportToothISONumberResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

function createBaseApproveReportToothReq(): ApproveReportToothReq {
  return { ToothID: '', ToothConditionIDs: [] };
}

export const ApproveReportToothReq = {
  encode(
    message: ApproveReportToothReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    for (const v of message.ToothConditionIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ApproveReportToothReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveReportToothReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.ToothConditionIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ApproveReportToothReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      ToothConditionIDs: Array.isArray(object?.ToothConditionIDs)
        ? object.ToothConditionIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: ApproveReportToothReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    if (message.ToothConditionIDs) {
      obj.ToothConditionIDs = message.ToothConditionIDs.map((e) => e);
    } else {
      obj.ToothConditionIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ApproveReportToothReq>, I>>(
    object: I,
  ): ApproveReportToothReq {
    const message = createBaseApproveReportToothReq();
    message.ToothID = object.ToothID ?? '';
    message.ToothConditionIDs = object.ToothConditionIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseApproveReportToothResp(): ApproveReportToothResp {
  return { Tooth: undefined, Conditions: [] };
}

export const ApproveReportToothResp = {
  encode(
    message: ApproveReportToothResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Conditions) {
      Condition.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ApproveReportToothResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveReportToothResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        case 2:
          message.Conditions.push(Condition.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ApproveReportToothResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) => Condition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ApproveReportToothResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ApproveReportToothResp>, I>>(
    object: I,
  ): ApproveReportToothResp {
    const message = createBaseApproveReportToothResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    message.Conditions =
      object.Conditions?.map((e) => Condition.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDisApproveReportToothReq(): DisApproveReportToothReq {
  return { ToothID: '' };
}

export const DisApproveReportToothReq = {
  encode(
    message: DisApproveReportToothReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DisApproveReportToothReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDisApproveReportToothReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DisApproveReportToothReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
    };
  },

  toJSON(message: DisApproveReportToothReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DisApproveReportToothReq>, I>>(
    object: I,
  ): DisApproveReportToothReq {
    const message = createBaseDisApproveReportToothReq();
    message.ToothID = object.ToothID ?? '';
    return message;
  },
};

function createBaseDisApproveReportToothResp(): DisApproveReportToothResp {
  return { Tooth: undefined };
}

export const DisApproveReportToothResp = {
  encode(
    message: DisApproveReportToothResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DisApproveReportToothResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDisApproveReportToothResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DisApproveReportToothResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: DisApproveReportToothResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DisApproveReportToothResp>, I>>(
    object: I,
  ): DisApproveReportToothResp {
    const message = createBaseDisApproveReportToothResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

function createBaseApproveAllTeethAndSignReportReq(): ApproveAllTeethAndSignReportReq {
  return { ReportID: '', TeethConditionIDs: [] };
}

export const ApproveAllTeethAndSignReportReq = {
  encode(
    message: ApproveAllTeethAndSignReportReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    for (const v of message.TeethConditionIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ApproveAllTeethAndSignReportReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveAllTeethAndSignReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.TeethConditionIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ApproveAllTeethAndSignReportReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      TeethConditionIDs: Array.isArray(object?.TeethConditionIDs)
        ? object.TeethConditionIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: ApproveAllTeethAndSignReportReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    if (message.TeethConditionIDs) {
      obj.TeethConditionIDs = message.TeethConditionIDs.map((e) => e);
    } else {
      obj.TeethConditionIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ApproveAllTeethAndSignReportReq>, I>>(
    object: I,
  ): ApproveAllTeethAndSignReportReq {
    const message = createBaseApproveAllTeethAndSignReportReq();
    message.ReportID = object.ReportID ?? '';
    message.TeethConditionIDs = object.TeethConditionIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseApproveAllTeethAndSignReportResp(): ApproveAllTeethAndSignReportResp {
  return { Report: undefined, Teeth: [], Conditions: [] };
}

export const ApproveAllTeethAndSignReportResp = {
  encode(
    message: ApproveAllTeethAndSignReportResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Teeth) {
      Tooth.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.Conditions) {
      Condition.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ApproveAllTeethAndSignReportResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveAllTeethAndSignReportResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        case 2:
          message.Teeth.push(Tooth.decode(reader, reader.uint32()));
          break;
        case 3:
          message.Conditions.push(Condition.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ApproveAllTeethAndSignReportResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
      Teeth: Array.isArray(object?.Teeth)
        ? object.Teeth.map((e: any) => Tooth.fromJSON(e))
        : [],
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) => Condition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ApproveAllTeethAndSignReportResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    if (message.Teeth) {
      obj.Teeth = message.Teeth.map((e) => (e ? Tooth.toJSON(e) : undefined));
    } else {
      obj.Teeth = [];
    }
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ApproveAllTeethAndSignReportResp>, I>,
  >(object: I): ApproveAllTeethAndSignReportResp {
    const message = createBaseApproveAllTeethAndSignReportResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    message.Teeth = object.Teeth?.map((e) => Tooth.fromPartial(e)) || [];
    message.Conditions =
      object.Conditions?.map((e) => Condition.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetReportToothMissingReq(): SetReportToothMissingReq {
  return { ToothID: '', IsMissing: false };
}

export const SetReportToothMissingReq = {
  encode(
    message: SetReportToothMissingReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.IsMissing === true) {
      writer.uint32(16).bool(message.IsMissing);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothMissingReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothMissingReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.IsMissing = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothMissingReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      IsMissing: isSet(object.IsMissing) ? Boolean(object.IsMissing) : false,
    };
  },

  toJSON(message: SetReportToothMissingReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.IsMissing !== undefined && (obj.IsMissing = message.IsMissing);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothMissingReq>, I>>(
    object: I,
  ): SetReportToothMissingReq {
    const message = createBaseSetReportToothMissingReq();
    message.ToothID = object.ToothID ?? '';
    message.IsMissing = object.IsMissing ?? false;
    return message;
  },
};

function createBaseSetReportToothMissingResp(): SetReportToothMissingResp {
  return { Tooth: undefined };
}

export const SetReportToothMissingResp = {
  encode(
    message: SetReportToothMissingResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothMissingResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothMissingResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothMissingResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: SetReportToothMissingResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothMissingResp>, I>>(
    object: I,
  ): SetReportToothMissingResp {
    const message = createBaseSetReportToothMissingResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

function createBaseSetReportToothSupernumeraryReq(): SetReportToothSupernumeraryReq {
  return { ToothID: '', IsSupernumerary: false };
}

export const SetReportToothSupernumeraryReq = {
  encode(
    message: SetReportToothSupernumeraryReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.IsSupernumerary === true) {
      writer.uint32(16).bool(message.IsSupernumerary);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothSupernumeraryReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothSupernumeraryReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.IsSupernumerary = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothSupernumeraryReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      IsSupernumerary: isSet(object.IsSupernumerary)
        ? Boolean(object.IsSupernumerary)
        : false,
    };
  },

  toJSON(message: SetReportToothSupernumeraryReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.IsSupernumerary !== undefined &&
      (obj.IsSupernumerary = message.IsSupernumerary);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothSupernumeraryReq>, I>>(
    object: I,
  ): SetReportToothSupernumeraryReq {
    const message = createBaseSetReportToothSupernumeraryReq();
    message.ToothID = object.ToothID ?? '';
    message.IsSupernumerary = object.IsSupernumerary ?? false;
    return message;
  },
};

function createBaseSetReportToothSupernumeraryResp(): SetReportToothSupernumeraryResp {
  return { Tooth: undefined };
}

export const SetReportToothSupernumeraryResp = {
  encode(
    message: SetReportToothSupernumeraryResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothSupernumeraryResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothSupernumeraryResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothSupernumeraryResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: SetReportToothSupernumeraryResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothSupernumeraryResp>, I>>(
    object: I,
  ): SetReportToothSupernumeraryResp {
    const message = createBaseSetReportToothSupernumeraryResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

function createBaseSetReportToothControlReq(): SetReportToothControlReq {
  return { ToothID: '', IsControl: false };
}

export const SetReportToothControlReq = {
  encode(
    message: SetReportToothControlReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.IsControl === true) {
      writer.uint32(16).bool(message.IsControl);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothControlReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothControlReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.IsControl = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothControlReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      IsControl: isSet(object.IsControl) ? Boolean(object.IsControl) : false,
    };
  },

  toJSON(message: SetReportToothControlReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.IsControl !== undefined && (obj.IsControl = message.IsControl);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothControlReq>, I>>(
    object: I,
  ): SetReportToothControlReq {
    const message = createBaseSetReportToothControlReq();
    message.ToothID = object.ToothID ?? '';
    message.IsControl = object.IsControl ?? false;
    return message;
  },
};

function createBaseSetReportToothControlResp(): SetReportToothControlResp {
  return { Tooth: undefined };
}

export const SetReportToothControlResp = {
  encode(
    message: SetReportToothControlResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothControlResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothControlResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothControlResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: SetReportToothControlResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothControlResp>, I>>(
    object: I,
  ): SetReportToothControlResp {
    const message = createBaseSetReportToothControlResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

function createBaseResetReportToothReq(): ResetReportToothReq {
  return { ToothID: '', GeneratedVersion: false };
}

export const ResetReportToothReq = {
  encode(
    message: ResetReportToothReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.GeneratedVersion === true) {
      writer.uint32(16).bool(message.GeneratedVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetReportToothReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetReportToothReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.GeneratedVersion = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResetReportToothReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      GeneratedVersion: isSet(object.GeneratedVersion)
        ? Boolean(object.GeneratedVersion)
        : false,
    };
  },

  toJSON(message: ResetReportToothReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.GeneratedVersion !== undefined &&
      (obj.GeneratedVersion = message.GeneratedVersion);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ResetReportToothReq>, I>>(
    object: I,
  ): ResetReportToothReq {
    const message = createBaseResetReportToothReq();
    message.ToothID = object.ToothID ?? '';
    message.GeneratedVersion = object.GeneratedVersion ?? false;
    return message;
  },
};

function createBaseResetReportToothResp(): ResetReportToothResp {
  return { Tooth: undefined, Conditions: [] };
}

export const ResetReportToothResp = {
  encode(
    message: ResetReportToothResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Conditions) {
      Condition.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ResetReportToothResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetReportToothResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        case 2:
          message.Conditions.push(Condition.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResetReportToothResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) => Condition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ResetReportToothResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ResetReportToothResp>, I>>(
    object: I,
  ): ResetReportToothResp {
    const message = createBaseResetReportToothResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    message.Conditions =
      object.Conditions?.map((e) => Condition.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddToothDisplaySliceReq(): AddToothDisplaySliceReq {
  return { ToothID: '', AssetID: '' };
}

export const AddToothDisplaySliceReq = {
  encode(
    message: AddToothDisplaySliceReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.AssetID !== '') {
      writer.uint32(18).string(message.AssetID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddToothDisplaySliceReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddToothDisplaySliceReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.AssetID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddToothDisplaySliceReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      AssetID: isSet(object.AssetID) ? String(object.AssetID) : '',
    };
  },

  toJSON(message: AddToothDisplaySliceReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.AssetID !== undefined && (obj.AssetID = message.AssetID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddToothDisplaySliceReq>, I>>(
    object: I,
  ): AddToothDisplaySliceReq {
    const message = createBaseAddToothDisplaySliceReq();
    message.ToothID = object.ToothID ?? '';
    message.AssetID = object.AssetID ?? '';
    return message;
  },
};

function createBaseAddToothDisplaySliceResp(): AddToothDisplaySliceResp {
  return { Tooth: undefined };
}

export const AddToothDisplaySliceResp = {
  encode(
    message: AddToothDisplaySliceResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddToothDisplaySliceResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddToothDisplaySliceResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddToothDisplaySliceResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: AddToothDisplaySliceResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddToothDisplaySliceResp>, I>>(
    object: I,
  ): AddToothDisplaySliceResp {
    const message = createBaseAddToothDisplaySliceResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

function createBaseRemoveToothDisplaySliceReq(): RemoveToothDisplaySliceReq {
  return { ToothID: '', AssetID: '' };
}

export const RemoveToothDisplaySliceReq = {
  encode(
    message: RemoveToothDisplaySliceReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.AssetID !== '') {
      writer.uint32(18).string(message.AssetID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemoveToothDisplaySliceReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveToothDisplaySliceReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.AssetID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveToothDisplaySliceReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      AssetID: isSet(object.AssetID) ? String(object.AssetID) : '',
    };
  },

  toJSON(message: RemoveToothDisplaySliceReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.AssetID !== undefined && (obj.AssetID = message.AssetID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemoveToothDisplaySliceReq>, I>>(
    object: I,
  ): RemoveToothDisplaySliceReq {
    const message = createBaseRemoveToothDisplaySliceReq();
    message.ToothID = object.ToothID ?? '';
    message.AssetID = object.AssetID ?? '';
    return message;
  },
};

function createBaseRemoveToothDisplaySliceResp(): RemoveToothDisplaySliceResp {
  return { Tooth: undefined };
}

export const RemoveToothDisplaySliceResp = {
  encode(
    message: RemoveToothDisplaySliceResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemoveToothDisplaySliceResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveToothDisplaySliceResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveToothDisplaySliceResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: RemoveToothDisplaySliceResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemoveToothDisplaySliceResp>, I>>(
    object: I,
  ): RemoveToothDisplaySliceResp {
    const message = createBaseRemoveToothDisplaySliceResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

function createBaseCreateReportToothConditionsReq(): CreateReportToothConditionsReq {
  return { ToothID: '', Conditions: [] };
}

export const CreateReportToothConditionsReq = {
  encode(
    message: CreateReportToothConditionsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    for (const v of message.Conditions) {
      CreateReportToothConditionsReq_Condition.encode(
        v!,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateReportToothConditionsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateReportToothConditionsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.Conditions.push(
            CreateReportToothConditionsReq_Condition.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateReportToothConditionsReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) =>
            CreateReportToothConditionsReq_Condition.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: CreateReportToothConditionsReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? CreateReportToothConditionsReq_Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateReportToothConditionsReq>, I>>(
    object: I,
  ): CreateReportToothConditionsReq {
    const message = createBaseCreateReportToothConditionsReq();
    message.ToothID = object.ToothID ?? '';
    message.Conditions =
      object.Conditions?.map((e) =>
        CreateReportToothConditionsReq_Condition.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseCreateReportToothConditionsReq_Condition(): CreateReportToothConditionsReq_Condition {
  return { Code: 0, ParentConditionID: '' };
}

export const CreateReportToothConditionsReq_Condition = {
  encode(
    message: CreateReportToothConditionsReq_Condition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Code !== 0) {
      writer.uint32(8).int32(message.Code);
    }
    if (message.ParentConditionID !== '') {
      writer.uint32(18).string(message.ParentConditionID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateReportToothConditionsReq_Condition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateReportToothConditionsReq_Condition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Code = reader.int32() as any;
          break;
        case 2:
          message.ParentConditionID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateReportToothConditionsReq_Condition {
    return {
      Code: isSet(object.Code) ? conditionCodeFromJSON(object.Code) : 0,
      ParentConditionID: isSet(object.ParentConditionID)
        ? String(object.ParentConditionID)
        : '',
    };
  },

  toJSON(message: CreateReportToothConditionsReq_Condition): unknown {
    const obj: any = {};
    message.Code !== undefined &&
      (obj.Code = conditionCodeToJSON(message.Code));
    message.ParentConditionID !== undefined &&
      (obj.ParentConditionID = message.ParentConditionID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<CreateReportToothConditionsReq_Condition>, I>,
  >(object: I): CreateReportToothConditionsReq_Condition {
    const message = createBaseCreateReportToothConditionsReq_Condition();
    message.Code = object.Code ?? 0;
    message.ParentConditionID = object.ParentConditionID ?? '';
    return message;
  },
};

function createBaseCreateReportToothConditionsResp(): CreateReportToothConditionsResp {
  return { Conditions: [] };
}

export const CreateReportToothConditionsResp = {
  encode(
    message: CreateReportToothConditionsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Conditions) {
      Condition.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateReportToothConditionsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateReportToothConditionsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Conditions.push(Condition.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateReportToothConditionsResp {
    return {
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) => Condition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CreateReportToothConditionsResp): unknown {
    const obj: any = {};
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateReportToothConditionsResp>, I>>(
    object: I,
  ): CreateReportToothConditionsResp {
    const message = createBaseCreateReportToothConditionsResp();
    message.Conditions =
      object.Conditions?.map((e) => Condition.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetReportToothConditionUserDecisionReq(): SetReportToothConditionUserDecisionReq {
  return { ConditionID: '', UserDecision: 0 };
}

export const SetReportToothConditionUserDecisionReq = {
  encode(
    message: SetReportToothConditionUserDecisionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ConditionID !== '') {
      writer.uint32(10).string(message.ConditionID);
    }
    if (message.UserDecision !== 0) {
      writer.uint32(16).int32(message.UserDecision);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothConditionUserDecisionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothConditionUserDecisionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ConditionID = reader.string();
          break;
        case 2:
          message.UserDecision = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothConditionUserDecisionReq {
    return {
      ConditionID: isSet(object.ConditionID) ? String(object.ConditionID) : '',
      UserDecision: isSet(object.UserDecision)
        ? decisionFromJSON(object.UserDecision)
        : 0,
    };
  },

  toJSON(message: SetReportToothConditionUserDecisionReq): unknown {
    const obj: any = {};
    message.ConditionID !== undefined &&
      (obj.ConditionID = message.ConditionID);
    message.UserDecision !== undefined &&
      (obj.UserDecision = decisionToJSON(message.UserDecision));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetReportToothConditionUserDecisionReq>, I>,
  >(object: I): SetReportToothConditionUserDecisionReq {
    const message = createBaseSetReportToothConditionUserDecisionReq();
    message.ConditionID = object.ConditionID ?? '';
    message.UserDecision = object.UserDecision ?? 0;
    return message;
  },
};

function createBaseSetReportToothConditionUserDecisionResp(): SetReportToothConditionUserDecisionResp {
  return { Condition: undefined, Tooth: undefined };
}

export const SetReportToothConditionUserDecisionResp = {
  encode(
    message: SetReportToothConditionUserDecisionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Condition !== undefined) {
      Condition.encode(message.Condition, writer.uint32(10).fork()).ldelim();
    }
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothConditionUserDecisionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothConditionUserDecisionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Condition = Condition.decode(reader, reader.uint32());
          break;
        case 2:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothConditionUserDecisionResp {
    return {
      Condition: isSet(object.Condition)
        ? Condition.fromJSON(object.Condition)
        : undefined,
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: SetReportToothConditionUserDecisionResp): unknown {
    const obj: any = {};
    message.Condition !== undefined &&
      (obj.Condition = message.Condition
        ? Condition.toJSON(message.Condition)
        : undefined);
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetReportToothConditionUserDecisionResp>, I>,
  >(object: I): SetReportToothConditionUserDecisionResp {
    const message = createBaseSetReportToothConditionUserDecisionResp();
    message.Condition =
      object.Condition !== undefined && object.Condition !== null
        ? Condition.fromPartial(object.Condition)
        : undefined;
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

function createBaseResetReportToothConditionsReq(): ResetReportToothConditionsReq {
  return { ToothID: '', GeneratedVersion: false };
}

export const ResetReportToothConditionsReq = {
  encode(
    message: ResetReportToothConditionsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.GeneratedVersion === true) {
      writer.uint32(16).bool(message.GeneratedVersion);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ResetReportToothConditionsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetReportToothConditionsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.GeneratedVersion = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResetReportToothConditionsReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      GeneratedVersion: isSet(object.GeneratedVersion)
        ? Boolean(object.GeneratedVersion)
        : false,
    };
  },

  toJSON(message: ResetReportToothConditionsReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.GeneratedVersion !== undefined &&
      (obj.GeneratedVersion = message.GeneratedVersion);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ResetReportToothConditionsReq>, I>>(
    object: I,
  ): ResetReportToothConditionsReq {
    const message = createBaseResetReportToothConditionsReq();
    message.ToothID = object.ToothID ?? '';
    message.GeneratedVersion = object.GeneratedVersion ?? false;
    return message;
  },
};

function createBaseResetReportToothConditionsResp(): ResetReportToothConditionsResp {
  return { Conditions: [] };
}

export const ResetReportToothConditionsResp = {
  encode(
    message: ResetReportToothConditionsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Conditions) {
      Condition.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ResetReportToothConditionsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetReportToothConditionsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Conditions.push(Condition.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResetReportToothConditionsResp {
    return {
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) => Condition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ResetReportToothConditionsResp): unknown {
    const obj: any = {};
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ResetReportToothConditionsResp>, I>>(
    object: I,
  ): ResetReportToothConditionsResp {
    const message = createBaseResetReportToothConditionsResp();
    message.Conditions =
      object.Conditions?.map((e) => Condition.fromPartial(e)) || [];
    return message;
  },
};

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: ReportID
 * - type: NotFoundError, Entity: report (if report not found by ReportID)
 * - type: BadRequestError, Reason: report deleted (if remote report found by ReportID)
 * - type: NotFoundError, Entity: tooth (if tooth not found by ToothID)
 */
export interface ReportService {
  ReportDataStream(
    request: DeepPartial<ReportDataStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<ReportDataStreamResp>;
  /**
   * Request report
   *
   * Permission: `PatientPermissions.CanRequestReport`
   */
  RequestReport(
    request: DeepPartial<RequestReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<RequestReportResp>;
  /**
   * Mark report as deleted using report id
   * - this report mark as deleted
   * - all invitations to this report are closed
   *
   * Permission: `ReportPermissions.CanDelete`
   */
  DeleteReport(
    request: DeepPartial<DeleteReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteReportResp>;
  /**
   * Set region of interest (slice of tooth identifiers)
   *
   * Permission: `ReportPermissions.CanChangeROI`
   */
  SetROI(
    request: DeepPartial<SetROIReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetROIResp>;
  /**
   * Set legacy conclusion
   *
   * Permission: `ReportPermissions.CanChangeConclusion`
   */
  SetReportConclusion(
    request: DeepPartial<SetReportConclusionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportConclusionResp>;
  /** Permission: `ReportPermissions.CanChangeSettings` */
  SetReportSettings(
    request: DeepPartial<SetReportSettingsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportSettingsResp>;
  /** Permission: `ReportPermissions.CanChangeSettings` */
  SetReportPrintSettings(
    request: DeepPartial<SetReportPrintSettingsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportPrintSettingsResp>;
  /**
   * Sign report
   *
   * Permission: `ReportPermissions.CanSign`
   *
   * Errors:
   * - type: BadRequestError, Reason: you do not have signature
   */
  SignReport(
    request: DeepPartial<SignReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<SignReportResp>;
  /**
   * Copy report, teeth, conditions either with only its initial state or with all changes made, the copied report becomes unsigned
   *
   * Permission: `ReportPermissions.CanCopy`
   */
  CopyReport(
    request: DeepPartial<CopyReportReq>,
    metadata?: grpc.Metadata,
  ): Observable<CopyReportResp>;
  /**
   * Set tooth comment
   *
   * Permission: `ReportPermissions.CanChangeToothComment`
   */
  SetReportToothComment(
    request: DeepPartial<SetReportToothCommentReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothCommentResp>;
  /**
   * Change tooth ISO number
   *
   * Permission: `ReportPermissions.CanChangeToothISONumber`
   */
  SetReportToothISONumber(
    request: DeepPartial<SetReportToothISONumberReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothISONumberResp>;
  /** Permission: `ReportPermissions.CanChangeToothMissing` */
  SetReportToothMissing(
    request: DeepPartial<SetReportToothMissingReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothMissingResp>;
  /** Permission: `ReportPermissions.CanChangeToothSupernumerary` */
  SetReportToothSupernumerary(
    request: DeepPartial<SetReportToothSupernumeraryReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothSupernumeraryResp>;
  /** Permission: `ReportPermissions.CanChangeControlTeeth` */
  SetReportToothControl(
    request: DeepPartial<SetReportToothControlReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothControlResp>;
  /**
   * Using this method doctor confirms that conditions on this tooth are correct (if `SetReportToothApprovedReq.Approved` == true) or cancels confirmation (if `SetReportToothApprovedReq.Approved` == false)
   *
   * Permission: `ReportPermissions.CanChangeToothApproved`
   */
  ApproveReportTooth(
    request: DeepPartial<ApproveReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<ApproveReportToothResp>;
  /** Permission: `ReportPermissions.CanChangeToothApproved` */
  DisApproveReportTooth(
    request: DeepPartial<DisApproveReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<DisApproveReportToothResp>;
  /**
   * Approve all disapprove teeth in ROI and sign report
   * Permission: `ReportPermissions.CanChangeToothApproved` && `ReportPermissions.CanSign`
   */
  ApproveAllTeethAndSignReport(
    request: DeepPartial<ApproveAllTeethAndSignReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<ApproveAllTeethAndSignReportResp>;
  /**
   * Reset tooth && tooth conditions
   *
   * Permission: `ReportPermissions.CanResetTooth`
   */
  ResetReportTooth(
    request: DeepPartial<ResetReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<ResetReportToothResp>;
  /**
   * Add Asset to Tooth.DisplaySlices
   *
   * Permission: `ReportPermissions.CanChangeConditions`
   */
  AddToothDisplaySlice(
    request: DeepPartial<AddToothDisplaySliceReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddToothDisplaySliceResp>;
  /**
   * Remove Asset from Tooth.DisplaySlices
   *
   * Permission: `ReportPermissions.CanChangeConditions`
   */
  RemoveToothDisplaySlice(
    request: DeepPartial<RemoveToothDisplaySliceReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveToothDisplaySliceResp>;
  /**
   * Permission: `ReportPermissions.CanChangeConditions`
   *
   * Errors:
   * - type: BadRequestError, Reason: ROI does not contain this tooth
   */
  CreateReportToothConditions(
    request: DeepPartial<CreateReportToothConditionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateReportToothConditionsResp>;
  /** Permission: `ReportPermissions.CanChangeConditions` */
  SetReportToothConditionUserDecision(
    request: DeepPartial<SetReportToothConditionUserDecisionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothConditionUserDecisionResp>;
  /** Permission: `ReportPermissions.CanChangeConditions` */
  ResetReportToothConditions(
    request: DeepPartial<ResetReportToothConditionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<ResetReportToothConditionsResp>;
}

export class ReportServiceClientImpl implements ReportService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ReportDataStream = this.ReportDataStream.bind(this);
    this.RequestReport = this.RequestReport.bind(this);
    this.DeleteReport = this.DeleteReport.bind(this);
    this.SetROI = this.SetROI.bind(this);
    this.SetReportConclusion = this.SetReportConclusion.bind(this);
    this.SetReportSettings = this.SetReportSettings.bind(this);
    this.SetReportPrintSettings = this.SetReportPrintSettings.bind(this);
    this.SignReport = this.SignReport.bind(this);
    this.CopyReport = this.CopyReport.bind(this);
    this.SetReportToothComment = this.SetReportToothComment.bind(this);
    this.SetReportToothISONumber = this.SetReportToothISONumber.bind(this);
    this.SetReportToothMissing = this.SetReportToothMissing.bind(this);
    this.SetReportToothSupernumerary =
      this.SetReportToothSupernumerary.bind(this);
    this.SetReportToothControl = this.SetReportToothControl.bind(this);
    this.ApproveReportTooth = this.ApproveReportTooth.bind(this);
    this.DisApproveReportTooth = this.DisApproveReportTooth.bind(this);
    this.ApproveAllTeethAndSignReport =
      this.ApproveAllTeethAndSignReport.bind(this);
    this.ResetReportTooth = this.ResetReportTooth.bind(this);
    this.AddToothDisplaySlice = this.AddToothDisplaySlice.bind(this);
    this.RemoveToothDisplaySlice = this.RemoveToothDisplaySlice.bind(this);
    this.CreateReportToothConditions =
      this.CreateReportToothConditions.bind(this);
    this.SetReportToothConditionUserDecision =
      this.SetReportToothConditionUserDecision.bind(this);
    this.ResetReportToothConditions =
      this.ResetReportToothConditions.bind(this);
  }

  ReportDataStream(
    request: DeepPartial<ReportDataStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<ReportDataStreamResp> {
    return this.rpc.invoke(
      ReportServiceReportDataStreamDesc,
      ReportDataStreamReq.fromPartial(request),
      metadata,
    );
  }

  RequestReport(
    request: DeepPartial<RequestReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<RequestReportResp> {
    return this.rpc.unary(
      ReportServiceRequestReportDesc,
      RequestReportReq.fromPartial(request),
      metadata,
    );
  }

  DeleteReport(
    request: DeepPartial<DeleteReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteReportResp> {
    return this.rpc.unary(
      ReportServiceDeleteReportDesc,
      DeleteReportReq.fromPartial(request),
      metadata,
    );
  }

  SetROI(
    request: DeepPartial<SetROIReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetROIResp> {
    return this.rpc.unary(
      ReportServiceSetROIDesc,
      SetROIReq.fromPartial(request),
      metadata,
    );
  }

  SetReportConclusion(
    request: DeepPartial<SetReportConclusionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportConclusionResp> {
    return this.rpc.unary(
      ReportServiceSetReportConclusionDesc,
      SetReportConclusionReq.fromPartial(request),
      metadata,
    );
  }

  SetReportSettings(
    request: DeepPartial<SetReportSettingsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportSettingsResp> {
    return this.rpc.unary(
      ReportServiceSetReportSettingsDesc,
      SetReportSettingsReq.fromPartial(request),
      metadata,
    );
  }

  SetReportPrintSettings(
    request: DeepPartial<SetReportPrintSettingsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportPrintSettingsResp> {
    return this.rpc.unary(
      ReportServiceSetReportPrintSettingsDesc,
      SetReportPrintSettingsReq.fromPartial(request),
      metadata,
    );
  }

  SignReport(
    request: DeepPartial<SignReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<SignReportResp> {
    return this.rpc.unary(
      ReportServiceSignReportDesc,
      SignReportReq.fromPartial(request),
      metadata,
    );
  }

  CopyReport(
    request: DeepPartial<CopyReportReq>,
    metadata?: grpc.Metadata,
  ): Observable<CopyReportResp> {
    return this.rpc.invoke(
      ReportServiceCopyReportDesc,
      CopyReportReq.fromPartial(request),
      metadata,
    );
  }

  SetReportToothComment(
    request: DeepPartial<SetReportToothCommentReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothCommentResp> {
    return this.rpc.unary(
      ReportServiceSetReportToothCommentDesc,
      SetReportToothCommentReq.fromPartial(request),
      metadata,
    );
  }

  SetReportToothISONumber(
    request: DeepPartial<SetReportToothISONumberReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothISONumberResp> {
    return this.rpc.unary(
      ReportServiceSetReportToothISONumberDesc,
      SetReportToothISONumberReq.fromPartial(request),
      metadata,
    );
  }

  SetReportToothMissing(
    request: DeepPartial<SetReportToothMissingReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothMissingResp> {
    return this.rpc.unary(
      ReportServiceSetReportToothMissingDesc,
      SetReportToothMissingReq.fromPartial(request),
      metadata,
    );
  }

  SetReportToothSupernumerary(
    request: DeepPartial<SetReportToothSupernumeraryReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothSupernumeraryResp> {
    return this.rpc.unary(
      ReportServiceSetReportToothSupernumeraryDesc,
      SetReportToothSupernumeraryReq.fromPartial(request),
      metadata,
    );
  }

  SetReportToothControl(
    request: DeepPartial<SetReportToothControlReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothControlResp> {
    return this.rpc.unary(
      ReportServiceSetReportToothControlDesc,
      SetReportToothControlReq.fromPartial(request),
      metadata,
    );
  }

  ApproveReportTooth(
    request: DeepPartial<ApproveReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<ApproveReportToothResp> {
    return this.rpc.unary(
      ReportServiceApproveReportToothDesc,
      ApproveReportToothReq.fromPartial(request),
      metadata,
    );
  }

  DisApproveReportTooth(
    request: DeepPartial<DisApproveReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<DisApproveReportToothResp> {
    return this.rpc.unary(
      ReportServiceDisApproveReportToothDesc,
      DisApproveReportToothReq.fromPartial(request),
      metadata,
    );
  }

  ApproveAllTeethAndSignReport(
    request: DeepPartial<ApproveAllTeethAndSignReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<ApproveAllTeethAndSignReportResp> {
    return this.rpc.unary(
      ReportServiceApproveAllTeethAndSignReportDesc,
      ApproveAllTeethAndSignReportReq.fromPartial(request),
      metadata,
    );
  }

  ResetReportTooth(
    request: DeepPartial<ResetReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<ResetReportToothResp> {
    return this.rpc.unary(
      ReportServiceResetReportToothDesc,
      ResetReportToothReq.fromPartial(request),
      metadata,
    );
  }

  AddToothDisplaySlice(
    request: DeepPartial<AddToothDisplaySliceReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddToothDisplaySliceResp> {
    return this.rpc.unary(
      ReportServiceAddToothDisplaySliceDesc,
      AddToothDisplaySliceReq.fromPartial(request),
      metadata,
    );
  }

  RemoveToothDisplaySlice(
    request: DeepPartial<RemoveToothDisplaySliceReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveToothDisplaySliceResp> {
    return this.rpc.unary(
      ReportServiceRemoveToothDisplaySliceDesc,
      RemoveToothDisplaySliceReq.fromPartial(request),
      metadata,
    );
  }

  CreateReportToothConditions(
    request: DeepPartial<CreateReportToothConditionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateReportToothConditionsResp> {
    return this.rpc.unary(
      ReportServiceCreateReportToothConditionsDesc,
      CreateReportToothConditionsReq.fromPartial(request),
      metadata,
    );
  }

  SetReportToothConditionUserDecision(
    request: DeepPartial<SetReportToothConditionUserDecisionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothConditionUserDecisionResp> {
    return this.rpc.unary(
      ReportServiceSetReportToothConditionUserDecisionDesc,
      SetReportToothConditionUserDecisionReq.fromPartial(request),
      metadata,
    );
  }

  ResetReportToothConditions(
    request: DeepPartial<ResetReportToothConditionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<ResetReportToothConditionsResp> {
    return this.rpc.unary(
      ReportServiceResetReportToothConditionsDesc,
      ResetReportToothConditionsReq.fromPartial(request),
      metadata,
    );
  }
}

export const ReportServiceDesc = {
  serviceName: 'com.diagnocat.core.ReportService',
};

export const ReportServiceReportDataStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'ReportDataStream',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return ReportDataStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ReportDataStreamResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceRequestReportDesc: UnaryMethodDefinitionish = {
  methodName: 'RequestReport',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RequestReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RequestReportResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceDeleteReportDesc: UnaryMethodDefinitionish = {
  methodName: 'DeleteReport',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeleteReportResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceSetROIDesc: UnaryMethodDefinitionish = {
  methodName: 'SetROI',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetROIReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetROIResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceSetReportConclusionDesc: UnaryMethodDefinitionish = {
  methodName: 'SetReportConclusion',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetReportConclusionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetReportConclusionResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceSetReportSettingsDesc: UnaryMethodDefinitionish = {
  methodName: 'SetReportSettings',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetReportSettingsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetReportSettingsResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceSetReportPrintSettingsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportPrintSettings',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportPrintSettingsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportPrintSettingsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSignReportDesc: UnaryMethodDefinitionish = {
  methodName: 'SignReport',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SignReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SignReportResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceCopyReportDesc: UnaryMethodDefinitionish = {
  methodName: 'CopyReport',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return CopyReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CopyReportResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceSetReportToothCommentDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportToothComment',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportToothCommentReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportToothCommentResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSetReportToothISONumberDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportToothISONumber',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportToothISONumberReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportToothISONumberResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSetReportToothMissingDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportToothMissing',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportToothMissingReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportToothMissingResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSetReportToothSupernumeraryDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportToothSupernumerary',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportToothSupernumeraryReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportToothSupernumeraryResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSetReportToothControlDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportToothControl',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportToothControlReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportToothControlResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceApproveReportToothDesc: UnaryMethodDefinitionish = {
  methodName: 'ApproveReportTooth',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ApproveReportToothReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ApproveReportToothResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceDisApproveReportToothDesc: UnaryMethodDefinitionish =
  {
    methodName: 'DisApproveReportTooth',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return DisApproveReportToothReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...DisApproveReportToothResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceApproveAllTeethAndSignReportDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ApproveAllTeethAndSignReport',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ApproveAllTeethAndSignReportReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...ApproveAllTeethAndSignReportResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceResetReportToothDesc: UnaryMethodDefinitionish = {
  methodName: 'ResetReportTooth',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResetReportToothReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ResetReportToothResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceAddToothDisplaySliceDesc: UnaryMethodDefinitionish = {
  methodName: 'AddToothDisplaySlice',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddToothDisplaySliceReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AddToothDisplaySliceResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceRemoveToothDisplaySliceDesc: UnaryMethodDefinitionish =
  {
    methodName: 'RemoveToothDisplaySlice',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return RemoveToothDisplaySliceReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...RemoveToothDisplaySliceResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceCreateReportToothConditionsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'CreateReportToothConditions',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return CreateReportToothConditionsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...CreateReportToothConditionsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSetReportToothConditionUserDecisionDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportToothConditionUserDecision',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportToothConditionUserDecisionReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportToothConditionUserDecisionResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceResetReportToothConditionsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ResetReportToothConditions',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ResetReportToothConditionsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...ResetReportToothConditionsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
