import { useAppSelector, useModal } from '@/shared/hooks';
import { useRemoveDoctorModal } from '@/features/removeDoctor';
import { patientModel } from '@/entities/patient';
import { selectPatientDoctorsAsUsers } from '@/widgets/PatientInfo/model/selectors';

export const usePatientInfo = (patientID: string) => {
  const {
    isOpen: isAddDoctorsModalOpen,
    openModal: openAddDoctorsModal,
    closeModal: closeAddDoctorsModal,
  } = useModal();

  const {
    currentDoctorID,
    isRemoveDoctorModalOpen,
    openRemoveDoctorModal,
    closeRemoveDoctorModal,
  } = useRemoveDoctorModal();

  const patient = useAppSelector((state) =>
    patientModel.selectors.selectById(state, patientID),
  );

  const patientDoctors = useAppSelector(
    patientModel.selectors.selectPatientDoctors(patientID),
  );

  const patientDoctorsAsUsers = useAppSelector(
    selectPatientDoctorsAsUsers(patientID),
  );

  const patientLoading = useAppSelector((state) => state.patient.loading);

  const isLoading = patientLoading === 'idle' || patientLoading === 'pending';
  const isError = patientLoading === 'failed';

  return {
    isLoading,
    isError,
    patient,
    patientDoctors,
    patientDoctorsAsUsers,
    addDoctorsModal: {
      isOpen: isAddDoctorsModalOpen,
      open: openAddDoctorsModal,
      close: closeAddDoctorsModal,
    },
    removeDoctorModal: {
      currentDoctorID,
      isOpen: isRemoveDoctorModalOpen,
      open: openRemoveDoctorModal,
      close: closeRemoveDoctorModal,
    },
  };
};
