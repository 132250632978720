import React, { useState, RefObject } from 'react';
import RcDatePicker from 'react-datepicker';
import InputMask, { ReactInputMask } from 'react-input-mask';
import { PulseLoader } from 'react-spinners';
import cn from 'classnames';

import { Icon } from '../Icon/Icon';
import { InputLabel } from '../InputLabel/InputLabel';
import { ErrorText } from '../ErrorText/ErrorText';
import { CURRENT_YEAR } from '../../lib/ISO';

import styles from './DatePicker.module.scss';

import './DatePicker.scss';
import 'react-datepicker/dist/react-datepicker.css';

export interface DatePickerProps {
  ref?: unknown;
  mask?: string;
  dateFormat?: string;
  label?: string | JSX.Element;
  value?: Date;
  error?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  testID?: string;
  onChange?: (val: Date) => void;
  datepickerClassname?: string;
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    ref,
    mask = '99/99/9999', // TODO: config masks by locale
    dateFormat = 'dd/MM/yyyy',
    placeholder = 'DD/MM/YYYY',
    label,
    value,
    error,
    required,
    disabled,
    loading,
    className,
    testID,
    onChange,
    datepickerClassname,
  } = props;
  const [focus, setFocus] = useState(false);

  return (
    <div className={cn(className, styles.container)}>
      <InputLabel label={label} required={required} />
      <div className={styles.inputCont}>
        <RcDatePicker
          data-testid={testID}
          className={cn(
            styles.input,
            focus && styles.focus,
            disabled && styles.disabled,
            error && styles.error,
            datepickerClassname,
          )}
          dateFormat={dateFormat}
          placeholderText={placeholder}
          calendarStartDay={1}
          selected={value}
          onChange={onChange}
          onCalendarOpen={() => setFocus(true)}
          onCalendarClose={() => setFocus(false)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          // locale="ru" TODO: Add localization - https://github.com/Hacker0x01/react-datepicker#localization
          minDate={new Date(String(CURRENT_YEAR - 100))}
          maxDate={new Date(String(CURRENT_YEAR + 5))}
          disabled={disabled}
          customInput={
            <InputMask ref={ref as RefObject<ReactInputMask>} mask={mask} />
          }
        />
        {loading ? (
          <div className={`${styles.icon} ${styles.loadingIcon}`}>
            <PulseLoader size={4} />
          </div>
        ) : (
          <Icon
            name="calendar"
            className={cn(
              styles.icon,
              focus && styles.focus,
              disabled && styles.disabled,
            )}
          />
        )}
      </div>
      <ErrorText error={error} withInput />
    </div>
  );
};
