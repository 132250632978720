import {
  patientListFiltersMessages,
  patientListRowMessages,
} from './config/i18n';

export * as patientModel from './model';
export * from './lib';

// UI
export * from './ui/MenuButton/MenuButton';
export * from './ui/StudiesCounters/StudiesCounters';

// config
export const i18n = {
  patientListFiltersMessages,
  patientListRowMessages,
};

export * from './config/const';
