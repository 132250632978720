import { createSelector } from '@reduxjs/toolkit';
import { compose, filter, map, prop } from 'ramda';

import { RootState } from '@/app/model/store';

import { assetsAdapter } from './assetsSlice';

const assetsSelectors = assetsAdapter.getSelectors(
  (state: RootState) => state.assets,
);

const selectSlices = createSelector([assetsSelectors.selectAll], (assets) =>
  assets.map((asset) => asset.GeneratedReport),
);

export const selectSlicesByToothID = (toothID: string) =>
  createSelector(selectSlices, (slices) =>
    slices.filter((slice) => slice?.ToothID === toothID),
  );

export const selectByToothID = (toothID: string) =>
  createSelector(assetsSelectors.selectAll, (slices) =>
    slices.filter((slice) => slice?.GeneratedReport?.ToothID === toothID),
  );

export const selectCBCTGPPanoImageByReportID = (reportID: string) =>
  createSelector(selectSlices, (slices) =>
    slices.find(
      (slice) =>
        slice?.ReportID === reportID && slice?.CBCTGPPanoramaReformatGeneral,
    ),
  );

export const selectCBCTSlicesByToothID = (toothID: string) =>
  createSelector(selectSlicesByToothID(toothID), (slices) =>
    compose(
      map(prop('CBCTGPToothSlice')),
      filter(prop('CBCTGPToothSlice')),
    )(slices),
  );
export const selectCBCTToothPathologySlice = (toothID: string) =>
  createSelector(selectSlicesByToothID(toothID), (slices) =>
    compose(
      map(prop('CBCTToothPathologySlice')),
      filter(prop('CBCTToothPathologySlice')),
    )(slices),
  );

export const { selectAll, selectById, selectIds, selectEntities, selectTotal } =
  assetsSelectors;
