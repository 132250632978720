import { FC, useCallback, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { Icon } from '@/shared/ui';
import {
  ConditionButton,
  ConditionInterface,
  ConditionToggle,
  ConditionToggleContainer,
  ConditionChildGroup,
  i18n,
} from '@/entities/condition';

import { useUpdateConditionDecision } from '../hooks';

import styles from './ConditionControl.module.scss';

type ConditionControlProps = {
  className?: string;
  toothID: string;
  conditionInterface: ConditionInterface;
  conditionInterfaceGroup: ConditionInterface[];
};

export const ConditionControl: FC<ConditionControlProps> = (props) => {
  const { className, toothID, conditionInterface, conditionInterfaceGroup } =
    props;

  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { updateConditionDecision, isLoading } =
    useUpdateConditionDecision(toothID);

  const handleRemoveCondition = useCallback(() => {
    updateConditionDecision({
      conditionCode: conditionInterface.code,
      conditionID: conditionInterface.id,
      userDecision: Decision.NegativeDecision,
    });
  }, [conditionInterface.code, conditionInterface.id, updateConditionDecision]);

  return (
    <Popover.Root
      open={isOpen}
      onOpenChange={() => setIsOpen((state) => !state)}
    >
      {isOpen && <div className={styles.overlay} />}

      <Popover.Trigger asChild>
        <ConditionButton
          className={cn(isOpen && styles.highlightTrigger)}
          text={conditionInterface.text}
          color={conditionInterface.color}
          probability={conditionInterface.probability}
          probabilityText={conditionInterface.probabilityText}
          onRemove={
            conditionInterface.isOneOf ? undefined : handleRemoveCondition
          }
        />
      </Popover.Trigger>

      <Popover.Portal>
        {/* TODO: Tune max width and collision paddings of popover on mobile */}
        <Popover.Content
          className={cn(styles.container, className)}
          sideOffset={12}
          collisionPadding={24}
        >
          <header className={styles.header}>
            <h3 className="h3">
              {formatMessage(i18n.conditionGroupText[conditionInterface.group])}
            </h3>
            <Popover.Close className={styles.closeIcon} aria-label="Close">
              <Icon name="close" size={40} />
            </Popover.Close>
          </header>

          {conditionInterfaceGroup.map((data) => {
            const showChildConditionInterfaceGroups =
              data.childConditionInterfaces && data.isChecked;

            return showChildConditionInterfaceGroups ? (
              <ConditionToggleContainer key={data.code}>
                <ConditionToggle
                  data={data}
                  onChange={updateConditionDecision}
                  disabled={isLoading}
                />

                <ConditionChildGroup
                  data={data.childConditionInterfaces}
                  onChange={updateConditionDecision}
                  disabled={isLoading}
                />
              </ConditionToggleContainer>
            ) : (
              <ConditionToggle
                key={data.code}
                data={data}
                onChange={updateConditionDecision}
                disabled={isLoading}
              />
            );
          })}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
