/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { Empty } from '../../google/protobuf/empty';
import { Secret, AuthState, PersonalInfo } from '../../api/auth/dto_auth';
import { BrowserHeaders } from 'browser-headers';

export const protobufPackage = 'com.diagnocat.auth';

export interface WhoAmIResp {
  IsAuthenticated: boolean;
  User?: WhoAmIResp_WhoAmIRespUser | undefined;
}

export interface WhoAmIResp_WhoAmIRespUser {
  UserID: string;
  Info: PersonalInfo | undefined;
}

export interface LogoutReq {
  Option: LogoutReq_LogoutOption;
}

export enum LogoutReq_LogoutOption {
  None = 0,
  SteppedAway = 1,
  LogoutEverywhere = 2,
  UNRECOGNIZED = -1,
}

export function logoutReq_LogoutOptionFromJSON(
  object: any,
): LogoutReq_LogoutOption {
  switch (object) {
    case 0:
    case 'None':
      return LogoutReq_LogoutOption.None;
    case 1:
    case 'SteppedAway':
      return LogoutReq_LogoutOption.SteppedAway;
    case 2:
    case 'LogoutEverywhere':
      return LogoutReq_LogoutOption.LogoutEverywhere;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return LogoutReq_LogoutOption.UNRECOGNIZED;
  }
}

export function logoutReq_LogoutOptionToJSON(
  object: LogoutReq_LogoutOption,
): string {
  switch (object) {
    case LogoutReq_LogoutOption.None:
      return 'None';
    case LogoutReq_LogoutOption.SteppedAway:
      return 'SteppedAway';
    case LogoutReq_LogoutOption.LogoutEverywhere:
      return 'LogoutEverywhere';
    case LogoutReq_LogoutOption.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface SignUpReq {
  Email: string | undefined;
  Info: PersonalInfo | undefined;
  /** optional, for invites */
  AuthenticationID: string;
}

export interface SignInReq {
  Email: string | undefined;
}

export interface ResendCodeReq {
  UsePhoneCallerNumber?: boolean | undefined;
}

export interface VerifyResp {
  AuthState: AuthState | undefined;
  /**
   * Token ConfirmToken = 101;
   * Token RecoverToken = 102;
   */
  None: Empty | undefined;
}

export interface RefreshResp {
  OK: boolean;
}

function createBaseWhoAmIResp(): WhoAmIResp {
  return { IsAuthenticated: false, User: undefined };
}

export const WhoAmIResp = {
  encode(
    message: WhoAmIResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IsAuthenticated === true) {
      writer.uint32(8).bool(message.IsAuthenticated);
    }
    if (message.User !== undefined) {
      WhoAmIResp_WhoAmIRespUser.encode(
        message.User,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WhoAmIResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWhoAmIResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IsAuthenticated = reader.bool();
          break;
        case 2:
          message.User = WhoAmIResp_WhoAmIRespUser.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WhoAmIResp {
    return {
      IsAuthenticated: isSet(object.IsAuthenticated)
        ? Boolean(object.IsAuthenticated)
        : false,
      User: isSet(object.User)
        ? WhoAmIResp_WhoAmIRespUser.fromJSON(object.User)
        : undefined,
    };
  },

  toJSON(message: WhoAmIResp): unknown {
    const obj: any = {};
    message.IsAuthenticated !== undefined &&
      (obj.IsAuthenticated = message.IsAuthenticated);
    message.User !== undefined &&
      (obj.User = message.User
        ? WhoAmIResp_WhoAmIRespUser.toJSON(message.User)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<WhoAmIResp>, I>>(
    object: I,
  ): WhoAmIResp {
    const message = createBaseWhoAmIResp();
    message.IsAuthenticated = object.IsAuthenticated ?? false;
    message.User =
      object.User !== undefined && object.User !== null
        ? WhoAmIResp_WhoAmIRespUser.fromPartial(object.User)
        : undefined;
    return message;
  },
};

function createBaseWhoAmIResp_WhoAmIRespUser(): WhoAmIResp_WhoAmIRespUser {
  return { UserID: '', Info: undefined };
}

export const WhoAmIResp_WhoAmIRespUser = {
  encode(
    message: WhoAmIResp_WhoAmIRespUser,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.Info !== undefined) {
      PersonalInfo.encode(message.Info, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): WhoAmIResp_WhoAmIRespUser {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWhoAmIResp_WhoAmIRespUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.Info = PersonalInfo.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WhoAmIResp_WhoAmIRespUser {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      Info: isSet(object.Info) ? PersonalInfo.fromJSON(object.Info) : undefined,
    };
  },

  toJSON(message: WhoAmIResp_WhoAmIRespUser): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.Info !== undefined &&
      (obj.Info = message.Info ? PersonalInfo.toJSON(message.Info) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<WhoAmIResp_WhoAmIRespUser>, I>>(
    object: I,
  ): WhoAmIResp_WhoAmIRespUser {
    const message = createBaseWhoAmIResp_WhoAmIRespUser();
    message.UserID = object.UserID ?? '';
    message.Info =
      object.Info !== undefined && object.Info !== null
        ? PersonalInfo.fromPartial(object.Info)
        : undefined;
    return message;
  },
};

function createBaseLogoutReq(): LogoutReq {
  return { Option: 0 };
}

export const LogoutReq = {
  encode(
    message: LogoutReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Option !== 0) {
      writer.uint32(8).int32(message.Option);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogoutReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogoutReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Option = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): LogoutReq {
    return {
      Option: isSet(object.Option)
        ? logoutReq_LogoutOptionFromJSON(object.Option)
        : 0,
    };
  },

  toJSON(message: LogoutReq): unknown {
    const obj: any = {};
    message.Option !== undefined &&
      (obj.Option = logoutReq_LogoutOptionToJSON(message.Option));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<LogoutReq>, I>>(
    object: I,
  ): LogoutReq {
    const message = createBaseLogoutReq();
    message.Option = object.Option ?? 0;
    return message;
  },
};

function createBaseSignUpReq(): SignUpReq {
  return { Email: undefined, Info: undefined, AuthenticationID: '' };
}

export const SignUpReq = {
  encode(
    message: SignUpReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Email !== undefined) {
      writer.uint32(10).string(message.Email);
    }
    if (message.Info !== undefined) {
      PersonalInfo.encode(message.Info, writer.uint32(802).fork()).ldelim();
    }
    if (message.AuthenticationID !== '') {
      writer.uint32(810).string(message.AuthenticationID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignUpReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignUpReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Email = reader.string();
          break;
        case 100:
          message.Info = PersonalInfo.decode(reader, reader.uint32());
          break;
        case 101:
          message.AuthenticationID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignUpReq {
    return {
      Email: isSet(object.Email) ? String(object.Email) : undefined,
      Info: isSet(object.Info) ? PersonalInfo.fromJSON(object.Info) : undefined,
      AuthenticationID: isSet(object.AuthenticationID)
        ? String(object.AuthenticationID)
        : '',
    };
  },

  toJSON(message: SignUpReq): unknown {
    const obj: any = {};
    message.Email !== undefined && (obj.Email = message.Email);
    message.Info !== undefined &&
      (obj.Info = message.Info ? PersonalInfo.toJSON(message.Info) : undefined);
    message.AuthenticationID !== undefined &&
      (obj.AuthenticationID = message.AuthenticationID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignUpReq>, I>>(
    object: I,
  ): SignUpReq {
    const message = createBaseSignUpReq();
    message.Email = object.Email ?? undefined;
    message.Info =
      object.Info !== undefined && object.Info !== null
        ? PersonalInfo.fromPartial(object.Info)
        : undefined;
    message.AuthenticationID = object.AuthenticationID ?? '';
    return message;
  },
};

function createBaseSignInReq(): SignInReq {
  return { Email: undefined };
}

export const SignInReq = {
  encode(
    message: SignInReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Email !== undefined) {
      writer.uint32(10).string(message.Email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignInReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignInReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignInReq {
    return {
      Email: isSet(object.Email) ? String(object.Email) : undefined,
    };
  },

  toJSON(message: SignInReq): unknown {
    const obj: any = {};
    message.Email !== undefined && (obj.Email = message.Email);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignInReq>, I>>(
    object: I,
  ): SignInReq {
    const message = createBaseSignInReq();
    message.Email = object.Email ?? undefined;
    return message;
  },
};

function createBaseResendCodeReq(): ResendCodeReq {
  return { UsePhoneCallerNumber: undefined };
}

export const ResendCodeReq = {
  encode(
    message: ResendCodeReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UsePhoneCallerNumber !== undefined) {
      writer.uint32(8).bool(message.UsePhoneCallerNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResendCodeReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResendCodeReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UsePhoneCallerNumber = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResendCodeReq {
    return {
      UsePhoneCallerNumber: isSet(object.UsePhoneCallerNumber)
        ? Boolean(object.UsePhoneCallerNumber)
        : undefined,
    };
  },

  toJSON(message: ResendCodeReq): unknown {
    const obj: any = {};
    message.UsePhoneCallerNumber !== undefined &&
      (obj.UsePhoneCallerNumber = message.UsePhoneCallerNumber);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ResendCodeReq>, I>>(
    object: I,
  ): ResendCodeReq {
    const message = createBaseResendCodeReq();
    message.UsePhoneCallerNumber = object.UsePhoneCallerNumber ?? undefined;
    return message;
  },
};

function createBaseVerifyResp(): VerifyResp {
  return { AuthState: undefined, None: undefined };
}

export const VerifyResp = {
  encode(
    message: VerifyResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AuthState !== undefined) {
      AuthState.encode(message.AuthState, writer.uint32(10).fork()).ldelim();
    }
    if (message.None !== undefined) {
      Empty.encode(message.None, writer.uint32(802).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VerifyResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVerifyResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AuthState = AuthState.decode(reader, reader.uint32());
          break;
        case 100:
          message.None = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): VerifyResp {
    return {
      AuthState: isSet(object.AuthState)
        ? AuthState.fromJSON(object.AuthState)
        : undefined,
      None: isSet(object.None) ? Empty.fromJSON(object.None) : undefined,
    };
  },

  toJSON(message: VerifyResp): unknown {
    const obj: any = {};
    message.AuthState !== undefined &&
      (obj.AuthState = message.AuthState
        ? AuthState.toJSON(message.AuthState)
        : undefined);
    message.None !== undefined &&
      (obj.None = message.None ? Empty.toJSON(message.None) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<VerifyResp>, I>>(
    object: I,
  ): VerifyResp {
    const message = createBaseVerifyResp();
    message.AuthState =
      object.AuthState !== undefined && object.AuthState !== null
        ? AuthState.fromPartial(object.AuthState)
        : undefined;
    message.None =
      object.None !== undefined && object.None !== null
        ? Empty.fromPartial(object.None)
        : undefined;
    return message;
  },
};

function createBaseRefreshResp(): RefreshResp {
  return { OK: false };
}

export const RefreshResp = {
  encode(
    message: RefreshResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OK === true) {
      writer.uint32(8).bool(message.OK);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OK = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RefreshResp {
    return {
      OK: isSet(object.OK) ? Boolean(object.OK) : false,
    };
  },

  toJSON(message: RefreshResp): unknown {
    const obj: any = {};
    message.OK !== undefined && (obj.OK = message.OK);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RefreshResp>, I>>(
    object: I,
  ): RefreshResp {
    const message = createBaseRefreshResp();
    message.OK = object.OK ?? false;
    return message;
  },
};

/** / Authentication process */
export interface Authentication {
  Logout(
    request: DeepPartial<LogoutReq>,
    metadata?: grpc.Metadata,
  ): Promise<Empty>;
  /**
   * Errors:
   * -type: NotFound, Entity: AuthState (if no authentication process is run at the moment)
   */
  GetAuthenticationStatus(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  StartSignUp(
    request: DeepPartial<SignUpReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  StartSignIn(
    request: DeepPartial<SignInReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState>;
  Verify(
    request: DeepPartial<Secret>,
    metadata?: grpc.Metadata,
  ): Promise<VerifyResp>;
  ResendCode(
    request: DeepPartial<ResendCodeReq>,
    metadata?: grpc.Metadata,
  ): Promise<Empty>;
  WhoAmI(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<WhoAmIResp>;
  Refresh(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<RefreshResp>;
}

export class AuthenticationClientImpl implements Authentication {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Logout = this.Logout.bind(this);
    this.GetAuthenticationStatus = this.GetAuthenticationStatus.bind(this);
    this.StartSignUp = this.StartSignUp.bind(this);
    this.StartSignIn = this.StartSignIn.bind(this);
    this.Verify = this.Verify.bind(this);
    this.ResendCode = this.ResendCode.bind(this);
    this.WhoAmI = this.WhoAmI.bind(this);
    this.Refresh = this.Refresh.bind(this);
  }

  Logout(
    request: DeepPartial<LogoutReq>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      AuthenticationLogoutDesc,
      LogoutReq.fromPartial(request),
      metadata,
    );
  }

  GetAuthenticationStatus(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationGetAuthenticationStatusDesc,
      Empty.fromPartial(request),
      metadata,
    );
  }

  StartSignUp(
    request: DeepPartial<SignUpReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationStartSignUpDesc,
      SignUpReq.fromPartial(request),
      metadata,
    );
  }

  StartSignIn(
    request: DeepPartial<SignInReq>,
    metadata?: grpc.Metadata,
  ): Promise<AuthState> {
    return this.rpc.unary(
      AuthenticationStartSignInDesc,
      SignInReq.fromPartial(request),
      metadata,
    );
  }

  Verify(
    request: DeepPartial<Secret>,
    metadata?: grpc.Metadata,
  ): Promise<VerifyResp> {
    return this.rpc.unary(
      AuthenticationVerifyDesc,
      Secret.fromPartial(request),
      metadata,
    );
  }

  ResendCode(
    request: DeepPartial<ResendCodeReq>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      AuthenticationResendCodeDesc,
      ResendCodeReq.fromPartial(request),
      metadata,
    );
  }

  WhoAmI(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<WhoAmIResp> {
    return this.rpc.unary(
      AuthenticationWhoAmIDesc,
      Empty.fromPartial(request),
      metadata,
    );
  }

  Refresh(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<RefreshResp> {
    return this.rpc.unary(
      AuthenticationRefreshDesc,
      Empty.fromPartial(request),
      metadata,
    );
  }
}

export const AuthenticationDesc = {
  serviceName: 'com.diagnocat.auth.Authentication',
};

export const AuthenticationLogoutDesc: UnaryMethodDefinitionish = {
  methodName: 'Logout',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LogoutReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Empty.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AuthenticationGetAuthenticationStatusDesc: UnaryMethodDefinitionish =
  {
    methodName: 'GetAuthenticationStatus',
    service: AuthenticationDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return Empty.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AuthState.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const AuthenticationStartSignUpDesc: UnaryMethodDefinitionish = {
  methodName: 'StartSignUp',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SignUpReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthState.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AuthenticationStartSignInDesc: UnaryMethodDefinitionish = {
  methodName: 'StartSignIn',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SignInReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AuthState.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AuthenticationVerifyDesc: UnaryMethodDefinitionish = {
  methodName: 'Verify',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Secret.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...VerifyResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AuthenticationResendCodeDesc: UnaryMethodDefinitionish = {
  methodName: 'ResendCode',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResendCodeReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Empty.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AuthenticationWhoAmIDesc: UnaryMethodDefinitionish = {
  methodName: 'WhoAmI',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...WhoAmIResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const AuthenticationRefreshDesc: UnaryMethodDefinitionish = {
  methodName: 'Refresh',
  service: AuthenticationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RefreshResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
