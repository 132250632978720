/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  AssetType,
  assetTypeFromJSON,
  assetTypeToJSON,
} from '../model/dto_asset';
import { Created } from '../model/dto_base';
import { File } from '../model/dto_asset_common';

export const protobufPackage = 'com.diagnocat.model';

export interface UploadSession {
  ID: string;
  AssetType: AssetType;
  Files: UploadSessionFile[];
  UserID: string;
  OrganizationID: string;
  PatientID: string;
  StudyID: string;
  GeneratedFromStudyIDs: string[];
  ReportID: string;
  Closed: boolean;
  /** creation information */
  Created: Created | undefined;
}

export interface UploadSessionFile {
  File: File | undefined;
  InProcess: boolean;
  Uploaded: boolean;
  StartedAt: number;
  LastInProcessAt: number;
  UploadedAt: number;
}

export interface UploadTarget {
  FileID: string;
  Path: string;
  /** URL where you will PUT the file */
  UploadURL: string;
}

function createBaseUploadSession(): UploadSession {
  return {
    ID: '',
    AssetType: 0,
    Files: [],
    UserID: '',
    OrganizationID: '',
    PatientID: '',
    StudyID: '',
    GeneratedFromStudyIDs: [],
    ReportID: '',
    Closed: false,
    Created: undefined,
  };
}

export const UploadSession = {
  encode(
    message: UploadSession,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.AssetType !== 0) {
      writer.uint32(16).int32(message.AssetType);
    }
    for (const v of message.Files) {
      UploadSessionFile.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.UserID !== '') {
      writer.uint32(82).string(message.UserID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(90).string(message.OrganizationID);
    }
    if (message.PatientID !== '') {
      writer.uint32(98).string(message.PatientID);
    }
    if (message.StudyID !== '') {
      writer.uint32(106).string(message.StudyID);
    }
    for (const v of message.GeneratedFromStudyIDs) {
      writer.uint32(114).string(v!);
    }
    if (message.ReportID !== '') {
      writer.uint32(122).string(message.ReportID);
    }
    if (message.Closed === true) {
      writer.uint32(168).bool(message.Closed);
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadSession {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.AssetType = reader.int32() as any;
          break;
        case 3:
          message.Files.push(UploadSessionFile.decode(reader, reader.uint32()));
          break;
        case 10:
          message.UserID = reader.string();
          break;
        case 11:
          message.OrganizationID = reader.string();
          break;
        case 12:
          message.PatientID = reader.string();
          break;
        case 13:
          message.StudyID = reader.string();
          break;
        case 14:
          message.GeneratedFromStudyIDs.push(reader.string());
          break;
        case 15:
          message.ReportID = reader.string();
          break;
        case 21:
          message.Closed = reader.bool();
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UploadSession {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      AssetType: isSet(object.AssetType)
        ? assetTypeFromJSON(object.AssetType)
        : 0,
      Files: Array.isArray(object?.Files)
        ? object.Files.map((e: any) => UploadSessionFile.fromJSON(e))
        : [],
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      StudyID: isSet(object.StudyID) ? String(object.StudyID) : '',
      GeneratedFromStudyIDs: Array.isArray(object?.GeneratedFromStudyIDs)
        ? object.GeneratedFromStudyIDs.map((e: any) => String(e))
        : [],
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      Closed: isSet(object.Closed) ? Boolean(object.Closed) : false,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
    };
  },

  toJSON(message: UploadSession): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.AssetType !== undefined &&
      (obj.AssetType = assetTypeToJSON(message.AssetType));
    if (message.Files) {
      obj.Files = message.Files.map((e) =>
        e ? UploadSessionFile.toJSON(e) : undefined,
      );
    } else {
      obj.Files = [];
    }
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.StudyID !== undefined && (obj.StudyID = message.StudyID);
    if (message.GeneratedFromStudyIDs) {
      obj.GeneratedFromStudyIDs = message.GeneratedFromStudyIDs.map((e) => e);
    } else {
      obj.GeneratedFromStudyIDs = [];
    }
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.Closed !== undefined && (obj.Closed = message.Closed);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<UploadSession>, I>>(
    object: I,
  ): UploadSession {
    const message = createBaseUploadSession();
    message.ID = object.ID ?? '';
    message.AssetType = object.AssetType ?? 0;
    message.Files =
      object.Files?.map((e) => UploadSessionFile.fromPartial(e)) || [];
    message.UserID = object.UserID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.StudyID = object.StudyID ?? '';
    message.GeneratedFromStudyIDs =
      object.GeneratedFromStudyIDs?.map((e) => e) || [];
    message.ReportID = object.ReportID ?? '';
    message.Closed = object.Closed ?? false;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    return message;
  },
};

function createBaseUploadSessionFile(): UploadSessionFile {
  return {
    File: undefined,
    InProcess: false,
    Uploaded: false,
    StartedAt: 0,
    LastInProcessAt: 0,
    UploadedAt: 0,
  };
}

export const UploadSessionFile = {
  encode(
    message: UploadSessionFile,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.File !== undefined) {
      File.encode(message.File, writer.uint32(10).fork()).ldelim();
    }
    if (message.InProcess === true) {
      writer.uint32(16).bool(message.InProcess);
    }
    if (message.Uploaded === true) {
      writer.uint32(24).bool(message.Uploaded);
    }
    if (message.StartedAt !== 0) {
      writer.uint32(32).int64(message.StartedAt);
    }
    if (message.LastInProcessAt !== 0) {
      writer.uint32(40).int64(message.LastInProcessAt);
    }
    if (message.UploadedAt !== 0) {
      writer.uint32(48).int64(message.UploadedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadSessionFile {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadSessionFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.File = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.InProcess = reader.bool();
          break;
        case 3:
          message.Uploaded = reader.bool();
          break;
        case 4:
          message.StartedAt = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.LastInProcessAt = longToNumber(reader.int64() as Long);
          break;
        case 6:
          message.UploadedAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UploadSessionFile {
    return {
      File: isSet(object.File) ? File.fromJSON(object.File) : undefined,
      InProcess: isSet(object.InProcess) ? Boolean(object.InProcess) : false,
      Uploaded: isSet(object.Uploaded) ? Boolean(object.Uploaded) : false,
      StartedAt: isSet(object.StartedAt) ? Number(object.StartedAt) : 0,
      LastInProcessAt: isSet(object.LastInProcessAt)
        ? Number(object.LastInProcessAt)
        : 0,
      UploadedAt: isSet(object.UploadedAt) ? Number(object.UploadedAt) : 0,
    };
  },

  toJSON(message: UploadSessionFile): unknown {
    const obj: any = {};
    message.File !== undefined &&
      (obj.File = message.File ? File.toJSON(message.File) : undefined);
    message.InProcess !== undefined && (obj.InProcess = message.InProcess);
    message.Uploaded !== undefined && (obj.Uploaded = message.Uploaded);
    message.StartedAt !== undefined &&
      (obj.StartedAt = Math.round(message.StartedAt));
    message.LastInProcessAt !== undefined &&
      (obj.LastInProcessAt = Math.round(message.LastInProcessAt));
    message.UploadedAt !== undefined &&
      (obj.UploadedAt = Math.round(message.UploadedAt));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<UploadSessionFile>, I>>(
    object: I,
  ): UploadSessionFile {
    const message = createBaseUploadSessionFile();
    message.File =
      object.File !== undefined && object.File !== null
        ? File.fromPartial(object.File)
        : undefined;
    message.InProcess = object.InProcess ?? false;
    message.Uploaded = object.Uploaded ?? false;
    message.StartedAt = object.StartedAt ?? 0;
    message.LastInProcessAt = object.LastInProcessAt ?? 0;
    message.UploadedAt = object.UploadedAt ?? 0;
    return message;
  },
};

function createBaseUploadTarget(): UploadTarget {
  return { FileID: '', Path: '', UploadURL: '' };
}

export const UploadTarget = {
  encode(
    message: UploadTarget,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FileID !== '') {
      writer.uint32(10).string(message.FileID);
    }
    if (message.Path !== '') {
      writer.uint32(18).string(message.Path);
    }
    if (message.UploadURL !== '') {
      writer.uint32(26).string(message.UploadURL);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadTarget {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadTarget();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FileID = reader.string();
          break;
        case 2:
          message.Path = reader.string();
          break;
        case 3:
          message.UploadURL = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UploadTarget {
    return {
      FileID: isSet(object.FileID) ? String(object.FileID) : '',
      Path: isSet(object.Path) ? String(object.Path) : '',
      UploadURL: isSet(object.UploadURL) ? String(object.UploadURL) : '',
    };
  },

  toJSON(message: UploadTarget): unknown {
    const obj: any = {};
    message.FileID !== undefined && (obj.FileID = message.FileID);
    message.Path !== undefined && (obj.Path = message.Path);
    message.UploadURL !== undefined && (obj.UploadURL = message.UploadURL);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<UploadTarget>, I>>(
    object: I,
  ): UploadTarget {
    const message = createBaseUploadTarget();
    message.FileID = object.FileID ?? '';
    message.Path = object.Path ?? '';
    message.UploadURL = object.UploadURL ?? '';
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
