import { defineMessages } from 'react-intl';

import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';

export const studyCardTitle = defineMessages<StudyType>({
  [StudyType.StudyType_CBCT]: {
    id: 'studyCardTitle.StudyType_CBCT',
    defaultMessage: 'CBCT',
  },
  [StudyType.StudyType_PanoramicXRay]: {
    id: 'studyCardTitle.StudyType_PanoramicXRay',
    defaultMessage: 'PANO',
  },
  [StudyType.StudyType_IntraoralXRay]: {
    id: 'studyCardTitle.StudyType_IntraoralXRay',
    defaultMessage: 'FMX',
  },
  [StudyType.StudyType_DentalPhoto]: {
    id: 'studyCardTitle.StudyType_DentalPhoto',
    defaultMessage: 'Dental Photo',
  },
  [StudyType.StudyType_IOS_STL]: {
    id: 'studyCardTitle.StudyType_IOS_STL',
    defaultMessage: 'ISO STL',
  },
  [StudyType.StudyType_InvalidValue]: {
    id: 'studyCardTitle.StudyType_InvalidValue',
    defaultMessage: 'InvalidValue',
  },
  [StudyType.UNRECOGNIZED]: {
    id: 'studyCardTitle.UNRECOGNIZED',
    defaultMessage: 'UNRECOGNIZED',
  },
});
