import React, { useState } from 'react';

export const CircleHandleHelper = ({
  x,
  y,
  onDown,
}: {
  x: number;
  y: number;
  onDown: () => void;
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <circle
        opacity={visible ? 1 : 0}
        cx={x}
        cy={y}
        r="6"
        fill="none"
        stroke="white"
        strokeWidth="3"
        style={{ pointerEvents: 'all' }}
        onMouseEnter={() => {
          setVisible(true);
        }}
        onMouseLeave={() => {
          setVisible(false);
        }}
        onMouseDown={(e) => {
          onDown();
        }}
      />
    </>
  );
};
