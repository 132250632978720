import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@/shared/ui';

type EditToothNumberProps = {
  className?: string;
};

export const EditToothNumber: FC<EditToothNumberProps> = (props) => {
  const { className } = props;

  return (
    <Button variant="tertiary" size="small" icon="pen" className={className}>
      <FormattedMessage
        id="report.editToothNumbers"
        defaultMessage="Edit Numbers"
      />
    </Button>
  );
};
