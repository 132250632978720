import React, { FC } from 'react';
import cn from 'classnames';

import styles from './Header.module.scss';

type HeaderProps = {
  className?: string;
  testID?: string;
  children?: React.ReactNode;
  sticky?: 'sticky';
};

export const Header: FC<HeaderProps> = (props) => {
  const { className, testID, children, sticky } = props;

  return (
    <div
      className={cn(styles.container, styles[sticky], className)}
      data-testid={testID}
    >
      {children}
    </div>
  );
};
