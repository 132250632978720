import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { PURPLE_CODES, RED_CODES, YELLOW_CODES } from '../config/const';
import { ConditionColor } from '../config/types';

import { isConditionUncertain } from './isConditionUncertain';

// TODO: Deprectated function. Should be deleted
export const getConditionColor = (
  condition: Condition = {} as Condition,
  showUncertainConditions: boolean = false,
): ConditionColor => {
  const { Code } = condition;

  if (
    showUncertainConditions &&
    YELLOW_CODES.includes(Code) &&
    isConditionUncertain(condition)
  ) {
    return 'yellow';
  }

  if (RED_CODES.includes(Code)) {
    return 'red';
  }

  if (PURPLE_CODES.includes(Code)) {
    return 'purple';
  }

  return 'white';
};
