import React, { FC } from 'react';
import cn from 'classnames';

import { Layout } from '@/shared/ui/';
import { Header } from '@/widgets/Header';

import styles from './Disclaimer.module.scss';

type DisclaimerProps = {
  className?: string;
  testID?: string;
};

export const Disclaimer: FC<DisclaimerProps> = (props) => {
  const { className, testID } = props;

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.Content>
        <Layout.Main>
          <div className={cn(styles.container, className)} data-testid={testID}>
            Disclaimer page
          </div>
        </Layout.Main>
      </Layout.Content>
      <Layout.Footer />
    </Layout>
  );
};
