// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sider-module__container--ZgNeBWHe{display:flex;flex-direction:column;flex:0;margin-right:16px}\n\n[dir=\"rtl\"] .Sider-module__container--ZgNeBWHe{margin-right:0;margin-left:16px}", "",{"version":3,"sources":["webpack://./src/shared/ui/Layout/components/Sider/Sider.module.scss"],"names":[],"mappings":"AAAA,mCACE,YAAA,CACA,qBAAA,CACA,MAAA,CACA,iBAAA;;AAJF,+CAIE,cAAA,CAAA,gBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  flex: 0;\n  margin-right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Sider-module__container--ZgNeBWHe"
};
export default ___CSS_LOADER_EXPORT___;
