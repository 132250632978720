/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Created, Revision } from '../model/dto_base';

export const protobufPackage = 'com.diagnocat.model';

export enum ProjectTaskStatus {
  /** ProjectTaskStatusInvalidValue - default value */
  ProjectTaskStatusInvalidValue = 0,
  ProjectTaskStatusPending = 1,
  ProjectTaskStatusInProgress = 2,
  ProjectTaskStatusDone = 3,
  ProjectTaskStatusCancelled = 4,
  UNRECOGNIZED = -1,
}

export function projectTaskStatusFromJSON(object: any): ProjectTaskStatus {
  switch (object) {
    case 0:
    case 'ProjectTaskStatusInvalidValue':
      return ProjectTaskStatus.ProjectTaskStatusInvalidValue;
    case 1:
    case 'ProjectTaskStatusPending':
      return ProjectTaskStatus.ProjectTaskStatusPending;
    case 2:
    case 'ProjectTaskStatusInProgress':
      return ProjectTaskStatus.ProjectTaskStatusInProgress;
    case 3:
    case 'ProjectTaskStatusDone':
      return ProjectTaskStatus.ProjectTaskStatusDone;
    case 4:
    case 'ProjectTaskStatusCancelled':
      return ProjectTaskStatus.ProjectTaskStatusCancelled;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ProjectTaskStatus.UNRECOGNIZED;
  }
}

export function projectTaskStatusToJSON(object: ProjectTaskStatus): string {
  switch (object) {
    case ProjectTaskStatus.ProjectTaskStatusInvalidValue:
      return 'ProjectTaskStatusInvalidValue';
    case ProjectTaskStatus.ProjectTaskStatusPending:
      return 'ProjectTaskStatusPending';
    case ProjectTaskStatus.ProjectTaskStatusInProgress:
      return 'ProjectTaskStatusInProgress';
    case ProjectTaskStatus.ProjectTaskStatusDone:
      return 'ProjectTaskStatusDone';
    case ProjectTaskStatus.ProjectTaskStatusCancelled:
      return 'ProjectTaskStatusCancelled';
    case ProjectTaskStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface PatientProject {
  ID: string;
  PatientID: string;
  CaseDescription: string;
  DueDate: number;
  ClosedAt: number;
  Tasks: ProjectTask[];
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
}

export interface ProjectTask {
  ID: string;
  ProjectID: string;
  Title: string;
  Description: string;
  DueDate: number;
  Status: ProjectTaskStatus;
  Order: number;
  Assignees: string[];
  /** creation information */
  Created: Created | undefined;
}

function createBasePatientProject(): PatientProject {
  return {
    ID: '',
    PatientID: '',
    CaseDescription: '',
    DueDate: 0,
    ClosedAt: 0,
    Tasks: [],
    Created: undefined,
    Revision: undefined,
  };
}

export const PatientProject = {
  encode(
    message: PatientProject,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.PatientID !== '') {
      writer.uint32(18).string(message.PatientID);
    }
    if (message.CaseDescription !== '') {
      writer.uint32(26).string(message.CaseDescription);
    }
    if (message.DueDate !== 0) {
      writer.uint32(32).int64(message.DueDate);
    }
    if (message.ClosedAt !== 0) {
      writer.uint32(40).int64(message.ClosedAt);
    }
    for (const v of message.Tasks) {
      ProjectTask.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatientProject {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientProject();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.PatientID = reader.string();
          break;
        case 3:
          message.CaseDescription = reader.string();
          break;
        case 4:
          message.DueDate = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.ClosedAt = longToNumber(reader.int64() as Long);
          break;
        case 6:
          message.Tasks.push(ProjectTask.decode(reader, reader.uint32()));
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientProject {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      CaseDescription: isSet(object.CaseDescription)
        ? String(object.CaseDescription)
        : '',
      DueDate: isSet(object.DueDate) ? Number(object.DueDate) : 0,
      ClosedAt: isSet(object.ClosedAt) ? Number(object.ClosedAt) : 0,
      Tasks: Array.isArray(object?.Tasks)
        ? object.Tasks.map((e: any) => ProjectTask.fromJSON(e))
        : [],
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
    };
  },

  toJSON(message: PatientProject): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.CaseDescription !== undefined &&
      (obj.CaseDescription = message.CaseDescription);
    message.DueDate !== undefined &&
      (obj.DueDate = Math.round(message.DueDate));
    message.ClosedAt !== undefined &&
      (obj.ClosedAt = Math.round(message.ClosedAt));
    if (message.Tasks) {
      obj.Tasks = message.Tasks.map((e) =>
        e ? ProjectTask.toJSON(e) : undefined,
      );
    } else {
      obj.Tasks = [];
    }
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientProject>, I>>(
    object: I,
  ): PatientProject {
    const message = createBasePatientProject();
    message.ID = object.ID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.CaseDescription = object.CaseDescription ?? '';
    message.DueDate = object.DueDate ?? 0;
    message.ClosedAt = object.ClosedAt ?? 0;
    message.Tasks = object.Tasks?.map((e) => ProjectTask.fromPartial(e)) || [];
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    return message;
  },
};

function createBaseProjectTask(): ProjectTask {
  return {
    ID: '',
    ProjectID: '',
    Title: '',
    Description: '',
    DueDate: 0,
    Status: 0,
    Order: 0,
    Assignees: [],
    Created: undefined,
  };
}

export const ProjectTask = {
  encode(
    message: ProjectTask,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.ProjectID !== '') {
      writer.uint32(18).string(message.ProjectID);
    }
    if (message.Title !== '') {
      writer.uint32(26).string(message.Title);
    }
    if (message.Description !== '') {
      writer.uint32(34).string(message.Description);
    }
    if (message.DueDate !== 0) {
      writer.uint32(40).int64(message.DueDate);
    }
    if (message.Status !== 0) {
      writer.uint32(48).int32(message.Status);
    }
    if (message.Order !== 0) {
      writer.uint32(56).int64(message.Order);
    }
    for (const v of message.Assignees) {
      writer.uint32(66).string(v!);
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProjectTask {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProjectTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.ProjectID = reader.string();
          break;
        case 3:
          message.Title = reader.string();
          break;
        case 4:
          message.Description = reader.string();
          break;
        case 5:
          message.DueDate = longToNumber(reader.int64() as Long);
          break;
        case 6:
          message.Status = reader.int32() as any;
          break;
        case 7:
          message.Order = longToNumber(reader.int64() as Long);
          break;
        case 8:
          message.Assignees.push(reader.string());
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ProjectTask {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      ProjectID: isSet(object.ProjectID) ? String(object.ProjectID) : '',
      Title: isSet(object.Title) ? String(object.Title) : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
      DueDate: isSet(object.DueDate) ? Number(object.DueDate) : 0,
      Status: isSet(object.Status)
        ? projectTaskStatusFromJSON(object.Status)
        : 0,
      Order: isSet(object.Order) ? Number(object.Order) : 0,
      Assignees: Array.isArray(object?.Assignees)
        ? object.Assignees.map((e: any) => String(e))
        : [],
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
    };
  },

  toJSON(message: ProjectTask): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.ProjectID !== undefined && (obj.ProjectID = message.ProjectID);
    message.Title !== undefined && (obj.Title = message.Title);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    message.DueDate !== undefined &&
      (obj.DueDate = Math.round(message.DueDate));
    message.Status !== undefined &&
      (obj.Status = projectTaskStatusToJSON(message.Status));
    message.Order !== undefined && (obj.Order = Math.round(message.Order));
    if (message.Assignees) {
      obj.Assignees = message.Assignees.map((e) => e);
    } else {
      obj.Assignees = [];
    }
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ProjectTask>, I>>(
    object: I,
  ): ProjectTask {
    const message = createBaseProjectTask();
    message.ID = object.ID ?? '';
    message.ProjectID = object.ProjectID ?? '';
    message.Title = object.Title ?? '';
    message.Description = object.Description ?? '';
    message.DueDate = object.DueDate ?? 0;
    message.Status = object.Status ?? 0;
    message.Order = object.Order ?? 0;
    message.Assignees = object.Assignees?.map((e) => e) || [];
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
