// @ts-nocheck
import { FC, useState } from 'react';
import cn from 'classnames';

import { Button, Modal } from '@/shared/ui';

import styles from './ObjModal.module.scss';

type Props = {
  className?: string;
  obj?: Object;
};

const isValueBoolean = (value) => typeof value === 'boolean';
const isValueEmptyArray = (value) => Array.isArray(value) && !value.length;

const convertBooleanToString = (bool) => (bool ? 'true' : 'false');
const convertValueToString = (value) =>
  isValueEmptyArray(value) ? '[]' : value || 'undefined';

const generateKeyValue = (key, value) => (
  <li className={styles.row}>
    <div className={styles.key}>{key}</div>
    <div className={styles.divider}>=&#62;</div>
    <div className={styles.value}>
      {isValueBoolean(value)
        ? convertBooleanToString(value)
        : convertValueToString(value)}
    </div>
  </li>
);

const generateKeyObj = (key, object) => (
  <ul className={styles.ul}>
    <li className={styles.header}>{key}</li>

    <ul>
      {
        // eslint-disable-next-line
        generateListFromObject(object)
      }
    </ul>
  </ul>
);

// eslint-disable-next-line
const generateListFromObject = (obj) =>
  Object.keys(obj).map((key) =>
    typeof obj[key] === 'object' && !isValueEmptyArray(obj[key])
      ? generateKeyObj(key, obj[key])
      : generateKeyValue(key, obj[key]),
  );

export const ObjModal: FC<Props> = (props) => {
  const { className, obj } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={cn(styles.cont, className)}>
      <Button size="small" onClick={() => setIsOpen(true)} icon="eye" />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {!!obj && isOpen && generateListFromObject(obj)}
      </Modal>
    </div>
  );
};
