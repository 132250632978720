import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import { format } from 'date-fns';
import { generatePath, useNavigate } from 'react-router-dom';

import { WidgetCard, Button } from '@/shared/ui';
import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import { useAppSelector, useMedia } from '@/shared/hooks';
import { STUDY_IMAGE_DATE_FORMAT } from '@/entities/study';
import { assetsModel, getImageSrc } from '@/entities/assets';
import { PATHS } from '@/shared/config';
import { reportType } from '@/entities/reports/config/i18n';

import { RecentReportsIcons } from '../RecentReportsIcons/RecentReportsIcons';

import styles from './RecentReports.module.scss';

type RecentReportsProps = {
  reports: Report[];
  patientID: string;
};

export const RecentReports: React.FC<RecentReportsProps> = (props) => {
  const { reports, patientID } = props;

  const { isPhone } = useMedia();

  const navigate = useNavigate();

  const { formatMessage } = useIntl();

  const recentReports = reports
    .sort((a, b) => a.Created.At - b.Created.At)
    .slice(0, 6);

  const defaultRecentReport = recentReports.length - 1;

  const [currentReportIndex, setCurrentReportIndex] =
    useState(defaultRecentReport);

  const currentReport = reports[currentReportIndex];

  const currentPanoImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(currentReport.ID),
  );

  const currentPanoSrc = useMemo(
    () =>
      getImageSrc(
        currentPanoImage?.CBCTGPPanoramaReformatGeneral.Derived.Thumbnail.ID,
      ),
    [currentPanoImage],
  );

  const handleSetCurrentReport = (index: number) => {
    setCurrentReportIndex(index);
  };

  const handleReportClick = () => {
    navigate(
      generatePath(PATHS.report, {
        patientID,
        reportID: currentReport.ID,
      }),
    );
  };

  return (
    <WidgetCard className={styles.container}>
      <h3 className={isPhone ? 'h4' : 'h3'}>
        <FormattedMessage
          id="recentReports.title"
          defaultMessage="Recent Reports"
        />
      </h3>
      <div className={styles.photoWrapper} onClick={handleReportClick}>
        {currentPanoImage ? (
          <img
            className={styles.image}
            src={currentPanoSrc}
            alt={formatMessage(reportType[currentReport.Type])}
          />
        ) : (
          <div className={styles.noImage} />
        )}

        <div className={styles.layer}>
          <div className={styles.info}>
            <h4 className={cn(styles.reportType, isPhone ? 'h5' : 'h4')}>
              {formatMessage(reportType[currentReport.Type])}
            </h4>
            <span className={cn(styles.date, isPhone ? 'p4' : 'p3')}>
              {format(currentReport?.Created.At, STUDY_IMAGE_DATE_FORMAT)}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.reportsWithAllButton}>
        <div className={styles.reportsWrapper}>
          {recentReports.map((report, index) => (
            <RecentReportsIcons
              report={report}
              reportIndex={index}
              currentReportIndex={currentReportIndex}
              setCurrentReport={handleSetCurrentReport}
              key={report.ID}
            />
          ))}
        </div>
      </div>
      <footer>
        <Button variant="secondary" size="medium" block icon="plus">
          <FormattedMessage
            id="recentReports.orderButton"
            defaultMessage="Order new report"
          />
        </Button>
      </footer>
    </WidgetCard>
  );
};
