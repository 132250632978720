export const TOOTH_PAGE_GROUPNAMES = {
  OrientationAxial: 1,
  OrientationVestibuloOral: 2,
  OrientationMesioDistal: 3,
};

export const TOOTH_PAGE_PATHOLOGYS = {
  Caries: 0,
  PeriapicalLesion: 1,
};
