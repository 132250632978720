import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { groupBy, prop } from 'ramda';

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { Accordion } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { reportsModel, i18n } from '@/entities/reports';
import {
  RemoveReportModal,
  useRemoveReportModal,
} from '@/features/removeReport';
import { ReportRow } from '@/features/reportRow';

import styles from './ReportAccordion.module.scss';

// Can we add remove callback here and move remove report modal upper in StudyCard?
type ReportAccordionProps = {
  studyID: string;
};

export const ReportAccordion: FC<ReportAccordionProps> = (props) => {
  const { studyID } = props;
  const { formatMessage } = useIntl();

  const reports = useAppSelector((state) =>
    reportsModel.selectors.selectByStudyID(state, studyID),
  );

  const reportGroups = useMemo(
    () => groupBy(prop<keyof Report>('Type'))(reports),
    [reports],
  ) as Record<ReportType, Report[]>;

  const {
    currentReportID,
    isRemoveReportModalOpen,
    openRemoveReportModal,
    closeRemoveReportModal,
  } = useRemoveReportModal();

  const studyReportTypes = Object.keys(reportGroups);

  return (
    <>
      <Accordion.Root
        type="multiple"
        defaultValue={studyReportTypes}
        className={styles.container}
      >
        {studyReportTypes.map((reportType) => (
          <Accordion.Item key={reportType} value={reportType}>
            <Accordion.Header>
              <Accordion.Trigger>
                {formatMessage(
                  i18n.reportType[reportType as unknown as ReportType],
                )}
              </Accordion.Trigger>
            </Accordion.Header>

            <Accordion.Content>
              <div className={styles.reportRowContainer}>
                {reportGroups[reportType as unknown as ReportType].map(
                  (report) => (
                    <ReportRow
                      key={report.ID}
                      report={report}
                      reportType={reportType as unknown as ReportType}
                      onDelete={() => openRemoveReportModal(report.ID)}
                    />
                  ),
                )}
              </div>
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>

      <RemoveReportModal
        reportID={currentReportID}
        isOpen={isRemoveReportModalOpen}
        onClose={closeRemoveReportModal}
      />
    </>
  );
};
