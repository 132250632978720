import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/app/model/store';

import { patientAdapter } from './patientSlice';

export const patientSelectors = patientAdapter.getSelectors(
  (state: RootState) => state.patient,
);

export const { selectAll, selectById, selectTotal, selectEntities, selectIds } =
  patientSelectors;

export const selectCounters = createSelector(
  (state: RootState): [number, number] => [
    state.patient.sharedCounter,
    state.patient.totalCounter,
  ],

  (counters) => counters,
);

export const selectPatientDoctors = (patientID: string) => (state: RootState) =>
  patientSelectors.selectById(state, patientID)?.Doctors;
