import React, { useContext, useEffect, useState } from 'react';
import { AbsoluteBlock } from '../AbsoluteBlock';
import {
  ObjectFitContain,
  ObjectFitContaintContext,
} from '../ObjectFitContain';
import { AnnotationOrEraser } from '../Presets/DicomImageWithAnnotations';
import {
  TouchableState,
  TouchableLayer,
  TouchableLayerProps,
} from '../TouchableLayer/TouchableLayer';
import { Annotation, AnnotationEditContext } from './Annotation';
import { AnnotationsHandlesLayer } from './AnnotationsHandlesLayer';
import { EditableSomething } from './EditableSomething';
import { RenderAnnotationsLayer } from './RenderAnnotationsLayer';

const createAnnotation = (
  kind: Annotation['kind'],
  x: number,
  y: number,
): { annotation: Annotation; context: AnnotationEditContext } => {
  if (kind === 'ruler')
    return {
      annotation: { kind: 'ruler', x1: x, y1: y, x2: x, y2: y },
      context: { kind: 'ruler', value: 'creation' },
    };

  if (kind === 'arrow')
    return {
      annotation: { kind: 'arrow', x1: x, y1: y, x2: x, y2: y },
      context: { kind: 'arrow', value: 'creation' },
    };

  if (kind === 'angle')
    return {
      annotation: { kind: 'angle', x1: x, y1: y, x2: x, y2: y, x3: x, y3: y },
      context: { kind: 'angle', value: 'creation' },
    };
  else throw new Error(`No such kind of annotation like ${kind}`);
};

export type AnnotationLayerProps = {
  annotations: Annotation[];
  pixelSpacing: [number, number];
  setAnnotations: React.Dispatch<React.SetStateAction<Annotation[]>>;
  annotationKind: AnnotationOrEraser;
  editable?: boolean;
};

export const AnnotationsLayer = ({
  annotationKind,
  pixelSpacing,
  // Current bunch of static (opposite to editable) annotations
  annotations,
  setAnnotations,
  editable = true,
}: AnnotationLayerProps) => {
  // Input state from TouchableLayer (up, down, pointer position, etc)
  const [touchableState, setTouchableState] = useState<TouchableState>({
    kind: 'none',
  });
  // Annotation that is is edit mode (we creating a new one or editing existing one)
  const [editableAnnotation, setEditableAnnotation] = useState<{
    annotation: Annotation;
    context: AnnotationEditContext;
  } | null>(null);

  // Set Input state to none after 'up' to not stuck in 'up'
  // state while no more actions
  useEffect(() => {
    if (touchableState.kind !== 'up') return;
    setTouchableState({ kind: 'none' });
  }, [touchableState]);

  return (
    <>
      <AbsoluteBlock>
        <RenderAnnotationsLayer
          annotations={annotations}
          pixelSpacing={pixelSpacing}
        >
          {editableAnnotation && (
            <EditableSomething
              context={editableAnnotation.context}
              annotation={editableAnnotation.annotation}
              touchableState={touchableState}
              pixelSpacing={pixelSpacing}
              onFinishedChanging={(annotation) => {
                // add annotation and disable editable
                setAnnotations((state) => [...state, annotation]);
                setEditableAnnotation(null);
                // console.log(annotation, 'transfered to static');
              }}
              onStepChange={(annotation, context) => {
                // Just replace current editable with requested
                setEditableAnnotation({
                  annotation,
                  context,
                });
              }}
            />
          )}
        </RenderAnnotationsLayer>
      </AbsoluteBlock>
      {editable && (
        <>
          <AbsoluteBlock>
            <LogicalSpaceTouchableLayer
              onDown={(x1, y1, x2, y2, targetIsChild) => {
                console.log('onDown');
                setTouchableState({ kind: 'down', x1, y1, x2, y2 });
                // if there is something editable already do nothing then
                if (editableAnnotation || targetIsChild) return;
                // if eraser down, dont create nothing
                if (annotationKind === 'eraser') return;
                // else create new editable entity
                // console.log('down new entity', x1, y1);
                const entity = createAnnotation(annotationKind, x1, y1);
                // console.log('annotationCreated', entity);
                setEditableAnnotation(entity);
              }}
              onDrag={(x1, y1, x2, y2, dx, dy) => {
                setTouchableState({ kind: 'drag', x1, y1, x2, y2 });
              }}
              onUp={(x1, y1, x2, y2, outside) => {
                setTouchableState({ kind: 'up', x1, y1, x2, y2 });
              }}
            >
              <AnnotationsHandlesLayer
                // We place AnnotationsHandlesLayer inside TouchableLayer to be able get inputs over handles
                // and also bubble them up to touchableLayer
                annotations={annotations}
                onIntentToEdit={(annotation, index, context) => {
                  // remove annotation and enable editablesomthing
                  setAnnotations((state) =>
                    state.filter((_, i) => i !== index),
                  );
                  if (annotationKind == 'eraser') {
                    // console.log(index, annotations, 'erased');
                    return;
                  }
                  setEditableAnnotation({ annotation, context });
                  // console.log(
                  //   index,
                  //   annotation,
                  //   'transfered to editable.',
                  //   context,
                  // );
                }}
              />
            </LogicalSpaceTouchableLayer>
          </AbsoluteBlock>
        </>
      )}
    </>
  );
};

// Same as TouchableLayer but all the output coords
// translated to logical space (matters when image is upscaled)
const LogicalSpaceTouchableLayer = (props: TouchableLayerProps) => {
  const objectFit = useContext(ObjectFitContaintContext);

  const aspect = 1 / objectFit.aspect;

  return (
    <TouchableLayer
      onDown={(x1, y1, x2, y2, targetIsChild) => {
        props.onDown(
          x1 * aspect,
          y1 * aspect,
          x2 * aspect,
          y2 * aspect,
          targetIsChild,
        );
      }}
      onDrag={(x1, y1, x2, y2, dx, dy, outside) => {
        props.onDrag(
          x1 * aspect,
          y1 * aspect,
          x2 * aspect,
          y2 * aspect,
          dx * aspect,
          dy * aspect,
          outside,
        );
      }}
      onUp={(x1, y1, x2, y2, outside) => {
        props.onUp(x1 * aspect, y1 * aspect, x2 * aspect, y2 * aspect, outside);
      }}
    >
      {props.children}
    </TouchableLayer>
  );
};
