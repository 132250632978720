import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { JAW, Quartiles } from '../config/toothConfig';

import { getJawQuartile } from './getJawQuartile';

export const makeJawObject = (teeth: Partial<Tooth>[]) =>
  teeth.reduce(
    (acc, tooth) => {
      const quartile = getJawQuartile(tooth.ISONumber);

      return {
        ...acc,
        [quartile]: teeth.filter(({ ISONumber }) =>
          // TODO type error?
          JAW[quartile].includes(ISONumber),
        ),
      };
    },
    {
      quartile1: [],
      quartile2: [],
      quartile3: [],
      quartile4: [],
    } as Record<Quartiles, Tooth[]>,
  );
