import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { Notification } from '@/shared/api/protocol_gen/api/notifications/dto_notification';

export const notificationAdapter = createEntityAdapter({
  selectId: (notification: Notification) => notification.ID,
});

const initialState = {
  notificationStatus: {
    isOpen: false,
    hasUnread: false,
  },
  notification: notificationAdapter.getInitialState(),
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    changeHasUnreadTo: (state, action) => {
      state.notificationStatus.hasUnread = action.payload;
    },

    changeNotificationIsOpenTo: (state, action) => {
      state.notificationStatus.isOpen = action.payload;
    },

    clearNotification: (state) => {
      notificationAdapter.removeAll(state.notification);
    },

    addNotification: (state, action) => {
      notificationAdapter.setOne(state.notification, action.payload);
    },
  },
});

export const {
  changeHasUnreadTo,
  addNotification,
  changeNotificationIsOpenTo,
  clearNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
