// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ObjModal-module__ul--em7-Pq7J{padding-inline-start:10px;margin-block-start:1px}.ObjModal-module__ul--em7-Pq7J ul{padding-inline-start:10px}.ObjModal-module__row--tkxTSsxP{display:flex}.ObjModal-module__key--owpfgYjc{font-weight:bold}.ObjModal-module__header--3U7hken3{font-size:18px;text-decoration:underline;padding-top:8px}.ObjModal-module__divider--3g7yAbju{margin:0 4px}", "",{"version":3,"sources":["webpack://./src/pages/TestPage/components/ObjModal/ObjModal.module.scss"],"names":[],"mappings":"AAAA,+BACE,yBAAA,CACA,sBAAA,CACA,kCACE,yBAAA,CAGJ,gCACE,YAAA,CAGF,gCACE,gBAAA,CAGF,mCACE,cAAA,CACA,yBAAA,CACA,eAAA,CAGF,oCACE,YAAA","sourcesContent":[".ul {\n  padding-inline-start: 10px;\n  margin-block-start: 1px;\n  ul {\n    padding-inline-start: 10px;\n  }\n}\n.row {\n  display: flex;\n}\n\n.key {\n  font-weight: bold;\n}\n\n.header {\n  font-size: 18px;\n  text-decoration: underline;\n  padding-top: 8px;\n}\n\n.divider {\n  margin: 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ul": "ObjModal-module__ul--em7-Pq7J",
	"row": "ObjModal-module__row--tkxTSsxP",
	"key": "ObjModal-module__key--owpfgYjc",
	"header": "ObjModal-module__header--3U7hken3",
	"divider": "ObjModal-module__divider--3g7yAbju"
};
export default ___CSS_LOADER_EXPORT___;
