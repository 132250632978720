import { defineMessages } from 'react-intl';

import { PatientsFilters, PatientListRow } from './const';

export const patientListFiltersMessages = defineMessages<PatientsFilters>({
  all: {
    id: 'patientList.filters.all',
    defaultMessage: 'All',
  },
  sharedWithMe: {
    id: 'patientList.filters.sharedWithMe',
    defaultMessage: 'Shared with me',
  },
});

export const patientListRowMessages = defineMessages<PatientListRow>({
  cbct: {
    id: 'patientList.studies.cbct',
    defaultMessage: 'CBCT',
  },
  ios: {
    id: 'patientList.studies.ios',
    defaultMessage: 'IOS',
  },
  pano: {
    id: 'patientList.studies.pano',
    defaultMessage: 'Panorama',
  },
  photo: {
    id: 'patientList.studies.photo',
    defaultMessage: 'Photo',
  },
  '3d': {
    id: 'patientList.studies.3d',
    defaultMessage: '3D',
  },
  doc: {
    id: 'patientList.studies.doc',
    defaultMessage: 'Document',
  },
});
