import React, { FC } from 'react';
import cn from 'classnames';

import { Switch } from '../Switch/Switch';
import { ToothIcon } from '../ToothIcon/ToothIcon';

import styles from './ToothView.module.scss';

export type ToothViewProps = {
  position: number;
  toothNumberView?: number;
  withToggle?: boolean;
  toothStatus?: string;
  disabled?: boolean;
  isToggleChecked?: boolean;
  isLowerJaw: boolean;
  isSupernumerary?: boolean;
  isActive?: boolean;
  isROIOpen?: boolean;
  isSimplify?: boolean;
  onToothClick?: () => void;
  onToggle?: () => void;
  className?: string;
  toothOfInterestNumber?: number;
};

export const ToothView: FC<ToothViewProps> = (props) => {
  const {
    className,
    toothNumberView,
    position,
    withToggle,
    toothStatus,
    disabled,
    isToggleChecked,
    isActive,
    isROIOpen,
    isSimplify,
    onToothClick,
    onToggle,
    isLowerJaw,
    isSupernumerary,
    toothOfInterestNumber,
  } = props;

  const isThirdMolar = position.toString().includes('8');

  return (
    <div
      className={cn(
        isSimplify ? styles.siplyContainer : styles.container,
        toothOfInterestNumber === toothNumberView && styles.currentActiveTooth,
        isActive === false && styles.notActive,
        (isROIOpen || isActive) && styles.pointer,
        isLowerJaw && styles.isLowerJaw,
        withToggle && styles.withToggle,
        disabled && styles.disabled,
        className,
      )}
      onClick={onToothClick}
    >
      <span
        className={cn(
          styles.position,
          toothStatus === 'unhealthy' && styles.pathology,
          toothStatus === 'missing' && !isThirdMolar && styles.pathology,
          toothStatus === 'treated' && styles.treated,
          toothStatus === 'lowProbability' && styles.lowProbability,
          'p4',
        )}
      >
        {`${toothNumberView}${isSupernumerary ? 'S' : ''}`}
      </span>
      {!isSimplify && (
        <div className={styles.iconContainer}>
          <ToothIcon
            isMissing={toothStatus === 'missing'}
            isThirdMolar={isThirdMolar}
            className={cn(
              toothStatus === 'unhealthy' && styles.pathology,
              toothStatus === 'treated' && styles.treated,
              toothStatus === 'lowProbability' && styles.lowProbability,
            )}
            position={position}
          />
        </div>
      )}
      {withToggle && (
        <Switch
          disabled={toothStatus === 'missing'}
          onChange={onToggle}
          checked={isToggleChecked}
          size="small"
        />
      )}
    </div>
  );
};
