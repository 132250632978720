import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';
import { Subscription } from 'rxjs';

import { Layout } from '@/shared/ui/';
import api from '@/shared/api/api';
import { userModel } from '@/entities/user';
import { useAppDispatch } from '@/shared/hooks';
import { organizationModel } from '@/entities/organization';
import { PATHS } from '@/shared/config';
import { Header } from '@/widgets/Header';

import styles from './TestPage.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import { UserTest } from './components/User/UserTest';
import { PatientTest } from './components/Patient/PatientTest';
import { ReportTest } from './components/Report/ReportTest';
import { OrganizationTest } from './components/Organization/OrganizationTest';
import { TestNavigation } from './components/TestNavigation/TestNavigation';

interface Props {
  className?: string;
}

type Tab = 'user' | 'patient' | 'report' | 'organization';

export const TestPage: FC<Props> = (props) => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [tab, setTab] = useState<Tab>('user');

  const isTabActive = (currentTab: Tab) => currentTab === tab;

  const userDataStream = useRef<Subscription>(undefined);

  const openUserDataStream = () => {
    userDataStream.current = api.user.MyDataStream({}).subscribe({
      next: (data) => {
        if (data.HistoricalMe) {
          dispatch(userModel.actions.addUser(data.HistoricalMe));
          navigate(PATHS.organizations);
        }
        if (data.HistoricalOrganization) {
          dispatch(
            organizationModel.actions.setOne(data.HistoricalOrganization),
          );
        }
      },
      error: (error) => {
        // eslint-disable-next-line no-console
        console.error(
          'MyDataStream:: error: ',
          error.message,
          error.code,
          error.type,
          error,
        );
      },
      complete: () => {
        // Do nothing
      },
    });
  };

  useEffect(
    () => () => {
      if (userDataStream.current) userDataStream.current.unsubscribe();
    },
    [],
  );

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.Content>
        <Layout.Main>
          <div className={cn(styles.cont, className)}>
            <TestNavigation />
            <div className={styles.menu}>
              <h3
                className={styles[isTabActive('user') && 'activeTab']}
                onClick={() => {
                  setTab('user');
                }}
              >
                USER
              </h3>
              <h3
                className={styles[isTabActive('patient') && 'activeTab']}
                onClick={() => {
                  setTab('patient');
                }}
              >
                PATIENT
              </h3>

              <h3
                className={styles[isTabActive('report') && 'activeTab']}
                onClick={() => {
                  setTab('report');
                }}
              >
                REPORT
              </h3>
              <h3
                className={styles[isTabActive('organization') && 'activeTab']}
                onClick={() => {
                  setTab('organization');
                }}
              >
                ORGANIZATION
              </h3>
            </div>
            {tab === 'user' && (
              <UserTest
                openStream={openUserDataStream}
                closeStream={() => {
                  userDataStream.current.unsubscribe();
                }}
                className={styles.testEntity}
              />
            )}
            {tab === 'patient' && <PatientTest className={styles.testEntity} />}
            {tab === 'report' && <ReportTest className={styles.testEntity} />}
            {tab === 'organization' && (
              <OrganizationTest className={styles.testEntity} />
            )}
          </div>
        </Layout.Main>
      </Layout.Content>
      <Layout.Footer />
    </Layout>
  );
};
