import { useCallback, useState } from 'react';

import { useAppDispatch } from '@/shared/hooks';
import { reportsModel } from '@/entities/reports';
import { conditionModel, ConditionDecisionChange } from '@/entities/condition';
import { toastCaller } from '@/shared/ui';

export const useUpdateConditionDecision = (toothID: string) => {
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const dispatch = useAppDispatch();

  const updateConditionDecision: ConditionDecisionChange = useCallback(
    async ({ conditionID, parentConditionId, conditionCode, userDecision }) => {
      setLoading(true);

      try {
        // Update condition
        if (conditionID) {
          const { Condition } = await dispatch(
            reportsModel.thunks.setReportToothConditionUserDecision({
              ConditionID: conditionID,
              UserDecision: userDecision,
            }),
          ).unwrap();

          dispatch(conditionModel.actions.setNewestOne(Condition));
          // Add condition
        } else {
          const { Conditions } = await dispatch(
            reportsModel.thunks.createReportToothConditions({
              ToothID: toothID,
              Conditions: [
                { Code: conditionCode, ParentConditionID: parentConditionId },
              ],
            }),
          ).unwrap();

          dispatch(conditionModel.actions.addMany(Conditions));
        }
      } catch (error) {
        toastCaller({
          message: error?.message,
          type: 'error',
          heading: 'Error',
        });

        setErrorMessage(error?.message);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, toothID],
  );

  return {
    updateConditionDecision,
    isLoading,
    errorMessage,
  };
};
