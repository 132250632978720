// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddSignature-module__container--PjoqMh-f{padding:12px 0;display:flex;flex-direction:column;gap:12px}.AddSignature-module__addSignature--fF6amvvd{display:flex;gap:12px;align-items:center}", "",{"version":3,"sources":["webpack://./src/pages/Report/ui/AddSignature/AddSignature.module.scss"],"names":[],"mappings":"AAAA,0CACE,cAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,6CACE,YAAA,CACA,QAAA,CACA,kBAAA","sourcesContent":[".container {\n  padding: 12px 0;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.addSignature {\n  display: flex;\n  gap: 12px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AddSignature-module__container--PjoqMh-f",
	"addSignature": "AddSignature-module__addSignature--fF6amvvd"
};
export default ___CSS_LOADER_EXPORT___;
