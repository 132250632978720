// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CopyPaste-module__cont--4zywYxum{display:inline-block}", "",{"version":3,"sources":["webpack://./src/pages/TestPage/components/CopyPaste/CopyPaste.module.scss"],"names":[],"mappings":"AAAA,kCACE,oBAAA","sourcesContent":[".cont {\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cont": "CopyPaste-module__cont--4zywYxum"
};
export default ___CSS_LOADER_EXPORT___;
