import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { isConditionUncertain } from './isConditionUncertain';

export const shouldConditionItemBeShown =
  (showUncertainConditions: boolean = false) =>
  (condition: Condition): boolean => {
    const { Certainty } = condition;

    if (showUncertainConditions && isConditionUncertain(condition)) {
      return true;
    }

    if (Certainty.UserDecision === Decision.PositiveDecision) {
      return true;
    }

    if (Certainty.UserDecision === Decision.NegativeDecision) {
      return false;
    }

    if (
      Certainty.UserDecision === Decision.NoDecision &&
      Certainty.EngineDecision === Decision.PositiveDecision
    ) {
      return true;
    }

    if (
      Certainty.UserDecision === Decision.NoDecision &&
      Certainty.EngineDecision === Decision.NegativeDecision
    ) {
      return false;
    }

    if (
      Certainty.UserDecision === Decision.NoDecision &&
      Certainty.EngineDecision === Decision.NoDecision &&
      Certainty.ModelDecision === Decision.PositiveDecision
    ) {
      return true;
    }

    if (
      Certainty.UserDecision === Decision.NoDecision &&
      Certainty.EngineDecision === Decision.NoDecision &&
      Certainty.ModelDecision === Decision.NoDecision
    ) {
      return false;
    }

    return false;
  };
