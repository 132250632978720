import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

export const REPORT_DATE_FORMAT = 'MMM d, Y';

export const REPORT_CREATION_DATE_FORMAT = 'MMM d, Y HH:mm';

export const REPORT_SIGNED_DATE_FORMAT = 'EEE dd, yyyy HH:mm';

export const ENABLED_RECENT_REPORTS_TYPES: ReportType[] = [
  ReportType.ReportType_CBCT_GP,
  ReportType.ReportType_CBCT_Endo,
  ReportType.ReportType_CBCT_Implant,
  ReportType.ReportType_CBCT_Molar,
  ReportType.ReportType_IOXRay_GP,
  ReportType.ReportType_DentalPhoto_GP,
];
