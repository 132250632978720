import { useCallback } from 'react';
import { useParams } from 'react-router';

import { useAppSelector, useAppDispatch } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';
import { getPatientFullName, patientModel } from '@/entities/patient';
import { reportsModel } from '@/entities/reports';
import { getROITeethIDsAfterRemoveOne, toothModel } from '@/entities/tooth';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { useReportDataStream } from './useReportDataStream';

export const useReport = () => {
  const dispatch = useAppDispatch();

  const { patientID, reportID } = useParams();

  const report = useAppSelector((state) =>
    reportsModel.selectors.selectById(state, reportID),
  );

  const reportLoading = useAppSelector((state) => state.reports.loading);
  const isReportLoading =
    reportLoading === 'idle' || reportLoading === 'pending';

  const ROITeeth: Tooth[] = useAppSelector((state) =>
    toothModel.selectors.selectByReportIDAndROI(state, reportID),
  );

  const isAllTeethApproved = ROITeeth.every((tooth) => tooth.IsApproved);

  const isReportSigned = report?.Signature.UserSignatures.length;

  const patient = useAppSelector((state) =>
    patientModel.selectors.selectById(state, patientID),
  );

  const patientFullName = getPatientFullName(patient);

  const patientLoading = useAppSelector((state) => state.patient.loading);
  const isPatientLoading =
    patientLoading === 'idle' || patientLoading === 'pending';

  const handleRemoveToothFromROI = useCallback(
    async (toothID: string) => {
      const newROITeethIDs = getROITeethIDsAfterRemoveOne(ROITeeth, toothID);

      try {
        const response = await dispatch(
          reportsModel.thunks.setROI({
            ReportID: reportID,
            ROIToothIDs: newROITeethIDs,
          }),
        ).unwrap();

        dispatch(toothModel.actions.setMany(response.AllReportTeeth));
      } catch (error) {
        toastCaller({
          message: error?.message,
          type: 'error',
          heading: 'Error',
        });
      }
    },
    [reportID, ROITeeth, dispatch],
  );

  useReportDataStream(reportID);

  return {
    report,
    isReportLoading,
    ROITeeth,
    isAllTeethApproved,
    isReportSigned,
    handleRemoveToothFromROI,
    patient,
    isPatientLoading,
    patientFullName,
  };
};
