import React from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';

import { Avatar, Button, Icon, toastCaller } from '@/shared/ui';
import { useAppDispatch, useMedia } from '@/shared/hooks';
import { authModel } from '@/entities/auth';
import { PATHS } from '@/shared/config';
import { resetState } from '@/shared/model';

import styles from './ControlPanel.module.scss';

type MenuProps = {
  className?: string;
};

export const ControlPanel = (props: MenuProps) => {
  const { className } = props;

  const navigate = useNavigate();
  const intl = useIntl();

  const dispatch = useAppDispatch();

  const { isMobile } = useMedia();

  const size = isMobile ? 32 : 48;

  const logout = async () => {
    try {
      await dispatch(authModel.thunks.logout({})).unwrap();

      // TODO: REMOVE dispatch and localstorage cleaner whe auth will work correctly.
      // Think about to encapsulate this into thunk
      localStorage.removeItem('organizationID');
      localStorage.removeItem('user');
      window.dispatchEvent(new Event('storage'));
      dispatch(authModel.actions.setIsAuthenticated(false));
      dispatch(resetState());

      navigate(PATHS.signIn);
    } catch (e) {
      toastCaller({
        type: 'error',
        heading: intl.formatMessage({
          id: 'global.error',
          defaultMessage: 'Error',
        }),
        message: intl.formatMessage({
          id: 'global.wentWrong',
          defaultMessage: 'Something went wrong',
        }),
      });
    }
  };

  return isMobile ? (
    <div className={cn(styles.container, className)}>
      <Icon className={styles.burger} name="burger" width={26} height={20} />
    </div>
  ) : (
    <div className={cn(styles.container, className)}>
      <Icon className={styles.icon} name="study" size={size} />
      <Icon className={styles.icon} name="search" size={size} />
      <Icon className={styles.icon} name="bell" size={size} />
      <Icon className={styles.icon} name="moon" size={size} />
      <Button size="small" onClick={() => logout()}>
        <FormattedMessage id="global.logout" defaultMessage="Logout" />
      </Button>
      <Avatar initials="JD" size={size} />
    </div>
  );
};
