import {
  createSlice,
  createEntityAdapter,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Study } from '@/shared/api/protocol_gen/model/dto_study';
import { DefaultInitialState, SliceLoading } from '@/shared/config';

import { deleteStudy } from './studySlice.thunks';

export const studyAdapter = createEntityAdapter<Study>({
  selectId: (study) => study.ID,
  sortComparer: (a, b) => b.Revision.UpdatedAt - a.Revision.UpdatedAt,
});

type StudySliceInitialState = DefaultInitialState & {
  isDeleteStudyPending: boolean;
};

const initialStateFlags = {
  isDeleteStudyPending: false,
};

// TODO: Need to discuss this with the team
type StateFlags = keyof typeof initialStateFlags;

const initialState: StudySliceInitialState = {
  loading: 'idle',
  ...initialStateFlags,
};

const studySlice = createSlice({
  name: 'study',
  initialState: studyAdapter.getInitialState(initialState),
  reducers: {
    addOne: studyAdapter.addOne,
    addMany: studyAdapter.addMany,
    setMany: studyAdapter.setMany,
    setNewestOne: (state, action: PayloadAction<Study>) => {
      const currentRevisionNumber =
        state.entities[action.payload.ID]?.Revision?.Number ?? 0;
      const payloadRevisionNumber = action.payload.Revision.Number;

      if (payloadRevisionNumber > currentRevisionNumber) {
        studyAdapter.setOne(state, action.payload);
      }
    },
    removeOne: studyAdapter.removeOne,
    setLoading: (state, action: PayloadAction<SliceLoading>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    const asyncRequestCreator = <A, B, C>(
      thunkAction: AsyncThunk<A, B, C>,
      pendingFlagKey: StateFlags,
      fullfiledCallback?: (payload: unknown) => void,
    ) => {
      builder.addCase(thunkAction.pending, (state) => {
        state[pendingFlagKey] = true;
      });
      builder.addCase(thunkAction.fulfilled, (state, { payload }) => {
        state[pendingFlagKey] = false;
        if (typeof fullfiledCallback === 'function') {
          fullfiledCallback(payload);
        }
      });
      builder.addCase(thunkAction.rejected, (state) => {
        state[pendingFlagKey] = false;
      });
    };

    asyncRequestCreator(deleteStudy, 'isDeleteStudyPending');
  },
});

export const { actions } = studySlice;

export default studySlice.reducer;
