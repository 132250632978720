import React, { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Navigation.module.scss';

type NavigationProps = {
  links: { path: string; name: string }[];
  className?: string;
};

export const Navigation: FC<NavigationProps> = (props) => {
  const { className, links } = props;
  return (
    <nav className={cn(styles.container, className)}>
      {links.map(({ path, name }) => (
        <Link key={name} className={cn(styles.link, 'p1')} to={path}>
          {name}
        </Link>
      ))}
    </nav>
  );
};
