// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TestPage-module__cont--9krR1dqV{width:700px;margin-bottom:24px}.TestPage-module__testEntity--g29jMfhR{padding:20px;border:1px solid orange;overflow-wrap:break-word}.TestPage-module__menu--uy6HEfrR{display:flex;margin-bottom:8px}.TestPage-module__menu--uy6HEfrR h3{display:block;padding:0 8px;cursor:pointer}.TestPage-module__activeTab--6LMpAdh0{background-color:var(--purpleflat)}.TestPage-module__downloadButtons--LWC9seEi{display:flex;gap:24px;margin-top:24px}", "",{"version":3,"sources":["webpack://./src/pages/TestPage/TestPage.module.scss"],"names":[],"mappings":"AAAA,iCACE,WAAA,CACA,kBAAA,CAGF,uCACE,YAAA,CACA,uBAAA,CACA,wBAAA,CAGF,iCACE,YAAA,CACA,iBAAA,CAEA,oCACE,aAAA,CACA,aAAA,CACA,cAAA,CAIJ,sCACE,kCAAA,CAGF,4CACE,YAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".cont {\n  width: 700px;\n  margin-bottom: 24px;\n}\n\n.testEntity {\n  padding: 20px;\n  border: 1px solid orange;\n  overflow-wrap: break-word;\n}\n\n.menu {\n  display: flex;\n  margin-bottom: 8px;\n\n  h3 {\n    display: block;\n    padding: 0 8px;\n    cursor: pointer;\n  }\n}\n\n.activeTab {\n  background-color: var(--purpleflat);\n}\n\n.downloadButtons {\n  display: flex;\n  gap: 24px;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cont": "TestPage-module__cont--9krR1dqV",
	"testEntity": "TestPage-module__testEntity--g29jMfhR",
	"menu": "TestPage-module__menu--uy6HEfrR",
	"activeTab": "TestPage-module__activeTab--6LMpAdh0",
	"downloadButtons": "TestPage-module__downloadButtons--LWC9seEi"
};
export default ___CSS_LOADER_EXPORT___;
