import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import styles from './WidgetLayout.module.scss';

export type WidgetLayoutProps = {
  className?: string;
  children?: ReactNode;
};

export const WidgetLayout: FC<WidgetLayoutProps> = (props) => {
  const { className, children } = props;
  return <div className={cn(styles.wrapper, className)}>{children}</div>;
};
