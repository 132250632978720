// import { generatePath } from 'react-router-dom';

export const PATHS = {
  main: '/',
  patients: '/patients',
  patientProfile: '/patients/:patientID',
  organizations: '/organizations',
  settings: '/settings',
  report: '/patients/:patientID/reports/:reportID',
  gpReport: '/patients/:patientID/reports/gp/:reportID',
  endodonticReport: '/patients/:patientID/reports/endodontic/:reportID',
  implantReport: '/patients/:patientID/reports/implant/:reportID',
  molarReport: '/patients/:patientID/reports/molar/:reportID',
  segmentationReport: '/patients/:patientID/reports/segmentation/:reportID',
  previewReport: '/patients/:patientID/reports/:reportID/preview',
  STLViewer: '/patients/:patientID/:STLViewer',
  implantStudio: '/patients/:patientID/implantStudio',
  tooth: '/patients/:patientID/report/:reportID/tooth/:toothID',
  error: '*',
  login: '/login',
  resetPassword: '/reset-password',
  disclaimer: '/disclaimer',
  signUp: '/sign-up',
  signIn: '/sign-in',
  confirmEmail: '/confirm-email',
  confirmSharing: '/confirm-sharing',

  // TODO: remove before deploy
  test: '/test',
};
