import React from 'react';

import { Annotation } from './Annotation';

export type ArrowProps = Omit<Extract<Annotation, { kind: 'arrow' }>, 'kind'>;

const strokeColor = 'lime';

export const Arrow = ({ x1, y1, x2, y2 }: ArrowProps) => {
  return (
    <>
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={strokeColor}
        strokeWidth="2"
        strokeOpacity="0.4"
        markerEnd="url(#arrowhead)"
      />
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="4"
          refY="2"
          orient="auto"
          fill={strokeColor}
        >
          <polygon points="0 0, 4 2, 0 4" />
        </marker>
      </defs>
    </>
  );
};
