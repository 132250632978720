import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { PATHS } from '@/shared/config';

export const getReportPathType = (type: ReportType) => {
  switch (type) {
    case ReportType.ReportType_CBCT_Endo:
      return PATHS.endodonticReport;
    case ReportType.ReportType_CBCT_GP:
      return PATHS.gpReport;
    case ReportType.ReportType_CBCT_Implant:
      return PATHS.implantReport;
    case ReportType.ReportType_CBCT_Molar:
      return PATHS.molarReport;
    case ReportType.ReportType_CBCT_Segmentation:
      return PATHS.segmentationReport;
    default:
      return PATHS.report;
  }
};
