// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WidgetLayout-module__wrapper--TVnOfV4\\+{width:100%;display:flex;gap:24px;flex-wrap:wrap}", "",{"version":3,"sources":["webpack://./src/shared/ui/WidgetLayout/WidgetLayout.module.scss"],"names":[],"mappings":"AAAA,yCACE,UAAA,CACA,YAAA,CACA,QAAA,CACA,cAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  display: flex;\n  gap: 24px;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "WidgetLayout-module__wrapper--TVnOfV4+"
};
export default ___CSS_LOADER_EXPORT___;
