import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SliceName } from '@/shared/config';

export enum ModalID {
  AddCondition = 'AddCondition',
  Confirm = 'Confirm',
  ZoomedSlice = 'ZoomedSlice',
}

type ConfirmModalData = {
  title: string;
  description?: string;
  okText?: string;
  cancelText?: string;
};

type ZoomedSliceModalData = {
  src: string;
};

type AddConditionModalData = {
  toothID: string;
};

type OpenModalPayload = {
  modalID: ModalID;
  data: ConfirmModalData | AddConditionModalData | ZoomedSliceModalData;
};

// type InitialState = Record<
//   ModalID,
//   {
//     visible: boolean;
//     data: ConfirmModalData | AddConditionModalData;
//   }
// >;

type InitialState = {
  [ModalID.AddCondition]: {
    visible: boolean;
    data: AddConditionModalData;
  };
  [ModalID.Confirm]: {
    visible: boolean;
    data: ConfirmModalData;
  };
  [ModalID.ZoomedSlice]: {
    visible: boolean;
    data: ZoomedSliceModalData;
  };
};

const initialState: InitialState = {
  [ModalID.AddCondition]: {
    visible: false,
    data: {
      toothID: '',
    },
  },
  [ModalID.Confirm]: {
    visible: false,
    data: {
      title: '',
      description: '',
      okText: '',
      cancelText: '',
    },
  },
  [ModalID.ZoomedSlice]: {
    visible: false,
    data: {
      title: '',
    },
  },
};

const modalSlice = createSlice({
  name: SliceName.modal,
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<OpenModalPayload>) => {
      state[action.payload.modalID].visible = true;
      state[action.payload.modalID].data = action.payload.data;
    },
    closeModal: (state, action: PayloadAction<ModalID>) => {
      state[action.payload] = initialState[action.payload];
    },
    openConfirmModal: (state, action: PayloadAction<ConfirmModalData>) => {
      state[ModalID.Confirm].visible = true;
      state[ModalID.Confirm].data = action.payload;
    },
    openZoomedSliceModal: (
      state,
      action: PayloadAction<ZoomedSliceModalData>,
    ) => {
      state[ModalID.ZoomedSlice].visible = true;
      state[ModalID.ZoomedSlice].data = action.payload;
    },
  },
});

export const { actions } = modalSlice;

export default modalSlice.reducer;
