/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { ReportIOXRayGP } from '../model/dto_report_type_ioxray_gp';
import {
  ReportPanoGP,
  ReportCBCTEndo,
  ReportCBCTImplant,
  ReportCBCTMolar,
  ReportCBCTOrtho,
  ReportDentalPhotoGP,
} from '../model/dto_report_type_specific';
import { ReportCBCTGP } from '../model/dto_report_type_cbct_gp';
import { ReportCBCTSegmentation } from '../model/dto_report_type_cbct_segmentation';
import { ReportCBCTIOSSuperimposition } from '../model/dto_report_type_cbct_ios_superimposition';
import { ReportPermissions } from '../model/dto_report_permissions';
import { Created, Revision, Deleted } from '../model/dto_base';

export const protobufPackage = 'com.diagnocat.model';

export enum ReportType {
  ReportType_InvalidValue = 0,
  ReportType_CBCT_GP = 1,
  ReportType_CBCT_Segmentation = 2,
  ReportType_CBCT_OrthoSlides = 3,
  ReportType_CBCT_Implant = 4,
  ReportType_CBCT_Endo = 5,
  ReportType_CBCT_Molar = 6,
  ReportType_Pano_GP = 7,
  ReportType_IOXRay_GP = 8,
  ReportType_DentalPhoto_GP = 9,
  ReportType_IOS_Segmentation = 10,
  ReportType_CBCT_IOS_Superimposition = 11,
  ReportType_ImplantStudio = 12,
  ReportType_Photo_Ortho = 14,
  ReportType_OrthoStudio = 15,
  UNRECOGNIZED = -1,
}

export function reportTypeFromJSON(object: any): ReportType {
  switch (object) {
    case 0:
    case 'ReportType_InvalidValue':
      return ReportType.ReportType_InvalidValue;
    case 1:
    case 'ReportType_CBCT_GP':
      return ReportType.ReportType_CBCT_GP;
    case 2:
    case 'ReportType_CBCT_Segmentation':
      return ReportType.ReportType_CBCT_Segmentation;
    case 3:
    case 'ReportType_CBCT_OrthoSlides':
      return ReportType.ReportType_CBCT_OrthoSlides;
    case 4:
    case 'ReportType_CBCT_Implant':
      return ReportType.ReportType_CBCT_Implant;
    case 5:
    case 'ReportType_CBCT_Endo':
      return ReportType.ReportType_CBCT_Endo;
    case 6:
    case 'ReportType_CBCT_Molar':
      return ReportType.ReportType_CBCT_Molar;
    case 7:
    case 'ReportType_Pano_GP':
      return ReportType.ReportType_Pano_GP;
    case 8:
    case 'ReportType_IOXRay_GP':
      return ReportType.ReportType_IOXRay_GP;
    case 9:
    case 'ReportType_DentalPhoto_GP':
      return ReportType.ReportType_DentalPhoto_GP;
    case 10:
    case 'ReportType_IOS_Segmentation':
      return ReportType.ReportType_IOS_Segmentation;
    case 11:
    case 'ReportType_CBCT_IOS_Superimposition':
      return ReportType.ReportType_CBCT_IOS_Superimposition;
    case 12:
    case 'ReportType_ImplantStudio':
      return ReportType.ReportType_ImplantStudio;
    case 14:
    case 'ReportType_Photo_Ortho':
      return ReportType.ReportType_Photo_Ortho;
    case 15:
    case 'ReportType_OrthoStudio':
      return ReportType.ReportType_OrthoStudio;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ReportType.UNRECOGNIZED;
  }
}

export function reportTypeToJSON(object: ReportType): string {
  switch (object) {
    case ReportType.ReportType_InvalidValue:
      return 'ReportType_InvalidValue';
    case ReportType.ReportType_CBCT_GP:
      return 'ReportType_CBCT_GP';
    case ReportType.ReportType_CBCT_Segmentation:
      return 'ReportType_CBCT_Segmentation';
    case ReportType.ReportType_CBCT_OrthoSlides:
      return 'ReportType_CBCT_OrthoSlides';
    case ReportType.ReportType_CBCT_Implant:
      return 'ReportType_CBCT_Implant';
    case ReportType.ReportType_CBCT_Endo:
      return 'ReportType_CBCT_Endo';
    case ReportType.ReportType_CBCT_Molar:
      return 'ReportType_CBCT_Molar';
    case ReportType.ReportType_Pano_GP:
      return 'ReportType_Pano_GP';
    case ReportType.ReportType_IOXRay_GP:
      return 'ReportType_IOXRay_GP';
    case ReportType.ReportType_DentalPhoto_GP:
      return 'ReportType_DentalPhoto_GP';
    case ReportType.ReportType_IOS_Segmentation:
      return 'ReportType_IOS_Segmentation';
    case ReportType.ReportType_CBCT_IOS_Superimposition:
      return 'ReportType_CBCT_IOS_Superimposition';
    case ReportType.ReportType_ImplantStudio:
      return 'ReportType_ImplantStudio';
    case ReportType.ReportType_Photo_Ortho:
      return 'ReportType_Photo_Ortho';
    case ReportType.ReportType_OrthoStudio:
      return 'ReportType_OrthoStudio';
    case ReportType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum ReportPrintSettingsDescriptionLang {
  ReportPrintSettings_DescriptionLang_Clinical = 0,
  ReportPrintSettings_DescriptionLang_Radiological = 1,
  UNRECOGNIZED = -1,
}

export function reportPrintSettingsDescriptionLangFromJSON(
  object: any,
): ReportPrintSettingsDescriptionLang {
  switch (object) {
    case 0:
    case 'ReportPrintSettings_DescriptionLang_Clinical':
      return ReportPrintSettingsDescriptionLang.ReportPrintSettings_DescriptionLang_Clinical;
    case 1:
    case 'ReportPrintSettings_DescriptionLang_Radiological':
      return ReportPrintSettingsDescriptionLang.ReportPrintSettings_DescriptionLang_Radiological;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ReportPrintSettingsDescriptionLang.UNRECOGNIZED;
  }
}

export function reportPrintSettingsDescriptionLangToJSON(
  object: ReportPrintSettingsDescriptionLang,
): string {
  switch (object) {
    case ReportPrintSettingsDescriptionLang.ReportPrintSettings_DescriptionLang_Clinical:
      return 'ReportPrintSettings_DescriptionLang_Clinical';
    case ReportPrintSettingsDescriptionLang.ReportPrintSettings_DescriptionLang_Radiological:
      return 'ReportPrintSettings_DescriptionLang_Radiological';
    case ReportPrintSettingsDescriptionLang.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum ReportPrintSettingsPrintType {
  ReportPrintSettings_PrintType_Full = 0,
  ReportPrintSettings_PrintType_Motivation = 1,
  UNRECOGNIZED = -1,
}

export function reportPrintSettingsPrintTypeFromJSON(
  object: any,
): ReportPrintSettingsPrintType {
  switch (object) {
    case 0:
    case 'ReportPrintSettings_PrintType_Full':
      return ReportPrintSettingsPrintType.ReportPrintSettings_PrintType_Full;
    case 1:
    case 'ReportPrintSettings_PrintType_Motivation':
      return ReportPrintSettingsPrintType.ReportPrintSettings_PrintType_Motivation;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ReportPrintSettingsPrintType.UNRECOGNIZED;
  }
}

export function reportPrintSettingsPrintTypeToJSON(
  object: ReportPrintSettingsPrintType,
): string {
  switch (object) {
    case ReportPrintSettingsPrintType.ReportPrintSettings_PrintType_Full:
      return 'ReportPrintSettings_PrintType_Full';
    case ReportPrintSettingsPrintType.ReportPrintSettings_PrintType_Motivation:
      return 'ReportPrintSettings_PrintType_Motivation';
    case ReportPrintSettingsPrintType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Report {
  ID: string;
  OrganizationID: string;
  PatientID: string;
  SourceStudyIDs: string[];
  /** if copy */
  SourceReportID: string;
  Type: ReportType;
  LegacyConclusion: string;
  DataIOXRayGP: ReportIOXRayGP | undefined;
  DataPanoGP: ReportPanoGP | undefined;
  DataCBCTGP: ReportCBCTGP | undefined;
  DataCBCTEndo: ReportCBCTEndo | undefined;
  DataCBCTImplant: ReportCBCTImplant | undefined;
  DataCBCTMolar: ReportCBCTMolar | undefined;
  DataCBCTOrtho: ReportCBCTOrtho | undefined;
  DataCBCTSegmentation: ReportCBCTSegmentation | undefined;
  DataDentalPhoto: ReportDentalPhotoGP | undefined;
  DataCBCTIOSSuperimposition: ReportCBCTIOSSuperimposition | undefined;
  Settings: ReportSettings | undefined;
  PrintSettings: ReportPrintSettings | undefined;
  Signature: ReportSignature | undefined;
  /** completed at - ts */
  Completed: number | undefined;
  InProgress: ReportProgress | undefined;
  YourPermissions: ReportPermissions | undefined;
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
}

export interface ReportProgress {
  AllTeeth: number | undefined;
  CompletedTeeth: CompletedTeeth | undefined;
  AllConditions: number | undefined;
  CompletedForTeeth: CompletedTeeth | undefined;
}

export interface CompletedTeeth {
  Teeth: CompletedTeeth_CompletedTooth[];
}

export interface CompletedTeeth_CompletedTooth {
  ToothID: string;
  CompletedAt: number;
}

export interface ReportSignature {
  Signed: boolean;
  SignedAt: number;
  OrganizationLogoAssetID: string;
  OrganizationStampAssetID: string;
  UserSignatures: ReportUserSignature[];
}

export interface ReportUserSignature {
  UserID: string;
  SignedAt: number;
  SignatureAssetID: string;
  StampAssetID: string;
}

export interface ReportSettings {
  WatchfulnessModeOn: boolean;
  ShowProblemAreas: boolean;
  ShowDiagnosisDetails: boolean;
  ShowPanoramaSplitInsteadOfSingle: boolean;
}

export interface ReportPrintSettings {
  DescriptionLang: ReportPrintSettingsDescriptionLang;
  PrintType: ReportPrintSettingsPrintType;
  /** default == true */
  ShowDiagnoses: boolean;
  /** default == true */
  ShowDiagnosesProbabilities: boolean;
  /** default == true */
  ShowDentalChart: boolean;
  /** default == true */
  ShowSlices: boolean;
  /** default == true */
  ShowTopJaw: boolean;
  /** default == true */
  ShowBottomJaw: boolean;
  ShowBBoxes: boolean;
  ShowReferralRecommendations: boolean;
  ShowOrthodonticScreening: boolean;
  ShowStudyMetadata: boolean;
  BlackAndWhiteMode: boolean;
  IsInverted: boolean;
}

function createBaseReport(): Report {
  return {
    ID: '',
    OrganizationID: '',
    PatientID: '',
    SourceStudyIDs: [],
    SourceReportID: '',
    Type: 0,
    LegacyConclusion: '',
    DataIOXRayGP: undefined,
    DataPanoGP: undefined,
    DataCBCTGP: undefined,
    DataCBCTEndo: undefined,
    DataCBCTImplant: undefined,
    DataCBCTMolar: undefined,
    DataCBCTOrtho: undefined,
    DataCBCTSegmentation: undefined,
    DataDentalPhoto: undefined,
    DataCBCTIOSSuperimposition: undefined,
    Settings: undefined,
    PrintSettings: undefined,
    Signature: undefined,
    Completed: undefined,
    InProgress: undefined,
    YourPermissions: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const Report = {
  encode(
    message: Report,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(18).string(message.OrganizationID);
    }
    if (message.PatientID !== '') {
      writer.uint32(26).string(message.PatientID);
    }
    for (const v of message.SourceStudyIDs) {
      writer.uint32(34).string(v!);
    }
    if (message.SourceReportID !== '') {
      writer.uint32(42).string(message.SourceReportID);
    }
    if (message.Type !== 0) {
      writer.uint32(48).int32(message.Type);
    }
    if (message.LegacyConclusion !== '') {
      writer.uint32(58).string(message.LegacyConclusion);
    }
    if (message.DataIOXRayGP !== undefined) {
      ReportIOXRayGP.encode(
        message.DataIOXRayGP,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.DataPanoGP !== undefined) {
      ReportPanoGP.encode(
        message.DataPanoGP,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.DataCBCTGP !== undefined) {
      ReportCBCTGP.encode(
        message.DataCBCTGP,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    if (message.DataCBCTEndo !== undefined) {
      ReportCBCTEndo.encode(
        message.DataCBCTEndo,
        writer.uint32(834).fork(),
      ).ldelim();
    }
    if (message.DataCBCTImplant !== undefined) {
      ReportCBCTImplant.encode(
        message.DataCBCTImplant,
        writer.uint32(842).fork(),
      ).ldelim();
    }
    if (message.DataCBCTMolar !== undefined) {
      ReportCBCTMolar.encode(
        message.DataCBCTMolar,
        writer.uint32(850).fork(),
      ).ldelim();
    }
    if (message.DataCBCTOrtho !== undefined) {
      ReportCBCTOrtho.encode(
        message.DataCBCTOrtho,
        writer.uint32(858).fork(),
      ).ldelim();
    }
    if (message.DataCBCTSegmentation !== undefined) {
      ReportCBCTSegmentation.encode(
        message.DataCBCTSegmentation,
        writer.uint32(866).fork(),
      ).ldelim();
    }
    if (message.DataDentalPhoto !== undefined) {
      ReportDentalPhotoGP.encode(
        message.DataDentalPhoto,
        writer.uint32(874).fork(),
      ).ldelim();
    }
    if (message.DataCBCTIOSSuperimposition !== undefined) {
      ReportCBCTIOSSuperimposition.encode(
        message.DataCBCTIOSSuperimposition,
        writer.uint32(882).fork(),
      ).ldelim();
    }
    if (message.Settings !== undefined) {
      ReportSettings.encode(
        message.Settings,
        writer.uint32(1610).fork(),
      ).ldelim();
    }
    if (message.PrintSettings !== undefined) {
      ReportPrintSettings.encode(
        message.PrintSettings,
        writer.uint32(1618).fork(),
      ).ldelim();
    }
    if (message.Signature !== undefined) {
      ReportSignature.encode(
        message.Signature,
        writer.uint32(1626).fork(),
      ).ldelim();
    }
    if (message.Completed !== undefined) {
      writer.uint32(2408).int64(message.Completed);
    }
    if (message.InProgress !== undefined) {
      ReportProgress.encode(
        message.InProgress,
        writer.uint32(2418).fork(),
      ).ldelim();
    }
    if (message.YourPermissions !== undefined) {
      ReportPermissions.encode(
        message.YourPermissions,
        writer.uint32(80162).fork(),
      ).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Report {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.OrganizationID = reader.string();
          break;
        case 3:
          message.PatientID = reader.string();
          break;
        case 4:
          message.SourceStudyIDs.push(reader.string());
          break;
        case 5:
          message.SourceReportID = reader.string();
          break;
        case 6:
          message.Type = reader.int32() as any;
          break;
        case 7:
          message.LegacyConclusion = reader.string();
          break;
        case 101:
          message.DataIOXRayGP = ReportIOXRayGP.decode(reader, reader.uint32());
          break;
        case 102:
          message.DataPanoGP = ReportPanoGP.decode(reader, reader.uint32());
          break;
        case 103:
          message.DataCBCTGP = ReportCBCTGP.decode(reader, reader.uint32());
          break;
        case 104:
          message.DataCBCTEndo = ReportCBCTEndo.decode(reader, reader.uint32());
          break;
        case 105:
          message.DataCBCTImplant = ReportCBCTImplant.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 106:
          message.DataCBCTMolar = ReportCBCTMolar.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 107:
          message.DataCBCTOrtho = ReportCBCTOrtho.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 108:
          message.DataCBCTSegmentation = ReportCBCTSegmentation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 109:
          message.DataDentalPhoto = ReportDentalPhotoGP.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 110:
          message.DataCBCTIOSSuperimposition =
            ReportCBCTIOSSuperimposition.decode(reader, reader.uint32());
          break;
        case 201:
          message.Settings = ReportSettings.decode(reader, reader.uint32());
          break;
        case 202:
          message.PrintSettings = ReportPrintSettings.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 203:
          message.Signature = ReportSignature.decode(reader, reader.uint32());
          break;
        case 301:
          message.Completed = longToNumber(reader.int64() as Long);
          break;
        case 302:
          message.InProgress = ReportProgress.decode(reader, reader.uint32());
          break;
        case 10020:
          message.YourPermissions = ReportPermissions.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Report {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      SourceStudyIDs: Array.isArray(object?.SourceStudyIDs)
        ? object.SourceStudyIDs.map((e: any) => String(e))
        : [],
      SourceReportID: isSet(object.SourceReportID)
        ? String(object.SourceReportID)
        : '',
      Type: isSet(object.Type) ? reportTypeFromJSON(object.Type) : 0,
      LegacyConclusion: isSet(object.LegacyConclusion)
        ? String(object.LegacyConclusion)
        : '',
      DataIOXRayGP: isSet(object.DataIOXRayGP)
        ? ReportIOXRayGP.fromJSON(object.DataIOXRayGP)
        : undefined,
      DataPanoGP: isSet(object.DataPanoGP)
        ? ReportPanoGP.fromJSON(object.DataPanoGP)
        : undefined,
      DataCBCTGP: isSet(object.DataCBCTGP)
        ? ReportCBCTGP.fromJSON(object.DataCBCTGP)
        : undefined,
      DataCBCTEndo: isSet(object.DataCBCTEndo)
        ? ReportCBCTEndo.fromJSON(object.DataCBCTEndo)
        : undefined,
      DataCBCTImplant: isSet(object.DataCBCTImplant)
        ? ReportCBCTImplant.fromJSON(object.DataCBCTImplant)
        : undefined,
      DataCBCTMolar: isSet(object.DataCBCTMolar)
        ? ReportCBCTMolar.fromJSON(object.DataCBCTMolar)
        : undefined,
      DataCBCTOrtho: isSet(object.DataCBCTOrtho)
        ? ReportCBCTOrtho.fromJSON(object.DataCBCTOrtho)
        : undefined,
      DataCBCTSegmentation: isSet(object.DataCBCTSegmentation)
        ? ReportCBCTSegmentation.fromJSON(object.DataCBCTSegmentation)
        : undefined,
      DataDentalPhoto: isSet(object.DataDentalPhoto)
        ? ReportDentalPhotoGP.fromJSON(object.DataDentalPhoto)
        : undefined,
      DataCBCTIOSSuperimposition: isSet(object.DataCBCTIOSSuperimposition)
        ? ReportCBCTIOSSuperimposition.fromJSON(
            object.DataCBCTIOSSuperimposition,
          )
        : undefined,
      Settings: isSet(object.Settings)
        ? ReportSettings.fromJSON(object.Settings)
        : undefined,
      PrintSettings: isSet(object.PrintSettings)
        ? ReportPrintSettings.fromJSON(object.PrintSettings)
        : undefined,
      Signature: isSet(object.Signature)
        ? ReportSignature.fromJSON(object.Signature)
        : undefined,
      Completed: isSet(object.Completed) ? Number(object.Completed) : undefined,
      InProgress: isSet(object.InProgress)
        ? ReportProgress.fromJSON(object.InProgress)
        : undefined,
      YourPermissions: isSet(object.YourPermissions)
        ? ReportPermissions.fromJSON(object.YourPermissions)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: Report): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    if (message.SourceStudyIDs) {
      obj.SourceStudyIDs = message.SourceStudyIDs.map((e) => e);
    } else {
      obj.SourceStudyIDs = [];
    }
    message.SourceReportID !== undefined &&
      (obj.SourceReportID = message.SourceReportID);
    message.Type !== undefined && (obj.Type = reportTypeToJSON(message.Type));
    message.LegacyConclusion !== undefined &&
      (obj.LegacyConclusion = message.LegacyConclusion);
    message.DataIOXRayGP !== undefined &&
      (obj.DataIOXRayGP = message.DataIOXRayGP
        ? ReportIOXRayGP.toJSON(message.DataIOXRayGP)
        : undefined);
    message.DataPanoGP !== undefined &&
      (obj.DataPanoGP = message.DataPanoGP
        ? ReportPanoGP.toJSON(message.DataPanoGP)
        : undefined);
    message.DataCBCTGP !== undefined &&
      (obj.DataCBCTGP = message.DataCBCTGP
        ? ReportCBCTGP.toJSON(message.DataCBCTGP)
        : undefined);
    message.DataCBCTEndo !== undefined &&
      (obj.DataCBCTEndo = message.DataCBCTEndo
        ? ReportCBCTEndo.toJSON(message.DataCBCTEndo)
        : undefined);
    message.DataCBCTImplant !== undefined &&
      (obj.DataCBCTImplant = message.DataCBCTImplant
        ? ReportCBCTImplant.toJSON(message.DataCBCTImplant)
        : undefined);
    message.DataCBCTMolar !== undefined &&
      (obj.DataCBCTMolar = message.DataCBCTMolar
        ? ReportCBCTMolar.toJSON(message.DataCBCTMolar)
        : undefined);
    message.DataCBCTOrtho !== undefined &&
      (obj.DataCBCTOrtho = message.DataCBCTOrtho
        ? ReportCBCTOrtho.toJSON(message.DataCBCTOrtho)
        : undefined);
    message.DataCBCTSegmentation !== undefined &&
      (obj.DataCBCTSegmentation = message.DataCBCTSegmentation
        ? ReportCBCTSegmentation.toJSON(message.DataCBCTSegmentation)
        : undefined);
    message.DataDentalPhoto !== undefined &&
      (obj.DataDentalPhoto = message.DataDentalPhoto
        ? ReportDentalPhotoGP.toJSON(message.DataDentalPhoto)
        : undefined);
    message.DataCBCTIOSSuperimposition !== undefined &&
      (obj.DataCBCTIOSSuperimposition = message.DataCBCTIOSSuperimposition
        ? ReportCBCTIOSSuperimposition.toJSON(
            message.DataCBCTIOSSuperimposition,
          )
        : undefined);
    message.Settings !== undefined &&
      (obj.Settings = message.Settings
        ? ReportSettings.toJSON(message.Settings)
        : undefined);
    message.PrintSettings !== undefined &&
      (obj.PrintSettings = message.PrintSettings
        ? ReportPrintSettings.toJSON(message.PrintSettings)
        : undefined);
    message.Signature !== undefined &&
      (obj.Signature = message.Signature
        ? ReportSignature.toJSON(message.Signature)
        : undefined);
    message.Completed !== undefined &&
      (obj.Completed = Math.round(message.Completed));
    message.InProgress !== undefined &&
      (obj.InProgress = message.InProgress
        ? ReportProgress.toJSON(message.InProgress)
        : undefined);
    message.YourPermissions !== undefined &&
      (obj.YourPermissions = message.YourPermissions
        ? ReportPermissions.toJSON(message.YourPermissions)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Report>, I>>(object: I): Report {
    const message = createBaseReport();
    message.ID = object.ID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.SourceStudyIDs = object.SourceStudyIDs?.map((e) => e) || [];
    message.SourceReportID = object.SourceReportID ?? '';
    message.Type = object.Type ?? 0;
    message.LegacyConclusion = object.LegacyConclusion ?? '';
    message.DataIOXRayGP =
      object.DataIOXRayGP !== undefined && object.DataIOXRayGP !== null
        ? ReportIOXRayGP.fromPartial(object.DataIOXRayGP)
        : undefined;
    message.DataPanoGP =
      object.DataPanoGP !== undefined && object.DataPanoGP !== null
        ? ReportPanoGP.fromPartial(object.DataPanoGP)
        : undefined;
    message.DataCBCTGP =
      object.DataCBCTGP !== undefined && object.DataCBCTGP !== null
        ? ReportCBCTGP.fromPartial(object.DataCBCTGP)
        : undefined;
    message.DataCBCTEndo =
      object.DataCBCTEndo !== undefined && object.DataCBCTEndo !== null
        ? ReportCBCTEndo.fromPartial(object.DataCBCTEndo)
        : undefined;
    message.DataCBCTImplant =
      object.DataCBCTImplant !== undefined && object.DataCBCTImplant !== null
        ? ReportCBCTImplant.fromPartial(object.DataCBCTImplant)
        : undefined;
    message.DataCBCTMolar =
      object.DataCBCTMolar !== undefined && object.DataCBCTMolar !== null
        ? ReportCBCTMolar.fromPartial(object.DataCBCTMolar)
        : undefined;
    message.DataCBCTOrtho =
      object.DataCBCTOrtho !== undefined && object.DataCBCTOrtho !== null
        ? ReportCBCTOrtho.fromPartial(object.DataCBCTOrtho)
        : undefined;
    message.DataCBCTSegmentation =
      object.DataCBCTSegmentation !== undefined &&
      object.DataCBCTSegmentation !== null
        ? ReportCBCTSegmentation.fromPartial(object.DataCBCTSegmentation)
        : undefined;
    message.DataDentalPhoto =
      object.DataDentalPhoto !== undefined && object.DataDentalPhoto !== null
        ? ReportDentalPhotoGP.fromPartial(object.DataDentalPhoto)
        : undefined;
    message.DataCBCTIOSSuperimposition =
      object.DataCBCTIOSSuperimposition !== undefined &&
      object.DataCBCTIOSSuperimposition !== null
        ? ReportCBCTIOSSuperimposition.fromPartial(
            object.DataCBCTIOSSuperimposition,
          )
        : undefined;
    message.Settings =
      object.Settings !== undefined && object.Settings !== null
        ? ReportSettings.fromPartial(object.Settings)
        : undefined;
    message.PrintSettings =
      object.PrintSettings !== undefined && object.PrintSettings !== null
        ? ReportPrintSettings.fromPartial(object.PrintSettings)
        : undefined;
    message.Signature =
      object.Signature !== undefined && object.Signature !== null
        ? ReportSignature.fromPartial(object.Signature)
        : undefined;
    message.Completed = object.Completed ?? undefined;
    message.InProgress =
      object.InProgress !== undefined && object.InProgress !== null
        ? ReportProgress.fromPartial(object.InProgress)
        : undefined;
    message.YourPermissions =
      object.YourPermissions !== undefined && object.YourPermissions !== null
        ? ReportPermissions.fromPartial(object.YourPermissions)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

function createBaseReportProgress(): ReportProgress {
  return {
    AllTeeth: undefined,
    CompletedTeeth: undefined,
    AllConditions: undefined,
    CompletedForTeeth: undefined,
  };
}

export const ReportProgress = {
  encode(
    message: ReportProgress,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AllTeeth !== undefined) {
      writer.uint32(8).int64(message.AllTeeth);
    }
    if (message.CompletedTeeth !== undefined) {
      CompletedTeeth.encode(
        message.CompletedTeeth,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.AllConditions !== undefined) {
      writer.uint32(88).int64(message.AllConditions);
    }
    if (message.CompletedForTeeth !== undefined) {
      CompletedTeeth.encode(
        message.CompletedForTeeth,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportProgress {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportProgress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AllTeeth = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.CompletedTeeth = CompletedTeeth.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 11:
          message.AllConditions = longToNumber(reader.int64() as Long);
          break;
        case 12:
          message.CompletedForTeeth = CompletedTeeth.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportProgress {
    return {
      AllTeeth: isSet(object.AllTeeth) ? Number(object.AllTeeth) : undefined,
      CompletedTeeth: isSet(object.CompletedTeeth)
        ? CompletedTeeth.fromJSON(object.CompletedTeeth)
        : undefined,
      AllConditions: isSet(object.AllConditions)
        ? Number(object.AllConditions)
        : undefined,
      CompletedForTeeth: isSet(object.CompletedForTeeth)
        ? CompletedTeeth.fromJSON(object.CompletedForTeeth)
        : undefined,
    };
  },

  toJSON(message: ReportProgress): unknown {
    const obj: any = {};
    message.AllTeeth !== undefined &&
      (obj.AllTeeth = Math.round(message.AllTeeth));
    message.CompletedTeeth !== undefined &&
      (obj.CompletedTeeth = message.CompletedTeeth
        ? CompletedTeeth.toJSON(message.CompletedTeeth)
        : undefined);
    message.AllConditions !== undefined &&
      (obj.AllConditions = Math.round(message.AllConditions));
    message.CompletedForTeeth !== undefined &&
      (obj.CompletedForTeeth = message.CompletedForTeeth
        ? CompletedTeeth.toJSON(message.CompletedForTeeth)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportProgress>, I>>(
    object: I,
  ): ReportProgress {
    const message = createBaseReportProgress();
    message.AllTeeth = object.AllTeeth ?? undefined;
    message.CompletedTeeth =
      object.CompletedTeeth !== undefined && object.CompletedTeeth !== null
        ? CompletedTeeth.fromPartial(object.CompletedTeeth)
        : undefined;
    message.AllConditions = object.AllConditions ?? undefined;
    message.CompletedForTeeth =
      object.CompletedForTeeth !== undefined &&
      object.CompletedForTeeth !== null
        ? CompletedTeeth.fromPartial(object.CompletedForTeeth)
        : undefined;
    return message;
  },
};

function createBaseCompletedTeeth(): CompletedTeeth {
  return { Teeth: [] };
}

export const CompletedTeeth = {
  encode(
    message: CompletedTeeth,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Teeth) {
      CompletedTeeth_CompletedTooth.encode(
        v!,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompletedTeeth {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompletedTeeth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Teeth.push(
            CompletedTeeth_CompletedTooth.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CompletedTeeth {
    return {
      Teeth: Array.isArray(object?.Teeth)
        ? object.Teeth.map((e: any) =>
            CompletedTeeth_CompletedTooth.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: CompletedTeeth): unknown {
    const obj: any = {};
    if (message.Teeth) {
      obj.Teeth = message.Teeth.map((e) =>
        e ? CompletedTeeth_CompletedTooth.toJSON(e) : undefined,
      );
    } else {
      obj.Teeth = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CompletedTeeth>, I>>(
    object: I,
  ): CompletedTeeth {
    const message = createBaseCompletedTeeth();
    message.Teeth =
      object.Teeth?.map((e) => CompletedTeeth_CompletedTooth.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseCompletedTeeth_CompletedTooth(): CompletedTeeth_CompletedTooth {
  return { ToothID: '', CompletedAt: 0 };
}

export const CompletedTeeth_CompletedTooth = {
  encode(
    message: CompletedTeeth_CompletedTooth,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.CompletedAt !== 0) {
      writer.uint32(16).int64(message.CompletedAt);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CompletedTeeth_CompletedTooth {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompletedTeeth_CompletedTooth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.CompletedAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CompletedTeeth_CompletedTooth {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      CompletedAt: isSet(object.CompletedAt) ? Number(object.CompletedAt) : 0,
    };
  },

  toJSON(message: CompletedTeeth_CompletedTooth): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.CompletedAt !== undefined &&
      (obj.CompletedAt = Math.round(message.CompletedAt));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CompletedTeeth_CompletedTooth>, I>>(
    object: I,
  ): CompletedTeeth_CompletedTooth {
    const message = createBaseCompletedTeeth_CompletedTooth();
    message.ToothID = object.ToothID ?? '';
    message.CompletedAt = object.CompletedAt ?? 0;
    return message;
  },
};

function createBaseReportSignature(): ReportSignature {
  return {
    Signed: false,
    SignedAt: 0,
    OrganizationLogoAssetID: '',
    OrganizationStampAssetID: '',
    UserSignatures: [],
  };
}

export const ReportSignature = {
  encode(
    message: ReportSignature,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Signed === true) {
      writer.uint32(8).bool(message.Signed);
    }
    if (message.SignedAt !== 0) {
      writer.uint32(16).int64(message.SignedAt);
    }
    if (message.OrganizationLogoAssetID !== '') {
      writer.uint32(26).string(message.OrganizationLogoAssetID);
    }
    if (message.OrganizationStampAssetID !== '') {
      writer.uint32(34).string(message.OrganizationStampAssetID);
    }
    for (const v of message.UserSignatures) {
      ReportUserSignature.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportSignature {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportSignature();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Signed = reader.bool();
          break;
        case 2:
          message.SignedAt = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.OrganizationLogoAssetID = reader.string();
          break;
        case 4:
          message.OrganizationStampAssetID = reader.string();
          break;
        case 5:
          message.UserSignatures.push(
            ReportUserSignature.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportSignature {
    return {
      Signed: isSet(object.Signed) ? Boolean(object.Signed) : false,
      SignedAt: isSet(object.SignedAt) ? Number(object.SignedAt) : 0,
      OrganizationLogoAssetID: isSet(object.OrganizationLogoAssetID)
        ? String(object.OrganizationLogoAssetID)
        : '',
      OrganizationStampAssetID: isSet(object.OrganizationStampAssetID)
        ? String(object.OrganizationStampAssetID)
        : '',
      UserSignatures: Array.isArray(object?.UserSignatures)
        ? object.UserSignatures.map((e: any) => ReportUserSignature.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ReportSignature): unknown {
    const obj: any = {};
    message.Signed !== undefined && (obj.Signed = message.Signed);
    message.SignedAt !== undefined &&
      (obj.SignedAt = Math.round(message.SignedAt));
    message.OrganizationLogoAssetID !== undefined &&
      (obj.OrganizationLogoAssetID = message.OrganizationLogoAssetID);
    message.OrganizationStampAssetID !== undefined &&
      (obj.OrganizationStampAssetID = message.OrganizationStampAssetID);
    if (message.UserSignatures) {
      obj.UserSignatures = message.UserSignatures.map((e) =>
        e ? ReportUserSignature.toJSON(e) : undefined,
      );
    } else {
      obj.UserSignatures = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportSignature>, I>>(
    object: I,
  ): ReportSignature {
    const message = createBaseReportSignature();
    message.Signed = object.Signed ?? false;
    message.SignedAt = object.SignedAt ?? 0;
    message.OrganizationLogoAssetID = object.OrganizationLogoAssetID ?? '';
    message.OrganizationStampAssetID = object.OrganizationStampAssetID ?? '';
    message.UserSignatures =
      object.UserSignatures?.map((e) => ReportUserSignature.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseReportUserSignature(): ReportUserSignature {
  return { UserID: '', SignedAt: 0, SignatureAssetID: '', StampAssetID: '' };
}

export const ReportUserSignature = {
  encode(
    message: ReportUserSignature,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.SignedAt !== 0) {
      writer.uint32(16).int64(message.SignedAt);
    }
    if (message.SignatureAssetID !== '') {
      writer.uint32(26).string(message.SignatureAssetID);
    }
    if (message.StampAssetID !== '') {
      writer.uint32(34).string(message.StampAssetID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportUserSignature {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportUserSignature();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.SignedAt = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.SignatureAssetID = reader.string();
          break;
        case 4:
          message.StampAssetID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportUserSignature {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      SignedAt: isSet(object.SignedAt) ? Number(object.SignedAt) : 0,
      SignatureAssetID: isSet(object.SignatureAssetID)
        ? String(object.SignatureAssetID)
        : '',
      StampAssetID: isSet(object.StampAssetID)
        ? String(object.StampAssetID)
        : '',
    };
  },

  toJSON(message: ReportUserSignature): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.SignedAt !== undefined &&
      (obj.SignedAt = Math.round(message.SignedAt));
    message.SignatureAssetID !== undefined &&
      (obj.SignatureAssetID = message.SignatureAssetID);
    message.StampAssetID !== undefined &&
      (obj.StampAssetID = message.StampAssetID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportUserSignature>, I>>(
    object: I,
  ): ReportUserSignature {
    const message = createBaseReportUserSignature();
    message.UserID = object.UserID ?? '';
    message.SignedAt = object.SignedAt ?? 0;
    message.SignatureAssetID = object.SignatureAssetID ?? '';
    message.StampAssetID = object.StampAssetID ?? '';
    return message;
  },
};

function createBaseReportSettings(): ReportSettings {
  return {
    WatchfulnessModeOn: false,
    ShowProblemAreas: false,
    ShowDiagnosisDetails: false,
    ShowPanoramaSplitInsteadOfSingle: false,
  };
}

export const ReportSettings = {
  encode(
    message: ReportSettings,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.WatchfulnessModeOn === true) {
      writer.uint32(8).bool(message.WatchfulnessModeOn);
    }
    if (message.ShowProblemAreas === true) {
      writer.uint32(16).bool(message.ShowProblemAreas);
    }
    if (message.ShowDiagnosisDetails === true) {
      writer.uint32(24).bool(message.ShowDiagnosisDetails);
    }
    if (message.ShowPanoramaSplitInsteadOfSingle === true) {
      writer.uint32(32).bool(message.ShowPanoramaSplitInsteadOfSingle);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportSettings {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.WatchfulnessModeOn = reader.bool();
          break;
        case 2:
          message.ShowProblemAreas = reader.bool();
          break;
        case 3:
          message.ShowDiagnosisDetails = reader.bool();
          break;
        case 4:
          message.ShowPanoramaSplitInsteadOfSingle = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportSettings {
    return {
      WatchfulnessModeOn: isSet(object.WatchfulnessModeOn)
        ? Boolean(object.WatchfulnessModeOn)
        : false,
      ShowProblemAreas: isSet(object.ShowProblemAreas)
        ? Boolean(object.ShowProblemAreas)
        : false,
      ShowDiagnosisDetails: isSet(object.ShowDiagnosisDetails)
        ? Boolean(object.ShowDiagnosisDetails)
        : false,
      ShowPanoramaSplitInsteadOfSingle: isSet(
        object.ShowPanoramaSplitInsteadOfSingle,
      )
        ? Boolean(object.ShowPanoramaSplitInsteadOfSingle)
        : false,
    };
  },

  toJSON(message: ReportSettings): unknown {
    const obj: any = {};
    message.WatchfulnessModeOn !== undefined &&
      (obj.WatchfulnessModeOn = message.WatchfulnessModeOn);
    message.ShowProblemAreas !== undefined &&
      (obj.ShowProblemAreas = message.ShowProblemAreas);
    message.ShowDiagnosisDetails !== undefined &&
      (obj.ShowDiagnosisDetails = message.ShowDiagnosisDetails);
    message.ShowPanoramaSplitInsteadOfSingle !== undefined &&
      (obj.ShowPanoramaSplitInsteadOfSingle =
        message.ShowPanoramaSplitInsteadOfSingle);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportSettings>, I>>(
    object: I,
  ): ReportSettings {
    const message = createBaseReportSettings();
    message.WatchfulnessModeOn = object.WatchfulnessModeOn ?? false;
    message.ShowProblemAreas = object.ShowProblemAreas ?? false;
    message.ShowDiagnosisDetails = object.ShowDiagnosisDetails ?? false;
    message.ShowPanoramaSplitInsteadOfSingle =
      object.ShowPanoramaSplitInsteadOfSingle ?? false;
    return message;
  },
};

function createBaseReportPrintSettings(): ReportPrintSettings {
  return {
    DescriptionLang: 0,
    PrintType: 0,
    ShowDiagnoses: false,
    ShowDiagnosesProbabilities: false,
    ShowDentalChart: false,
    ShowSlices: false,
    ShowTopJaw: false,
    ShowBottomJaw: false,
    ShowBBoxes: false,
    ShowReferralRecommendations: false,
    ShowOrthodonticScreening: false,
    ShowStudyMetadata: false,
    BlackAndWhiteMode: false,
    IsInverted: false,
  };
}

export const ReportPrintSettings = {
  encode(
    message: ReportPrintSettings,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DescriptionLang !== 0) {
      writer.uint32(8).int32(message.DescriptionLang);
    }
    if (message.PrintType !== 0) {
      writer.uint32(16).int32(message.PrintType);
    }
    if (message.ShowDiagnoses === true) {
      writer.uint32(24).bool(message.ShowDiagnoses);
    }
    if (message.ShowDiagnosesProbabilities === true) {
      writer.uint32(32).bool(message.ShowDiagnosesProbabilities);
    }
    if (message.ShowDentalChart === true) {
      writer.uint32(40).bool(message.ShowDentalChart);
    }
    if (message.ShowSlices === true) {
      writer.uint32(48).bool(message.ShowSlices);
    }
    if (message.ShowTopJaw === true) {
      writer.uint32(56).bool(message.ShowTopJaw);
    }
    if (message.ShowBottomJaw === true) {
      writer.uint32(64).bool(message.ShowBottomJaw);
    }
    if (message.ShowBBoxes === true) {
      writer.uint32(72).bool(message.ShowBBoxes);
    }
    if (message.ShowReferralRecommendations === true) {
      writer.uint32(80).bool(message.ShowReferralRecommendations);
    }
    if (message.ShowOrthodonticScreening === true) {
      writer.uint32(88).bool(message.ShowOrthodonticScreening);
    }
    if (message.ShowStudyMetadata === true) {
      writer.uint32(96).bool(message.ShowStudyMetadata);
    }
    if (message.BlackAndWhiteMode === true) {
      writer.uint32(104).bool(message.BlackAndWhiteMode);
    }
    if (message.IsInverted === true) {
      writer.uint32(112).bool(message.IsInverted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportPrintSettings {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportPrintSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DescriptionLang = reader.int32() as any;
          break;
        case 2:
          message.PrintType = reader.int32() as any;
          break;
        case 3:
          message.ShowDiagnoses = reader.bool();
          break;
        case 4:
          message.ShowDiagnosesProbabilities = reader.bool();
          break;
        case 5:
          message.ShowDentalChart = reader.bool();
          break;
        case 6:
          message.ShowSlices = reader.bool();
          break;
        case 7:
          message.ShowTopJaw = reader.bool();
          break;
        case 8:
          message.ShowBottomJaw = reader.bool();
          break;
        case 9:
          message.ShowBBoxes = reader.bool();
          break;
        case 10:
          message.ShowReferralRecommendations = reader.bool();
          break;
        case 11:
          message.ShowOrthodonticScreening = reader.bool();
          break;
        case 12:
          message.ShowStudyMetadata = reader.bool();
          break;
        case 13:
          message.BlackAndWhiteMode = reader.bool();
          break;
        case 14:
          message.IsInverted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportPrintSettings {
    return {
      DescriptionLang: isSet(object.DescriptionLang)
        ? reportPrintSettingsDescriptionLangFromJSON(object.DescriptionLang)
        : 0,
      PrintType: isSet(object.PrintType)
        ? reportPrintSettingsPrintTypeFromJSON(object.PrintType)
        : 0,
      ShowDiagnoses: isSet(object.ShowDiagnoses)
        ? Boolean(object.ShowDiagnoses)
        : false,
      ShowDiagnosesProbabilities: isSet(object.ShowDiagnosesProbabilities)
        ? Boolean(object.ShowDiagnosesProbabilities)
        : false,
      ShowDentalChart: isSet(object.ShowDentalChart)
        ? Boolean(object.ShowDentalChart)
        : false,
      ShowSlices: isSet(object.ShowSlices) ? Boolean(object.ShowSlices) : false,
      ShowTopJaw: isSet(object.ShowTopJaw) ? Boolean(object.ShowTopJaw) : false,
      ShowBottomJaw: isSet(object.ShowBottomJaw)
        ? Boolean(object.ShowBottomJaw)
        : false,
      ShowBBoxes: isSet(object.ShowBBoxes) ? Boolean(object.ShowBBoxes) : false,
      ShowReferralRecommendations: isSet(object.ShowReferralRecommendations)
        ? Boolean(object.ShowReferralRecommendations)
        : false,
      ShowOrthodonticScreening: isSet(object.ShowOrthodonticScreening)
        ? Boolean(object.ShowOrthodonticScreening)
        : false,
      ShowStudyMetadata: isSet(object.ShowStudyMetadata)
        ? Boolean(object.ShowStudyMetadata)
        : false,
      BlackAndWhiteMode: isSet(object.BlackAndWhiteMode)
        ? Boolean(object.BlackAndWhiteMode)
        : false,
      IsInverted: isSet(object.IsInverted) ? Boolean(object.IsInverted) : false,
    };
  },

  toJSON(message: ReportPrintSettings): unknown {
    const obj: any = {};
    message.DescriptionLang !== undefined &&
      (obj.DescriptionLang = reportPrintSettingsDescriptionLangToJSON(
        message.DescriptionLang,
      ));
    message.PrintType !== undefined &&
      (obj.PrintType = reportPrintSettingsPrintTypeToJSON(message.PrintType));
    message.ShowDiagnoses !== undefined &&
      (obj.ShowDiagnoses = message.ShowDiagnoses);
    message.ShowDiagnosesProbabilities !== undefined &&
      (obj.ShowDiagnosesProbabilities = message.ShowDiagnosesProbabilities);
    message.ShowDentalChart !== undefined &&
      (obj.ShowDentalChart = message.ShowDentalChart);
    message.ShowSlices !== undefined && (obj.ShowSlices = message.ShowSlices);
    message.ShowTopJaw !== undefined && (obj.ShowTopJaw = message.ShowTopJaw);
    message.ShowBottomJaw !== undefined &&
      (obj.ShowBottomJaw = message.ShowBottomJaw);
    message.ShowBBoxes !== undefined && (obj.ShowBBoxes = message.ShowBBoxes);
    message.ShowReferralRecommendations !== undefined &&
      (obj.ShowReferralRecommendations = message.ShowReferralRecommendations);
    message.ShowOrthodonticScreening !== undefined &&
      (obj.ShowOrthodonticScreening = message.ShowOrthodonticScreening);
    message.ShowStudyMetadata !== undefined &&
      (obj.ShowStudyMetadata = message.ShowStudyMetadata);
    message.BlackAndWhiteMode !== undefined &&
      (obj.BlackAndWhiteMode = message.BlackAndWhiteMode);
    message.IsInverted !== undefined && (obj.IsInverted = message.IsInverted);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportPrintSettings>, I>>(
    object: I,
  ): ReportPrintSettings {
    const message = createBaseReportPrintSettings();
    message.DescriptionLang = object.DescriptionLang ?? 0;
    message.PrintType = object.PrintType ?? 0;
    message.ShowDiagnoses = object.ShowDiagnoses ?? false;
    message.ShowDiagnosesProbabilities =
      object.ShowDiagnosesProbabilities ?? false;
    message.ShowDentalChart = object.ShowDentalChart ?? false;
    message.ShowSlices = object.ShowSlices ?? false;
    message.ShowTopJaw = object.ShowTopJaw ?? false;
    message.ShowBottomJaw = object.ShowBottomJaw ?? false;
    message.ShowBBoxes = object.ShowBBoxes ?? false;
    message.ShowReferralRecommendations =
      object.ShowReferralRecommendations ?? false;
    message.ShowOrthodonticScreening = object.ShowOrthodonticScreening ?? false;
    message.ShowStudyMetadata = object.ShowStudyMetadata ?? false;
    message.BlackAndWhiteMode = object.BlackAndWhiteMode ?? false;
    message.IsInverted = object.IsInverted ?? false;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
