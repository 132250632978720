/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export interface PatientPermissions {
  /**
   * - users with Owner or Administrative role in the organization <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanViewPatient` == false <br>
   * - users with any patient role (team member or sharing) if organizationSettings.OnlyTeamMembersCanViewPatient == true
   */
  CanView: boolean;
  /**
   * - users with Owner or Administrative role in the organization <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanViewPersonalData` == false <br>
   * - patient team members if `OrganizationSettings.OnlyTeamMembersCanViewPersonalData` == true <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanViewPersonalData` == true)
   */
  CanViewPersonalData: boolean;
  /**
   * - users with Owner or Administrative role in the organization <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanEditPatient` == false <br>
   * - only patient team members if `OrganizationSettings.OnlyTeamMembersCanEditPatient` == true
   */
  CanEditPersonalData: boolean;
  /**
   * - users with Owner or Administrative role in the organization <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanEditPatient` == false <br>
   * - only patient team members if `OrganizationSettings.OnlyTeamMembersCanEditPatient` == true
   */
  CanEditPatient: boolean;
  /** all organization employees if they can view patient */
  CanViewTeam: boolean;
  /** users with Owner or Administrative role in the organization */
  CanEditTeam: boolean;
  /** all organization employees if they can view patient */
  CanViewSharings: boolean;
  /** all organization employees if they can view patient */
  CanShare: boolean;
  /** users with Owner or Administrative role in the organization */
  CanCancelOthersSharings: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanViewProject` == true)
   */
  CanViewProject: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanEditProject` == true)
   */
  CanEditProject: boolean;
  /**
   * - users with Owner or Administrative role in the organization <br>
   * - all organization employees, if `OrganizationSettings.OnlyTeamMembersCanRequestReport` == false <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanRequestReport` == true)
   */
  CanRequestReport: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanUploadCBCT` == true)
   */
  CanUploadCBCT: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanUploadIOXRay` == true)
   */
  CanUploadIOXRay: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanUploadPanoramicXRay` == true)
   */
  CanUploadPanoramicXRay: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanUploadSTL` == true)
   */
  CanUploadSTL: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanUploadDentalPhoto` == true)
   */
  CanUploadDentalPhoto: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanUploadDocuments` == true)
   */
  CanUploadDocuments: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanViewNewReports` == true)
   */
  CanViewNewReports: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanViewNewStudies` == true)
   */
  CanViewNewStudies: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanViewHistoricalReports` == true)
   */
  CanViewHistoricalReports: boolean;
  /**
   * - all organization employees if they can view patient <br>
   * - patient team members <br>
   * - this patient was shared to current user (`PatientSharing.Permissions.CanViewHistoricalStudies` == true)
   */
  CanViewHistoricalStudies: boolean;
  /** the time invitation has been created */
  InvitationTime: number;
}

export interface PatientSharingPermissions {
  /** allow user (invitation recipient) to view patient's personal data */
  CanViewPersonalData: boolean;
  CanViewProject: boolean;
  CanEditProject: boolean;
  CanRequestReport: boolean;
  CanUploadCBCT: boolean;
  CanUploadIOXRay: boolean;
  CanUploadPanoramicXRay: boolean;
  CanUploadSTL: boolean;
  CanUploadDentalPhoto: boolean;
  CanUploadDocuments: boolean;
  CanViewNewReports: boolean;
  CanViewNewStudies: boolean;
  CanViewHistoricalReports: boolean;
  CanViewHistoricalStudies: boolean;
}

function createBasePatientPermissions(): PatientPermissions {
  return {
    CanView: false,
    CanViewPersonalData: false,
    CanEditPersonalData: false,
    CanEditPatient: false,
    CanViewTeam: false,
    CanEditTeam: false,
    CanViewSharings: false,
    CanShare: false,
    CanCancelOthersSharings: false,
    CanViewProject: false,
    CanEditProject: false,
    CanRequestReport: false,
    CanUploadCBCT: false,
    CanUploadIOXRay: false,
    CanUploadPanoramicXRay: false,
    CanUploadSTL: false,
    CanUploadDentalPhoto: false,
    CanUploadDocuments: false,
    CanViewNewReports: false,
    CanViewNewStudies: false,
    CanViewHistoricalReports: false,
    CanViewHistoricalStudies: false,
    InvitationTime: 0,
  };
}

export const PatientPermissions = {
  encode(
    message: PatientPermissions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CanView === true) {
      writer.uint32(8).bool(message.CanView);
    }
    if (message.CanViewPersonalData === true) {
      writer.uint32(16).bool(message.CanViewPersonalData);
    }
    if (message.CanEditPersonalData === true) {
      writer.uint32(24).bool(message.CanEditPersonalData);
    }
    if (message.CanEditPatient === true) {
      writer.uint32(32).bool(message.CanEditPatient);
    }
    if (message.CanViewTeam === true) {
      writer.uint32(40).bool(message.CanViewTeam);
    }
    if (message.CanEditTeam === true) {
      writer.uint32(48).bool(message.CanEditTeam);
    }
    if (message.CanViewSharings === true) {
      writer.uint32(64).bool(message.CanViewSharings);
    }
    if (message.CanShare === true) {
      writer.uint32(72).bool(message.CanShare);
    }
    if (message.CanCancelOthersSharings === true) {
      writer.uint32(80).bool(message.CanCancelOthersSharings);
    }
    if (message.CanViewProject === true) {
      writer.uint32(88).bool(message.CanViewProject);
    }
    if (message.CanEditProject === true) {
      writer.uint32(96).bool(message.CanEditProject);
    }
    if (message.CanRequestReport === true) {
      writer.uint32(408).bool(message.CanRequestReport);
    }
    if (message.CanUploadCBCT === true) {
      writer.uint32(808).bool(message.CanUploadCBCT);
    }
    if (message.CanUploadIOXRay === true) {
      writer.uint32(816).bool(message.CanUploadIOXRay);
    }
    if (message.CanUploadPanoramicXRay === true) {
      writer.uint32(824).bool(message.CanUploadPanoramicXRay);
    }
    if (message.CanUploadSTL === true) {
      writer.uint32(832).bool(message.CanUploadSTL);
    }
    if (message.CanUploadDentalPhoto === true) {
      writer.uint32(840).bool(message.CanUploadDentalPhoto);
    }
    if (message.CanUploadDocuments === true) {
      writer.uint32(848).bool(message.CanUploadDocuments);
    }
    if (message.CanViewNewReports === true) {
      writer.uint32(1608).bool(message.CanViewNewReports);
    }
    if (message.CanViewNewStudies === true) {
      writer.uint32(1616).bool(message.CanViewNewStudies);
    }
    if (message.CanViewHistoricalReports === true) {
      writer.uint32(1624).bool(message.CanViewHistoricalReports);
    }
    if (message.CanViewHistoricalStudies === true) {
      writer.uint32(1632).bool(message.CanViewHistoricalStudies);
    }
    if (message.InvitationTime !== 0) {
      writer.uint32(1640).int64(message.InvitationTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatientPermissions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CanView = reader.bool();
          break;
        case 2:
          message.CanViewPersonalData = reader.bool();
          break;
        case 3:
          message.CanEditPersonalData = reader.bool();
          break;
        case 4:
          message.CanEditPatient = reader.bool();
          break;
        case 5:
          message.CanViewTeam = reader.bool();
          break;
        case 6:
          message.CanEditTeam = reader.bool();
          break;
        case 8:
          message.CanViewSharings = reader.bool();
          break;
        case 9:
          message.CanShare = reader.bool();
          break;
        case 10:
          message.CanCancelOthersSharings = reader.bool();
          break;
        case 11:
          message.CanViewProject = reader.bool();
          break;
        case 12:
          message.CanEditProject = reader.bool();
          break;
        case 51:
          message.CanRequestReport = reader.bool();
          break;
        case 101:
          message.CanUploadCBCT = reader.bool();
          break;
        case 102:
          message.CanUploadIOXRay = reader.bool();
          break;
        case 103:
          message.CanUploadPanoramicXRay = reader.bool();
          break;
        case 104:
          message.CanUploadSTL = reader.bool();
          break;
        case 105:
          message.CanUploadDentalPhoto = reader.bool();
          break;
        case 106:
          message.CanUploadDocuments = reader.bool();
          break;
        case 201:
          message.CanViewNewReports = reader.bool();
          break;
        case 202:
          message.CanViewNewStudies = reader.bool();
          break;
        case 203:
          message.CanViewHistoricalReports = reader.bool();
          break;
        case 204:
          message.CanViewHistoricalStudies = reader.bool();
          break;
        case 205:
          message.InvitationTime = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientPermissions {
    return {
      CanView: isSet(object.CanView) ? Boolean(object.CanView) : false,
      CanViewPersonalData: isSet(object.CanViewPersonalData)
        ? Boolean(object.CanViewPersonalData)
        : false,
      CanEditPersonalData: isSet(object.CanEditPersonalData)
        ? Boolean(object.CanEditPersonalData)
        : false,
      CanEditPatient: isSet(object.CanEditPatient)
        ? Boolean(object.CanEditPatient)
        : false,
      CanViewTeam: isSet(object.CanViewTeam)
        ? Boolean(object.CanViewTeam)
        : false,
      CanEditTeam: isSet(object.CanEditTeam)
        ? Boolean(object.CanEditTeam)
        : false,
      CanViewSharings: isSet(object.CanViewSharings)
        ? Boolean(object.CanViewSharings)
        : false,
      CanShare: isSet(object.CanShare) ? Boolean(object.CanShare) : false,
      CanCancelOthersSharings: isSet(object.CanCancelOthersSharings)
        ? Boolean(object.CanCancelOthersSharings)
        : false,
      CanViewProject: isSet(object.CanViewProject)
        ? Boolean(object.CanViewProject)
        : false,
      CanEditProject: isSet(object.CanEditProject)
        ? Boolean(object.CanEditProject)
        : false,
      CanRequestReport: isSet(object.CanRequestReport)
        ? Boolean(object.CanRequestReport)
        : false,
      CanUploadCBCT: isSet(object.CanUploadCBCT)
        ? Boolean(object.CanUploadCBCT)
        : false,
      CanUploadIOXRay: isSet(object.CanUploadIOXRay)
        ? Boolean(object.CanUploadIOXRay)
        : false,
      CanUploadPanoramicXRay: isSet(object.CanUploadPanoramicXRay)
        ? Boolean(object.CanUploadPanoramicXRay)
        : false,
      CanUploadSTL: isSet(object.CanUploadSTL)
        ? Boolean(object.CanUploadSTL)
        : false,
      CanUploadDentalPhoto: isSet(object.CanUploadDentalPhoto)
        ? Boolean(object.CanUploadDentalPhoto)
        : false,
      CanUploadDocuments: isSet(object.CanUploadDocuments)
        ? Boolean(object.CanUploadDocuments)
        : false,
      CanViewNewReports: isSet(object.CanViewNewReports)
        ? Boolean(object.CanViewNewReports)
        : false,
      CanViewNewStudies: isSet(object.CanViewNewStudies)
        ? Boolean(object.CanViewNewStudies)
        : false,
      CanViewHistoricalReports: isSet(object.CanViewHistoricalReports)
        ? Boolean(object.CanViewHistoricalReports)
        : false,
      CanViewHistoricalStudies: isSet(object.CanViewHistoricalStudies)
        ? Boolean(object.CanViewHistoricalStudies)
        : false,
      InvitationTime: isSet(object.InvitationTime)
        ? Number(object.InvitationTime)
        : 0,
    };
  },

  toJSON(message: PatientPermissions): unknown {
    const obj: any = {};
    message.CanView !== undefined && (obj.CanView = message.CanView);
    message.CanViewPersonalData !== undefined &&
      (obj.CanViewPersonalData = message.CanViewPersonalData);
    message.CanEditPersonalData !== undefined &&
      (obj.CanEditPersonalData = message.CanEditPersonalData);
    message.CanEditPatient !== undefined &&
      (obj.CanEditPatient = message.CanEditPatient);
    message.CanViewTeam !== undefined &&
      (obj.CanViewTeam = message.CanViewTeam);
    message.CanEditTeam !== undefined &&
      (obj.CanEditTeam = message.CanEditTeam);
    message.CanViewSharings !== undefined &&
      (obj.CanViewSharings = message.CanViewSharings);
    message.CanShare !== undefined && (obj.CanShare = message.CanShare);
    message.CanCancelOthersSharings !== undefined &&
      (obj.CanCancelOthersSharings = message.CanCancelOthersSharings);
    message.CanViewProject !== undefined &&
      (obj.CanViewProject = message.CanViewProject);
    message.CanEditProject !== undefined &&
      (obj.CanEditProject = message.CanEditProject);
    message.CanRequestReport !== undefined &&
      (obj.CanRequestReport = message.CanRequestReport);
    message.CanUploadCBCT !== undefined &&
      (obj.CanUploadCBCT = message.CanUploadCBCT);
    message.CanUploadIOXRay !== undefined &&
      (obj.CanUploadIOXRay = message.CanUploadIOXRay);
    message.CanUploadPanoramicXRay !== undefined &&
      (obj.CanUploadPanoramicXRay = message.CanUploadPanoramicXRay);
    message.CanUploadSTL !== undefined &&
      (obj.CanUploadSTL = message.CanUploadSTL);
    message.CanUploadDentalPhoto !== undefined &&
      (obj.CanUploadDentalPhoto = message.CanUploadDentalPhoto);
    message.CanUploadDocuments !== undefined &&
      (obj.CanUploadDocuments = message.CanUploadDocuments);
    message.CanViewNewReports !== undefined &&
      (obj.CanViewNewReports = message.CanViewNewReports);
    message.CanViewNewStudies !== undefined &&
      (obj.CanViewNewStudies = message.CanViewNewStudies);
    message.CanViewHistoricalReports !== undefined &&
      (obj.CanViewHistoricalReports = message.CanViewHistoricalReports);
    message.CanViewHistoricalStudies !== undefined &&
      (obj.CanViewHistoricalStudies = message.CanViewHistoricalStudies);
    message.InvitationTime !== undefined &&
      (obj.InvitationTime = Math.round(message.InvitationTime));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientPermissions>, I>>(
    object: I,
  ): PatientPermissions {
    const message = createBasePatientPermissions();
    message.CanView = object.CanView ?? false;
    message.CanViewPersonalData = object.CanViewPersonalData ?? false;
    message.CanEditPersonalData = object.CanEditPersonalData ?? false;
    message.CanEditPatient = object.CanEditPatient ?? false;
    message.CanViewTeam = object.CanViewTeam ?? false;
    message.CanEditTeam = object.CanEditTeam ?? false;
    message.CanViewSharings = object.CanViewSharings ?? false;
    message.CanShare = object.CanShare ?? false;
    message.CanCancelOthersSharings = object.CanCancelOthersSharings ?? false;
    message.CanViewProject = object.CanViewProject ?? false;
    message.CanEditProject = object.CanEditProject ?? false;
    message.CanRequestReport = object.CanRequestReport ?? false;
    message.CanUploadCBCT = object.CanUploadCBCT ?? false;
    message.CanUploadIOXRay = object.CanUploadIOXRay ?? false;
    message.CanUploadPanoramicXRay = object.CanUploadPanoramicXRay ?? false;
    message.CanUploadSTL = object.CanUploadSTL ?? false;
    message.CanUploadDentalPhoto = object.CanUploadDentalPhoto ?? false;
    message.CanUploadDocuments = object.CanUploadDocuments ?? false;
    message.CanViewNewReports = object.CanViewNewReports ?? false;
    message.CanViewNewStudies = object.CanViewNewStudies ?? false;
    message.CanViewHistoricalReports = object.CanViewHistoricalReports ?? false;
    message.CanViewHistoricalStudies = object.CanViewHistoricalStudies ?? false;
    message.InvitationTime = object.InvitationTime ?? 0;
    return message;
  },
};

function createBasePatientSharingPermissions(): PatientSharingPermissions {
  return {
    CanViewPersonalData: false,
    CanViewProject: false,
    CanEditProject: false,
    CanRequestReport: false,
    CanUploadCBCT: false,
    CanUploadIOXRay: false,
    CanUploadPanoramicXRay: false,
    CanUploadSTL: false,
    CanUploadDentalPhoto: false,
    CanUploadDocuments: false,
    CanViewNewReports: false,
    CanViewNewStudies: false,
    CanViewHistoricalReports: false,
    CanViewHistoricalStudies: false,
  };
}

export const PatientSharingPermissions = {
  encode(
    message: PatientSharingPermissions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CanViewPersonalData === true) {
      writer.uint32(8).bool(message.CanViewPersonalData);
    }
    if (message.CanViewProject === true) {
      writer.uint32(80).bool(message.CanViewProject);
    }
    if (message.CanEditProject === true) {
      writer.uint32(88).bool(message.CanEditProject);
    }
    if (message.CanRequestReport === true) {
      writer.uint32(408).bool(message.CanRequestReport);
    }
    if (message.CanUploadCBCT === true) {
      writer.uint32(808).bool(message.CanUploadCBCT);
    }
    if (message.CanUploadIOXRay === true) {
      writer.uint32(816).bool(message.CanUploadIOXRay);
    }
    if (message.CanUploadPanoramicXRay === true) {
      writer.uint32(824).bool(message.CanUploadPanoramicXRay);
    }
    if (message.CanUploadSTL === true) {
      writer.uint32(832).bool(message.CanUploadSTL);
    }
    if (message.CanUploadDentalPhoto === true) {
      writer.uint32(840).bool(message.CanUploadDentalPhoto);
    }
    if (message.CanUploadDocuments === true) {
      writer.uint32(848).bool(message.CanUploadDocuments);
    }
    if (message.CanViewNewReports === true) {
      writer.uint32(1608).bool(message.CanViewNewReports);
    }
    if (message.CanViewNewStudies === true) {
      writer.uint32(1616).bool(message.CanViewNewStudies);
    }
    if (message.CanViewHistoricalReports === true) {
      writer.uint32(1624).bool(message.CanViewHistoricalReports);
    }
    if (message.CanViewHistoricalStudies === true) {
      writer.uint32(1632).bool(message.CanViewHistoricalStudies);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): PatientSharingPermissions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientSharingPermissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CanViewPersonalData = reader.bool();
          break;
        case 10:
          message.CanViewProject = reader.bool();
          break;
        case 11:
          message.CanEditProject = reader.bool();
          break;
        case 51:
          message.CanRequestReport = reader.bool();
          break;
        case 101:
          message.CanUploadCBCT = reader.bool();
          break;
        case 102:
          message.CanUploadIOXRay = reader.bool();
          break;
        case 103:
          message.CanUploadPanoramicXRay = reader.bool();
          break;
        case 104:
          message.CanUploadSTL = reader.bool();
          break;
        case 105:
          message.CanUploadDentalPhoto = reader.bool();
          break;
        case 106:
          message.CanUploadDocuments = reader.bool();
          break;
        case 201:
          message.CanViewNewReports = reader.bool();
          break;
        case 202:
          message.CanViewNewStudies = reader.bool();
          break;
        case 203:
          message.CanViewHistoricalReports = reader.bool();
          break;
        case 204:
          message.CanViewHistoricalStudies = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientSharingPermissions {
    return {
      CanViewPersonalData: isSet(object.CanViewPersonalData)
        ? Boolean(object.CanViewPersonalData)
        : false,
      CanViewProject: isSet(object.CanViewProject)
        ? Boolean(object.CanViewProject)
        : false,
      CanEditProject: isSet(object.CanEditProject)
        ? Boolean(object.CanEditProject)
        : false,
      CanRequestReport: isSet(object.CanRequestReport)
        ? Boolean(object.CanRequestReport)
        : false,
      CanUploadCBCT: isSet(object.CanUploadCBCT)
        ? Boolean(object.CanUploadCBCT)
        : false,
      CanUploadIOXRay: isSet(object.CanUploadIOXRay)
        ? Boolean(object.CanUploadIOXRay)
        : false,
      CanUploadPanoramicXRay: isSet(object.CanUploadPanoramicXRay)
        ? Boolean(object.CanUploadPanoramicXRay)
        : false,
      CanUploadSTL: isSet(object.CanUploadSTL)
        ? Boolean(object.CanUploadSTL)
        : false,
      CanUploadDentalPhoto: isSet(object.CanUploadDentalPhoto)
        ? Boolean(object.CanUploadDentalPhoto)
        : false,
      CanUploadDocuments: isSet(object.CanUploadDocuments)
        ? Boolean(object.CanUploadDocuments)
        : false,
      CanViewNewReports: isSet(object.CanViewNewReports)
        ? Boolean(object.CanViewNewReports)
        : false,
      CanViewNewStudies: isSet(object.CanViewNewStudies)
        ? Boolean(object.CanViewNewStudies)
        : false,
      CanViewHistoricalReports: isSet(object.CanViewHistoricalReports)
        ? Boolean(object.CanViewHistoricalReports)
        : false,
      CanViewHistoricalStudies: isSet(object.CanViewHistoricalStudies)
        ? Boolean(object.CanViewHistoricalStudies)
        : false,
    };
  },

  toJSON(message: PatientSharingPermissions): unknown {
    const obj: any = {};
    message.CanViewPersonalData !== undefined &&
      (obj.CanViewPersonalData = message.CanViewPersonalData);
    message.CanViewProject !== undefined &&
      (obj.CanViewProject = message.CanViewProject);
    message.CanEditProject !== undefined &&
      (obj.CanEditProject = message.CanEditProject);
    message.CanRequestReport !== undefined &&
      (obj.CanRequestReport = message.CanRequestReport);
    message.CanUploadCBCT !== undefined &&
      (obj.CanUploadCBCT = message.CanUploadCBCT);
    message.CanUploadIOXRay !== undefined &&
      (obj.CanUploadIOXRay = message.CanUploadIOXRay);
    message.CanUploadPanoramicXRay !== undefined &&
      (obj.CanUploadPanoramicXRay = message.CanUploadPanoramicXRay);
    message.CanUploadSTL !== undefined &&
      (obj.CanUploadSTL = message.CanUploadSTL);
    message.CanUploadDentalPhoto !== undefined &&
      (obj.CanUploadDentalPhoto = message.CanUploadDentalPhoto);
    message.CanUploadDocuments !== undefined &&
      (obj.CanUploadDocuments = message.CanUploadDocuments);
    message.CanViewNewReports !== undefined &&
      (obj.CanViewNewReports = message.CanViewNewReports);
    message.CanViewNewStudies !== undefined &&
      (obj.CanViewNewStudies = message.CanViewNewStudies);
    message.CanViewHistoricalReports !== undefined &&
      (obj.CanViewHistoricalReports = message.CanViewHistoricalReports);
    message.CanViewHistoricalStudies !== undefined &&
      (obj.CanViewHistoricalStudies = message.CanViewHistoricalStudies);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientSharingPermissions>, I>>(
    object: I,
  ): PatientSharingPermissions {
    const message = createBasePatientSharingPermissions();
    message.CanViewPersonalData = object.CanViewPersonalData ?? false;
    message.CanViewProject = object.CanViewProject ?? false;
    message.CanEditProject = object.CanEditProject ?? false;
    message.CanRequestReport = object.CanRequestReport ?? false;
    message.CanUploadCBCT = object.CanUploadCBCT ?? false;
    message.CanUploadIOXRay = object.CanUploadIOXRay ?? false;
    message.CanUploadPanoramicXRay = object.CanUploadPanoramicXRay ?? false;
    message.CanUploadSTL = object.CanUploadSTL ?? false;
    message.CanUploadDentalPhoto = object.CanUploadDentalPhoto ?? false;
    message.CanUploadDocuments = object.CanUploadDocuments ?? false;
    message.CanViewNewReports = object.CanViewNewReports ?? false;
    message.CanViewNewStudies = object.CanViewNewStudies ?? false;
    message.CanViewHistoricalReports = object.CanViewHistoricalReports ?? false;
    message.CanViewHistoricalStudies = object.CanViewHistoricalStudies ?? false;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
