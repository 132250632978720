import { studyScanInfoMessages } from './config/i18n';

export * as studyModel from './model';

export const i18n = {
  studyScanInfoMessages,
};

export * from './config/const';
export * from './lib/getStudiesIds';

export { StudySelectOption } from './ui/StudySelectOption/StudySelectOption';
