import { useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import { useSearchParams } from 'react-router-dom';

import api from '@/shared/api/api';
import { useAppDispatch } from '@/shared/hooks';
import { patientModel } from '@/entities/patient';
import { studyCountModel } from '@/entities/studyCount';
import { usersModel } from '@/entities/users';

export type UsePatientsListStreamArgs = {
  organizationID: string;
};

export type PatientsFilter = 'all' | 'sharedWithMe';

export const usePatientsListStream = ({
  organizationID,
}: UsePatientsListStreamArgs) => {
  // TODO: Resolve issue with previous searchParams date on reopening stream
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get('search');
  const patietnsFilter = searchParams.get('filters') as PatientsFilter;

  // use to restore patients state for infinity scroll
  // must be reseted after any filters update
  const [resumeToken, setResumeToken] = useState('');

  const dispatch = useAppDispatch();

  const patientListStream = useRef<Subscription>(undefined);

  const openPatientListStream = (
    filterSettings = {
      searchString: searchValue,
      sharedWithMe: patietnsFilter,
      id: '',
    },
  ) => {
    const { searchString, sharedWithMe, id } = filterSettings;

    dispatch(patientModel.actions.setLoading('pending'));

    patientListStream.current = api.patient
      .PatientListStream({
        OrganizationID: organizationID,
        ResumeToken: resumeToken,
        SearchString: searchString,
        SharedWithMe: sharedWithMe === 'sharedWithMe',
        // DoctorIDs: filters.doctors,
        StartFromPatientID: id,
        Limit: 20,
      })
      .subscribe({
        next: ({
          InitialPatientsListItems,
          PatientUpdated,
          ResumeToken,
          SharedCount,
          TotalCount,
        }) => {
          if (InitialPatientsListItems?.Patients) {
            const patients = InitialPatientsListItems?.Patients.map(
              ({ Patient }) => Patient,
            );

            dispatch(patientModel.actions.addMany(patients));

            dispatch(patientModel.actions.setLoading('succeeded'));

            const users = InitialPatientsListItems?.Patients.reduce(
              (patientUsers, { RelatedUsers }) => [
                ...patientUsers,
                ...RelatedUsers,
              ],
              [],
            );

            dispatch(usersModel.actions.setMany(users));

            const studyCounts = InitialPatientsListItems?.Patients.reduce(
              (patientStudyCounts, { Patient, Counts }) => {
                patientStudyCounts.push({
                  patientID: Patient.ID,
                  counts: Counts,
                });

                return patientStudyCounts;
              },
              [],
            );

            dispatch(studyCountModel.actions.addMany(studyCounts));
          }

          if (PatientUpdated) {
            dispatch(patientModel.actions.setNewestOne(PatientUpdated.Patient));

            dispatch(usersModel.actions.setMany(PatientUpdated.RelatedUsers));

            const studyCount = {
              patientID: PatientUpdated.Patient.ID,
              counts: PatientUpdated.Counts,
            };

            dispatch(studyCountModel.actions.setOne(studyCount));
          }

          if (ResumeToken) {
            setResumeToken(ResumeToken);
          }

          if (SharedCount) {
            dispatch(patientModel.actions.setSharedCounter(SharedCount));
          }

          if (TotalCount) {
            dispatch(patientModel.actions.setTotalCounter(TotalCount));
          }
        },
        complete: () => {},
        error: () => {
          dispatch(patientModel.actions.setLoading('failed'));
        },
      });
  };

  const closePatientListStream = (shouldClearStore = true) => {
    if (patientListStream.current) {
      patientListStream.current.unsubscribe();

      if (shouldClearStore) {
        dispatch(patientModel.actions.removeAll());
      }
    }
  };

  return { openPatientListStream, closePatientListStream };
};
