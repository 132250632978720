import React, { FC, useState } from 'react';
import cn from 'classnames';

import { userModel } from '@/entities/user';
import { useAppSelector } from '@/shared/hooks';
import { Button, Input } from '@/shared/ui';

import { CopyPaste } from '../CopyPaste/CopyPaste';
import { ObjModal } from '../ObjModal/ObjModal';

import styles from './UserTest.module.scss';

type Props = {
  className?: string;
  openStream: () => void;
  closeStream: () => void;
};

export const UserTest: FC<Props> = (props) => {
  const { className, closeStream, openStream } = props;

  const me = useAppSelector(userModel.selectors.selectCurrentUser);

  const { ID, PersonalData } = me || {
    ID: 'undefined',
    PersonalData: undefined,
  };

  const [accessToken, setAccessToken] = useState('');

  return (
    <div className={cn(className)}>
      <div className={styles.wrap}>
        <Input
          name="dontknow"
          value={accessToken}
          onChange={(e) => {
            setAccessToken(e.target.value);
          }}
        />
        <Button
          onClick={() => {
            localStorage.setItem('accessToken', accessToken);
          }}
        >
          Update access token
        </Button>
      </div>
      <br />
      <div className={styles.buttons}>
        <Button onClick={openStream}>Open UserDataStream</Button>
        <Button onClick={closeStream} variant="secondary">
          Close UserDataStream
        </Button>
      </div>
      {!!ID && (
        <div className={styles.userInfo}>
          <ObjModal obj={me} />
          <ul>
            <li>
              <b className={styles.label}>ID:</b>
              {ID}
              <CopyPaste text={ID} className={styles.inline} />{' '}
            </li>
            <li>
              <b className={styles.labelContainer}>Name:</b>
              {` ${PersonalData?.FirstName} ${PersonalData?.LastName}`}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
