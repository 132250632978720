import React, { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { toothChartLegendsMessages } from '../../config/i18n';
import { ToothChartLegendsTypes } from '../../config/tooth.type';

import styles from './ToothChartTabs.module.scss';

export type ToothChartLegendsProps = {
  counters: Record<ToothChartLegendsTypes, number[]>;
  toothChartTabs: ToothChartLegendsTypes[];
  onClick?: (legend: ToothChartLegendsTypes) => void;
  ROIList?: number[];
  className?: string;
};

export const ToothChartTabs: FC<ToothChartLegendsProps> = (props) => {
  const { counters, toothChartTabs, onClick, ROIList, className } = props;

  const { formatMessage } = useIntl();

  const handleClick = (legend: ToothChartLegendsTypes) => {
    onClick(legend);
  };

  return (
    <div className={cn(styles.container, className)}>
      {toothChartTabs.map((legend) => {
        if (!counters[legend].length) {
          return null;
        }

        return (
          <div
            className={cn(
              styles.legend,
              styles[legend],
              counters[legend].length &&
                counters[legend].every((counter) =>
                  ROIList.includes(counter),
                ) &&
                styles.active,
              legend === 'all' &&
                ROIList.length === counters.all.length &&
                styles.active,
              onClick && styles.pointer,
            )}
            key={legend}
            onClick={() => handleClick(legend)}
          >
            <span
              className={cn(
                styles.legendTitle,
                (legend === 'missing' ||
                  legend === 'all' ||
                  legend === 'healthy') &&
                  styles.legendGreyInfo,
                'p3t',
              )}
            >
              {formatMessage(toothChartLegendsMessages[legend])}
            </span>
            <span
              className={cn(
                styles.legendCount,
                (legend === 'missing' ||
                  legend === 'all' ||
                  legend === 'healthy') &&
                  styles.legendGreyInfo,
                'p3t',
              )}
            >
              {counters[legend].length}
            </span>
          </div>
        );
      })}
    </div>
  );
};
