import { IntlProvider as ReactIntlProvider } from 'react-intl';
import React, { ReactNode, useEffect, useState, FC } from 'react';

import { useAppSelector } from '@/shared/hooks';
import { userModel } from '@/entities/user';
import {
  getAvailableLanguages,
  getMessages,
  isLanguageRTL,
} from '@/features/intl';

type IntlProviderProps = {
  children: ReactNode;
};

export const IntlProvider: FC<IntlProviderProps> = (props) => {
  const { children } = props;

  const [messages, setMessages] = useState({});

  const { locale } = useAppSelector(userModel.selectors.selectSettings);
  const availableLanguages = getAvailableLanguages();

  useEffect(() => {
    const init = async () => {
      const msg = await getMessages(locale);
      setMessages(msg);
    };

    init();
  }, [locale]);

  useEffect(() => {
    if (isLanguageRTL(locale, availableLanguages)) {
      document.body.setAttribute('dir', 'rtl');
    } else {
      document.body.setAttribute('dir', 'ltr');
    }
  }, [locale, availableLanguages]);

  return (
    // Some mismatch with react-intl types for react 18 - https://github.com/formatjs/formatjs/issues/3510
    // @ts-ignore
    <ReactIntlProvider defaultLocale="en" locale={locale} messages={messages}>
      {children}
    </ReactIntlProvider>
  );
};
