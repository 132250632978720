import { trim } from 'ramda';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import { useAppDispatch } from '@/shared/hooks';

export const useAddNewComment = () => {
  const dispatch = useAppDispatch();

  return async (toothID: string, newComment: string) => {
    const response = await dispatch(
      reportsModel.thunks.setReportToothComment({
        ToothID: toothID,
        Comment: trim(newComment),
      }),
    ).unwrap();

    dispatch(toothModel.actions.setNewestOne(response.Tooth));
  };
};
