import { OrderedLine2D } from '@/shared/api/protocol_gen/model/dto_common_geometry';
import { SliceInterface } from '@/entities/assets';

export const getGuideLines = (
  lines: OrderedLine2D[],
  slices: SliceInterface[],
) =>
  [...lines]
    .sort((a, b) => a.Order - b.Order)
    .reduce((acc, line, lineIndex) => {
      const src = slices[lineIndex]?.src;
      return [
        ...acc,
        {
          x1: line.Start.X,
          x2: line.End.X,
          y1: line.Start.Y,
          y2: line.End.Y,
          order: line.Order,
          src,
        },
      ];
    }, []);
