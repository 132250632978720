// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserTest-module__buttons--3hyGKpkk{padding-bottom:8px;display:flex;grid-gap:12px}.UserTest-module__wrap--5eScdaYN{display:flex;grid-gap:12px}.UserTest-module__inline--L45mwIWg{display:inline-block}.UserTest-module__userInfo--OitZOnxj{display:flex;align-items:center}.UserTest-module__userInfo--OitZOnxj li{display:flex;align-items:center}\n\n.UserTest-module__label--O61XB7gO{margin-right:4px}\n\n[dir=\"rtl\"] .UserTest-module__label--O61XB7gO{margin-right:0;margin-left:4px}", "",{"version":3,"sources":["webpack://./src/pages/TestPage/components/User/UserTest.module.scss"],"names":[],"mappings":"AAAA,oCACE,kBAAA,CACA,YAAA,CACA,aAAA,CAGF,iCACE,YAAA,CACA,aAAA,CAGF,mCACE,oBAAA,CAGF,qCACE,YAAA,CACA,kBAAA,CAEA,wCACE,YAAA,CACA,kBAAA;;AAIJ,kCACE,gBAAA;;AADF,8CACE,cAAA,CAAA,eAAA","sourcesContent":[".buttons {\n  padding-bottom: 8px;\n  display: flex;\n  grid-gap: 12px;\n}\n\n.wrap {\n  display: flex;\n  grid-gap: 12px;\n}\n\n.inline {\n  display: inline-block;\n}\n\n.userInfo {\n  display: flex;\n  align-items: center;\n\n  li {\n    display: flex;\n    align-items: center;\n  }\n}\n\n.label {\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "UserTest-module__buttons--3hyGKpkk",
	"wrap": "UserTest-module__wrap--5eScdaYN",
	"inline": "UserTest-module__inline--L45mwIWg",
	"userInfo": "UserTest-module__userInfo--OitZOnxj",
	"label": "UserTest-module__label--O61XB7gO"
};
export default ___CSS_LOADER_EXPORT___;
