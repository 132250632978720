/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  Language,
  Country,
  languageFromJSON,
  countryFromJSON,
  languageToJSON,
  countryToJSON,
} from '../../model/dto_base';
import { Timestamp } from '../../google/protobuf/timestamp';
import { Empty } from '../../google/protobuf/empty';

export const protobufPackage = 'com.diagnocat.auth';

export enum OAuthProvider {
  OAuthProviderInvalid = 0,
  OAuthProviderGoogle = 1,
  /** OAuthProviderGithub - ... */
  OAuthProviderGithub = 2,
  UNRECOGNIZED = -1,
}

export function oAuthProviderFromJSON(object: any): OAuthProvider {
  switch (object) {
    case 0:
    case 'OAuthProviderInvalid':
      return OAuthProvider.OAuthProviderInvalid;
    case 1:
    case 'OAuthProviderGoogle':
      return OAuthProvider.OAuthProviderGoogle;
    case 2:
    case 'OAuthProviderGithub':
      return OAuthProvider.OAuthProviderGithub;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OAuthProvider.UNRECOGNIZED;
  }
}

export function oAuthProviderToJSON(object: OAuthProvider): string {
  switch (object) {
    case OAuthProvider.OAuthProviderInvalid:
      return 'OAuthProviderInvalid';
    case OAuthProvider.OAuthProviderGoogle:
      return 'OAuthProviderGoogle';
    case OAuthProvider.OAuthProviderGithub:
      return 'OAuthProviderGithub';
    case OAuthProvider.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum AuthMethod {
  MethodInvalid = 0,
  MethodPassword = 1,
  MethodPhoneSMS = 2,
  MethodCallerNumber = 3,
  MethodEmailCode = 4,
  MethodPIN = 5,
  MethodOTP = 6,
  MethodWebAuthnKey = 7,
  UNRECOGNIZED = -1,
}

export function authMethodFromJSON(object: any): AuthMethod {
  switch (object) {
    case 0:
    case 'MethodInvalid':
      return AuthMethod.MethodInvalid;
    case 1:
    case 'MethodPassword':
      return AuthMethod.MethodPassword;
    case 2:
    case 'MethodPhoneSMS':
      return AuthMethod.MethodPhoneSMS;
    case 3:
    case 'MethodCallerNumber':
      return AuthMethod.MethodCallerNumber;
    case 4:
    case 'MethodEmailCode':
      return AuthMethod.MethodEmailCode;
    case 5:
    case 'MethodPIN':
      return AuthMethod.MethodPIN;
    case 6:
    case 'MethodOTP':
      return AuthMethod.MethodOTP;
    case 7:
    case 'MethodWebAuthnKey':
      return AuthMethod.MethodWebAuthnKey;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AuthMethod.UNRECOGNIZED;
  }
}

export function authMethodToJSON(object: AuthMethod): string {
  switch (object) {
    case AuthMethod.MethodInvalid:
      return 'MethodInvalid';
    case AuthMethod.MethodPassword:
      return 'MethodPassword';
    case AuthMethod.MethodPhoneSMS:
      return 'MethodPhoneSMS';
    case AuthMethod.MethodCallerNumber:
      return 'MethodCallerNumber';
    case AuthMethod.MethodEmailCode:
      return 'MethodEmailCode';
    case AuthMethod.MethodPIN:
      return 'MethodPIN';
    case AuthMethod.MethodOTP:
      return 'MethodOTP';
    case AuthMethod.MethodWebAuthnKey:
      return 'MethodWebAuthnKey';
    case AuthMethod.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Identity {
  Phone: string | undefined;
  Email: string | undefined;
  OAuth: Identity_IdentityOAuth | undefined;
  WebAuthn: Identity_IdentityWebAuthn | undefined;
}

export interface Identity_IdentityOAuth {
  Provider: OAuthProvider;
  UserID: string;
  UserName: string;
}

export interface Identity_IdentityWebAuthn {
  /** ???? */
  Name: string;
  Pubkey: string;
}

export interface AuthState {
  IsSuccessful: boolean;
  InProgress: boolean;
  /** non-empty if an error occured */
  Error?: Error | undefined;
  CurrentStep: number;
  Objective: AuthState_AuthObjective;
  Steps: AuthState_AuthStep[];
  CreatedAt: Date | undefined;
  ExpiresAt: Date | undefined;
}

export enum AuthState_AuthObjective {
  NONE = 0,
  SIGNUP = 1,
  SIGNIN = 2,
  CONFIRM = 3,
  RECOVER = 4,
  ADD_IDENTITY = 5,
  UNRECOGNIZED = -1,
}

export function authState_AuthObjectiveFromJSON(
  object: any,
): AuthState_AuthObjective {
  switch (object) {
    case 0:
    case 'NONE':
      return AuthState_AuthObjective.NONE;
    case 1:
    case 'SIGNUP':
      return AuthState_AuthObjective.SIGNUP;
    case 2:
    case 'SIGNIN':
      return AuthState_AuthObjective.SIGNIN;
    case 3:
    case 'CONFIRM':
      return AuthState_AuthObjective.CONFIRM;
    case 4:
    case 'RECOVER':
      return AuthState_AuthObjective.RECOVER;
    case 5:
    case 'ADD_IDENTITY':
      return AuthState_AuthObjective.ADD_IDENTITY;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AuthState_AuthObjective.UNRECOGNIZED;
  }
}

export function authState_AuthObjectiveToJSON(
  object: AuthState_AuthObjective,
): string {
  switch (object) {
    case AuthState_AuthObjective.NONE:
      return 'NONE';
    case AuthState_AuthObjective.SIGNUP:
      return 'SIGNUP';
    case AuthState_AuthObjective.SIGNIN:
      return 'SIGNIN';
    case AuthState_AuthObjective.CONFIRM:
      return 'CONFIRM';
    case AuthState_AuthObjective.RECOVER:
      return 'RECOVER';
    case AuthState_AuthObjective.ADD_IDENTITY:
      return 'ADD_IDENTITY';
    case AuthState_AuthObjective.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface AuthState_AuthMethodOptionsOAuth {
  RedirectURL: string;
}

export interface AuthState_AuthMethodOptionsWebAuthn {
  ChallengeData: string;
}

export interface AuthState_AuthStep {
  /** MethodPassword */
  PreferredAuthMethod: AuthMethod;
  /** [MethodEmailCode, MethodPhoneSMS, MethodCallerNumber] */
  AlternativeAuthMethods: AuthMethod[];
  None: Empty | undefined;
  AuthMethodOptionsOAuth: AuthState_AuthMethodOptionsOAuth | undefined;
  AuthMethodOptionsWebAuthn: AuthState_AuthMethodOptionsWebAuthn | undefined;
}

export interface Token {
  Value: string;
}

export interface Secret {
  Value: string;
}

export interface Error {
  Code: number;
  Message: string;
}

export interface PersonalInfo {
  FirstName: string;
  LastName: string;
  Language: Language;
  Country: Country;
}

export interface PhoneAuthOptions {
  UsePhoneCallerNumber: boolean;
  PhoneNumber: string;
}

function createBaseIdentity(): Identity {
  return {
    Phone: undefined,
    Email: undefined,
    OAuth: undefined,
    WebAuthn: undefined,
  };
}

export const Identity = {
  encode(
    message: Identity,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Phone !== undefined) {
      writer.uint32(10).string(message.Phone);
    }
    if (message.Email !== undefined) {
      writer.uint32(18).string(message.Email);
    }
    if (message.OAuth !== undefined) {
      Identity_IdentityOAuth.encode(
        message.OAuth,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.WebAuthn !== undefined) {
      Identity_IdentityWebAuthn.encode(
        message.WebAuthn,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Identity {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIdentity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Phone = reader.string();
          break;
        case 2:
          message.Email = reader.string();
          break;
        case 3:
          message.OAuth = Identity_IdentityOAuth.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.WebAuthn = Identity_IdentityWebAuthn.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Identity {
    return {
      Phone: isSet(object.Phone) ? String(object.Phone) : undefined,
      Email: isSet(object.Email) ? String(object.Email) : undefined,
      OAuth: isSet(object.OAuth)
        ? Identity_IdentityOAuth.fromJSON(object.OAuth)
        : undefined,
      WebAuthn: isSet(object.WebAuthn)
        ? Identity_IdentityWebAuthn.fromJSON(object.WebAuthn)
        : undefined,
    };
  },

  toJSON(message: Identity): unknown {
    const obj: any = {};
    message.Phone !== undefined && (obj.Phone = message.Phone);
    message.Email !== undefined && (obj.Email = message.Email);
    message.OAuth !== undefined &&
      (obj.OAuth = message.OAuth
        ? Identity_IdentityOAuth.toJSON(message.OAuth)
        : undefined);
    message.WebAuthn !== undefined &&
      (obj.WebAuthn = message.WebAuthn
        ? Identity_IdentityWebAuthn.toJSON(message.WebAuthn)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Identity>, I>>(object: I): Identity {
    const message = createBaseIdentity();
    message.Phone = object.Phone ?? undefined;
    message.Email = object.Email ?? undefined;
    message.OAuth =
      object.OAuth !== undefined && object.OAuth !== null
        ? Identity_IdentityOAuth.fromPartial(object.OAuth)
        : undefined;
    message.WebAuthn =
      object.WebAuthn !== undefined && object.WebAuthn !== null
        ? Identity_IdentityWebAuthn.fromPartial(object.WebAuthn)
        : undefined;
    return message;
  },
};

function createBaseIdentity_IdentityOAuth(): Identity_IdentityOAuth {
  return { Provider: 0, UserID: '', UserName: '' };
}

export const Identity_IdentityOAuth = {
  encode(
    message: Identity_IdentityOAuth,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Provider !== 0) {
      writer.uint32(8).int32(message.Provider);
    }
    if (message.UserID !== '') {
      writer.uint32(18).string(message.UserID);
    }
    if (message.UserName !== '') {
      writer.uint32(26).string(message.UserName);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Identity_IdentityOAuth {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIdentity_IdentityOAuth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Provider = reader.int32() as any;
          break;
        case 2:
          message.UserID = reader.string();
          break;
        case 3:
          message.UserName = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Identity_IdentityOAuth {
    return {
      Provider: isSet(object.Provider)
        ? oAuthProviderFromJSON(object.Provider)
        : 0,
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      UserName: isSet(object.UserName) ? String(object.UserName) : '',
    };
  },

  toJSON(message: Identity_IdentityOAuth): unknown {
    const obj: any = {};
    message.Provider !== undefined &&
      (obj.Provider = oAuthProviderToJSON(message.Provider));
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.UserName !== undefined && (obj.UserName = message.UserName);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Identity_IdentityOAuth>, I>>(
    object: I,
  ): Identity_IdentityOAuth {
    const message = createBaseIdentity_IdentityOAuth();
    message.Provider = object.Provider ?? 0;
    message.UserID = object.UserID ?? '';
    message.UserName = object.UserName ?? '';
    return message;
  },
};

function createBaseIdentity_IdentityWebAuthn(): Identity_IdentityWebAuthn {
  return { Name: '', Pubkey: '' };
}

export const Identity_IdentityWebAuthn = {
  encode(
    message: Identity_IdentityWebAuthn,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Name !== '') {
      writer.uint32(10).string(message.Name);
    }
    if (message.Pubkey !== '') {
      writer.uint32(18).string(message.Pubkey);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Identity_IdentityWebAuthn {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIdentity_IdentityWebAuthn();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Name = reader.string();
          break;
        case 2:
          message.Pubkey = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Identity_IdentityWebAuthn {
    return {
      Name: isSet(object.Name) ? String(object.Name) : '',
      Pubkey: isSet(object.Pubkey) ? String(object.Pubkey) : '',
    };
  },

  toJSON(message: Identity_IdentityWebAuthn): unknown {
    const obj: any = {};
    message.Name !== undefined && (obj.Name = message.Name);
    message.Pubkey !== undefined && (obj.Pubkey = message.Pubkey);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Identity_IdentityWebAuthn>, I>>(
    object: I,
  ): Identity_IdentityWebAuthn {
    const message = createBaseIdentity_IdentityWebAuthn();
    message.Name = object.Name ?? '';
    message.Pubkey = object.Pubkey ?? '';
    return message;
  },
};

function createBaseAuthState(): AuthState {
  return {
    IsSuccessful: false,
    InProgress: false,
    Error: undefined,
    CurrentStep: 0,
    Objective: 0,
    Steps: [],
    CreatedAt: undefined,
    ExpiresAt: undefined,
  };
}

export const AuthState = {
  encode(
    message: AuthState,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IsSuccessful === true) {
      writer.uint32(800).bool(message.IsSuccessful);
    }
    if (message.InProgress === true) {
      writer.uint32(816).bool(message.InProgress);
    }
    if (message.Error !== undefined) {
      Error.encode(message.Error, writer.uint32(826).fork()).ldelim();
    }
    if (message.CurrentStep !== 0) {
      writer.uint32(1608).int64(message.CurrentStep);
    }
    if (message.Objective !== 0) {
      writer.uint32(1616).int32(message.Objective);
    }
    for (const v of message.Steps) {
      AuthState_AuthStep.encode(v!, writer.uint32(1626).fork()).ldelim();
    }
    if (message.CreatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.CreatedAt),
        writer.uint32(2402).fork(),
      ).ldelim();
    }
    if (message.ExpiresAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.ExpiresAt),
        writer.uint32(2410).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthState {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 100:
          message.IsSuccessful = reader.bool();
          break;
        case 102:
          message.InProgress = reader.bool();
          break;
        case 103:
          message.Error = Error.decode(reader, reader.uint32());
          break;
        case 201:
          message.CurrentStep = longToNumber(reader.int64() as Long);
          break;
        case 202:
          message.Objective = reader.int32() as any;
          break;
        case 203:
          message.Steps.push(
            AuthState_AuthStep.decode(reader, reader.uint32()),
          );
          break;
        case 300:
          message.CreatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        case 301:
          message.ExpiresAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AuthState {
    return {
      IsSuccessful: isSet(object.IsSuccessful)
        ? Boolean(object.IsSuccessful)
        : false,
      InProgress: isSet(object.InProgress) ? Boolean(object.InProgress) : false,
      Error: isSet(object.Error) ? Error.fromJSON(object.Error) : undefined,
      CurrentStep: isSet(object.CurrentStep) ? Number(object.CurrentStep) : 0,
      Objective: isSet(object.Objective)
        ? authState_AuthObjectiveFromJSON(object.Objective)
        : 0,
      Steps: Array.isArray(object?.Steps)
        ? object.Steps.map((e: any) => AuthState_AuthStep.fromJSON(e))
        : [],
      CreatedAt: isSet(object.CreatedAt)
        ? fromJsonTimestamp(object.CreatedAt)
        : undefined,
      ExpiresAt: isSet(object.ExpiresAt)
        ? fromJsonTimestamp(object.ExpiresAt)
        : undefined,
    };
  },

  toJSON(message: AuthState): unknown {
    const obj: any = {};
    message.IsSuccessful !== undefined &&
      (obj.IsSuccessful = message.IsSuccessful);
    message.InProgress !== undefined && (obj.InProgress = message.InProgress);
    message.Error !== undefined &&
      (obj.Error = message.Error ? Error.toJSON(message.Error) : undefined);
    message.CurrentStep !== undefined &&
      (obj.CurrentStep = Math.round(message.CurrentStep));
    message.Objective !== undefined &&
      (obj.Objective = authState_AuthObjectiveToJSON(message.Objective));
    if (message.Steps) {
      obj.Steps = message.Steps.map((e) =>
        e ? AuthState_AuthStep.toJSON(e) : undefined,
      );
    } else {
      obj.Steps = [];
    }
    message.CreatedAt !== undefined &&
      (obj.CreatedAt = message.CreatedAt.toISOString());
    message.ExpiresAt !== undefined &&
      (obj.ExpiresAt = message.ExpiresAt.toISOString());
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AuthState>, I>>(
    object: I,
  ): AuthState {
    const message = createBaseAuthState();
    message.IsSuccessful = object.IsSuccessful ?? false;
    message.InProgress = object.InProgress ?? false;
    message.Error =
      object.Error !== undefined && object.Error !== null
        ? Error.fromPartial(object.Error)
        : undefined;
    message.CurrentStep = object.CurrentStep ?? 0;
    message.Objective = object.Objective ?? 0;
    message.Steps =
      object.Steps?.map((e) => AuthState_AuthStep.fromPartial(e)) || [];
    message.CreatedAt = object.CreatedAt ?? undefined;
    message.ExpiresAt = object.ExpiresAt ?? undefined;
    return message;
  },
};

function createBaseAuthState_AuthMethodOptionsOAuth(): AuthState_AuthMethodOptionsOAuth {
  return { RedirectURL: '' };
}

export const AuthState_AuthMethodOptionsOAuth = {
  encode(
    message: AuthState_AuthMethodOptionsOAuth,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.RedirectURL !== '') {
      writer.uint32(10).string(message.RedirectURL);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AuthState_AuthMethodOptionsOAuth {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthState_AuthMethodOptionsOAuth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.RedirectURL = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AuthState_AuthMethodOptionsOAuth {
    return {
      RedirectURL: isSet(object.RedirectURL) ? String(object.RedirectURL) : '',
    };
  },

  toJSON(message: AuthState_AuthMethodOptionsOAuth): unknown {
    const obj: any = {};
    message.RedirectURL !== undefined &&
      (obj.RedirectURL = message.RedirectURL);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AuthState_AuthMethodOptionsOAuth>, I>,
  >(object: I): AuthState_AuthMethodOptionsOAuth {
    const message = createBaseAuthState_AuthMethodOptionsOAuth();
    message.RedirectURL = object.RedirectURL ?? '';
    return message;
  },
};

function createBaseAuthState_AuthMethodOptionsWebAuthn(): AuthState_AuthMethodOptionsWebAuthn {
  return { ChallengeData: '' };
}

export const AuthState_AuthMethodOptionsWebAuthn = {
  encode(
    message: AuthState_AuthMethodOptionsWebAuthn,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ChallengeData !== '') {
      writer.uint32(10).string(message.ChallengeData);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AuthState_AuthMethodOptionsWebAuthn {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthState_AuthMethodOptionsWebAuthn();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ChallengeData = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AuthState_AuthMethodOptionsWebAuthn {
    return {
      ChallengeData: isSet(object.ChallengeData)
        ? String(object.ChallengeData)
        : '',
    };
  },

  toJSON(message: AuthState_AuthMethodOptionsWebAuthn): unknown {
    const obj: any = {};
    message.ChallengeData !== undefined &&
      (obj.ChallengeData = message.ChallengeData);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AuthState_AuthMethodOptionsWebAuthn>, I>,
  >(object: I): AuthState_AuthMethodOptionsWebAuthn {
    const message = createBaseAuthState_AuthMethodOptionsWebAuthn();
    message.ChallengeData = object.ChallengeData ?? '';
    return message;
  },
};

function createBaseAuthState_AuthStep(): AuthState_AuthStep {
  return {
    PreferredAuthMethod: 0,
    AlternativeAuthMethods: [],
    None: undefined,
    AuthMethodOptionsOAuth: undefined,
    AuthMethodOptionsWebAuthn: undefined,
  };
}

export const AuthState_AuthStep = {
  encode(
    message: AuthState_AuthStep,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PreferredAuthMethod !== 0) {
      writer.uint32(800).int32(message.PreferredAuthMethod);
    }
    writer.uint32(810).fork();
    for (const v of message.AlternativeAuthMethods) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.None !== undefined) {
      Empty.encode(message.None, writer.uint32(1602).fork()).ldelim();
    }
    if (message.AuthMethodOptionsOAuth !== undefined) {
      AuthState_AuthMethodOptionsOAuth.encode(
        message.AuthMethodOptionsOAuth,
        writer.uint32(1610).fork(),
      ).ldelim();
    }
    if (message.AuthMethodOptionsWebAuthn !== undefined) {
      AuthState_AuthMethodOptionsWebAuthn.encode(
        message.AuthMethodOptionsWebAuthn,
        writer.uint32(1618).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthState_AuthStep {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthState_AuthStep();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 100:
          message.PreferredAuthMethod = reader.int32() as any;
          break;
        case 101:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.AlternativeAuthMethods.push(reader.int32() as any);
            }
          } else {
            message.AlternativeAuthMethods.push(reader.int32() as any);
          }
          break;
        case 200:
          message.None = Empty.decode(reader, reader.uint32());
          break;
        case 201:
          message.AuthMethodOptionsOAuth =
            AuthState_AuthMethodOptionsOAuth.decode(reader, reader.uint32());
          break;
        case 202:
          message.AuthMethodOptionsWebAuthn =
            AuthState_AuthMethodOptionsWebAuthn.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AuthState_AuthStep {
    return {
      PreferredAuthMethod: isSet(object.PreferredAuthMethod)
        ? authMethodFromJSON(object.PreferredAuthMethod)
        : 0,
      AlternativeAuthMethods: Array.isArray(object?.AlternativeAuthMethods)
        ? object.AlternativeAuthMethods.map((e: any) => authMethodFromJSON(e))
        : [],
      None: isSet(object.None) ? Empty.fromJSON(object.None) : undefined,
      AuthMethodOptionsOAuth: isSet(object.AuthMethodOptionsOAuth)
        ? AuthState_AuthMethodOptionsOAuth.fromJSON(
            object.AuthMethodOptionsOAuth,
          )
        : undefined,
      AuthMethodOptionsWebAuthn: isSet(object.AuthMethodOptionsWebAuthn)
        ? AuthState_AuthMethodOptionsWebAuthn.fromJSON(
            object.AuthMethodOptionsWebAuthn,
          )
        : undefined,
    };
  },

  toJSON(message: AuthState_AuthStep): unknown {
    const obj: any = {};
    message.PreferredAuthMethod !== undefined &&
      (obj.PreferredAuthMethod = authMethodToJSON(message.PreferredAuthMethod));
    if (message.AlternativeAuthMethods) {
      obj.AlternativeAuthMethods = message.AlternativeAuthMethods.map((e) =>
        authMethodToJSON(e),
      );
    } else {
      obj.AlternativeAuthMethods = [];
    }
    message.None !== undefined &&
      (obj.None = message.None ? Empty.toJSON(message.None) : undefined);
    message.AuthMethodOptionsOAuth !== undefined &&
      (obj.AuthMethodOptionsOAuth = message.AuthMethodOptionsOAuth
        ? AuthState_AuthMethodOptionsOAuth.toJSON(
            message.AuthMethodOptionsOAuth,
          )
        : undefined);
    message.AuthMethodOptionsWebAuthn !== undefined &&
      (obj.AuthMethodOptionsWebAuthn = message.AuthMethodOptionsWebAuthn
        ? AuthState_AuthMethodOptionsWebAuthn.toJSON(
            message.AuthMethodOptionsWebAuthn,
          )
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AuthState_AuthStep>, I>>(
    object: I,
  ): AuthState_AuthStep {
    const message = createBaseAuthState_AuthStep();
    message.PreferredAuthMethod = object.PreferredAuthMethod ?? 0;
    message.AlternativeAuthMethods =
      object.AlternativeAuthMethods?.map((e) => e) || [];
    message.None =
      object.None !== undefined && object.None !== null
        ? Empty.fromPartial(object.None)
        : undefined;
    message.AuthMethodOptionsOAuth =
      object.AuthMethodOptionsOAuth !== undefined &&
      object.AuthMethodOptionsOAuth !== null
        ? AuthState_AuthMethodOptionsOAuth.fromPartial(
            object.AuthMethodOptionsOAuth,
          )
        : undefined;
    message.AuthMethodOptionsWebAuthn =
      object.AuthMethodOptionsWebAuthn !== undefined &&
      object.AuthMethodOptionsWebAuthn !== null
        ? AuthState_AuthMethodOptionsWebAuthn.fromPartial(
            object.AuthMethodOptionsWebAuthn,
          )
        : undefined;
    return message;
  },
};

function createBaseToken(): Token {
  return { Value: '' };
}

export const Token = {
  encode(message: Token, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Value !== '') {
      writer.uint32(10).string(message.Value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Token {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToken();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Token {
    return {
      Value: isSet(object.Value) ? String(object.Value) : '',
    };
  },

  toJSON(message: Token): unknown {
    const obj: any = {};
    message.Value !== undefined && (obj.Value = message.Value);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Token>, I>>(object: I): Token {
    const message = createBaseToken();
    message.Value = object.Value ?? '';
    return message;
  },
};

function createBaseSecret(): Secret {
  return { Value: '' };
}

export const Secret = {
  encode(
    message: Secret,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Value !== '') {
      writer.uint32(10).string(message.Value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Secret {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecret();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Secret {
    return {
      Value: isSet(object.Value) ? String(object.Value) : '',
    };
  },

  toJSON(message: Secret): unknown {
    const obj: any = {};
    message.Value !== undefined && (obj.Value = message.Value);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Secret>, I>>(object: I): Secret {
    const message = createBaseSecret();
    message.Value = object.Value ?? '';
    return message;
  },
};

function createBaseError(): Error {
  return { Code: 0, Message: '' };
}

export const Error = {
  encode(message: Error, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Code !== 0) {
      writer.uint32(8).int64(message.Code);
    }
    if (message.Message !== '') {
      writer.uint32(18).string(message.Message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Error {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Code = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.Message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Error {
    return {
      Code: isSet(object.Code) ? Number(object.Code) : 0,
      Message: isSet(object.Message) ? String(object.Message) : '',
    };
  },

  toJSON(message: Error): unknown {
    const obj: any = {};
    message.Code !== undefined && (obj.Code = Math.round(message.Code));
    message.Message !== undefined && (obj.Message = message.Message);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Error>, I>>(object: I): Error {
    const message = createBaseError();
    message.Code = object.Code ?? 0;
    message.Message = object.Message ?? '';
    return message;
  },
};

function createBasePersonalInfo(): PersonalInfo {
  return { FirstName: '', LastName: '', Language: 0, Country: 0 };
}

export const PersonalInfo = {
  encode(
    message: PersonalInfo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FirstName !== '') {
      writer.uint32(10).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(18).string(message.LastName);
    }
    if (message.Language !== 0) {
      writer.uint32(24).int32(message.Language);
    }
    if (message.Country !== 0) {
      writer.uint32(32).int32(message.Country);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PersonalInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePersonalInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FirstName = reader.string();
          break;
        case 2:
          message.LastName = reader.string();
          break;
        case 3:
          message.Language = reader.int32() as any;
          break;
        case 4:
          message.Country = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PersonalInfo {
    return {
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      Language: isSet(object.Language) ? languageFromJSON(object.Language) : 0,
      Country: isSet(object.Country) ? countryFromJSON(object.Country) : 0,
    };
  },

  toJSON(message: PersonalInfo): unknown {
    const obj: any = {};
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    message.Language !== undefined &&
      (obj.Language = languageToJSON(message.Language));
    message.Country !== undefined &&
      (obj.Country = countryToJSON(message.Country));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PersonalInfo>, I>>(
    object: I,
  ): PersonalInfo {
    const message = createBasePersonalInfo();
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.Language = object.Language ?? 0;
    message.Country = object.Country ?? 0;
    return message;
  },
};

function createBasePhoneAuthOptions(): PhoneAuthOptions {
  return { UsePhoneCallerNumber: false, PhoneNumber: '' };
}

export const PhoneAuthOptions = {
  encode(
    message: PhoneAuthOptions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UsePhoneCallerNumber === true) {
      writer.uint32(8).bool(message.UsePhoneCallerNumber);
    }
    if (message.PhoneNumber !== '') {
      writer.uint32(18).string(message.PhoneNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PhoneAuthOptions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePhoneAuthOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UsePhoneCallerNumber = reader.bool();
          break;
        case 2:
          message.PhoneNumber = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PhoneAuthOptions {
    return {
      UsePhoneCallerNumber: isSet(object.UsePhoneCallerNumber)
        ? Boolean(object.UsePhoneCallerNumber)
        : false,
      PhoneNumber: isSet(object.PhoneNumber) ? String(object.PhoneNumber) : '',
    };
  },

  toJSON(message: PhoneAuthOptions): unknown {
    const obj: any = {};
    message.UsePhoneCallerNumber !== undefined &&
      (obj.UsePhoneCallerNumber = message.UsePhoneCallerNumber);
    message.PhoneNumber !== undefined &&
      (obj.PhoneNumber = message.PhoneNumber);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PhoneAuthOptions>, I>>(
    object: I,
  ): PhoneAuthOptions {
    const message = createBasePhoneAuthOptions();
    message.UsePhoneCallerNumber = object.UsePhoneCallerNumber ?? false;
    message.PhoneNumber = object.PhoneNumber ?? '';
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
