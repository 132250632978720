import {
  addPatientTeamMember,
  revokePatientTeamAccess,
} from './model/accessSlice.thunks';

export const accessModel = {
  thunks: {
    addPatientTeamMember,
    revokePatientTeamAccess,
  },
};
