import { includes, prop } from 'ramda';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { ConditionGroups, CONDITION_CODES_BY_GROUPS } from '../config/const';

export const isConditionInGroup =
  (groupCode: ConditionGroups) => (condition: Condition) =>
    includes(
      prop<keyof Condition>('ID')(condition),
      prop(groupCode)(CONDITION_CODES_BY_GROUPS),
    );
