import React, { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl/lib';

import { Column, Description } from '@/shared/ui';
import { SliceInterface } from '@/entities/assets';

import { GroupSlicesList } from '../GroupSlicesList/GroupSlicesList';
import { sliceTitles } from '../../config/i18n';
import { GPSliceGroupsTypes, Group } from '../../config/types';
import GuidesList from '../GuidesList/GuidesList';

import styles from './SliceGroupSection.module.scss';

type SliceGroupSectionProps = {
  group: Group;
  onDragSlice?: (src: string) => void;
  selectedSlices?: SliceInterface[];
  toggleSelected?: (src: string) => void;
  className?: string;
  slices?: SliceInterface[];
};

export const SliceGroupSection: FC<SliceGroupSectionProps> = (props) => {
  const { className, group, selectedSlices, toggleSelected, onDragSlice } =
    props;
  const [hoveredGuide, setHoveredGuide] = useState(-1);
  const [zoomedImg, setZoomedImg] = useState(null);
  const [slices, setSlices] = useState([]);

  const { formatMessage } = useIntl();

  const handleZoomImg = useCallback(() => {
    setZoomedImg(hoveredGuide);
  }, [hoveredGuide]);

  const title = formatMessage(sliceTitles[group.type as GPSliceGroupsTypes]);

  useEffect(() => {
    if (group?.slices.length && !slices.length) {
      setSlices(group.slices);
    }
  }, [group.slices]);

  if (!group?.guides?.length && !group?.slices?.length) {
    return null;
  }
  const { thicknessMm, strideMm } = group.slices[0];

  const hasSlices = !!(group.slices.length && slices.length);

  const shouldShowSliceThinknessAndStride =
    group.type !== 'Caries' && group.type !== 'PeriapicalLesion';

  return (
    <>
      <div>
        <h3 className={styles.title}>{title}</h3>
        {shouldShowSliceThinknessAndStride && (
          <>
            <Description
              label={
                <FormattedMessage
                  id="report.slice_step"
                  defaultMessage="Slice step"
                />
              }
            >
              {thicknessMm}
              <FormattedMessage id="global.mm" defaultMessage="mm" />
            </Description>{' '}
            <Description
              label={
                <FormattedMessage
                  id="report.slice_thickness"
                  defaultMessage="Slice thickness"
                />
              }
            >
              {strideMm}
              <FormattedMessage id="global.mm" defaultMessage="mm" />
            </Description>
          </>
        )}
      </div>
      <div className={styles.sliceSection}>
        {!!group?.guides?.length && (
          <Column className={cn(styles.container, className)}>
            <GuidesList
              group={group}
              hoveredGuide={hoveredGuide}
              handleZoomImg={handleZoomImg}
              setHoveredGuide={setHoveredGuide}
            />
          </Column>
        )}
        <Column className={styles.slicesColumn}>
          {hasSlices && (
            <GroupSlicesList
              slices={slices}
              zoomedIndex={zoomedImg}
              focusedIndex={hoveredGuide}
              selectedSlices={selectedSlices}
              toggleSelected={toggleSelected}
              onHoveredGuideChanged={setHoveredGuide}
              onDragSlice={onDragSlice}
            />
          )}
        </Column>
      </div>
    </>
  );
};
