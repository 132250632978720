import { filter } from 'ramda';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { ReferralsGroupsType } from '../config/referralsGroups.type';

import { isConditionCodeIncludeInThisReferralGroup } from './isConditionCodeIncludeInThisReferralGroup';

export const filterConditionByReferral = (
  conditions: Condition[],
  referralGroup: ReferralsGroupsType,
) =>
  filter(
    (condition) =>
      isConditionCodeIncludeInThisReferralGroup(condition.Code, referralGroup),
    conditions,
  );
