import { ContextRequest, ProcessingUnitContext } from 'graphics';
import { useState } from 'react';
import { AbsoluteBlock } from '../AbsoluteBlock';
import { Annotation } from '../AnnotationsLayer/Annotation';
import { AnnotationsLayer } from '../AnnotationsLayer/AnnotationsLayer';
import { MedicalImage } from '../MedicalImage/MedicalImage';
import { ObjectFitContain } from '../ObjectFitContain';
import { TouchableLayer } from '../TouchableLayer/TouchableLayer';
import { AnnotationOrEraser } from './DicomImageWithAnnotations';

export type UltimateMedicalImageMode =
  | 'view'
  | 'annotate'
  | 'sharpening'
  | 'windowing';

export type UltimateMedicalImageProps = {
  src: ContextRequest;
  width: number;
  height: number;
  ww: number;
  wc: number;
  sharpness: number;
  inverted: boolean;
  mode: UltimateMedicalImageMode;
  annotations: Annotation[];
  mask?: ContextRequest;
  currentAnnotation: AnnotationOrEraser;
  onAnnotationsChanged: React.Dispatch<React.SetStateAction<Annotation[]>>;
  onSharpnessChanged: React.Dispatch<React.SetStateAction<number>>;
  onWWWCChanged: React.Dispatch<
    React.SetStateAction<{ ww: number; wc: number }>
  >;
  onLoaded: React.Dispatch<ProcessingUnitContext>;
  className?: string;
};

export const UltimateMedicalImage = ({
  src,
  mode,
  annotations,
  currentAnnotation,
  onAnnotationsChanged,
  onWWWCChanged,
  onSharpnessChanged,
  onLoaded,
  width,
  height,
  ww,
  wc,
  mask,
  sharpness,
  inverted,
  className,
}: UltimateMedicalImageProps) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [pixelSpacing, setPixelSpacing] = useState<
    [number, number] | undefined
  >([1, 1]);

  return (
    <div
      style={{
        position: 'relative',
        width,
        height,
      }}
      className={className}
    >
      <AbsoluteBlock data-id="MedicalImage">
        <MedicalImage
          src={src}
          uniforms={{ ww, wc, sharpness, invert: inverted }}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
          onLoaded={(context) => {
            onLoaded(context);
            setImageSize({ width: context.width, height: context.height });
            setPixelSpacing(context.pixelSpacing);
          }}
        />
      </AbsoluteBlock>
      {mask && (
        <AbsoluteBlock data-id="MedicalImage Mask">
          <MedicalImage
            src={mask}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </AbsoluteBlock>
      )}
      <AbsoluteBlock data-id="Annotations">
        <ObjectFitContain
          objectWidth={imageSize.width}
          objectHeight={imageSize.height}
        >
          <AnnotationsLayer
            pixelSpacing={pixelSpacing}
            annotations={annotations}
            annotationKind={currentAnnotation}
            setAnnotations={onAnnotationsChanged}
            editable={mode === 'annotate'}
          />
        </ObjectFitContain>
      </AbsoluteBlock>
      {mode === 'windowing' && (
        <>
          <AbsoluteBlock data-id="TouchableLayer Windowing">
            <ObjectFitContain
              objectWidth={imageSize.width}
              objectHeight={imageSize.height}
            >
              <TouchableLayer
                onDrag={(x1, y1, x2, y2, dx: number, dy: number) => {
                  onWWWCChanged((state) => ({
                    ww: state.ww + dx * 10,
                    wc: state.wc + dy * 10,
                  }));
                }}
              />
            </ObjectFitContain>
          </AbsoluteBlock>
        </>
      )}
      {mode === 'sharpening' && (
        <>
          <AbsoluteBlock data-id="TouchableLayer Sharpening">
            <ObjectFitContain
              objectWidth={imageSize.width}
              objectHeight={imageSize.height}
            >
              <TouchableLayer
                onDrag={(x1, y1, x2, y2, dx: number, dy: number) => {
                  onSharpnessChanged((state) => state + dx * 0.01);
                }}
              />
            </ObjectFitContain>
          </AbsoluteBlock>
        </>
      )}
    </div>
  );
};
