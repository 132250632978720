import { FC } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';

import { Column, Icon, Layout } from '@/shared/ui/';
import { Header } from '@/widgets/Header';
import { PATHS } from '@/shared/config';
import { useReportDataStream } from '@/features/reportStream';

import styles from './PreviewReport.module.scss';
import { SettingsBar } from './ui/SettingsBar/SettingsBar';
import { PreviewDocument } from './ui/PreviewDocument/PreviewDocument';
import { usePreviewSettings } from './hooks/usePreviewSettings';

type PreviewReportProps = {};

export const PreviewReport: FC<PreviewReportProps> = () => {
  const { patientID, reportID } = useParams();
  const { settings, handlers } = usePreviewSettings();

  useReportDataStream(reportID);

  return (
    <Layout>
      <Layout.Header className={styles.header}>
        <Header />
      </Layout.Header>

      <Layout.Content className={styles.content}>
        <Layout.Sider className={styles.sider}>
          <SettingsBar settings={settings} handlers={handlers} />
        </Layout.Sider>

        <Layout.Main>
          <nav className={styles.nav}>
            <Link
              to={generatePath(PATHS.report, { patientID, reportID })}
              className={styles.backLink}
            >
              <Icon
                className={styles.backLinkIcon}
                name="arrowDown"
                size={32}
              />

              <span className="p1">
                <FormattedMessage
                  id="previewReport.backToReport"
                  defaultMessage="Back to report"
                />
              </span>
            </Link>
          </nav>

          <Column>
            <PreviewDocument settings={settings} />
          </Column>
        </Layout.Main>
      </Layout.Content>
      <Layout.Footer />
    </Layout>
  );
};
