import { FC } from 'react';
import cn from 'classnames';
import { generatePath, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { format } from 'date-fns';

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';
import { Icon } from '@/shared/ui';
import { useAppSelector, useMedia } from '@/shared/hooks';
import {
  DentalNotations,
  getDisplayToothNumber,
  toothModel,
} from '@/entities/tooth';
import {
  getIsAllTeethInROI,
  getReportSignStatus,
  REPORT_DATE_FORMAT,
} from '@/entities/reports';

import { RoiColumn } from '../RoiColumn/RoiColumn';
import { getReportPathType } from '../../helpers/getReportPathType';

import styles from './ReportRow.module.scss';

type ReportRowProps = {
  report: Report;
  reportType?: ReportType;
  className?: string;
  testID?: string;
  onDelete?: () => void;
  onDownload?: () => void;
};

export const ReportRow: FC<ReportRowProps> = (props) => {
  const { report, reportType, className, testID, onDelete, onDownload } = props;

  const { patientID } = useParams();

  const { isPhone } = useMedia();

  const size = isPhone ? 24 : 32;

  const reportID = report.ID;

  const ROI = useAppSelector((state) =>
    toothModel.selectors.selectISONumbersByReportIDAndROI(state, reportID),
  );

  const reportTeeth = useAppSelector((state) =>
    toothModel.selectors.selectByReportID(state, reportID),
  );

  const mockDentalNotation = DentalNotations.UNIVERSAL;

  const displayROI = ROI.map((ISOToothNumber) =>
    getDisplayToothNumber(ISOToothNumber, mockDentalNotation),
  );

  const isAllTeethInROI = getIsAllTeethInROI(reportTeeth);

  const signStatus = getReportSignStatus(report);

  const linkToReport = generatePath(getReportPathType(reportType), {
    patientID,
    reportID,
  });

  const handleDownloadReport = () => {
    onDownload();
  };

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      <Link className={cn(styles.link)} to={linkToReport}>
        <div
          className={cn(
            styles.linkWrapper,
            signStatus === 'withoutSign' && styles.gridWithoutSign,
          )}
        >
          <div className={cn(styles.column)}>
            <span className={cn(styles.name, 'p3')}>
              <FormattedMessage
                id="reportRow.createdAt"
                defaultMessage="Creation date"
              />
            </span>
            <p className={cn(styles.date, 'p2')}>
              {format(report.Created.At, REPORT_DATE_FORMAT)}
            </p>
          </div>
          {!isPhone && (
            <div className={cn(styles.column)}>
              <span className={cn(styles.name, 'p3')}>
                <FormattedMessage
                  id="reportRow.roi"
                  defaultMessage="Region of interest"
                />
              </span>
              <RoiColumn roi={displayROI} isAllTeethInROI={isAllTeethInROI} />
            </div>
          )}
          {signStatus !== 'withoutSign' && (
            <div className={cn(styles.column)}>
              <span className={cn(styles.name, 'p3')}>
                <FormattedMessage
                  id="reportRow.status"
                  defaultMessage="Status"
                />
              </span>
              {signStatus === 'signed' && (
                <p className={cn(styles.info, styles.signed)}>
                  <Icon name="check" size={24} />
                  <span className="p2">
                    <FormattedMessage
                      id="reportRow.signed"
                      defaultMessage="Signed"
                    />
                  </span>
                </p>
              )}
              {signStatus === 'notSigned' && (
                <p className={cn(styles.info, styles.notSigned)}>
                  <Icon name="time" size={24} />
                  <span className="p2">
                    <FormattedMessage
                      id="reportRow.notSigned"
                      defaultMessage="Not signed"
                    />
                  </span>
                </p>
              )}
            </div>
          )}
        </div>
      </Link>
      <div className={cn(styles.column, styles.lastColumn)}>
        <p className={cn(styles.controls)}>
          <button
            className={cn(styles.button, styles.downloadButton)}
            onClick={handleDownloadReport}
            type="button"
          >
            <Icon name="download" size={size} />
          </button>
          <button
            className={cn(styles.button, styles.deleteButton)}
            type="button"
            onClick={onDelete}
          >
            <Icon name="delete" size={size} />
          </button>
        </p>
      </div>
    </div>
  );
};
