import { defineMessages } from 'react-intl';

import { OrganizationSpecialization } from '@/shared/api/protocol_gen/model/dto_organization';

import {
  ReferralsGroupsByStudyType,
  ReferralsGroupsType,
} from './referralsGroups.type';

export const referralRecomendationCodesType =
  defineMessages<ReferralsGroupsType>({
    [OrganizationSpecialization.OrganizationSpecializationGeneral]: {
      id: 'referralGroup.gp',
      defaultMessage: 'General Practitioner',
    },
    [OrganizationSpecialization.OrganizationSpecializationPeriodontologist]: {
      id: 'referralGroup.periodontist',
      defaultMessage: 'Periodontist',
    },
    [OrganizationSpecialization.OrganizationSpecializationProsthodontist]: {
      id: 'referralGroup.prosthodontist',
      defaultMessage: 'Prosthodontist',
    },
    [OrganizationSpecialization.OrganizationSpecializationOrthodontist]: {
      id: 'referralGroup.orthodontist',
      defaultMessage: 'Orthodontist',
    },
    [OrganizationSpecialization.OrganizationSpecializationEndodontist]: {
      id: 'referralGroup.endodontist',
      defaultMessage: 'Endodontist',
    },
    [ReferralsGroupsByStudyType.FMXAndPano]: {
      id: 'referralGroup.FMXAndPano',
      defaultMessage: 'FMX and Pano',
    },
    [ReferralsGroupsByStudyType.CBCT]: {
      id: 'referralGroup.CBCT',
      defaultMessage: 'CBCT',
    },
  });
