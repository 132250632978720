import React, { useState, useEffect } from 'react';

export interface InputHookType {
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  resetValue: () => void;
  clearValue: () => void;
  isChanged: boolean;
  isValid: boolean;
}

export const useInput = (
  initialValue: string,
  validate?: (s: string) => boolean,
  externalValue?: string,
): InputHookType => {
  const [value, setValue] = useState(initialValue);
  const [isChanged, setIsChanged] = useState(false);
  const [isValid, setIsValid] = useState(
    validate ? validate(initialValue) : false,
  );

  useEffect(() => {
    if (initialValue && !isChanged) {
      setValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (typeof externalValue !== 'undefined') {
      setValue(externalValue || '');
    }
  }, [externalValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!isChanged) {
      setIsChanged(true);
    }
    setValue(e.target.value);
    if (validate) {
      setIsValid(validate(e.target.value));
    }
  };

  const resetValue = (): void => {
    setValue(initialValue);
    if (validate) {
      setIsValid(validate(initialValue));
    }
  };

  const clearValue = (): void => {
    setValue('');
  };

  return {
    value,
    onChange: handleChange,
    resetValue,
    clearValue,
    isChanged,
    isValid,
  };
};
