// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddDoctorsModal-module__container--sNo-707h{margin-top:90px}.AddDoctorsModal-module__form--fopbPuxs{display:flex;flex-direction:column;gap:16px}.AddDoctorsModal-module__formGlobalError--yoxLE4uZ{text-align:center}", "",{"version":3,"sources":["webpack://./src/features/addDoctors/ui/AddDoctorsModal/AddDoctorsModal.module.scss"],"names":[],"mappings":"AAAA,6CACE,eAAA,CAGF,wCACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,mDACE,iBAAA","sourcesContent":[".container {\n  margin-top: 90px;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.formGlobalError {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AddDoctorsModal-module__container--sNo-707h",
	"form": "AddDoctorsModal-module__form--fopbPuxs",
	"formGlobalError": "AddDoctorsModal-module__formGlobalError--yoxLE4uZ"
};
export default ___CSS_LOADER_EXPORT___;
