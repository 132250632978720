/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { DerivedData3DModel, File } from '../model/dto_asset_common';
import {
  CBCTAnatomy,
  cBCTAnatomyFromJSON,
  cBCTAnatomyToJSON,
} from '../model/dto_report_common';

export const protobufPackage = 'com.diagnocat.model';

export interface AssetContentGeneratedCBCTSegmentedModel {
  Segments: AssetContentGeneratedCBCTSegmentedModel_Segment[];
  /** Not used in pipelines. */
  Derived: DerivedData3DModel | undefined;
}

export interface AssetContentGeneratedCBCTSegmentedModel_Segment {
  ID: string;
  Structures: AssetContentGeneratedCBCTSegmentedModel_Segment_Structure[];
  DracoModel: File | undefined;
  /** If `STLModel` is not specified the file was not created. */
  STLModel: File | undefined;
  Hidden: boolean;
  Color: string;
}

export interface AssetContentGeneratedCBCTSegmentedModel_Segment_Structure {
  IsSegmented: boolean;
  CBCTTooth:
    | AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth
    | undefined;
  Anatomy: CBCTAnatomy | undefined;
}

export interface AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth {
  ToothNumberISO: number;
  SupernumeraryIndex: number;
}

function createBaseAssetContentGeneratedCBCTSegmentedModel(): AssetContentGeneratedCBCTSegmentedModel {
  return { Segments: [], Derived: undefined };
}

export const AssetContentGeneratedCBCTSegmentedModel = {
  encode(
    message: AssetContentGeneratedCBCTSegmentedModel,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Segments) {
      AssetContentGeneratedCBCTSegmentedModel_Segment.encode(
        v!,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedData3DModel.encode(
        message.Derived,
        writer.uint32(802).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTSegmentedModel {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTSegmentedModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Segments.push(
            AssetContentGeneratedCBCTSegmentedModel_Segment.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        case 100:
          message.Derived = DerivedData3DModel.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTSegmentedModel {
    return {
      Segments: Array.isArray(object?.Segments)
        ? object.Segments.map((e: any) =>
            AssetContentGeneratedCBCTSegmentedModel_Segment.fromJSON(e),
          )
        : [],
      Derived: isSet(object.Derived)
        ? DerivedData3DModel.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTSegmentedModel): unknown {
    const obj: any = {};
    if (message.Segments) {
      obj.Segments = message.Segments.map((e) =>
        e
          ? AssetContentGeneratedCBCTSegmentedModel_Segment.toJSON(e)
          : undefined,
      );
    } else {
      obj.Segments = [];
    }
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedData3DModel.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTSegmentedModel>, I>,
  >(object: I): AssetContentGeneratedCBCTSegmentedModel {
    const message = createBaseAssetContentGeneratedCBCTSegmentedModel();
    message.Segments =
      object.Segments?.map((e) =>
        AssetContentGeneratedCBCTSegmentedModel_Segment.fromPartial(e),
      ) || [];
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedData3DModel.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTSegmentedModel_Segment(): AssetContentGeneratedCBCTSegmentedModel_Segment {
  return {
    ID: '',
    Structures: [],
    DracoModel: undefined,
    STLModel: undefined,
    Hidden: false,
    Color: '',
  };
}

export const AssetContentGeneratedCBCTSegmentedModel_Segment = {
  encode(
    message: AssetContentGeneratedCBCTSegmentedModel_Segment,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    for (const v of message.Structures) {
      AssetContentGeneratedCBCTSegmentedModel_Segment_Structure.encode(
        v!,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.DracoModel !== undefined) {
      File.encode(message.DracoModel, writer.uint32(26).fork()).ldelim();
    }
    if (message.STLModel !== undefined) {
      File.encode(message.STLModel, writer.uint32(34).fork()).ldelim();
    }
    if (message.Hidden === true) {
      writer.uint32(40).bool(message.Hidden);
    }
    if (message.Color !== '') {
      writer.uint32(50).string(message.Color);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTSegmentedModel_Segment {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTSegmentedModel_Segment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Structures.push(
            AssetContentGeneratedCBCTSegmentedModel_Segment_Structure.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        case 3:
          message.DracoModel = File.decode(reader, reader.uint32());
          break;
        case 4:
          message.STLModel = File.decode(reader, reader.uint32());
          break;
        case 5:
          message.Hidden = reader.bool();
          break;
        case 6:
          message.Color = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTSegmentedModel_Segment {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Structures: Array.isArray(object?.Structures)
        ? object.Structures.map((e: any) =>
            AssetContentGeneratedCBCTSegmentedModel_Segment_Structure.fromJSON(
              e,
            ),
          )
        : [],
      DracoModel: isSet(object.DracoModel)
        ? File.fromJSON(object.DracoModel)
        : undefined,
      STLModel: isSet(object.STLModel)
        ? File.fromJSON(object.STLModel)
        : undefined,
      Hidden: isSet(object.Hidden) ? Boolean(object.Hidden) : false,
      Color: isSet(object.Color) ? String(object.Color) : '',
    };
  },

  toJSON(message: AssetContentGeneratedCBCTSegmentedModel_Segment): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    if (message.Structures) {
      obj.Structures = message.Structures.map((e) =>
        e
          ? AssetContentGeneratedCBCTSegmentedModel_Segment_Structure.toJSON(e)
          : undefined,
      );
    } else {
      obj.Structures = [];
    }
    message.DracoModel !== undefined &&
      (obj.DracoModel = message.DracoModel
        ? File.toJSON(message.DracoModel)
        : undefined);
    message.STLModel !== undefined &&
      (obj.STLModel = message.STLModel
        ? File.toJSON(message.STLModel)
        : undefined);
    message.Hidden !== undefined && (obj.Hidden = message.Hidden);
    message.Color !== undefined && (obj.Color = message.Color);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedCBCTSegmentedModel_Segment>,
      I
    >,
  >(object: I): AssetContentGeneratedCBCTSegmentedModel_Segment {
    const message = createBaseAssetContentGeneratedCBCTSegmentedModel_Segment();
    message.ID = object.ID ?? '';
    message.Structures =
      object.Structures?.map((e) =>
        AssetContentGeneratedCBCTSegmentedModel_Segment_Structure.fromPartial(
          e,
        ),
      ) || [];
    message.DracoModel =
      object.DracoModel !== undefined && object.DracoModel !== null
        ? File.fromPartial(object.DracoModel)
        : undefined;
    message.STLModel =
      object.STLModel !== undefined && object.STLModel !== null
        ? File.fromPartial(object.STLModel)
        : undefined;
    message.Hidden = object.Hidden ?? false;
    message.Color = object.Color ?? '';
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTSegmentedModel_Segment_Structure(): AssetContentGeneratedCBCTSegmentedModel_Segment_Structure {
  return { IsSegmented: false, CBCTTooth: undefined, Anatomy: undefined };
}

export const AssetContentGeneratedCBCTSegmentedModel_Segment_Structure = {
  encode(
    message: AssetContentGeneratedCBCTSegmentedModel_Segment_Structure,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IsSegmented === true) {
      writer.uint32(8).bool(message.IsSegmented);
    }
    if (message.CBCTTooth !== undefined) {
      AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth.encode(
        message.CBCTTooth,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Anatomy !== undefined) {
      writer.uint32(24).int32(message.Anatomy);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTSegmentedModel_Segment_Structure {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseAssetContentGeneratedCBCTSegmentedModel_Segment_Structure();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IsSegmented = reader.bool();
          break;
        case 2:
          message.CBCTTooth =
            AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 3:
          message.Anatomy = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): AssetContentGeneratedCBCTSegmentedModel_Segment_Structure {
    return {
      IsSegmented: isSet(object.IsSegmented)
        ? Boolean(object.IsSegmented)
        : false,
      CBCTTooth: isSet(object.CBCTTooth)
        ? AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth.fromJSON(
            object.CBCTTooth,
          )
        : undefined,
      Anatomy: isSet(object.Anatomy)
        ? cBCTAnatomyFromJSON(object.Anatomy)
        : undefined,
    };
  },

  toJSON(
    message: AssetContentGeneratedCBCTSegmentedModel_Segment_Structure,
  ): unknown {
    const obj: any = {};
    message.IsSegmented !== undefined &&
      (obj.IsSegmented = message.IsSegmented);
    message.CBCTTooth !== undefined &&
      (obj.CBCTTooth = message.CBCTTooth
        ? AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth.toJSON(
            message.CBCTTooth,
          )
        : undefined);
    message.Anatomy !== undefined &&
      (obj.Anatomy =
        message.Anatomy !== undefined
          ? cBCTAnatomyToJSON(message.Anatomy)
          : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedCBCTSegmentedModel_Segment_Structure>,
      I
    >,
  >(object: I): AssetContentGeneratedCBCTSegmentedModel_Segment_Structure {
    const message =
      createBaseAssetContentGeneratedCBCTSegmentedModel_Segment_Structure();
    message.IsSegmented = object.IsSegmented ?? false;
    message.CBCTTooth =
      object.CBCTTooth !== undefined && object.CBCTTooth !== null
        ? AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth.fromPartial(
            object.CBCTTooth,
          )
        : undefined;
    message.Anatomy = object.Anatomy ?? undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth(): AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth {
  return { ToothNumberISO: 0, SupernumeraryIndex: 0 };
}

export const AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth =
  {
    encode(
      message: AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth,
      writer: _m0.Writer = _m0.Writer.create(),
    ): _m0.Writer {
      if (message.ToothNumberISO !== 0) {
        writer.uint32(8).uint32(message.ToothNumberISO);
      }
      if (message.SupernumeraryIndex !== 0) {
        writer.uint32(16).uint32(message.SupernumeraryIndex);
      }
      return writer;
    },

    decode(
      input: _m0.Reader | Uint8Array,
      length?: number,
    ): AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth {
      const reader =
        input instanceof _m0.Reader ? input : new _m0.Reader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message =
        createBaseAssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth();
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.ToothNumberISO = reader.uint32();
            break;
          case 2:
            message.SupernumeraryIndex = reader.uint32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    },

    fromJSON(
      object: any,
    ): AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth {
      return {
        ToothNumberISO: isSet(object.ToothNumberISO)
          ? Number(object.ToothNumberISO)
          : 0,
        SupernumeraryIndex: isSet(object.SupernumeraryIndex)
          ? Number(object.SupernumeraryIndex)
          : 0,
      };
    },

    toJSON(
      message: AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth,
    ): unknown {
      const obj: any = {};
      message.ToothNumberISO !== undefined &&
        (obj.ToothNumberISO = Math.round(message.ToothNumberISO));
      message.SupernumeraryIndex !== undefined &&
        (obj.SupernumeraryIndex = Math.round(message.SupernumeraryIndex));
      return obj;
    },

    fromPartial<
      I extends Exact<
        DeepPartial<AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth>,
        I
      >,
    >(
      object: I,
    ): AssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth {
      const message =
        createBaseAssetContentGeneratedCBCTSegmentedModel_Segment_Structure_StructureCBCTTooth();
      message.ToothNumberISO = object.ToothNumberISO ?? 0;
      message.SupernumeraryIndex = object.SupernumeraryIndex ?? 0;
      return message;
    },
  };

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
