export enum LocalStorageKey {
  locale = 'locale',
  theme = 'theme',
  view = 'view',
  organizationID = 'organizationID',
}

export type SliceLoading = 'idle' | 'pending' | 'succeeded' | 'failed';

export type DefaultInitialState = {
  loading: SliceLoading;
};

export enum StreamDataAccumulatorKey {
  study = 'study',
  reports = 'reports',
  teeth = 'teeth',
  conditions = 'conditions',
  assets = 'assets',
}
