// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SliceGroupSection-module__container--DC74ivMf{display:flex;gap:40px}\n\n.SliceGroupSection-module__guidesLayerWrapper--X0yuWieO{position:relative;margin-right:12px}\n\n[dir=\"rtl\"] .SliceGroupSection-module__guidesLayerWrapper--X0yuWieO{margin-right:0;margin-left:12px}\n\n.SliceGroupSection-module__container--DC74ivMf{display:flex;margin-right:24px;gap:0px}\n\n[dir=\"rtl\"] .SliceGroupSection-module__container--DC74ivMf{margin-right:0;margin-left:24px}.SliceGroupSection-module__sliceSection--J3cSY7kF{display:flex}.SliceGroupSection-module__slicesColumn--03cj1dO\\+{flex:7}.SliceGroupSection-module__title--5Fo9hMva{margin:10px 0 5px 0}", "",{"version":3,"sources":["webpack://./src/pages/Tooth/ui/SliceGroupSection/SliceGroupSection.module.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,QAAA;;AAGF,wDACE,iBAAA,CACA,iBAAA;;AAFF,oEAEE,cAAA,CAAA,gBAAA;;AAGF,+CACE,YAAA,CACA,iBAAA,CACA,OAAA;;AAHF,2DAEE,cAAA,CAAA,gBACA,CAGF,kDACE,YAAA,CAGF,mDACE,MAAA,CAGF,2CACE,mBAAA","sourcesContent":[".container {\n  display: flex;\n  gap: 40px;\n}\n\n.guidesLayerWrapper {\n  position: relative;\n  margin-right: 12px;\n}\n\n.container {\n  display: flex;\n  margin-right: 24px;\n  gap: 0px;\n}\n\n.sliceSection {\n  display: flex;\n}\n\n.slicesColumn {\n  flex: 7;\n}\n\n.title {\n  margin: 10px 0 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SliceGroupSection-module__container--DC74ivMf",
	"guidesLayerWrapper": "SliceGroupSection-module__guidesLayerWrapper--X0yuWieO",
	"sliceSection": "SliceGroupSection-module__sliceSection--J3cSY7kF",
	"slicesColumn": "SliceGroupSection-module__slicesColumn--03cj1dO+",
	"title": "SliceGroupSection-module__title--5Fo9hMva"
};
export default ___CSS_LOADER_EXPORT___;
