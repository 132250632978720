/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export enum Language {
  InvalidValue = 0,
  /** EN_US - American English */
  EN_US = 1,
  /** RU - Russian */
  RU = 2,
  /** HE - Hebrew */
  HE = 3,
  /** DE - German */
  DE = 4,
  /** ES - Spanish */
  ES = 5,
  /** ZH_CN - Simplified Chinese */
  ZH_CN = 6,
  /** IT - Italian */
  IT = 7,
  /** RO - Romanian */
  RO = 8,
  /** PT_BR - Brazilian Portuguese */
  PT_BR = 9,
  /** FR - French */
  FR = 10,
  /** EN_GB - British English */
  EN_GB = 11,
  /** JA - Japanese */
  JA = 12,
  /** KA - Georgian */
  KA = 13,
  /** UK - Ukrainian */
  UK = 14,
  /** PL - Polish */
  PL = 15,
  UNRECOGNIZED = -1,
}

export function languageFromJSON(object: any): Language {
  switch (object) {
    case 0:
    case 'InvalidValue':
      return Language.InvalidValue;
    case 1:
    case 'EN_US':
      return Language.EN_US;
    case 2:
    case 'RU':
      return Language.RU;
    case 3:
    case 'HE':
      return Language.HE;
    case 4:
    case 'DE':
      return Language.DE;
    case 5:
    case 'ES':
      return Language.ES;
    case 6:
    case 'ZH_CN':
      return Language.ZH_CN;
    case 7:
    case 'IT':
      return Language.IT;
    case 8:
    case 'RO':
      return Language.RO;
    case 9:
    case 'PT_BR':
      return Language.PT_BR;
    case 10:
    case 'FR':
      return Language.FR;
    case 11:
    case 'EN_GB':
      return Language.EN_GB;
    case 12:
    case 'JA':
      return Language.JA;
    case 13:
    case 'KA':
      return Language.KA;
    case 14:
    case 'UK':
      return Language.UK;
    case 15:
    case 'PL':
      return Language.PL;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Language.UNRECOGNIZED;
  }
}

export function languageToJSON(object: Language): string {
  switch (object) {
    case Language.InvalidValue:
      return 'InvalidValue';
    case Language.EN_US:
      return 'EN_US';
    case Language.RU:
      return 'RU';
    case Language.HE:
      return 'HE';
    case Language.DE:
      return 'DE';
    case Language.ES:
      return 'ES';
    case Language.ZH_CN:
      return 'ZH_CN';
    case Language.IT:
      return 'IT';
    case Language.RO:
      return 'RO';
    case Language.PT_BR:
      return 'PT_BR';
    case Language.FR:
      return 'FR';
    case Language.EN_GB:
      return 'EN_GB';
    case Language.JA:
      return 'JA';
    case Language.KA:
      return 'KA';
    case Language.UK:
      return 'UK';
    case Language.PL:
      return 'PL';
    case Language.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum Country {
  COUNTRY_UNSPECIFIED = 0,
  /** COUNTRY_KG - Kyrgyzstan */
  COUNTRY_KG = 1,
  /** COUNTRY_AT - Austria */
  COUNTRY_AT = 2,
  /** COUNTRY_AM - Armenia */
  COUNTRY_AM = 3,
  /** COUNTRY_GB - United Kingdom */
  COUNTRY_GB = 4,
  /** COUNTRY_SE - Sweden */
  COUNTRY_SE = 5,
  /** COUNTRY_UZ - Uzbekistan */
  COUNTRY_UZ = 6,
  /** COUNTRY_MD - Moldova */
  COUNTRY_MD = 7,
  /** COUNTRY_BE - Belgium */
  COUNTRY_BE = 8,
  /** COUNTRY_FI - Finland */
  COUNTRY_FI = 9,
  /** COUNTRY_FR - France */
  COUNTRY_FR = 10,
  /** COUNTRY_MX - Mexico */
  COUNTRY_MX = 11,
  /** COUNTRY_TJ - Tajikistan */
  COUNTRY_TJ = 12,
  /** COUNTRY_BY - Belarus */
  COUNTRY_BY = 13,
  /** COUNTRY_RU - Russia */
  COUNTRY_RU = 14,
  /** COUNTRY_IL - Israel */
  COUNTRY_IL = 15,
  /** COUNTRY_NO - Norway */
  COUNTRY_NO = 16,
  /** COUNTRY_DK - Denmark */
  COUNTRY_DK = 17,
  /** COUNTRY_US - United States */
  COUNTRY_US = 18,
  /** COUNTRY_CA - Canada */
  COUNTRY_CA = 19,
  /** COUNTRY_DE - Germany */
  COUNTRY_DE = 20,
  /** COUNTRY_LU - Luxembourg */
  COUNTRY_LU = 21,
  /** COUNTRY_AZ - Azerbaijan */
  COUNTRY_AZ = 22,
  /** COUNTRY_KZ - Kazakhstan */
  COUNTRY_KZ = 23,
  /** COUNTRY_CH - Switzerland */
  COUNTRY_CH = 24,
  /** COUNTRY_IT - Italy */
  COUNTRY_IT = 25,
  /** COUNTRY_NL - Netherlands */
  COUNTRY_NL = 26,
  /** COUNTRY_HR - Croatia */
  COUNTRY_HR = 27,
  /** COUNTRY_SI - Slovenia */
  COUNTRY_SI = 28,
  /** COUNTRY_MT - Malta */
  COUNTRY_MT = 29,
  /** COUNTRY_CY - Cyprus */
  COUNTRY_CY = 30,
  /** COUNTRY_SK - Slovakia */
  COUNTRY_SK = 31,
  /** COUNTRY_PL - Poland */
  COUNTRY_PL = 32,
  /** COUNTRY_PS - Palestinian Territories */
  COUNTRY_PS = 33,
  /** COUNTRY_CZ - Czechia */
  COUNTRY_CZ = 34,
  /** COUNTRY_HU - Hungary */
  COUNTRY_HU = 35,
  /** COUNTRY_VI - U.S. Virgin Islands */
  COUNTRY_VI = 36,
  /** COUNTRY_EE - Estonia */
  COUNTRY_EE = 37,
  /** COUNTRY_PR - Puerto Rico */
  COUNTRY_PR = 38,
  /** COUNTRY_CO - Colombia */
  COUNTRY_CO = 39,
  /** COUNTRY_RO - Romania */
  COUNTRY_RO = 40,
  /** COUNTRY_GU - Guam */
  COUNTRY_GU = 41,
  /** COUNTRY_GE - Georgia */
  COUNTRY_GE = 42,
  /** COUNTRY_IE - Ireland */
  COUNTRY_IE = 43,
  /** COUNTRY_GR - Greece */
  COUNTRY_GR = 44,
  /** COUNTRY_PT - Portugal */
  COUNTRY_PT = 45,
  /** COUNTRY_LV - Latvia */
  COUNTRY_LV = 46,
  /** COUNTRY_AU - Australia */
  COUNTRY_AU = 47,
  /** COUNTRY_NZ - New Zealand */
  COUNTRY_NZ = 48,
  /** COUNTRY_AS - American Samoa */
  COUNTRY_AS = 49,
  /** COUNTRY_LT - Lithuania */
  COUNTRY_LT = 50,
  /** COUNTRY_ES - Spain */
  COUNTRY_ES = 51,
  /** COUNTRY_UA - Ukraine */
  COUNTRY_UA = 52,
  UNRECOGNIZED = -1,
}

export function countryFromJSON(object: any): Country {
  switch (object) {
    case 0:
    case 'COUNTRY_UNSPECIFIED':
      return Country.COUNTRY_UNSPECIFIED;
    case 1:
    case 'COUNTRY_KG':
      return Country.COUNTRY_KG;
    case 2:
    case 'COUNTRY_AT':
      return Country.COUNTRY_AT;
    case 3:
    case 'COUNTRY_AM':
      return Country.COUNTRY_AM;
    case 4:
    case 'COUNTRY_GB':
      return Country.COUNTRY_GB;
    case 5:
    case 'COUNTRY_SE':
      return Country.COUNTRY_SE;
    case 6:
    case 'COUNTRY_UZ':
      return Country.COUNTRY_UZ;
    case 7:
    case 'COUNTRY_MD':
      return Country.COUNTRY_MD;
    case 8:
    case 'COUNTRY_BE':
      return Country.COUNTRY_BE;
    case 9:
    case 'COUNTRY_FI':
      return Country.COUNTRY_FI;
    case 10:
    case 'COUNTRY_FR':
      return Country.COUNTRY_FR;
    case 11:
    case 'COUNTRY_MX':
      return Country.COUNTRY_MX;
    case 12:
    case 'COUNTRY_TJ':
      return Country.COUNTRY_TJ;
    case 13:
    case 'COUNTRY_BY':
      return Country.COUNTRY_BY;
    case 14:
    case 'COUNTRY_RU':
      return Country.COUNTRY_RU;
    case 15:
    case 'COUNTRY_IL':
      return Country.COUNTRY_IL;
    case 16:
    case 'COUNTRY_NO':
      return Country.COUNTRY_NO;
    case 17:
    case 'COUNTRY_DK':
      return Country.COUNTRY_DK;
    case 18:
    case 'COUNTRY_US':
      return Country.COUNTRY_US;
    case 19:
    case 'COUNTRY_CA':
      return Country.COUNTRY_CA;
    case 20:
    case 'COUNTRY_DE':
      return Country.COUNTRY_DE;
    case 21:
    case 'COUNTRY_LU':
      return Country.COUNTRY_LU;
    case 22:
    case 'COUNTRY_AZ':
      return Country.COUNTRY_AZ;
    case 23:
    case 'COUNTRY_KZ':
      return Country.COUNTRY_KZ;
    case 24:
    case 'COUNTRY_CH':
      return Country.COUNTRY_CH;
    case 25:
    case 'COUNTRY_IT':
      return Country.COUNTRY_IT;
    case 26:
    case 'COUNTRY_NL':
      return Country.COUNTRY_NL;
    case 27:
    case 'COUNTRY_HR':
      return Country.COUNTRY_HR;
    case 28:
    case 'COUNTRY_SI':
      return Country.COUNTRY_SI;
    case 29:
    case 'COUNTRY_MT':
      return Country.COUNTRY_MT;
    case 30:
    case 'COUNTRY_CY':
      return Country.COUNTRY_CY;
    case 31:
    case 'COUNTRY_SK':
      return Country.COUNTRY_SK;
    case 32:
    case 'COUNTRY_PL':
      return Country.COUNTRY_PL;
    case 33:
    case 'COUNTRY_PS':
      return Country.COUNTRY_PS;
    case 34:
    case 'COUNTRY_CZ':
      return Country.COUNTRY_CZ;
    case 35:
    case 'COUNTRY_HU':
      return Country.COUNTRY_HU;
    case 36:
    case 'COUNTRY_VI':
      return Country.COUNTRY_VI;
    case 37:
    case 'COUNTRY_EE':
      return Country.COUNTRY_EE;
    case 38:
    case 'COUNTRY_PR':
      return Country.COUNTRY_PR;
    case 39:
    case 'COUNTRY_CO':
      return Country.COUNTRY_CO;
    case 40:
    case 'COUNTRY_RO':
      return Country.COUNTRY_RO;
    case 41:
    case 'COUNTRY_GU':
      return Country.COUNTRY_GU;
    case 42:
    case 'COUNTRY_GE':
      return Country.COUNTRY_GE;
    case 43:
    case 'COUNTRY_IE':
      return Country.COUNTRY_IE;
    case 44:
    case 'COUNTRY_GR':
      return Country.COUNTRY_GR;
    case 45:
    case 'COUNTRY_PT':
      return Country.COUNTRY_PT;
    case 46:
    case 'COUNTRY_LV':
      return Country.COUNTRY_LV;
    case 47:
    case 'COUNTRY_AU':
      return Country.COUNTRY_AU;
    case 48:
    case 'COUNTRY_NZ':
      return Country.COUNTRY_NZ;
    case 49:
    case 'COUNTRY_AS':
      return Country.COUNTRY_AS;
    case 50:
    case 'COUNTRY_LT':
      return Country.COUNTRY_LT;
    case 51:
    case 'COUNTRY_ES':
      return Country.COUNTRY_ES;
    case 52:
    case 'COUNTRY_UA':
      return Country.COUNTRY_UA;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Country.UNRECOGNIZED;
  }
}

export function countryToJSON(object: Country): string {
  switch (object) {
    case Country.COUNTRY_UNSPECIFIED:
      return 'COUNTRY_UNSPECIFIED';
    case Country.COUNTRY_KG:
      return 'COUNTRY_KG';
    case Country.COUNTRY_AT:
      return 'COUNTRY_AT';
    case Country.COUNTRY_AM:
      return 'COUNTRY_AM';
    case Country.COUNTRY_GB:
      return 'COUNTRY_GB';
    case Country.COUNTRY_SE:
      return 'COUNTRY_SE';
    case Country.COUNTRY_UZ:
      return 'COUNTRY_UZ';
    case Country.COUNTRY_MD:
      return 'COUNTRY_MD';
    case Country.COUNTRY_BE:
      return 'COUNTRY_BE';
    case Country.COUNTRY_FI:
      return 'COUNTRY_FI';
    case Country.COUNTRY_FR:
      return 'COUNTRY_FR';
    case Country.COUNTRY_MX:
      return 'COUNTRY_MX';
    case Country.COUNTRY_TJ:
      return 'COUNTRY_TJ';
    case Country.COUNTRY_BY:
      return 'COUNTRY_BY';
    case Country.COUNTRY_RU:
      return 'COUNTRY_RU';
    case Country.COUNTRY_IL:
      return 'COUNTRY_IL';
    case Country.COUNTRY_NO:
      return 'COUNTRY_NO';
    case Country.COUNTRY_DK:
      return 'COUNTRY_DK';
    case Country.COUNTRY_US:
      return 'COUNTRY_US';
    case Country.COUNTRY_CA:
      return 'COUNTRY_CA';
    case Country.COUNTRY_DE:
      return 'COUNTRY_DE';
    case Country.COUNTRY_LU:
      return 'COUNTRY_LU';
    case Country.COUNTRY_AZ:
      return 'COUNTRY_AZ';
    case Country.COUNTRY_KZ:
      return 'COUNTRY_KZ';
    case Country.COUNTRY_CH:
      return 'COUNTRY_CH';
    case Country.COUNTRY_IT:
      return 'COUNTRY_IT';
    case Country.COUNTRY_NL:
      return 'COUNTRY_NL';
    case Country.COUNTRY_HR:
      return 'COUNTRY_HR';
    case Country.COUNTRY_SI:
      return 'COUNTRY_SI';
    case Country.COUNTRY_MT:
      return 'COUNTRY_MT';
    case Country.COUNTRY_CY:
      return 'COUNTRY_CY';
    case Country.COUNTRY_SK:
      return 'COUNTRY_SK';
    case Country.COUNTRY_PL:
      return 'COUNTRY_PL';
    case Country.COUNTRY_PS:
      return 'COUNTRY_PS';
    case Country.COUNTRY_CZ:
      return 'COUNTRY_CZ';
    case Country.COUNTRY_HU:
      return 'COUNTRY_HU';
    case Country.COUNTRY_VI:
      return 'COUNTRY_VI';
    case Country.COUNTRY_EE:
      return 'COUNTRY_EE';
    case Country.COUNTRY_PR:
      return 'COUNTRY_PR';
    case Country.COUNTRY_CO:
      return 'COUNTRY_CO';
    case Country.COUNTRY_RO:
      return 'COUNTRY_RO';
    case Country.COUNTRY_GU:
      return 'COUNTRY_GU';
    case Country.COUNTRY_GE:
      return 'COUNTRY_GE';
    case Country.COUNTRY_IE:
      return 'COUNTRY_IE';
    case Country.COUNTRY_GR:
      return 'COUNTRY_GR';
    case Country.COUNTRY_PT:
      return 'COUNTRY_PT';
    case Country.COUNTRY_LV:
      return 'COUNTRY_LV';
    case Country.COUNTRY_AU:
      return 'COUNTRY_AU';
    case Country.COUNTRY_NZ:
      return 'COUNTRY_NZ';
    case Country.COUNTRY_AS:
      return 'COUNTRY_AS';
    case Country.COUNTRY_LT:
      return 'COUNTRY_LT';
    case Country.COUNTRY_ES:
      return 'COUNTRY_ES';
    case Country.COUNTRY_UA:
      return 'COUNTRY_UA';
    case Country.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum CalledVia {
  CalledViaUnspecified = 0,
  CalledViaClient = 1,
  CalledViaPartnerAPI = 2,
  CalledViaMLPipelines = 3,
  UNRECOGNIZED = -1,
}

export function calledViaFromJSON(object: any): CalledVia {
  switch (object) {
    case 0:
    case 'CalledViaUnspecified':
      return CalledVia.CalledViaUnspecified;
    case 1:
    case 'CalledViaClient':
      return CalledVia.CalledViaClient;
    case 2:
    case 'CalledViaPartnerAPI':
      return CalledVia.CalledViaPartnerAPI;
    case 3:
    case 'CalledViaMLPipelines':
      return CalledVia.CalledViaMLPipelines;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CalledVia.UNRECOGNIZED;
  }
}

export function calledViaToJSON(object: CalledVia): string {
  switch (object) {
    case CalledVia.CalledViaUnspecified:
      return 'CalledViaUnspecified';
    case CalledVia.CalledViaClient:
      return 'CalledViaClient';
    case CalledVia.CalledViaPartnerAPI:
      return 'CalledViaPartnerAPI';
    case CalledVia.CalledViaMLPipelines:
      return 'CalledViaMLPipelines';
    case CalledVia.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum Gender {
  GenderUnspecified = 0,
  GenderMale = 1,
  GenderFemale = 2,
  GenderOther = 3,
  UNRECOGNIZED = -1,
}

export function genderFromJSON(object: any): Gender {
  switch (object) {
    case 0:
    case 'GenderUnspecified':
      return Gender.GenderUnspecified;
    case 1:
    case 'GenderMale':
      return Gender.GenderMale;
    case 2:
    case 'GenderFemale':
      return Gender.GenderFemale;
    case 3:
    case 'GenderOther':
      return Gender.GenderOther;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Gender.UNRECOGNIZED;
  }
}

export function genderToJSON(object: Gender): string {
  switch (object) {
    case Gender.GenderUnspecified:
      return 'GenderUnspecified';
    case Gender.GenderMale:
      return 'GenderMale';
    case Gender.GenderFemale:
      return 'GenderFemale';
    case Gender.GenderOther:
      return 'GenderOther';
    case Gender.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum OptionalBoolean {
  OptionalBooleanUnspecified = 0,
  OptionalBooleanFalse = 1,
  OptionalBooleanTrue = 2,
  UNRECOGNIZED = -1,
}

export function optionalBooleanFromJSON(object: any): OptionalBoolean {
  switch (object) {
    case 0:
    case 'OptionalBooleanUnspecified':
      return OptionalBoolean.OptionalBooleanUnspecified;
    case 1:
    case 'OptionalBooleanFalse':
      return OptionalBoolean.OptionalBooleanFalse;
    case 2:
    case 'OptionalBooleanTrue':
      return OptionalBoolean.OptionalBooleanTrue;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OptionalBoolean.UNRECOGNIZED;
  }
}

export function optionalBooleanToJSON(object: OptionalBoolean): string {
  switch (object) {
    case OptionalBoolean.OptionalBooleanUnspecified:
      return 'OptionalBooleanUnspecified';
    case OptionalBoolean.OptionalBooleanFalse:
      return 'OptionalBooleanFalse';
    case OptionalBoolean.OptionalBooleanTrue:
      return 'OptionalBooleanTrue';
    case OptionalBoolean.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Revision {
  Number: number;
  UpdatedAt: number;
  /** user identifier || system const ("system:core/report_ingress") */
  UserID: string;
}

export interface Created {
  By: string;
  At: number;
  Via: CalledVia;
}

export interface Deleted {
  Deleted: boolean;
  By: string;
  At: number;
  Via: CalledVia;
}

export interface Attribute {
  Key: string;
  /** bool Private = 3; // TODO */
  Value: string;
}

export interface PersonalData {
  FirstName: string;
  LastName: string;
  /** 2022-01-14 */
  DateOfBirth: string;
  Emails: string[];
  Phones: string[];
}

function createBaseRevision(): Revision {
  return { Number: 0, UpdatedAt: 0, UserID: '' };
}

export const Revision = {
  encode(
    message: Revision,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Number !== 0) {
      writer.uint32(8).int64(message.Number);
    }
    if (message.UpdatedAt !== 0) {
      writer.uint32(16).int64(message.UpdatedAt);
    }
    if (message.UserID !== '') {
      writer.uint32(26).string(message.UserID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Revision {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevision();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Number = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.UpdatedAt = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.UserID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Revision {
    return {
      Number: isSet(object.Number) ? Number(object.Number) : 0,
      UpdatedAt: isSet(object.UpdatedAt) ? Number(object.UpdatedAt) : 0,
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
    };
  },

  toJSON(message: Revision): unknown {
    const obj: any = {};
    message.Number !== undefined && (obj.Number = Math.round(message.Number));
    message.UpdatedAt !== undefined &&
      (obj.UpdatedAt = Math.round(message.UpdatedAt));
    message.UserID !== undefined && (obj.UserID = message.UserID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Revision>, I>>(object: I): Revision {
    const message = createBaseRevision();
    message.Number = object.Number ?? 0;
    message.UpdatedAt = object.UpdatedAt ?? 0;
    message.UserID = object.UserID ?? '';
    return message;
  },
};

function createBaseCreated(): Created {
  return { By: '', At: 0, Via: 0 };
}

export const Created = {
  encode(
    message: Created,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.By !== '') {
      writer.uint32(10).string(message.By);
    }
    if (message.At !== 0) {
      writer.uint32(16).int64(message.At);
    }
    if (message.Via !== 0) {
      writer.uint32(24).int32(message.Via);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Created {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreated();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.By = reader.string();
          break;
        case 2:
          message.At = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.Via = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Created {
    return {
      By: isSet(object.By) ? String(object.By) : '',
      At: isSet(object.At) ? Number(object.At) : 0,
      Via: isSet(object.Via) ? calledViaFromJSON(object.Via) : 0,
    };
  },

  toJSON(message: Created): unknown {
    const obj: any = {};
    message.By !== undefined && (obj.By = message.By);
    message.At !== undefined && (obj.At = Math.round(message.At));
    message.Via !== undefined && (obj.Via = calledViaToJSON(message.Via));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Created>, I>>(object: I): Created {
    const message = createBaseCreated();
    message.By = object.By ?? '';
    message.At = object.At ?? 0;
    message.Via = object.Via ?? 0;
    return message;
  },
};

function createBaseDeleted(): Deleted {
  return { Deleted: false, By: '', At: 0, Via: 0 };
}

export const Deleted = {
  encode(
    message: Deleted,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Deleted === true) {
      writer.uint32(8).bool(message.Deleted);
    }
    if (message.By !== '') {
      writer.uint32(18).string(message.By);
    }
    if (message.At !== 0) {
      writer.uint32(24).int64(message.At);
    }
    if (message.Via !== 0) {
      writer.uint32(32).int32(message.Via);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Deleted {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleted();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Deleted = reader.bool();
          break;
        case 2:
          message.By = reader.string();
          break;
        case 3:
          message.At = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.Via = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Deleted {
    return {
      Deleted: isSet(object.Deleted) ? Boolean(object.Deleted) : false,
      By: isSet(object.By) ? String(object.By) : '',
      At: isSet(object.At) ? Number(object.At) : 0,
      Via: isSet(object.Via) ? calledViaFromJSON(object.Via) : 0,
    };
  },

  toJSON(message: Deleted): unknown {
    const obj: any = {};
    message.Deleted !== undefined && (obj.Deleted = message.Deleted);
    message.By !== undefined && (obj.By = message.By);
    message.At !== undefined && (obj.At = Math.round(message.At));
    message.Via !== undefined && (obj.Via = calledViaToJSON(message.Via));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Deleted>, I>>(object: I): Deleted {
    const message = createBaseDeleted();
    message.Deleted = object.Deleted ?? false;
    message.By = object.By ?? '';
    message.At = object.At ?? 0;
    message.Via = object.Via ?? 0;
    return message;
  },
};

function createBaseAttribute(): Attribute {
  return { Key: '', Value: '' };
}

export const Attribute = {
  encode(
    message: Attribute,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Key !== '') {
      writer.uint32(10).string(message.Key);
    }
    if (message.Value !== '') {
      writer.uint32(18).string(message.Value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Attribute {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttribute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Key = reader.string();
          break;
        case 2:
          message.Value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Attribute {
    return {
      Key: isSet(object.Key) ? String(object.Key) : '',
      Value: isSet(object.Value) ? String(object.Value) : '',
    };
  },

  toJSON(message: Attribute): unknown {
    const obj: any = {};
    message.Key !== undefined && (obj.Key = message.Key);
    message.Value !== undefined && (obj.Value = message.Value);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Attribute>, I>>(
    object: I,
  ): Attribute {
    const message = createBaseAttribute();
    message.Key = object.Key ?? '';
    message.Value = object.Value ?? '';
    return message;
  },
};

function createBasePersonalData(): PersonalData {
  return {
    FirstName: '',
    LastName: '',
    DateOfBirth: '',
    Emails: [],
    Phones: [],
  };
}

export const PersonalData = {
  encode(
    message: PersonalData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FirstName !== '') {
      writer.uint32(10).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(18).string(message.LastName);
    }
    if (message.DateOfBirth !== '') {
      writer.uint32(26).string(message.DateOfBirth);
    }
    for (const v of message.Emails) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.Phones) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PersonalData {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePersonalData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FirstName = reader.string();
          break;
        case 2:
          message.LastName = reader.string();
          break;
        case 3:
          message.DateOfBirth = reader.string();
          break;
        case 4:
          message.Emails.push(reader.string());
          break;
        case 5:
          message.Phones.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PersonalData {
    return {
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      DateOfBirth: isSet(object.DateOfBirth) ? String(object.DateOfBirth) : '',
      Emails: Array.isArray(object?.Emails)
        ? object.Emails.map((e: any) => String(e))
        : [],
      Phones: Array.isArray(object?.Phones)
        ? object.Phones.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: PersonalData): unknown {
    const obj: any = {};
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    message.DateOfBirth !== undefined &&
      (obj.DateOfBirth = message.DateOfBirth);
    if (message.Emails) {
      obj.Emails = message.Emails.map((e) => e);
    } else {
      obj.Emails = [];
    }
    if (message.Phones) {
      obj.Phones = message.Phones.map((e) => e);
    } else {
      obj.Phones = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PersonalData>, I>>(
    object: I,
  ): PersonalData {
    const message = createBasePersonalData();
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.DateOfBirth = object.DateOfBirth ?? '';
    message.Emails = object.Emails?.map((e) => e) || [];
    message.Phones = object.Phones?.map((e) => e) || [];
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
