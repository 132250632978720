/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  ReportCBCTIOSSuperimpositionOrientation,
  reportCBCTIOSSuperimpositionOrientationFromJSON,
  reportCBCTIOSSuperimpositionOrientationToJSON,
} from '../model/dto_report_type_cbct_ios_superimposition';
import {
  CBCTAnatomy,
  cBCTAnatomyFromJSON,
  cBCTAnatomyToJSON,
} from '../model/dto_report_common';

export const protobufPackage = 'com.diagnocat.model';

export interface ReportRequest {
  InputCBCTGP: ReportRequest_InputTypeCBCTGP | undefined;
  InputCBCTEndo: ReportRequest_InputTypeCBCTEndo | undefined;
  InputCBCTImplant: ReportRequest_InputTypeCBCTImplant | undefined;
  InputCBCTMolar: ReportRequest_InputTypeCBCTMolar | undefined;
  InputCBCTOrtho: ReportRequest_InputTypeCBCTOrtho | undefined;
  InputPanoGP: ReportRequest_InputTypePanoGP | undefined;
  InputIOXRayGP: ReportRequest_InputTypeIOXRayGP | undefined;
  InputDentalPhotoGP: ReportRequest_InputTypeDentalPhotoGP | undefined;
  InputCBCTSegmentation: ReportRequest_InputTypeCBCTSegmentation | undefined;
  InputCBCTIOSSuperimposition:
    | ReportRequest_InputTypeCBCTIOSSuperimposition
    | undefined;
  InputImplantStudio: ReportRequest_InputTypeImplantStudio | undefined;
  InputOrthoStudio: ReportRequest_InputTypeOrthoStudio | undefined;
}

export interface ReportRequest_InputTypeCBCTGP {
  CBCTStudyID: string;
}

export interface ReportRequest_InputTypeCBCTEndo {
  CBCTStudyID: string;
  /** tooth number in ISO format (https://odonte.com/index.php/english/tooth-designation-system-iso-3950/) */
  ToothNumberISO: number;
  /** step in millimeters */
  StepMM: number;
  /** thickness of slice */
  ThicknessMM: number;
}

export interface ReportRequest_InputTypeCBCTImplant {
  CBCTStudyID: string;
  /** tooth numbers range to perform report on */
  StartToothNumberISO: number;
  /** tooth numbers range to perform report on */
  EndToothNumberISO: number;
  /** step in millimeters  default == 2 */
  StepMM: number;
  /** thickness of slice == optional */
  ThicknessMM: number;
}

export interface ReportRequest_InputTypeCBCTMolar {
  CBCTStudyID: string;
  ToothNumberISO: number;
}

export interface ReportRequest_InputTypeCBCTOrtho {
  CBCTStudyID: string;
  Language: string;
}

export interface ReportRequest_InputTypePanoGP {
  PanoramicXRayStudyID: string;
}

export interface ReportRequest_InputTypeIOXRayGP {
  IntraoralXRayStudyID: string;
}

export interface ReportRequest_InputTypeDentalPhotoGP {
  DentalPhotoStudyID: string;
}

export interface ReportRequest_InputTypeCBCTSegmentation {
  CBCTStudyID: string;
  Segments: ReportRequest_InputTypeCBCTSegmentation_CBCTSegments[];
}

export interface ReportRequest_InputTypeCBCTSegmentation_CBCTSegments {
  CBCTTeethISO: number[];
  Anatomy: CBCTAnatomy[];
}

export interface ReportRequest_InputTypeCBCTIOSSuperimposition {
  /** CBCT study identifier on which perform an alignment */
  CBCTStudyID: string;
  /** STL study identifier which should be segmented and aligned on CBCT */
  STLStudyID: string;
  Orientation: ReportCBCTIOSSuperimpositionOrientation;
  Segments: ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments[];
}

export interface ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments {
  CBCTTeethISO: number[];
  IOSTeethISO: number[];
  FusedTeethISO: number[];
  Anatomy: CBCTAnatomy[];
}

export interface ReportRequest_InputTypeImplantStudio {
  CBCTStudyID: string;
  IOSSTLStudyID: string;
}

export interface ReportRequest_InputTypeOrthoStudio {
  CBCTStudyID: string;
  IOSSTLStudyID: string;
}

function createBaseReportRequest(): ReportRequest {
  return {
    InputCBCTGP: undefined,
    InputCBCTEndo: undefined,
    InputCBCTImplant: undefined,
    InputCBCTMolar: undefined,
    InputCBCTOrtho: undefined,
    InputPanoGP: undefined,
    InputIOXRayGP: undefined,
    InputDentalPhotoGP: undefined,
    InputCBCTSegmentation: undefined,
    InputCBCTIOSSuperimposition: undefined,
    InputImplantStudio: undefined,
    InputOrthoStudio: undefined,
  };
}

export const ReportRequest = {
  encode(
    message: ReportRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.InputCBCTGP !== undefined) {
      ReportRequest_InputTypeCBCTGP.encode(
        message.InputCBCTGP,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.InputCBCTEndo !== undefined) {
      ReportRequest_InputTypeCBCTEndo.encode(
        message.InputCBCTEndo,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.InputCBCTImplant !== undefined) {
      ReportRequest_InputTypeCBCTImplant.encode(
        message.InputCBCTImplant,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.InputCBCTMolar !== undefined) {
      ReportRequest_InputTypeCBCTMolar.encode(
        message.InputCBCTMolar,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.InputCBCTOrtho !== undefined) {
      ReportRequest_InputTypeCBCTOrtho.encode(
        message.InputCBCTOrtho,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.InputPanoGP !== undefined) {
      ReportRequest_InputTypePanoGP.encode(
        message.InputPanoGP,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    if (message.InputIOXRayGP !== undefined) {
      ReportRequest_InputTypeIOXRayGP.encode(
        message.InputIOXRayGP,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.InputDentalPhotoGP !== undefined) {
      ReportRequest_InputTypeDentalPhotoGP.encode(
        message.InputDentalPhotoGP,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    if (message.InputCBCTSegmentation !== undefined) {
      ReportRequest_InputTypeCBCTSegmentation.encode(
        message.InputCBCTSegmentation,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    if (message.InputCBCTIOSSuperimposition !== undefined) {
      ReportRequest_InputTypeCBCTIOSSuperimposition.encode(
        message.InputCBCTIOSSuperimposition,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    if (message.InputImplantStudio !== undefined) {
      ReportRequest_InputTypeImplantStudio.encode(
        message.InputImplantStudio,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.InputOrthoStudio !== undefined) {
      ReportRequest_InputTypeOrthoStudio.encode(
        message.InputOrthoStudio,
        writer.uint32(122).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.InputCBCTGP = ReportRequest_InputTypeCBCTGP.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.InputCBCTEndo = ReportRequest_InputTypeCBCTEndo.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.InputCBCTImplant = ReportRequest_InputTypeCBCTImplant.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.InputCBCTMolar = ReportRequest_InputTypeCBCTMolar.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.InputCBCTOrtho = ReportRequest_InputTypeCBCTOrtho.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 6:
          message.InputPanoGP = ReportRequest_InputTypePanoGP.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 7:
          message.InputIOXRayGP = ReportRequest_InputTypeIOXRayGP.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 8:
          message.InputDentalPhotoGP =
            ReportRequest_InputTypeDentalPhotoGP.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 9:
          message.InputCBCTSegmentation =
            ReportRequest_InputTypeCBCTSegmentation.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 10:
          message.InputCBCTIOSSuperimposition =
            ReportRequest_InputTypeCBCTIOSSuperimposition.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 13:
          message.InputImplantStudio =
            ReportRequest_InputTypeImplantStudio.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 15:
          message.InputOrthoStudio = ReportRequest_InputTypeOrthoStudio.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest {
    return {
      InputCBCTGP: isSet(object.InputCBCTGP)
        ? ReportRequest_InputTypeCBCTGP.fromJSON(object.InputCBCTGP)
        : undefined,
      InputCBCTEndo: isSet(object.InputCBCTEndo)
        ? ReportRequest_InputTypeCBCTEndo.fromJSON(object.InputCBCTEndo)
        : undefined,
      InputCBCTImplant: isSet(object.InputCBCTImplant)
        ? ReportRequest_InputTypeCBCTImplant.fromJSON(object.InputCBCTImplant)
        : undefined,
      InputCBCTMolar: isSet(object.InputCBCTMolar)
        ? ReportRequest_InputTypeCBCTMolar.fromJSON(object.InputCBCTMolar)
        : undefined,
      InputCBCTOrtho: isSet(object.InputCBCTOrtho)
        ? ReportRequest_InputTypeCBCTOrtho.fromJSON(object.InputCBCTOrtho)
        : undefined,
      InputPanoGP: isSet(object.InputPanoGP)
        ? ReportRequest_InputTypePanoGP.fromJSON(object.InputPanoGP)
        : undefined,
      InputIOXRayGP: isSet(object.InputIOXRayGP)
        ? ReportRequest_InputTypeIOXRayGP.fromJSON(object.InputIOXRayGP)
        : undefined,
      InputDentalPhotoGP: isSet(object.InputDentalPhotoGP)
        ? ReportRequest_InputTypeDentalPhotoGP.fromJSON(
            object.InputDentalPhotoGP,
          )
        : undefined,
      InputCBCTSegmentation: isSet(object.InputCBCTSegmentation)
        ? ReportRequest_InputTypeCBCTSegmentation.fromJSON(
            object.InputCBCTSegmentation,
          )
        : undefined,
      InputCBCTIOSSuperimposition: isSet(object.InputCBCTIOSSuperimposition)
        ? ReportRequest_InputTypeCBCTIOSSuperimposition.fromJSON(
            object.InputCBCTIOSSuperimposition,
          )
        : undefined,
      InputImplantStudio: isSet(object.InputImplantStudio)
        ? ReportRequest_InputTypeImplantStudio.fromJSON(
            object.InputImplantStudio,
          )
        : undefined,
      InputOrthoStudio: isSet(object.InputOrthoStudio)
        ? ReportRequest_InputTypeOrthoStudio.fromJSON(object.InputOrthoStudio)
        : undefined,
    };
  },

  toJSON(message: ReportRequest): unknown {
    const obj: any = {};
    message.InputCBCTGP !== undefined &&
      (obj.InputCBCTGP = message.InputCBCTGP
        ? ReportRequest_InputTypeCBCTGP.toJSON(message.InputCBCTGP)
        : undefined);
    message.InputCBCTEndo !== undefined &&
      (obj.InputCBCTEndo = message.InputCBCTEndo
        ? ReportRequest_InputTypeCBCTEndo.toJSON(message.InputCBCTEndo)
        : undefined);
    message.InputCBCTImplant !== undefined &&
      (obj.InputCBCTImplant = message.InputCBCTImplant
        ? ReportRequest_InputTypeCBCTImplant.toJSON(message.InputCBCTImplant)
        : undefined);
    message.InputCBCTMolar !== undefined &&
      (obj.InputCBCTMolar = message.InputCBCTMolar
        ? ReportRequest_InputTypeCBCTMolar.toJSON(message.InputCBCTMolar)
        : undefined);
    message.InputCBCTOrtho !== undefined &&
      (obj.InputCBCTOrtho = message.InputCBCTOrtho
        ? ReportRequest_InputTypeCBCTOrtho.toJSON(message.InputCBCTOrtho)
        : undefined);
    message.InputPanoGP !== undefined &&
      (obj.InputPanoGP = message.InputPanoGP
        ? ReportRequest_InputTypePanoGP.toJSON(message.InputPanoGP)
        : undefined);
    message.InputIOXRayGP !== undefined &&
      (obj.InputIOXRayGP = message.InputIOXRayGP
        ? ReportRequest_InputTypeIOXRayGP.toJSON(message.InputIOXRayGP)
        : undefined);
    message.InputDentalPhotoGP !== undefined &&
      (obj.InputDentalPhotoGP = message.InputDentalPhotoGP
        ? ReportRequest_InputTypeDentalPhotoGP.toJSON(
            message.InputDentalPhotoGP,
          )
        : undefined);
    message.InputCBCTSegmentation !== undefined &&
      (obj.InputCBCTSegmentation = message.InputCBCTSegmentation
        ? ReportRequest_InputTypeCBCTSegmentation.toJSON(
            message.InputCBCTSegmentation,
          )
        : undefined);
    message.InputCBCTIOSSuperimposition !== undefined &&
      (obj.InputCBCTIOSSuperimposition = message.InputCBCTIOSSuperimposition
        ? ReportRequest_InputTypeCBCTIOSSuperimposition.toJSON(
            message.InputCBCTIOSSuperimposition,
          )
        : undefined);
    message.InputImplantStudio !== undefined &&
      (obj.InputImplantStudio = message.InputImplantStudio
        ? ReportRequest_InputTypeImplantStudio.toJSON(
            message.InputImplantStudio,
          )
        : undefined);
    message.InputOrthoStudio !== undefined &&
      (obj.InputOrthoStudio = message.InputOrthoStudio
        ? ReportRequest_InputTypeOrthoStudio.toJSON(message.InputOrthoStudio)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportRequest>, I>>(
    object: I,
  ): ReportRequest {
    const message = createBaseReportRequest();
    message.InputCBCTGP =
      object.InputCBCTGP !== undefined && object.InputCBCTGP !== null
        ? ReportRequest_InputTypeCBCTGP.fromPartial(object.InputCBCTGP)
        : undefined;
    message.InputCBCTEndo =
      object.InputCBCTEndo !== undefined && object.InputCBCTEndo !== null
        ? ReportRequest_InputTypeCBCTEndo.fromPartial(object.InputCBCTEndo)
        : undefined;
    message.InputCBCTImplant =
      object.InputCBCTImplant !== undefined && object.InputCBCTImplant !== null
        ? ReportRequest_InputTypeCBCTImplant.fromPartial(
            object.InputCBCTImplant,
          )
        : undefined;
    message.InputCBCTMolar =
      object.InputCBCTMolar !== undefined && object.InputCBCTMolar !== null
        ? ReportRequest_InputTypeCBCTMolar.fromPartial(object.InputCBCTMolar)
        : undefined;
    message.InputCBCTOrtho =
      object.InputCBCTOrtho !== undefined && object.InputCBCTOrtho !== null
        ? ReportRequest_InputTypeCBCTOrtho.fromPartial(object.InputCBCTOrtho)
        : undefined;
    message.InputPanoGP =
      object.InputPanoGP !== undefined && object.InputPanoGP !== null
        ? ReportRequest_InputTypePanoGP.fromPartial(object.InputPanoGP)
        : undefined;
    message.InputIOXRayGP =
      object.InputIOXRayGP !== undefined && object.InputIOXRayGP !== null
        ? ReportRequest_InputTypeIOXRayGP.fromPartial(object.InputIOXRayGP)
        : undefined;
    message.InputDentalPhotoGP =
      object.InputDentalPhotoGP !== undefined &&
      object.InputDentalPhotoGP !== null
        ? ReportRequest_InputTypeDentalPhotoGP.fromPartial(
            object.InputDentalPhotoGP,
          )
        : undefined;
    message.InputCBCTSegmentation =
      object.InputCBCTSegmentation !== undefined &&
      object.InputCBCTSegmentation !== null
        ? ReportRequest_InputTypeCBCTSegmentation.fromPartial(
            object.InputCBCTSegmentation,
          )
        : undefined;
    message.InputCBCTIOSSuperimposition =
      object.InputCBCTIOSSuperimposition !== undefined &&
      object.InputCBCTIOSSuperimposition !== null
        ? ReportRequest_InputTypeCBCTIOSSuperimposition.fromPartial(
            object.InputCBCTIOSSuperimposition,
          )
        : undefined;
    message.InputImplantStudio =
      object.InputImplantStudio !== undefined &&
      object.InputImplantStudio !== null
        ? ReportRequest_InputTypeImplantStudio.fromPartial(
            object.InputImplantStudio,
          )
        : undefined;
    message.InputOrthoStudio =
      object.InputOrthoStudio !== undefined && object.InputOrthoStudio !== null
        ? ReportRequest_InputTypeOrthoStudio.fromPartial(
            object.InputOrthoStudio,
          )
        : undefined;
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTGP(): ReportRequest_InputTypeCBCTGP {
  return { CBCTStudyID: '' };
}

export const ReportRequest_InputTypeCBCTGP = {
  encode(
    message: ReportRequest_InputTypeCBCTGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTGP {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTGP): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTGP>, I>>(
    object: I,
  ): ReportRequest_InputTypeCBCTGP {
    const message = createBaseReportRequest_InputTypeCBCTGP();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTEndo(): ReportRequest_InputTypeCBCTEndo {
  return { CBCTStudyID: '', ToothNumberISO: 0, StepMM: 0, ThicknessMM: 0 };
}

export const ReportRequest_InputTypeCBCTEndo = {
  encode(
    message: ReportRequest_InputTypeCBCTEndo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.ToothNumberISO !== 0) {
      writer.uint32(16).uint32(message.ToothNumberISO);
    }
    if (message.StepMM !== 0) {
      writer.uint32(29).float(message.StepMM);
    }
    if (message.ThicknessMM !== 0) {
      writer.uint32(37).float(message.ThicknessMM);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTEndo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTEndo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.ToothNumberISO = reader.uint32();
          break;
        case 3:
          message.StepMM = reader.float();
          break;
        case 4:
          message.ThicknessMM = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTEndo {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      ToothNumberISO: isSet(object.ToothNumberISO)
        ? Number(object.ToothNumberISO)
        : 0,
      StepMM: isSet(object.StepMM) ? Number(object.StepMM) : 0,
      ThicknessMM: isSet(object.ThicknessMM) ? Number(object.ThicknessMM) : 0,
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTEndo): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.ToothNumberISO !== undefined &&
      (obj.ToothNumberISO = Math.round(message.ToothNumberISO));
    message.StepMM !== undefined && (obj.StepMM = message.StepMM);
    message.ThicknessMM !== undefined &&
      (obj.ThicknessMM = message.ThicknessMM);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTEndo>, I>>(
    object: I,
  ): ReportRequest_InputTypeCBCTEndo {
    const message = createBaseReportRequest_InputTypeCBCTEndo();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.ToothNumberISO = object.ToothNumberISO ?? 0;
    message.StepMM = object.StepMM ?? 0;
    message.ThicknessMM = object.ThicknessMM ?? 0;
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTImplant(): ReportRequest_InputTypeCBCTImplant {
  return {
    CBCTStudyID: '',
    StartToothNumberISO: 0,
    EndToothNumberISO: 0,
    StepMM: 0,
    ThicknessMM: 0,
  };
}

export const ReportRequest_InputTypeCBCTImplant = {
  encode(
    message: ReportRequest_InputTypeCBCTImplant,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.StartToothNumberISO !== 0) {
      writer.uint32(16).uint32(message.StartToothNumberISO);
    }
    if (message.EndToothNumberISO !== 0) {
      writer.uint32(24).uint32(message.EndToothNumberISO);
    }
    if (message.StepMM !== 0) {
      writer.uint32(37).float(message.StepMM);
    }
    if (message.ThicknessMM !== 0) {
      writer.uint32(45).float(message.ThicknessMM);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTImplant {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTImplant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.StartToothNumberISO = reader.uint32();
          break;
        case 3:
          message.EndToothNumberISO = reader.uint32();
          break;
        case 4:
          message.StepMM = reader.float();
          break;
        case 5:
          message.ThicknessMM = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTImplant {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      StartToothNumberISO: isSet(object.StartToothNumberISO)
        ? Number(object.StartToothNumberISO)
        : 0,
      EndToothNumberISO: isSet(object.EndToothNumberISO)
        ? Number(object.EndToothNumberISO)
        : 0,
      StepMM: isSet(object.StepMM) ? Number(object.StepMM) : 0,
      ThicknessMM: isSet(object.ThicknessMM) ? Number(object.ThicknessMM) : 0,
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTImplant): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.StartToothNumberISO !== undefined &&
      (obj.StartToothNumberISO = Math.round(message.StartToothNumberISO));
    message.EndToothNumberISO !== undefined &&
      (obj.EndToothNumberISO = Math.round(message.EndToothNumberISO));
    message.StepMM !== undefined && (obj.StepMM = message.StepMM);
    message.ThicknessMM !== undefined &&
      (obj.ThicknessMM = message.ThicknessMM);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTImplant>, I>,
  >(object: I): ReportRequest_InputTypeCBCTImplant {
    const message = createBaseReportRequest_InputTypeCBCTImplant();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.StartToothNumberISO = object.StartToothNumberISO ?? 0;
    message.EndToothNumberISO = object.EndToothNumberISO ?? 0;
    message.StepMM = object.StepMM ?? 0;
    message.ThicknessMM = object.ThicknessMM ?? 0;
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTMolar(): ReportRequest_InputTypeCBCTMolar {
  return { CBCTStudyID: '', ToothNumberISO: 0 };
}

export const ReportRequest_InputTypeCBCTMolar = {
  encode(
    message: ReportRequest_InputTypeCBCTMolar,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.ToothNumberISO !== 0) {
      writer.uint32(16).uint32(message.ToothNumberISO);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTMolar {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTMolar();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.ToothNumberISO = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTMolar {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      ToothNumberISO: isSet(object.ToothNumberISO)
        ? Number(object.ToothNumberISO)
        : 0,
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTMolar): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.ToothNumberISO !== undefined &&
      (obj.ToothNumberISO = Math.round(message.ToothNumberISO));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTMolar>, I>,
  >(object: I): ReportRequest_InputTypeCBCTMolar {
    const message = createBaseReportRequest_InputTypeCBCTMolar();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.ToothNumberISO = object.ToothNumberISO ?? 0;
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTOrtho(): ReportRequest_InputTypeCBCTOrtho {
  return { CBCTStudyID: '', Language: '' };
}

export const ReportRequest_InputTypeCBCTOrtho = {
  encode(
    message: ReportRequest_InputTypeCBCTOrtho,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.Language !== '') {
      writer.uint32(18).string(message.Language);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTOrtho {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTOrtho();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.Language = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTOrtho {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      Language: isSet(object.Language) ? String(object.Language) : '',
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTOrtho): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.Language !== undefined && (obj.Language = message.Language);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTOrtho>, I>,
  >(object: I): ReportRequest_InputTypeCBCTOrtho {
    const message = createBaseReportRequest_InputTypeCBCTOrtho();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.Language = object.Language ?? '';
    return message;
  },
};

function createBaseReportRequest_InputTypePanoGP(): ReportRequest_InputTypePanoGP {
  return { PanoramicXRayStudyID: '' };
}

export const ReportRequest_InputTypePanoGP = {
  encode(
    message: ReportRequest_InputTypePanoGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PanoramicXRayStudyID !== '') {
      writer.uint32(10).string(message.PanoramicXRayStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypePanoGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypePanoGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PanoramicXRayStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypePanoGP {
    return {
      PanoramicXRayStudyID: isSet(object.PanoramicXRayStudyID)
        ? String(object.PanoramicXRayStudyID)
        : '',
    };
  },

  toJSON(message: ReportRequest_InputTypePanoGP): unknown {
    const obj: any = {};
    message.PanoramicXRayStudyID !== undefined &&
      (obj.PanoramicXRayStudyID = message.PanoramicXRayStudyID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportRequest_InputTypePanoGP>, I>>(
    object: I,
  ): ReportRequest_InputTypePanoGP {
    const message = createBaseReportRequest_InputTypePanoGP();
    message.PanoramicXRayStudyID = object.PanoramicXRayStudyID ?? '';
    return message;
  },
};

function createBaseReportRequest_InputTypeIOXRayGP(): ReportRequest_InputTypeIOXRayGP {
  return { IntraoralXRayStudyID: '' };
}

export const ReportRequest_InputTypeIOXRayGP = {
  encode(
    message: ReportRequest_InputTypeIOXRayGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IntraoralXRayStudyID !== '') {
      writer.uint32(10).string(message.IntraoralXRayStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeIOXRayGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeIOXRayGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IntraoralXRayStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeIOXRayGP {
    return {
      IntraoralXRayStudyID: isSet(object.IntraoralXRayStudyID)
        ? String(object.IntraoralXRayStudyID)
        : '',
    };
  },

  toJSON(message: ReportRequest_InputTypeIOXRayGP): unknown {
    const obj: any = {};
    message.IntraoralXRayStudyID !== undefined &&
      (obj.IntraoralXRayStudyID = message.IntraoralXRayStudyID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportRequest_InputTypeIOXRayGP>, I>>(
    object: I,
  ): ReportRequest_InputTypeIOXRayGP {
    const message = createBaseReportRequest_InputTypeIOXRayGP();
    message.IntraoralXRayStudyID = object.IntraoralXRayStudyID ?? '';
    return message;
  },
};

function createBaseReportRequest_InputTypeDentalPhotoGP(): ReportRequest_InputTypeDentalPhotoGP {
  return { DentalPhotoStudyID: '' };
}

export const ReportRequest_InputTypeDentalPhotoGP = {
  encode(
    message: ReportRequest_InputTypeDentalPhotoGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DentalPhotoStudyID !== '') {
      writer.uint32(10).string(message.DentalPhotoStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeDentalPhotoGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeDentalPhotoGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DentalPhotoStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeDentalPhotoGP {
    return {
      DentalPhotoStudyID: isSet(object.DentalPhotoStudyID)
        ? String(object.DentalPhotoStudyID)
        : '',
    };
  },

  toJSON(message: ReportRequest_InputTypeDentalPhotoGP): unknown {
    const obj: any = {};
    message.DentalPhotoStudyID !== undefined &&
      (obj.DentalPhotoStudyID = message.DentalPhotoStudyID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeDentalPhotoGP>, I>,
  >(object: I): ReportRequest_InputTypeDentalPhotoGP {
    const message = createBaseReportRequest_InputTypeDentalPhotoGP();
    message.DentalPhotoStudyID = object.DentalPhotoStudyID ?? '';
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTSegmentation(): ReportRequest_InputTypeCBCTSegmentation {
  return { CBCTStudyID: '', Segments: [] };
}

export const ReportRequest_InputTypeCBCTSegmentation = {
  encode(
    message: ReportRequest_InputTypeCBCTSegmentation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    for (const v of message.Segments) {
      ReportRequest_InputTypeCBCTSegmentation_CBCTSegments.encode(
        v!,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTSegmentation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTSegmentation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.Segments.push(
            ReportRequest_InputTypeCBCTSegmentation_CBCTSegments.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTSegmentation {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      Segments: Array.isArray(object?.Segments)
        ? object.Segments.map((e: any) =>
            ReportRequest_InputTypeCBCTSegmentation_CBCTSegments.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTSegmentation): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    if (message.Segments) {
      obj.Segments = message.Segments.map((e) =>
        e
          ? ReportRequest_InputTypeCBCTSegmentation_CBCTSegments.toJSON(e)
          : undefined,
      );
    } else {
      obj.Segments = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTSegmentation>, I>,
  >(object: I): ReportRequest_InputTypeCBCTSegmentation {
    const message = createBaseReportRequest_InputTypeCBCTSegmentation();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.Segments =
      object.Segments?.map((e) =>
        ReportRequest_InputTypeCBCTSegmentation_CBCTSegments.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTSegmentation_CBCTSegments(): ReportRequest_InputTypeCBCTSegmentation_CBCTSegments {
  return { CBCTTeethISO: [], Anatomy: [] };
}

export const ReportRequest_InputTypeCBCTSegmentation_CBCTSegments = {
  encode(
    message: ReportRequest_InputTypeCBCTSegmentation_CBCTSegments,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.CBCTTeethISO) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.Anatomy) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTSegmentation_CBCTSegments {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseReportRequest_InputTypeCBCTSegmentation_CBCTSegments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.CBCTTeethISO.push(reader.uint32());
            }
          } else {
            message.CBCTTeethISO.push(reader.uint32());
          }
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.Anatomy.push(reader.int32() as any);
            }
          } else {
            message.Anatomy.push(reader.int32() as any);
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTSegmentation_CBCTSegments {
    return {
      CBCTTeethISO: Array.isArray(object?.CBCTTeethISO)
        ? object.CBCTTeethISO.map((e: any) => Number(e))
        : [],
      Anatomy: Array.isArray(object?.Anatomy)
        ? object.Anatomy.map((e: any) => cBCTAnatomyFromJSON(e))
        : [],
    };
  },

  toJSON(
    message: ReportRequest_InputTypeCBCTSegmentation_CBCTSegments,
  ): unknown {
    const obj: any = {};
    if (message.CBCTTeethISO) {
      obj.CBCTTeethISO = message.CBCTTeethISO.map((e) => Math.round(e));
    } else {
      obj.CBCTTeethISO = [];
    }
    if (message.Anatomy) {
      obj.Anatomy = message.Anatomy.map((e) => cBCTAnatomyToJSON(e));
    } else {
      obj.Anatomy = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportRequest_InputTypeCBCTSegmentation_CBCTSegments>,
      I
    >,
  >(object: I): ReportRequest_InputTypeCBCTSegmentation_CBCTSegments {
    const message =
      createBaseReportRequest_InputTypeCBCTSegmentation_CBCTSegments();
    message.CBCTTeethISO = object.CBCTTeethISO?.map((e) => e) || [];
    message.Anatomy = object.Anatomy?.map((e) => e) || [];
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTIOSSuperimposition(): ReportRequest_InputTypeCBCTIOSSuperimposition {
  return { CBCTStudyID: '', STLStudyID: '', Orientation: 0, Segments: [] };
}

export const ReportRequest_InputTypeCBCTIOSSuperimposition = {
  encode(
    message: ReportRequest_InputTypeCBCTIOSSuperimposition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.STLStudyID !== '') {
      writer.uint32(18).string(message.STLStudyID);
    }
    if (message.Orientation !== 0) {
      writer.uint32(24).int32(message.Orientation);
    }
    for (const v of message.Segments) {
      ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments.encode(
        v!,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTIOSSuperimposition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTIOSSuperimposition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.STLStudyID = reader.string();
          break;
        case 3:
          message.Orientation = reader.int32() as any;
          break;
        case 4:
          message.Segments.push(
            ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTIOSSuperimposition {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      STLStudyID: isSet(object.STLStudyID) ? String(object.STLStudyID) : '',
      Orientation: isSet(object.Orientation)
        ? reportCBCTIOSSuperimpositionOrientationFromJSON(object.Orientation)
        : 0,
      Segments: Array.isArray(object?.Segments)
        ? object.Segments.map((e: any) =>
            ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments.fromJSON(
              e,
            ),
          )
        : [],
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTIOSSuperimposition): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.STLStudyID !== undefined && (obj.STLStudyID = message.STLStudyID);
    message.Orientation !== undefined &&
      (obj.Orientation = reportCBCTIOSSuperimpositionOrientationToJSON(
        message.Orientation,
      ));
    if (message.Segments) {
      obj.Segments = message.Segments.map((e) =>
        e
          ? ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments.toJSON(
              e,
            )
          : undefined,
      );
    } else {
      obj.Segments = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportRequest_InputTypeCBCTIOSSuperimposition>,
      I
    >,
  >(object: I): ReportRequest_InputTypeCBCTIOSSuperimposition {
    const message = createBaseReportRequest_InputTypeCBCTIOSSuperimposition();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.STLStudyID = object.STLStudyID ?? '';
    message.Orientation = object.Orientation ?? 0;
    message.Segments =
      object.Segments?.map((e) =>
        ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments.fromPartial(
          e,
        ),
      ) || [];
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments(): ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments {
  return { CBCTTeethISO: [], IOSTeethISO: [], FusedTeethISO: [], Anatomy: [] };
}

export const ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments = {
  encode(
    message: ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.CBCTTeethISO) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.IOSTeethISO) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.FusedTeethISO) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.Anatomy) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.CBCTTeethISO.push(reader.uint32());
            }
          } else {
            message.CBCTTeethISO.push(reader.uint32());
          }
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.IOSTeethISO.push(reader.uint32());
            }
          } else {
            message.IOSTeethISO.push(reader.uint32());
          }
          break;
        case 3:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.FusedTeethISO.push(reader.uint32());
            }
          } else {
            message.FusedTeethISO.push(reader.uint32());
          }
          break;
        case 4:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.Anatomy.push(reader.int32() as any);
            }
          } else {
            message.Anatomy.push(reader.int32() as any);
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments {
    return {
      CBCTTeethISO: Array.isArray(object?.CBCTTeethISO)
        ? object.CBCTTeethISO.map((e: any) => Number(e))
        : [],
      IOSTeethISO: Array.isArray(object?.IOSTeethISO)
        ? object.IOSTeethISO.map((e: any) => Number(e))
        : [],
      FusedTeethISO: Array.isArray(object?.FusedTeethISO)
        ? object.FusedTeethISO.map((e: any) => Number(e))
        : [],
      Anatomy: Array.isArray(object?.Anatomy)
        ? object.Anatomy.map((e: any) => cBCTAnatomyFromJSON(e))
        : [],
    };
  },

  toJSON(
    message: ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments,
  ): unknown {
    const obj: any = {};
    if (message.CBCTTeethISO) {
      obj.CBCTTeethISO = message.CBCTTeethISO.map((e) => Math.round(e));
    } else {
      obj.CBCTTeethISO = [];
    }
    if (message.IOSTeethISO) {
      obj.IOSTeethISO = message.IOSTeethISO.map((e) => Math.round(e));
    } else {
      obj.IOSTeethISO = [];
    }
    if (message.FusedTeethISO) {
      obj.FusedTeethISO = message.FusedTeethISO.map((e) => Math.round(e));
    } else {
      obj.FusedTeethISO = [];
    }
    if (message.Anatomy) {
      obj.Anatomy = message.Anatomy.map((e) => cBCTAnatomyToJSON(e));
    } else {
      obj.Anatomy = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments>,
      I
    >,
  >(object: I): ReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments {
    const message =
      createBaseReportRequest_InputTypeCBCTIOSSuperimposition_CBCTIOSSegments();
    message.CBCTTeethISO = object.CBCTTeethISO?.map((e) => e) || [];
    message.IOSTeethISO = object.IOSTeethISO?.map((e) => e) || [];
    message.FusedTeethISO = object.FusedTeethISO?.map((e) => e) || [];
    message.Anatomy = object.Anatomy?.map((e) => e) || [];
    return message;
  },
};

function createBaseReportRequest_InputTypeImplantStudio(): ReportRequest_InputTypeImplantStudio {
  return { CBCTStudyID: '', IOSSTLStudyID: '' };
}

export const ReportRequest_InputTypeImplantStudio = {
  encode(
    message: ReportRequest_InputTypeImplantStudio,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.IOSSTLStudyID !== '') {
      writer.uint32(18).string(message.IOSSTLStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeImplantStudio {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeImplantStudio();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.IOSSTLStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeImplantStudio {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      IOSSTLStudyID: isSet(object.IOSSTLStudyID)
        ? String(object.IOSSTLStudyID)
        : '',
    };
  },

  toJSON(message: ReportRequest_InputTypeImplantStudio): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.IOSSTLStudyID !== undefined &&
      (obj.IOSSTLStudyID = message.IOSSTLStudyID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeImplantStudio>, I>,
  >(object: I): ReportRequest_InputTypeImplantStudio {
    const message = createBaseReportRequest_InputTypeImplantStudio();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.IOSSTLStudyID = object.IOSSTLStudyID ?? '';
    return message;
  },
};

function createBaseReportRequest_InputTypeOrthoStudio(): ReportRequest_InputTypeOrthoStudio {
  return { CBCTStudyID: '', IOSSTLStudyID: '' };
}

export const ReportRequest_InputTypeOrthoStudio = {
  encode(
    message: ReportRequest_InputTypeOrthoStudio,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.IOSSTLStudyID !== '') {
      writer.uint32(18).string(message.IOSSTLStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeOrthoStudio {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeOrthoStudio();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.IOSSTLStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeOrthoStudio {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      IOSSTLStudyID: isSet(object.IOSSTLStudyID)
        ? String(object.IOSSTLStudyID)
        : '',
    };
  },

  toJSON(message: ReportRequest_InputTypeOrthoStudio): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.IOSSTLStudyID !== undefined &&
      (obj.IOSSTLStudyID = message.IOSSTLStudyID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeOrthoStudio>, I>,
  >(object: I): ReportRequest_InputTypeOrthoStudio {
    const message = createBaseReportRequest_InputTypeOrthoStudio();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.IOSSTLStudyID = object.IOSSTLStudyID ?? '';
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
