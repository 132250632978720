import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import { Icon } from '@/shared/ui';

import styles from './DoctorRow.module.scss';

type DoctorRowProps = {
  className?: string;
  title?: JSX.Element | string;
  description?: JSX.Element | string;
  avatar?: ReactNode;
  disabled?: boolean;
  onShare?: () => void;
  onDelete?: () => void;
};

export const DoctorRow: FC<DoctorRowProps> = (props) => {
  const { className, title, description, avatar, disabled, onShare, onDelete } =
    props;

  return (
    <div
      className={cn(styles.container, disabled && styles.disabled, className)}
    >
      {avatar}
      <div className={styles.content}>
        <h5 className={cn(styles.title, 'p2')}>{title}</h5>
        <p className={cn(styles.description, 'p3')}>{description}</p>
      </div>
      <div className={styles.controls}>
        {onShare ? (
          <button type="button" className={styles.button} onClick={onShare}>
            <Icon name="share" size={32} />
          </button>
        ) : null}
        {onDelete ? (
          <button type="button" className={styles.button} onClick={onDelete}>
            <Icon name="delete" size={32} />
          </button>
        ) : null}
      </div>
    </div>
  );
};
