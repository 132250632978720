import { BrowserHeaders } from 'browser-headers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeepPartial } from 'react-hook-form';

import { ApiError } from '../api/api';

type RequestFunction<Request, Response> = (
  request: Request,
  metadata?: BrowserHeaders,
) => Promise<Response>;

// TODO: Remove after testing and refactoring all thunks with createThunkGenerator
export const generateThunk = <Request, Response, RequestName>(
  sliceName: string,
  requestName: RequestName,
  requestFunction: RequestFunction<DeepPartial<Request>, Response>,
) =>
  createAsyncThunk(
    `${sliceName}/${requestName}`,
    async (request: DeepPartial<Request>, { rejectWithValue }) => {
      try {
        const response = (await requestFunction(
          request,
        )) as unknown as Response;

        return response;
      } catch (error: unknown) {
        const { message, type } = error as ApiError;

        return rejectWithValue({ message, type });
      }
    },
  );
