/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Service } from '../../api/billing/dto_services';

export const protobufPackage = 'com.diagnocat.billing';

export enum SubscriptionStateStatus {
  SubscriptionStateStatusInvalidValue = 0,
  SubscriptionStateStatusActive = 1,
  SubscriptionStateStatusBlockedForNonPayment = 2,
  SubscriptionStateStatusLifeTimeIsOver = 3,
  UNRECOGNIZED = -1,
}

export function subscriptionStateStatusFromJSON(
  object: any,
): SubscriptionStateStatus {
  switch (object) {
    case 0:
    case 'SubscriptionStateStatusInvalidValue':
      return SubscriptionStateStatus.SubscriptionStateStatusInvalidValue;
    case 1:
    case 'SubscriptionStateStatusActive':
      return SubscriptionStateStatus.SubscriptionStateStatusActive;
    case 2:
    case 'SubscriptionStateStatusBlockedForNonPayment':
      return SubscriptionStateStatus.SubscriptionStateStatusBlockedForNonPayment;
    case 3:
    case 'SubscriptionStateStatusLifeTimeIsOver':
      return SubscriptionStateStatus.SubscriptionStateStatusLifeTimeIsOver;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SubscriptionStateStatus.UNRECOGNIZED;
  }
}

export function subscriptionStateStatusToJSON(
  object: SubscriptionStateStatus,
): string {
  switch (object) {
    case SubscriptionStateStatus.SubscriptionStateStatusInvalidValue:
      return 'SubscriptionStateStatusInvalidValue';
    case SubscriptionStateStatus.SubscriptionStateStatusActive:
      return 'SubscriptionStateStatusActive';
    case SubscriptionStateStatus.SubscriptionStateStatusBlockedForNonPayment:
      return 'SubscriptionStateStatusBlockedForNonPayment';
    case SubscriptionStateStatus.SubscriptionStateStatusLifeTimeIsOver:
      return 'SubscriptionStateStatusLifeTimeIsOver';
    case SubscriptionStateStatus.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum SubscriptionModelGroupName {
  SubscriptionModelGroupNameInvalidValue = 0,
  SubscriptionModelGroupNameDentalPractice = 1,
  SubscriptionModelGroupNameDigitalSegmentation = 2,
  SubscriptionModelGroupNameMaintenancePlans = 3,
  SubscriptionModelGroupNameDentalClinic = 4,
  SubscriptionModelGroupNameImagingCenter = 5,
  SubscriptionModelGroupNameDigitalLab = 6,
  SubscriptionModelGroupNameClinicalTestingPlans = 7,
  UNRECOGNIZED = -1,
}

export function subscriptionModelGroupNameFromJSON(
  object: any,
): SubscriptionModelGroupName {
  switch (object) {
    case 0:
    case 'SubscriptionModelGroupNameInvalidValue':
      return SubscriptionModelGroupName.SubscriptionModelGroupNameInvalidValue;
    case 1:
    case 'SubscriptionModelGroupNameDentalPractice':
      return SubscriptionModelGroupName.SubscriptionModelGroupNameDentalPractice;
    case 2:
    case 'SubscriptionModelGroupNameDigitalSegmentation':
      return SubscriptionModelGroupName.SubscriptionModelGroupNameDigitalSegmentation;
    case 3:
    case 'SubscriptionModelGroupNameMaintenancePlans':
      return SubscriptionModelGroupName.SubscriptionModelGroupNameMaintenancePlans;
    case 4:
    case 'SubscriptionModelGroupNameDentalClinic':
      return SubscriptionModelGroupName.SubscriptionModelGroupNameDentalClinic;
    case 5:
    case 'SubscriptionModelGroupNameImagingCenter':
      return SubscriptionModelGroupName.SubscriptionModelGroupNameImagingCenter;
    case 6:
    case 'SubscriptionModelGroupNameDigitalLab':
      return SubscriptionModelGroupName.SubscriptionModelGroupNameDigitalLab;
    case 7:
    case 'SubscriptionModelGroupNameClinicalTestingPlans':
      return SubscriptionModelGroupName.SubscriptionModelGroupNameClinicalTestingPlans;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SubscriptionModelGroupName.UNRECOGNIZED;
  }
}

export function subscriptionModelGroupNameToJSON(
  object: SubscriptionModelGroupName,
): string {
  switch (object) {
    case SubscriptionModelGroupName.SubscriptionModelGroupNameInvalidValue:
      return 'SubscriptionModelGroupNameInvalidValue';
    case SubscriptionModelGroupName.SubscriptionModelGroupNameDentalPractice:
      return 'SubscriptionModelGroupNameDentalPractice';
    case SubscriptionModelGroupName.SubscriptionModelGroupNameDigitalSegmentation:
      return 'SubscriptionModelGroupNameDigitalSegmentation';
    case SubscriptionModelGroupName.SubscriptionModelGroupNameMaintenancePlans:
      return 'SubscriptionModelGroupNameMaintenancePlans';
    case SubscriptionModelGroupName.SubscriptionModelGroupNameDentalClinic:
      return 'SubscriptionModelGroupNameDentalClinic';
    case SubscriptionModelGroupName.SubscriptionModelGroupNameImagingCenter:
      return 'SubscriptionModelGroupNameImagingCenter';
    case SubscriptionModelGroupName.SubscriptionModelGroupNameDigitalLab:
      return 'SubscriptionModelGroupNameDigitalLab';
    case SubscriptionModelGroupName.SubscriptionModelGroupNameClinicalTestingPlans:
      return 'SubscriptionModelGroupNameClinicalTestingPlans';
    case SubscriptionModelGroupName.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface SubscriptionState {
  ID: string;
  AccountID: string;
  Model: SubscriptionModel | undefined;
  LifeTime: SubscriptionState_SubscriptionLifeTime | undefined;
  AutoRenewal: boolean;
  AutoCharge: boolean;
  Paid: boolean;
  Status: SubscriptionStateStatus;
  SelectedPlan: SubscriptionModelPlan | undefined;
  Services: Service[];
}

export interface SubscriptionState_SubscriptionLifeTime {
  Start: number;
  End: number;
}

export interface SubscriptionModel {
  ID: string;
  Name: string;
  Services: Service[];
  GeoZone: string;
  Currency: string;
  OverdraftIsPossible: boolean;
  AvailableForAdminsOnly: boolean;
  GroupName: SubscriptionModelGroupName;
  SubscriptionPlans: SubscriptionModel_PlansForSubscription | undefined;
  PackagePlan: SubscriptionModel_PlanForPackage | undefined;
  TrialPlan: SubscriptionModel_PlanForTrial | undefined;
}

export interface SubscriptionModel_PlansForSubscription {
  Plans: SubscriptionModelPlan[];
}

export interface SubscriptionModel_PlanForPackage {
  Price: string;
  IsAddon: boolean;
  /** 0 means unlimited */
  LifeTimeMonths: number;
  ID: string;
  StripeProductID: string;
  Description: string;
}

export interface SubscriptionModel_PlanForTrial {
  Days: number;
  ID: string;
  Description: string;
}

export interface SubscriptionModelPlan {
  Price: string;
  CrossedOutPrice: string;
  LifeTimeMonths: number;
  ID: string;
  StripeProductID: string;
  Description: string;
}

function createBaseSubscriptionState(): SubscriptionState {
  return {
    ID: '',
    AccountID: '',
    Model: undefined,
    LifeTime: undefined,
    AutoRenewal: false,
    AutoCharge: false,
    Paid: false,
    Status: 0,
    SelectedPlan: undefined,
    Services: [],
  };
}

export const SubscriptionState = {
  encode(
    message: SubscriptionState,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.AccountID !== '') {
      writer.uint32(18).string(message.AccountID);
    }
    if (message.Model !== undefined) {
      SubscriptionModel.encode(
        message.Model,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.LifeTime !== undefined) {
      SubscriptionState_SubscriptionLifeTime.encode(
        message.LifeTime,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.AutoRenewal === true) {
      writer.uint32(40).bool(message.AutoRenewal);
    }
    if (message.AutoCharge === true) {
      writer.uint32(48).bool(message.AutoCharge);
    }
    if (message.Paid === true) {
      writer.uint32(56).bool(message.Paid);
    }
    if (message.Status !== 0) {
      writer.uint32(64).int32(message.Status);
    }
    if (message.SelectedPlan !== undefined) {
      SubscriptionModelPlan.encode(
        message.SelectedPlan,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    for (const v of message.Services) {
      Service.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubscriptionState {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.AccountID = reader.string();
          break;
        case 3:
          message.Model = SubscriptionModel.decode(reader, reader.uint32());
          break;
        case 4:
          message.LifeTime = SubscriptionState_SubscriptionLifeTime.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.AutoRenewal = reader.bool();
          break;
        case 6:
          message.AutoCharge = reader.bool();
          break;
        case 7:
          message.Paid = reader.bool();
          break;
        case 8:
          message.Status = reader.int32() as any;
          break;
        case 9:
          message.SelectedPlan = SubscriptionModelPlan.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10:
          message.Services.push(Service.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SubscriptionState {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      AccountID: isSet(object.AccountID) ? String(object.AccountID) : '',
      Model: isSet(object.Model)
        ? SubscriptionModel.fromJSON(object.Model)
        : undefined,
      LifeTime: isSet(object.LifeTime)
        ? SubscriptionState_SubscriptionLifeTime.fromJSON(object.LifeTime)
        : undefined,
      AutoRenewal: isSet(object.AutoRenewal)
        ? Boolean(object.AutoRenewal)
        : false,
      AutoCharge: isSet(object.AutoCharge) ? Boolean(object.AutoCharge) : false,
      Paid: isSet(object.Paid) ? Boolean(object.Paid) : false,
      Status: isSet(object.Status)
        ? subscriptionStateStatusFromJSON(object.Status)
        : 0,
      SelectedPlan: isSet(object.SelectedPlan)
        ? SubscriptionModelPlan.fromJSON(object.SelectedPlan)
        : undefined,
      Services: Array.isArray(object?.Services)
        ? object.Services.map((e: any) => Service.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SubscriptionState): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.AccountID !== undefined && (obj.AccountID = message.AccountID);
    message.Model !== undefined &&
      (obj.Model = message.Model
        ? SubscriptionModel.toJSON(message.Model)
        : undefined);
    message.LifeTime !== undefined &&
      (obj.LifeTime = message.LifeTime
        ? SubscriptionState_SubscriptionLifeTime.toJSON(message.LifeTime)
        : undefined);
    message.AutoRenewal !== undefined &&
      (obj.AutoRenewal = message.AutoRenewal);
    message.AutoCharge !== undefined && (obj.AutoCharge = message.AutoCharge);
    message.Paid !== undefined && (obj.Paid = message.Paid);
    message.Status !== undefined &&
      (obj.Status = subscriptionStateStatusToJSON(message.Status));
    message.SelectedPlan !== undefined &&
      (obj.SelectedPlan = message.SelectedPlan
        ? SubscriptionModelPlan.toJSON(message.SelectedPlan)
        : undefined);
    if (message.Services) {
      obj.Services = message.Services.map((e) =>
        e ? Service.toJSON(e) : undefined,
      );
    } else {
      obj.Services = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionState>, I>>(
    object: I,
  ): SubscriptionState {
    const message = createBaseSubscriptionState();
    message.ID = object.ID ?? '';
    message.AccountID = object.AccountID ?? '';
    message.Model =
      object.Model !== undefined && object.Model !== null
        ? SubscriptionModel.fromPartial(object.Model)
        : undefined;
    message.LifeTime =
      object.LifeTime !== undefined && object.LifeTime !== null
        ? SubscriptionState_SubscriptionLifeTime.fromPartial(object.LifeTime)
        : undefined;
    message.AutoRenewal = object.AutoRenewal ?? false;
    message.AutoCharge = object.AutoCharge ?? false;
    message.Paid = object.Paid ?? false;
    message.Status = object.Status ?? 0;
    message.SelectedPlan =
      object.SelectedPlan !== undefined && object.SelectedPlan !== null
        ? SubscriptionModelPlan.fromPartial(object.SelectedPlan)
        : undefined;
    message.Services =
      object.Services?.map((e) => Service.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSubscriptionState_SubscriptionLifeTime(): SubscriptionState_SubscriptionLifeTime {
  return { Start: 0, End: 0 };
}

export const SubscriptionState_SubscriptionLifeTime = {
  encode(
    message: SubscriptionState_SubscriptionLifeTime,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Start !== 0) {
      writer.uint32(8).int64(message.Start);
    }
    if (message.End !== 0) {
      writer.uint32(16).int64(message.End);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubscriptionState_SubscriptionLifeTime {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionState_SubscriptionLifeTime();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Start = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.End = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SubscriptionState_SubscriptionLifeTime {
    return {
      Start: isSet(object.Start) ? Number(object.Start) : 0,
      End: isSet(object.End) ? Number(object.End) : 0,
    };
  },

  toJSON(message: SubscriptionState_SubscriptionLifeTime): unknown {
    const obj: any = {};
    message.Start !== undefined && (obj.Start = Math.round(message.Start));
    message.End !== undefined && (obj.End = Math.round(message.End));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SubscriptionState_SubscriptionLifeTime>, I>,
  >(object: I): SubscriptionState_SubscriptionLifeTime {
    const message = createBaseSubscriptionState_SubscriptionLifeTime();
    message.Start = object.Start ?? 0;
    message.End = object.End ?? 0;
    return message;
  },
};

function createBaseSubscriptionModel(): SubscriptionModel {
  return {
    ID: '',
    Name: '',
    Services: [],
    GeoZone: '',
    Currency: '',
    OverdraftIsPossible: false,
    AvailableForAdminsOnly: false,
    GroupName: 0,
    SubscriptionPlans: undefined,
    PackagePlan: undefined,
    TrialPlan: undefined,
  };
}

export const SubscriptionModel = {
  encode(
    message: SubscriptionModel,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== '') {
      writer.uint32(18).string(message.Name);
    }
    for (const v of message.Services) {
      Service.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.GeoZone !== '') {
      writer.uint32(34).string(message.GeoZone);
    }
    if (message.Currency !== '') {
      writer.uint32(42).string(message.Currency);
    }
    if (message.OverdraftIsPossible === true) {
      writer.uint32(48).bool(message.OverdraftIsPossible);
    }
    if (message.AvailableForAdminsOnly === true) {
      writer.uint32(56).bool(message.AvailableForAdminsOnly);
    }
    if (message.GroupName !== 0) {
      writer.uint32(64).int32(message.GroupName);
    }
    if (message.SubscriptionPlans !== undefined) {
      SubscriptionModel_PlansForSubscription.encode(
        message.SubscriptionPlans,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    if (message.PackagePlan !== undefined) {
      SubscriptionModel_PlanForPackage.encode(
        message.PackagePlan,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    if (message.TrialPlan !== undefined) {
      SubscriptionModel_PlanForTrial.encode(
        message.TrialPlan,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubscriptionModel {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Services.push(Service.decode(reader, reader.uint32()));
          break;
        case 4:
          message.GeoZone = reader.string();
          break;
        case 5:
          message.Currency = reader.string();
          break;
        case 6:
          message.OverdraftIsPossible = reader.bool();
          break;
        case 7:
          message.AvailableForAdminsOnly = reader.bool();
          break;
        case 8:
          message.GroupName = reader.int32() as any;
          break;
        case 9:
          message.SubscriptionPlans =
            SubscriptionModel_PlansForSubscription.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 10:
          message.PackagePlan = SubscriptionModel_PlanForPackage.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 11:
          message.TrialPlan = SubscriptionModel_PlanForTrial.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SubscriptionModel {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Name: isSet(object.Name) ? String(object.Name) : '',
      Services: Array.isArray(object?.Services)
        ? object.Services.map((e: any) => Service.fromJSON(e))
        : [],
      GeoZone: isSet(object.GeoZone) ? String(object.GeoZone) : '',
      Currency: isSet(object.Currency) ? String(object.Currency) : '',
      OverdraftIsPossible: isSet(object.OverdraftIsPossible)
        ? Boolean(object.OverdraftIsPossible)
        : false,
      AvailableForAdminsOnly: isSet(object.AvailableForAdminsOnly)
        ? Boolean(object.AvailableForAdminsOnly)
        : false,
      GroupName: isSet(object.GroupName)
        ? subscriptionModelGroupNameFromJSON(object.GroupName)
        : 0,
      SubscriptionPlans: isSet(object.SubscriptionPlans)
        ? SubscriptionModel_PlansForSubscription.fromJSON(
            object.SubscriptionPlans,
          )
        : undefined,
      PackagePlan: isSet(object.PackagePlan)
        ? SubscriptionModel_PlanForPackage.fromJSON(object.PackagePlan)
        : undefined,
      TrialPlan: isSet(object.TrialPlan)
        ? SubscriptionModel_PlanForTrial.fromJSON(object.TrialPlan)
        : undefined,
    };
  },

  toJSON(message: SubscriptionModel): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    if (message.Services) {
      obj.Services = message.Services.map((e) =>
        e ? Service.toJSON(e) : undefined,
      );
    } else {
      obj.Services = [];
    }
    message.GeoZone !== undefined && (obj.GeoZone = message.GeoZone);
    message.Currency !== undefined && (obj.Currency = message.Currency);
    message.OverdraftIsPossible !== undefined &&
      (obj.OverdraftIsPossible = message.OverdraftIsPossible);
    message.AvailableForAdminsOnly !== undefined &&
      (obj.AvailableForAdminsOnly = message.AvailableForAdminsOnly);
    message.GroupName !== undefined &&
      (obj.GroupName = subscriptionModelGroupNameToJSON(message.GroupName));
    message.SubscriptionPlans !== undefined &&
      (obj.SubscriptionPlans = message.SubscriptionPlans
        ? SubscriptionModel_PlansForSubscription.toJSON(
            message.SubscriptionPlans,
          )
        : undefined);
    message.PackagePlan !== undefined &&
      (obj.PackagePlan = message.PackagePlan
        ? SubscriptionModel_PlanForPackage.toJSON(message.PackagePlan)
        : undefined);
    message.TrialPlan !== undefined &&
      (obj.TrialPlan = message.TrialPlan
        ? SubscriptionModel_PlanForTrial.toJSON(message.TrialPlan)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionModel>, I>>(
    object: I,
  ): SubscriptionModel {
    const message = createBaseSubscriptionModel();
    message.ID = object.ID ?? '';
    message.Name = object.Name ?? '';
    message.Services =
      object.Services?.map((e) => Service.fromPartial(e)) || [];
    message.GeoZone = object.GeoZone ?? '';
    message.Currency = object.Currency ?? '';
    message.OverdraftIsPossible = object.OverdraftIsPossible ?? false;
    message.AvailableForAdminsOnly = object.AvailableForAdminsOnly ?? false;
    message.GroupName = object.GroupName ?? 0;
    message.SubscriptionPlans =
      object.SubscriptionPlans !== undefined &&
      object.SubscriptionPlans !== null
        ? SubscriptionModel_PlansForSubscription.fromPartial(
            object.SubscriptionPlans,
          )
        : undefined;
    message.PackagePlan =
      object.PackagePlan !== undefined && object.PackagePlan !== null
        ? SubscriptionModel_PlanForPackage.fromPartial(object.PackagePlan)
        : undefined;
    message.TrialPlan =
      object.TrialPlan !== undefined && object.TrialPlan !== null
        ? SubscriptionModel_PlanForTrial.fromPartial(object.TrialPlan)
        : undefined;
    return message;
  },
};

function createBaseSubscriptionModel_PlansForSubscription(): SubscriptionModel_PlansForSubscription {
  return { Plans: [] };
}

export const SubscriptionModel_PlansForSubscription = {
  encode(
    message: SubscriptionModel_PlansForSubscription,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Plans) {
      SubscriptionModelPlan.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubscriptionModel_PlansForSubscription {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionModel_PlansForSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Plans.push(
            SubscriptionModelPlan.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SubscriptionModel_PlansForSubscription {
    return {
      Plans: Array.isArray(object?.Plans)
        ? object.Plans.map((e: any) => SubscriptionModelPlan.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SubscriptionModel_PlansForSubscription): unknown {
    const obj: any = {};
    if (message.Plans) {
      obj.Plans = message.Plans.map((e) =>
        e ? SubscriptionModelPlan.toJSON(e) : undefined,
      );
    } else {
      obj.Plans = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SubscriptionModel_PlansForSubscription>, I>,
  >(object: I): SubscriptionModel_PlansForSubscription {
    const message = createBaseSubscriptionModel_PlansForSubscription();
    message.Plans =
      object.Plans?.map((e) => SubscriptionModelPlan.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSubscriptionModel_PlanForPackage(): SubscriptionModel_PlanForPackage {
  return {
    Price: '',
    IsAddon: false,
    LifeTimeMonths: 0,
    ID: '',
    StripeProductID: '',
    Description: '',
  };
}

export const SubscriptionModel_PlanForPackage = {
  encode(
    message: SubscriptionModel_PlanForPackage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Price !== '') {
      writer.uint32(10).string(message.Price);
    }
    if (message.IsAddon === true) {
      writer.uint32(16).bool(message.IsAddon);
    }
    if (message.LifeTimeMonths !== 0) {
      writer.uint32(24).int32(message.LifeTimeMonths);
    }
    if (message.ID !== '') {
      writer.uint32(34).string(message.ID);
    }
    if (message.StripeProductID !== '') {
      writer.uint32(42).string(message.StripeProductID);
    }
    if (message.Description !== '') {
      writer.uint32(50).string(message.Description);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubscriptionModel_PlanForPackage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionModel_PlanForPackage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Price = reader.string();
          break;
        case 2:
          message.IsAddon = reader.bool();
          break;
        case 3:
          message.LifeTimeMonths = reader.int32();
          break;
        case 4:
          message.ID = reader.string();
          break;
        case 5:
          message.StripeProductID = reader.string();
          break;
        case 6:
          message.Description = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SubscriptionModel_PlanForPackage {
    return {
      Price: isSet(object.Price) ? String(object.Price) : '',
      IsAddon: isSet(object.IsAddon) ? Boolean(object.IsAddon) : false,
      LifeTimeMonths: isSet(object.LifeTimeMonths)
        ? Number(object.LifeTimeMonths)
        : 0,
      ID: isSet(object.ID) ? String(object.ID) : '',
      StripeProductID: isSet(object.StripeProductID)
        ? String(object.StripeProductID)
        : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
    };
  },

  toJSON(message: SubscriptionModel_PlanForPackage): unknown {
    const obj: any = {};
    message.Price !== undefined && (obj.Price = message.Price);
    message.IsAddon !== undefined && (obj.IsAddon = message.IsAddon);
    message.LifeTimeMonths !== undefined &&
      (obj.LifeTimeMonths = Math.round(message.LifeTimeMonths));
    message.ID !== undefined && (obj.ID = message.ID);
    message.StripeProductID !== undefined &&
      (obj.StripeProductID = message.StripeProductID);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SubscriptionModel_PlanForPackage>, I>,
  >(object: I): SubscriptionModel_PlanForPackage {
    const message = createBaseSubscriptionModel_PlanForPackage();
    message.Price = object.Price ?? '';
    message.IsAddon = object.IsAddon ?? false;
    message.LifeTimeMonths = object.LifeTimeMonths ?? 0;
    message.ID = object.ID ?? '';
    message.StripeProductID = object.StripeProductID ?? '';
    message.Description = object.Description ?? '';
    return message;
  },
};

function createBaseSubscriptionModel_PlanForTrial(): SubscriptionModel_PlanForTrial {
  return { Days: 0, ID: '', Description: '' };
}

export const SubscriptionModel_PlanForTrial = {
  encode(
    message: SubscriptionModel_PlanForTrial,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Days !== 0) {
      writer.uint32(8).int32(message.Days);
    }
    if (message.ID !== '') {
      writer.uint32(18).string(message.ID);
    }
    if (message.Description !== '') {
      writer.uint32(26).string(message.Description);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubscriptionModel_PlanForTrial {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionModel_PlanForTrial();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Days = reader.int32();
          break;
        case 2:
          message.ID = reader.string();
          break;
        case 3:
          message.Description = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SubscriptionModel_PlanForTrial {
    return {
      Days: isSet(object.Days) ? Number(object.Days) : 0,
      ID: isSet(object.ID) ? String(object.ID) : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
    };
  },

  toJSON(message: SubscriptionModel_PlanForTrial): unknown {
    const obj: any = {};
    message.Days !== undefined && (obj.Days = Math.round(message.Days));
    message.ID !== undefined && (obj.ID = message.ID);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionModel_PlanForTrial>, I>>(
    object: I,
  ): SubscriptionModel_PlanForTrial {
    const message = createBaseSubscriptionModel_PlanForTrial();
    message.Days = object.Days ?? 0;
    message.ID = object.ID ?? '';
    message.Description = object.Description ?? '';
    return message;
  },
};

function createBaseSubscriptionModelPlan(): SubscriptionModelPlan {
  return {
    Price: '',
    CrossedOutPrice: '',
    LifeTimeMonths: 0,
    ID: '',
    StripeProductID: '',
    Description: '',
  };
}

export const SubscriptionModelPlan = {
  encode(
    message: SubscriptionModelPlan,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Price !== '') {
      writer.uint32(10).string(message.Price);
    }
    if (message.CrossedOutPrice !== '') {
      writer.uint32(18).string(message.CrossedOutPrice);
    }
    if (message.LifeTimeMonths !== 0) {
      writer.uint32(24).int32(message.LifeTimeMonths);
    }
    if (message.ID !== '') {
      writer.uint32(34).string(message.ID);
    }
    if (message.StripeProductID !== '') {
      writer.uint32(42).string(message.StripeProductID);
    }
    if (message.Description !== '') {
      writer.uint32(50).string(message.Description);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SubscriptionModelPlan {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubscriptionModelPlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Price = reader.string();
          break;
        case 2:
          message.CrossedOutPrice = reader.string();
          break;
        case 3:
          message.LifeTimeMonths = reader.int32();
          break;
        case 4:
          message.ID = reader.string();
          break;
        case 5:
          message.StripeProductID = reader.string();
          break;
        case 6:
          message.Description = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SubscriptionModelPlan {
    return {
      Price: isSet(object.Price) ? String(object.Price) : '',
      CrossedOutPrice: isSet(object.CrossedOutPrice)
        ? String(object.CrossedOutPrice)
        : '',
      LifeTimeMonths: isSet(object.LifeTimeMonths)
        ? Number(object.LifeTimeMonths)
        : 0,
      ID: isSet(object.ID) ? String(object.ID) : '',
      StripeProductID: isSet(object.StripeProductID)
        ? String(object.StripeProductID)
        : '',
      Description: isSet(object.Description) ? String(object.Description) : '',
    };
  },

  toJSON(message: SubscriptionModelPlan): unknown {
    const obj: any = {};
    message.Price !== undefined && (obj.Price = message.Price);
    message.CrossedOutPrice !== undefined &&
      (obj.CrossedOutPrice = message.CrossedOutPrice);
    message.LifeTimeMonths !== undefined &&
      (obj.LifeTimeMonths = Math.round(message.LifeTimeMonths));
    message.ID !== undefined && (obj.ID = message.ID);
    message.StripeProductID !== undefined &&
      (obj.StripeProductID = message.StripeProductID);
    message.Description !== undefined &&
      (obj.Description = message.Description);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionModelPlan>, I>>(
    object: I,
  ): SubscriptionModelPlan {
    const message = createBaseSubscriptionModelPlan();
    message.Price = object.Price ?? '';
    message.CrossedOutPrice = object.CrossedOutPrice ?? '';
    message.LifeTimeMonths = object.LifeTimeMonths ?? 0;
    message.ID = object.ID ?? '';
    message.StripeProductID = object.StripeProductID ?? '';
    message.Description = object.Description ?? '';
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
