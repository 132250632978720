import { useAppSelector } from '@/shared/hooks';
import { DoctorOptionType, getUserFullName } from '@/entities/users';

import { selectOrganizationUsers } from '../model/selectors';

export const useOrganizationDoctorsOptions = (): DoctorOptionType[] => {
  const users = useAppSelector(selectOrganizationUsers) || [];

  return users.map((user) => ({
    label: getUserFullName(user),
    value: user?.ID,
    avatarSrc: user?.AvatarAssetID,
  }));
};
