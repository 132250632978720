import React, { FC, memo, useCallback } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { Slice } from '@/shared/ui';
import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { SliceLabelsTypes } from '@/widgets/ToothCard/config/types';
import { modalModel } from '@/entities/modal';
import { useAppDispatch } from '@/shared/hooks';

import { sliceLabels } from '../../config/i18n';
import { getSliceLabel } from '../../lib/getSliceLabel';

import styles from './SliceList.module.scss';

type SliceListProps = {
  slices?: { src: string; path?: string; annotations?: Annotation[] }[];
  className?: string;
};

export const SliceList: FC<SliceListProps> = memo((props) => {
  const { slices, className } = props;

  const dispatch = useAppDispatch();

  const handleOpenModal = useCallback(
    (src: string) => {
      dispatch(
        modalModel.actions.openZoomedSliceModal({
          src,
        }),
      );
    },
    [dispatch],
  );

  const { formatMessage } = useIntl();

  return (
    <div className={cn(styles.container, className)}>
      {slices?.map((tooth) => {
        const sliceLabel = getSliceLabel(tooth?.path) as SliceLabelsTypes;
        const label = formatMessage(sliceLabels[sliceLabel]);

        return (
          <Slice
            key={tooth.src}
            src={tooth.src}
            onClick={() => handleOpenModal(tooth.src)}
            sliceLabel={label}
            annotations={tooth.annotations}
          />
        );
      })}
    </div>
  );
});
