import {
  Service,
  ServiceType,
} from '@/shared/api/protocol_gen/api/billing/dto_services';
import {
  SubscriptionState,
  SubscriptionStateStatus,
} from '@/shared/api/protocol_gen/api/billing/dto_subscription';

export const INITIAL_SERVICES_FLAGS = {
  uploadCBCT: { isActive: true },
  uploadIOXRay: { isActive: true },
  uploadPanoramic: { isActive: true },
  uploadSTL: { isActive: true },
  uploadDentalPhoto: { isActive: true },
  uploadDocuments: { isActive: true },
  collaborationTool: { isActive: true },
  STLViewer: { isActive: true },
  radiologicalAnalysis: { isActive: true },
  endodonticAnalysis: { isActive: true },
  implantologyAnalysis: { isActive: true },
  STL: { isActive: true },
  thirdMolarAnalysis: { isActive: true },
  IOXRayAnalysis: { isActive: true },
  panoramicAnalysis: { isActive: true },
  CBCT: { isActive: true },
  superimpositionAnalysis: { isActive: true },
  orthodonticAnalysis: { isActive: true },
  dentalPhotoAnalysis: { isActive: true },
  IOSSegmentationAnalysis: { isActive: true },
};

export type ServicesFlags = typeof INITIAL_SERVICES_FLAGS;

export const SERVICES_LIST_UPLOADS: ServiceType[] = [
  ServiceType.ServiceType_Upload_CBCT,
  ServiceType.ServiceType_Upload_IOXRay,
  ServiceType.ServiceType_Upload_Panorama,
  ServiceType.ServiceType_Upload_DentalPhoto,
  ServiceType.ServiceType_Upload_Documents,
  ServiceType.ServiceType_Upload_STL,
];

export const SERVICES_LIST_NOT_COUNTABLE: ServiceType[] = [
  ServiceType.ServiceType_Enable_CollaborationTool,
  ServiceType.ServiceType_Enable_STLViewer,
];

export const SERVICES_LIST_COUNTABLE: ServiceType[] = [
  ServiceType.ServiceType_Order_CBCT_GP_Analysis,
  ServiceType.ServiceType_Order_CBCT_Endodontic_Analysis,
  ServiceType.ServiceType_Order_CBCT_Implantology_Analysis,
  ServiceType.ServiceType_Order_CBCT_STL_Analysis,
  ServiceType.ServiceType_Order_CBCT_ThirdMolar_Analysis,
  ServiceType.ServiceType_Order_IOXRay_GP_Analysis,
  ServiceType.ServiceType_Order_Panoramic_GP_Analysis,
  ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis,
  ServiceType.ServiceType_Order_CBCT_Orthodontic_Analysis,
  ServiceType.ServiceType_Order_DentalPhoto_GP_Analysis,
  ServiceType.ServiceType_Order_IOS_Segmentation_Analysis,
];

export const SERVICES_LIST_GROUPS: ServiceType[] = [
  ServiceType.ServiceType_Group_Order_3D_Analysis,
  ServiceType.ServiceType_Group_Order_2D_Analysis,
  ServiceType.ServiceType_Group_PrimaryStorage,
];

export const SERVICES_LIST_COMPOSE: ServiceType[] = [
  ...SERVICES_LIST_UPLOADS,
  ...SERVICES_LIST_NOT_COUNTABLE,
  ...SERVICES_LIST_COUNTABLE,
];

export const getServices = (subscriptionsList: SubscriptionState[]) => {
  const services = subscriptionsList.reduce(
    (result, subscription) => [...result, ...(subscription?.Services ?? [])],
    [] as Service[],
  );
  return services;
};

export const getIsActiveByServices = (services: Service[]) => {
  const isActive = services.some(
    (service) => !!service.Enabler || !!service?.Countable?.Leftover,
  );
  return isActive;
};

export const getServicesFlags = (subscriptionsList: SubscriptionState[]) => {
  const servicesList = getServices(
    subscriptionsList.filter(
      (subscription) =>
        subscription.Status ===
        SubscriptionStateStatus.SubscriptionStateStatusActive,
    ),
  );

  const flagsState = SERVICES_LIST_COMPOSE.map((currentService) => {
    const dependedServices = servicesList.filter(
      (service) =>
        service.Type === currentService ||
        service?.Children?.includes(currentService),
    );

    return {
      type: currentService,
      dependedServices,
      isActive: getIsActiveByServices(dependedServices),
    };
  });

  const flags = flagsState.reduce((result, state): ServicesFlags => {
    switch (state.type) {
      // upload flags cases
      case ServiceType.ServiceType_Upload_CBCT:
        return { ...result, uploadCBCT: state };
      case ServiceType.ServiceType_Upload_IOXRay:
        return { ...result, uploadIOXRay: state };
      case ServiceType.ServiceType_Upload_Panorama:
        return { ...result, uploadPanoramic: state };
      case ServiceType.ServiceType_Upload_STL:
        return { ...result, uploadSTL: state };
      case ServiceType.ServiceType_Upload_DentalPhoto:
        return { ...result, uploadDentalPhoto: state };
      case ServiceType.ServiceType_Upload_Documents:
        return { ...result, uploadDocuments: state };

      // not countable flags cases
      case ServiceType.ServiceType_Enable_CollaborationTool:
        return { ...result, collaborationTool: state };
      case ServiceType.ServiceType_Enable_STLViewer:
        return { ...result, STLViewer: state };

      // order analysis flags cases
      case ServiceType.ServiceType_Order_CBCT_GP_Analysis:
        return { ...result, radiologicalAnalysis: state };
      case ServiceType.ServiceType_Order_CBCT_Endodontic_Analysis:
        return { ...result, endodonticAnalysis: state };
      case ServiceType.ServiceType_Order_CBCT_Implantology_Analysis:
        return { ...result, implantologyAnalysis: state };
      case ServiceType.ServiceType_Order_CBCT_STL_Analysis:
        return { ...result, STL: state };
      case ServiceType.ServiceType_Order_CBCT_ThirdMolar_Analysis:
        return { ...result, thirdMolarAnalysis: state };
      case ServiceType.ServiceType_Order_IOXRay_GP_Analysis:
        return { ...result, IOXRayAnalysis: state };
      case ServiceType.ServiceType_Order_Panoramic_GP_Analysis:
        return { ...result, panoramicAnalysis: state };
      case ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis:
        return { ...result, superimpositionAnalysis: state };
      case ServiceType.ServiceType_Order_CBCT_Orthodontic_Analysis:
        return { ...result, orthodonticAnalysis: state };
      case ServiceType.ServiceType_Order_DentalPhoto_GP_Analysis:
        return { ...result, dentalPhotoAnalysis: state };
      case ServiceType.ServiceType_Order_IOS_Segmentation_Analysis:
        return { ...result, IOSSegmentationAnalysis: state };
      default:
        return result;
    }
  }, INITIAL_SERVICES_FLAGS);

  const isCBCTActive =
    flags.radiologicalAnalysis.isActive ||
    flags.endodonticAnalysis.isActive ||
    flags.implantologyAnalysis.isActive ||
    flags.thirdMolarAnalysis.isActive ||
    flags.STL.isActive ||
    flags.orthodonticAnalysis.isActive;

  return { ...flags, CBCT: { isActive: isCBCTActive } };
};
