/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  Gender,
  PersonalData,
  Created,
  Revision,
  genderFromJSON,
  genderToJSON,
} from '../model/dto_base';
import { UserPermissions } from '../model/dto_user_permissions';

export const protobufPackage = 'com.diagnocat.model';

export interface User {
  ID: string;
  /** // PERM: UserPermissions.CanViewPersonalData == true */
  PersonalData: PersonalData | undefined;
  Age: number;
  Gender: Gender;
  AvatarAssetID: string;
  SignatureAssetID: string;
  YourPermissions: UserPermissions | undefined;
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
}

function createBaseUser(): User {
  return {
    ID: '',
    PersonalData: undefined,
    Age: 0,
    Gender: 0,
    AvatarAssetID: '',
    SignatureAssetID: '',
    YourPermissions: undefined,
    Created: undefined,
    Revision: undefined,
  };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.PersonalData !== undefined) {
      PersonalData.encode(
        message.PersonalData,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Age !== 0) {
      writer.uint32(24).int64(message.Age);
    }
    if (message.Gender !== 0) {
      writer.uint32(32).int32(message.Gender);
    }
    if (message.AvatarAssetID !== '') {
      writer.uint32(42).string(message.AvatarAssetID);
    }
    if (message.SignatureAssetID !== '') {
      writer.uint32(50).string(message.SignatureAssetID);
    }
    if (message.YourPermissions !== undefined) {
      UserPermissions.encode(
        message.YourPermissions,
        writer.uint32(80162).fork(),
      ).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.PersonalData = PersonalData.decode(reader, reader.uint32());
          break;
        case 3:
          message.Age = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.Gender = reader.int32() as any;
          break;
        case 5:
          message.AvatarAssetID = reader.string();
          break;
        case 6:
          message.SignatureAssetID = reader.string();
          break;
        case 10020:
          message.YourPermissions = UserPermissions.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      PersonalData: isSet(object.PersonalData)
        ? PersonalData.fromJSON(object.PersonalData)
        : undefined,
      Age: isSet(object.Age) ? Number(object.Age) : 0,
      Gender: isSet(object.Gender) ? genderFromJSON(object.Gender) : 0,
      AvatarAssetID: isSet(object.AvatarAssetID)
        ? String(object.AvatarAssetID)
        : '',
      SignatureAssetID: isSet(object.SignatureAssetID)
        ? String(object.SignatureAssetID)
        : '',
      YourPermissions: isSet(object.YourPermissions)
        ? UserPermissions.fromJSON(object.YourPermissions)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.PersonalData !== undefined &&
      (obj.PersonalData = message.PersonalData
        ? PersonalData.toJSON(message.PersonalData)
        : undefined);
    message.Age !== undefined && (obj.Age = Math.round(message.Age));
    message.Gender !== undefined && (obj.Gender = genderToJSON(message.Gender));
    message.AvatarAssetID !== undefined &&
      (obj.AvatarAssetID = message.AvatarAssetID);
    message.SignatureAssetID !== undefined &&
      (obj.SignatureAssetID = message.SignatureAssetID);
    message.YourPermissions !== undefined &&
      (obj.YourPermissions = message.YourPermissions
        ? UserPermissions.toJSON(message.YourPermissions)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.ID = object.ID ?? '';
    message.PersonalData =
      object.PersonalData !== undefined && object.PersonalData !== null
        ? PersonalData.fromPartial(object.PersonalData)
        : undefined;
    message.Age = object.Age ?? 0;
    message.Gender = object.Gender ?? 0;
    message.AvatarAssetID = object.AvatarAssetID ?? '';
    message.SignatureAssetID = object.SignatureAssetID ?? '';
    message.YourPermissions =
      object.YourPermissions !== undefined && object.YourPermissions !== null
        ? UserPermissions.fromPartial(object.YourPermissions)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
