import React, { FC } from 'react';
import cn from 'classnames';

import { BasicSkeleton } from '../BasicSkeleton/BasicSkeleton';

import styles from './SkeletonFilter.module.scss';

type SkeletonFilterProps = {
  className?: string;
  testID?: string;
  filtersQuantity?: number;
};

export const SkeletonFilter: FC<SkeletonFilterProps> = (props) => {
  const { className, testID, filtersQuantity = 6 } = props;

  const filtersQuantityArray: number[] = Array(filtersQuantity).fill(0);

  return (
    <div className={cn(styles.container, className)} data-testid={testID}>
      {filtersQuantityArray.map((element, index) => (
        <BasicSkeleton
          width="100%"
          height="20px"
          borderRadius="28px"
          key={(element + index).toString()}
        />
      ))}
    </div>
  );
};
