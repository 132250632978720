import { FC, memo } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';

import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { Checkbox, Radio } from '@/shared/ui';

import {
  ConditionDecisionChange,
  ConditionColor,
  ConditionInterface,
  ChildConditionInterface,
} from '../../config/types';

import styles from './ConditionToggle.module.scss';

const colorStyle: Record<ConditionColor, string> = {
  yellow: styles.colorYellow,
  red: styles.colorRed,
  purple: styles.colorPurple,
  white: styles.colorWhite,
};

type ConditionToggleProps = {
  className?: string;
  data: ConditionInterface | ChildConditionInterface;
  disabled?: boolean;
  onChange: ConditionDecisionChange;
};

const InnerConditionToggle: FC<ConditionToggleProps> = (props) => {
  const { className, data, disabled, onChange } = props;

  if (isEmpty(data)) {
    return null;
  }

  const name = data.isOneOf ? data.group : data.code.toString();
  const Component = data.isOneOf ? Radio : Checkbox;

  const handleChange = () => {
    onChange({
      conditionID: data.id,
      parentConditionId: (data as ChildConditionInterface)?.parentConditionId,
      conditionCode: data.code,
      userDecision: data.isChecked
        ? Decision.NegativeDecision
        : Decision.PositiveDecision,
    });
  };

  return (
    <Component
      className={cn(styles.container, className)}
      checked={data.isChecked}
      disabled={disabled}
      onChange={handleChange}
      name={name}
    >
      <div className={styles.label}>
        <span className={cn(styles.text, colorStyle[data.color])}>
          {data.text}
        </span>
        {data.probability !== null ? <span>{data.probabilityText}</span> : null}
      </div>
    </Component>
  );
};

export const ConditionToggle = memo(InnerConditionToggle);
