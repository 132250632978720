// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReportSign-module__container--akvQl1tz{display:flex;justify-content:space-between;align-items:center}.ReportSign-module__signedUser--lhPc3FLR{display:flex;flex-direction:column}.ReportSign-module__signedUserName--Du0pYh17{color:var(--purpletext1)}.ReportSign-module__signedUserSpeciality--jSDgKAUP{color:var(--black)}.ReportSign-module__signDate--LcVQFj2I{color:var(--grey9)}", "",{"version":3,"sources":["webpack://./src/pages/Report/ui/ReportSign/ReportSign.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,yCACE,YAAA,CACA,qBAAA,CAGF,6CACE,wBAAA,CAGF,mDACE,kBAAA,CAGF,uCACE,kBAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.signedUser {\n  display: flex;\n  flex-direction: column;\n}\n\n.signedUserName {\n  color: var(--purpletext1);\n}\n\n.signedUserSpeciality {\n  color: var(--black);\n}\n\n.signDate {\n  color: var(--grey9);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReportSign-module__container--akvQl1tz",
	"signedUser": "ReportSign-module__signedUser--lhPc3FLR",
	"signedUserName": "ReportSign-module__signedUserName--Du0pYh17",
	"signedUserSpeciality": "ReportSign-module__signedUserSpeciality--jSDgKAUP",
	"signDate": "ReportSign-module__signDate--LcVQFj2I"
};
export default ___CSS_LOADER_EXPORT___;
