/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { OrganizationPermissions } from '../model/dto_organization_permissions';
import { Created, Revision, Deleted } from '../model/dto_base';

export const protobufPackage = 'com.diagnocat.model';

export enum OrganizationType {
  OrganizationTypeClinic = 0,
  OrganizationTypeDiagnosticCenter = 1,
  OrganizationTypeSoloPractitioner = 2,
  UNRECOGNIZED = -1,
}

export function organizationTypeFromJSON(object: any): OrganizationType {
  switch (object) {
    case 0:
    case 'OrganizationTypeClinic':
      return OrganizationType.OrganizationTypeClinic;
    case 1:
    case 'OrganizationTypeDiagnosticCenter':
      return OrganizationType.OrganizationTypeDiagnosticCenter;
    case 2:
    case 'OrganizationTypeSoloPractitioner':
      return OrganizationType.OrganizationTypeSoloPractitioner;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OrganizationType.UNRECOGNIZED;
  }
}

export function organizationTypeToJSON(object: OrganizationType): string {
  switch (object) {
    case OrganizationType.OrganizationTypeClinic:
      return 'OrganizationTypeClinic';
    case OrganizationType.OrganizationTypeDiagnosticCenter:
      return 'OrganizationTypeDiagnosticCenter';
    case OrganizationType.OrganizationTypeSoloPractitioner:
      return 'OrganizationTypeSoloPractitioner';
    case OrganizationType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum OrganizationSpecialization {
  OrganizationSpecializationGeneral = 0,
  OrganizationSpecializationEndodontist = 1,
  OrganizationSpecializationPathologist = 2,
  OrganizationSpecializationRadiologist = 3,
  OrganizationSpecializationSurgeon = 4,
  OrganizationSpecializationOrthodontist = 5,
  OrganizationSpecializationPediatric = 6,
  OrganizationSpecializationPeriodontologist = 7,
  OrganizationSpecializationProsthodontist = 8,
  OrganizationSpecializationChief = 9,
  UNRECOGNIZED = -1,
}

export function organizationSpecializationFromJSON(
  object: any,
): OrganizationSpecialization {
  switch (object) {
    case 0:
    case 'OrganizationSpecializationGeneral':
      return OrganizationSpecialization.OrganizationSpecializationGeneral;
    case 1:
    case 'OrganizationSpecializationEndodontist':
      return OrganizationSpecialization.OrganizationSpecializationEndodontist;
    case 2:
    case 'OrganizationSpecializationPathologist':
      return OrganizationSpecialization.OrganizationSpecializationPathologist;
    case 3:
    case 'OrganizationSpecializationRadiologist':
      return OrganizationSpecialization.OrganizationSpecializationRadiologist;
    case 4:
    case 'OrganizationSpecializationSurgeon':
      return OrganizationSpecialization.OrganizationSpecializationSurgeon;
    case 5:
    case 'OrganizationSpecializationOrthodontist':
      return OrganizationSpecialization.OrganizationSpecializationOrthodontist;
    case 6:
    case 'OrganizationSpecializationPediatric':
      return OrganizationSpecialization.OrganizationSpecializationPediatric;
    case 7:
    case 'OrganizationSpecializationPeriodontologist':
      return OrganizationSpecialization.OrganizationSpecializationPeriodontologist;
    case 8:
    case 'OrganizationSpecializationProsthodontist':
      return OrganizationSpecialization.OrganizationSpecializationProsthodontist;
    case 9:
    case 'OrganizationSpecializationChief':
      return OrganizationSpecialization.OrganizationSpecializationChief;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OrganizationSpecialization.UNRECOGNIZED;
  }
}

export function organizationSpecializationToJSON(
  object: OrganizationSpecialization,
): string {
  switch (object) {
    case OrganizationSpecialization.OrganizationSpecializationGeneral:
      return 'OrganizationSpecializationGeneral';
    case OrganizationSpecialization.OrganizationSpecializationEndodontist:
      return 'OrganizationSpecializationEndodontist';
    case OrganizationSpecialization.OrganizationSpecializationPathologist:
      return 'OrganizationSpecializationPathologist';
    case OrganizationSpecialization.OrganizationSpecializationRadiologist:
      return 'OrganizationSpecializationRadiologist';
    case OrganizationSpecialization.OrganizationSpecializationSurgeon:
      return 'OrganizationSpecializationSurgeon';
    case OrganizationSpecialization.OrganizationSpecializationOrthodontist:
      return 'OrganizationSpecializationOrthodontist';
    case OrganizationSpecialization.OrganizationSpecializationPediatric:
      return 'OrganizationSpecializationPediatric';
    case OrganizationSpecialization.OrganizationSpecializationPeriodontologist:
      return 'OrganizationSpecializationPeriodontologist';
    case OrganizationSpecialization.OrganizationSpecializationProsthodontist:
      return 'OrganizationSpecializationProsthodontist';
    case OrganizationSpecialization.OrganizationSpecializationChief:
      return 'OrganizationSpecializationChief';
    case OrganizationSpecialization.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Organization {
  ID: string;
  Name: string;
  Type: OrganizationType;
  ParentID: string;
  LogoAssetID: string;
  StampAssetID: string;
  Contacts: OrganizationContacts | undefined;
  /** // PERM: OrganizationPermissions.CanViewSettings == true */
  Settings: OrganizationSettings | undefined;
  AvailableSpecializations: OrganizationSpecialization[];
  /** // PERM: com.diagnocat.model.OrganizationPermissions.CanViewStaff == true */
  UserRoles: OrganizationUserRole[];
  YourPermissions: OrganizationPermissions | undefined;
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
}

/** TODO - needs product investigation */
export interface OrganizationSettings {
  HackEditUsersContacts: boolean;
  OnlyTeamMembersCanViewPatient: boolean;
  OnlyTeamMembersCanViewPersonalData: boolean;
  OnlyTeamMembersCanRequestReport: boolean;
  OnlyTeamMembersCanEditPatient: boolean;
  OnlyTeamMembersCanEditReport: boolean;
}

export interface OrganizationContacts {
  City: string;
  Address: string;
  Phones: string[];
  Emails: string[];
  WebSite: string;
}

export interface OrganizationUserRole {
  UserID: string;
  Roles: OrganizationUserRole_Type[];
  /** user specializations */
  Specializations: OrganizationSpecialization[];
  /** user job title */
  JobTitle: string;
  /** user stamp */
  StampAssetID: string;
  CreatedAt: number;
}

export enum OrganizationUserRole_Type {
  /** TypeInvalidValue - default value */
  TypeInvalidValue = 0,
  /** TypeOwner - organization owner */
  TypeOwner = 1,
  /** TypeDoctor - doctor */
  TypeDoctor = 2,
  /** TypeNetworkDoctor - External doctor participating in clinic */
  TypeNetworkDoctor = 3,
  /** TypeAdministrative - organization administrator */
  TypeAdministrative = 4,
  UNRECOGNIZED = -1,
}

export function organizationUserRole_TypeFromJSON(
  object: any,
): OrganizationUserRole_Type {
  switch (object) {
    case 0:
    case 'TypeInvalidValue':
      return OrganizationUserRole_Type.TypeInvalidValue;
    case 1:
    case 'TypeOwner':
      return OrganizationUserRole_Type.TypeOwner;
    case 2:
    case 'TypeDoctor':
      return OrganizationUserRole_Type.TypeDoctor;
    case 3:
    case 'TypeNetworkDoctor':
      return OrganizationUserRole_Type.TypeNetworkDoctor;
    case 4:
    case 'TypeAdministrative':
      return OrganizationUserRole_Type.TypeAdministrative;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OrganizationUserRole_Type.UNRECOGNIZED;
  }
}

export function organizationUserRole_TypeToJSON(
  object: OrganizationUserRole_Type,
): string {
  switch (object) {
    case OrganizationUserRole_Type.TypeInvalidValue:
      return 'TypeInvalidValue';
    case OrganizationUserRole_Type.TypeOwner:
      return 'TypeOwner';
    case OrganizationUserRole_Type.TypeDoctor:
      return 'TypeDoctor';
    case OrganizationUserRole_Type.TypeNetworkDoctor:
      return 'TypeNetworkDoctor';
    case OrganizationUserRole_Type.TypeAdministrative:
      return 'TypeAdministrative';
    case OrganizationUserRole_Type.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

function createBaseOrganization(): Organization {
  return {
    ID: '',
    Name: '',
    Type: 0,
    ParentID: '',
    LogoAssetID: '',
    StampAssetID: '',
    Contacts: undefined,
    Settings: undefined,
    AvailableSpecializations: [],
    UserRoles: [],
    YourPermissions: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const Organization = {
  encode(
    message: Organization,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== '') {
      writer.uint32(18).string(message.Name);
    }
    if (message.Type !== 0) {
      writer.uint32(24).int32(message.Type);
    }
    if (message.ParentID !== '') {
      writer.uint32(34).string(message.ParentID);
    }
    if (message.LogoAssetID !== '') {
      writer.uint32(42).string(message.LogoAssetID);
    }
    if (message.StampAssetID !== '') {
      writer.uint32(50).string(message.StampAssetID);
    }
    if (message.Contacts !== undefined) {
      OrganizationContacts.encode(
        message.Contacts,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.Settings !== undefined) {
      OrganizationSettings.encode(
        message.Settings,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    writer.uint32(74).fork();
    for (const v of message.AvailableSpecializations) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.UserRoles) {
      OrganizationUserRole.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    if (message.YourPermissions !== undefined) {
      OrganizationPermissions.encode(
        message.YourPermissions,
        writer.uint32(80162).fork(),
      ).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Organization {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Type = reader.int32() as any;
          break;
        case 4:
          message.ParentID = reader.string();
          break;
        case 5:
          message.LogoAssetID = reader.string();
          break;
        case 6:
          message.StampAssetID = reader.string();
          break;
        case 7:
          message.Contacts = OrganizationContacts.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 8:
          message.Settings = OrganizationSettings.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 9:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.AvailableSpecializations.push(reader.int32() as any);
            }
          } else {
            message.AvailableSpecializations.push(reader.int32() as any);
          }
          break;
        case 10:
          message.UserRoles.push(
            OrganizationUserRole.decode(reader, reader.uint32()),
          );
          break;
        case 10020:
          message.YourPermissions = OrganizationPermissions.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Organization {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Name: isSet(object.Name) ? String(object.Name) : '',
      Type: isSet(object.Type) ? organizationTypeFromJSON(object.Type) : 0,
      ParentID: isSet(object.ParentID) ? String(object.ParentID) : '',
      LogoAssetID: isSet(object.LogoAssetID) ? String(object.LogoAssetID) : '',
      StampAssetID: isSet(object.StampAssetID)
        ? String(object.StampAssetID)
        : '',
      Contacts: isSet(object.Contacts)
        ? OrganizationContacts.fromJSON(object.Contacts)
        : undefined,
      Settings: isSet(object.Settings)
        ? OrganizationSettings.fromJSON(object.Settings)
        : undefined,
      AvailableSpecializations: Array.isArray(object?.AvailableSpecializations)
        ? object.AvailableSpecializations.map((e: any) =>
            organizationSpecializationFromJSON(e),
          )
        : [],
      UserRoles: Array.isArray(object?.UserRoles)
        ? object.UserRoles.map((e: any) => OrganizationUserRole.fromJSON(e))
        : [],
      YourPermissions: isSet(object.YourPermissions)
        ? OrganizationPermissions.fromJSON(object.YourPermissions)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: Organization): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Type !== undefined &&
      (obj.Type = organizationTypeToJSON(message.Type));
    message.ParentID !== undefined && (obj.ParentID = message.ParentID);
    message.LogoAssetID !== undefined &&
      (obj.LogoAssetID = message.LogoAssetID);
    message.StampAssetID !== undefined &&
      (obj.StampAssetID = message.StampAssetID);
    message.Contacts !== undefined &&
      (obj.Contacts = message.Contacts
        ? OrganizationContacts.toJSON(message.Contacts)
        : undefined);
    message.Settings !== undefined &&
      (obj.Settings = message.Settings
        ? OrganizationSettings.toJSON(message.Settings)
        : undefined);
    if (message.AvailableSpecializations) {
      obj.AvailableSpecializations = message.AvailableSpecializations.map((e) =>
        organizationSpecializationToJSON(e),
      );
    } else {
      obj.AvailableSpecializations = [];
    }
    if (message.UserRoles) {
      obj.UserRoles = message.UserRoles.map((e) =>
        e ? OrganizationUserRole.toJSON(e) : undefined,
      );
    } else {
      obj.UserRoles = [];
    }
    message.YourPermissions !== undefined &&
      (obj.YourPermissions = message.YourPermissions
        ? OrganizationPermissions.toJSON(message.YourPermissions)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Organization>, I>>(
    object: I,
  ): Organization {
    const message = createBaseOrganization();
    message.ID = object.ID ?? '';
    message.Name = object.Name ?? '';
    message.Type = object.Type ?? 0;
    message.ParentID = object.ParentID ?? '';
    message.LogoAssetID = object.LogoAssetID ?? '';
    message.StampAssetID = object.StampAssetID ?? '';
    message.Contacts =
      object.Contacts !== undefined && object.Contacts !== null
        ? OrganizationContacts.fromPartial(object.Contacts)
        : undefined;
    message.Settings =
      object.Settings !== undefined && object.Settings !== null
        ? OrganizationSettings.fromPartial(object.Settings)
        : undefined;
    message.AvailableSpecializations =
      object.AvailableSpecializations?.map((e) => e) || [];
    message.UserRoles =
      object.UserRoles?.map((e) => OrganizationUserRole.fromPartial(e)) || [];
    message.YourPermissions =
      object.YourPermissions !== undefined && object.YourPermissions !== null
        ? OrganizationPermissions.fromPartial(object.YourPermissions)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

function createBaseOrganizationSettings(): OrganizationSettings {
  return {
    HackEditUsersContacts: false,
    OnlyTeamMembersCanViewPatient: false,
    OnlyTeamMembersCanViewPersonalData: false,
    OnlyTeamMembersCanRequestReport: false,
    OnlyTeamMembersCanEditPatient: false,
    OnlyTeamMembersCanEditReport: false,
  };
}

export const OrganizationSettings = {
  encode(
    message: OrganizationSettings,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.HackEditUsersContacts === true) {
      writer.uint32(8).bool(message.HackEditUsersContacts);
    }
    if (message.OnlyTeamMembersCanViewPatient === true) {
      writer.uint32(16).bool(message.OnlyTeamMembersCanViewPatient);
    }
    if (message.OnlyTeamMembersCanViewPersonalData === true) {
      writer.uint32(24).bool(message.OnlyTeamMembersCanViewPersonalData);
    }
    if (message.OnlyTeamMembersCanRequestReport === true) {
      writer.uint32(32).bool(message.OnlyTeamMembersCanRequestReport);
    }
    if (message.OnlyTeamMembersCanEditPatient === true) {
      writer.uint32(40).bool(message.OnlyTeamMembersCanEditPatient);
    }
    if (message.OnlyTeamMembersCanEditReport === true) {
      writer.uint32(48).bool(message.OnlyTeamMembersCanEditReport);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationSettings {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.HackEditUsersContacts = reader.bool();
          break;
        case 2:
          message.OnlyTeamMembersCanViewPatient = reader.bool();
          break;
        case 3:
          message.OnlyTeamMembersCanViewPersonalData = reader.bool();
          break;
        case 4:
          message.OnlyTeamMembersCanRequestReport = reader.bool();
          break;
        case 5:
          message.OnlyTeamMembersCanEditPatient = reader.bool();
          break;
        case 6:
          message.OnlyTeamMembersCanEditReport = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationSettings {
    return {
      HackEditUsersContacts: isSet(object.HackEditUsersContacts)
        ? Boolean(object.HackEditUsersContacts)
        : false,
      OnlyTeamMembersCanViewPatient: isSet(object.OnlyTeamMembersCanViewPatient)
        ? Boolean(object.OnlyTeamMembersCanViewPatient)
        : false,
      OnlyTeamMembersCanViewPersonalData: isSet(
        object.OnlyTeamMembersCanViewPersonalData,
      )
        ? Boolean(object.OnlyTeamMembersCanViewPersonalData)
        : false,
      OnlyTeamMembersCanRequestReport: isSet(
        object.OnlyTeamMembersCanRequestReport,
      )
        ? Boolean(object.OnlyTeamMembersCanRequestReport)
        : false,
      OnlyTeamMembersCanEditPatient: isSet(object.OnlyTeamMembersCanEditPatient)
        ? Boolean(object.OnlyTeamMembersCanEditPatient)
        : false,
      OnlyTeamMembersCanEditReport: isSet(object.OnlyTeamMembersCanEditReport)
        ? Boolean(object.OnlyTeamMembersCanEditReport)
        : false,
    };
  },

  toJSON(message: OrganizationSettings): unknown {
    const obj: any = {};
    message.HackEditUsersContacts !== undefined &&
      (obj.HackEditUsersContacts = message.HackEditUsersContacts);
    message.OnlyTeamMembersCanViewPatient !== undefined &&
      (obj.OnlyTeamMembersCanViewPatient =
        message.OnlyTeamMembersCanViewPatient);
    message.OnlyTeamMembersCanViewPersonalData !== undefined &&
      (obj.OnlyTeamMembersCanViewPersonalData =
        message.OnlyTeamMembersCanViewPersonalData);
    message.OnlyTeamMembersCanRequestReport !== undefined &&
      (obj.OnlyTeamMembersCanRequestReport =
        message.OnlyTeamMembersCanRequestReport);
    message.OnlyTeamMembersCanEditPatient !== undefined &&
      (obj.OnlyTeamMembersCanEditPatient =
        message.OnlyTeamMembersCanEditPatient);
    message.OnlyTeamMembersCanEditReport !== undefined &&
      (obj.OnlyTeamMembersCanEditReport = message.OnlyTeamMembersCanEditReport);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationSettings>, I>>(
    object: I,
  ): OrganizationSettings {
    const message = createBaseOrganizationSettings();
    message.HackEditUsersContacts = object.HackEditUsersContacts ?? false;
    message.OnlyTeamMembersCanViewPatient =
      object.OnlyTeamMembersCanViewPatient ?? false;
    message.OnlyTeamMembersCanViewPersonalData =
      object.OnlyTeamMembersCanViewPersonalData ?? false;
    message.OnlyTeamMembersCanRequestReport =
      object.OnlyTeamMembersCanRequestReport ?? false;
    message.OnlyTeamMembersCanEditPatient =
      object.OnlyTeamMembersCanEditPatient ?? false;
    message.OnlyTeamMembersCanEditReport =
      object.OnlyTeamMembersCanEditReport ?? false;
    return message;
  },
};

function createBaseOrganizationContacts(): OrganizationContacts {
  return { City: '', Address: '', Phones: [], Emails: [], WebSite: '' };
}

export const OrganizationContacts = {
  encode(
    message: OrganizationContacts,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.City !== '') {
      writer.uint32(10).string(message.City);
    }
    if (message.Address !== '') {
      writer.uint32(18).string(message.Address);
    }
    for (const v of message.Phones) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.Emails) {
      writer.uint32(34).string(v!);
    }
    if (message.WebSite !== '') {
      writer.uint32(42).string(message.WebSite);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationContacts {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationContacts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.City = reader.string();
          break;
        case 2:
          message.Address = reader.string();
          break;
        case 3:
          message.Phones.push(reader.string());
          break;
        case 4:
          message.Emails.push(reader.string());
          break;
        case 5:
          message.WebSite = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationContacts {
    return {
      City: isSet(object.City) ? String(object.City) : '',
      Address: isSet(object.Address) ? String(object.Address) : '',
      Phones: Array.isArray(object?.Phones)
        ? object.Phones.map((e: any) => String(e))
        : [],
      Emails: Array.isArray(object?.Emails)
        ? object.Emails.map((e: any) => String(e))
        : [],
      WebSite: isSet(object.WebSite) ? String(object.WebSite) : '',
    };
  },

  toJSON(message: OrganizationContacts): unknown {
    const obj: any = {};
    message.City !== undefined && (obj.City = message.City);
    message.Address !== undefined && (obj.Address = message.Address);
    if (message.Phones) {
      obj.Phones = message.Phones.map((e) => e);
    } else {
      obj.Phones = [];
    }
    if (message.Emails) {
      obj.Emails = message.Emails.map((e) => e);
    } else {
      obj.Emails = [];
    }
    message.WebSite !== undefined && (obj.WebSite = message.WebSite);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationContacts>, I>>(
    object: I,
  ): OrganizationContacts {
    const message = createBaseOrganizationContacts();
    message.City = object.City ?? '';
    message.Address = object.Address ?? '';
    message.Phones = object.Phones?.map((e) => e) || [];
    message.Emails = object.Emails?.map((e) => e) || [];
    message.WebSite = object.WebSite ?? '';
    return message;
  },
};

function createBaseOrganizationUserRole(): OrganizationUserRole {
  return {
    UserID: '',
    Roles: [],
    Specializations: [],
    JobTitle: '',
    StampAssetID: '',
    CreatedAt: 0,
  };
}

export const OrganizationUserRole = {
  encode(
    message: OrganizationUserRole,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    writer.uint32(18).fork();
    for (const v of message.Roles) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.Specializations) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.JobTitle !== '') {
      writer.uint32(34).string(message.JobTitle);
    }
    if (message.StampAssetID !== '') {
      writer.uint32(42).string(message.StampAssetID);
    }
    if (message.CreatedAt !== 0) {
      writer.uint32(48).int64(message.CreatedAt);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationUserRole {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationUserRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.Roles.push(reader.int32() as any);
            }
          } else {
            message.Roles.push(reader.int32() as any);
          }
          break;
        case 3:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.Specializations.push(reader.int32() as any);
            }
          } else {
            message.Specializations.push(reader.int32() as any);
          }
          break;
        case 4:
          message.JobTitle = reader.string();
          break;
        case 5:
          message.StampAssetID = reader.string();
          break;
        case 6:
          message.CreatedAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationUserRole {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      Roles: Array.isArray(object?.Roles)
        ? object.Roles.map((e: any) => organizationUserRole_TypeFromJSON(e))
        : [],
      Specializations: Array.isArray(object?.Specializations)
        ? object.Specializations.map((e: any) =>
            organizationSpecializationFromJSON(e),
          )
        : [],
      JobTitle: isSet(object.JobTitle) ? String(object.JobTitle) : '',
      StampAssetID: isSet(object.StampAssetID)
        ? String(object.StampAssetID)
        : '',
      CreatedAt: isSet(object.CreatedAt) ? Number(object.CreatedAt) : 0,
    };
  },

  toJSON(message: OrganizationUserRole): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    if (message.Roles) {
      obj.Roles = message.Roles.map((e) => organizationUserRole_TypeToJSON(e));
    } else {
      obj.Roles = [];
    }
    if (message.Specializations) {
      obj.Specializations = message.Specializations.map((e) =>
        organizationSpecializationToJSON(e),
      );
    } else {
      obj.Specializations = [];
    }
    message.JobTitle !== undefined && (obj.JobTitle = message.JobTitle);
    message.StampAssetID !== undefined &&
      (obj.StampAssetID = message.StampAssetID);
    message.CreatedAt !== undefined &&
      (obj.CreatedAt = Math.round(message.CreatedAt));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationUserRole>, I>>(
    object: I,
  ): OrganizationUserRole {
    const message = createBaseOrganizationUserRole();
    message.UserID = object.UserID ?? '';
    message.Roles = object.Roles?.map((e) => e) || [];
    message.Specializations = object.Specializations?.map((e) => e) || [];
    message.JobTitle = object.JobTitle ?? '';
    message.StampAssetID = object.StampAssetID ?? '';
    message.CreatedAt = object.CreatedAt ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
