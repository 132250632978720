import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { ToastContainer } from 'react-toastify';

import { defaultValue, ErrorContext } from '@/shared/error/ErrorContext';
import ErrorBoundary from '@/shared/error/ErrorBoundary';
import { RefreshPage } from '@/widgets/RefreshPage';

import { ClientCoreRoutes } from './model/routes';
import { GlobalStream } from './providers/GlobalStream';
import { IntlProvider } from './providers/IntlProvider';
import { store } from './model/store';

export const App = () => (
  <ErrorContext.Provider value={defaultValue}>
    <ErrorBoundary>
      <Provider store={store}>
        <IntlProvider>
          <GlobalStream>
            <RadixTooltip.Provider>
              <Router>
                <ClientCoreRoutes />
                <RefreshPage />
                <ToastContainer icon={false} hideProgressBar />
              </Router>
            </RadixTooltip.Provider>
          </GlobalStream>
        </IntlProvider>
      </Provider>
    </ErrorBoundary>
  </ErrorContext.Provider>
);
