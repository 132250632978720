import React, { FC, ReactNode } from 'react';

import { useUserDataStream } from '../hooks/useUserDataStream';

type GlobalStreamProps = { children: ReactNode };

export const GlobalStream: FC<GlobalStreamProps> = ({ children }) => {
  useUserDataStream();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
