export type ToothChartLegendsTypes =
  | 'unhealthy'
  | 'lowProbability'
  | 'treated'
  | 'healthy'
  | 'missing'
  | 'all';

export enum DentalNotations {
  ISO = 'ISO',
  UNIVERSAL = 'UNIVERSAL',
}

export type DentalNotation = keyof typeof DentalNotations;
