import { SlicesGroups } from '../config/slices.types';

import { updateOneSliceGroupByMasks } from './updateOneSliceGroupByMasks';

export const updateSlicesGroupsByMasks = (smartSlicesGroups: SlicesGroups) => {
  const updatedGroups = Object.keys(smartSlicesGroups).reduce((acc, key) => {
    const groupImages = smartSlicesGroups[key];

    return {
      ...acc,
      [key]: updateOneSliceGroupByMasks(groupImages),
    };
  }, {} as SlicesGroups);

  return updatedGroups;
};
