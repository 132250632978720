// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZoomedSliceModal-module__container--qmXkR6Uv{position:relative}.ZoomedSliceModal-module__popup--34woy0Ri{padding:0;overflow:hidden;display:flex;align-items:center;flex-direction:column}\n\n.ZoomedSliceModal-module__controls--71wnbRYf{display:flex;align-items:flex-start;gap:8px;padding:10px 24px 10px 48px;justify-content:center}\n\n[dir=\"rtl\"] .ZoomedSliceModal-module__controls--71wnbRYf{padding:10px 48px 10px 24px}.ZoomedSliceModal-module__description--kEUdMo7Z{margin:8px 0 48px}.ZoomedSliceModal-module__toolbar--VLA9dt8K{border:1px solid rgba(var(--bg3-rgb), 0.2)}", "",{"version":3,"sources":["webpack://./src/widgets/ZoomedSliceModal/ui/ZoomedSliceModal.module.scss"],"names":[],"mappings":"AAAA,8CACE,iBAAA,CAGF,0CACE,SAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,qBAAA;;AAGF,6CACE,YAAA,CACA,sBAAA,CACA,OAAA,CACA,2BAAA,CACA,sBAAA;;AALF,yDAIE,2BACA,CAGF,gDACE,iBAAA,CAGF,4CACE,0CAAA","sourcesContent":[".container {\n  position: relative;\n}\n\n.popup {\n  padding: 0;\n  overflow: hidden;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.controls {\n  display: flex;\n  align-items: flex-start;\n  gap: 8px;\n  padding: 10px 24px 10px 48px;\n  justify-content: center;\n}\n\n.description {\n  margin: 8px 0 48px;\n}\n\n.toolbar {\n  border: 1px solid rgba(var(--bg3-rgb), 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ZoomedSliceModal-module__container--qmXkR6Uv",
	"popup": "ZoomedSliceModal-module__popup--34woy0Ri",
	"controls": "ZoomedSliceModal-module__controls--71wnbRYf",
	"description": "ZoomedSliceModal-module__description--kEUdMo7Z",
	"toolbar": "ZoomedSliceModal-module__toolbar--VLA9dt8K"
};
export default ___CSS_LOADER_EXPORT___;
