import { Dispatch, FC, SetStateAction } from 'react';
import cn from 'classnames';

import {
  JawQuartile,
  Quartiles,
  makeJawObject,
  LOWER_JAW_QUARTILE,
} from '@/entities/tooth';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import styles from './TeethChart.module.scss';

type ToothChartProps = {
  teeth: Partial<Tooth>[];
  ROIList?: number[];
  isROIOpen?: boolean;
  setROIList?: Dispatch<SetStateAction<number[]>>;
  onToothClick?: (id: string) => void;
  className?: string;
  showUpperJaw?: boolean;
  showLowerJaw?: boolean;
};

export const TeethChart: FC<ToothChartProps> = (props) => {
  const {
    teeth,
    ROIList,
    isROIOpen,
    setROIList,
    onToothClick,
    showUpperJaw = true,
    showLowerJaw = true,
    className,
  } = props;

  if (!showUpperJaw && !showLowerJaw) {
    return null;
  }

  const jawObject = makeJawObject(teeth);
  const filteredJawEntries = Object.entries(jawObject).filter(([key]) => {
    if (!showUpperJaw) {
      return LOWER_JAW_QUARTILE.includes(key);
    }

    if (!showLowerJaw) {
      return !LOWER_JAW_QUARTILE.includes(key);
    }

    return true;
  });

  return (
    <div
      className={cn(
        styles.container,
        !showUpperJaw && styles.hideUpperJaw,
        !showLowerJaw && styles.hideLowerJaw,
        className,
      )}
    >
      {filteredJawEntries.map(([quartile, quartileTeeth]) => (
        <JawQuartile
          key={quartile}
          className={cn(styles[quartile])}
          quartile={quartile as Quartiles}
          teeth={quartileTeeth}
          ROIList={ROIList}
          setROIList={setROIList}
          isROIOpen={isROIOpen}
          onToothClick={onToothClick}
        />
      ))}
    </div>
  );
};
