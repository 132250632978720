import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@/shared/ui';

type ChooseToothRegionProps = {
  className?: string;
  onClick?: () => void;
};

export const ChooseToothRegion: FC<ChooseToothRegionProps> = (props) => {
  const { className, onClick } = props;

  return (
    <Button
      onClick={onClick}
      variant="secondary"
      size="small"
      className={className}
    >
      <FormattedMessage
        id="report.chooseToothRegion"
        defaultMessage="Choose region of interest"
      />
    </Button>
  );
};
