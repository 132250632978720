// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StudyCounter-module__icon--jIkj9Fd7{color:var(--grey9);cursor:pointer;padding:3px;box-sizing:content-box}.StudyCounter-module__icon--jIkj9Fd7.StudyCounter-module__cardIcon--vxieMlOb{padding:4px}.StudyCounter-module__icon--jIkj9Fd7:hover{background:rgba(117,100,237,.1);border-radius:12px;transition:background .5s ease,border-radius .5s ease}.StudyCounter-module__d3icon--xQ0WEYoG{padding:1px 3px 5px}.StudyCounter-module__d3icon--xQ0WEYoG.StudyCounter-module__cardIcon--vxieMlOb{padding:2px 4px 6px}\n\n.StudyCounter-module__smallIcon--oz7rfvsN{padding:7px 6px 7px 8px}\n\n[dir=\"rtl\"] .StudyCounter-module__smallIcon--oz7rfvsN{padding:7px 8px 7px 6px}.StudyCounter-module__smallIcon--oz7rfvsN.StudyCounter-module__cardIcon--vxieMlOb{padding:8px}.StudyCounter-module__active--RqyPG4nw{color:var(--purpledec)}", "",{"version":3,"sources":["webpack://./src/entities/patient/ui/StudyCounter/StudyCounter.module.scss"],"names":[],"mappings":"AAAA,qCACE,kBAAA,CACA,cAAA,CACA,WAAA,CACA,sBAAA,CAEA,6EACE,WAAA,CAGF,2CACE,+BAAA,CACA,kBAAA,CACA,qDAAA,CAIJ,uCACE,mBAAA,CAEA,+EACE,mBAAA;;AAIJ,0CACE,uBAAA;;AADF,sDACE,uBAAA,CAEA,kFACE,WAAA,CAIJ,uCACE,sBAAA","sourcesContent":[".icon {\n  color: var(--grey9);\n  cursor: pointer;\n  padding: 3px;\n  box-sizing: content-box;\n\n  &.cardIcon {\n    padding: 4px;\n  }\n\n  &:hover {\n    background: rgba(117, 100, 237, 0.1);\n    border-radius: 12px;\n    transition: background 0.5s ease, border-radius 0.5s ease;\n  }\n}\n\n.d3icon {\n  padding: 1px 3px 5px;\n\n  &.cardIcon {\n    padding: 2px 4px 6px;\n  }\n}\n\n.smallIcon {\n  padding: 7px 6px 7px 8px;\n\n  &.cardIcon {\n    padding: 8px;\n  }\n}\n\n.active {\n  color: var(--purpledec);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "StudyCounter-module__icon--jIkj9Fd7",
	"cardIcon": "StudyCounter-module__cardIcon--vxieMlOb",
	"d3icon": "StudyCounter-module__d3icon--xQ0WEYoG",
	"smallIcon": "StudyCounter-module__smallIcon--oz7rfvsN",
	"active": "StudyCounter-module__active--RqyPG4nw"
};
export default ___CSS_LOADER_EXPORT___;
