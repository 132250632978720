import { FC, useState } from 'react';
import { useIntl } from 'react-intl';

import { ErrorText, Modal, ModalProps } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { DoctorLabel, getUserFullName, usersModel } from '@/entities/users';

import { useRemoveDoctor } from '../../hooks/useRemoveDoctor';

import styles from './RemoveDoctorModal.module.scss';

type RemoveDoctorModalProps = {
  patientID?: string;
  doctorID?: string;
} & Pick<ModalProps, 'isOpen' | 'onClose'>;

export const RemoveDoctorModal: FC<RemoveDoctorModalProps> = (props) => {
  const { isOpen, onClose, patientID, doctorID } = props;

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { formatMessage } = useIntl();
  const removeDoctor = useRemoveDoctor();

  const doctor = useAppSelector(usersModel.selectors.selectById(doctorID));

  const doctorName = getUserFullName(doctor);

  const handleClose = () => {
    setErrorMessage(undefined);
    onClose();
  };

  const handleRemoveDoctor = async () => {
    setLoading(true);

    try {
      await removeDoctor({ patientID, doctorID });
    } catch (error) {
      setErrorMessage(error?.message);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      containerClassName={styles.container}
      bodyClassName={styles.modalBody}
      title={formatMessage({
        id: 'removeDoctorModal.title',
        defaultMessage: 'Remove treating doctor',
      })}
      isOpen={isOpen}
      onClose={handleClose}
      onApply={handleRemoveDoctor}
      applyButtonText={formatMessage({
        id: 'removeDoctorModal.applyButtonText',
        defaultMessage: 'Remove doctor',
      })}
      applyButtonProps={{
        danger: true,
        loading: isLoading,
      }}
    >
      <DoctorLabel fullName={doctorName} avatarSrc={doctor?.AvatarAssetID} />
      <ErrorText className={styles.errorText} error={errorMessage} />
    </Modal>
  );
};
