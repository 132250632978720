/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import {
  AssetType,
  AssetContentStudyCBCT,
  AssetContentStudyIntraoralXRay,
  AssetContentStudyIOSSTL,
  AssetContentStudyDentalPhoto,
  AssetContentStudyPanoramicXRay,
  Asset,
  assetTypeFromJSON,
  assetTypeToJSON,
} from '../../model/dto_asset';
import { BrowserHeaders } from 'browser-headers';
import { UploadSession, UploadTarget } from '../../model/dto_upload_session';
import { User } from '../../model/dto_user';
import { Organization } from '../../model/dto_organization';
import { Study } from '../../model/dto_study';
import { Report } from '../../model/dto_report';

export const protobufPackage = 'com.diagnocat.core';

export interface StartUploadSessionReq {
  Type: AssetType;
  /** AssetType_User_Avatar || AssetType_User_Signature || AssetType_Organization_EmployeeStamp */
  UserID: string;
  /** AssetType_Organization_Logo || AssetType_Organization_Stamp || AssetType_Organization_EmployeeStamp */
  OrganizationID: string;
  /** patient or study assets */
  PatientID: string;
  FilePaths: StartUploadSessionReq_Paths | undefined;
  /** path */
  Archive: string | undefined;
  STLStudy: StartUploadSessionReq_STLStudyFiles | undefined;
}

export interface StartUploadSessionReq_Paths {
  FilePaths: string[];
}

export interface StartUploadSessionReq_STLStudyFiles {
  /** file path */
  UpperJawModel: string;
  /** file path */
  LowerJawModel: string;
}

export interface StartUploadSessionResp {
  UploadSession: UploadSession | undefined;
  UploadTargets: UploadTarget[];
}

/** only if UploadSession is not closed yet */
export interface AddFilesToUploadSessionReq {
  UploadSessionID: string;
  CBCT: AssetContentStudyCBCT | undefined;
  IntraoralXRay: AssetContentStudyIntraoralXRay | undefined;
  iosStl: AssetContentStudyIOSSTL | undefined;
  DentalPhoto: AssetContentStudyDentalPhoto | undefined;
  PanoramicXRay: AssetContentStudyPanoramicXRay | undefined;
}

export interface AddFilesToUploadSessionResp {
  UploadSession: UploadSession | undefined;
  UploadTargets: UploadTarget[];
}

export interface NotifyFileUploadStaredReq {
  UploadSessionID: string;
  FileID: string;
}

export interface NotifyFileUploadStaredResp {
  UploadSession: UploadSession | undefined;
}

export interface NotifyFileUploadProgressReq {
  UploadSessionID: string;
  FileID: string;
}

export interface NotifyFileUploadProgressResp {
  UploadSession: UploadSession | undefined;
}

export interface NotifyFileUploadFinishedReq {
  UploadSessionID: string;
  FileID: string;
}

export interface NotifyFileUploadFinishedResp {
  UploadSession: UploadSession | undefined;
}

export interface CloseSessionReq {
  UploadSessionID: string;
}

export interface CloseSessionResp {
  Assets: Asset[];
  User: User | undefined;
  Organization: Organization | undefined;
  Study: Study | undefined;
  Report: Report | undefined;
}

export interface DeleteStudyReq {
  StudyID: string;
}

export interface DeleteStudyResp {
  DeletedStudyID: string;
  DeletedReportIDs: string[];
  DeletedToothIDs: string[];
  DeletedConditionIDs: string[];
  DeletedAssetIDs: string[];
  DeletedInvitationIDs: string[];
}

function createBaseStartUploadSessionReq(): StartUploadSessionReq {
  return {
    Type: 0,
    UserID: '',
    OrganizationID: '',
    PatientID: '',
    FilePaths: undefined,
    Archive: undefined,
    STLStudy: undefined,
  };
}

export const StartUploadSessionReq = {
  encode(
    message: StartUploadSessionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Type !== 0) {
      writer.uint32(8).int32(message.Type);
    }
    if (message.UserID !== '') {
      writer.uint32(90).string(message.UserID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(98).string(message.OrganizationID);
    }
    if (message.PatientID !== '') {
      writer.uint32(106).string(message.PatientID);
    }
    if (message.FilePaths !== undefined) {
      StartUploadSessionReq_Paths.encode(
        message.FilePaths,
        writer.uint32(170).fork(),
      ).ldelim();
    }
    if (message.Archive !== undefined) {
      writer.uint32(178).string(message.Archive);
    }
    if (message.STLStudy !== undefined) {
      StartUploadSessionReq_STLStudyFiles.encode(
        message.STLStudy,
        writer.uint32(186).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUploadSessionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Type = reader.int32() as any;
          break;
        case 11:
          message.UserID = reader.string();
          break;
        case 12:
          message.OrganizationID = reader.string();
          break;
        case 13:
          message.PatientID = reader.string();
          break;
        case 21:
          message.FilePaths = StartUploadSessionReq_Paths.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 22:
          message.Archive = reader.string();
          break;
        case 23:
          message.STLStudy = StartUploadSessionReq_STLStudyFiles.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq {
    return {
      Type: isSet(object.Type) ? assetTypeFromJSON(object.Type) : 0,
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      FilePaths: isSet(object.FilePaths)
        ? StartUploadSessionReq_Paths.fromJSON(object.FilePaths)
        : undefined,
      Archive: isSet(object.Archive) ? String(object.Archive) : undefined,
      STLStudy: isSet(object.STLStudy)
        ? StartUploadSessionReq_STLStudyFiles.fromJSON(object.STLStudy)
        : undefined,
    };
  },

  toJSON(message: StartUploadSessionReq): unknown {
    const obj: any = {};
    message.Type !== undefined && (obj.Type = assetTypeToJSON(message.Type));
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.FilePaths !== undefined &&
      (obj.FilePaths = message.FilePaths
        ? StartUploadSessionReq_Paths.toJSON(message.FilePaths)
        : undefined);
    message.Archive !== undefined && (obj.Archive = message.Archive);
    message.STLStudy !== undefined &&
      (obj.STLStudy = message.STLStudy
        ? StartUploadSessionReq_STLStudyFiles.toJSON(message.STLStudy)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StartUploadSessionReq>, I>>(
    object: I,
  ): StartUploadSessionReq {
    const message = createBaseStartUploadSessionReq();
    message.Type = object.Type ?? 0;
    message.UserID = object.UserID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.FilePaths =
      object.FilePaths !== undefined && object.FilePaths !== null
        ? StartUploadSessionReq_Paths.fromPartial(object.FilePaths)
        : undefined;
    message.Archive = object.Archive ?? undefined;
    message.STLStudy =
      object.STLStudy !== undefined && object.STLStudy !== null
        ? StartUploadSessionReq_STLStudyFiles.fromPartial(object.STLStudy)
        : undefined;
    return message;
  },
};

function createBaseStartUploadSessionReq_Paths(): StartUploadSessionReq_Paths {
  return { FilePaths: [] };
}

export const StartUploadSessionReq_Paths = {
  encode(
    message: StartUploadSessionReq_Paths,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.FilePaths) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_Paths {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUploadSessionReq_Paths();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FilePaths.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq_Paths {
    return {
      FilePaths: Array.isArray(object?.FilePaths)
        ? object.FilePaths.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: StartUploadSessionReq_Paths): unknown {
    const obj: any = {};
    if (message.FilePaths) {
      obj.FilePaths = message.FilePaths.map((e) => e);
    } else {
      obj.FilePaths = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StartUploadSessionReq_Paths>, I>>(
    object: I,
  ): StartUploadSessionReq_Paths {
    const message = createBaseStartUploadSessionReq_Paths();
    message.FilePaths = object.FilePaths?.map((e) => e) || [];
    return message;
  },
};

function createBaseStartUploadSessionReq_STLStudyFiles(): StartUploadSessionReq_STLStudyFiles {
  return { UpperJawModel: '', LowerJawModel: '' };
}

export const StartUploadSessionReq_STLStudyFiles = {
  encode(
    message: StartUploadSessionReq_STLStudyFiles,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UpperJawModel !== '') {
      writer.uint32(10).string(message.UpperJawModel);
    }
    if (message.LowerJawModel !== '') {
      writer.uint32(18).string(message.LowerJawModel);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionReq_STLStudyFiles {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUploadSessionReq_STLStudyFiles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UpperJawModel = reader.string();
          break;
        case 2:
          message.LowerJawModel = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionReq_STLStudyFiles {
    return {
      UpperJawModel: isSet(object.UpperJawModel)
        ? String(object.UpperJawModel)
        : '',
      LowerJawModel: isSet(object.LowerJawModel)
        ? String(object.LowerJawModel)
        : '',
    };
  },

  toJSON(message: StartUploadSessionReq_STLStudyFiles): unknown {
    const obj: any = {};
    message.UpperJawModel !== undefined &&
      (obj.UpperJawModel = message.UpperJawModel);
    message.LowerJawModel !== undefined &&
      (obj.LowerJawModel = message.LowerJawModel);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<StartUploadSessionReq_STLStudyFiles>, I>,
  >(object: I): StartUploadSessionReq_STLStudyFiles {
    const message = createBaseStartUploadSessionReq_STLStudyFiles();
    message.UpperJawModel = object.UpperJawModel ?? '';
    message.LowerJawModel = object.LowerJawModel ?? '';
    return message;
  },
};

function createBaseStartUploadSessionResp(): StartUploadSessionResp {
  return { UploadSession: undefined, UploadTargets: [] };
}

export const StartUploadSessionResp = {
  encode(
    message: StartUploadSessionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSession !== undefined) {
      UploadSession.encode(
        message.UploadSession,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    for (const v of message.UploadTargets) {
      UploadTarget.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): StartUploadSessionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStartUploadSessionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSession = UploadSession.decode(reader, reader.uint32());
          break;
        case 2:
          message.UploadTargets.push(
            UploadTarget.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StartUploadSessionResp {
    return {
      UploadSession: isSet(object.UploadSession)
        ? UploadSession.fromJSON(object.UploadSession)
        : undefined,
      UploadTargets: Array.isArray(object?.UploadTargets)
        ? object.UploadTargets.map((e: any) => UploadTarget.fromJSON(e))
        : [],
    };
  },

  toJSON(message: StartUploadSessionResp): unknown {
    const obj: any = {};
    message.UploadSession !== undefined &&
      (obj.UploadSession = message.UploadSession
        ? UploadSession.toJSON(message.UploadSession)
        : undefined);
    if (message.UploadTargets) {
      obj.UploadTargets = message.UploadTargets.map((e) =>
        e ? UploadTarget.toJSON(e) : undefined,
      );
    } else {
      obj.UploadTargets = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StartUploadSessionResp>, I>>(
    object: I,
  ): StartUploadSessionResp {
    const message = createBaseStartUploadSessionResp();
    message.UploadSession =
      object.UploadSession !== undefined && object.UploadSession !== null
        ? UploadSession.fromPartial(object.UploadSession)
        : undefined;
    message.UploadTargets =
      object.UploadTargets?.map((e) => UploadTarget.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddFilesToUploadSessionReq(): AddFilesToUploadSessionReq {
  return {
    UploadSessionID: '',
    CBCT: undefined,
    IntraoralXRay: undefined,
    iosStl: undefined,
    DentalPhoto: undefined,
    PanoramicXRay: undefined,
  };
}

export const AddFilesToUploadSessionReq = {
  encode(
    message: AddFilesToUploadSessionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSessionID !== '') {
      writer.uint32(10).string(message.UploadSessionID);
    }
    if (message.CBCT !== undefined) {
      AssetContentStudyCBCT.encode(
        message.CBCT,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.IntraoralXRay !== undefined) {
      AssetContentStudyIntraoralXRay.encode(
        message.IntraoralXRay,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.iosStl !== undefined) {
      AssetContentStudyIOSSTL.encode(
        message.iosStl,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    if (message.DentalPhoto !== undefined) {
      AssetContentStudyDentalPhoto.encode(
        message.DentalPhoto,
        writer.uint32(834).fork(),
      ).ldelim();
    }
    if (message.PanoramicXRay !== undefined) {
      AssetContentStudyPanoramicXRay.encode(
        message.PanoramicXRay,
        writer.uint32(842).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddFilesToUploadSessionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFilesToUploadSessionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSessionID = reader.string();
          break;
        case 101:
          message.CBCT = AssetContentStudyCBCT.decode(reader, reader.uint32());
          break;
        case 102:
          message.IntraoralXRay = AssetContentStudyIntraoralXRay.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 103:
          message.iosStl = AssetContentStudyIOSSTL.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 104:
          message.DentalPhoto = AssetContentStudyDentalPhoto.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 105:
          message.PanoramicXRay = AssetContentStudyPanoramicXRay.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddFilesToUploadSessionReq {
    return {
      UploadSessionID: isSet(object.UploadSessionID)
        ? String(object.UploadSessionID)
        : '',
      CBCT: isSet(object.CBCT)
        ? AssetContentStudyCBCT.fromJSON(object.CBCT)
        : undefined,
      IntraoralXRay: isSet(object.IntraoralXRay)
        ? AssetContentStudyIntraoralXRay.fromJSON(object.IntraoralXRay)
        : undefined,
      iosStl: isSet(object.IOSSTL)
        ? AssetContentStudyIOSSTL.fromJSON(object.IOSSTL)
        : undefined,
      DentalPhoto: isSet(object.DentalPhoto)
        ? AssetContentStudyDentalPhoto.fromJSON(object.DentalPhoto)
        : undefined,
      PanoramicXRay: isSet(object.PanoramicXRay)
        ? AssetContentStudyPanoramicXRay.fromJSON(object.PanoramicXRay)
        : undefined,
    };
  },

  toJSON(message: AddFilesToUploadSessionReq): unknown {
    const obj: any = {};
    message.UploadSessionID !== undefined &&
      (obj.UploadSessionID = message.UploadSessionID);
    message.CBCT !== undefined &&
      (obj.CBCT = message.CBCT
        ? AssetContentStudyCBCT.toJSON(message.CBCT)
        : undefined);
    message.IntraoralXRay !== undefined &&
      (obj.IntraoralXRay = message.IntraoralXRay
        ? AssetContentStudyIntraoralXRay.toJSON(message.IntraoralXRay)
        : undefined);
    message.iosStl !== undefined &&
      (obj.IOSSTL = message.iosStl
        ? AssetContentStudyIOSSTL.toJSON(message.iosStl)
        : undefined);
    message.DentalPhoto !== undefined &&
      (obj.DentalPhoto = message.DentalPhoto
        ? AssetContentStudyDentalPhoto.toJSON(message.DentalPhoto)
        : undefined);
    message.PanoramicXRay !== undefined &&
      (obj.PanoramicXRay = message.PanoramicXRay
        ? AssetContentStudyPanoramicXRay.toJSON(message.PanoramicXRay)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddFilesToUploadSessionReq>, I>>(
    object: I,
  ): AddFilesToUploadSessionReq {
    const message = createBaseAddFilesToUploadSessionReq();
    message.UploadSessionID = object.UploadSessionID ?? '';
    message.CBCT =
      object.CBCT !== undefined && object.CBCT !== null
        ? AssetContentStudyCBCT.fromPartial(object.CBCT)
        : undefined;
    message.IntraoralXRay =
      object.IntraoralXRay !== undefined && object.IntraoralXRay !== null
        ? AssetContentStudyIntraoralXRay.fromPartial(object.IntraoralXRay)
        : undefined;
    message.iosStl =
      object.iosStl !== undefined && object.iosStl !== null
        ? AssetContentStudyIOSSTL.fromPartial(object.iosStl)
        : undefined;
    message.DentalPhoto =
      object.DentalPhoto !== undefined && object.DentalPhoto !== null
        ? AssetContentStudyDentalPhoto.fromPartial(object.DentalPhoto)
        : undefined;
    message.PanoramicXRay =
      object.PanoramicXRay !== undefined && object.PanoramicXRay !== null
        ? AssetContentStudyPanoramicXRay.fromPartial(object.PanoramicXRay)
        : undefined;
    return message;
  },
};

function createBaseAddFilesToUploadSessionResp(): AddFilesToUploadSessionResp {
  return { UploadSession: undefined, UploadTargets: [] };
}

export const AddFilesToUploadSessionResp = {
  encode(
    message: AddFilesToUploadSessionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSession !== undefined) {
      UploadSession.encode(
        message.UploadSession,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    for (const v of message.UploadTargets) {
      UploadTarget.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddFilesToUploadSessionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFilesToUploadSessionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSession = UploadSession.decode(reader, reader.uint32());
          break;
        case 2:
          message.UploadTargets.push(
            UploadTarget.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddFilesToUploadSessionResp {
    return {
      UploadSession: isSet(object.UploadSession)
        ? UploadSession.fromJSON(object.UploadSession)
        : undefined,
      UploadTargets: Array.isArray(object?.UploadTargets)
        ? object.UploadTargets.map((e: any) => UploadTarget.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AddFilesToUploadSessionResp): unknown {
    const obj: any = {};
    message.UploadSession !== undefined &&
      (obj.UploadSession = message.UploadSession
        ? UploadSession.toJSON(message.UploadSession)
        : undefined);
    if (message.UploadTargets) {
      obj.UploadTargets = message.UploadTargets.map((e) =>
        e ? UploadTarget.toJSON(e) : undefined,
      );
    } else {
      obj.UploadTargets = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddFilesToUploadSessionResp>, I>>(
    object: I,
  ): AddFilesToUploadSessionResp {
    const message = createBaseAddFilesToUploadSessionResp();
    message.UploadSession =
      object.UploadSession !== undefined && object.UploadSession !== null
        ? UploadSession.fromPartial(object.UploadSession)
        : undefined;
    message.UploadTargets =
      object.UploadTargets?.map((e) => UploadTarget.fromPartial(e)) || [];
    return message;
  },
};

function createBaseNotifyFileUploadStaredReq(): NotifyFileUploadStaredReq {
  return { UploadSessionID: '', FileID: '' };
}

export const NotifyFileUploadStaredReq = {
  encode(
    message: NotifyFileUploadStaredReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSessionID !== '') {
      writer.uint32(10).string(message.UploadSessionID);
    }
    if (message.FileID !== '') {
      writer.uint32(18).string(message.FileID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadStaredReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadStaredReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSessionID = reader.string();
          break;
        case 2:
          message.FileID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadStaredReq {
    return {
      UploadSessionID: isSet(object.UploadSessionID)
        ? String(object.UploadSessionID)
        : '',
      FileID: isSet(object.FileID) ? String(object.FileID) : '',
    };
  },

  toJSON(message: NotifyFileUploadStaredReq): unknown {
    const obj: any = {};
    message.UploadSessionID !== undefined &&
      (obj.UploadSessionID = message.UploadSessionID);
    message.FileID !== undefined && (obj.FileID = message.FileID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadStaredReq>, I>>(
    object: I,
  ): NotifyFileUploadStaredReq {
    const message = createBaseNotifyFileUploadStaredReq();
    message.UploadSessionID = object.UploadSessionID ?? '';
    message.FileID = object.FileID ?? '';
    return message;
  },
};

function createBaseNotifyFileUploadStaredResp(): NotifyFileUploadStaredResp {
  return { UploadSession: undefined };
}

export const NotifyFileUploadStaredResp = {
  encode(
    message: NotifyFileUploadStaredResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSession !== undefined) {
      UploadSession.encode(
        message.UploadSession,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadStaredResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadStaredResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSession = UploadSession.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadStaredResp {
    return {
      UploadSession: isSet(object.UploadSession)
        ? UploadSession.fromJSON(object.UploadSession)
        : undefined,
    };
  },

  toJSON(message: NotifyFileUploadStaredResp): unknown {
    const obj: any = {};
    message.UploadSession !== undefined &&
      (obj.UploadSession = message.UploadSession
        ? UploadSession.toJSON(message.UploadSession)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadStaredResp>, I>>(
    object: I,
  ): NotifyFileUploadStaredResp {
    const message = createBaseNotifyFileUploadStaredResp();
    message.UploadSession =
      object.UploadSession !== undefined && object.UploadSession !== null
        ? UploadSession.fromPartial(object.UploadSession)
        : undefined;
    return message;
  },
};

function createBaseNotifyFileUploadProgressReq(): NotifyFileUploadProgressReq {
  return { UploadSessionID: '', FileID: '' };
}

export const NotifyFileUploadProgressReq = {
  encode(
    message: NotifyFileUploadProgressReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSessionID !== '') {
      writer.uint32(10).string(message.UploadSessionID);
    }
    if (message.FileID !== '') {
      writer.uint32(18).string(message.FileID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadProgressReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadProgressReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSessionID = reader.string();
          break;
        case 2:
          message.FileID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadProgressReq {
    return {
      UploadSessionID: isSet(object.UploadSessionID)
        ? String(object.UploadSessionID)
        : '',
      FileID: isSet(object.FileID) ? String(object.FileID) : '',
    };
  },

  toJSON(message: NotifyFileUploadProgressReq): unknown {
    const obj: any = {};
    message.UploadSessionID !== undefined &&
      (obj.UploadSessionID = message.UploadSessionID);
    message.FileID !== undefined && (obj.FileID = message.FileID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadProgressReq>, I>>(
    object: I,
  ): NotifyFileUploadProgressReq {
    const message = createBaseNotifyFileUploadProgressReq();
    message.UploadSessionID = object.UploadSessionID ?? '';
    message.FileID = object.FileID ?? '';
    return message;
  },
};

function createBaseNotifyFileUploadProgressResp(): NotifyFileUploadProgressResp {
  return { UploadSession: undefined };
}

export const NotifyFileUploadProgressResp = {
  encode(
    message: NotifyFileUploadProgressResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSession !== undefined) {
      UploadSession.encode(
        message.UploadSession,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadProgressResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadProgressResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSession = UploadSession.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadProgressResp {
    return {
      UploadSession: isSet(object.UploadSession)
        ? UploadSession.fromJSON(object.UploadSession)
        : undefined,
    };
  },

  toJSON(message: NotifyFileUploadProgressResp): unknown {
    const obj: any = {};
    message.UploadSession !== undefined &&
      (obj.UploadSession = message.UploadSession
        ? UploadSession.toJSON(message.UploadSession)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadProgressResp>, I>>(
    object: I,
  ): NotifyFileUploadProgressResp {
    const message = createBaseNotifyFileUploadProgressResp();
    message.UploadSession =
      object.UploadSession !== undefined && object.UploadSession !== null
        ? UploadSession.fromPartial(object.UploadSession)
        : undefined;
    return message;
  },
};

function createBaseNotifyFileUploadFinishedReq(): NotifyFileUploadFinishedReq {
  return { UploadSessionID: '', FileID: '' };
}

export const NotifyFileUploadFinishedReq = {
  encode(
    message: NotifyFileUploadFinishedReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSessionID !== '') {
      writer.uint32(10).string(message.UploadSessionID);
    }
    if (message.FileID !== '') {
      writer.uint32(18).string(message.FileID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadFinishedReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadFinishedReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSessionID = reader.string();
          break;
        case 2:
          message.FileID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadFinishedReq {
    return {
      UploadSessionID: isSet(object.UploadSessionID)
        ? String(object.UploadSessionID)
        : '',
      FileID: isSet(object.FileID) ? String(object.FileID) : '',
    };
  },

  toJSON(message: NotifyFileUploadFinishedReq): unknown {
    const obj: any = {};
    message.UploadSessionID !== undefined &&
      (obj.UploadSessionID = message.UploadSessionID);
    message.FileID !== undefined && (obj.FileID = message.FileID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadFinishedReq>, I>>(
    object: I,
  ): NotifyFileUploadFinishedReq {
    const message = createBaseNotifyFileUploadFinishedReq();
    message.UploadSessionID = object.UploadSessionID ?? '';
    message.FileID = object.FileID ?? '';
    return message;
  },
};

function createBaseNotifyFileUploadFinishedResp(): NotifyFileUploadFinishedResp {
  return { UploadSession: undefined };
}

export const NotifyFileUploadFinishedResp = {
  encode(
    message: NotifyFileUploadFinishedResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSession !== undefined) {
      UploadSession.encode(
        message.UploadSession,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): NotifyFileUploadFinishedResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotifyFileUploadFinishedResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSession = UploadSession.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): NotifyFileUploadFinishedResp {
    return {
      UploadSession: isSet(object.UploadSession)
        ? UploadSession.fromJSON(object.UploadSession)
        : undefined,
    };
  },

  toJSON(message: NotifyFileUploadFinishedResp): unknown {
    const obj: any = {};
    message.UploadSession !== undefined &&
      (obj.UploadSession = message.UploadSession
        ? UploadSession.toJSON(message.UploadSession)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<NotifyFileUploadFinishedResp>, I>>(
    object: I,
  ): NotifyFileUploadFinishedResp {
    const message = createBaseNotifyFileUploadFinishedResp();
    message.UploadSession =
      object.UploadSession !== undefined && object.UploadSession !== null
        ? UploadSession.fromPartial(object.UploadSession)
        : undefined;
    return message;
  },
};

function createBaseCloseSessionReq(): CloseSessionReq {
  return { UploadSessionID: '' };
}

export const CloseSessionReq = {
  encode(
    message: CloseSessionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UploadSessionID !== '') {
      writer.uint32(10).string(message.UploadSessionID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloseSessionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCloseSessionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UploadSessionID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CloseSessionReq {
    return {
      UploadSessionID: isSet(object.UploadSessionID)
        ? String(object.UploadSessionID)
        : '',
    };
  },

  toJSON(message: CloseSessionReq): unknown {
    const obj: any = {};
    message.UploadSessionID !== undefined &&
      (obj.UploadSessionID = message.UploadSessionID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CloseSessionReq>, I>>(
    object: I,
  ): CloseSessionReq {
    const message = createBaseCloseSessionReq();
    message.UploadSessionID = object.UploadSessionID ?? '';
    return message;
  },
};

function createBaseCloseSessionResp(): CloseSessionResp {
  return {
    Assets: [],
    User: undefined,
    Organization: undefined,
    Study: undefined,
    Report: undefined,
  };
}

export const CloseSessionResp = {
  encode(
    message: CloseSessionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Assets) {
      Asset.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.User !== undefined) {
      User.encode(message.User, writer.uint32(18).fork()).ldelim();
    }
    if (message.Organization !== undefined) {
      Organization.encode(
        message.Organization,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Study !== undefined) {
      Study.encode(message.Study, writer.uint32(34).fork()).ldelim();
    }
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloseSessionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCloseSessionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Assets.push(Asset.decode(reader, reader.uint32()));
          break;
        case 2:
          message.User = User.decode(reader, reader.uint32());
          break;
        case 3:
          message.Organization = Organization.decode(reader, reader.uint32());
          break;
        case 4:
          message.Study = Study.decode(reader, reader.uint32());
          break;
        case 5:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CloseSessionResp {
    return {
      Assets: Array.isArray(object?.Assets)
        ? object.Assets.map((e: any) => Asset.fromJSON(e))
        : [],
      User: isSet(object.User) ? User.fromJSON(object.User) : undefined,
      Organization: isSet(object.Organization)
        ? Organization.fromJSON(object.Organization)
        : undefined,
      Study: isSet(object.Study) ? Study.fromJSON(object.Study) : undefined,
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: CloseSessionResp): unknown {
    const obj: any = {};
    if (message.Assets) {
      obj.Assets = message.Assets.map((e) => (e ? Asset.toJSON(e) : undefined));
    } else {
      obj.Assets = [];
    }
    message.User !== undefined &&
      (obj.User = message.User ? User.toJSON(message.User) : undefined);
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? Organization.toJSON(message.Organization)
        : undefined);
    message.Study !== undefined &&
      (obj.Study = message.Study ? Study.toJSON(message.Study) : undefined);
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CloseSessionResp>, I>>(
    object: I,
  ): CloseSessionResp {
    const message = createBaseCloseSessionResp();
    message.Assets = object.Assets?.map((e) => Asset.fromPartial(e)) || [];
    message.User =
      object.User !== undefined && object.User !== null
        ? User.fromPartial(object.User)
        : undefined;
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? Organization.fromPartial(object.Organization)
        : undefined;
    message.Study =
      object.Study !== undefined && object.Study !== null
        ? Study.fromPartial(object.Study)
        : undefined;
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseDeleteStudyReq(): DeleteStudyReq {
  return { StudyID: '' };
}

export const DeleteStudyReq = {
  encode(
    message: DeleteStudyReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.StudyID !== '') {
      writer.uint32(10).string(message.StudyID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteStudyReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteStudyReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.StudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteStudyReq {
    return {
      StudyID: isSet(object.StudyID) ? String(object.StudyID) : '',
    };
  },

  toJSON(message: DeleteStudyReq): unknown {
    const obj: any = {};
    message.StudyID !== undefined && (obj.StudyID = message.StudyID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteStudyReq>, I>>(
    object: I,
  ): DeleteStudyReq {
    const message = createBaseDeleteStudyReq();
    message.StudyID = object.StudyID ?? '';
    return message;
  },
};

function createBaseDeleteStudyResp(): DeleteStudyResp {
  return {
    DeletedStudyID: '',
    DeletedReportIDs: [],
    DeletedToothIDs: [],
    DeletedConditionIDs: [],
    DeletedAssetIDs: [],
    DeletedInvitationIDs: [],
  };
}

export const DeleteStudyResp = {
  encode(
    message: DeleteStudyResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DeletedStudyID !== '') {
      writer.uint32(10).string(message.DeletedStudyID);
    }
    for (const v of message.DeletedReportIDs) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.DeletedToothIDs) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.DeletedConditionIDs) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.DeletedAssetIDs) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.DeletedInvitationIDs) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteStudyResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteStudyResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DeletedStudyID = reader.string();
          break;
        case 2:
          message.DeletedReportIDs.push(reader.string());
          break;
        case 3:
          message.DeletedToothIDs.push(reader.string());
          break;
        case 4:
          message.DeletedConditionIDs.push(reader.string());
          break;
        case 5:
          message.DeletedAssetIDs.push(reader.string());
          break;
        case 6:
          message.DeletedInvitationIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteStudyResp {
    return {
      DeletedStudyID: isSet(object.DeletedStudyID)
        ? String(object.DeletedStudyID)
        : '',
      DeletedReportIDs: Array.isArray(object?.DeletedReportIDs)
        ? object.DeletedReportIDs.map((e: any) => String(e))
        : [],
      DeletedToothIDs: Array.isArray(object?.DeletedToothIDs)
        ? object.DeletedToothIDs.map((e: any) => String(e))
        : [],
      DeletedConditionIDs: Array.isArray(object?.DeletedConditionIDs)
        ? object.DeletedConditionIDs.map((e: any) => String(e))
        : [],
      DeletedAssetIDs: Array.isArray(object?.DeletedAssetIDs)
        ? object.DeletedAssetIDs.map((e: any) => String(e))
        : [],
      DeletedInvitationIDs: Array.isArray(object?.DeletedInvitationIDs)
        ? object.DeletedInvitationIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: DeleteStudyResp): unknown {
    const obj: any = {};
    message.DeletedStudyID !== undefined &&
      (obj.DeletedStudyID = message.DeletedStudyID);
    if (message.DeletedReportIDs) {
      obj.DeletedReportIDs = message.DeletedReportIDs.map((e) => e);
    } else {
      obj.DeletedReportIDs = [];
    }
    if (message.DeletedToothIDs) {
      obj.DeletedToothIDs = message.DeletedToothIDs.map((e) => e);
    } else {
      obj.DeletedToothIDs = [];
    }
    if (message.DeletedConditionIDs) {
      obj.DeletedConditionIDs = message.DeletedConditionIDs.map((e) => e);
    } else {
      obj.DeletedConditionIDs = [];
    }
    if (message.DeletedAssetIDs) {
      obj.DeletedAssetIDs = message.DeletedAssetIDs.map((e) => e);
    } else {
      obj.DeletedAssetIDs = [];
    }
    if (message.DeletedInvitationIDs) {
      obj.DeletedInvitationIDs = message.DeletedInvitationIDs.map((e) => e);
    } else {
      obj.DeletedInvitationIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteStudyResp>, I>>(
    object: I,
  ): DeleteStudyResp {
    const message = createBaseDeleteStudyResp();
    message.DeletedStudyID = object.DeletedStudyID ?? '';
    message.DeletedReportIDs = object.DeletedReportIDs?.map((e) => e) || [];
    message.DeletedToothIDs = object.DeletedToothIDs?.map((e) => e) || [];
    message.DeletedConditionIDs =
      object.DeletedConditionIDs?.map((e) => e) || [];
    message.DeletedAssetIDs = object.DeletedAssetIDs?.map((e) => e) || [];
    message.DeletedInvitationIDs =
      object.DeletedInvitationIDs?.map((e) => e) || [];
    return message;
  },
};

export interface StorageService {
  /**
   * Permission: `UserPermissions.CanEditPersonalData`: user assets
   * Permission: `OrganizationPermissions.CanEditInfo`: organization assets
   * Permission: `PatientPermissions.CanUploadDocuments`: AssetType_Document
   * Permission: `PatientPermissions.CanUploadCBCT`: AssetType_CBCT_Study
   * Permission: `PatientPermissions.CanUploadIOXRay`: AssetType_IntraoralXRay_Study
   * Permission: `PatientPermissions.CanUploadPanoramicXRay`: AssetType_PanoramicXRay_Study
   * Permission: `PatientPermissions.CanUploadSTL`: AssetType_IOS_STL_Study
   * Permission: `PatientPermissions.CanUploadDentalPhoto`: AssetType_DentalPhoto_Study
   */
  StartUploadSession(
    request: DeepPartial<StartUploadSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<StartUploadSessionResp>;
  /** only if UploadSession.Closed == false */
  AddFilesToUploadSession(
    request: DeepPartial<AddFilesToUploadSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddFilesToUploadSessionResp>;
  NotifyFileUploadStared(
    request: DeepPartial<NotifyFileUploadStaredReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadStaredResp>;
  NotifyFileUploadProgress(
    request: DeepPartial<NotifyFileUploadProgressReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadProgressResp>;
  NotifyFileUploadFinished(
    request: DeepPartial<NotifyFileUploadFinishedReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadFinishedResp>;
  CloseSession(
    request: DeepPartial<CloseSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<CloseSessionResp>;
  DeleteStudy(
    request: DeepPartial<DeleteStudyReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteStudyResp>;
}

export class StorageServiceClientImpl implements StorageService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.StartUploadSession = this.StartUploadSession.bind(this);
    this.AddFilesToUploadSession = this.AddFilesToUploadSession.bind(this);
    this.NotifyFileUploadStared = this.NotifyFileUploadStared.bind(this);
    this.NotifyFileUploadProgress = this.NotifyFileUploadProgress.bind(this);
    this.NotifyFileUploadFinished = this.NotifyFileUploadFinished.bind(this);
    this.CloseSession = this.CloseSession.bind(this);
    this.DeleteStudy = this.DeleteStudy.bind(this);
  }

  StartUploadSession(
    request: DeepPartial<StartUploadSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<StartUploadSessionResp> {
    return this.rpc.unary(
      StorageServiceStartUploadSessionDesc,
      StartUploadSessionReq.fromPartial(request),
      metadata,
    );
  }

  AddFilesToUploadSession(
    request: DeepPartial<AddFilesToUploadSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddFilesToUploadSessionResp> {
    return this.rpc.unary(
      StorageServiceAddFilesToUploadSessionDesc,
      AddFilesToUploadSessionReq.fromPartial(request),
      metadata,
    );
  }

  NotifyFileUploadStared(
    request: DeepPartial<NotifyFileUploadStaredReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadStaredResp> {
    return this.rpc.unary(
      StorageServiceNotifyFileUploadStaredDesc,
      NotifyFileUploadStaredReq.fromPartial(request),
      metadata,
    );
  }

  NotifyFileUploadProgress(
    request: DeepPartial<NotifyFileUploadProgressReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadProgressResp> {
    return this.rpc.unary(
      StorageServiceNotifyFileUploadProgressDesc,
      NotifyFileUploadProgressReq.fromPartial(request),
      metadata,
    );
  }

  NotifyFileUploadFinished(
    request: DeepPartial<NotifyFileUploadFinishedReq>,
    metadata?: grpc.Metadata,
  ): Promise<NotifyFileUploadFinishedResp> {
    return this.rpc.unary(
      StorageServiceNotifyFileUploadFinishedDesc,
      NotifyFileUploadFinishedReq.fromPartial(request),
      metadata,
    );
  }

  CloseSession(
    request: DeepPartial<CloseSessionReq>,
    metadata?: grpc.Metadata,
  ): Promise<CloseSessionResp> {
    return this.rpc.unary(
      StorageServiceCloseSessionDesc,
      CloseSessionReq.fromPartial(request),
      metadata,
    );
  }

  DeleteStudy(
    request: DeepPartial<DeleteStudyReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteStudyResp> {
    return this.rpc.unary(
      StorageServiceDeleteStudyDesc,
      DeleteStudyReq.fromPartial(request),
      metadata,
    );
  }
}

export const StorageServiceDesc = {
  serviceName: 'com.diagnocat.core.StorageService',
};

export const StorageServiceStartUploadSessionDesc: UnaryMethodDefinitionish = {
  methodName: 'StartUploadSession',
  service: StorageServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StartUploadSessionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...StartUploadSessionResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const StorageServiceAddFilesToUploadSessionDesc: UnaryMethodDefinitionish =
  {
    methodName: 'AddFilesToUploadSession',
    service: StorageServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return AddFilesToUploadSessionReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...AddFilesToUploadSessionResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const StorageServiceNotifyFileUploadStaredDesc: UnaryMethodDefinitionish =
  {
    methodName: 'NotifyFileUploadStared',
    service: StorageServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return NotifyFileUploadStaredReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...NotifyFileUploadStaredResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const StorageServiceNotifyFileUploadProgressDesc: UnaryMethodDefinitionish =
  {
    methodName: 'NotifyFileUploadProgress',
    service: StorageServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return NotifyFileUploadProgressReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...NotifyFileUploadProgressResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const StorageServiceNotifyFileUploadFinishedDesc: UnaryMethodDefinitionish =
  {
    methodName: 'NotifyFileUploadFinished',
    service: StorageServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return NotifyFileUploadFinishedReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...NotifyFileUploadFinishedResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const StorageServiceCloseSessionDesc: UnaryMethodDefinitionish = {
  methodName: 'CloseSession',
  service: StorageServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CloseSessionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...CloseSessionResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const StorageServiceDeleteStudyDesc: UnaryMethodDefinitionish = {
  methodName: 'DeleteStudy',
  service: StorageServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteStudyReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeleteStudyResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
