import { JAW, Quartiles } from '../config/toothConfig';

export const getJawQuartile = (toothNumber: number): Quartiles => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const targetJawEntry = Object.entries(JAW).find(([_, teeth]) =>
    teeth.includes(toothNumber),
  );

  return targetJawEntry[0] as unknown as Quartiles;
};
