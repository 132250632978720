import React, { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import { useParams } from 'react-router';

import {
  Button,
  Column,
  Description,
  Layout,
  Skeleton,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui';
import { useReport } from '@/features/reportStream';
import { Header } from '@/widgets/Header';
import { PanoImage } from '@/widgets/PanoImage';
import { Conclusion } from '@/widgets/Conclusion';
import { useMedia } from '@/shared/hooks';

import {
  PANO_MOCKED_SRC,
  PANO_TOOLS_ICONS,
  REPORT_CREATION_DATE_FORMAT,
} from './config';
import styles from './EndodonticReport.module.scss';

type ReportProps = {
  className?: string;
};

export const EndodonticReport: FC<ReportProps> = (props) => {
  const { className } = props;
  const { isMobile } = useMedia();

  const { reportID } = useParams();

  const { report, isReportLoading, patientFullName, isPatientLoading } =
    useReport();

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main>
          <Layout.Content className={cn(styles.container, className)}>
            <header className={styles.header}>
              {isPatientLoading ? (
                <Skeleton width="35%" borderRadius="12px" height="24px" />
              ) : (
                <p className={cn(isMobile ? 'h2' : 'h1')}>{patientFullName}</p>
              )}
              {isReportLoading ? (
                <Skeleton width="55%" borderRadius="8px" height="16px" />
              ) : (
                <div className={styles.descriptionContainer}>
                  <Description
                    label={
                      <FormattedMessage
                        id="report.ID"
                        defaultMessage="Report ID"
                      />
                    }
                  >
                    {reportID}
                  </Description>

                  <Description
                    label={
                      <FormattedMessage
                        id="report.creationDate"
                        defaultMessage="Creation date"
                      />
                    }
                  >
                    {format(report.Created.At, REPORT_CREATION_DATE_FORMAT)}
                  </Description>

                  <Description
                    label={
                      <FormattedMessage
                        id="report.dateOfCBCT"
                        defaultMessage="Date of CBCT"
                      />
                    }
                  >
                    {format(report.Created.At, REPORT_CREATION_DATE_FORMAT)}
                  </Description>
                </div>
              )}
            </header>

            <WidgetLayout>
              <Column>
                <WidgetCard className={styles.panoImageWidget}>
                  <div className={styles.panoImage}>
                    <PanoImage
                      src={PANO_MOCKED_SRC}
                      controls={PANO_TOOLS_ICONS}
                    />
                  </div>
                </WidgetCard>
              </Column>

              <Column>
                <WidgetCard>
                  <h3>Slices</h3>
                </WidgetCard>

                <WidgetCard>
                  <Conclusion conclusion={report?.LegacyConclusion} />
                </WidgetCard>
              </Column>
            </WidgetLayout>
          </Layout.Content>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer className={styles.footer} sticky>
        <div className={styles.breadCrumbs}>breadcrumbs</div>
        <div className={styles.footerActions}>
          <Button
            variant="tertiary"
            onClick={() => {}}
            size={isMobile ? 'medium' : 'large'}
          >
            <FormattedMessage
              id="report.printReport"
              defaultMessage="Print report without signature"
            />
          </Button>
          <Button
            icon="check"
            onClick={() => {}}
            size={isMobile ? 'medium' : 'large'}
          >
            <FormattedMessage
              id="report.sign"
              defaultMessage="Sign report and print"
            />
          </Button>
        </div>
      </Layout.Footer>
    </Layout>
  );
};
