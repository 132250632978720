/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTSectionOrientation,
  File,
  DerivedData3DModel,
  DerivedDataImage,
  cBCTSectionOrientationFromJSON,
  cBCTSectionOrientationToJSON,
} from '../model/dto_asset_common';
import { Created, Revision, Deleted } from '../model/dto_base';
import { AssetContentDocument } from '../model/dto_asset_document';
import { MedicalImageFeatures } from '../model/dto_common_image_medical_features';
import {
  AssetContentGeneratedCBCTGPPanoramaReformatSplit,
  AssetContentGeneratedCBCTGPPanoramaReformatGeneral,
  AssetContentGeneratedCBCTGPToothSlice,
  AssetContentGeneratedCBCTGPMPRSubVolume,
  AssetContentGeneratedCBCTGPPeriodontiumImage,
} from '../model/dto_asset_cbct_gp';
import {
  AssetContentGeneratedCBCTEndoPanoramaSplit,
  AssetContentGeneratedCBCTEndoCrossSection,
  AssetContentGeneratedCBCTEndoGuide,
} from '../model/dto_asset_cbct_endo';
import {
  AssetContentGeneratedCBCTImplantPanorama,
  AssetContentGeneratedCBCTImplantSlice,
} from '../model/dto_asset_cbct_implant';
import {
  AssetContentGeneratedCBCTMolarPanorama,
  AssetContentGeneratedCBCTMolarGuide,
  AssetContentGeneratedCBCTMolarCrossSection,
} from '../model/dto_asset_cbct_molar';
import { AssetContentDentalPhotoGPTypePredictions } from '../model/dto_asset_dental_photo';
import { AssetContentGeneratedCBCTSegmentedModel } from '../model/dto_asset_cbct_segmentation';
import { AssetContentGeneratedSuperimpositionModel } from '../model/dto_asset_ios_cbct_superimposition';

export const protobufPackage = 'com.diagnocat.model';

export enum AssetType {
  AssetType_InvalidValue = 0,
  AssetType_User_Avatar = 1,
  AssetType_User_Signature = 2,
  AssetType_Organization_Logo = 11,
  AssetType_Organization_Stamp = 12,
  AssetType_Organization_EmployeeStamp = 13,
  AssetType_Document = 21,
  AssetType_CBCT_Study = 31,
  /** AssetType_IntraoralXRay_Study - FMX */
  AssetType_IntraoralXRay_Study = 32,
  /** AssetType_PanoramicXRay_Study - PANORAMA */
  AssetType_PanoramicXRay_Study = 33,
  AssetType_IOS_STL_Study = 34,
  AssetType_DentalPhoto_Study = 35,
  AssetType_Report_CBCT_Generated_ToothPathologySlice = 41,
  AssetType_Report_CBCT_GP_Generated_PanoramaReformatSplit = 51,
  AssetType_Report_CBCT_GP_Generated_PanoramaReformatGeneral = 52,
  AssetType_Report_CBCT_GP_Generated_ToothSlice = 53,
  AssetType_Report_CBCT_GP_Generated_MPRSubVolume = 54,
  AssetType_Report_CBCT_GP_Generated_PeriodontiumImage = 55,
  AssetType_Report_CBCT_Endo_Generated_PanoramaSplit = 61,
  AssetType_Report_CBCT_Endo_Generated_CrossSection = 62,
  AssetType_Report_CBCT_Endo_Generated_Guide = 63,
  AssetType_Report_CBCT_Implant_Generated_Panorama = 71,
  AssetType_Report_CBCT_Implant_Generated_Slice = 72,
  AssetType_Report_CBCT_Molar_Generated_Panorama = 81,
  AssetType_Report_CBCT_Molar_Generated_Guide = 82,
  AssetType_Report_CBCT_Molar_Generated_CrossSection = 83,
  AssetType_Report_CBCT_Ortho_Generated_PDF = 91,
  AssetType_Report_Generated_Mask2D = 101,
  AssetType_Report_DentalPhoto_GP_Generated_TypePredictions = 111,
  AssetType_Report_CBCT_Segmented_Generated_Model = 121,
  AssetType_Report_Superimposition_Generated_Model = 122,
  UNRECOGNIZED = -1,
}

export function assetTypeFromJSON(object: any): AssetType {
  switch (object) {
    case 0:
    case 'AssetType_InvalidValue':
      return AssetType.AssetType_InvalidValue;
    case 1:
    case 'AssetType_User_Avatar':
      return AssetType.AssetType_User_Avatar;
    case 2:
    case 'AssetType_User_Signature':
      return AssetType.AssetType_User_Signature;
    case 11:
    case 'AssetType_Organization_Logo':
      return AssetType.AssetType_Organization_Logo;
    case 12:
    case 'AssetType_Organization_Stamp':
      return AssetType.AssetType_Organization_Stamp;
    case 13:
    case 'AssetType_Organization_EmployeeStamp':
      return AssetType.AssetType_Organization_EmployeeStamp;
    case 21:
    case 'AssetType_Document':
      return AssetType.AssetType_Document;
    case 31:
    case 'AssetType_CBCT_Study':
      return AssetType.AssetType_CBCT_Study;
    case 32:
    case 'AssetType_IntraoralXRay_Study':
      return AssetType.AssetType_IntraoralXRay_Study;
    case 33:
    case 'AssetType_PanoramicXRay_Study':
      return AssetType.AssetType_PanoramicXRay_Study;
    case 34:
    case 'AssetType_IOS_STL_Study':
      return AssetType.AssetType_IOS_STL_Study;
    case 35:
    case 'AssetType_DentalPhoto_Study':
      return AssetType.AssetType_DentalPhoto_Study;
    case 41:
    case 'AssetType_Report_CBCT_Generated_ToothPathologySlice':
      return AssetType.AssetType_Report_CBCT_Generated_ToothPathologySlice;
    case 51:
    case 'AssetType_Report_CBCT_GP_Generated_PanoramaReformatSplit':
      return AssetType.AssetType_Report_CBCT_GP_Generated_PanoramaReformatSplit;
    case 52:
    case 'AssetType_Report_CBCT_GP_Generated_PanoramaReformatGeneral':
      return AssetType.AssetType_Report_CBCT_GP_Generated_PanoramaReformatGeneral;
    case 53:
    case 'AssetType_Report_CBCT_GP_Generated_ToothSlice':
      return AssetType.AssetType_Report_CBCT_GP_Generated_ToothSlice;
    case 54:
    case 'AssetType_Report_CBCT_GP_Generated_MPRSubVolume':
      return AssetType.AssetType_Report_CBCT_GP_Generated_MPRSubVolume;
    case 55:
    case 'AssetType_Report_CBCT_GP_Generated_PeriodontiumImage':
      return AssetType.AssetType_Report_CBCT_GP_Generated_PeriodontiumImage;
    case 61:
    case 'AssetType_Report_CBCT_Endo_Generated_PanoramaSplit':
      return AssetType.AssetType_Report_CBCT_Endo_Generated_PanoramaSplit;
    case 62:
    case 'AssetType_Report_CBCT_Endo_Generated_CrossSection':
      return AssetType.AssetType_Report_CBCT_Endo_Generated_CrossSection;
    case 63:
    case 'AssetType_Report_CBCT_Endo_Generated_Guide':
      return AssetType.AssetType_Report_CBCT_Endo_Generated_Guide;
    case 71:
    case 'AssetType_Report_CBCT_Implant_Generated_Panorama':
      return AssetType.AssetType_Report_CBCT_Implant_Generated_Panorama;
    case 72:
    case 'AssetType_Report_CBCT_Implant_Generated_Slice':
      return AssetType.AssetType_Report_CBCT_Implant_Generated_Slice;
    case 81:
    case 'AssetType_Report_CBCT_Molar_Generated_Panorama':
      return AssetType.AssetType_Report_CBCT_Molar_Generated_Panorama;
    case 82:
    case 'AssetType_Report_CBCT_Molar_Generated_Guide':
      return AssetType.AssetType_Report_CBCT_Molar_Generated_Guide;
    case 83:
    case 'AssetType_Report_CBCT_Molar_Generated_CrossSection':
      return AssetType.AssetType_Report_CBCT_Molar_Generated_CrossSection;
    case 91:
    case 'AssetType_Report_CBCT_Ortho_Generated_PDF':
      return AssetType.AssetType_Report_CBCT_Ortho_Generated_PDF;
    case 101:
    case 'AssetType_Report_Generated_Mask2D':
      return AssetType.AssetType_Report_Generated_Mask2D;
    case 111:
    case 'AssetType_Report_DentalPhoto_GP_Generated_TypePredictions':
      return AssetType.AssetType_Report_DentalPhoto_GP_Generated_TypePredictions;
    case 121:
    case 'AssetType_Report_CBCT_Segmented_Generated_Model':
      return AssetType.AssetType_Report_CBCT_Segmented_Generated_Model;
    case 122:
    case 'AssetType_Report_Superimposition_Generated_Model':
      return AssetType.AssetType_Report_Superimposition_Generated_Model;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AssetType.UNRECOGNIZED;
  }
}

export function assetTypeToJSON(object: AssetType): string {
  switch (object) {
    case AssetType.AssetType_InvalidValue:
      return 'AssetType_InvalidValue';
    case AssetType.AssetType_User_Avatar:
      return 'AssetType_User_Avatar';
    case AssetType.AssetType_User_Signature:
      return 'AssetType_User_Signature';
    case AssetType.AssetType_Organization_Logo:
      return 'AssetType_Organization_Logo';
    case AssetType.AssetType_Organization_Stamp:
      return 'AssetType_Organization_Stamp';
    case AssetType.AssetType_Organization_EmployeeStamp:
      return 'AssetType_Organization_EmployeeStamp';
    case AssetType.AssetType_Document:
      return 'AssetType_Document';
    case AssetType.AssetType_CBCT_Study:
      return 'AssetType_CBCT_Study';
    case AssetType.AssetType_IntraoralXRay_Study:
      return 'AssetType_IntraoralXRay_Study';
    case AssetType.AssetType_PanoramicXRay_Study:
      return 'AssetType_PanoramicXRay_Study';
    case AssetType.AssetType_IOS_STL_Study:
      return 'AssetType_IOS_STL_Study';
    case AssetType.AssetType_DentalPhoto_Study:
      return 'AssetType_DentalPhoto_Study';
    case AssetType.AssetType_Report_CBCT_Generated_ToothPathologySlice:
      return 'AssetType_Report_CBCT_Generated_ToothPathologySlice';
    case AssetType.AssetType_Report_CBCT_GP_Generated_PanoramaReformatSplit:
      return 'AssetType_Report_CBCT_GP_Generated_PanoramaReformatSplit';
    case AssetType.AssetType_Report_CBCT_GP_Generated_PanoramaReformatGeneral:
      return 'AssetType_Report_CBCT_GP_Generated_PanoramaReformatGeneral';
    case AssetType.AssetType_Report_CBCT_GP_Generated_ToothSlice:
      return 'AssetType_Report_CBCT_GP_Generated_ToothSlice';
    case AssetType.AssetType_Report_CBCT_GP_Generated_MPRSubVolume:
      return 'AssetType_Report_CBCT_GP_Generated_MPRSubVolume';
    case AssetType.AssetType_Report_CBCT_GP_Generated_PeriodontiumImage:
      return 'AssetType_Report_CBCT_GP_Generated_PeriodontiumImage';
    case AssetType.AssetType_Report_CBCT_Endo_Generated_PanoramaSplit:
      return 'AssetType_Report_CBCT_Endo_Generated_PanoramaSplit';
    case AssetType.AssetType_Report_CBCT_Endo_Generated_CrossSection:
      return 'AssetType_Report_CBCT_Endo_Generated_CrossSection';
    case AssetType.AssetType_Report_CBCT_Endo_Generated_Guide:
      return 'AssetType_Report_CBCT_Endo_Generated_Guide';
    case AssetType.AssetType_Report_CBCT_Implant_Generated_Panorama:
      return 'AssetType_Report_CBCT_Implant_Generated_Panorama';
    case AssetType.AssetType_Report_CBCT_Implant_Generated_Slice:
      return 'AssetType_Report_CBCT_Implant_Generated_Slice';
    case AssetType.AssetType_Report_CBCT_Molar_Generated_Panorama:
      return 'AssetType_Report_CBCT_Molar_Generated_Panorama';
    case AssetType.AssetType_Report_CBCT_Molar_Generated_Guide:
      return 'AssetType_Report_CBCT_Molar_Generated_Guide';
    case AssetType.AssetType_Report_CBCT_Molar_Generated_CrossSection:
      return 'AssetType_Report_CBCT_Molar_Generated_CrossSection';
    case AssetType.AssetType_Report_CBCT_Ortho_Generated_PDF:
      return 'AssetType_Report_CBCT_Ortho_Generated_PDF';
    case AssetType.AssetType_Report_Generated_Mask2D:
      return 'AssetType_Report_Generated_Mask2D';
    case AssetType.AssetType_Report_DentalPhoto_GP_Generated_TypePredictions:
      return 'AssetType_Report_DentalPhoto_GP_Generated_TypePredictions';
    case AssetType.AssetType_Report_CBCT_Segmented_Generated_Model:
      return 'AssetType_Report_CBCT_Segmented_Generated_Model';
    case AssetType.AssetType_Report_Superimposition_Generated_Model:
      return 'AssetType_Report_Superimposition_Generated_Model';
    case AssetType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Asset is a group of logically connected `Files`
 * no `File` could exists without `Asset`
 */
export interface Asset {
  ID: string;
  UploadSessionIDs: string[];
  Type: AssetType;
  /** Uploaded from client */
  User: UserAsset | undefined;
  /** Uploaded from client */
  Organization: OrganizationAsset | undefined;
  /** Uploaded from client */
  Patient: PatientAsset | undefined;
  /** Uploaded from client */
  Study: StudyAsset | undefined;
  /** Generated and uploaded by pipelines */
  GeneratedReport: GeneratedReportAsset | undefined;
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
}

export interface UserAsset {
  UserID: string;
  Avatar: AssetContentSimpleImage | undefined;
  Signature: AssetContentSimpleImage | undefined;
}

export interface OrganizationAsset {
  OrganizationID: string;
  /** only for EmployeeStamp */
  UserID: string;
  Logo: AssetContentSimpleImage | undefined;
  Stamp: AssetContentSimpleImage | undefined;
  EmployeeStamp: AssetContentSimpleImage | undefined;
}

export interface PatientAsset {
  OrganizationID: string;
  PatientID: string;
  Document: AssetContentDocument | undefined;
}

export interface StudyAsset {
  StudyID: string;
  PatientID: string;
  OrganizationID: string;
  CBCT: AssetContentStudyCBCT | undefined;
  PanoramicXRay: AssetContentStudyPanoramicXRay | undefined;
  IntraoralXRay: AssetContentStudyIntraoralXRay | undefined;
  iosStl: AssetContentStudyIOSSTL | undefined;
  DentalPhoto: AssetContentStudyDentalPhoto | undefined;
}

export interface GeneratedReportAsset {
  ReportID: string;
  SourceStudyIDs: string[];
  PatientID: string;
  OrganizationID: string;
  ToothID: string;
  /** Optional. Applies only to medical image assets. */
  MedicalImageFeatures: MedicalImageFeatures | undefined;
  CBCTGPPanoramaReformatSplit:
    | AssetContentGeneratedCBCTGPPanoramaReformatSplit
    | undefined;
  CBCTGPPanoramaReformatGeneral:
    | AssetContentGeneratedCBCTGPPanoramaReformatGeneral
    | undefined;
  CBCTGPToothSlice: AssetContentGeneratedCBCTGPToothSlice | undefined;
  CBCTGPMPRSubVolume: AssetContentGeneratedCBCTGPMPRSubVolume | undefined;
  CBCTGPPeriodontiumImage:
    | AssetContentGeneratedCBCTGPPeriodontiumImage
    | undefined;
  CBCTEndoPanoramaSplit: AssetContentGeneratedCBCTEndoPanoramaSplit | undefined;
  CBCTEndoCrossSection: AssetContentGeneratedCBCTEndoCrossSection | undefined;
  CBCTEndoGuide: AssetContentGeneratedCBCTEndoGuide | undefined;
  CBCTImplantPanorama: AssetContentGeneratedCBCTImplantPanorama | undefined;
  CBCTImplantSlice: AssetContentGeneratedCBCTImplantSlice | undefined;
  CBCTMolarPanorama: AssetContentGeneratedCBCTMolarPanorama | undefined;
  CBCTMolarGuide: AssetContentGeneratedCBCTMolarGuide | undefined;
  CBCTMolarCrossSection: AssetContentGeneratedCBCTMolarCrossSection | undefined;
  CBCTOrthoPDF: AssetContentGeneratedCBCTOrthoPDF | undefined;
  Mask2D: AssetContentGeneratedMask2D | undefined;
  DentalPhotoGPTypePredictions:
    | AssetContentDentalPhotoGPTypePredictions
    | undefined;
  CBCTSegmentedModel: AssetContentGeneratedCBCTSegmentedModel | undefined;
  /** AssetContent_Generated_Mask_3D Mask_3D = 172; */
  SuperimpositionModel: AssetContentGeneratedSuperimpositionModel | undefined;
  CBCTToothPathologySlice:
    | AssetContentGeneratedCBCTToothPathologySlice
    | undefined;
}

export interface AssetContentStudyCBCT {
  DICOMFiles: File[];
  /** only for old data */
  CBCTArchive: File | undefined;
  Metadata: AssetContentStudyCBCT_CBCTMetadata | undefined;
}

export interface AssetContentStudyCBCT_CBCTMetadata {
  StudyDate: string;
  StudyTime: string;
  InternalStudyUID: string;
  InternalSeriesUID: string;
  InternalInstanceUID: string;
  ScanUID: string;
  Manufacturer: string;
  ManufacturerModelName: string;
  SliceThickness: string;
  FOV: string;
  VoxelSize: string;
  Exposure: string;
}

export interface AssetContentStudyIOSSTL {
  UpperJawModel: File | undefined;
  LowerJawModel: File | undefined;
  /** Not used in pipelines */
  Derived: DerivedData3DModel | undefined;
}

export interface AssetContentStudyPanoramicXRay {
  PanoramaImage: File | undefined;
  /** Not used in pipelines */
  Derived: DerivedDataImage | undefined;
}

export interface AssetContentStudyIntraoralXRay {
  Images: AssetContentStudyIntraoralXRay_XRayImage[];
}

export interface AssetContentStudyIntraoralXRay_XRayImage {
  Image: File | undefined;
  /** TODO Metadata = 3; */
  Derived: DerivedDataImage | undefined;
}

export interface AssetContentStudyDentalPhoto {
  Images: AssetContentSimpleImage[];
}

/**
 * //////////////////////////////////////////////////////////////
 * // ToothPathologySliceImage
 * //
 */
export interface AssetContentGeneratedCBCTToothPathologySlice {
  Slice: File | undefined;
  Orientation: CBCTSectionOrientation;
  PredictedISONumber: number;
  Pathology: AssetContentGeneratedCBCTToothPathologySlice_PathologyType;
  PathologyComponent: number;
  MaskAssetID: string;
}

export enum AssetContentGeneratedCBCTToothPathologySlice_PathologyType {
  Caries = 0,
  PeriapicalLesion = 1,
  UNRECOGNIZED = -1,
}

export function assetContentGeneratedCBCTToothPathologySlice_PathologyTypeFromJSON(
  object: any,
): AssetContentGeneratedCBCTToothPathologySlice_PathologyType {
  switch (object) {
    case 0:
    case 'Caries':
      return AssetContentGeneratedCBCTToothPathologySlice_PathologyType.Caries;
    case 1:
    case 'PeriapicalLesion':
      return AssetContentGeneratedCBCTToothPathologySlice_PathologyType.PeriapicalLesion;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AssetContentGeneratedCBCTToothPathologySlice_PathologyType.UNRECOGNIZED;
  }
}

export function assetContentGeneratedCBCTToothPathologySlice_PathologyTypeToJSON(
  object: AssetContentGeneratedCBCTToothPathologySlice_PathologyType,
): string {
  switch (object) {
    case AssetContentGeneratedCBCTToothPathologySlice_PathologyType.Caries:
      return 'Caries';
    case AssetContentGeneratedCBCTToothPathologySlice_PathologyType.PeriapicalLesion:
      return 'PeriapicalLesion';
    case AssetContentGeneratedCBCTToothPathologySlice_PathologyType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * //////////////////////////////////////////////////////////////
 * // Primary Assets
 * //
 */
export interface AssetContentSimpleImage {
  Original: File | undefined;
  /** Not used in pipelines */
  Derived: DerivedDataImage | undefined;
}

export interface AssetContentGeneratedCBCTOrthoPDF {
  PDF: File | undefined;
}

export interface AssetContentGeneratedMask2D {
  Mask: File | undefined;
  TargetAssetID: string;
  TargetFileID: string;
}

function createBaseAsset(): Asset {
  return {
    ID: '',
    UploadSessionIDs: [],
    Type: 0,
    User: undefined,
    Organization: undefined,
    Patient: undefined,
    Study: undefined,
    GeneratedReport: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const Asset = {
  encode(message: Asset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    for (const v of message.UploadSessionIDs) {
      writer.uint32(18).string(v!);
    }
    if (message.Type !== 0) {
      writer.uint32(24).int32(message.Type);
    }
    if (message.User !== undefined) {
      UserAsset.encode(message.User, writer.uint32(810).fork()).ldelim();
    }
    if (message.Organization !== undefined) {
      OrganizationAsset.encode(
        message.Organization,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.Patient !== undefined) {
      PatientAsset.encode(message.Patient, writer.uint32(826).fork()).ldelim();
    }
    if (message.Study !== undefined) {
      StudyAsset.encode(message.Study, writer.uint32(834).fork()).ldelim();
    }
    if (message.GeneratedReport !== undefined) {
      GeneratedReportAsset.encode(
        message.GeneratedReport,
        writer.uint32(842).fork(),
      ).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Asset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.UploadSessionIDs.push(reader.string());
          break;
        case 3:
          message.Type = reader.int32() as any;
          break;
        case 101:
          message.User = UserAsset.decode(reader, reader.uint32());
          break;
        case 102:
          message.Organization = OrganizationAsset.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 103:
          message.Patient = PatientAsset.decode(reader, reader.uint32());
          break;
        case 104:
          message.Study = StudyAsset.decode(reader, reader.uint32());
          break;
        case 105:
          message.GeneratedReport = GeneratedReportAsset.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Asset {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      UploadSessionIDs: Array.isArray(object?.UploadSessionIDs)
        ? object.UploadSessionIDs.map((e: any) => String(e))
        : [],
      Type: isSet(object.Type) ? assetTypeFromJSON(object.Type) : 0,
      User: isSet(object.User) ? UserAsset.fromJSON(object.User) : undefined,
      Organization: isSet(object.Organization)
        ? OrganizationAsset.fromJSON(object.Organization)
        : undefined,
      Patient: isSet(object.Patient)
        ? PatientAsset.fromJSON(object.Patient)
        : undefined,
      Study: isSet(object.Study)
        ? StudyAsset.fromJSON(object.Study)
        : undefined,
      GeneratedReport: isSet(object.GeneratedReport)
        ? GeneratedReportAsset.fromJSON(object.GeneratedReport)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: Asset): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    if (message.UploadSessionIDs) {
      obj.UploadSessionIDs = message.UploadSessionIDs.map((e) => e);
    } else {
      obj.UploadSessionIDs = [];
    }
    message.Type !== undefined && (obj.Type = assetTypeToJSON(message.Type));
    message.User !== undefined &&
      (obj.User = message.User ? UserAsset.toJSON(message.User) : undefined);
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? OrganizationAsset.toJSON(message.Organization)
        : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? PatientAsset.toJSON(message.Patient)
        : undefined);
    message.Study !== undefined &&
      (obj.Study = message.Study
        ? StudyAsset.toJSON(message.Study)
        : undefined);
    message.GeneratedReport !== undefined &&
      (obj.GeneratedReport = message.GeneratedReport
        ? GeneratedReportAsset.toJSON(message.GeneratedReport)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Asset>, I>>(object: I): Asset {
    const message = createBaseAsset();
    message.ID = object.ID ?? '';
    message.UploadSessionIDs = object.UploadSessionIDs?.map((e) => e) || [];
    message.Type = object.Type ?? 0;
    message.User =
      object.User !== undefined && object.User !== null
        ? UserAsset.fromPartial(object.User)
        : undefined;
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? OrganizationAsset.fromPartial(object.Organization)
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? PatientAsset.fromPartial(object.Patient)
        : undefined;
    message.Study =
      object.Study !== undefined && object.Study !== null
        ? StudyAsset.fromPartial(object.Study)
        : undefined;
    message.GeneratedReport =
      object.GeneratedReport !== undefined && object.GeneratedReport !== null
        ? GeneratedReportAsset.fromPartial(object.GeneratedReport)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

function createBaseUserAsset(): UserAsset {
  return { UserID: '', Avatar: undefined, Signature: undefined };
}

export const UserAsset = {
  encode(
    message: UserAsset,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.Avatar !== undefined) {
      AssetContentSimpleImage.encode(
        message.Avatar,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.Signature !== undefined) {
      AssetContentSimpleImage.encode(
        message.Signature,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAsset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 101:
          message.Avatar = AssetContentSimpleImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 102:
          message.Signature = AssetContentSimpleImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UserAsset {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      Avatar: isSet(object.Avatar)
        ? AssetContentSimpleImage.fromJSON(object.Avatar)
        : undefined,
      Signature: isSet(object.Signature)
        ? AssetContentSimpleImage.fromJSON(object.Signature)
        : undefined,
    };
  },

  toJSON(message: UserAsset): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.Avatar !== undefined &&
      (obj.Avatar = message.Avatar
        ? AssetContentSimpleImage.toJSON(message.Avatar)
        : undefined);
    message.Signature !== undefined &&
      (obj.Signature = message.Signature
        ? AssetContentSimpleImage.toJSON(message.Signature)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<UserAsset>, I>>(
    object: I,
  ): UserAsset {
    const message = createBaseUserAsset();
    message.UserID = object.UserID ?? '';
    message.Avatar =
      object.Avatar !== undefined && object.Avatar !== null
        ? AssetContentSimpleImage.fromPartial(object.Avatar)
        : undefined;
    message.Signature =
      object.Signature !== undefined && object.Signature !== null
        ? AssetContentSimpleImage.fromPartial(object.Signature)
        : undefined;
    return message;
  },
};

function createBaseOrganizationAsset(): OrganizationAsset {
  return {
    OrganizationID: '',
    UserID: '',
    Logo: undefined,
    Stamp: undefined,
    EmployeeStamp: undefined,
  };
}

export const OrganizationAsset = {
  encode(
    message: OrganizationAsset,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.UserID !== '') {
      writer.uint32(18).string(message.UserID);
    }
    if (message.Logo !== undefined) {
      AssetContentSimpleImage.encode(
        message.Logo,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.Stamp !== undefined) {
      AssetContentSimpleImage.encode(
        message.Stamp,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.EmployeeStamp !== undefined) {
      AssetContentSimpleImage.encode(
        message.EmployeeStamp,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationAsset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.UserID = reader.string();
          break;
        case 101:
          message.Logo = AssetContentSimpleImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 102:
          message.Stamp = AssetContentSimpleImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 103:
          message.EmployeeStamp = AssetContentSimpleImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationAsset {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      Logo: isSet(object.Logo)
        ? AssetContentSimpleImage.fromJSON(object.Logo)
        : undefined,
      Stamp: isSet(object.Stamp)
        ? AssetContentSimpleImage.fromJSON(object.Stamp)
        : undefined,
      EmployeeStamp: isSet(object.EmployeeStamp)
        ? AssetContentSimpleImage.fromJSON(object.EmployeeStamp)
        : undefined,
    };
  },

  toJSON(message: OrganizationAsset): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.Logo !== undefined &&
      (obj.Logo = message.Logo
        ? AssetContentSimpleImage.toJSON(message.Logo)
        : undefined);
    message.Stamp !== undefined &&
      (obj.Stamp = message.Stamp
        ? AssetContentSimpleImage.toJSON(message.Stamp)
        : undefined);
    message.EmployeeStamp !== undefined &&
      (obj.EmployeeStamp = message.EmployeeStamp
        ? AssetContentSimpleImage.toJSON(message.EmployeeStamp)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationAsset>, I>>(
    object: I,
  ): OrganizationAsset {
    const message = createBaseOrganizationAsset();
    message.OrganizationID = object.OrganizationID ?? '';
    message.UserID = object.UserID ?? '';
    message.Logo =
      object.Logo !== undefined && object.Logo !== null
        ? AssetContentSimpleImage.fromPartial(object.Logo)
        : undefined;
    message.Stamp =
      object.Stamp !== undefined && object.Stamp !== null
        ? AssetContentSimpleImage.fromPartial(object.Stamp)
        : undefined;
    message.EmployeeStamp =
      object.EmployeeStamp !== undefined && object.EmployeeStamp !== null
        ? AssetContentSimpleImage.fromPartial(object.EmployeeStamp)
        : undefined;
    return message;
  },
};

function createBasePatientAsset(): PatientAsset {
  return { OrganizationID: '', PatientID: '', Document: undefined };
}

export const PatientAsset = {
  encode(
    message: PatientAsset,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.PatientID !== '') {
      writer.uint32(18).string(message.PatientID);
    }
    if (message.Document !== undefined) {
      AssetContentDocument.encode(
        message.Document,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatientAsset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.PatientID = reader.string();
          break;
        case 101:
          message.Document = AssetContentDocument.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientAsset {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      Document: isSet(object.Document)
        ? AssetContentDocument.fromJSON(object.Document)
        : undefined,
    };
  },

  toJSON(message: PatientAsset): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.Document !== undefined &&
      (obj.Document = message.Document
        ? AssetContentDocument.toJSON(message.Document)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientAsset>, I>>(
    object: I,
  ): PatientAsset {
    const message = createBasePatientAsset();
    message.OrganizationID = object.OrganizationID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.Document =
      object.Document !== undefined && object.Document !== null
        ? AssetContentDocument.fromPartial(object.Document)
        : undefined;
    return message;
  },
};

function createBaseStudyAsset(): StudyAsset {
  return {
    StudyID: '',
    PatientID: '',
    OrganizationID: '',
    CBCT: undefined,
    PanoramicXRay: undefined,
    IntraoralXRay: undefined,
    iosStl: undefined,
    DentalPhoto: undefined,
  };
}

export const StudyAsset = {
  encode(
    message: StudyAsset,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.StudyID !== '') {
      writer.uint32(10).string(message.StudyID);
    }
    if (message.PatientID !== '') {
      writer.uint32(18).string(message.PatientID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(26).string(message.OrganizationID);
    }
    if (message.CBCT !== undefined) {
      AssetContentStudyCBCT.encode(
        message.CBCT,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.PanoramicXRay !== undefined) {
      AssetContentStudyPanoramicXRay.encode(
        message.PanoramicXRay,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.IntraoralXRay !== undefined) {
      AssetContentStudyIntraoralXRay.encode(
        message.IntraoralXRay,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    if (message.iosStl !== undefined) {
      AssetContentStudyIOSSTL.encode(
        message.iosStl,
        writer.uint32(834).fork(),
      ).ldelim();
    }
    if (message.DentalPhoto !== undefined) {
      AssetContentStudyDentalPhoto.encode(
        message.DentalPhoto,
        writer.uint32(842).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StudyAsset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStudyAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.StudyID = reader.string();
          break;
        case 2:
          message.PatientID = reader.string();
          break;
        case 3:
          message.OrganizationID = reader.string();
          break;
        case 101:
          message.CBCT = AssetContentStudyCBCT.decode(reader, reader.uint32());
          break;
        case 102:
          message.PanoramicXRay = AssetContentStudyPanoramicXRay.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 103:
          message.IntraoralXRay = AssetContentStudyIntraoralXRay.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 104:
          message.iosStl = AssetContentStudyIOSSTL.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 105:
          message.DentalPhoto = AssetContentStudyDentalPhoto.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StudyAsset {
    return {
      StudyID: isSet(object.StudyID) ? String(object.StudyID) : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      CBCT: isSet(object.CBCT)
        ? AssetContentStudyCBCT.fromJSON(object.CBCT)
        : undefined,
      PanoramicXRay: isSet(object.PanoramicXRay)
        ? AssetContentStudyPanoramicXRay.fromJSON(object.PanoramicXRay)
        : undefined,
      IntraoralXRay: isSet(object.IntraoralXRay)
        ? AssetContentStudyIntraoralXRay.fromJSON(object.IntraoralXRay)
        : undefined,
      iosStl: isSet(object.IOSSTL)
        ? AssetContentStudyIOSSTL.fromJSON(object.IOSSTL)
        : undefined,
      DentalPhoto: isSet(object.DentalPhoto)
        ? AssetContentStudyDentalPhoto.fromJSON(object.DentalPhoto)
        : undefined,
    };
  },

  toJSON(message: StudyAsset): unknown {
    const obj: any = {};
    message.StudyID !== undefined && (obj.StudyID = message.StudyID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.CBCT !== undefined &&
      (obj.CBCT = message.CBCT
        ? AssetContentStudyCBCT.toJSON(message.CBCT)
        : undefined);
    message.PanoramicXRay !== undefined &&
      (obj.PanoramicXRay = message.PanoramicXRay
        ? AssetContentStudyPanoramicXRay.toJSON(message.PanoramicXRay)
        : undefined);
    message.IntraoralXRay !== undefined &&
      (obj.IntraoralXRay = message.IntraoralXRay
        ? AssetContentStudyIntraoralXRay.toJSON(message.IntraoralXRay)
        : undefined);
    message.iosStl !== undefined &&
      (obj.IOSSTL = message.iosStl
        ? AssetContentStudyIOSSTL.toJSON(message.iosStl)
        : undefined);
    message.DentalPhoto !== undefined &&
      (obj.DentalPhoto = message.DentalPhoto
        ? AssetContentStudyDentalPhoto.toJSON(message.DentalPhoto)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StudyAsset>, I>>(
    object: I,
  ): StudyAsset {
    const message = createBaseStudyAsset();
    message.StudyID = object.StudyID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.CBCT =
      object.CBCT !== undefined && object.CBCT !== null
        ? AssetContentStudyCBCT.fromPartial(object.CBCT)
        : undefined;
    message.PanoramicXRay =
      object.PanoramicXRay !== undefined && object.PanoramicXRay !== null
        ? AssetContentStudyPanoramicXRay.fromPartial(object.PanoramicXRay)
        : undefined;
    message.IntraoralXRay =
      object.IntraoralXRay !== undefined && object.IntraoralXRay !== null
        ? AssetContentStudyIntraoralXRay.fromPartial(object.IntraoralXRay)
        : undefined;
    message.iosStl =
      object.iosStl !== undefined && object.iosStl !== null
        ? AssetContentStudyIOSSTL.fromPartial(object.iosStl)
        : undefined;
    message.DentalPhoto =
      object.DentalPhoto !== undefined && object.DentalPhoto !== null
        ? AssetContentStudyDentalPhoto.fromPartial(object.DentalPhoto)
        : undefined;
    return message;
  },
};

function createBaseGeneratedReportAsset(): GeneratedReportAsset {
  return {
    ReportID: '',
    SourceStudyIDs: [],
    PatientID: '',
    OrganizationID: '',
    ToothID: '',
    MedicalImageFeatures: undefined,
    CBCTGPPanoramaReformatSplit: undefined,
    CBCTGPPanoramaReformatGeneral: undefined,
    CBCTGPToothSlice: undefined,
    CBCTGPMPRSubVolume: undefined,
    CBCTGPPeriodontiumImage: undefined,
    CBCTEndoPanoramaSplit: undefined,
    CBCTEndoCrossSection: undefined,
    CBCTEndoGuide: undefined,
    CBCTImplantPanorama: undefined,
    CBCTImplantSlice: undefined,
    CBCTMolarPanorama: undefined,
    CBCTMolarGuide: undefined,
    CBCTMolarCrossSection: undefined,
    CBCTOrthoPDF: undefined,
    Mask2D: undefined,
    DentalPhotoGPTypePredictions: undefined,
    CBCTSegmentedModel: undefined,
    SuperimpositionModel: undefined,
    CBCTToothPathologySlice: undefined,
  };
}

export const GeneratedReportAsset = {
  encode(
    message: GeneratedReportAsset,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    for (const v of message.SourceStudyIDs) {
      writer.uint32(18).string(v!);
    }
    if (message.PatientID !== '') {
      writer.uint32(26).string(message.PatientID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(34).string(message.OrganizationID);
    }
    if (message.ToothID !== '') {
      writer.uint32(42).string(message.ToothID);
    }
    if (message.MedicalImageFeatures !== undefined) {
      MedicalImageFeatures.encode(
        message.MedicalImageFeatures,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.CBCTGPPanoramaReformatSplit !== undefined) {
      AssetContentGeneratedCBCTGPPanoramaReformatSplit.encode(
        message.CBCTGPPanoramaReformatSplit,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.CBCTGPPanoramaReformatGeneral !== undefined) {
      AssetContentGeneratedCBCTGPPanoramaReformatGeneral.encode(
        message.CBCTGPPanoramaReformatGeneral,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.CBCTGPToothSlice !== undefined) {
      AssetContentGeneratedCBCTGPToothSlice.encode(
        message.CBCTGPToothSlice,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    if (message.CBCTGPMPRSubVolume !== undefined) {
      AssetContentGeneratedCBCTGPMPRSubVolume.encode(
        message.CBCTGPMPRSubVolume,
        writer.uint32(834).fork(),
      ).ldelim();
    }
    if (message.CBCTGPPeriodontiumImage !== undefined) {
      AssetContentGeneratedCBCTGPPeriodontiumImage.encode(
        message.CBCTGPPeriodontiumImage,
        writer.uint32(842).fork(),
      ).ldelim();
    }
    if (message.CBCTEndoPanoramaSplit !== undefined) {
      AssetContentGeneratedCBCTEndoPanoramaSplit.encode(
        message.CBCTEndoPanoramaSplit,
        writer.uint32(890).fork(),
      ).ldelim();
    }
    if (message.CBCTEndoCrossSection !== undefined) {
      AssetContentGeneratedCBCTEndoCrossSection.encode(
        message.CBCTEndoCrossSection,
        writer.uint32(898).fork(),
      ).ldelim();
    }
    if (message.CBCTEndoGuide !== undefined) {
      AssetContentGeneratedCBCTEndoGuide.encode(
        message.CBCTEndoGuide,
        writer.uint32(906).fork(),
      ).ldelim();
    }
    if (message.CBCTImplantPanorama !== undefined) {
      AssetContentGeneratedCBCTImplantPanorama.encode(
        message.CBCTImplantPanorama,
        writer.uint32(970).fork(),
      ).ldelim();
    }
    if (message.CBCTImplantSlice !== undefined) {
      AssetContentGeneratedCBCTImplantSlice.encode(
        message.CBCTImplantSlice,
        writer.uint32(978).fork(),
      ).ldelim();
    }
    if (message.CBCTMolarPanorama !== undefined) {
      AssetContentGeneratedCBCTMolarPanorama.encode(
        message.CBCTMolarPanorama,
        writer.uint32(1050).fork(),
      ).ldelim();
    }
    if (message.CBCTMolarGuide !== undefined) {
      AssetContentGeneratedCBCTMolarGuide.encode(
        message.CBCTMolarGuide,
        writer.uint32(1058).fork(),
      ).ldelim();
    }
    if (message.CBCTMolarCrossSection !== undefined) {
      AssetContentGeneratedCBCTMolarCrossSection.encode(
        message.CBCTMolarCrossSection,
        writer.uint32(1066).fork(),
      ).ldelim();
    }
    if (message.CBCTOrthoPDF !== undefined) {
      AssetContentGeneratedCBCTOrthoPDF.encode(
        message.CBCTOrthoPDF,
        writer.uint32(1130).fork(),
      ).ldelim();
    }
    if (message.Mask2D !== undefined) {
      AssetContentGeneratedMask2D.encode(
        message.Mask2D,
        writer.uint32(1210).fork(),
      ).ldelim();
    }
    if (message.DentalPhotoGPTypePredictions !== undefined) {
      AssetContentDentalPhotoGPTypePredictions.encode(
        message.DentalPhotoGPTypePredictions,
        writer.uint32(1290).fork(),
      ).ldelim();
    }
    if (message.CBCTSegmentedModel !== undefined) {
      AssetContentGeneratedCBCTSegmentedModel.encode(
        message.CBCTSegmentedModel,
        writer.uint32(1370).fork(),
      ).ldelim();
    }
    if (message.SuperimpositionModel !== undefined) {
      AssetContentGeneratedSuperimpositionModel.encode(
        message.SuperimpositionModel,
        writer.uint32(1378).fork(),
      ).ldelim();
    }
    if (message.CBCTToothPathologySlice !== undefined) {
      AssetContentGeneratedCBCTToothPathologySlice.encode(
        message.CBCTToothPathologySlice,
        writer.uint32(1450).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GeneratedReportAsset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeneratedReportAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.SourceStudyIDs.push(reader.string());
          break;
        case 3:
          message.PatientID = reader.string();
          break;
        case 4:
          message.OrganizationID = reader.string();
          break;
        case 5:
          message.ToothID = reader.string();
          break;
        case 11:
          message.MedicalImageFeatures = MedicalImageFeatures.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 101:
          message.CBCTGPPanoramaReformatSplit =
            AssetContentGeneratedCBCTGPPanoramaReformatSplit.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 102:
          message.CBCTGPPanoramaReformatGeneral =
            AssetContentGeneratedCBCTGPPanoramaReformatGeneral.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 103:
          message.CBCTGPToothSlice =
            AssetContentGeneratedCBCTGPToothSlice.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 104:
          message.CBCTGPMPRSubVolume =
            AssetContentGeneratedCBCTGPMPRSubVolume.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 105:
          message.CBCTGPPeriodontiumImage =
            AssetContentGeneratedCBCTGPPeriodontiumImage.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 111:
          message.CBCTEndoPanoramaSplit =
            AssetContentGeneratedCBCTEndoPanoramaSplit.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 112:
          message.CBCTEndoCrossSection =
            AssetContentGeneratedCBCTEndoCrossSection.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 113:
          message.CBCTEndoGuide = AssetContentGeneratedCBCTEndoGuide.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 121:
          message.CBCTImplantPanorama =
            AssetContentGeneratedCBCTImplantPanorama.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 122:
          message.CBCTImplantSlice =
            AssetContentGeneratedCBCTImplantSlice.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 131:
          message.CBCTMolarPanorama =
            AssetContentGeneratedCBCTMolarPanorama.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 132:
          message.CBCTMolarGuide = AssetContentGeneratedCBCTMolarGuide.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 133:
          message.CBCTMolarCrossSection =
            AssetContentGeneratedCBCTMolarCrossSection.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 141:
          message.CBCTOrthoPDF = AssetContentGeneratedCBCTOrthoPDF.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 151:
          message.Mask2D = AssetContentGeneratedMask2D.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 161:
          message.DentalPhotoGPTypePredictions =
            AssetContentDentalPhotoGPTypePredictions.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 171:
          message.CBCTSegmentedModel =
            AssetContentGeneratedCBCTSegmentedModel.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 172:
          message.SuperimpositionModel =
            AssetContentGeneratedSuperimpositionModel.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 181:
          message.CBCTToothPathologySlice =
            AssetContentGeneratedCBCTToothPathologySlice.decode(
              reader,
              reader.uint32(),
            );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeneratedReportAsset {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      SourceStudyIDs: Array.isArray(object?.SourceStudyIDs)
        ? object.SourceStudyIDs.map((e: any) => String(e))
        : [],
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      MedicalImageFeatures: isSet(object.MedicalImageFeatures)
        ? MedicalImageFeatures.fromJSON(object.MedicalImageFeatures)
        : undefined,
      CBCTGPPanoramaReformatSplit: isSet(object.CBCTGPPanoramaReformatSplit)
        ? AssetContentGeneratedCBCTGPPanoramaReformatSplit.fromJSON(
            object.CBCTGPPanoramaReformatSplit,
          )
        : undefined,
      CBCTGPPanoramaReformatGeneral: isSet(object.CBCTGPPanoramaReformatGeneral)
        ? AssetContentGeneratedCBCTGPPanoramaReformatGeneral.fromJSON(
            object.CBCTGPPanoramaReformatGeneral,
          )
        : undefined,
      CBCTGPToothSlice: isSet(object.CBCTGPToothSlice)
        ? AssetContentGeneratedCBCTGPToothSlice.fromJSON(
            object.CBCTGPToothSlice,
          )
        : undefined,
      CBCTGPMPRSubVolume: isSet(object.CBCTGPMPRSubVolume)
        ? AssetContentGeneratedCBCTGPMPRSubVolume.fromJSON(
            object.CBCTGPMPRSubVolume,
          )
        : undefined,
      CBCTGPPeriodontiumImage: isSet(object.CBCTGPPeriodontiumImage)
        ? AssetContentGeneratedCBCTGPPeriodontiumImage.fromJSON(
            object.CBCTGPPeriodontiumImage,
          )
        : undefined,
      CBCTEndoPanoramaSplit: isSet(object.CBCTEndoPanoramaSplit)
        ? AssetContentGeneratedCBCTEndoPanoramaSplit.fromJSON(
            object.CBCTEndoPanoramaSplit,
          )
        : undefined,
      CBCTEndoCrossSection: isSet(object.CBCTEndoCrossSection)
        ? AssetContentGeneratedCBCTEndoCrossSection.fromJSON(
            object.CBCTEndoCrossSection,
          )
        : undefined,
      CBCTEndoGuide: isSet(object.CBCTEndoGuide)
        ? AssetContentGeneratedCBCTEndoGuide.fromJSON(object.CBCTEndoGuide)
        : undefined,
      CBCTImplantPanorama: isSet(object.CBCTImplantPanorama)
        ? AssetContentGeneratedCBCTImplantPanorama.fromJSON(
            object.CBCTImplantPanorama,
          )
        : undefined,
      CBCTImplantSlice: isSet(object.CBCTImplantSlice)
        ? AssetContentGeneratedCBCTImplantSlice.fromJSON(
            object.CBCTImplantSlice,
          )
        : undefined,
      CBCTMolarPanorama: isSet(object.CBCTMolarPanorama)
        ? AssetContentGeneratedCBCTMolarPanorama.fromJSON(
            object.CBCTMolarPanorama,
          )
        : undefined,
      CBCTMolarGuide: isSet(object.CBCTMolarGuide)
        ? AssetContentGeneratedCBCTMolarGuide.fromJSON(object.CBCTMolarGuide)
        : undefined,
      CBCTMolarCrossSection: isSet(object.CBCTMolarCrossSection)
        ? AssetContentGeneratedCBCTMolarCrossSection.fromJSON(
            object.CBCTMolarCrossSection,
          )
        : undefined,
      CBCTOrthoPDF: isSet(object.CBCTOrthoPDF)
        ? AssetContentGeneratedCBCTOrthoPDF.fromJSON(object.CBCTOrthoPDF)
        : undefined,
      Mask2D: isSet(object.Mask2D)
        ? AssetContentGeneratedMask2D.fromJSON(object.Mask2D)
        : undefined,
      DentalPhotoGPTypePredictions: isSet(object.DentalPhotoGPTypePredictions)
        ? AssetContentDentalPhotoGPTypePredictions.fromJSON(
            object.DentalPhotoGPTypePredictions,
          )
        : undefined,
      CBCTSegmentedModel: isSet(object.CBCTSegmentedModel)
        ? AssetContentGeneratedCBCTSegmentedModel.fromJSON(
            object.CBCTSegmentedModel,
          )
        : undefined,
      SuperimpositionModel: isSet(object.SuperimpositionModel)
        ? AssetContentGeneratedSuperimpositionModel.fromJSON(
            object.SuperimpositionModel,
          )
        : undefined,
      CBCTToothPathologySlice: isSet(object.CBCTToothPathologySlice)
        ? AssetContentGeneratedCBCTToothPathologySlice.fromJSON(
            object.CBCTToothPathologySlice,
          )
        : undefined,
    };
  },

  toJSON(message: GeneratedReportAsset): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    if (message.SourceStudyIDs) {
      obj.SourceStudyIDs = message.SourceStudyIDs.map((e) => e);
    } else {
      obj.SourceStudyIDs = [];
    }
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.MedicalImageFeatures !== undefined &&
      (obj.MedicalImageFeatures = message.MedicalImageFeatures
        ? MedicalImageFeatures.toJSON(message.MedicalImageFeatures)
        : undefined);
    message.CBCTGPPanoramaReformatSplit !== undefined &&
      (obj.CBCTGPPanoramaReformatSplit = message.CBCTGPPanoramaReformatSplit
        ? AssetContentGeneratedCBCTGPPanoramaReformatSplit.toJSON(
            message.CBCTGPPanoramaReformatSplit,
          )
        : undefined);
    message.CBCTGPPanoramaReformatGeneral !== undefined &&
      (obj.CBCTGPPanoramaReformatGeneral = message.CBCTGPPanoramaReformatGeneral
        ? AssetContentGeneratedCBCTGPPanoramaReformatGeneral.toJSON(
            message.CBCTGPPanoramaReformatGeneral,
          )
        : undefined);
    message.CBCTGPToothSlice !== undefined &&
      (obj.CBCTGPToothSlice = message.CBCTGPToothSlice
        ? AssetContentGeneratedCBCTGPToothSlice.toJSON(message.CBCTGPToothSlice)
        : undefined);
    message.CBCTGPMPRSubVolume !== undefined &&
      (obj.CBCTGPMPRSubVolume = message.CBCTGPMPRSubVolume
        ? AssetContentGeneratedCBCTGPMPRSubVolume.toJSON(
            message.CBCTGPMPRSubVolume,
          )
        : undefined);
    message.CBCTGPPeriodontiumImage !== undefined &&
      (obj.CBCTGPPeriodontiumImage = message.CBCTGPPeriodontiumImage
        ? AssetContentGeneratedCBCTGPPeriodontiumImage.toJSON(
            message.CBCTGPPeriodontiumImage,
          )
        : undefined);
    message.CBCTEndoPanoramaSplit !== undefined &&
      (obj.CBCTEndoPanoramaSplit = message.CBCTEndoPanoramaSplit
        ? AssetContentGeneratedCBCTEndoPanoramaSplit.toJSON(
            message.CBCTEndoPanoramaSplit,
          )
        : undefined);
    message.CBCTEndoCrossSection !== undefined &&
      (obj.CBCTEndoCrossSection = message.CBCTEndoCrossSection
        ? AssetContentGeneratedCBCTEndoCrossSection.toJSON(
            message.CBCTEndoCrossSection,
          )
        : undefined);
    message.CBCTEndoGuide !== undefined &&
      (obj.CBCTEndoGuide = message.CBCTEndoGuide
        ? AssetContentGeneratedCBCTEndoGuide.toJSON(message.CBCTEndoGuide)
        : undefined);
    message.CBCTImplantPanorama !== undefined &&
      (obj.CBCTImplantPanorama = message.CBCTImplantPanorama
        ? AssetContentGeneratedCBCTImplantPanorama.toJSON(
            message.CBCTImplantPanorama,
          )
        : undefined);
    message.CBCTImplantSlice !== undefined &&
      (obj.CBCTImplantSlice = message.CBCTImplantSlice
        ? AssetContentGeneratedCBCTImplantSlice.toJSON(message.CBCTImplantSlice)
        : undefined);
    message.CBCTMolarPanorama !== undefined &&
      (obj.CBCTMolarPanorama = message.CBCTMolarPanorama
        ? AssetContentGeneratedCBCTMolarPanorama.toJSON(
            message.CBCTMolarPanorama,
          )
        : undefined);
    message.CBCTMolarGuide !== undefined &&
      (obj.CBCTMolarGuide = message.CBCTMolarGuide
        ? AssetContentGeneratedCBCTMolarGuide.toJSON(message.CBCTMolarGuide)
        : undefined);
    message.CBCTMolarCrossSection !== undefined &&
      (obj.CBCTMolarCrossSection = message.CBCTMolarCrossSection
        ? AssetContentGeneratedCBCTMolarCrossSection.toJSON(
            message.CBCTMolarCrossSection,
          )
        : undefined);
    message.CBCTOrthoPDF !== undefined &&
      (obj.CBCTOrthoPDF = message.CBCTOrthoPDF
        ? AssetContentGeneratedCBCTOrthoPDF.toJSON(message.CBCTOrthoPDF)
        : undefined);
    message.Mask2D !== undefined &&
      (obj.Mask2D = message.Mask2D
        ? AssetContentGeneratedMask2D.toJSON(message.Mask2D)
        : undefined);
    message.DentalPhotoGPTypePredictions !== undefined &&
      (obj.DentalPhotoGPTypePredictions = message.DentalPhotoGPTypePredictions
        ? AssetContentDentalPhotoGPTypePredictions.toJSON(
            message.DentalPhotoGPTypePredictions,
          )
        : undefined);
    message.CBCTSegmentedModel !== undefined &&
      (obj.CBCTSegmentedModel = message.CBCTSegmentedModel
        ? AssetContentGeneratedCBCTSegmentedModel.toJSON(
            message.CBCTSegmentedModel,
          )
        : undefined);
    message.SuperimpositionModel !== undefined &&
      (obj.SuperimpositionModel = message.SuperimpositionModel
        ? AssetContentGeneratedSuperimpositionModel.toJSON(
            message.SuperimpositionModel,
          )
        : undefined);
    message.CBCTToothPathologySlice !== undefined &&
      (obj.CBCTToothPathologySlice = message.CBCTToothPathologySlice
        ? AssetContentGeneratedCBCTToothPathologySlice.toJSON(
            message.CBCTToothPathologySlice,
          )
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeneratedReportAsset>, I>>(
    object: I,
  ): GeneratedReportAsset {
    const message = createBaseGeneratedReportAsset();
    message.ReportID = object.ReportID ?? '';
    message.SourceStudyIDs = object.SourceStudyIDs?.map((e) => e) || [];
    message.PatientID = object.PatientID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.ToothID = object.ToothID ?? '';
    message.MedicalImageFeatures =
      object.MedicalImageFeatures !== undefined &&
      object.MedicalImageFeatures !== null
        ? MedicalImageFeatures.fromPartial(object.MedicalImageFeatures)
        : undefined;
    message.CBCTGPPanoramaReformatSplit =
      object.CBCTGPPanoramaReformatSplit !== undefined &&
      object.CBCTGPPanoramaReformatSplit !== null
        ? AssetContentGeneratedCBCTGPPanoramaReformatSplit.fromPartial(
            object.CBCTGPPanoramaReformatSplit,
          )
        : undefined;
    message.CBCTGPPanoramaReformatGeneral =
      object.CBCTGPPanoramaReformatGeneral !== undefined &&
      object.CBCTGPPanoramaReformatGeneral !== null
        ? AssetContentGeneratedCBCTGPPanoramaReformatGeneral.fromPartial(
            object.CBCTGPPanoramaReformatGeneral,
          )
        : undefined;
    message.CBCTGPToothSlice =
      object.CBCTGPToothSlice !== undefined && object.CBCTGPToothSlice !== null
        ? AssetContentGeneratedCBCTGPToothSlice.fromPartial(
            object.CBCTGPToothSlice,
          )
        : undefined;
    message.CBCTGPMPRSubVolume =
      object.CBCTGPMPRSubVolume !== undefined &&
      object.CBCTGPMPRSubVolume !== null
        ? AssetContentGeneratedCBCTGPMPRSubVolume.fromPartial(
            object.CBCTGPMPRSubVolume,
          )
        : undefined;
    message.CBCTGPPeriodontiumImage =
      object.CBCTGPPeriodontiumImage !== undefined &&
      object.CBCTGPPeriodontiumImage !== null
        ? AssetContentGeneratedCBCTGPPeriodontiumImage.fromPartial(
            object.CBCTGPPeriodontiumImage,
          )
        : undefined;
    message.CBCTEndoPanoramaSplit =
      object.CBCTEndoPanoramaSplit !== undefined &&
      object.CBCTEndoPanoramaSplit !== null
        ? AssetContentGeneratedCBCTEndoPanoramaSplit.fromPartial(
            object.CBCTEndoPanoramaSplit,
          )
        : undefined;
    message.CBCTEndoCrossSection =
      object.CBCTEndoCrossSection !== undefined &&
      object.CBCTEndoCrossSection !== null
        ? AssetContentGeneratedCBCTEndoCrossSection.fromPartial(
            object.CBCTEndoCrossSection,
          )
        : undefined;
    message.CBCTEndoGuide =
      object.CBCTEndoGuide !== undefined && object.CBCTEndoGuide !== null
        ? AssetContentGeneratedCBCTEndoGuide.fromPartial(object.CBCTEndoGuide)
        : undefined;
    message.CBCTImplantPanorama =
      object.CBCTImplantPanorama !== undefined &&
      object.CBCTImplantPanorama !== null
        ? AssetContentGeneratedCBCTImplantPanorama.fromPartial(
            object.CBCTImplantPanorama,
          )
        : undefined;
    message.CBCTImplantSlice =
      object.CBCTImplantSlice !== undefined && object.CBCTImplantSlice !== null
        ? AssetContentGeneratedCBCTImplantSlice.fromPartial(
            object.CBCTImplantSlice,
          )
        : undefined;
    message.CBCTMolarPanorama =
      object.CBCTMolarPanorama !== undefined &&
      object.CBCTMolarPanorama !== null
        ? AssetContentGeneratedCBCTMolarPanorama.fromPartial(
            object.CBCTMolarPanorama,
          )
        : undefined;
    message.CBCTMolarGuide =
      object.CBCTMolarGuide !== undefined && object.CBCTMolarGuide !== null
        ? AssetContentGeneratedCBCTMolarGuide.fromPartial(object.CBCTMolarGuide)
        : undefined;
    message.CBCTMolarCrossSection =
      object.CBCTMolarCrossSection !== undefined &&
      object.CBCTMolarCrossSection !== null
        ? AssetContentGeneratedCBCTMolarCrossSection.fromPartial(
            object.CBCTMolarCrossSection,
          )
        : undefined;
    message.CBCTOrthoPDF =
      object.CBCTOrthoPDF !== undefined && object.CBCTOrthoPDF !== null
        ? AssetContentGeneratedCBCTOrthoPDF.fromPartial(object.CBCTOrthoPDF)
        : undefined;
    message.Mask2D =
      object.Mask2D !== undefined && object.Mask2D !== null
        ? AssetContentGeneratedMask2D.fromPartial(object.Mask2D)
        : undefined;
    message.DentalPhotoGPTypePredictions =
      object.DentalPhotoGPTypePredictions !== undefined &&
      object.DentalPhotoGPTypePredictions !== null
        ? AssetContentDentalPhotoGPTypePredictions.fromPartial(
            object.DentalPhotoGPTypePredictions,
          )
        : undefined;
    message.CBCTSegmentedModel =
      object.CBCTSegmentedModel !== undefined &&
      object.CBCTSegmentedModel !== null
        ? AssetContentGeneratedCBCTSegmentedModel.fromPartial(
            object.CBCTSegmentedModel,
          )
        : undefined;
    message.SuperimpositionModel =
      object.SuperimpositionModel !== undefined &&
      object.SuperimpositionModel !== null
        ? AssetContentGeneratedSuperimpositionModel.fromPartial(
            object.SuperimpositionModel,
          )
        : undefined;
    message.CBCTToothPathologySlice =
      object.CBCTToothPathologySlice !== undefined &&
      object.CBCTToothPathologySlice !== null
        ? AssetContentGeneratedCBCTToothPathologySlice.fromPartial(
            object.CBCTToothPathologySlice,
          )
        : undefined;
    return message;
  },
};

function createBaseAssetContentStudyCBCT(): AssetContentStudyCBCT {
  return { DICOMFiles: [], CBCTArchive: undefined, Metadata: undefined };
}

export const AssetContentStudyCBCT = {
  encode(
    message: AssetContentStudyCBCT,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.DICOMFiles) {
      File.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.CBCTArchive !== undefined) {
      File.encode(message.CBCTArchive, writer.uint32(18).fork()).ldelim();
    }
    if (message.Metadata !== undefined) {
      AssetContentStudyCBCT_CBCTMetadata.encode(
        message.Metadata,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyCBCT {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyCBCT();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DICOMFiles.push(File.decode(reader, reader.uint32()));
          break;
        case 2:
          message.CBCTArchive = File.decode(reader, reader.uint32());
          break;
        case 3:
          message.Metadata = AssetContentStudyCBCT_CBCTMetadata.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyCBCT {
    return {
      DICOMFiles: Array.isArray(object?.DICOMFiles)
        ? object.DICOMFiles.map((e: any) => File.fromJSON(e))
        : [],
      CBCTArchive: isSet(object.CBCTArchive)
        ? File.fromJSON(object.CBCTArchive)
        : undefined,
      Metadata: isSet(object.Metadata)
        ? AssetContentStudyCBCT_CBCTMetadata.fromJSON(object.Metadata)
        : undefined,
    };
  },

  toJSON(message: AssetContentStudyCBCT): unknown {
    const obj: any = {};
    if (message.DICOMFiles) {
      obj.DICOMFiles = message.DICOMFiles.map((e) =>
        e ? File.toJSON(e) : undefined,
      );
    } else {
      obj.DICOMFiles = [];
    }
    message.CBCTArchive !== undefined &&
      (obj.CBCTArchive = message.CBCTArchive
        ? File.toJSON(message.CBCTArchive)
        : undefined);
    message.Metadata !== undefined &&
      (obj.Metadata = message.Metadata
        ? AssetContentStudyCBCT_CBCTMetadata.toJSON(message.Metadata)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentStudyCBCT>, I>>(
    object: I,
  ): AssetContentStudyCBCT {
    const message = createBaseAssetContentStudyCBCT();
    message.DICOMFiles =
      object.DICOMFiles?.map((e) => File.fromPartial(e)) || [];
    message.CBCTArchive =
      object.CBCTArchive !== undefined && object.CBCTArchive !== null
        ? File.fromPartial(object.CBCTArchive)
        : undefined;
    message.Metadata =
      object.Metadata !== undefined && object.Metadata !== null
        ? AssetContentStudyCBCT_CBCTMetadata.fromPartial(object.Metadata)
        : undefined;
    return message;
  },
};

function createBaseAssetContentStudyCBCT_CBCTMetadata(): AssetContentStudyCBCT_CBCTMetadata {
  return {
    StudyDate: '',
    StudyTime: '',
    InternalStudyUID: '',
    InternalSeriesUID: '',
    InternalInstanceUID: '',
    ScanUID: '',
    Manufacturer: '',
    ManufacturerModelName: '',
    SliceThickness: '',
    FOV: '',
    VoxelSize: '',
    Exposure: '',
  };
}

export const AssetContentStudyCBCT_CBCTMetadata = {
  encode(
    message: AssetContentStudyCBCT_CBCTMetadata,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.StudyDate !== '') {
      writer.uint32(10).string(message.StudyDate);
    }
    if (message.StudyTime !== '') {
      writer.uint32(18).string(message.StudyTime);
    }
    if (message.InternalStudyUID !== '') {
      writer.uint32(26).string(message.InternalStudyUID);
    }
    if (message.InternalSeriesUID !== '') {
      writer.uint32(34).string(message.InternalSeriesUID);
    }
    if (message.InternalInstanceUID !== '') {
      writer.uint32(42).string(message.InternalInstanceUID);
    }
    if (message.ScanUID !== '') {
      writer.uint32(50).string(message.ScanUID);
    }
    if (message.Manufacturer !== '') {
      writer.uint32(58).string(message.Manufacturer);
    }
    if (message.ManufacturerModelName !== '') {
      writer.uint32(66).string(message.ManufacturerModelName);
    }
    if (message.SliceThickness !== '') {
      writer.uint32(74).string(message.SliceThickness);
    }
    if (message.FOV !== '') {
      writer.uint32(82).string(message.FOV);
    }
    if (message.VoxelSize !== '') {
      writer.uint32(90).string(message.VoxelSize);
    }
    if (message.Exposure !== '') {
      writer.uint32(98).string(message.Exposure);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyCBCT_CBCTMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyCBCT_CBCTMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.StudyDate = reader.string();
          break;
        case 2:
          message.StudyTime = reader.string();
          break;
        case 3:
          message.InternalStudyUID = reader.string();
          break;
        case 4:
          message.InternalSeriesUID = reader.string();
          break;
        case 5:
          message.InternalInstanceUID = reader.string();
          break;
        case 6:
          message.ScanUID = reader.string();
          break;
        case 7:
          message.Manufacturer = reader.string();
          break;
        case 8:
          message.ManufacturerModelName = reader.string();
          break;
        case 9:
          message.SliceThickness = reader.string();
          break;
        case 10:
          message.FOV = reader.string();
          break;
        case 11:
          message.VoxelSize = reader.string();
          break;
        case 12:
          message.Exposure = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyCBCT_CBCTMetadata {
    return {
      StudyDate: isSet(object.StudyDate) ? String(object.StudyDate) : '',
      StudyTime: isSet(object.StudyTime) ? String(object.StudyTime) : '',
      InternalStudyUID: isSet(object.InternalStudyUID)
        ? String(object.InternalStudyUID)
        : '',
      InternalSeriesUID: isSet(object.InternalSeriesUID)
        ? String(object.InternalSeriesUID)
        : '',
      InternalInstanceUID: isSet(object.InternalInstanceUID)
        ? String(object.InternalInstanceUID)
        : '',
      ScanUID: isSet(object.ScanUID) ? String(object.ScanUID) : '',
      Manufacturer: isSet(object.Manufacturer)
        ? String(object.Manufacturer)
        : '',
      ManufacturerModelName: isSet(object.ManufacturerModelName)
        ? String(object.ManufacturerModelName)
        : '',
      SliceThickness: isSet(object.SliceThickness)
        ? String(object.SliceThickness)
        : '',
      FOV: isSet(object.FOV) ? String(object.FOV) : '',
      VoxelSize: isSet(object.VoxelSize) ? String(object.VoxelSize) : '',
      Exposure: isSet(object.Exposure) ? String(object.Exposure) : '',
    };
  },

  toJSON(message: AssetContentStudyCBCT_CBCTMetadata): unknown {
    const obj: any = {};
    message.StudyDate !== undefined && (obj.StudyDate = message.StudyDate);
    message.StudyTime !== undefined && (obj.StudyTime = message.StudyTime);
    message.InternalStudyUID !== undefined &&
      (obj.InternalStudyUID = message.InternalStudyUID);
    message.InternalSeriesUID !== undefined &&
      (obj.InternalSeriesUID = message.InternalSeriesUID);
    message.InternalInstanceUID !== undefined &&
      (obj.InternalInstanceUID = message.InternalInstanceUID);
    message.ScanUID !== undefined && (obj.ScanUID = message.ScanUID);
    message.Manufacturer !== undefined &&
      (obj.Manufacturer = message.Manufacturer);
    message.ManufacturerModelName !== undefined &&
      (obj.ManufacturerModelName = message.ManufacturerModelName);
    message.SliceThickness !== undefined &&
      (obj.SliceThickness = message.SliceThickness);
    message.FOV !== undefined && (obj.FOV = message.FOV);
    message.VoxelSize !== undefined && (obj.VoxelSize = message.VoxelSize);
    message.Exposure !== undefined && (obj.Exposure = message.Exposure);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentStudyCBCT_CBCTMetadata>, I>,
  >(object: I): AssetContentStudyCBCT_CBCTMetadata {
    const message = createBaseAssetContentStudyCBCT_CBCTMetadata();
    message.StudyDate = object.StudyDate ?? '';
    message.StudyTime = object.StudyTime ?? '';
    message.InternalStudyUID = object.InternalStudyUID ?? '';
    message.InternalSeriesUID = object.InternalSeriesUID ?? '';
    message.InternalInstanceUID = object.InternalInstanceUID ?? '';
    message.ScanUID = object.ScanUID ?? '';
    message.Manufacturer = object.Manufacturer ?? '';
    message.ManufacturerModelName = object.ManufacturerModelName ?? '';
    message.SliceThickness = object.SliceThickness ?? '';
    message.FOV = object.FOV ?? '';
    message.VoxelSize = object.VoxelSize ?? '';
    message.Exposure = object.Exposure ?? '';
    return message;
  },
};

function createBaseAssetContentStudyIOSSTL(): AssetContentStudyIOSSTL {
  return {
    UpperJawModel: undefined,
    LowerJawModel: undefined,
    Derived: undefined,
  };
}

export const AssetContentStudyIOSSTL = {
  encode(
    message: AssetContentStudyIOSSTL,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UpperJawModel !== undefined) {
      File.encode(message.UpperJawModel, writer.uint32(10).fork()).ldelim();
    }
    if (message.LowerJawModel !== undefined) {
      File.encode(message.LowerJawModel, writer.uint32(18).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedData3DModel.encode(
        message.Derived,
        writer.uint32(802).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyIOSSTL {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyIOSSTL();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UpperJawModel = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.LowerJawModel = File.decode(reader, reader.uint32());
          break;
        case 100:
          message.Derived = DerivedData3DModel.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyIOSSTL {
    return {
      UpperJawModel: isSet(object.UpperJawModel)
        ? File.fromJSON(object.UpperJawModel)
        : undefined,
      LowerJawModel: isSet(object.LowerJawModel)
        ? File.fromJSON(object.LowerJawModel)
        : undefined,
      Derived: isSet(object.Derived)
        ? DerivedData3DModel.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentStudyIOSSTL): unknown {
    const obj: any = {};
    message.UpperJawModel !== undefined &&
      (obj.UpperJawModel = message.UpperJawModel
        ? File.toJSON(message.UpperJawModel)
        : undefined);
    message.LowerJawModel !== undefined &&
      (obj.LowerJawModel = message.LowerJawModel
        ? File.toJSON(message.LowerJawModel)
        : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedData3DModel.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentStudyIOSSTL>, I>>(
    object: I,
  ): AssetContentStudyIOSSTL {
    const message = createBaseAssetContentStudyIOSSTL();
    message.UpperJawModel =
      object.UpperJawModel !== undefined && object.UpperJawModel !== null
        ? File.fromPartial(object.UpperJawModel)
        : undefined;
    message.LowerJawModel =
      object.LowerJawModel !== undefined && object.LowerJawModel !== null
        ? File.fromPartial(object.LowerJawModel)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedData3DModel.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentStudyPanoramicXRay(): AssetContentStudyPanoramicXRay {
  return { PanoramaImage: undefined, Derived: undefined };
}

export const AssetContentStudyPanoramicXRay = {
  encode(
    message: AssetContentStudyPanoramicXRay,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PanoramaImage !== undefined) {
      File.encode(message.PanoramaImage, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(802).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyPanoramicXRay {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyPanoramicXRay();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PanoramaImage = File.decode(reader, reader.uint32());
          break;
        case 100:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyPanoramicXRay {
    return {
      PanoramaImage: isSet(object.PanoramaImage)
        ? File.fromJSON(object.PanoramaImage)
        : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentStudyPanoramicXRay): unknown {
    const obj: any = {};
    message.PanoramaImage !== undefined &&
      (obj.PanoramaImage = message.PanoramaImage
        ? File.toJSON(message.PanoramaImage)
        : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentStudyPanoramicXRay>, I>>(
    object: I,
  ): AssetContentStudyPanoramicXRay {
    const message = createBaseAssetContentStudyPanoramicXRay();
    message.PanoramaImage =
      object.PanoramaImage !== undefined && object.PanoramaImage !== null
        ? File.fromPartial(object.PanoramaImage)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentStudyIntraoralXRay(): AssetContentStudyIntraoralXRay {
  return { Images: [] };
}

export const AssetContentStudyIntraoralXRay = {
  encode(
    message: AssetContentStudyIntraoralXRay,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Images) {
      AssetContentStudyIntraoralXRay_XRayImage.encode(
        v!,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyIntraoralXRay {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyIntraoralXRay();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Images.push(
            AssetContentStudyIntraoralXRay_XRayImage.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyIntraoralXRay {
    return {
      Images: Array.isArray(object?.Images)
        ? object.Images.map((e: any) =>
            AssetContentStudyIntraoralXRay_XRayImage.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: AssetContentStudyIntraoralXRay): unknown {
    const obj: any = {};
    if (message.Images) {
      obj.Images = message.Images.map((e) =>
        e ? AssetContentStudyIntraoralXRay_XRayImage.toJSON(e) : undefined,
      );
    } else {
      obj.Images = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentStudyIntraoralXRay>, I>>(
    object: I,
  ): AssetContentStudyIntraoralXRay {
    const message = createBaseAssetContentStudyIntraoralXRay();
    message.Images =
      object.Images?.map((e) =>
        AssetContentStudyIntraoralXRay_XRayImage.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseAssetContentStudyIntraoralXRay_XRayImage(): AssetContentStudyIntraoralXRay_XRayImage {
  return { Image: undefined, Derived: undefined };
}

export const AssetContentStudyIntraoralXRay_XRayImage = {
  encode(
    message: AssetContentStudyIntraoralXRay_XRayImage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Image !== undefined) {
      File.encode(message.Image, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyIntraoralXRay_XRayImage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyIntraoralXRay_XRayImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Image = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyIntraoralXRay_XRayImage {
    return {
      Image: isSet(object.Image) ? File.fromJSON(object.Image) : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentStudyIntraoralXRay_XRayImage): unknown {
    const obj: any = {};
    message.Image !== undefined &&
      (obj.Image = message.Image ? File.toJSON(message.Image) : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentStudyIntraoralXRay_XRayImage>, I>,
  >(object: I): AssetContentStudyIntraoralXRay_XRayImage {
    const message = createBaseAssetContentStudyIntraoralXRay_XRayImage();
    message.Image =
      object.Image !== undefined && object.Image !== null
        ? File.fromPartial(object.Image)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentStudyDentalPhoto(): AssetContentStudyDentalPhoto {
  return { Images: [] };
}

export const AssetContentStudyDentalPhoto = {
  encode(
    message: AssetContentStudyDentalPhoto,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Images) {
      AssetContentSimpleImage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyDentalPhoto {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyDentalPhoto();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Images.push(
            AssetContentSimpleImage.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyDentalPhoto {
    return {
      Images: Array.isArray(object?.Images)
        ? object.Images.map((e: any) => AssetContentSimpleImage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AssetContentStudyDentalPhoto): unknown {
    const obj: any = {};
    if (message.Images) {
      obj.Images = message.Images.map((e) =>
        e ? AssetContentSimpleImage.toJSON(e) : undefined,
      );
    } else {
      obj.Images = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentStudyDentalPhoto>, I>>(
    object: I,
  ): AssetContentStudyDentalPhoto {
    const message = createBaseAssetContentStudyDentalPhoto();
    message.Images =
      object.Images?.map((e) => AssetContentSimpleImage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTToothPathologySlice(): AssetContentGeneratedCBCTToothPathologySlice {
  return {
    Slice: undefined,
    Orientation: 0,
    PredictedISONumber: 0,
    Pathology: 0,
    PathologyComponent: 0,
    MaskAssetID: '',
  };
}

export const AssetContentGeneratedCBCTToothPathologySlice = {
  encode(
    message: AssetContentGeneratedCBCTToothPathologySlice,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Slice !== undefined) {
      File.encode(message.Slice, writer.uint32(10).fork()).ldelim();
    }
    if (message.Orientation !== 0) {
      writer.uint32(16).int32(message.Orientation);
    }
    if (message.PredictedISONumber !== 0) {
      writer.uint32(24).uint32(message.PredictedISONumber);
    }
    if (message.Pathology !== 0) {
      writer.uint32(32).int32(message.Pathology);
    }
    if (message.PathologyComponent !== 0) {
      writer.uint32(40).uint32(message.PathologyComponent);
    }
    if (message.MaskAssetID !== '') {
      writer.uint32(50).string(message.MaskAssetID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTToothPathologySlice {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTToothPathologySlice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Slice = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Orientation = reader.int32() as any;
          break;
        case 3:
          message.PredictedISONumber = reader.uint32();
          break;
        case 4:
          message.Pathology = reader.int32() as any;
          break;
        case 5:
          message.PathologyComponent = reader.uint32();
          break;
        case 6:
          message.MaskAssetID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTToothPathologySlice {
    return {
      Slice: isSet(object.Slice) ? File.fromJSON(object.Slice) : undefined,
      Orientation: isSet(object.Orientation)
        ? cBCTSectionOrientationFromJSON(object.Orientation)
        : 0,
      PredictedISONumber: isSet(object.PredictedISONumber)
        ? Number(object.PredictedISONumber)
        : 0,
      Pathology: isSet(object.Pathology)
        ? assetContentGeneratedCBCTToothPathologySlice_PathologyTypeFromJSON(
            object.Pathology,
          )
        : 0,
      PathologyComponent: isSet(object.PathologyComponent)
        ? Number(object.PathologyComponent)
        : 0,
      MaskAssetID: isSet(object.MaskAssetID) ? String(object.MaskAssetID) : '',
    };
  },

  toJSON(message: AssetContentGeneratedCBCTToothPathologySlice): unknown {
    const obj: any = {};
    message.Slice !== undefined &&
      (obj.Slice = message.Slice ? File.toJSON(message.Slice) : undefined);
    message.Orientation !== undefined &&
      (obj.Orientation = cBCTSectionOrientationToJSON(message.Orientation));
    message.PredictedISONumber !== undefined &&
      (obj.PredictedISONumber = Math.round(message.PredictedISONumber));
    message.Pathology !== undefined &&
      (obj.Pathology =
        assetContentGeneratedCBCTToothPathologySlice_PathologyTypeToJSON(
          message.Pathology,
        ));
    message.PathologyComponent !== undefined &&
      (obj.PathologyComponent = Math.round(message.PathologyComponent));
    message.MaskAssetID !== undefined &&
      (obj.MaskAssetID = message.MaskAssetID);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedCBCTToothPathologySlice>,
      I
    >,
  >(object: I): AssetContentGeneratedCBCTToothPathologySlice {
    const message = createBaseAssetContentGeneratedCBCTToothPathologySlice();
    message.Slice =
      object.Slice !== undefined && object.Slice !== null
        ? File.fromPartial(object.Slice)
        : undefined;
    message.Orientation = object.Orientation ?? 0;
    message.PredictedISONumber = object.PredictedISONumber ?? 0;
    message.Pathology = object.Pathology ?? 0;
    message.PathologyComponent = object.PathologyComponent ?? 0;
    message.MaskAssetID = object.MaskAssetID ?? '';
    return message;
  },
};

function createBaseAssetContentSimpleImage(): AssetContentSimpleImage {
  return { Original: undefined, Derived: undefined };
}

export const AssetContentSimpleImage = {
  encode(
    message: AssetContentSimpleImage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Original !== undefined) {
      File.encode(message.Original, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentSimpleImage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentSimpleImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Original = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentSimpleImage {
    return {
      Original: isSet(object.Original)
        ? File.fromJSON(object.Original)
        : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentSimpleImage): unknown {
    const obj: any = {};
    message.Original !== undefined &&
      (obj.Original = message.Original
        ? File.toJSON(message.Original)
        : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentSimpleImage>, I>>(
    object: I,
  ): AssetContentSimpleImage {
    const message = createBaseAssetContentSimpleImage();
    message.Original =
      object.Original !== undefined && object.Original !== null
        ? File.fromPartial(object.Original)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTOrthoPDF(): AssetContentGeneratedCBCTOrthoPDF {
  return { PDF: undefined };
}

export const AssetContentGeneratedCBCTOrthoPDF = {
  encode(
    message: AssetContentGeneratedCBCTOrthoPDF,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PDF !== undefined) {
      File.encode(message.PDF, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTOrthoPDF {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTOrthoPDF();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PDF = File.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTOrthoPDF {
    return {
      PDF: isSet(object.PDF) ? File.fromJSON(object.PDF) : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTOrthoPDF): unknown {
    const obj: any = {};
    message.PDF !== undefined &&
      (obj.PDF = message.PDF ? File.toJSON(message.PDF) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTOrthoPDF>, I>,
  >(object: I): AssetContentGeneratedCBCTOrthoPDF {
    const message = createBaseAssetContentGeneratedCBCTOrthoPDF();
    message.PDF =
      object.PDF !== undefined && object.PDF !== null
        ? File.fromPartial(object.PDF)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedMask2D(): AssetContentGeneratedMask2D {
  return { Mask: undefined, TargetAssetID: '', TargetFileID: '' };
}

export const AssetContentGeneratedMask2D = {
  encode(
    message: AssetContentGeneratedMask2D,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Mask !== undefined) {
      File.encode(message.Mask, writer.uint32(10).fork()).ldelim();
    }
    if (message.TargetAssetID !== '') {
      writer.uint32(18).string(message.TargetAssetID);
    }
    if (message.TargetFileID !== '') {
      writer.uint32(26).string(message.TargetFileID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedMask2D {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedMask2D();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Mask = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.TargetAssetID = reader.string();
          break;
        case 3:
          message.TargetFileID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedMask2D {
    return {
      Mask: isSet(object.Mask) ? File.fromJSON(object.Mask) : undefined,
      TargetAssetID: isSet(object.TargetAssetID)
        ? String(object.TargetAssetID)
        : '',
      TargetFileID: isSet(object.TargetFileID)
        ? String(object.TargetFileID)
        : '',
    };
  },

  toJSON(message: AssetContentGeneratedMask2D): unknown {
    const obj: any = {};
    message.Mask !== undefined &&
      (obj.Mask = message.Mask ? File.toJSON(message.Mask) : undefined);
    message.TargetAssetID !== undefined &&
      (obj.TargetAssetID = message.TargetAssetID);
    message.TargetFileID !== undefined &&
      (obj.TargetFileID = message.TargetFileID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentGeneratedMask2D>, I>>(
    object: I,
  ): AssetContentGeneratedMask2D {
    const message = createBaseAssetContentGeneratedMask2D();
    message.Mask =
      object.Mask !== undefined && object.Mask !== null
        ? File.fromPartial(object.Mask)
        : undefined;
    message.TargetAssetID = object.TargetAssetID ?? '';
    message.TargetFileID = object.TargetFileID ?? '';
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
