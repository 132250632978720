import { defineMessages } from 'react-intl';

import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

export const reportType = defineMessages<ReportType>({
  [ReportType.ReportType_InvalidValue]: {
    id: 'ReportType.ReportType_InvalidValue',
    defaultMessage: 'ReportType_InvalidValue',
  },
  // Radiological
  [ReportType.ReportType_CBCT_GP]: {
    id: 'ReportType.ReportType_CBCT_GP',
    defaultMessage: 'Radiological report',
  },
  [ReportType.ReportType_CBCT_Segmentation]: {
    id: 'ReportType.ReportType_CBCT_Segmentation',
    defaultMessage: 'ReportType_CBCT_Segmentation',
  },
  [ReportType.ReportType_CBCT_OrthoSlides]: {
    id: 'ReportType.ReportType_CBCT_OrthoSlides',
    defaultMessage: 'Orthodontic reports',
  },
  [ReportType.ReportType_CBCT_Implant]: {
    id: 'ReportType.ReportType_CBCT_Implant',
    defaultMessage: 'Implantology Study',
  },
  [ReportType.ReportType_CBCT_Endo]: {
    id: 'ReportType.ReportType_CBCT_Endo',
    defaultMessage: 'Endodontic Study',
  },
  [ReportType.ReportType_CBCT_Molar]: {
    id: 'ReportType.ReportType_CBCT_Molar',
    defaultMessage: 'Third Molar Study',
  },
  [ReportType.ReportType_Pano_GP]: {
    id: 'ReportType.ReportType_Pano_GP',
    defaultMessage: 'ReportType_Pano_GP',
  },
  // FMX
  [ReportType.ReportType_IOXRay_GP]: {
    id: 'ReportType.ReportType_IOXRay_GP',
    defaultMessage: 'ReportType_IOXRay_GP',
  },
  [ReportType.ReportType_DentalPhoto_GP]: {
    id: 'ReportType.ReportType_DentalPhoto_GP',
    defaultMessage: 'ReportType_DentalPhoto_GP',
  },
  [ReportType.ReportType_IOS_Segmentation]: {
    id: 'ReportType.ReportType_IOS_Segmentation',
    defaultMessage: 'ReportType_IOS_Segmentation',
  },
  [ReportType.ReportType_CBCT_IOS_Superimposition]: {
    id: 'ReportType.ReportType_CBCT_IOS_Superimposition',
    defaultMessage: 'ReportType_CBCT_IOS_Superimposition',
  },
  [ReportType.ReportType_ImplantStudio]: {
    id: 'ReportType.ReportType_ImplantStudio',
    defaultMessage: 'ReportType_ImplantStudio',
  },
  [ReportType.ReportType_Photo_Ortho]: {
    id: 'ReportType.ReportType_Photo_Ortho',
    defaultMessage: 'ReportType_Photo_Ortho',
  },
  [ReportType.UNRECOGNIZED]: {
    id: 'ReportType.UNRECOGNIZED',
    defaultMessage: 'UNRECOGNIZED',
  },
});
