import { createSelector } from '@reduxjs/toolkit';
import { filter, propEq } from 'ramda';

import { Study, StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import { RootState } from '@/app/model/store';

import { studyAdapter } from './studySlice';

export const studySelectors = studyAdapter.getSelectors(
  (state: RootState) => state.study,
);

export const { selectIds, selectEntities, selectTotal, selectById, selectAll } =
  studySelectors;

export const selectByType = createSelector(
  studySelectors.selectAll,
  (state: RootState, studyType: StudyType) => studyType,
  (studies, studyType) =>
    filter(propEq<keyof Study>('Type', studyType))(studies),
);
