// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddConditionModal-module__container--Ml7WLUch{display:flex}.AddConditionModal-module__tabContent--O-X7wO2l{display:flex;flex-direction:column;gap:16px;overflow:auto;max-height:calc(100vh - 293px)}@media screen and (orientation: landscape)and (max-width: 768px){.AddConditionModal-module__tabContent--O-X7wO2l{max-height:35vh}}.AddConditionModal-module__modalContainer--W5HvKp56{margin-top:0}.AddConditionModal-module__modalBody--Jcr8\\+0po{padding-top:0}.AddConditionModal-module__childGroup--sJ49ZKJ7{justify-content:flex-start}", "",{"version":3,"sources":["webpack://./src/widgets/AddConditionModal/ui/AddConditionModal.module.scss"],"names":[],"mappings":"AAIA,+CACE,YAAA,CAGF,gDACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,aAAA,CACA,8BAAA,CAAA,iEAIA,gDAEI,eAAA,CAAA,CAKN,oDACE,YAAA,CAGF,gDACE,aAAA,CAGF,gDACE,0BAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n$bottomPaddingForList: 293px;\n\n.container {\n  display: flex;\n}\n\n.tabContent {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  overflow: auto;\n  max-height: calc(100vh - $bottomPaddingForList);\n}\n\n@media (orientation: landscape) {\n  .tabContent {\n    @include tablet {\n      max-height: 35vh;\n    }\n  }\n}\n\n.modalContainer {\n  margin-top: 0;\n}\n\n.modalBody {\n  padding-top: 0;\n}\n\n.childGroup {\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AddConditionModal-module__container--Ml7WLUch",
	"tabContent": "AddConditionModal-module__tabContent--O-X7wO2l",
	"modalContainer": "AddConditionModal-module__modalContainer--W5HvKp56",
	"modalBody": "AddConditionModal-module__modalBody--Jcr8+0po",
	"childGroup": "AddConditionModal-module__childGroup--sJ49ZKJ7"
};
export default ___CSS_LOADER_EXPORT___;
