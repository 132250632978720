import { Condition } from '@/shared/api/protocol_gen/model/dto_report_core';

export const getConditionModelScorePercentage = (
  condition: Condition,
): string | null => {
  if (condition?.Certainty?.IsModelScoreSet) {
    const score = condition?.Certainty?.ModelScore;

    return score <= 0.01 ? '~0%' : `${Math.round(score * 100)}%`;
  }

  return null;
};
