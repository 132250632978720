import React, { ReactNode } from 'react';
import cn from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { useMedia } from '@/shared/hooks';

import styles from './Filters.module.scss';

type FilterItem<FilterValue> = {
  value: FilterValue;
  label: ReactNode;
  amount?: number;
};

type FiltersProps<FilterValue> = {
  className?: string;
  filterName: string;
  defaultActiveKey?: FilterValue;
  items: FilterItem<FilterValue>[];
  onChange: (filterKey: FilterValue) => void;
};

export const Filters = <K extends string>(props: FiltersProps<K>) => {
  const { className, filterName, items, defaultActiveKey, onChange } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const { isPhone } = useMedia();

  const activeKey = (searchParams.get(filterName) || defaultActiveKey) as K;

  const handleFiltersChange = (value: K) => {
    searchParams.set(filterName, value);
    setSearchParams(searchParams);
    onChange(value);
  };

  return (
    <div className={cn(styles.container, className)}>
      {items.map((item) => {
        const isItemActive = activeKey === item.value;

        return (
          <button
            key={item.value}
            type="button"
            onClick={() => handleFiltersChange(item.value)}
            className={cn(
              styles.item,
              isItemActive && styles.isSelected,
              isPhone ? 'p2' : 'p1',
            )}
          >
            {item.label}
            {item.amount !== undefined && (
              <span
                className={cn(
                  styles.amount,
                  isItemActive && styles.amountActive,
                )}
              >
                {item.amount}
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
};
