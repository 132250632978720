import { ToothImage } from '../config/slices.types';

export const sortSlicesByOrder = (imagesList: ToothImage[]) =>
  imagesList.sort((a, b) => {
    if (a.order === null) {
      return -1;
    }
    if (b.order === null) {
      return 1;
    }

    return a.order - b.order;
  });
