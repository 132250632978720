import React, { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Icon, toastCaller } from '@/shared/ui';

import styles from './ScanUidCopy.module.scss';

type ScanUidCopyProps = {
  scanUid: string;
  className?: string;
};

export const ScanUidCopy: FC<ScanUidCopyProps> = (props) => {
  const { scanUid, className } = props;

  const intl = useIntl();

  const [copied, setCopied] = useState(false);

  const isScanUid = Boolean(scanUid);

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);

      setCopied(true);

      toastCaller({
        message: intl.formatMessage({
          id: 'global.linkCopied',
          defaultMessage: 'Link copied',
        }),
        type: 'default',
        heading: '',
        position: 'bottom-right',
      });
    } catch (err) {
      setCopied(false);
    }
  };

  return (
    <div className={cn(className, styles.container)}>
      <p className="p3">
        <span className={styles.title}>
          <FormattedMessage id="studyInfo.scanUID" defaultMessage="Scan UID" />
          {`: `}
        </span>
        <span className={styles.scanUid}>
          {isScanUid ? (
            ` ${scanUid}`
          ) : (
            <FormattedMessage
              id="global.notAvailable "
              defaultMessage="not available"
            />
          )}
        </span>
      </p>
      {isScanUid && (
        <Button
          size="small"
          variant="tertiary"
          onClick={() => copyToClipBoard(scanUid)}
        >
          <div className={styles.button}>
            <Icon name={copied ? 'check' : 'copy'} size={copied ? 32 : 24} />
            <h5 className="h5">
              {copied ? (
                <FormattedMessage id="global.copied" defaultMessage="Copied" />
              ) : (
                <FormattedMessage id="global.copy" defaultMessage="Copy" />
              )}
            </h5>
          </div>
        </Button>
      )}
    </div>
  );
};
