import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { Button, Checkbox, RadioGroup, WidgetCard } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { reportsModel } from '@/entities/reports';

import {
  PreviewPrintType,
  PreviewSettings,
  PreviewSettingsHandlers,
} from '../../hooks/usePreviewSettings';

import styles from './SettingsBar.module.scss';

type SettingsBarProps = {
  className?: string;
  settings: PreviewSettings;
  handlers: PreviewSettingsHandlers;
};

export const SettingsBar: FC<SettingsBarProps> = (props) => {
  const { className, settings, handlers } = props;

  const { reportID } = useParams();

  const report = useAppSelector((state) =>
    reportsModel.selectors.selectById(state, reportID),
  );
  const isReportSigned = report?.Signature?.Signed;

  const {
    printType,
    inverted,
    showToothChart,
    showUpperJaw,
    showLowerJaw,
    showProbability,
    showConditions,
    showSlices,
  } = settings;

  const {
    changePrintType,
    toggleInvert,
    toggleToothChart,
    toggleUpperJaw,
    toggleLowerJaw,
    toggleProbability,
    toggleConditions,
    toggleSlices,
  } = handlers;

  return (
    <WidgetCard className={cn(styles.container, className)}>
      {/* TODO: Make it real
      <Select
        label="TODO: select clinic"
        placeholder="TODO: select clinic"
        onChange={() => {}}
        value={MOCKED_OPTIONS[0].value}
        options={MOCKED_OPTIONS}
      />

      <Select
        label="TODO: select preset"
        placeholder="TODO: select preset"
        onChange={() => {}}
        value={MOCKED_PRESET[0].value}
        options={MOCKED_PRESET}
      />
      */}

      <div className={styles.checkboxContainer}>
        <Checkbox checked={inverted} onChange={toggleInvert}>
          <FormattedMessage
            id="reportPreview.settings.inverted"
            defaultMessage="Inverted"
          />
        </Checkbox>

        <Checkbox checked={showProbability} onChange={toggleProbability}>
          <FormattedMessage
            id="reportPreview.settings.probability"
            defaultMessage="Probability"
          />
        </Checkbox>

        <Checkbox checked={showToothChart} onChange={toggleToothChart}>
          <FormattedMessage
            id="reportPreview.settings.dentalChart"
            defaultMessage="Dental chart"
          />
        </Checkbox>

        <Checkbox
          className={styles.nestedCheckbox}
          checked={showUpperJaw}
          onChange={toggleUpperJaw}
        >
          <FormattedMessage
            id="reportPreview.settings.upperJaw"
            defaultMessage="Upper jaw"
          />
        </Checkbox>

        <Checkbox
          className={styles.nestedCheckbox}
          checked={showLowerJaw}
          onChange={toggleLowerJaw}
        >
          <FormattedMessage
            id="reportPreview.settings.lowerJaw"
            defaultMessage="Lower jaw"
          />
        </Checkbox>

        <Checkbox checked={showConditions} onChange={toggleConditions}>
          <FormattedMessage
            id="reportPreview.settings.conditions"
            defaultMessage="Conditions"
          />
        </Checkbox>

        <Checkbox checked={showSlices} onChange={toggleSlices}>
          <FormattedMessage
            id="reportPreview.settings.slices"
            defaultMessage="Slices"
          />
        </Checkbox>

        {/* TODO: Make them real
        <Checkbox checked={false} onChange={() => {}}>
          <FormattedMessage
            id="reportPreview.settings.recomendations"
            defaultMessage="Refferal reccomendations"
          />
        </Checkbox>

        <Checkbox checked={false} onChange={() => {}}>
          <FormattedMessage
            id="reportPreview.settings.orthoScreening"
            defaultMessage="Orthodontic screening"
          />
        </Checkbox>

        <Checkbox checked={false} onChange={() => {}}>
          <FormattedMessage
            id="reportPreview.settings.studyInfo"
            defaultMessage="Study meta info"
          />
        </Checkbox>
      */}
      </div>

      <h3>
        <FormattedMessage
          id="reportPreview.settings.printType"
          defaultMessage="Print type"
        />
      </h3>

      <RadioGroup
        name="print-type"
        value={printType}
        onChange={changePrintType}
        items={
          [
            {
              value: 'black-and-white',
              label: 'Black/White',
            },
            {
              value: 'colored',
              label: 'Colored',
            },
          ] as { value: PreviewPrintType; label: string }[]
        }
      />

      <div className={styles.status}>
        <p className={styles.notSigned}>
          {isReportSigned ? (
            <FormattedMessage
              id="reportPreview.settings.signed"
              defaultMessage="Signed by doctor"
            />
          ) : (
            <FormattedMessage
              id="reportPreview.settings.notSigned"
              defaultMessage="Not signed by doctor"
            />
          )}
        </p>

        {!isReportSigned && (
          <p>
            <FormattedMessage
              id="reportPreview.settings.disclaimer"
              defaultMessage="If you want the Conclusion to be displayed in printed version or PDF, it is necessary to sign the report at the previous step"
            />
          </p>
        )}
      </div>

      <Button onClick={() => {}}>
        <FormattedMessage id="global.print" defaultMessage="Print" />
      </Button>

      <Button variant="tertiary" onClick={() => {}}>
        <FormattedMessage
          id="reportPreview.settings.downloadReport"
          defaultMessage="Download report"
        />
      </Button>
    </WidgetCard>
  );
};
