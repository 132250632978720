import { SelectProps } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { getUserFullName } from '@/entities/users';

import { selectOrganizationUsers } from '../model/selectors';

export const useOrganizationUsersOptions = (): SelectProps['options'] => {
  const users = useAppSelector(selectOrganizationUsers) || [];

  return users.map((user) => ({
    label: getUserFullName(user),
    value: user?.ID,
  }));
};
