import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';

import { useAppDispatch } from '@/shared/hooks';
import api from '@/shared/api/api';
import { reportsModel } from '@/entities/reports';
import { patientModel } from '@/entities/patient';
import { toothModel } from '@/entities/tooth';
import { studyModel } from '@/entities/study';
import { conditionModel } from '@/entities/condition';
import { StreamDataAccumulatorKey } from '@/shared/config';
import { Study } from '@/shared/api/protocol_gen/model/dto_study';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { assetsModel } from '@/entities/assets';
import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';

type ReportDataStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: Study[];
  [StreamDataAccumulatorKey.conditions]: Condition[];
  [StreamDataAccumulatorKey.teeth]: Tooth[];
  [StreamDataAccumulatorKey.assets]: Asset[];
};

const dataAccumulators: ReportDataStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: [],
  [StreamDataAccumulatorKey.conditions]: [],
  [StreamDataAccumulatorKey.teeth]: [],
  [StreamDataAccumulatorKey.assets]: [],
};

export const useReportDataStream = (reportID: string) => {
  const dispatch = useAppDispatch();

  const reportDataStream = useRef<Subscription>(undefined);

  const openReportDataStream = () => {
    dispatch(patientModel.actions.setLoading('pending'));
    dispatch(studyModel.actions.setLoading('pending'));
    dispatch(assetsModel.actions.setLoading('pending'));

    reportDataStream.current = api.report
      .ReportDataStream({ ReportID: reportID })
      .subscribe({
        next: (data) => {
          if (data.HistoricalPatient) {
            dispatch(patientModel.actions.addOne(data.HistoricalPatient));
            dispatch(patientModel.actions.setLoading('succeeded'));
          }

          if (data.UpdatedPatient) {
            dispatch(patientModel.actions.setNewestOne(data.UpdatedPatient));
          }

          if (data.HistoricalStudy) {
            dataAccumulators[StreamDataAccumulatorKey.study].push(
              data.HistoricalStudy,
            );
          }

          if (data.UpdatedStudy) {
            dispatch(studyModel.actions.setNewestOne(data.UpdatedStudy));
          }

          if (data.EndOfHistoricalStudies) {
            dispatch(
              studyModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.study],
              ),
            );

            dispatch(studyModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.study] = [];
          }

          if (data.HistoricalReport) {
            dispatch(reportsModel.actions.addOne(data.HistoricalReport));
            dispatch(reportsModel.actions.setLoading('succeeded'));
          }

          if (data.UpdatedReport) {
            dispatch(reportsModel.actions.setNewestOne(data.UpdatedReport));
          }

          if (data.HistoricalTooth) {
            dataAccumulators[StreamDataAccumulatorKey.teeth].push(
              data.HistoricalTooth,
            );
          }

          if (data.UpdatedTooth) {
            dispatch(toothModel.actions.setNewestOne(data.UpdatedTooth));
          }

          if (data.EndOfHistoricalTeeth) {
            dispatch(
              toothModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.teeth],
              ),
            );

            dataAccumulators[StreamDataAccumulatorKey.teeth] = [];
          }

          if (data.HistoricalCondition) {
            dataAccumulators[StreamDataAccumulatorKey.conditions].push(
              data.HistoricalCondition,
            );
          }

          if (data.UpdatedCondition) {
            dispatch(
              conditionModel.actions.setNewestOne(data.UpdatedCondition),
            );
          }
          if (data.HistoricalAsset) {
            dataAccumulators[StreamDataAccumulatorKey.assets].push(
              data.HistoricalAsset,
            );
          }

          if (data.EndOfHistoricalAssets) {
            dispatch(
              assetsModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.assets],
              ),
            );
            dispatch(assetsModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.assets] = [];
          }

          if (data.EndOfHistoricalConditions) {
            dispatch(
              conditionModel.actions.setMany(
                dataAccumulators[StreamDataAccumulatorKey.conditions],
              ),
            );

            dispatch(conditionModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.conditions] = [];
          }
        },
        // error: (error) => {},
        complete: () => {
          // Do nothing
        },
      });
  };

  const closeReportDataStream = () => {
    if (reportDataStream.current) {
      reportDataStream.current.unsubscribe();
    }
  };

  useEffect(() => {
    openReportDataStream();

    return () => {
      closeReportDataStream();
    };
  }, []);
};
