import { IconNames } from '@/shared/ui';

export const REPORT_CREATION_DATE_FORMAT = 'MMM d, Y HH:mm';

export const REPORT_SIGNED_DATE_FORMAT = 'EEE dd, yyyy HH:mm';

export const PANO_MOCKED_SRC =
  'https://storage.googleapis.com/diagnocat-model-activation-assets-2/2ab40940-4b88-8f9a-699c-53e52346ed87/panorama-single';
export const PANO_TOOLS_ICONS: IconNames[] = [
  'expand',
  'shaprness',
  'brightness',
  'invert',
  'back',
];
