import React from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Input } from '@/shared/ui';
import { useAppSelector, useSearch } from '@/shared/hooks';
import { patientModel, PatientsFilters } from '@/entities/patient';
import { ViewSwitcher } from '@/features/viewSwitcher';
import { Filters } from '@/features/filters';
import { DoctorsDropdownFilter } from '@/features/doctorsDropdownFilter';

import styles from './PatientListFilters.module.scss';

type PatientListFiltersProps = {
  className?: string;
  // TODO: Make general handler for all filters, e.g. search, filters, doctors
  onInputChange: (value: string) => void;
  onFilterChange: (searchValue: string) => void;
};

export const PatientListFilters = ({
  className,
  onInputChange,
  onFilterChange,
}: PatientListFiltersProps) => {
  const { formatMessage } = useIntl();
  const [search, updateSearch] = useSearch(onInputChange);
  const [sharedPatientsCounter, allPatientsCounter] = useAppSelector(
    patientModel.selectors.selectCounters,
  );

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.mainFilters}>
        <Filters<PatientsFilters>
          className={styles.patientFilters}
          filterName="filters"
          defaultActiveKey="all"
          items={[
            {
              value: 'all',
              label: (
                <FormattedMessage
                  id="patientList.filters.all"
                  defaultMessage="All"
                />
              ),
              amount: allPatientsCounter,
            },
            {
              value: 'sharedWithMe',
              label: (
                <FormattedMessage
                  id="patientList.filters.sharedWithMe"
                  defaultMessage="Shared with me"
                />
              ),
              amount: sharedPatientsCounter,
            },
          ]}
          onChange={onFilterChange}
        />

        <Input
          className={styles.search}
          name="search"
          value={search}
          icon="search"
          inputMode="search"
          placeholder={formatMessage({
            id: 'patientList.filters.searchBarPlaceHolder',
            defaultMessage: 'Search by patient’s name or ID',
          })}
          onChange={(e) => updateSearch(e.target.value)}
          onResetField={() => updateSearch('')}
        />

        <ViewSwitcher className={styles.viewSwitcher} />
      </div>

      <DoctorsDropdownFilter onChange={() => {}} />
    </div>
  );
};
