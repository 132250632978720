import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { organizationModel } from '@/entities/organization';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Button, Popup, Layout } from '@/shared/ui/';
import { PATHS } from '@/shared/config';
import { Header } from '@/widgets/Header';

import styles from './Organizations.module.scss';

export const Organizations = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const allOrganizations = useAppSelector(
    organizationModel.selectors.selectAll,
  );

  useEffect(() => {
    if (allOrganizations.length === 1) {
      dispatch(
        organizationModel.thunks.setCurrentOrganizationID(
          allOrganizations[0].ID,
        ),
      );
      navigate(PATHS.patients);
    }
  }, []);

  const onCheck = async (id: string) => {
    await dispatch(organizationModel.thunks.setCurrentOrganizationID(id));
    navigate(PATHS.patients);
  };

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.Content>
        <Layout.Main>
          <section>
            <Popup
              isOpen
              onClose={() => {}}
              className={styles.modal}
              shouldRenderCloseIconButton={false}
            >
              <h2 className="h2">Select current organization</h2>
              <div className={styles.modalContainer}>
                {allOrganizations.map((organization, index) => (
                  <div className={styles.organization} key={organization.ID}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span>{index + 1}</span>
                      <span>Org ID: {organization.ID}</span>
                      <span>Org Name: {organization.Name}</span>
                    </div>
                    <Button onClick={() => onCheck(organization.ID)}>
                      Open
                    </Button>
                  </div>
                ))}
              </div>
            </Popup>
          </section>
        </Layout.Main>
      </Layout.Content>
      <Layout.Footer />
    </Layout>
  );
};
