import { createSelector } from '@reduxjs/toolkit';

import { patientModel } from '@/entities/patient';
import { usersModel } from '@/entities/users';

// TODO: Don't write like this. This should be refactored.
export const selectPatientDoctorsAsUsers = (patientID: string) =>
  createSelector(
    usersModel.selectors.selectAll,
    patientModel.selectors.selectPatientDoctors(patientID),
    (users, patientDoctors) =>
      users?.filter(({ ID }) =>
        patientDoctors?.find(({ UserID }) => UserID === ID),
      ),
  );
