import { useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks';
import { toothModel } from '@/entities/tooth';

import { SliceInterface } from '../config/slices.types';
import { getImageSrc } from '../lib/getImageSrc';
import { assetsModel } from '../model';

export const useGetSelectedSlices = (toothID: string): SliceInterface[] => {
  const toothAssets = useAppSelector(
    assetsModel.selectors.selectByToothID(toothID),
  );

  const tooth = useAppSelector((state) =>
    toothModel.selectors.selectById(state, toothID),
  );

  const selectedSlices = useMemo(
    () =>
      tooth?.DisplaySlices.reduce((acc, selectedSliceID) => {
        const currentSlice = toothAssets.find(
          (asset) => asset.ID === selectedSliceID,
        );
        const slicesID =
          currentSlice?.GeneratedReport.CBCTGPToothSlice.Slice.ID;
        const slicesPath =
          currentSlice?.GeneratedReport.CBCTGPToothSlice.Slice.Path;

        if (slicesID) {
          return [
            ...acc,
            {
              src: getImageSrc(slicesID),
              id: selectedSliceID,
              path: slicesPath,
            },
          ];
        }
        return acc;
      }, []),
    [tooth?.DisplaySlices, toothAssets],
  );

  return selectedSlices;
};
