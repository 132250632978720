// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navigation-module__container--a43Oj0CE{display:flex;gap:24px;flex-grow:1}@media screen and (max-width: 1024px){.Navigation-module__container--a43Oj0CE{display:none}}.Navigation-module__link--V9wYjHr3{padding:4px;color:var(--purpletext2);text-decoration:none}.Navigation-module__link--V9wYjHr3:hover{color:var(--purpleflat)}", "",{"version":3,"sources":["webpack://./src/widgets/Header/ui/Navigation/Navigation.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,wCACE,YAAA,CACA,QAAA,CACA,WAAA,CCcA,sCDjBF,wCAMI,YAAA,CAAA,CAIJ,mCACE,WAAA,CACA,wBAAA,CACA,oBAAA,CAEA,yCACE,uBAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  display: flex;\n  gap: 24px;\n  flex-grow: 1;\n\n  @include smallDesktop {\n    display: none;\n  }\n}\n\n.link {\n  padding: 4px;\n  color: var(--purpletext2);\n  text-decoration: none;\n\n  &:hover {\n    color: var(--purpleflat);\n  }\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Navigation-module__container--a43Oj0CE",
	"link": "Navigation-module__link--V9wYjHr3"
};
export default ___CSS_LOADER_EXPORT___;
