import { createSelector } from '@reduxjs/toolkit';
import { compose } from 'ramda';

import { RootState } from '@/app/model/store';

import {
  filterTeethByPatientID,
  filterTeethByReportID,
  filterTeethByReportIDAndROI,
  filterTeethByReportIDAndROIISONumbers,
  mapTeethToIds,
  mapTeethToISONumbers,
} from '../lib';

import { toothAdapter } from './toothSlice';

const toothSelectors = toothAdapter.getSelectors(
  (state: RootState) => state.tooth,
);

export const { selectAll, selectTotal, selectEntities, selectIds, selectById } =
  toothSelectors;

export const selectByPatientID = createSelector(
  toothSelectors.selectAll,
  (state: RootState, patientID: string) => patientID,
  filterTeethByPatientID,
);

export const selectByReportID = createSelector(
  toothSelectors.selectAll,
  (state: RootState, reportID: string) => reportID,
  filterTeethByReportID,
);

export const selectByReportIDAndROI = createSelector(
  toothSelectors.selectAll,
  (state: RootState, reportID: string) => reportID,
  filterTeethByReportIDAndROI,
);

export const selectISONumbersByReportIDAndROI = createSelector(
  toothSelectors.selectAll,
  (state: RootState, reportID: string) => reportID,
  compose(mapTeethToISONumbers, filterTeethByReportIDAndROI),
);

export const selectIDsByReportIDAndROIISONumbers = createSelector(
  [
    toothSelectors.selectAll,
    (state: RootState, { reportID, ROIISONumbers }) => ({
      reportID,
      ROIISONumbers,
    }),
  ],
  (teeth, { reportID, ROIISONumbers }) =>
    compose(mapTeethToIds, filterTeethByReportIDAndROIISONumbers)(
      teeth,
      reportID,
      ROIISONumbers,
    ),
);
