import { ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import {
  CBCT_REPORTS_TYPE,
  REFFERALS_GROUP_DISABLED_FOR_CBCT,
  REFFERALS_GROUP_DISABLED_FOR_IOXRAY_AND_PANO,
} from '../config/constants';
import { ReferralsGroupsType } from '../config/referralsGroups.type';

export const isThisReferralGroupForThisRepport = (
  referralCode: ReferralsGroupsType,
  reportType: ReportType,
): boolean | undefined => {
  const isCBCTReport = CBCT_REPORTS_TYPE.includes(reportType);

  const isInvalidOrUnrecognized =
    reportType === ReportType.ReportType_InvalidValue ||
    reportType === ReportType.UNRECOGNIZED;

  if (isInvalidOrUnrecognized) {
    return undefined;
  }

  if (
    isCBCTReport &&
    !REFFERALS_GROUP_DISABLED_FOR_CBCT.includes(referralCode)
  ) {
    return true;
  }

  if (
    !isCBCTReport &&
    !REFFERALS_GROUP_DISABLED_FOR_IOXRAY_AND_PANO.includes(referralCode)
  ) {
    return true;
  }

  return false;
};
