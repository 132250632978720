import { Report } from '@/shared/api/protocol_gen/model/dto_report';

import { SignStatus } from '../config/reports.type';

export const getReportSignStatus = (report: Report) => {
  const signStatus: SignStatus = report?.Signature.Signed
    ? 'signed'
    : 'notSigned';

  const isReportWithoutSignature = Boolean(report?.DataCBCTOrtho);

  return isReportWithoutSignature ? 'withoutSign' : signStatus;
};
