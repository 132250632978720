import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import cn from 'classnames';

import { Icon, IconNames } from '@/shared/ui';

import styles from './ImageToolbar.module.scss';

const CONTROL_ICONS: Record<string, string> = {
  ruler: 'ruler',
  arrow: 'arrow',
  '3d': 'angle',
  erase: 'eraser',
  eyeFilled: 'view',
  brightness: 'brightness',
  shaprness: 'sharpening',
  expand: 'expand',
  back: 'reset',
};

type SliceToolbarProps = {
  controls: IconNames[];
  activeControl: string;
  setActiveControl: (newControl: string) => void;
  inverted?: boolean;
  setInverted?: Dispatch<SetStateAction<boolean>>;
  className?: string;
};

export const ImageToolbar: FC<SliceToolbarProps> = (props) => {
  const [activeIcon, setActiveIcon] = useState('');
  const {
    controls,
    activeControl,
    setActiveControl,
    inverted,
    setInverted,
    className,
  } = props;

  const handleControlClick = (control: IconNames) => {
    if (control === 'eyeFilled' && activeControl === 'view') {
      setActiveIcon('');
      return setActiveControl('');
    }
    setActiveIcon(control);
    if (control === 'invert') {
      setInverted((prev) => !prev);
    }

    return control === activeControl && control !== 'invert'
      ? setActiveControl('')
      : control !== 'invert' && setActiveControl(CONTROL_ICONS[control]);
  };

  return (
    <div className={cn(styles.container, className)}>
      {controls.map((control) => (
        <Icon
          key={control}
          name={control}
          className={cn(
            styles.icon,
            activeIcon === control && activeControl && styles.active,
            control === 'invert' && inverted && styles.active,
          )}
          size={32}
          onClick={() => handleControlClick(control)}
        />
      ))}
    </div>
  );
};
