import React, { FC, ReactElement, useState } from 'react';
import RcSelect from 'react-select';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { OptionsType, OptionType } from '../Select/Select.types';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';

import { Menu } from './components/Menu/Menu';
import { getDropdownSelectStyles } from './getDropdownSelectStyles';
import { dropdownControls } from './components/DropdownControls/DropdownControls';
import style from './DropdownSelect.module.scss';

export type DropdownSelectProps = {
  value: string | string[];
  label: string;
  onChange: (newValue: string | string[]) => void;
  menuTitle?: string;
  isSearchable?: boolean;
  options: OptionsType;
  disabled?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  noOptionsMessage?: ReactElement | string;
  isRtl?: boolean;
  className?: string;
  testID?: string;
};

export const DropdownSelect: FC<DropdownSelectProps> = (props) => {
  const {
    value,
    label,
    menuTitle,
    isSearchable = true,
    options,
    disabled,
    onChange,
    placeholder,
    isMulti = false,
    isRtl = false,
    className,
    testID,
    noOptionsMessage = (
      <FormattedMessage id="global.noData" defaultMessage="No data..." />
    ),
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const multiValueText = (value?.length ?? 0) === 0 ? '' : value.length;

  return (
    <div className={cn(style.container, className)} data-testid={testID}>
      <button
        disabled={disabled}
        className={cn(style.trigger, isOpen && style.isOpen)}
        type="button"
        onClick={() => setIsOpen(true)}
      >
        {label}
        {value && (
          <span
            className={cn('h5', style.valueContainer, isOpen && style.isOpen)}
          >
            {isMulti ? multiValueText : value}
          </span>
        )}
        <Icon className={style.arrow} name="arrowDown" size={18} />
      </button>
      {isOpen && (
        <div className={style.overlay} onClick={() => setIsOpen(false)} />
      )}
      {isOpen ? (
        <Menu>
          {menuTitle && (
            <div className={cn('h5', style.menuTitle)}>{menuTitle}</div>
          )}
          <RcSelect
            onChange={(newValue) => {
              if (isMulti) {
                onChange(
                  (newValue as OptionsType).map((option) => option.value),
                );
              } else {
                onChange((newValue as OptionType).value);
                setIsOpen(false);
              }
            }}
            noOptionsMessage={() => noOptionsMessage}
            backspaceRemovesValue={false}
            components={dropdownControls}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            isRtl={isRtl}
            isMulti={isMulti}
            options={options}
            styles={getDropdownSelectStyles(isSearchable)}
            placeholder={placeholder}
            tabSelectsValue={false}
            value={
              isMulti
                ? options.filter((option) => value?.includes(option.value))
                : options.find((option) => option.value === value)
            }
          />
          {isMulti && (
            <>
              <div className={style.divider} />
              <div className={style.buttons}>
                <Button
                  type="button"
                  variant="tertiary"
                  size="small"
                  onClick={() => {
                    onChange(null);
                    setIsOpen(false);
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Cancel"
                    id="global.cancel"
                  />
                </Button>
                <Button
                  disabled={value?.length === 0}
                  type="button"
                  variant="secondary"
                  size="small"
                  onClick={() => setIsOpen(false)}
                >
                  <FormattedMessage defaultMessage="Apply" id="global.apply" />
                </Button>
              </div>
            </>
          )}
        </Menu>
      ) : null}
    </div>
  );
};
