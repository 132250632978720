// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReferralRecommendations-module__container--nYemHPYO{width:100%}.ReferralRecommendations-module__main--BBEVCTsE{padding-top:16px;padding-bottom:12px;display:grid;gap:8px}.ReferralRecommendations-module__footer---gVQK7rY{display:flex;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/widgets/ReferralRecommendations/ui/ReferralRecommendations/ReferralRecommendations.module.scss"],"names":[],"mappings":"AAAA,qDACE,UAAA,CAGF,gDACE,gBAAA,CACA,mBAAA,CACA,YAAA,CACA,OAAA,CAGF,kDACE,YAAA,CACA,6BAAA","sourcesContent":[".container {\n  width: 100%;\n}\n\n.main {\n  padding-top: 16px;\n  padding-bottom: 12px;\n  display: grid;\n  gap: 8px;\n}\n\n.footer {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReferralRecommendations-module__container--nYemHPYO",
	"main": "ReferralRecommendations-module__main--BBEVCTsE",
	"footer": "ReferralRecommendations-module__footer---gVQK7rY"
};
export default ___CSS_LOADER_EXPORT___;
