// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrganizationTest-module__methods--SxLZRtcy{margin:20px 0}", "",{"version":3,"sources":["webpack://./src/pages/TestPage/components/Organization/OrganizationTest.module.scss"],"names":[],"mappings":"AAAA,4CACE,aAAA","sourcesContent":[".methods {\n  margin: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"methods": "OrganizationTest-module__methods--SxLZRtcy"
};
export default ___CSS_LOADER_EXPORT___;
