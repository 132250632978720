import React, { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Accordion,
  Avatar,
  Button,
  Description,
  Divider,
  Skeleton,
  WidgetCard,
} from '@/shared/ui';
import { gender } from '@/shared/i18n';
import { DoctorRow, getUserFullName, getUserInitials } from '@/entities/users';
import { findPatientDoctorById } from '@/entities/patient';
import { AddDoctorsModal } from '@/features/addDoctors';
import { RemoveDoctorModal } from '@/features/removeDoctor';
import { useMedia } from '@/shared/hooks';

import { formatPatientDateOfBirth } from '../lib/formatPatientDateOfBirth';
import { usePatientInfo } from '../hooks/usePatientInfo';
import { AccordionListNames } from '../config/constants';

import styles from './PatientInfo.module.scss';

type PatientInfoProps = {
  className?: string;
  patientID: string;
  onEditPatient: (id: string | number) => void;
};

export const PatientInfo: FC<PatientInfoProps> = (props) => {
  const { className, patientID, onEditPatient } = props;

  const { formatMessage } = useIntl();

  const { isPhone } = useMedia();

  const {
    isLoading,
    isError,
    patient,
    patientDoctors,
    patientDoctorsAsUsers,
    addDoctorsModal,
    removeDoctorModal,
  } = usePatientInfo(patientID);

  if (isLoading) {
    return <Skeleton width="100%" borderRadius="16px" height="320px" />;
  }

  // TODO: Add UI for error state
  if (isError) {
    return <div>Something went wrong</div>;
  }

  const doctorsQuantity = patientDoctorsAsUsers.length;

  const isDoctorListOpen = doctorsQuantity !== 0;

  // TODO: Add data
  const sharePatientQuantity = [].length;

  const isSharePatientListOpen = sharePatientQuantity !== 0;

  return (
    <>
      <WidgetCard
        className={cn(styles.container, className)}
        loading={isLoading}
      >
        <header className={styles.header}>
          <div className={styles.headerTop}>
            <h3 className={isPhone ? 'h4' : 'h3'}>
              <FormattedMessage
                id="patientInfo.title"
                defaultMessage="Patient’s information"
              />
            </h3>

            {!isPhone && (
              <Button
                variant="secondary"
                size="medium"
                icon="pen"
                onClick={() => onEditPatient(patientID)}
              >
                <FormattedMessage
                  id="patientInfo.editPatientInfoButton"
                  defaultMessage="Edit Info"
                />
              </Button>
            )}
          </div>

          <div className={styles.headerInfo}>
            <Description label="ID">{patientID}</Description>

            <div className={styles.headerInfoRow}>
              <Description
                label={
                  <FormattedMessage id="patientInfo.DoB" defaultMessage="DoB" />
                }
              >
                {formatPatientDateOfBirth(patient)}
              </Description>

              <Description
                label={formatMessage({
                  id: 'patientInfo.gender',
                  defaultMessage: 'Gender',
                })}
              >
                {formatMessage(gender[patient.Gender])}
              </Description>
            </div>

            <Description
              label={
                <FormattedMessage
                  id="patientInfo.email"
                  defaultMessage="Email"
                />
              }
            >
              {patient.PersonalData.Emails[0]}
            </Description>
          </div>
          {isPhone && (
            <Button
              variant="secondary"
              size="small"
              icon="pen"
              onClick={() => onEditPatient(patientID)}
            >
              <FormattedMessage
                id="patientInfo.editPatientInfoButton"
                defaultMessage="Edit Info"
              />
            </Button>
          )}
        </header>

        <Divider />
        <Accordion.Root
          type="single"
          className={styles.doctorListAccordionContent}
          defaultValue={isDoctorListOpen ? AccordionListNames.doctorList : ''}
          collapsible
        >
          <Accordion.Item value={AccordionListNames.doctorList}>
            <Accordion.Header>
              <Accordion.Trigger>
                <FormattedMessage
                  id="patientInfo.treatingDoctors"
                  defaultMessage="Treating doctors"
                />
                <span className={styles.counter}> {doctorsQuantity}</span>
              </Accordion.Trigger>
              {!isPhone && (
                <Button
                  variant="secondary"
                  icon="plus"
                  size="medium"
                  onClick={addDoctorsModal.open}
                >
                  <FormattedMessage
                    id="patientInfo.addNew"
                    defaultMessage="Add new"
                  />
                </Button>
              )}
            </Accordion.Header>
            <Accordion.Content>
              <div className={cn(styles.doctorsList)}>
                {patientDoctorsAsUsers.map((patientDoctorAsUser) => {
                  const doctorFullName = getUserFullName(patientDoctorAsUser);

                  const doctorInitials = getUserInitials(patientDoctorAsUser);

                  const patientDoctor = findPatientDoctorById(
                    patientDoctorAsUser.ID,
                    patientDoctors,
                  );

                  return (
                    <DoctorRow
                      key={patientDoctorAsUser.ID}
                      avatar={
                        <Avatar
                          src={patientDoctorAsUser.AvatarAssetID}
                          alt={doctorFullName}
                          initials={doctorInitials}
                        />
                      }
                      disabled={patientDoctor?.TeamMember?.AccessRevoked}
                      title={doctorFullName}
                      description="Belgravia Dental Studio"
                      onDelete={() =>
                        removeDoctorModal.open(patientDoctorAsUser.ID)
                      }
                    />
                  );
                })}
              </div>
              {isPhone && (
                <Button
                  variant="secondary"
                  icon="plus"
                  size="small"
                  onClick={addDoctorsModal.open}
                  className={styles.buttonInAccordion}
                >
                  <FormattedMessage
                    id="patientInfo.addNew"
                    defaultMessage="Add new"
                  />
                </Button>
              )}
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
        <Divider />

        <Accordion.Root
          type="single"
          className={styles.sharedListAccordionContent}
          defaultValue={
            isSharePatientListOpen ? AccordionListNames.sharePatientList : ''
          }
          collapsible
        >
          <Accordion.Item value={AccordionListNames.sharePatientList}>
            <Accordion.Header>
              <Accordion.Trigger>
                <FormattedMessage
                  id="patientInfo.sharedWith"
                  defaultMessage="Shared with"
                />
                <span className={styles.counter}> {sharePatientQuantity}</span>
              </Accordion.Trigger>
              {!isPhone && (
                <Button variant="secondary" icon="share" size="medium">
                  <FormattedMessage
                    id="patientInfo.sharePatient"
                    defaultMessage="Share patient"
                  />
                </Button>
              )}
            </Accordion.Header>
            <Accordion.Content>
              <div className={cn(styles.emptyDoctorList, 'p3')}>
                <FormattedMessage
                  id="patientInfo.noSharedDoctors"
                  defaultMessage="No shared doctors yet"
                />
              </div>
              {isPhone && (
                <Button
                  variant="secondary"
                  icon="share"
                  size="small"
                  className={styles.buttonInAccordion}
                >
                  <FormattedMessage
                    id="patientInfo.sharePatient"
                    defaultMessage="Share patient"
                  />
                </Button>
              )}
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      </WidgetCard>

      <AddDoctorsModal
        currentDoctorIDs={patientDoctorsAsUsers.map(({ ID }) => ID)}
        patientID={patientID}
        isOpen={addDoctorsModal.isOpen}
        onClose={addDoctorsModal.close}
      />

      <RemoveDoctorModal
        patientID={patientID}
        doctorID={removeDoctorModal.currentDoctorID}
        isOpen={removeDoctorModal.isOpen}
        onClose={removeDoctorModal.close}
      />
    </>
  );
};
