import { useState } from 'react';

import { toastCaller } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import {
  getConditionIDsByColor,
  useToothConditions,
} from '@/entities/condition';

export const useApproveTooth = (tooth: Tooth) => {
  const [isApproveToothLoading, setApproveToothLoading] = useState(false);
  const { generalConditionItems } = useToothConditions(tooth?.ID);

  const yellowConditionsIDs = getConditionIDsByColor(
    'yellow',
    generalConditionItems,
  );

  const dispatch = useAppDispatch();

  // TODO change heading for errors into formatMessages
  const toggleApproveTooth = async () => {
    setApproveToothLoading(true);

    try {
      const currentTooth = !tooth?.IsApproved
        ? await dispatch(
            reportsModel.thunks.setReportToothApproved({
              ToothID: tooth.ID,
              ToothConditionIDs: yellowConditionsIDs,
            }),
          ).unwrap()
        : await dispatch(
            reportsModel.thunks.setReportToothDisapproved({
              ToothID: tooth.ID,
            }),
          ).unwrap();
      dispatch(toothModel.actions.setNewestOne(currentTooth.Tooth));
    } catch (error) {
      toastCaller({
        message: error?.message,
        type: 'error',
        heading: 'Error',
      });
    } finally {
      setApproveToothLoading(false);
    }
  };

  return { toggleApproveTooth, isApproveToothLoading };
};
