import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { stateToHTML } from 'draft-js-export-html';
import { FC, useEffect, useState } from 'react';

import { Button, toastCaller } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { reportsModel } from '@/entities/reports';

import { Editor } from '../Editor/Editor';
import { getEditorContentFromValue } from '../../lib/getEditorContentFromValue';
import { getContentWithoutHTML } from '../../lib/getContentWithoutHTML';
import { getDraftJsStyles } from '../../lib/getDraftJsStyles';
import { getHTMLWithStyles } from '../../lib/getHTMLWithStyles';

import styles from './Conclusion.module.scss';

type ConclusionProps = {
  conclusion: string;
  className?: string;
};

export const PreviewConclusion: FC<Pick<ConclusionProps, 'conclusion'>> = (
  props,
) => {
  const { conclusion } = props;

  const htmlWithoutAlign = stateToHTML(getEditorContentFromValue(conclusion));
  const html = getHTMLWithStyles(conclusion, htmlWithoutAlign);

  return (
    <div className={styles.preview}>
      <h1>
        <FormattedMessage id="report.conclusion" defaultMessage="Conclusion" />
      </h1>
      <div className={styles.legacyConclusion}>
        <p className="p2" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
};

// TODO: Discuss with the team method of choosing FSD slice
export const Conclusion: FC<ConclusionProps> = (props) => {
  const { conclusion, className } = props;

  const dispatch = useAppDispatch();

  const { reportID } = useParams();

  const { formatMessage } = useIntl();

  const [isEditMode, setIsEditMode] = useState(false);
  const [editorValue, setEditorValue] = useState('');
  const [loading, setLoading] = useState(false);

  const placeholder = formatMessage({
    id: 'report.conclusion.placeholder',
    defaultMessage: 'Leave a conclusion...',
  });
  const htmlWithoutAlign = stateToHTML(getEditorContentFromValue(conclusion));
  const html = getHTMLWithStyles(conclusion, htmlWithoutAlign);
  const text = getContentWithoutHTML(html);

  useEffect(() => {
    setEditorValue(html);
  }, [html, conclusion]);

  const handleSave = async () => {
    setLoading(true);

    try {
      const contentState = JSON.stringify(getDraftJsStyles(editorValue));

      const result = await dispatch(
        reportsModel.thunks.setReportConclusion({
          ReportID: reportID,
          LegacyConclusion: contentState,
        }),
      ).unwrap();

      await dispatch(reportsModel.actions.setNewestOne(result.Report));
    } catch (error) {
      toastCaller({ message: error?.message, type: 'error', heading: '' });
    } finally {
      setLoading(false);
      setIsEditMode(false);
    }
  };

  return (
    <div className={cn(styles.container, className)}>
      <h1>
        <FormattedMessage id="report.conclusion" defaultMessage="Conclusion" />
      </h1>
      {text && !isEditMode ? (
        <div className={styles.legacyConclusion}>
          {/* TODO: FIX dangerouslySetInnerHTML */}
          {/* eslint-disable-next-line react/no-danger */}
          <p className="p2" dangerouslySetInnerHTML={{ __html: html }} />
          <Button
            size="small"
            variant="secondary"
            onClick={() => setIsEditMode(true)}
            className={styles.editButton}
          >
            <FormattedMessage id="global.edit" defaultMessage="Edit" />
          </Button>
        </div>
      ) : (
        <div className={styles.editorContainer}>
          <Editor
            value={editorValue}
            setValue={setEditorValue}
            placeholder={placeholder}
          />
          <div className={styles.buttonsContainer}>
            {text && (
              <Button
                size="small"
                variant="tertiary"
                onClick={() => setIsEditMode(false)}
              >
                <FormattedMessage id="global.cancel" defaultMessage="Cancel" />
              </Button>
            )}
            <Button
              size="small"
              variant="secondary"
              onClick={handleSave}
              loading={loading}
              disabled={
                editorValue === html ||
                (!text && !getContentWithoutHTML(editorValue).trim())
              }
            >
              <FormattedMessage id="global.save" defaultMessage="Save" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
