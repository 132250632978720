/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Certainty } from '../model/dto_report_certainty';
import { FloatPoint3D, BBox } from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

/**
 * //////////////////////////////////////////////////////////////
 * //  Localization
 * //
 * //  Localization of something on an image;
 * //
 */
export enum LocalizationType {
  LocalizationType2D = 0,
  LocalizationType3D = 1,
  UNRECOGNIZED = -1,
}

export function localizationTypeFromJSON(object: any): LocalizationType {
  switch (object) {
    case 0:
    case 'LocalizationType2D':
      return LocalizationType.LocalizationType2D;
    case 1:
    case 'LocalizationType3D':
      return LocalizationType.LocalizationType3D;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return LocalizationType.UNRECOGNIZED;
  }
}

export function localizationTypeToJSON(object: LocalizationType): string {
  switch (object) {
    case LocalizationType.LocalizationType2D:
      return 'LocalizationType2D';
    case LocalizationType.LocalizationType3D:
      return 'LocalizationType3D';
    case LocalizationType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Localization {
  ID: string;
  Target: Localization_LocalizationTarget | undefined;
  Certainty: Certainty | undefined;
  Type: LocalizationType;
  MaskAssetID: string;
  MaskOffset: FloatPoint3D | undefined;
  BBox: BBox | undefined;
  /** Optional */
  Centroid: FloatPoint3D | undefined;
}

export interface Localization_LocalizationTarget {
  AssetID: string;
  /** > May be omitted if actual target is a whole asset (i.e. CBCT) */
  FileID: string;
}

function createBaseLocalization(): Localization {
  return {
    ID: '',
    Target: undefined,
    Certainty: undefined,
    Type: 0,
    MaskAssetID: '',
    MaskOffset: undefined,
    BBox: undefined,
    Centroid: undefined,
  };
}

export const Localization = {
  encode(
    message: Localization,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Target !== undefined) {
      Localization_LocalizationTarget.encode(
        message.Target,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Certainty !== undefined) {
      Certainty.encode(message.Certainty, writer.uint32(26).fork()).ldelim();
    }
    if (message.Type !== 0) {
      writer.uint32(88).int32(message.Type);
    }
    if (message.MaskAssetID !== '') {
      writer.uint32(98).string(message.MaskAssetID);
    }
    if (message.MaskOffset !== undefined) {
      FloatPoint3D.encode(
        message.MaskOffset,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.BBox !== undefined) {
      BBox.encode(message.BBox, writer.uint32(114).fork()).ldelim();
    }
    if (message.Centroid !== undefined) {
      FloatPoint3D.encode(message.Centroid, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Localization {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocalization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Target = Localization_LocalizationTarget.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.Certainty = Certainty.decode(reader, reader.uint32());
          break;
        case 11:
          message.Type = reader.int32() as any;
          break;
        case 12:
          message.MaskAssetID = reader.string();
          break;
        case 13:
          message.MaskOffset = FloatPoint3D.decode(reader, reader.uint32());
          break;
        case 14:
          message.BBox = BBox.decode(reader, reader.uint32());
          break;
        case 15:
          message.Centroid = FloatPoint3D.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Localization {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Target: isSet(object.Target)
        ? Localization_LocalizationTarget.fromJSON(object.Target)
        : undefined,
      Certainty: isSet(object.Certainty)
        ? Certainty.fromJSON(object.Certainty)
        : undefined,
      Type: isSet(object.Type) ? localizationTypeFromJSON(object.Type) : 0,
      MaskAssetID: isSet(object.MaskAssetID) ? String(object.MaskAssetID) : '',
      MaskOffset: isSet(object.MaskOffset)
        ? FloatPoint3D.fromJSON(object.MaskOffset)
        : undefined,
      BBox: isSet(object.BBox) ? BBox.fromJSON(object.BBox) : undefined,
      Centroid: isSet(object.Centroid)
        ? FloatPoint3D.fromJSON(object.Centroid)
        : undefined,
    };
  },

  toJSON(message: Localization): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Target !== undefined &&
      (obj.Target = message.Target
        ? Localization_LocalizationTarget.toJSON(message.Target)
        : undefined);
    message.Certainty !== undefined &&
      (obj.Certainty = message.Certainty
        ? Certainty.toJSON(message.Certainty)
        : undefined);
    message.Type !== undefined &&
      (obj.Type = localizationTypeToJSON(message.Type));
    message.MaskAssetID !== undefined &&
      (obj.MaskAssetID = message.MaskAssetID);
    message.MaskOffset !== undefined &&
      (obj.MaskOffset = message.MaskOffset
        ? FloatPoint3D.toJSON(message.MaskOffset)
        : undefined);
    message.BBox !== undefined &&
      (obj.BBox = message.BBox ? BBox.toJSON(message.BBox) : undefined);
    message.Centroid !== undefined &&
      (obj.Centroid = message.Centroid
        ? FloatPoint3D.toJSON(message.Centroid)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Localization>, I>>(
    object: I,
  ): Localization {
    const message = createBaseLocalization();
    message.ID = object.ID ?? '';
    message.Target =
      object.Target !== undefined && object.Target !== null
        ? Localization_LocalizationTarget.fromPartial(object.Target)
        : undefined;
    message.Certainty =
      object.Certainty !== undefined && object.Certainty !== null
        ? Certainty.fromPartial(object.Certainty)
        : undefined;
    message.Type = object.Type ?? 0;
    message.MaskAssetID = object.MaskAssetID ?? '';
    message.MaskOffset =
      object.MaskOffset !== undefined && object.MaskOffset !== null
        ? FloatPoint3D.fromPartial(object.MaskOffset)
        : undefined;
    message.BBox =
      object.BBox !== undefined && object.BBox !== null
        ? BBox.fromPartial(object.BBox)
        : undefined;
    message.Centroid =
      object.Centroid !== undefined && object.Centroid !== null
        ? FloatPoint3D.fromPartial(object.Centroid)
        : undefined;
    return message;
  },
};

function createBaseLocalization_LocalizationTarget(): Localization_LocalizationTarget {
  return { AssetID: '', FileID: '' };
}

export const Localization_LocalizationTarget = {
  encode(
    message: Localization_LocalizationTarget,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AssetID !== '') {
      writer.uint32(10).string(message.AssetID);
    }
    if (message.FileID !== '') {
      writer.uint32(18).string(message.FileID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Localization_LocalizationTarget {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocalization_LocalizationTarget();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AssetID = reader.string();
          break;
        case 2:
          message.FileID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Localization_LocalizationTarget {
    return {
      AssetID: isSet(object.AssetID) ? String(object.AssetID) : '',
      FileID: isSet(object.FileID) ? String(object.FileID) : '',
    };
  },

  toJSON(message: Localization_LocalizationTarget): unknown {
    const obj: any = {};
    message.AssetID !== undefined && (obj.AssetID = message.AssetID);
    message.FileID !== undefined && (obj.FileID = message.FileID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Localization_LocalizationTarget>, I>>(
    object: I,
  ): Localization_LocalizationTarget {
    const message = createBaseLocalization_LocalizationTarget();
    message.AssetID = object.AssetID ?? '';
    message.FileID = object.FileID ?? '';
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
