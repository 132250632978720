/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTSectionOrientation,
  File,
  DerivedDataImage,
  cBCTSectionOrientationFromJSON,
  cBCTSectionOrientationToJSON,
} from '../model/dto_asset_common';
import { OrderedLine2D, BBox, IntPoint3D } from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

/** https://diagnocat.atlassian.net/wiki/spaces/DATA/pages/89882743/Teeth+landmarks+specification */
export enum ToothLandmarkCode {
  /** LandmarkInvalidValue - default value */
  LandmarkInvalidValue = 0,
  /** LandmarkUpperToothMedian - utm */
  LandmarkUpperToothMedian = 1,
  /** LandmarkLowerToothMedian - ltm */
  LandmarkLowerToothMedian = 2,
  /** LandmarkEnamelJointMesial - ejm */
  LandmarkEnamelJointMesial = 3,
  /** LandmarkEnamelJointDistal - ejd */
  LandmarkEnamelJointDistal = 4,
  /** LandmarkEnamelJointVestibular - ejv */
  LandmarkEnamelJointVestibular = 5,
  /** LandmarkEnamelJointOral - ejo */
  LandmarkEnamelJointOral = 6,
  /** LandmarkBonePeakMesial - bpm */
  LandmarkBonePeakMesial = 7,
  /** LandmarkBonePeakDistal - bpd */
  LandmarkBonePeakDistal = 8,
  /** LandmarkBonePeakVestibular - bpv */
  LandmarkBonePeakVestibular = 9,
  /** LandmarkBonePeakOral - bpo */
  LandmarkBonePeakOral = 10,
  /** LandmarkAttachmentMesial - attm */
  LandmarkAttachmentMesial = 11,
  /** LandmarkAttachmentDistal - attd */
  LandmarkAttachmentDistal = 12,
  /** LandmarkAttachmentVestibular - attv */
  LandmarkAttachmentVestibular = 13,
  /** LandmarkAttachmentOral - atto */
  LandmarkAttachmentOral = 14,
  /** LandmarkFurcation - fur */
  LandmarkFurcation = 15,
  /** LandmarkBuccal - b */
  LandmarkBuccal = 16,
  /** LandmarkLingual - l */
  LandmarkLingual = 17,
  /** LandmarkMesial1 - m1 */
  LandmarkMesial1 = 18,
  /** LandmarkMesial2 - m2 */
  LandmarkMesial2 = 19,
  /** LandmarkDistal - d */
  LandmarkDistal = 20,
  /** LandmarkPalatal - p */
  LandmarkPalatal = 21,
  /** LandmarkA1 - a1 */
  LandmarkA1 = 22,
  UNRECOGNIZED = -1,
}

export function toothLandmarkCodeFromJSON(object: any): ToothLandmarkCode {
  switch (object) {
    case 0:
    case 'LandmarkInvalidValue':
      return ToothLandmarkCode.LandmarkInvalidValue;
    case 1:
    case 'LandmarkUpperToothMedian':
      return ToothLandmarkCode.LandmarkUpperToothMedian;
    case 2:
    case 'LandmarkLowerToothMedian':
      return ToothLandmarkCode.LandmarkLowerToothMedian;
    case 3:
    case 'LandmarkEnamelJointMesial':
      return ToothLandmarkCode.LandmarkEnamelJointMesial;
    case 4:
    case 'LandmarkEnamelJointDistal':
      return ToothLandmarkCode.LandmarkEnamelJointDistal;
    case 5:
    case 'LandmarkEnamelJointVestibular':
      return ToothLandmarkCode.LandmarkEnamelJointVestibular;
    case 6:
    case 'LandmarkEnamelJointOral':
      return ToothLandmarkCode.LandmarkEnamelJointOral;
    case 7:
    case 'LandmarkBonePeakMesial':
      return ToothLandmarkCode.LandmarkBonePeakMesial;
    case 8:
    case 'LandmarkBonePeakDistal':
      return ToothLandmarkCode.LandmarkBonePeakDistal;
    case 9:
    case 'LandmarkBonePeakVestibular':
      return ToothLandmarkCode.LandmarkBonePeakVestibular;
    case 10:
    case 'LandmarkBonePeakOral':
      return ToothLandmarkCode.LandmarkBonePeakOral;
    case 11:
    case 'LandmarkAttachmentMesial':
      return ToothLandmarkCode.LandmarkAttachmentMesial;
    case 12:
    case 'LandmarkAttachmentDistal':
      return ToothLandmarkCode.LandmarkAttachmentDistal;
    case 13:
    case 'LandmarkAttachmentVestibular':
      return ToothLandmarkCode.LandmarkAttachmentVestibular;
    case 14:
    case 'LandmarkAttachmentOral':
      return ToothLandmarkCode.LandmarkAttachmentOral;
    case 15:
    case 'LandmarkFurcation':
      return ToothLandmarkCode.LandmarkFurcation;
    case 16:
    case 'LandmarkBuccal':
      return ToothLandmarkCode.LandmarkBuccal;
    case 17:
    case 'LandmarkLingual':
      return ToothLandmarkCode.LandmarkLingual;
    case 18:
    case 'LandmarkMesial1':
      return ToothLandmarkCode.LandmarkMesial1;
    case 19:
    case 'LandmarkMesial2':
      return ToothLandmarkCode.LandmarkMesial2;
    case 20:
    case 'LandmarkDistal':
      return ToothLandmarkCode.LandmarkDistal;
    case 21:
    case 'LandmarkPalatal':
      return ToothLandmarkCode.LandmarkPalatal;
    case 22:
    case 'LandmarkA1':
      return ToothLandmarkCode.LandmarkA1;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ToothLandmarkCode.UNRECOGNIZED;
  }
}

export function toothLandmarkCodeToJSON(object: ToothLandmarkCode): string {
  switch (object) {
    case ToothLandmarkCode.LandmarkInvalidValue:
      return 'LandmarkInvalidValue';
    case ToothLandmarkCode.LandmarkUpperToothMedian:
      return 'LandmarkUpperToothMedian';
    case ToothLandmarkCode.LandmarkLowerToothMedian:
      return 'LandmarkLowerToothMedian';
    case ToothLandmarkCode.LandmarkEnamelJointMesial:
      return 'LandmarkEnamelJointMesial';
    case ToothLandmarkCode.LandmarkEnamelJointDistal:
      return 'LandmarkEnamelJointDistal';
    case ToothLandmarkCode.LandmarkEnamelJointVestibular:
      return 'LandmarkEnamelJointVestibular';
    case ToothLandmarkCode.LandmarkEnamelJointOral:
      return 'LandmarkEnamelJointOral';
    case ToothLandmarkCode.LandmarkBonePeakMesial:
      return 'LandmarkBonePeakMesial';
    case ToothLandmarkCode.LandmarkBonePeakDistal:
      return 'LandmarkBonePeakDistal';
    case ToothLandmarkCode.LandmarkBonePeakVestibular:
      return 'LandmarkBonePeakVestibular';
    case ToothLandmarkCode.LandmarkBonePeakOral:
      return 'LandmarkBonePeakOral';
    case ToothLandmarkCode.LandmarkAttachmentMesial:
      return 'LandmarkAttachmentMesial';
    case ToothLandmarkCode.LandmarkAttachmentDistal:
      return 'LandmarkAttachmentDistal';
    case ToothLandmarkCode.LandmarkAttachmentVestibular:
      return 'LandmarkAttachmentVestibular';
    case ToothLandmarkCode.LandmarkAttachmentOral:
      return 'LandmarkAttachmentOral';
    case ToothLandmarkCode.LandmarkFurcation:
      return 'LandmarkFurcation';
    case ToothLandmarkCode.LandmarkBuccal:
      return 'LandmarkBuccal';
    case ToothLandmarkCode.LandmarkLingual:
      return 'LandmarkLingual';
    case ToothLandmarkCode.LandmarkMesial1:
      return 'LandmarkMesial1';
    case ToothLandmarkCode.LandmarkMesial2:
      return 'LandmarkMesial2';
    case ToothLandmarkCode.LandmarkDistal:
      return 'LandmarkDistal';
    case ToothLandmarkCode.LandmarkPalatal:
      return 'LandmarkPalatal';
    case ToothLandmarkCode.LandmarkA1:
      return 'LandmarkA1';
    case ToothLandmarkCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface AssetContentGeneratedCBCTGPPanoramaReformatSplit {
  PanoramaSplit: File | undefined;
  Derived: DerivedDataImage | undefined;
}

export interface AssetContentGeneratedCBCTGPPanoramaReformatGeneral {
  PanoramaSingle: File | undefined;
  Derived: DerivedDataImage | undefined;
}

export interface AssetContentGeneratedCBCTGPToothSlice {
  Slice: File | undefined;
  PredictedISONumber: number;
  Orientation: CBCTSectionOrientation;
  Scale: number;
  WindowWidth: number;
  WindowLevel: number;
  /** if > 0 -> order */
  ProposedToothCardOrder: number;
  Regular: CBCTGPToothSliceMetadataRegular | undefined;
  Guide: CBCTGPToothSliceMetadataGuide | undefined;
  GuideFrontal: CBCTGPToothSliceMetadataGuideFrontal | undefined;
}

export interface CBCTGPToothSliceMetadataRegular {
  IdxStart: number;
  IdxEnd: number;
  /** in millimetrs; maps to `thickness_mm` */
  Thickness: number;
  /** in millimeters; maps to `pos_mm` */
  Position: number;
  TotalImages: number;
  Stride: number;
  Order: number;
}

export interface CBCTGPToothSliceMetadataGuide {
  Points: number[];
  /** maps to `axis`; is it the same? */
  Axis2: number;
  OrthoAxis: number;
}

export interface CBCTGPToothSliceMetadataGuideFrontal {
  IdxStart: number;
  IdxEnd: number;
  /** in millimetrs; maps to `thickness_mm` */
  Thickness: number;
  /** in millimeters; maps to `pos_mm` */
  Position: number;
  Axis2: number;
  /** maps to `line-*` */
  Lines: OrderedLine2D[];
}

export interface AssetContentGeneratedCBCTGPMPRSubVolume {
  DCM: File | undefined;
  PredictedISONumber: number;
  Position: BBox | undefined;
  PositionInner: BBox | undefined;
  WindowWidth: number;
  WindowLevel: number;
  /** {"landmarks": {"utm": [82, 126, 67], ...}} */
  Landmarks: AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark[];
}

export interface AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark {
  Code: ToothLandmarkCode;
  Position: IntPoint3D | undefined;
}

/** {"type": "File", "file": {"task_id": "b7ac1063-b446-dc29-2544-24c1de704e47", "key": "14/periodontium-md-image", "filename": "/tmp/tmp1t_p7cp3", "content_type": "image/png", "size": 8376, "meta": {"section": "periodontium", "image_type": "slice-mesiodistal", "image_height": 137, "image_width": 64}, "type": "File"}, "event": "execution-event"} */
export interface AssetContentGeneratedCBCTGPPeriodontiumImage {
  Periodontium: File | undefined;
  PredictedISONumber: number;
  ImageType: AssetContentGeneratedCBCTGPPeriodontiumImage_Type;
}

export enum AssetContentGeneratedCBCTGPPeriodontiumImage_Type {
  Mesiodistal = 0,
  UNRECOGNIZED = -1,
}

export function assetContentGeneratedCBCTGPPeriodontiumImage_TypeFromJSON(
  object: any,
): AssetContentGeneratedCBCTGPPeriodontiumImage_Type {
  switch (object) {
    case 0:
    case 'Mesiodistal':
      return AssetContentGeneratedCBCTGPPeriodontiumImage_Type.Mesiodistal;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AssetContentGeneratedCBCTGPPeriodontiumImage_Type.UNRECOGNIZED;
  }
}

export function assetContentGeneratedCBCTGPPeriodontiumImage_TypeToJSON(
  object: AssetContentGeneratedCBCTGPPeriodontiumImage_Type,
): string {
  switch (object) {
    case AssetContentGeneratedCBCTGPPeriodontiumImage_Type.Mesiodistal:
      return 'Mesiodistal';
    case AssetContentGeneratedCBCTGPPeriodontiumImage_Type.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

function createBaseAssetContentGeneratedCBCTGPPanoramaReformatSplit(): AssetContentGeneratedCBCTGPPanoramaReformatSplit {
  return { PanoramaSplit: undefined, Derived: undefined };
}

export const AssetContentGeneratedCBCTGPPanoramaReformatSplit = {
  encode(
    message: AssetContentGeneratedCBCTGPPanoramaReformatSplit,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PanoramaSplit !== undefined) {
      File.encode(message.PanoramaSplit, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTGPPanoramaReformatSplit {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseAssetContentGeneratedCBCTGPPanoramaReformatSplit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PanoramaSplit = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTGPPanoramaReformatSplit {
    return {
      PanoramaSplit: isSet(object.PanoramaSplit)
        ? File.fromJSON(object.PanoramaSplit)
        : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTGPPanoramaReformatSplit): unknown {
    const obj: any = {};
    message.PanoramaSplit !== undefined &&
      (obj.PanoramaSplit = message.PanoramaSplit
        ? File.toJSON(message.PanoramaSplit)
        : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedCBCTGPPanoramaReformatSplit>,
      I
    >,
  >(object: I): AssetContentGeneratedCBCTGPPanoramaReformatSplit {
    const message =
      createBaseAssetContentGeneratedCBCTGPPanoramaReformatSplit();
    message.PanoramaSplit =
      object.PanoramaSplit !== undefined && object.PanoramaSplit !== null
        ? File.fromPartial(object.PanoramaSplit)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTGPPanoramaReformatGeneral(): AssetContentGeneratedCBCTGPPanoramaReformatGeneral {
  return { PanoramaSingle: undefined, Derived: undefined };
}

export const AssetContentGeneratedCBCTGPPanoramaReformatGeneral = {
  encode(
    message: AssetContentGeneratedCBCTGPPanoramaReformatGeneral,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PanoramaSingle !== undefined) {
      File.encode(message.PanoramaSingle, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTGPPanoramaReformatGeneral {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseAssetContentGeneratedCBCTGPPanoramaReformatGeneral();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PanoramaSingle = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTGPPanoramaReformatGeneral {
    return {
      PanoramaSingle: isSet(object.PanoramaSingle)
        ? File.fromJSON(object.PanoramaSingle)
        : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTGPPanoramaReformatGeneral): unknown {
    const obj: any = {};
    message.PanoramaSingle !== undefined &&
      (obj.PanoramaSingle = message.PanoramaSingle
        ? File.toJSON(message.PanoramaSingle)
        : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedCBCTGPPanoramaReformatGeneral>,
      I
    >,
  >(object: I): AssetContentGeneratedCBCTGPPanoramaReformatGeneral {
    const message =
      createBaseAssetContentGeneratedCBCTGPPanoramaReformatGeneral();
    message.PanoramaSingle =
      object.PanoramaSingle !== undefined && object.PanoramaSingle !== null
        ? File.fromPartial(object.PanoramaSingle)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTGPToothSlice(): AssetContentGeneratedCBCTGPToothSlice {
  return {
    Slice: undefined,
    PredictedISONumber: 0,
    Orientation: 0,
    Scale: 0,
    WindowWidth: 0,
    WindowLevel: 0,
    ProposedToothCardOrder: 0,
    Regular: undefined,
    Guide: undefined,
    GuideFrontal: undefined,
  };
}

export const AssetContentGeneratedCBCTGPToothSlice = {
  encode(
    message: AssetContentGeneratedCBCTGPToothSlice,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Slice !== undefined) {
      File.encode(message.Slice, writer.uint32(10).fork()).ldelim();
    }
    if (message.PredictedISONumber !== 0) {
      writer.uint32(16).uint32(message.PredictedISONumber);
    }
    if (message.Orientation !== 0) {
      writer.uint32(24).int32(message.Orientation);
    }
    if (message.Scale !== 0) {
      writer.uint32(37).float(message.Scale);
    }
    if (message.WindowWidth !== 0) {
      writer.uint32(45).float(message.WindowWidth);
    }
    if (message.WindowLevel !== 0) {
      writer.uint32(53).float(message.WindowLevel);
    }
    if (message.ProposedToothCardOrder !== 0) {
      writer.uint32(56).int64(message.ProposedToothCardOrder);
    }
    if (message.Regular !== undefined) {
      CBCTGPToothSliceMetadataRegular.encode(
        message.Regular,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.Guide !== undefined) {
      CBCTGPToothSliceMetadataGuide.encode(
        message.Guide,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.GuideFrontal !== undefined) {
      CBCTGPToothSliceMetadataGuideFrontal.encode(
        message.GuideFrontal,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTGPToothSlice {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTGPToothSlice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Slice = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.PredictedISONumber = reader.uint32();
          break;
        case 3:
          message.Orientation = reader.int32() as any;
          break;
        case 4:
          message.Scale = reader.float();
          break;
        case 5:
          message.WindowWidth = reader.float();
          break;
        case 6:
          message.WindowLevel = reader.float();
          break;
        case 7:
          message.ProposedToothCardOrder = longToNumber(reader.int64() as Long);
          break;
        case 101:
          message.Regular = CBCTGPToothSliceMetadataRegular.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 102:
          message.Guide = CBCTGPToothSliceMetadataGuide.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 103:
          message.GuideFrontal = CBCTGPToothSliceMetadataGuideFrontal.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTGPToothSlice {
    return {
      Slice: isSet(object.Slice) ? File.fromJSON(object.Slice) : undefined,
      PredictedISONumber: isSet(object.PredictedISONumber)
        ? Number(object.PredictedISONumber)
        : 0,
      Orientation: isSet(object.Orientation)
        ? cBCTSectionOrientationFromJSON(object.Orientation)
        : 0,
      Scale: isSet(object.Scale) ? Number(object.Scale) : 0,
      WindowWidth: isSet(object.WindowWidth) ? Number(object.WindowWidth) : 0,
      WindowLevel: isSet(object.WindowLevel) ? Number(object.WindowLevel) : 0,
      ProposedToothCardOrder: isSet(object.ProposedToothCardOrder)
        ? Number(object.ProposedToothCardOrder)
        : 0,
      Regular: isSet(object.Regular)
        ? CBCTGPToothSliceMetadataRegular.fromJSON(object.Regular)
        : undefined,
      Guide: isSet(object.Guide)
        ? CBCTGPToothSliceMetadataGuide.fromJSON(object.Guide)
        : undefined,
      GuideFrontal: isSet(object.GuideFrontal)
        ? CBCTGPToothSliceMetadataGuideFrontal.fromJSON(object.GuideFrontal)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTGPToothSlice): unknown {
    const obj: any = {};
    message.Slice !== undefined &&
      (obj.Slice = message.Slice ? File.toJSON(message.Slice) : undefined);
    message.PredictedISONumber !== undefined &&
      (obj.PredictedISONumber = Math.round(message.PredictedISONumber));
    message.Orientation !== undefined &&
      (obj.Orientation = cBCTSectionOrientationToJSON(message.Orientation));
    message.Scale !== undefined && (obj.Scale = message.Scale);
    message.WindowWidth !== undefined &&
      (obj.WindowWidth = message.WindowWidth);
    message.WindowLevel !== undefined &&
      (obj.WindowLevel = message.WindowLevel);
    message.ProposedToothCardOrder !== undefined &&
      (obj.ProposedToothCardOrder = Math.round(message.ProposedToothCardOrder));
    message.Regular !== undefined &&
      (obj.Regular = message.Regular
        ? CBCTGPToothSliceMetadataRegular.toJSON(message.Regular)
        : undefined);
    message.Guide !== undefined &&
      (obj.Guide = message.Guide
        ? CBCTGPToothSliceMetadataGuide.toJSON(message.Guide)
        : undefined);
    message.GuideFrontal !== undefined &&
      (obj.GuideFrontal = message.GuideFrontal
        ? CBCTGPToothSliceMetadataGuideFrontal.toJSON(message.GuideFrontal)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTGPToothSlice>, I>,
  >(object: I): AssetContentGeneratedCBCTGPToothSlice {
    const message = createBaseAssetContentGeneratedCBCTGPToothSlice();
    message.Slice =
      object.Slice !== undefined && object.Slice !== null
        ? File.fromPartial(object.Slice)
        : undefined;
    message.PredictedISONumber = object.PredictedISONumber ?? 0;
    message.Orientation = object.Orientation ?? 0;
    message.Scale = object.Scale ?? 0;
    message.WindowWidth = object.WindowWidth ?? 0;
    message.WindowLevel = object.WindowLevel ?? 0;
    message.ProposedToothCardOrder = object.ProposedToothCardOrder ?? 0;
    message.Regular =
      object.Regular !== undefined && object.Regular !== null
        ? CBCTGPToothSliceMetadataRegular.fromPartial(object.Regular)
        : undefined;
    message.Guide =
      object.Guide !== undefined && object.Guide !== null
        ? CBCTGPToothSliceMetadataGuide.fromPartial(object.Guide)
        : undefined;
    message.GuideFrontal =
      object.GuideFrontal !== undefined && object.GuideFrontal !== null
        ? CBCTGPToothSliceMetadataGuideFrontal.fromPartial(object.GuideFrontal)
        : undefined;
    return message;
  },
};

function createBaseCBCTGPToothSliceMetadataRegular(): CBCTGPToothSliceMetadataRegular {
  return {
    IdxStart: 0,
    IdxEnd: 0,
    Thickness: 0,
    Position: 0,
    TotalImages: 0,
    Stride: 0,
    Order: 0,
  };
}

export const CBCTGPToothSliceMetadataRegular = {
  encode(
    message: CBCTGPToothSliceMetadataRegular,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IdxStart !== 0) {
      writer.uint32(8).int64(message.IdxStart);
    }
    if (message.IdxEnd !== 0) {
      writer.uint32(16).int64(message.IdxEnd);
    }
    if (message.Thickness !== 0) {
      writer.uint32(29).float(message.Thickness);
    }
    if (message.Position !== 0) {
      writer.uint32(37).float(message.Position);
    }
    if (message.TotalImages !== 0) {
      writer.uint32(40).int64(message.TotalImages);
    }
    if (message.Stride !== 0) {
      writer.uint32(53).float(message.Stride);
    }
    if (message.Order !== 0) {
      writer.uint32(56).int64(message.Order);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CBCTGPToothSliceMetadataRegular {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCBCTGPToothSliceMetadataRegular();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IdxStart = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.IdxEnd = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.Thickness = reader.float();
          break;
        case 4:
          message.Position = reader.float();
          break;
        case 5:
          message.TotalImages = longToNumber(reader.int64() as Long);
          break;
        case 6:
          message.Stride = reader.float();
          break;
        case 7:
          message.Order = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CBCTGPToothSliceMetadataRegular {
    return {
      IdxStart: isSet(object.IdxStart) ? Number(object.IdxStart) : 0,
      IdxEnd: isSet(object.IdxEnd) ? Number(object.IdxEnd) : 0,
      Thickness: isSet(object.Thickness) ? Number(object.Thickness) : 0,
      Position: isSet(object.Position) ? Number(object.Position) : 0,
      TotalImages: isSet(object.TotalImages) ? Number(object.TotalImages) : 0,
      Stride: isSet(object.Stride) ? Number(object.Stride) : 0,
      Order: isSet(object.Order) ? Number(object.Order) : 0,
    };
  },

  toJSON(message: CBCTGPToothSliceMetadataRegular): unknown {
    const obj: any = {};
    message.IdxStart !== undefined &&
      (obj.IdxStart = Math.round(message.IdxStart));
    message.IdxEnd !== undefined && (obj.IdxEnd = Math.round(message.IdxEnd));
    message.Thickness !== undefined && (obj.Thickness = message.Thickness);
    message.Position !== undefined && (obj.Position = message.Position);
    message.TotalImages !== undefined &&
      (obj.TotalImages = Math.round(message.TotalImages));
    message.Stride !== undefined && (obj.Stride = message.Stride);
    message.Order !== undefined && (obj.Order = Math.round(message.Order));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CBCTGPToothSliceMetadataRegular>, I>>(
    object: I,
  ): CBCTGPToothSliceMetadataRegular {
    const message = createBaseCBCTGPToothSliceMetadataRegular();
    message.IdxStart = object.IdxStart ?? 0;
    message.IdxEnd = object.IdxEnd ?? 0;
    message.Thickness = object.Thickness ?? 0;
    message.Position = object.Position ?? 0;
    message.TotalImages = object.TotalImages ?? 0;
    message.Stride = object.Stride ?? 0;
    message.Order = object.Order ?? 0;
    return message;
  },
};

function createBaseCBCTGPToothSliceMetadataGuide(): CBCTGPToothSliceMetadataGuide {
  return { Points: [], Axis2: 0, OrthoAxis: 0 };
}

export const CBCTGPToothSliceMetadataGuide = {
  encode(
    message: CBCTGPToothSliceMetadataGuide,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.Points) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.Axis2 !== 0) {
      writer.uint32(16).uint32(message.Axis2);
    }
    if (message.OrthoAxis !== 0) {
      writer.uint32(24).uint32(message.OrthoAxis);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CBCTGPToothSliceMetadataGuide {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCBCTGPToothSliceMetadataGuide();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.Points.push(reader.int32());
            }
          } else {
            message.Points.push(reader.int32());
          }
          break;
        case 2:
          message.Axis2 = reader.uint32();
          break;
        case 3:
          message.OrthoAxis = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CBCTGPToothSliceMetadataGuide {
    return {
      Points: Array.isArray(object?.Points)
        ? object.Points.map((e: any) => Number(e))
        : [],
      Axis2: isSet(object.Axis2) ? Number(object.Axis2) : 0,
      OrthoAxis: isSet(object.OrthoAxis) ? Number(object.OrthoAxis) : 0,
    };
  },

  toJSON(message: CBCTGPToothSliceMetadataGuide): unknown {
    const obj: any = {};
    if (message.Points) {
      obj.Points = message.Points.map((e) => Math.round(e));
    } else {
      obj.Points = [];
    }
    message.Axis2 !== undefined && (obj.Axis2 = Math.round(message.Axis2));
    message.OrthoAxis !== undefined &&
      (obj.OrthoAxis = Math.round(message.OrthoAxis));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CBCTGPToothSliceMetadataGuide>, I>>(
    object: I,
  ): CBCTGPToothSliceMetadataGuide {
    const message = createBaseCBCTGPToothSliceMetadataGuide();
    message.Points = object.Points?.map((e) => e) || [];
    message.Axis2 = object.Axis2 ?? 0;
    message.OrthoAxis = object.OrthoAxis ?? 0;
    return message;
  },
};

function createBaseCBCTGPToothSliceMetadataGuideFrontal(): CBCTGPToothSliceMetadataGuideFrontal {
  return {
    IdxStart: 0,
    IdxEnd: 0,
    Thickness: 0,
    Position: 0,
    Axis2: 0,
    Lines: [],
  };
}

export const CBCTGPToothSliceMetadataGuideFrontal = {
  encode(
    message: CBCTGPToothSliceMetadataGuideFrontal,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IdxStart !== 0) {
      writer.uint32(8).int64(message.IdxStart);
    }
    if (message.IdxEnd !== 0) {
      writer.uint32(16).int64(message.IdxEnd);
    }
    if (message.Thickness !== 0) {
      writer.uint32(29).float(message.Thickness);
    }
    if (message.Position !== 0) {
      writer.uint32(37).float(message.Position);
    }
    if (message.Axis2 !== 0) {
      writer.uint32(40).uint32(message.Axis2);
    }
    for (const v of message.Lines) {
      OrderedLine2D.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CBCTGPToothSliceMetadataGuideFrontal {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCBCTGPToothSliceMetadataGuideFrontal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IdxStart = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.IdxEnd = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.Thickness = reader.float();
          break;
        case 4:
          message.Position = reader.float();
          break;
        case 5:
          message.Axis2 = reader.uint32();
          break;
        case 6:
          message.Lines.push(OrderedLine2D.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CBCTGPToothSliceMetadataGuideFrontal {
    return {
      IdxStart: isSet(object.IdxStart) ? Number(object.IdxStart) : 0,
      IdxEnd: isSet(object.IdxEnd) ? Number(object.IdxEnd) : 0,
      Thickness: isSet(object.Thickness) ? Number(object.Thickness) : 0,
      Position: isSet(object.Position) ? Number(object.Position) : 0,
      Axis2: isSet(object.Axis2) ? Number(object.Axis2) : 0,
      Lines: Array.isArray(object?.Lines)
        ? object.Lines.map((e: any) => OrderedLine2D.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CBCTGPToothSliceMetadataGuideFrontal): unknown {
    const obj: any = {};
    message.IdxStart !== undefined &&
      (obj.IdxStart = Math.round(message.IdxStart));
    message.IdxEnd !== undefined && (obj.IdxEnd = Math.round(message.IdxEnd));
    message.Thickness !== undefined && (obj.Thickness = message.Thickness);
    message.Position !== undefined && (obj.Position = message.Position);
    message.Axis2 !== undefined && (obj.Axis2 = Math.round(message.Axis2));
    if (message.Lines) {
      obj.Lines = message.Lines.map((e) =>
        e ? OrderedLine2D.toJSON(e) : undefined,
      );
    } else {
      obj.Lines = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<CBCTGPToothSliceMetadataGuideFrontal>, I>,
  >(object: I): CBCTGPToothSliceMetadataGuideFrontal {
    const message = createBaseCBCTGPToothSliceMetadataGuideFrontal();
    message.IdxStart = object.IdxStart ?? 0;
    message.IdxEnd = object.IdxEnd ?? 0;
    message.Thickness = object.Thickness ?? 0;
    message.Position = object.Position ?? 0;
    message.Axis2 = object.Axis2 ?? 0;
    message.Lines =
      object.Lines?.map((e) => OrderedLine2D.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTGPMPRSubVolume(): AssetContentGeneratedCBCTGPMPRSubVolume {
  return {
    DCM: undefined,
    PredictedISONumber: 0,
    Position: undefined,
    PositionInner: undefined,
    WindowWidth: 0,
    WindowLevel: 0,
    Landmarks: [],
  };
}

export const AssetContentGeneratedCBCTGPMPRSubVolume = {
  encode(
    message: AssetContentGeneratedCBCTGPMPRSubVolume,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DCM !== undefined) {
      File.encode(message.DCM, writer.uint32(10).fork()).ldelim();
    }
    if (message.PredictedISONumber !== 0) {
      writer.uint32(16).uint32(message.PredictedISONumber);
    }
    if (message.Position !== undefined) {
      BBox.encode(message.Position, writer.uint32(26).fork()).ldelim();
    }
    if (message.PositionInner !== undefined) {
      BBox.encode(message.PositionInner, writer.uint32(34).fork()).ldelim();
    }
    if (message.WindowWidth !== 0) {
      writer.uint32(45).float(message.WindowWidth);
    }
    if (message.WindowLevel !== 0) {
      writer.uint32(53).float(message.WindowLevel);
    }
    for (const v of message.Landmarks) {
      AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark.encode(
        v!,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTGPMPRSubVolume {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTGPMPRSubVolume();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DCM = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.PredictedISONumber = reader.uint32();
          break;
        case 3:
          message.Position = BBox.decode(reader, reader.uint32());
          break;
        case 4:
          message.PositionInner = BBox.decode(reader, reader.uint32());
          break;
        case 5:
          message.WindowWidth = reader.float();
          break;
        case 6:
          message.WindowLevel = reader.float();
          break;
        case 7:
          message.Landmarks.push(
            AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTGPMPRSubVolume {
    return {
      DCM: isSet(object.DCM) ? File.fromJSON(object.DCM) : undefined,
      PredictedISONumber: isSet(object.PredictedISONumber)
        ? Number(object.PredictedISONumber)
        : 0,
      Position: isSet(object.Position)
        ? BBox.fromJSON(object.Position)
        : undefined,
      PositionInner: isSet(object.PositionInner)
        ? BBox.fromJSON(object.PositionInner)
        : undefined,
      WindowWidth: isSet(object.WindowWidth) ? Number(object.WindowWidth) : 0,
      WindowLevel: isSet(object.WindowLevel) ? Number(object.WindowLevel) : 0,
      Landmarks: Array.isArray(object?.Landmarks)
        ? object.Landmarks.map((e: any) =>
            AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: AssetContentGeneratedCBCTGPMPRSubVolume): unknown {
    const obj: any = {};
    message.DCM !== undefined &&
      (obj.DCM = message.DCM ? File.toJSON(message.DCM) : undefined);
    message.PredictedISONumber !== undefined &&
      (obj.PredictedISONumber = Math.round(message.PredictedISONumber));
    message.Position !== undefined &&
      (obj.Position = message.Position
        ? BBox.toJSON(message.Position)
        : undefined);
    message.PositionInner !== undefined &&
      (obj.PositionInner = message.PositionInner
        ? BBox.toJSON(message.PositionInner)
        : undefined);
    message.WindowWidth !== undefined &&
      (obj.WindowWidth = message.WindowWidth);
    message.WindowLevel !== undefined &&
      (obj.WindowLevel = message.WindowLevel);
    if (message.Landmarks) {
      obj.Landmarks = message.Landmarks.map((e) =>
        e
          ? AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark.toJSON(e)
          : undefined,
      );
    } else {
      obj.Landmarks = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTGPMPRSubVolume>, I>,
  >(object: I): AssetContentGeneratedCBCTGPMPRSubVolume {
    const message = createBaseAssetContentGeneratedCBCTGPMPRSubVolume();
    message.DCM =
      object.DCM !== undefined && object.DCM !== null
        ? File.fromPartial(object.DCM)
        : undefined;
    message.PredictedISONumber = object.PredictedISONumber ?? 0;
    message.Position =
      object.Position !== undefined && object.Position !== null
        ? BBox.fromPartial(object.Position)
        : undefined;
    message.PositionInner =
      object.PositionInner !== undefined && object.PositionInner !== null
        ? BBox.fromPartial(object.PositionInner)
        : undefined;
    message.WindowWidth = object.WindowWidth ?? 0;
    message.WindowLevel = object.WindowLevel ?? 0;
    message.Landmarks =
      object.Landmarks?.map((e) =>
        AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark(): AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark {
  return { Code: 0, Position: undefined };
}

export const AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark = {
  encode(
    message: AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Code !== 0) {
      writer.uint32(8).int32(message.Code);
    }
    if (message.Position !== undefined) {
      IntPoint3D.encode(message.Position, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseAssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Code = reader.int32() as any;
          break;
        case 2:
          message.Position = IntPoint3D.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark {
    return {
      Code: isSet(object.Code) ? toothLandmarkCodeFromJSON(object.Code) : 0,
      Position: isSet(object.Position)
        ? IntPoint3D.fromJSON(object.Position)
        : undefined,
    };
  },

  toJSON(
    message: AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark,
  ): unknown {
    const obj: any = {};
    message.Code !== undefined &&
      (obj.Code = toothLandmarkCodeToJSON(message.Code));
    message.Position !== undefined &&
      (obj.Position = message.Position
        ? IntPoint3D.toJSON(message.Position)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark>,
      I
    >,
  >(object: I): AssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark {
    const message =
      createBaseAssetContentGeneratedCBCTGPMPRSubVolume_ToothLandmark();
    message.Code = object.Code ?? 0;
    message.Position =
      object.Position !== undefined && object.Position !== null
        ? IntPoint3D.fromPartial(object.Position)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTGPPeriodontiumImage(): AssetContentGeneratedCBCTGPPeriodontiumImage {
  return { Periodontium: undefined, PredictedISONumber: 0, ImageType: 0 };
}

export const AssetContentGeneratedCBCTGPPeriodontiumImage = {
  encode(
    message: AssetContentGeneratedCBCTGPPeriodontiumImage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Periodontium !== undefined) {
      File.encode(message.Periodontium, writer.uint32(10).fork()).ldelim();
    }
    if (message.PredictedISONumber !== 0) {
      writer.uint32(16).uint32(message.PredictedISONumber);
    }
    if (message.ImageType !== 0) {
      writer.uint32(24).int32(message.ImageType);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTGPPeriodontiumImage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTGPPeriodontiumImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Periodontium = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.PredictedISONumber = reader.uint32();
          break;
        case 3:
          message.ImageType = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTGPPeriodontiumImage {
    return {
      Periodontium: isSet(object.Periodontium)
        ? File.fromJSON(object.Periodontium)
        : undefined,
      PredictedISONumber: isSet(object.PredictedISONumber)
        ? Number(object.PredictedISONumber)
        : 0,
      ImageType: isSet(object.ImageType)
        ? assetContentGeneratedCBCTGPPeriodontiumImage_TypeFromJSON(
            object.ImageType,
          )
        : 0,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTGPPeriodontiumImage): unknown {
    const obj: any = {};
    message.Periodontium !== undefined &&
      (obj.Periodontium = message.Periodontium
        ? File.toJSON(message.Periodontium)
        : undefined);
    message.PredictedISONumber !== undefined &&
      (obj.PredictedISONumber = Math.round(message.PredictedISONumber));
    message.ImageType !== undefined &&
      (obj.ImageType = assetContentGeneratedCBCTGPPeriodontiumImage_TypeToJSON(
        message.ImageType,
      ));
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedCBCTGPPeriodontiumImage>,
      I
    >,
  >(object: I): AssetContentGeneratedCBCTGPPeriodontiumImage {
    const message = createBaseAssetContentGeneratedCBCTGPPeriodontiumImage();
    message.Periodontium =
      object.Periodontium !== undefined && object.Periodontium !== null
        ? File.fromPartial(object.Periodontium)
        : undefined;
    message.PredictedISONumber = object.PredictedISONumber ?? 0;
    message.ImageType = object.ImageType ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
