import React, { FC, useMemo, useState } from 'react';
import cn from 'classnames';
import { generatePath, Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import { format } from 'date-fns';

import { PATHS } from '@/shared/config';
import {
  Button,
  Column,
  Description,
  Icon,
  Layout,
  Skeleton,
  toastCaller,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui';
import { getReportSignStatus, i18n, reportsModel } from '@/entities/reports';
import { assetsModel, getImageSrc } from '@/entities/assets';
import { userModel } from '@/entities/user';
import { useAppDispatch, useAppSelector, useMedia } from '@/shared/hooks';
import { Header } from '@/widgets/Header';
import { ReportToothChart } from '@/widgets/ReportToothChart';
import { ToothCard } from '@/widgets/ToothCard';
import { AddConditionModal } from '@/widgets/AddConditionModal';
import { Conclusion } from '@/widgets/Conclusion';
import { PanoImage } from '@/widgets/PanoImage';
import { ReferralRecommendations } from '@/widgets/ReferralRecommendations';
import {
  conditionModel,
  getConditionIDsByColor,
  useTransformCondition,
} from '@/entities/condition';
import { useReport } from '@/features/reportStream';
import { ZoomedSliceModal } from '@/widgets/ZoomedSliceModal';

import styles from './Report.module.scss';
import { PANO_TOOLS_ICONS, REPORT_CREATION_DATE_FORMAT } from './config';
import { ConfirmSignReportModal } from './ui/ConfirmSignReportModal/ConfirmSignReportModal';
import { useConfirmSignReportModal } from './hooks/useConfirmSignReportModal';
import { ReportSigns } from './ui/ReportSigns/ReportSigns';
import { AddSignature } from './ui/AddSignature/AddSignature';

type ReportProps = {
  className?: string;
};

export const Report: FC<ReportProps> = (props) => {
  const { className } = props;

  const [loading, setLoading] = useState(false);

  const { formatMessage } = useIntl();
  const { patientID, reportID } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isMobile } = useMedia();

  const {
    isConfirmSignReportModalOpen,
    openConfirmSignReportModal,
    closeConfirmSignReportModal,
  } = useConfirmSignReportModal();

  const {
    report,
    isReportLoading,
    isReportSigned,
    ROITeeth,
    isAllTeethApproved,
    handleRemoveToothFromROI,
    isPatientLoading,
    patientFullName,
  } = useReport();

  const conditionsByROI = useAppSelector(
    conditionModel.selectors.selectByROIIDs(ROITeeth),
  );

  const panoImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(reportID),
  );

  const panoImageUrl = useMemo(
    () =>
      getImageSrc(panoImage?.CBCTGPPanoramaReformatGeneral.PanoramaSingle.ID),
    [panoImage],
  );

  const conditionsInterfaces = useTransformCondition(conditionsByROI);
  const yellowConditionsIDs = getConditionIDsByColor(
    'yellow',
    conditionsInterfaces,
  );

  const user = useAppSelector(userModel.selectors.selectCurrentUser);

  const canAddSign =
    report?.Signature.UserSignatures.length &&
    !report?.Signature.UserSignatures.find(
      (userSignature) => userSignature.UserID === user.ID,
    ) &&
    report.YourPermissions.CanSign;

  const handlePrintButton = () => {
    navigate(generatePath(PATHS.previewReport, { patientID, reportID }));
  };

  const handleSign = async () => {
    setLoading(true);

    try {
      const response = await dispatch(
        reportsModel.thunks.signReport({ ReportID: reportID }),
      ).unwrap();

      dispatch(reportsModel.actions.setNewestOne(response.Report));
    } catch (error) {
      toastCaller({
        message: error?.message,
        type: 'error',
        heading: 'Error',
      });
    } finally {
      setLoading(false);
    }
  };

  if (!report) {
    return null;
  }

  const reportSignStatus = getReportSignStatus(report);

  return (
    <>
      <Layout>
        <Layout.Header>
          <Header />
        </Layout.Header>

        <Layout.Content>
          <Layout.Main>
            <Layout.Content className={cn(styles.container, className)}>
              <nav className={styles.nav}>
                <Link
                  to={generatePath(PATHS.patientProfile, { patientID })}
                  className={styles.backLink}
                >
                  <Icon
                    className={styles.backLinkIcon}
                    name="arrowDown"
                    size={32}
                  />
                  <span className={cn(isMobile ? 'p2' : 'p1')}>
                    <FormattedMessage
                      id="previewReport.backToPatient"
                      defaultMessage="Back to patient profile"
                    />
                  </span>
                </Link>
              </nav>

              <header className={styles.header}>
                {isReportLoading ? (
                  <Skeleton width="75%" borderRadius="42px" height="87px" />
                ) : (
                  <h1 className={cn(isMobile ? 'h2' : 'h1')}>
                    {formatMessage(i18n.reportType[report.Type])}
                  </h1>
                )}

                {isPatientLoading ? (
                  <Skeleton width="35%" borderRadius="12px" height="24px" />
                ) : (
                  <p className={cn(isMobile ? 'p2' : 'p1')}>
                    {patientFullName}
                  </p>
                )}

                {isReportLoading ? (
                  <Skeleton width="55%" borderRadius="8px" height="16px" />
                ) : (
                  <div className={styles.descriptionContainer}>
                    <Description
                      label={
                        <FormattedMessage
                          id="report.ID"
                          defaultMessage="Report ID"
                        />
                      }
                    >
                      {reportID}
                    </Description>

                    <Description
                      label={
                        <FormattedMessage
                          id="report.creationDate"
                          defaultMessage="Creation date"
                        />
                      }
                    >
                      {format(report.Created.At, REPORT_CREATION_DATE_FORMAT)}
                    </Description>
                  </div>
                )}
              </header>

              <WidgetLayout>
                <Column>
                  <WidgetCard className={styles.panoImageWidget}>
                    {panoImage && (
                      <PanoImage
                        src={panoImageUrl}
                        controls={PANO_TOOLS_ICONS}
                      />
                    )}
                  </WidgetCard>

                  <WidgetCard>
                    <ReportToothChart />
                  </WidgetCard>
                </Column>

                <Column>
                  <ReferralRecommendations />
                  {ROITeeth.map((tooth) => (
                    <ToothCard
                      key={tooth.ID}
                      tooth={tooth}
                      onRemove={handleRemoveToothFromROI}
                    />
                  ))}
                  <WidgetCard>
                    <Conclusion conclusion={report?.LegacyConclusion} />

                    {report.Signature.UserSignatures.length ? (
                      <ReportSigns
                        signedUsers={report.Signature.UserSignatures}
                      />
                    ) : null}

                    {canAddSign ? (
                      <AddSignature
                        user={user}
                        loading={loading}
                        handleSign={handleSign}
                      />
                    ) : null}
                  </WidgetCard>
                </Column>
              </WidgetLayout>
            </Layout.Content>
          </Layout.Main>
        </Layout.Content>

        <Layout.Footer className={styles.footer} sticky>
          <Button
            variant={isReportSigned ? 'primary' : 'tertiary'}
            onClick={handlePrintButton}
            icon={isReportSigned ? 'check' : null}
            size={isMobile ? 'medium' : 'large'}
          >
            {isReportSigned ? (
              <FormattedMessage
                id="report.printReport"
                defaultMessage="Print report"
              />
            ) : (
              <FormattedMessage
                id="report.printReportWithoutSignature"
                defaultMessage="Print report without signature"
              />
            )}
          </Button>

          {!isReportSigned && (
            <Button
              icon="pen"
              disabled={
                reportSignStatus === 'withoutSign' ||
                !report.YourPermissions.CanChangeToothApproved
              }
              onClick={openConfirmSignReportModal}
              size={isMobile ? 'medium' : 'large'}
            >
              {isAllTeethApproved ? (
                <FormattedMessage id="report.sign" defaultMessage="Sign" />
              ) : (
                <FormattedMessage
                  id="report.approveAndSign"
                  defaultMessage="Approve all and sign"
                />
              )}
            </Button>
          )}
        </Layout.Footer>
      </Layout>

      <ConfirmSignReportModal
        isOpen={isConfirmSignReportModalOpen}
        onClose={closeConfirmSignReportModal}
        yellowConditionsIDs={yellowConditionsIDs}
      />

      <AddConditionModal />
      <ZoomedSliceModal />
    </>
  );
};
