import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from '@/shared/hooks';
import { userModel, UserLocale } from '@/entities/user';
import { Layout } from '@/shared/ui/';
import { Header } from '@/widgets/Header';

export const TestIntlPage = () => {
  const dispatch = useAppDispatch();

  const changeLanguage = (newLocale: UserLocale) => {
    dispatch(userModel.thunks.setSettings({ locale: newLocale }));
  };

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.Content>
        <Layout.Main>
          <div style={{ padding: 40 }}>
            <div
              style={{
                display: 'flex',
                gap: 16,
                paddingTop: 24,
                paddingBottom: 24,
              }}
            >
              <button type="button" onClick={() => changeLanguage('en')}>
                EN
              </button>
              <button type="button" onClick={() => changeLanguage('ru')}>
                RU
              </button>
              <button type="button" onClick={() => changeLanguage('he')}>
                HE
              </button>
            </div>

            <FormattedMessage id="test.firstName" defaultMessage="First Name" />
          </div>
        </Layout.Main>
      </Layout.Content>
      <Layout.Footer />
    </Layout>
  );
};
