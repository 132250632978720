import { ProcessingUnit } from 'graphics';
import { MedicalImageBase, MedicalImageProps } from './MedicalImageBase';

export const MedicalImage = (props: MedicalImageProps) => {

    return (
        <MedicalImageBase {...props} repaint={(context, canvas) => {

            // console.log("MedicalImageBase repaint", props.src)
            const upsampling = 1
            const crop = { w: context.width, h: context.height }
            canvas.width = crop.w * upsampling
            canvas.height = crop.h * upsampling

            const ctx = canvas.getContext("2d")

            ctx.drawImage(ProcessingUnit.canvas,
                0, 0, crop.w * upsampling, crop.h * upsampling,
                0, 0, crop.w * upsampling, crop.h * upsampling)
        }} />
    )
};

