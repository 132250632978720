import React, { FC, useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { authModel } from '@/entities/auth';
import { PATHS, REFRESH_TOKEN_COOLDOWN_MS } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { toastCaller } from '@/shared/ui';

export const ProtectedRoutes: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const location = useLocation();
  const navigate = useNavigate();

  const isAuth = useAppSelector(authModel.selectors.selectIsAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleStorageEvent = () => {
      const user = localStorage.getItem('user');

      if (!user) {
        dispatch(authModel.actions.setIsAuthenticated(false));
        navigate(PATHS.signIn);
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    return () => window.removeEventListener('storage', handleStorageEvent);
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      const user = localStorage.getItem('user');

      await dispatch(authModel.thunks.refresh({})).unwrap();

      try {
        const whoIam = await dispatch(authModel.thunks.whoAmI({})).unwrap();

        if (whoIam.IsAuthenticated) {
          dispatch(authModel.actions.setIsAuthenticated(true));
          localStorage.setItem('user', JSON.stringify(whoIam?.User));
        } else if (user) {
          localStorage.removeItem('user');
          window.dispatchEvent(new Event('storage'));
        }
      } catch (e) {
        toastCaller({
          type: 'error',
          heading: 'Error',
          message: e.message,
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (!isAuth) {
      checkAuth();
    } else {
      setIsLoading(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const refresh = async () => {
      try {
        await dispatch(authModel.thunks.refresh({})).unwrap();
      } catch (e) {
        toastCaller({
          type: 'error',
          heading: 'Error',
          message: e.message,
        });
      }
    };

    const interval = setInterval(refresh, REFRESH_TOKEN_COOLDOWN_MS);

    return () => clearInterval(interval);
  }, []);

  if (isLoading) return null;

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={PATHS.signIn} state={{ from: location }} replace />
  );
};
