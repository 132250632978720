import React, { useContext } from 'react';
import { ObjectFitContaintContext } from '../ObjectFitContain';

type foo = React.HTMLAttributes<HTMLOrSVGElement>;

// svg viewBox do not allow values in percents
// SVG container that resizes to container size
export const AutosizeSvg = (props: foo) => {
  const objectFit = useContext(ObjectFitContaintContext);

  return (
    <svg
      width={objectFit.width}
      height={objectFit.height}
      viewBox={`0 0 ${objectFit.objectWidth} ${objectFit.objectHeight}`}
      {...props}
    >
      {props.children}
    </svg>
  );
};
