/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  SubscriptionState,
  SubscriptionModel,
} from '../../api/billing/dto_subscription';

export const protobufPackage = 'com.diagnocat.billing';

export interface Account {
  ID: string;
  Email: string;
  OwnerID: string;
  OrganizationIDs: string[];
  GeoZone: string;
  Overdraft: AccountOverdraft | undefined;
  Inventory: AccountInventory | undefined;
  AvailableProducts: AvailableProducts | undefined;
  StripeIntegration: AccountStripeIntegration | undefined;
  WithoutStripeInvoices: boolean;
  BillingInformation: Account_AccountBillingInformation | undefined;
}

export interface Account_AccountBillingInformation {
  Address: string;
  Phone: string;
}

export interface AccountOverdraft {
  /** string Limit = 2; // TODO: Unimplementable this way */
  Enabled: boolean;
}

export interface AccountInventory {
  Subscriptions: SubscriptionState[];
  Packages: SubscriptionState[];
  Trials: SubscriptionState[];
}

/** TODO: Do we need this entity at all??? */
export interface AvailableProducts {
  ID: string;
  GeoZone: string;
  Subscriptions: SubscriptionModel[];
  Packages: SubscriptionModel[];
  Trials: SubscriptionModel[];
}

export interface AccountStripeIntegration {
  CustomerID: string;
  PaymentMethodID: string;
}

function createBaseAccount(): Account {
  return {
    ID: '',
    Email: '',
    OwnerID: '',
    OrganizationIDs: [],
    GeoZone: '',
    Overdraft: undefined,
    Inventory: undefined,
    AvailableProducts: undefined,
    StripeIntegration: undefined,
    WithoutStripeInvoices: false,
    BillingInformation: undefined,
  };
}

export const Account = {
  encode(
    message: Account,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Email !== '') {
      writer.uint32(18).string(message.Email);
    }
    if (message.OwnerID !== '') {
      writer.uint32(26).string(message.OwnerID);
    }
    for (const v of message.OrganizationIDs) {
      writer.uint32(34).string(v!);
    }
    if (message.GeoZone !== '') {
      writer.uint32(42).string(message.GeoZone);
    }
    if (message.Overdraft !== undefined) {
      AccountOverdraft.encode(
        message.Overdraft,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    if (message.Inventory !== undefined) {
      AccountInventory.encode(
        message.Inventory,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.AvailableProducts !== undefined) {
      AvailableProducts.encode(
        message.AvailableProducts,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    if (message.StripeIntegration !== undefined) {
      AccountStripeIntegration.encode(
        message.StripeIntegration,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    if (message.WithoutStripeInvoices === true) {
      writer.uint32(80).bool(message.WithoutStripeInvoices);
    }
    if (message.BillingInformation !== undefined) {
      Account_AccountBillingInformation.encode(
        message.BillingInformation,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Account {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Email = reader.string();
          break;
        case 3:
          message.OwnerID = reader.string();
          break;
        case 4:
          message.OrganizationIDs.push(reader.string());
          break;
        case 5:
          message.GeoZone = reader.string();
          break;
        case 6:
          message.Overdraft = AccountOverdraft.decode(reader, reader.uint32());
          break;
        case 7:
          message.Inventory = AccountInventory.decode(reader, reader.uint32());
          break;
        case 8:
          message.AvailableProducts = AvailableProducts.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 9:
          message.StripeIntegration = AccountStripeIntegration.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10:
          message.WithoutStripeInvoices = reader.bool();
          break;
        case 11:
          message.BillingInformation = Account_AccountBillingInformation.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Account {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Email: isSet(object.Email) ? String(object.Email) : '',
      OwnerID: isSet(object.OwnerID) ? String(object.OwnerID) : '',
      OrganizationIDs: Array.isArray(object?.OrganizationIDs)
        ? object.OrganizationIDs.map((e: any) => String(e))
        : [],
      GeoZone: isSet(object.GeoZone) ? String(object.GeoZone) : '',
      Overdraft: isSet(object.Overdraft)
        ? AccountOverdraft.fromJSON(object.Overdraft)
        : undefined,
      Inventory: isSet(object.Inventory)
        ? AccountInventory.fromJSON(object.Inventory)
        : undefined,
      AvailableProducts: isSet(object.AvailableProducts)
        ? AvailableProducts.fromJSON(object.AvailableProducts)
        : undefined,
      StripeIntegration: isSet(object.StripeIntegration)
        ? AccountStripeIntegration.fromJSON(object.StripeIntegration)
        : undefined,
      WithoutStripeInvoices: isSet(object.WithoutStripeInvoices)
        ? Boolean(object.WithoutStripeInvoices)
        : false,
      BillingInformation: isSet(object.BillingInformation)
        ? Account_AccountBillingInformation.fromJSON(object.BillingInformation)
        : undefined,
    };
  },

  toJSON(message: Account): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Email !== undefined && (obj.Email = message.Email);
    message.OwnerID !== undefined && (obj.OwnerID = message.OwnerID);
    if (message.OrganizationIDs) {
      obj.OrganizationIDs = message.OrganizationIDs.map((e) => e);
    } else {
      obj.OrganizationIDs = [];
    }
    message.GeoZone !== undefined && (obj.GeoZone = message.GeoZone);
    message.Overdraft !== undefined &&
      (obj.Overdraft = message.Overdraft
        ? AccountOverdraft.toJSON(message.Overdraft)
        : undefined);
    message.Inventory !== undefined &&
      (obj.Inventory = message.Inventory
        ? AccountInventory.toJSON(message.Inventory)
        : undefined);
    message.AvailableProducts !== undefined &&
      (obj.AvailableProducts = message.AvailableProducts
        ? AvailableProducts.toJSON(message.AvailableProducts)
        : undefined);
    message.StripeIntegration !== undefined &&
      (obj.StripeIntegration = message.StripeIntegration
        ? AccountStripeIntegration.toJSON(message.StripeIntegration)
        : undefined);
    message.WithoutStripeInvoices !== undefined &&
      (obj.WithoutStripeInvoices = message.WithoutStripeInvoices);
    message.BillingInformation !== undefined &&
      (obj.BillingInformation = message.BillingInformation
        ? Account_AccountBillingInformation.toJSON(message.BillingInformation)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Account>, I>>(object: I): Account {
    const message = createBaseAccount();
    message.ID = object.ID ?? '';
    message.Email = object.Email ?? '';
    message.OwnerID = object.OwnerID ?? '';
    message.OrganizationIDs = object.OrganizationIDs?.map((e) => e) || [];
    message.GeoZone = object.GeoZone ?? '';
    message.Overdraft =
      object.Overdraft !== undefined && object.Overdraft !== null
        ? AccountOverdraft.fromPartial(object.Overdraft)
        : undefined;
    message.Inventory =
      object.Inventory !== undefined && object.Inventory !== null
        ? AccountInventory.fromPartial(object.Inventory)
        : undefined;
    message.AvailableProducts =
      object.AvailableProducts !== undefined &&
      object.AvailableProducts !== null
        ? AvailableProducts.fromPartial(object.AvailableProducts)
        : undefined;
    message.StripeIntegration =
      object.StripeIntegration !== undefined &&
      object.StripeIntegration !== null
        ? AccountStripeIntegration.fromPartial(object.StripeIntegration)
        : undefined;
    message.WithoutStripeInvoices = object.WithoutStripeInvoices ?? false;
    message.BillingInformation =
      object.BillingInformation !== undefined &&
      object.BillingInformation !== null
        ? Account_AccountBillingInformation.fromPartial(
            object.BillingInformation,
          )
        : undefined;
    return message;
  },
};

function createBaseAccount_AccountBillingInformation(): Account_AccountBillingInformation {
  return { Address: '', Phone: '' };
}

export const Account_AccountBillingInformation = {
  encode(
    message: Account_AccountBillingInformation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Address !== '') {
      writer.uint32(10).string(message.Address);
    }
    if (message.Phone !== '') {
      writer.uint32(18).string(message.Phone);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Account_AccountBillingInformation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccount_AccountBillingInformation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Address = reader.string();
          break;
        case 2:
          message.Phone = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Account_AccountBillingInformation {
    return {
      Address: isSet(object.Address) ? String(object.Address) : '',
      Phone: isSet(object.Phone) ? String(object.Phone) : '',
    };
  },

  toJSON(message: Account_AccountBillingInformation): unknown {
    const obj: any = {};
    message.Address !== undefined && (obj.Address = message.Address);
    message.Phone !== undefined && (obj.Phone = message.Phone);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<Account_AccountBillingInformation>, I>,
  >(object: I): Account_AccountBillingInformation {
    const message = createBaseAccount_AccountBillingInformation();
    message.Address = object.Address ?? '';
    message.Phone = object.Phone ?? '';
    return message;
  },
};

function createBaseAccountOverdraft(): AccountOverdraft {
  return { Enabled: false };
}

export const AccountOverdraft = {
  encode(
    message: AccountOverdraft,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Enabled === true) {
      writer.uint32(8).bool(message.Enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountOverdraft {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountOverdraft();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Enabled = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountOverdraft {
    return {
      Enabled: isSet(object.Enabled) ? Boolean(object.Enabled) : false,
    };
  },

  toJSON(message: AccountOverdraft): unknown {
    const obj: any = {};
    message.Enabled !== undefined && (obj.Enabled = message.Enabled);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AccountOverdraft>, I>>(
    object: I,
  ): AccountOverdraft {
    const message = createBaseAccountOverdraft();
    message.Enabled = object.Enabled ?? false;
    return message;
  },
};

function createBaseAccountInventory(): AccountInventory {
  return { Subscriptions: [], Packages: [], Trials: [] };
}

export const AccountInventory = {
  encode(
    message: AccountInventory,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Subscriptions) {
      SubscriptionState.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Packages) {
      SubscriptionState.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.Trials) {
      SubscriptionState.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountInventory {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountInventory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Subscriptions.push(
            SubscriptionState.decode(reader, reader.uint32()),
          );
          break;
        case 2:
          message.Packages.push(
            SubscriptionState.decode(reader, reader.uint32()),
          );
          break;
        case 3:
          message.Trials.push(
            SubscriptionState.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountInventory {
    return {
      Subscriptions: Array.isArray(object?.Subscriptions)
        ? object.Subscriptions.map((e: any) => SubscriptionState.fromJSON(e))
        : [],
      Packages: Array.isArray(object?.Packages)
        ? object.Packages.map((e: any) => SubscriptionState.fromJSON(e))
        : [],
      Trials: Array.isArray(object?.Trials)
        ? object.Trials.map((e: any) => SubscriptionState.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AccountInventory): unknown {
    const obj: any = {};
    if (message.Subscriptions) {
      obj.Subscriptions = message.Subscriptions.map((e) =>
        e ? SubscriptionState.toJSON(e) : undefined,
      );
    } else {
      obj.Subscriptions = [];
    }
    if (message.Packages) {
      obj.Packages = message.Packages.map((e) =>
        e ? SubscriptionState.toJSON(e) : undefined,
      );
    } else {
      obj.Packages = [];
    }
    if (message.Trials) {
      obj.Trials = message.Trials.map((e) =>
        e ? SubscriptionState.toJSON(e) : undefined,
      );
    } else {
      obj.Trials = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AccountInventory>, I>>(
    object: I,
  ): AccountInventory {
    const message = createBaseAccountInventory();
    message.Subscriptions =
      object.Subscriptions?.map((e) => SubscriptionState.fromPartial(e)) || [];
    message.Packages =
      object.Packages?.map((e) => SubscriptionState.fromPartial(e)) || [];
    message.Trials =
      object.Trials?.map((e) => SubscriptionState.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAvailableProducts(): AvailableProducts {
  return { ID: '', GeoZone: '', Subscriptions: [], Packages: [], Trials: [] };
}

export const AvailableProducts = {
  encode(
    message: AvailableProducts,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.GeoZone !== '') {
      writer.uint32(18).string(message.GeoZone);
    }
    for (const v of message.Subscriptions) {
      SubscriptionModel.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.Packages) {
      SubscriptionModel.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.Trials) {
      SubscriptionModel.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AvailableProducts {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAvailableProducts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.GeoZone = reader.string();
          break;
        case 10:
          message.Subscriptions.push(
            SubscriptionModel.decode(reader, reader.uint32()),
          );
          break;
        case 11:
          message.Packages.push(
            SubscriptionModel.decode(reader, reader.uint32()),
          );
          break;
        case 12:
          message.Trials.push(
            SubscriptionModel.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AvailableProducts {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      GeoZone: isSet(object.GeoZone) ? String(object.GeoZone) : '',
      Subscriptions: Array.isArray(object?.Subscriptions)
        ? object.Subscriptions.map((e: any) => SubscriptionModel.fromJSON(e))
        : [],
      Packages: Array.isArray(object?.Packages)
        ? object.Packages.map((e: any) => SubscriptionModel.fromJSON(e))
        : [],
      Trials: Array.isArray(object?.Trials)
        ? object.Trials.map((e: any) => SubscriptionModel.fromJSON(e))
        : [],
    };
  },

  toJSON(message: AvailableProducts): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.GeoZone !== undefined && (obj.GeoZone = message.GeoZone);
    if (message.Subscriptions) {
      obj.Subscriptions = message.Subscriptions.map((e) =>
        e ? SubscriptionModel.toJSON(e) : undefined,
      );
    } else {
      obj.Subscriptions = [];
    }
    if (message.Packages) {
      obj.Packages = message.Packages.map((e) =>
        e ? SubscriptionModel.toJSON(e) : undefined,
      );
    } else {
      obj.Packages = [];
    }
    if (message.Trials) {
      obj.Trials = message.Trials.map((e) =>
        e ? SubscriptionModel.toJSON(e) : undefined,
      );
    } else {
      obj.Trials = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AvailableProducts>, I>>(
    object: I,
  ): AvailableProducts {
    const message = createBaseAvailableProducts();
    message.ID = object.ID ?? '';
    message.GeoZone = object.GeoZone ?? '';
    message.Subscriptions =
      object.Subscriptions?.map((e) => SubscriptionModel.fromPartial(e)) || [];
    message.Packages =
      object.Packages?.map((e) => SubscriptionModel.fromPartial(e)) || [];
    message.Trials =
      object.Trials?.map((e) => SubscriptionModel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAccountStripeIntegration(): AccountStripeIntegration {
  return { CustomerID: '', PaymentMethodID: '' };
}

export const AccountStripeIntegration = {
  encode(
    message: AccountStripeIntegration,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CustomerID !== '') {
      writer.uint32(10).string(message.CustomerID);
    }
    if (message.PaymentMethodID !== '') {
      writer.uint32(18).string(message.PaymentMethodID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AccountStripeIntegration {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountStripeIntegration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CustomerID = reader.string();
          break;
        case 2:
          message.PaymentMethodID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AccountStripeIntegration {
    return {
      CustomerID: isSet(object.CustomerID) ? String(object.CustomerID) : '',
      PaymentMethodID: isSet(object.PaymentMethodID)
        ? String(object.PaymentMethodID)
        : '',
    };
  },

  toJSON(message: AccountStripeIntegration): unknown {
    const obj: any = {};
    message.CustomerID !== undefined && (obj.CustomerID = message.CustomerID);
    message.PaymentMethodID !== undefined &&
      (obj.PaymentMethodID = message.PaymentMethodID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AccountStripeIntegration>, I>>(
    object: I,
  ): AccountStripeIntegration {
    const message = createBaseAccountStripeIntegration();
    message.CustomerID = object.CustomerID ?? '';
    message.PaymentMethodID = object.PaymentMethodID ?? '';
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
