import React, { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import { Annotation } from './Annotation';
import { AutosizeSvg } from './AutosizeSvg';
import { RenderAnnotation } from './RenderAnnotation';

export const RenderAnnotationsLayer = ({
  annotations,
  pixelSpacing,
  children,
}: {
  annotations: Annotation[];
  pixelSpacing: [number, number];
  children?: ReactNode;
}) => {
  return (
    <AutosizeSvg style={{ display: 'block', pointerEvents: 'none' }}>
      {annotations?.map((annotation, index) => (
        <RenderAnnotation key={index} annotation={annotation} pixelSpacing={pixelSpacing} />
      ))}
      {children}
    </AutosizeSvg>
  );
};
