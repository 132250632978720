import React, { FC } from 'react';
import cn from 'classnames';
import { generatePath, Link } from 'react-router-dom';

import { useMedia } from '@/shared/hooks';
import patientImg from '@/shared/assets/images/patient.png';
import { Avatar } from '@/shared/ui';
import { StudiesCounters, StudyCounterItem } from '@/entities/patient';
import { PATHS } from '@/shared/config';

import styles from './PatientListRow.module.scss';

type PatientListRowProps = {
  id: string;
  patientName: string;
  patientBirthDate: string;
  className?: string;
  studies: StudyCounterItem[];
  testID?: string;
};

export const PatientListRow: FC<PatientListRowProps> = (props) => {
  const { id, patientName, patientBirthDate, studies, className, testID } =
    props;

  const { isPhone, isMobile, isSmallDesktop } = useMedia();

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <Link to={generatePath(PATHS.patientProfile, { patientID: id })}>
        <div className={styles.patient}>
          <img src={patientImg} alt="1" className={styles.img} />
          <div className={styles.patientInfo}>
            <p className={cn(isPhone ? 'p3' : 'p2', styles.name)}>
              {patientName}
            </p>
            <div className={styles.patientData}>
              <p className={cn(isPhone ? 'p3' : 'p2', styles.id)}>ID {id}</p>
              <p className={cn(isPhone ? 'p3' : 'p2', styles.date)}>
                {patientBirthDate}
              </p>
            </div>
          </div>
        </div>
      </Link>
      <div className={styles.doctors}>
        <Avatar.Group>
          <Avatar initials="VD" />
          <Avatar initials="AS" />
        </Avatar.Group>
      </div>
      {!isMobile && !isSmallDesktop && <StudiesCounters studies={studies} />}
    </div>
  );
};

// export const PatientListRow = memo(PatientListRowImplementation);
