// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConditionToggle-module__container--8Ij94pnm{display:flex}.ConditionToggle-module__text--GpWGCr9E.ConditionToggle-module__colorYellow--4kvtr56L{color:var(--orange)}.ConditionToggle-module__text--GpWGCr9E.ConditionToggle-module__colorRed--ZjDQ-ihb{color:var(--redrose)}.ConditionToggle-module__text--GpWGCr9E.ConditionToggle-module__colorPurple--akTKeqO\\+{color:var(--purpletext1)}.ConditionToggle-module__text--GpWGCr9E.ConditionToggle-module__colorWhite--zKO8LeCU{color:var(--black)}.ConditionToggle-module__label--LtGmpc2-{display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/entities/condition/ui/ConditionToggle/ConditionToggle.module.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CAIA,sFACE,mBAAA,CAGF,mFACE,oBAAA,CAGF,uFACE,wBAAA,CAGF,qFACE,kBAAA,CAIJ,yCACE,YAAA,CACA,OAAA","sourcesContent":[".container {\n  display: flex;\n}\n\n.text {\n  &.colorYellow {\n    color: var(--orange);\n  }\n\n  &.colorRed {\n    color: var(--redrose);\n  }\n\n  &.colorPurple {\n    color: var(--purpletext1);\n  }\n\n  &.colorWhite {\n    color: var(--black);\n  }\n}\n\n.label {\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ConditionToggle-module__container--8Ij94pnm",
	"text": "ConditionToggle-module__text--GpWGCr9E",
	"colorYellow": "ConditionToggle-module__colorYellow--4kvtr56L",
	"colorRed": "ConditionToggle-module__colorRed--ZjDQ-ihb",
	"colorPurple": "ConditionToggle-module__colorPurple--akTKeqO+",
	"colorWhite": "ConditionToggle-module__colorWhite--zKO8LeCU",
	"label": "ConditionToggle-module__label--LtGmpc2-"
};
export default ___CSS_LOADER_EXPORT___;
