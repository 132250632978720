import {
  components,
  MultiValueProps,
  MultiValueRemoveProps,
  OptionProps,
} from 'react-select';

import { Icon } from '@/shared/ui';

import { DoctorLabel } from '../DoctorLabel/DoctorLabel';
import { DoctorOptionType } from '../../config/types';

import styles from './DoctorsSelect.module.scss';

export const DoctorOption = (props: OptionProps<DoctorOptionType>) => {
  const {
    data: { label, avatarSrc },
    isSelected,
  } = props;
  return (
    <components.Option
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <DoctorLabel avatarSrc={avatarSrc} fullName={label} />
      {isSelected && (
        <Icon name="check" size={32} className={styles.selectedIcon} />
      )}
    </components.Option>
  );
};

export const DoctorMultiValueLabel = (
  props: MultiValueProps<DoctorOptionType>,
) => {
  const {
    data: { label, avatarSrc },
  } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.MultiValueLabel {...props}>
      <DoctorLabel avatarSrc={avatarSrc} fullName={label} />
    </components.MultiValueLabel>
  );
};

export const DoctorMultiValueRemove = (
  props: MultiValueRemoveProps<DoctorOptionType>,
) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.MultiValueRemove {...props}>
    <Icon name="close" size={24} />
  </components.MultiValueRemove>
);
