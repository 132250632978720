import { useState } from 'react';

export const usePatientModalForm = () => {
  const [currentPatientID, setPatientID] = useState<string>(undefined);
  const [show, setShow] = useState(false);

  const openNewPatientModalForm = () => {
    setShow(true);
  };

  const openEditPatientModalForm = (id: string) => {
    setPatientID(id);
    setShow(true);
  };

  const closePatientModalForm = () => {
    if (currentPatientID) {
      setPatientID(undefined);
    }
    setShow(false);
  };

  return {
    currentPatientID,
    showPatientModalForm: show,
    openNewPatientModalForm,
    openEditPatientModalForm,
    closePatientModalForm,
  };
};
